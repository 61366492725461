import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { getJobServiceAsssignedSurveyor } from "../../../../../requesters/jobServiceRequester"
import { getBaseUrl } from "../../../reducers"

import DashboardCardContainer from "../components/DashboardCardContainer";
import DashboardCard from "../components/DashboardCard";
import TableForSurveyor from "./TableForSurveyor"


const DashboardForSurveyor = ({ currentUser }) => {
  const { id, attributes: { firstname } } = currentUser
  const baseUrl = useSelector(getBaseUrl)
  const [jobServices, setJobServices] = useState([])
  const [jobCompleted, setJobCompleted] = useState(0)
  const [overdueData, setOverdueData] = useState([])
  const [upcomingServices, setUpcomingServices] = useState(0)

  const requestJobServices = userId => {
    getJobServiceAsssignedSurveyor(baseUrl, userId)
      .then(res => {
        if (res.status === 200) {
          const newData = Object.values(_.get(res.response.daily_jobs, "data", {}))
          setOverdueData(res?.response?.overdue_jobs)
          setJobServices(newData)
          setJobCompleted(res?.response?.completed_jobs)
          setUpcomingServices(res.response.service_required_count)
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  useEffect(() => {
    requestJobServices(id)
  }, [currentUser])

  return (
    <div className="dashboard">
      <div className="row no-gutters">
        <div className="col-lg-4 col-xl-3">
          <div className="container-fluid">
            <div className="container-inner">
              <div className="greeting">
                Hello,
                {" "}
                <br />
                {" "}
                {firstname}
                {" "}
              </div>
            </div>
          </div>
        </div>
        <DashboardCardContainer title="today">
          <div className="row">
            <DashboardCard
              // link=""
              title="Jobs Completed"
              total={jobServices.length}
              ofTotal
              count={jobCompleted}
              colorClass="bg-light" />
            <DashboardCard
              link="/flagged-jobs?filter=data_overdue&is_surveyor=true"
              title="Overdue Data until Today"
              count={overdueData.length}
              colorClass="bg-coral" />
            <DashboardCard
              link="/settings/my-gear"
              title="Asset Requirements"
              count={upcomingServices}
              colorClass={upcomingServices === 0 ? "bg-light" : "bg-coral"} />
            {/* <DashboardCard
              // link=""
              title="Additional Info (RS)"
              count="0"
              colorClass="bg-coral" />
            <DashboardCard
              // link=""
              title="Additional Info (Drafting)"
              count="0"
              colorClass="bg-coral" /> */}
          </div>
        </DashboardCardContainer>
      </div>
      {!!jobServices.length && (<TableForSurveyor currentUser={currentUser} jobServices={jobServices} requestJobServices={requestJobServices} />)}
    </div>
  )
}

export default DashboardForSurveyor
