// #region Date utils ===========================================
// use libray date-fns for date formatting to string
// https://www.section.io/engineering-education/javascript-dates-manipulation-with-date-fns/  - useful link
export const isDate = date => date instanceof Date && !isNaN(date.getTime())

export const getDefaultDate = dateString => {
  const date = new Date(dateString)
  if (isDate(date)) {
    return date
  }
  return new Date()
}

export const createDate = dateString => {
  if (dateString) {
    return new Date(dateString)
  }
    return null
}
// #endregion ==================================================
// #region Text utils ==========================================
export function copyToClipboard(textToCopy) {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  }
  // text area method
  const textArea = document.createElement("textarea");
  textArea.value = textToCopy;
  // make the textarea out of viewport
  textArea.style.position = "fixed";
  textArea.style.left = "-999999px";
  textArea.style.top = "-999999px";
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  return new Promise((res, rej) => {
    // here the magic happens
    document.execCommand("copy") ? res() : rej();
    textArea.remove();
  });
}

export const snakeCase = string => string.replace(/\W+/g, " ")
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join("_")

export const capitalizeString = state => {
  const array = state.split("_").map(item => _.capitalize(item))
  return array.join(" ")
}
// #endregion ==================================================
// #region Other utils =========================================
export function sortByName(array, sortIndex, switchOrder) {
  array.sort((a, b) => {
    if (a.attributes[sortIndex] < b.attributes[sortIndex]) { return switchOrder ? -1 : 1; }
    if (a.attributes[sortIndex] > b.attributes[sortIndex]) { return switchOrder ? 1 : -1; }
    if (a.attributes[sortIndex] === null) {
      return 1;
    }
    if (b.attributes[sortIndex] === null) {
      return -1;
    }
    return 0
  })
}

export function sortByTypeAndName(array, sortIndex1, sortIndex2, switchOrder) {
  array.sort((a, b) => {
    let result = 0
    if (a.attributes[sortIndex1] === b.attributes[sortIndex1]) {
      if (a.attributes[sortIndex2] < b.attributes[sortIndex2]) { result = switchOrder ? -1 : 1; }
      if (a.attributes[sortIndex2] > b.attributes[sortIndex2]) { result = switchOrder ? 1 : -1; }
      if (a.attributes[sortIndex2] === null) {
        result = 1;
      }
      if (b.attributes[sortIndex2] === null) {
        result = -1;
      }
    } else {
      if (a.attributes[sortIndex1] < b.attributes[sortIndex1]) { result = switchOrder ? -1 : 1; }
      if (a.attributes[sortIndex1] > b.attributes[sortIndex1]) { result = switchOrder ? 1 : -1; }
      if (a.attributes[sortIndex1] === null) {
        result = 1;
      }
      if (b.attributes[sortIndex1] === null) {
        result = -1;
      }
    }
    return result
  })
}

export const roleCheck = (systemRole, currentTeamRole, cat) => {
  if (["admin", "super_admin"].includes(systemRole)) {
    return true
  }
  return currentTeamRole?.includes(cat)
}

export const generalRoleCheck = (systemRole, currentTeamRole, cat) => {
  if (["admin", "super_admin"].includes(systemRole)) {
    return true
  }
  return currentTeamRole?.some(teamRole => teamRole.includes(cat))
}

export const checkEmail = email => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export const preventNegativeValues = e => ["e", "E", "-"].includes(e.key) && e.preventDefault()

export const isTouchDevice = () => ("ontouchstart" in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)

export const isMobileDevice = () => {
  if (!isTouchDevice()) return false;

  const result = /Mobi|iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || window.innerWidth <= 767;
  return result
}

export function DeepLinker(options) {
  if (!options) {
    throw new Error("no options")
  }

  let hasFocus = true;
  let didHide = false;
  let clicked = false;

  const onBlur = () => { // window is blurred when dialogs are shown
    hasFocus = false;
  }
  // document is hidden when native app is shown or browser is backgrounded
  const onVisibilityChange = e => {
    if (e.target.visibilityState === "hidden" || document.hidden || document.visibilityState === "hidden") {
      didHide = true;
      clicked = false;
    } else if (document.visibilityState === "visible") { // User just return to the browser
      if (didHide && options.onReturn) options.onReturn();
      didHide = false;
      clicked = false;
      hasFocus = true;
    }
  }
  // window is focused when dialogs are hidden, or browser comes into view
  const onFocus = () => {
    if (!didHide && !hasFocus && options.onFallback) {
      // wait for app switch transition to fully complete - only then is 'visibilitychange' fired
      setTimeout(() => {
        if (!didHide && clicked) { // if browser was not hidden, the deep link failed
          options.onFallback();
          clicked = false;
        }
      }, 1000);
    }

    hasFocus = true;
  }

  const bindEvents = mode => {
    const trackingEvents = [
      { obj: window, name: "blur", handler: onBlur },
      { obj: document, name: "visibilitychange", handler: onVisibilityChange },
      { obj: window, name: "focus", handler: onFocus }
    ]

    trackingEvents.forEach(config => {
      config.obj[`${mode}EventListener`](config.name, config.handler);
    });
  }

  bindEvents("add"); // add event listeners on initial
  this.destroy = bindEvents.bind(null, "remove"); // call the .destroy to remove events as need

  this.openURL = url => {
    const dialogTimeout = 500; // it can take a while for the dialog to appear
    clicked = true;
    hasFocus = true;
    didHide = false;

    setTimeout(() => {
      // Window is not blured, it mean do not have any dialog/alert so call ignore flow
      if (hasFocus && options.onIgnored && clicked) {
        options.onIgnored();
        clicked = false;
      }
    }, dialogTimeout);

    window.location.href = url;
  }
}

export const checkValidUrl = url => {
  if (!url || url === "") {
    return false
  }
  const urlRegex = /^(http|https):\/\/[^ "]+$/;
  return urlRegex.test(url);
}

export const addPayloadToFormData = (formData, payload) => {
  Object.entries(payload).forEach(([key, value]) => {
      if (Array.isArray(value)) {
          value.forEach(item => formData.append(key, item));
      } else {
          formData.append(key, value);
      }
  });
  return formData;
}

export const draggableMoveAnItem = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
// #endregion ==================================================
