import React, { useState } from "react"

import cx from "classnames"
import { differenceInCalendarDays, format } from "date-fns"
import SweetAlert from "react-bootstrap-sweetalert"
import { Link, useParams } from "react-router-dom"
import { capitalizeString } from "utils"

const daysAgo = updatedTime => {
  const days = differenceInCalendarDays(new Date(), new Date(updatedTime))
  if (days === 0) return "Last updated today"
  if (days === 1) return "Last updated yesterday"
  if (days > 1) return `Last updated ${days} days ago`
}
const daysAhead = bookingTime => differenceInCalendarDays(new Date(bookingTime), new Date())

export default function Row({ attributes, jobServiceId }) {
  const states = ["To Confirm", "Survey", "Drafting", "Final Review", "Completed"]
  const {
    bookingTime,
    state,
    updatedAt,
    jobAddress,
    label
  } = attributes

  const currentState = capitalizeString(state)
  const notBooked = !states.includes(currentState) || !bookingTime

  const getlabelNumber = label => label.split("-")[0].trim()
  const [warningOpen, setWarningOpen] = useState(false)
  const [warningTitle, setWarningTitle] = useState("")
  return (
    <>
      <div className={cx("card card-jobs d-md-flex align-items-md-center justify-content-between shadow-sm", { "card-job-danger": notBooked })}>
        <div className="d-lg-flex align-items-center justify-content-between job-summary">
          <div className="job-name">
            <strong>{jobAddress}</strong>
            <div>{getlabelNumber(label)}</div>
          </div>
          <div className="job-status-progress align-items-center">
            <button className="d-md-none status-item status-item-single d-inline bg-green" data-target="#statusModal" data-toggle="modal" type="button">
              <h3 className="mb-0">Booked</h3>
            </button>
            <div className="d-none d-md-block">
              <CustomerState states={states} currentState={notBooked ? "Not Booked" : currentState} />
            </div>
            <div className="pt-sm-1 pb-sm-2">
              <small className="text-muted">{daysAgo(updatedAt)}</small>
            </div>
          </div>
        </div>
        <div className="job-cta">
          {notBooked && <BookTimeslot jobServiceId={jobServiceId} state={state} setWarningOpen={setWarningOpen} setWarningTitle={setWarningTitle} />}
          {!notBooked && currentState === "To Confirm" && <BookedOn bookingTime={bookingTime} jobServiceId={jobServiceId} setWarningOpen={setWarningOpen} setWarningTitle={setWarningTitle} />}
          {!notBooked && ["Survey", "Drafting", "Final Review"].includes(currentState) && <RateSurveyor />}
          {!notBooked && currentState === "Completed" && <PaymentRequired />}
        </div>
      </div>
      <SweetAlert
        show={warningOpen}
        type="error"
        title={warningTitle}
        closeOnClickOutside
        allowEscape={false}
        onConfirm={() => setWarningOpen(false)} />
    </>
  )
}

// show the progress of the job with 5 states
const CustomerState = ({ states, currentState }) => {
  const completedStateIndex = states.indexOf(currentState)
  const completedStates = states.slice(0, completedStateIndex + 1)

  return (
    <div className="d-flex flex-column flex-sm-row">
      {
        states.map((state, index) => {
          if (index === 0 && completedStateIndex === -1) {
            return <div className="status-item bg-red" key={`state-${index}-NotBooked}`}>Not Booked</div>
          }
            return (
              <div className={cx("status-item", { "bg-green": completedStates.includes(state) })} key={`state-${index}-${state}`}>{state}</div>
            )
        })
      }
    </div>
  )
}

// optional components for right side of the row
const BookTimeslot = ({
 jobServiceId, state, setWarningOpen, setWarningTitle
}) => {
  const { secureToken } = useParams()
  const handler = e => {
    if (state !== "to_book" || state !== "to_confirm") {
      e.preventDefault()
      setWarningTitle("Payment is required before the job can be booked.")
      setWarningOpen(true)
    }
  }
  return (
    <Link
      to={`/customer-portal/${secureToken}/booking/jobs/${jobServiceId}`}
      className="btn btn-outline-danger btn-sm mr-2 mr-sm-0"
      onClick={e => handler(e)}>
      Book Timeslot
    </Link>
  )
}
const BookedOn = ({
 bookingTime, jobServiceId, setWarningOpen, setWarningTitle
}) => {
  const { secureToken } = useParams()

  const dateFormatting = date => format(date, "dd MMM, yyyy hh:mmb").replace("AM", "am").replace("PM", "pm")
  const handler = e => {
    if (daysAhead(bookingTime) <= 2) {
      e.preventDefault()
      setWarningTitle("You can't change the booking which is in the next 2 days")
      setWarningOpen(true)
    }
  }
  return (
    <div className="px-3">
      Booked on
      {" "}
      <br />
      <strong>
        {" "}
        {dateFormatting(new Date(bookingTime))}
        {" "}
      </strong>
      <Link to={`/customer-portal/${secureToken}/booking/jobs/${jobServiceId}`} onClick={e => handler(e)}>
        <u style={{ color: "#d12628" }}>Edit</u>
      </Link>
    </div>
  )
}

const RateSurveyor = () => (
  <Link to="/customer-portal/rate-surveyor/users/" className="btn btn-outline-danger btn-sm mr-2 mr-sm-0">
    Rate Surveyors
  </Link>
  )
const PaymentRequired = () => (
  <div style={{ color: "#d12628" }}>
    Payment Required
  </div>
  )
