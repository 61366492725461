/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
import { format } from "date-fns";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { postDocket, sendEmailToClient, updateDocket } from "requesters/docketRequester";
import { updateJobServiceTransition, updateJobService } from "requesters/jobServiceRequester";

import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import {
  DATA_OVERDUE,
  CONFIRMED,
  DOCKET_STATUS,
  FIELD_WORK_FILES,
  FIELD_WORK_STATE,
  CHECK_SURVEY,
  GPS_AHD,
  GPS_MGA,
  GPS_APPROVED,
  GPS_DECLINED,
  PROCESSING_STATES,
  OPERATION_MANAGER,
  OPERATIONS,
  PROJECT_SETOUT,
  SURVEYORS,
  SURVEYORS_USER,
  REQUEST_CADASTRAL,
  REQUESTED_CADASTRAL,
  CADASTRAL_DECLINED,
} from "utils/constants";
import { roleCheck } from "utils/index";
import AiOutlineInfoCircleCustom from "../../../../components/AiOutlineInfoCircleCustom";
import { getBaseUrl, getCurrentRole } from "../../../../reducers";

import UploadPhotosModal from "../../modals/UploadPhotosModal";
import ShowChangeBookingDate from "./ShowChangeBookingDate";

import SendCopyToClientModal from "../../../Dockets/SendCopyToClientModal";
import AddFilesModal from "../../modals/AddFilesModal";
import AssignSurveyorModal from "../../modals/AssignSurveyorModal";
import CheckOutModal from "../../modals/CheckOutModal";
import ConfirmationModal from "../../modals/ConfirmationModal";
import RequestGpsModal from "../../modals/RequestGpsModal";
import CommentBox from "./CommentBox";
import GpsActivity from "./GpsActivity";
import CadastralRequestActivity from "./CadastralRequestActivity";
import OpenInAppButton from "../../../../../../components/OpenInAppButton";
import { isMobileDevice } from "../../../../../../utils";
import CollectPaymentOnsiteCollectedModal from "../../modals/CollectPaymentOnsiteCollectedModal";
import AddUsedPlansForCalcModal from "../../modals/AddUsedPlansForCalcModal";
import { getScanningGuide } from "../../../../../../requesters/appConfigRequester";

const EditBookingDateHandler = (jobService, reloadData, setShowBookingDate, systemRole, currentTeamRole, scheduledAssignment) => (
  <>
    <div className="col-sm-6">
      <div className="activity timeline-activity">
        <div className="d-flex justify-content-between">
          <div className="activity-status">
            <strong>Booking Date</strong>
          </div>
        </div>
        <div className="ml-2">
          <ShowChangeBookingDate
            jobService={jobService}
            reloadData={reloadData}
            systemRole={systemRole}
            scheduledAssignment={scheduledAssignment}
            currentTeamRole={currentTeamRole} />
        </div>
      </div>
    </div>
    <div className="col-sm-6 text-right">
      <button className="btn btn-link" type="button" onClick={() => setShowBookingDate(false)}>Cancel</button>
    </div>
  </>
)

const BookingConfirmed = ({
  jobService,
  reloadData,
  sent,
  currentUser,
  systemRole,
  currentTeamRole,
  scheduledAssignment,
  comment,
  setComment,
  showActionButtons,
  showRequestGps
}) => {
  const {
    id,
    attributes: {
      // mandatoryFilesUploaded,
      state,
      linkedJobServiceId,
      code,
      is3D,
      gpsRequestComment,
      uploadedPhotos,
      jobPackUploaded,
      workflow,
      collectPaymentOnsiteRequested,
      collectPaymentOnsiteRequestedAmount,
      collectPaymentOnsiteCollected,
      bookedInForJobServiceId,
      isBookInCheckSurvey,
      requireSelectPlansUsedForCalculations,
      plansUsedForCalculationsSelected,
      jobNo,
      forProjectX,
      missingCadastralSurveyor,
      projectXJobNo,
      jobTitle,
      requireScimsFile,
      contactsWithEmail
    }
  } = jobService
  const displayJobNo = forProjectX ? projectXJobNo : jobNo

  const {
    assignments, scheduledAt, anySurveyorCheckedIn, dockets, productName, assignmentsWithSameTime
  } = scheduledAssignment

  const assignment = assignments.find(a => a.surveyorId === Number(currentUser?.id))
    || assignments.find(a => a.surveyorId === Number(jobService?.attributes?.primarySurveyorId)) || {};

  const {
    canCheckIn, checkedInAt, checkedOutAt, uploadedCheckInPhoto, surveyorAssignmentId, surveyorTypeDisplay, surveyorId,
    uploadedMandatoryFiles, role, uploadedRequiredFieldWorkFiles, collectPaymentOnsiteCollectedAmount
  } = assignment
  const history = useHistory();
  const baseUrl = useSelector(getBaseUrl)
  const [uploadPhotoChecked, setUploadPhotoChecked] = useState(!!uploadedPhotos?.length)
  // const [uploadChecked, setUploadChecked] = useState(mandatoryFilesUploaded)
  const currentRole = useSelector(getCurrentRole)

  const [readyToSend, setReadyToSend] = useState(false)

  const [scanningGuideUrl, setScanningGuideUrl] = useState("")
  const [processingRequired, setProcessingRequired] = useState(false)
  const [draftingRequired, setDraftingRequired] = useState(false)
  const [regSurveyorRequired, setRegSurveyorRequired] = useState(false)
  const [resolveBookedInJob, setResolveBookedInJob] = useState(false)

  const [showUploadPhotosModal, setShowUploadPhotosModal] = useState(false)
  const [showBookingDate, setShowBookingDate] = useState(false)

  const [showUploadFilesModal, setShowUploadFilesModal] = useState(false)
  const [showAssignModal, setShowAssignModal] = useState(false)
  const [showGpsModal, setShowGpsModal] = useState(false)
  const [showCheckOutModal, setShowCheckOutModal] = useState(false)
  const [checkoutSurveyorAssignmentId, setCheckoutSurveyorAssignmentId] = useState(false)
  const [invalidCheckOutMessage, setInvalidCheckOutMessage] = useState("")
  const [showError, setShowError] = useState(false)
  const [showProjectSetoutAlert, setShowProjectSetoutAlert] = useState(false)
  const [primarySurveyorRequired, setPrimarySurveyorRequired] = useState(false)
  const [docketConfirmModalSelected, setDocketConfirmModalSelected] = useState(null)
  const [docketSubmitModalSelected, setDocketSubmitModalSelected] = useState(null)
  const [docketSendToClientModalSelected, setDocketSendToClientModalSelected] = useState(null)
  const [showSendToClientModal, setShowSendToClientModal] = useState(false)
  const [showCollectPaymentOnsiteCollectedModal, setShowCollectPaymentOnsiteCollectedModal] = useState(false)
  const [showAddUsedPlansForCalcModal, setShowAddUsedPlansForCalcModal] = useState(false)

  const colorGrey = { color: "grey" }
  const colorGreen = { color: "green" }

  const isAdminOrOperations = roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER)
  const isSurveyor = roleCheck(systemRole, currentRole, SURVEYORS)
  const isDataOverdue = state === DATA_OVERDUE
  const [checkInLoading, setCheckInLoading] = useState(false)
  const [unConfirmLoading, setUnConfirmLoading] = useState(false)
  const [sendToLoading, setSendToLoading] = useState(false)

  const btnOutline = `btn-outline-${isAdminOrOperations || uploadedMandatoryFiles ? "primary" : "secondary"}`
  const btnOutlineSendToDrafting = `btn-outline-${isAdminOrOperations || uploadedRequiredFieldWorkFiles ? "primary" : "secondary"}`
  // const btnOutlineGPS = `btn-outline-${uploadedMandatoryFiles ? "primary" : "secondary"}`
  const btnPrimary = "btn-primary"

  const currentUserAssigned = assignment.surveyorAssignmentId
  const absoluteRoleCheck = currentUserAssigned || roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER)
  const toastOptions = {
    toastId: "container-inner",
    containerId: "main"
  }

  const isGpsAhd = (code === GPS_AHD)
  const isGpsMga = (code === GPS_MGA)
  const isProjectSetout = (code === PROJECT_SETOUT)
  // const isCheckSurvey = code === CHECK_SURVEY

  const needToSelectUsedPlansCalculations = requireSelectPlansUsedForCalculations && !plansUsedForCalculationsSelected
  const isMobile = isMobileDevice()
  const isScannerSurveyor = isSurveyor && currentUser?.attributes.surveyor_type === "Scanner"
  const allowedToRequestCadastral = (surveyorId === Number(currentUser?.id) && role === "primary" && isScannerSurveyor) || isAdminOrOperations
  const needToShowScanningGuide = forProjectX && (isScannerSurveyor || isAdminOrOperations)

  const fetchScanningGuide = () => {
    getScanningGuide(baseUrl)
    .then(res => {
      if (res.status === 200 && res.response?.url) {
        setScanningGuideUrl(res.response.url)
      }
    })
    .catch(err => console.log(err))
  }

  useEffect(() => {
    fetchScanningGuide()
  }, [])

  const onBookInJobComplete = bookInJobType => {
    const validTypes = ["gps", "check-survey"]
    if (!validTypes.includes(bookInJobType?.toLowerCase())) return;

    setSendToLoading(true)
    updateJobServiceTransition(baseUrl, id, `complete-${bookInJobType}`, comment)
      .then(res => {
        if (res.status === 200) {
          window.location.reload()
        } else {
          setSendToLoading(false)
        }
      })
      .catch(() => {
        setSendToLoading(false)
      })
  }

  const jobCheckInHandler = () => {
    setCheckInLoading(true)
    updateJobServiceTransition(baseUrl, id, "check-in", comment, surveyorAssignmentId)
      .then(res => {
        if (res.status === 200) {
          reloadData()
        } else {
          setCheckInLoading(false)
          toast.error(
            <div>
              <span><i className="fa fa-exclamation mr-2" /></span>
              <span className="ml-2">{res?.error?.response?.data?.errors[0]?.detail}</span>
            </div>,
            toastOptions
          )
        }
      })
      .catch(() => {
        setCheckInLoading(false)
      })
  }

  const jobCheckOutHandler = () => {
    if (role === "assistant") {
      updateJobServiceTransition(baseUrl, id, "check-out", comment, surveyorAssignmentId)
        .then(res => {
          if (res.status === 200) {
            reloadData()
          }
        })
        // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    } else {
      setShowCheckOutModal(true)
    }
  }

  const addDocketHandler = () => {
    // eslint-disable-next-line no-underscore-dangle
    let _surveyorAssignmentId = surveyorAssignmentId
    if (isSurveyor) {
      const assignmentOfCurrentSurveyor = assignments.find(a => a.surveyorId === Number(currentUser.id));
      if (assignmentOfCurrentSurveyor) {
        _surveyorAssignmentId = assignmentOfCurrentSurveyor.surveyorAssignmentId
      }
    }
    postDocket(baseUrl, { jobServiceId: jobService.id, surveyorAssignmentId: _surveyorAssignmentId })
      .then(({ response, status }) => {
        if (status === 201) {
          history.push(`/dockets/${response.data.id}`);
        }
      })
      .catch(err => console.log(err))
  }

  async function handleSendToClient(clientEmails, docketId) {
    await sendEmailToClient(baseUrl, docketId, { client_emails: clientEmails })
    reloadData()
  }

  const handleInvalidCheckOut = () => {
    if (state === "gps_requested") {
      setInvalidCheckOutMessage("Awaiting GPS Job Approval")
      setShowError(true)
    } else if (state === REQUESTED_CADASTRAL) {
      setInvalidCheckOutMessage("Awaiting Cadastral Request Approval")
      setShowError(true)
    } else if (!uploadedCheckInPhoto && !isGpsAhd && !isGpsMga) {
      setInvalidCheckOutMessage("Please upload a photo")
      setShowError(true)
    } else if (collectPaymentOnsiteRequested && !collectPaymentOnsiteCollected) {
      setInvalidCheckOutMessage("Please submit a collected payment onsite amount")
      setShowError(true)
    } else if (isProjectSetout) {
      setShowProjectSetoutAlert(true)
    }
  }

  const handleCheckOutLogic = () => {
    const completedCollectPaymentOnsiteFlow = !collectPaymentOnsiteRequested || collectPaymentOnsiteCollected

    if ([GPS_APPROVED, GPS_DECLINED, CADASTRAL_DECLINED, CONFIRMED, FIELD_WORK_STATE, "field_work_ongoing"].includes(state) && (uploadedCheckInPhoto || role === "assistant" || isGpsAhd) && !isProjectSetout && completedCollectPaymentOnsiteFlow) {
      jobCheckOutHandler()
    } else {
      handleInvalidCheckOut()
    }
  }

  const sendToProcessing = () => {
    setSendToLoading(true)
    updateJobServiceTransition(baseUrl, id, "move-to-processing", comment)
    .then(res => {
      if (res.status === 200) {
        window.location.reload()
      }
    })
    .catch(err => console.log(err))
    .finally(() => setSendToLoading(false))
  }

  const sendToDrafting = () => {
    setSendToLoading(true)
    updateJobServiceTransition(baseUrl, id, "move-to-drafting-manager", comment)
      .then(res => {
        if (res.status === 200) {
          window.location.reload()
        } else {
          setSendToLoading(false)
        }
      })
      .catch(() => {
        setSendToLoading(false)
      })
  }

  const sendBookedInJobHandler = () => {
    setSendToLoading(true)
    updateJobServiceTransition(baseUrl, id, "resolve-booked-in-job-service", comment)
      .then(res => {
        if (res.status === 200) {
          window.location.reload()
        } else {
          setSendToLoading(false)
        }
      })
      .catch(() => {
        setSendToLoading(false)
      })
  }

  const sendToRegSurveyor = () => {
    setSendToLoading(true)
    updateJobServiceTransition(baseUrl, id, "move-to-registered-surveyor", comment)
      .then(res => {
        if (res.status === 200) {
          window.location.reload()
        } else {
          setSendToLoading(false)
          toast.error(
            <div>
              <span><i className="fa fa-exclamation mr-2" /></span>
              <span className="ml-2">{res?.error?.response?.data?.errors[0]?.detail}</span>
            </div>,
            toastOptions
          )
        }
      })
      .catch(() => {
        setSendToLoading(false)
      })
  }

  const sendToReadyToSend = () => {
      let transition = "ready-to-send"
  
      updateJobServiceTransition(baseUrl, id, transition, comment)
        .then(res => {
          if (res.status === 200) {
            window.location.reload()
          }
        })
        // eslint-disable-next-line no-console
        .catch(err => console.log(err))
  }

  const processingRequiredHandler = () => {
    setProcessingRequired(true)
  }

  const draftingRequiredHandler = () => {
    setDraftingRequired(true)
    // setRegSurveyorRequired(false)
  }

  const resolveBookedInJobHandler = () => {
    setResolveBookedInJob(!resolveBookedInJob)
  }

  const regSurveyorRequiredHandler = () => {
    setRegSurveyorRequired(true)
    // setDraftingRequired(false)
  }

  const handleUnconfirmed = () => {
    setUnConfirmLoading(true)
    updateJobServiceTransition(baseUrl, id, "unconfirmed-booking")
      .then(res => {
        if (res.status === 200) {
          reloadData()
        } else {
          setUnConfirmLoading(false)
          toast.error(
            <div>
              <span><i className="fa fa-exclamation mr-2" /></span>
              <span className="ml-2">Cannot unconfirmed booking </span>
            </div>,
            {
              toastId: "rails-flash",
              containerId: "main"
            }
          )
        }
      })
      .catch(() => {
        setUnConfirmLoading(false)
      })
  }

  // const sendFieldWorkHandler = () => {
  //   if (draftingRequired) {
  //     sendToDrafting()
  //   }
  //   if (regSurveyorRequired) {
  //     sendToRegSurveyor()
  //   }
  // }

  const formatFullDate = date => {
    try {
      return date ? format(new Date(date), "dd/MM/yy hh:mm aaa") : ""
    } catch (error) {
      console.log("error", error)
      return ""
    }
  }
  // Helper function to format date for input fields
  const formatDateForInput = dateString => (dateString ? moment(dateString).format("YYYY-MM-DDTHH:mm") : "");

  const updateJobHandler = async params => {
    try {
      const res = await updateJobService(baseUrl, id, params);
      if (res.status === 200) reloadData();
    } catch (err) {
      console.error(err);
    }
  };

  const [isEditingCheckIn, setIsEditingCheckIn] = useState(false);
  const [isEditingCheckOut, setIsEditingCheckOut] = useState(false);
  const [checkInDate, setCheckInDate] = useState(formatDateForInput(checkedInAt));
  const [checkOutDate, setCheckOutDate] = useState(formatDateForInput(checkedOutAt));

  useEffect(() => {
    setCheckInDate(formatDateForInput(checkedInAt));
    setCheckOutDate(formatDateForInput(checkedOutAt));
  }, [checkedInAt, checkedOutAt]);


  const handleCheckInChange = e => {
    setCheckInDate(e.target.value);
  };

  const handleCheckOutChange = e => {
    setCheckOutDate(e.target.value);
  };

  const saveDate = (date, field, setter) => {
    if (!date) return;
    const formattedDate = moment(date).format("ddd, DD MMM YYYY HH:mm:ss ZZ");
    updateJobHandler({ [field]: formattedDate });
    setter(false);
  };

  const saveCheckInDate = () => saveDate(checkInDate, "checked_in_at", setIsEditingCheckIn);
  const saveCheckOutDate = () => saveDate(checkOutDate, "checked_out_at", setIsEditingCheckOut);

  const handleRequestCadastral = () => {
    updateJobServiceTransition(baseUrl, id, REQUEST_CADASTRAL)
      .then(res => {
        if (res.status === 200) {
          window.location.reload()
        }
      })
  }

  const generateCardHeader = () => {
    if (showBookingDate) {
      return (EditBookingDateHandler(jobService, reloadData, setShowBookingDate, systemRole, currentTeamRole, scheduledAssignment))
    }

    if (checkedInAt) {
      return (
        <>
          <div className="col-sm-6">
            <p className="mb-0">
              {isEditingCheckIn ? (
                <div className="row">
                  <div className="col-sm-7">
                    <input
                      type="datetime-local"
                      className="form-control"
                      value={checkInDate}
                      onChange={handleCheckInChange} />
                  </div>
                  <div className="col-sm-5">
                    <button type="button" onClick={saveCheckInDate} className="btn btn-link">Save</button>
                    <button type="button" onClick={() => setIsEditingCheckIn(false)} className="btn btn-link">Cancel</button>
                  </div>
                </div>
              ) : (
                <>
                  {`Checked in: ${formatFullDate(checkInDate)}`}
                  {isAdminOrOperations && (
                    <button type="button" onClick={() => setIsEditingCheckIn(true)} className="btn btn-link lh-1">(edit)</button>
                  )}
                </>
              )}
            </p>

            <p className="mb-0">
              {!checkedOutAt ? (
                "Checked out: In Progress"
              ) : (
                isEditingCheckOut ? (
                  <div className="row">
                    <div className="col-sm-7">
                      <input
                        type="datetime-local"
                        className="form-control"
                        value={checkOutDate}
                        onChange={handleCheckOutChange} />
                    </div>
                    <div className="col-sm-5">
                      <button onClick={saveCheckOutDate} className="btn btn-link">Save</button>
                      <button onClick={() => setIsEditingCheckOut(false)} className="btn btn-link">Cancel</button>
                    </div>
                  </div>
                ) : (
                  <>
                    {`Checked out: ${formatFullDate(checkOutDate)}`}
                    {isAdminOrOperations && (
                      <button type="button" onClick={() => setIsEditingCheckOut(true)} className="btn btn-link lh-1">(edit)</button>
                    )}
                  </>
                )
              )}
            </p>
          </div>
          <div className="col-sm-6 text-right">
            { isMobile && (
              <OpenInAppButton
                btnText="Open in App"
                classNames="btn-outlined-blue hide-on-desktop mx-2"
                appDeepLink={`surveyors://jobService/${id}/${surveyorAssignmentId}`} />
            )}
            { forProjectX && missingCadastralSurveyor && allowedToRequestCadastral && !checkedOutAt && state === "field_work_ongoing" && (
              <button
                className="btn btn-outlined-blue"
                type="button"
                onClick={handleRequestCadastral}>
                Request Cadastral
              </button>
            )}
            {!currentTeamRole.includes(SURVEYORS_USER) && (
              <React.Fragment>
                {(
                  (absoluteRoleCheck && role === "primary") && showRequestGps && !checkedOutAt && state === "field_work_ongoing"
                  && !isGpsAhd && !isGpsMga && !linkedJobServiceId && !forProjectX
                ) && (
                  <>
                    <button
                      className="btn btn-outlined-blue"
                      type="button"
                      onClick={() => setShowGpsModal(true)}>
                      Request GPS
                    </button>
                    <RequestGpsModal
                      jobService={jobService}
                      reloadData={reloadData}
                      show={showGpsModal}
                      onHide={() => { setShowGpsModal(false) }} />
                  </>
                )}

                <button className="btn btn-outlined-blue ml-2" type="button" onClick={addDocketHandler}>+ Docket</button>
                {!checkedOutAt && (
                  <>
                    <button
                      className="btn btn-red mx-2"
                      type="button"
                      onClick={() => handleCheckOutLogic()}>
                      Check Out
                    </button>
                  </>
                )}
              </React.Fragment>
            )}
          </div>
          <SweetAlert
            show={showError}
            type="error"
            title="Cannot Check Out!"
            style={{ width: "600px" }}
            closeOnClickOutside
            allowEscape={false}
            onConfirm={() => setShowError(false)}>
            {invalidCheckOutMessage}
          </SweetAlert>
          <SweetAlert
            custom
            show={showProjectSetoutAlert}
            type={undefined}
            title={<span style={{ fontSize: "22px", fontWeight: "normal" }}>Please Confirm you have completed any Required Dockets for this Service</span>}
            style={{ width: "600px" }}
            closeOnClickOutside
            allowEscape={false}
            onCancel={() => setShowProjectSetoutAlert(false)}
            confirmBtnText="Confirm"
            cancelBtnText="Back"
            cancelBtnCssClass="btn btn-red"
            customIcon={(
              <div>
                <AiOutlineInfoCircleCustom />
              </div>
            )}
            showCancel
            onConfirm={() => {
              setShowProjectSetoutAlert(false)
              jobCheckOutHandler()
            }}>
          </SweetAlert>
        </>
      )
    }

    return (
      <>
        <div className="col-sm-6">
          <h6>{format(new Date(scheduledAt), "dd MMM yyyy, EEEE h:mm aa")}</h6>
          {assignment.startAt && assignment.endAt && role !== "primary" && (
            <p>
              {`Schedule: ${format(new Date(assignment.startAt), "haa")} - ${format(new Date(assignment.endAt), "haa")}`}
            </p>
          )}
          <span className="text-capitalize">{state.split("_").join(" ")}</span>
        </div>
        {absoluteRoleCheck && (
          <div className="col-sm-6 text-right">
            {(canCheckIn && state !== "to_confirm" && (jobPackUploaded || isGpsAhd || isGpsMga) && currentUserAssigned && state !== "gps_requested" && isMobile) && (
              <OpenInAppButton
                btnText="Open in App"
                classNames="btn-outlined-blue hide-on-desktop mx-2"
                appDeepLink={`surveyors://jobService/${id}/${surveyorAssignmentId}`} />
            )}
            {(roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) && !anySurveyorCheckedIn) && (<button className="btn btn-link" type="button" onClick={() => setShowBookingDate(true)}>Edit</button>)}
            {!currentTeamRole.includes(SURVEYORS_USER) && (
              canCheckIn && state !== "to_confirm" && (jobPackUploaded || isGpsAhd || isGpsMga) && currentUserAssigned && state !== "gps_requested" && (
                <button disabled={checkInLoading} className="btn btn-red mx-2" type="button" onClick={jobCheckInHandler}>
                  Check in
                  {checkInLoading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
                </button>
              )
            )}
          </div>
        )}
      </>
    )
  }

  const userInitColorRendering = role => {
    switch (role) {
      case "primary":
        return "user-init bg-red"
      case "secondary":
        return "user-init bg-blue"
      default:
        return "user-init"
    }
  }

  const handleOpenCheckOutModal = surveyorAssignmentId => {
    setCheckoutSurveyorAssignmentId(surveyorAssignmentId)
    setShowAssignModal(false)
    setShowCheckOutModal(true)
  }

  const getFieldWorkFileCategories = () => {
    let categories = FIELD_WORK_FILES

    if (is3D) {
      categories = categories.map(file => {
        if (file.value === "Magnet File") {
          return file;
        }
        return { ...file, label: file.label.replaceAll("*", "") }
      })
    }
    categories = categories.map(file => {
      if (file.value === "SCIMS" && requireScimsFile) return { ...file, label: "SCIMS*" }
      if (file.value === "Field Notes" && (isGpsAhd || isGpsMga)) return { ...file, label: "Field Notes" }
      if (isScannerSurveyor && file.value === "Raw Data") return { ...file, label: "Raw Data*" }
      return file
    })

    return categories
  }

  return (
    <>
      <div className="timeline-item mb-3">
        {sent ? (
          <div>
            <p className="mb-0">{`Checked in: ${formatFullDate(checkedInAt)}`}</p>
            <p className="mb-0">{`Checked out: ${formatFullDate(checkedOutAt)}`}</p>
          </div>
        ) : (
          <div className="card border-0 shadow-sm mt-3">
            <div className="card-body p-4">
              <div className="row mb-4">
                {generateCardHeader()}
              </div>

              <div className="row justify-content-between">
                <div className="d-flex">
                  {roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) && (
                    <button
                      onClick={() => setShowAssignModal(true)}
                      className="btn btn-outlined-blue ml-3"
                      type="button">
                      Manage
                    </button>
                  )}

                  <div className="d-flex align-items-center ml-3">
                    {scheduledAssignment?.assignments?.length > 0 && scheduledAssignment.assignments.map(ass => (
                      <div className={userInitColorRendering(ass.role)} key={ass?.surveyorInitials + ass?.startAt}>{ass?.surveyorInitials}</div>
                    ))}
                  </div>
                </div>

                {roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) && state === "confirmed" && showBookingDate && (
                  <button
                    disabled={unConfirmLoading}
                    onClick={handleUnconfirmed}
                    className="btn btn-outlined-blue mr-3"
                    type="button">
                    Unconfirm
                    {unConfirmLoading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
                  </button>
                )}
              </div>

              <AssignSurveyorModal
                jobService={jobService}
                reloadData={reloadData}
                show={showAssignModal}
                handleOpenCheckOutModal={handleOpenCheckOutModal}
                scheduledAssignment={scheduledAssignment}
                userInitColorRendering={userInitColorRendering}
                onHide={() => { setShowAssignModal(false) }} />

              {(!!checkedInAt && absoluteRoleCheck && role !== "assistant") && (
                <div className=" p-4">
                  { needToShowScanningGuide && (
                    <div className="row py-3 border-top align-items-center">
                      <div className="col-sm-1"></div>
                      <div className="col-sm-2">
                        Scanning Guide
                      </div>
                      <div className="col-sm-9 text-right">
                        <a
                          className="btn btn-outlined-blue m-1"
                          href={scanningGuideUrl}
                          rel="noreferrer"
                          target="_blank">
                          View
                        </a>
                      </div>
                    </div>
                  )}

                  <div className="row py-3 border-top align-items-center">
                    <div className="col-sm-1">
                      <span className="bi bi-check-circle-fill" style={uploadedCheckInPhoto ? colorGreen : colorGrey}></span>
                    </div>
                    <div className="col-sm-2">
                      Uploaded Photos
                    </div>
                    <div className="col-sm-9 text-right">
                      <button
                        className="btn btn-outlined-blue m-1"
                        type="button"
                        onClick={() => setShowUploadPhotosModal(true)}>
                        Upload
                      </button>
                      <UploadPhotosModal
                        jobService={jobService}
                        reloadData={reloadData}
                        assignment={assignment}
                        setUploadChecked={setUploadPhotoChecked}
                        show={showUploadPhotosModal}
                        onHide={() => { setShowUploadPhotosModal(false) }} />
                    </div>
                  </div>

                  { collectPaymentOnsiteRequested && collectPaymentOnsiteRequestedAmount && (
                    <div className="row py-3 border-top align-items-center">
                      <div className="col-sm-1">
                        <span className="bi bi-check-circle-fill" style={collectPaymentOnsiteCollected ? colorGreen : colorGrey}></span>
                      </div>
                      <div className="col-sm-7">
                        Collect Payment Onsite - $
                        {collectPaymentOnsiteRequestedAmount}
                      </div>
                      <div className="col-sm-4 text-right">
                        {/* Temporary not show as text, just show disable button */}
                        {/* eslint-disable-next-line no-constant-condition */}
                        { collectPaymentOnsiteCollected && collectPaymentOnsiteCollectedAmount && false ? (
                          <span>
                            Surveyor Collected $
                            {collectPaymentOnsiteCollectedAmount}
                          </span>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-outlined-blue m-1"
                            disabled={collectPaymentOnsiteCollected}
                            onClick={() => setShowCollectPaymentOnsiteCollectedModal(true)}>
                            Collected?
                          </button>
                        )}
                        <CollectPaymentOnsiteCollectedModal
                          jobService={jobService}
                          reloadData={reloadData}
                          assignment={assignment}
                          show={showCollectPaymentOnsiteCollectedModal}
                          onHide={() => { setShowCollectPaymentOnsiteCollectedModal(false) }} />
                      </div>
                    </div>
                  )}

                  <div className="row py-3 border-top align-items-center">
                    <div className="col-sm-1">
                      <span className="bi bi-check-circle-fill" style={uploadedRequiredFieldWorkFiles ? colorGreen : colorGrey}></span>
                    </div>
                    <div className="col-sm-4">
                      Uploaded Files
                    </div>
                    <div className="col-sm-7 text-right">
                      <button
                        className="btn btn-outlined-blue m-1"
                        type="button"
                        onClick={() => {
                          setShowUploadFilesModal(true)
                        }}>
                        Upload
                      </button>
                      <AddFilesModal
                        showUploadedFilesSection
                        uploadedFilesSectionSize="full"
                        uploadedFilesSource={jobService.attributes?.surveyorFiles}
                        fileCategories={getFieldWorkFileCategories()}
                        jobService={jobService}
                        show={showUploadFilesModal}
                        reloadData={reloadData}
                        assignment={assignment}
                        onHide={() => { setShowUploadFilesModal(false) }} />
                    </div>
                  </div>

                  {(isSurveyor || isAdminOrOperations) && (
                    <div className="row py-3 border-top align-items-center">
                      <div className="col-sm-1">
                        <span className="bi bi-check-circle-fill" style={plansUsedForCalculationsSelected ? colorGreen : colorGrey}></span>
                      </div>
                      <div className="col-sm-4">
                        Plans Used for Calculations
                      </div>
                      <div className="col-sm-7 text-right">
                        <button
                          className="btn btn-outlined-blue m-1"
                          type="button"
                          onClick={() => {
                            setShowAddUsedPlansForCalcModal(true)
                          }}>
                          Select
                        </button>
                        <AddUsedPlansForCalcModal
                          jobService={jobService}
                          reloadData={reloadData}
                          assignment={assignment}
                          show={showAddUsedPlansForCalcModal}
                          onHide={() => { setShowAddUsedPlansForCalcModal(false) }} />
                      </div>
                    </div>
                  )}

                  {_.sortBy(dockets, ["data.attributes.number"]).map(docket => (
                    <div className="row py-3 border-top align-items-center" key={docket.data.id}>
                      <div className="col-sm-1">
                        <span className="bi bi-check-circle-fill" style={{ color: DOCKET_STATUS[_.get(docket, "data.attributes.status")]?.color }}></span>
                      </div>
                      <div className="col-sm-4">
                        Docket:
                        {" "}
                        {_.get(docket, "data.attributes.number")}
                      </div>
                      <div className="col-sm-7 text-right">
                        {isSurveyor && ["new"].includes(_.get(docket, "data.attributes.status")) && (
                          <button
                            disabled={
                              !_.get(docket, "data.attributes.hasDocketDescriptions", false)
                              || !!_.get(docket, "data.attributes.timeIn", null) === false
                              || !!_.get(docket, "data.attributes.timeOut", null) === false
                            }
                            className="btn btn-outlined-blue m-1"
                            type="button"
                            onClick={() => {
                              setDocketSubmitModalSelected({ id: docket.data.id, ...docket.data.attributes })
                            }}>
                            Submit to Operations
                          </button>
                        )}

                        {(isSurveyor || isAdminOrOperations) && !["new"].includes(_.get(docket, "data.attributes.status")) && (
                          <>
                            <button
                              disabled={
                                !_.get(docket, "data.attributes.hasDocketDescriptions", false)
                                || !!_.get(docket, "data.attributes.timeIn", null) === false
                                || !!_.get(docket, "data.attributes.timeOut", null) === false
                              }
                              className="btn btn-outlined-blue m-1"
                              type="button"
                              onClick={() => {
                                setDocketSendToClientModalSelected({ id: docket.data.id, ...docket.data.attributes })
                                setShowSendToClientModal(true)
                              }}>
                              Send Copy to Supervisor / Client
                            </button>
                          </>
                        )}

                        {/* {isAdminOrOperations && ["new", "no_signature", "signed"].includes(_.get(docket, "data.attributes.status")) && (
                          <button
                            className="btn btn-outlined-blue m-1"
                            type="button"
                            onClick={() => {
                              setDocketConfirmModalSelected({ id: docket.data.id, ...docket.data.attributes })
                            }}>
                            Approve and Send to Accounts
                          </button>
                        )} */}

                        <button
                          className="btn btn-outlined-blue m-1"
                          type="button"
                          onClick={() => {
                            history.push(`/dockets/${docket.data.id}`);
                          }}>
                          View Detail
                        </button>

                      </div>
                    </div>
                  ))}
                  {docketConfirmModalSelected && (

                    <SweetAlert
                      custom
                      show={!!docketConfirmModalSelected}
                      title="Are you sure?"
                      style={{ width: "600px" }}
                      closeOnClickOutside
                      allowEscape={false}
                      showCloseButton
                      onCancel={() => setDocketConfirmModalSelected(null)}
                      confirmBtnText="Continue"
                      customIcon={<AiOutlineInfoCircleCustom style={{ color: "rgb(238, 162, 54)", margin: "12px auto" }} />}
                      onConfirm={() => {
                        const docketConfirmModalSelectedClone = { ...docketConfirmModalSelected }
                        setDocketConfirmModalSelected(null)
                        updateDocket(baseUrl, docketConfirmModalSelectedClone.id, { total_amount_confirmed: true }).then(() => {
                          reloadData()
                        })
                      }}>
                      {!docketConfirmModalSelected.totalAmount || docketConfirmModalSelected.totalAmount === "0.00" ? (
                        <>
                          <p style={{ fontSize: "18px", color: "red" }}>WARNING $0.00 AMOUNT</p>
                          <span style={{
                            fontSize: "18px",
                            fontWeight: "normal",
                            color: "red"
                          }}>
                            {`Approve and Send docket ${docketConfirmModalSelected.number}, with a value of `}
                            <b>
                              $0.00
                            </b>
                            {" to Accounts?"}
                          </span>
                        </>
                      ) : (
                        <span style={{
                          fontSize: "18px",
                          fontWeight: "normal"
                        }}>
                          {`Approve and Send docket ${docketConfirmModalSelected.number}, with a value of `}
                          <b>
                            $
                            {docketConfirmModalSelected.totalAmount}
                          </b>
                          {" to Accounts?"}
                        </span>
                      )}
                    </SweetAlert>
                  )}
                  {docketSubmitModalSelected && (
                    <SweetAlert
                      custom
                      show={!!docketSubmitModalSelected}
                      title="Are you sure?"
                      style={{ width: "600px" }}
                      closeOnClickOutside
                      allowEscape={false}
                      showCloseButton
                      onCancel={() => setDocketSubmitModalSelected(null)}
                      confirmBtnText="Continue"
                      customIcon={<AiOutlineInfoCircleCustom style={{ color: "rgb(238, 162, 54)", margin: "12px auto" }} />}
                      onConfirm={() => {
                        const docketSubmitModalSelectedClone = { ...docketSubmitModalSelected }
                        setDocketSubmitModalSelected(null)
                        updateDocket(baseUrl, docketSubmitModalSelectedClone.id, { no_signature: true, status: "signed" }).then(() => {
                          reloadData()
                        })
                      }}>
                      <span style={{
                        fontSize: "18px",
                        fontWeight: "normal"
                      }}>
                        {`Submit docket ${docketSubmitModalSelected.number} to Operations`}
                      </span>
                    </SweetAlert>
                  )}
                  {
                    docketSendToClientModalSelected && (
                      <SendCopyToClientModal
                        show={!!docketSendToClientModalSelected && showSendToClientModal}
                        setShow={setShowSendToClientModal}
                        contactsWithEmail={contactsWithEmail}
                        onSendCopy={clientEmails => {
                          const docketSubmitModalSelectedClone = { ...docketSendToClientModalSelected }
                          setDocketSendToClientModalSelected(null)
                          handleSendToClient(clientEmails, docketSubmitModalSelectedClone.id)
                        }} />
                    )
                  }
                </div>
              )}
            </div>
          </div>
        )}

        {showRequestGps && gpsRequestComment && (
          <GpsActivity
            systemRole={systemRole}
            currentTeamRole={currentTeamRole}
            jobService={jobService}
            reloadData={reloadData} />
        )}

        <CadastralRequestActivity
          systemRole={systemRole}
          currentTeamRole={currentTeamRole}
          jobService={jobService}
          reloadData={reloadData} />

        {showActionButtons && (isGpsAhd || isGpsMga) && !bookedInForJobServiceId && state === "field_work_finished" && (roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) || role === "primary") && (
          <>
            <CommentBox comment={comment} setComment={setComment} />
            <div className="row mt-4 ml-2">
              <div className="mr-2 pt-2">Send to</div>
              <div className="ml-2">
                <button className={`btn ${btnOutline}`} type="button" disabled={!uploadedMandatoryFiles || needToSelectUsedPlansCalculations || sendToLoading} onClick={() => setPrimarySurveyorRequired(true)}>Primary Surveyor</button>
                <ConfirmationModal jobNo={displayJobNo} jobTitle={jobTitle} show={primarySurveyorRequired} resetState={() => setPrimarySurveyorRequired(false)} sendTo={() => onBookInJobComplete("gps")} messageType="Primary Surveyor"></ConfirmationModal>
              </div>
            </div>
          </>
        )}

        { showActionButtons && isBookInCheckSurvey && !bookedInForJobServiceId && state === "field_work_finished" && (roleCheck(systemRole, currentRole, OPERATIONS) || role === "primary") && (
          <>
            <CommentBox comment={comment} setComment={setComment} />
            <div className="row mt-4 ml-2">
              <div className="mr-2 pt-2">Send to</div>
              <div className="ml-2">
                <button className={`btn ${btnOutline}`} type="button" disabled={!uploadedMandatoryFiles || needToSelectUsedPlansCalculations || sendToLoading} onClick={() => setPrimarySurveyorRequired(true)}>Resolve</button>
                <ConfirmationModal jobNo={displayJobNo} jobTitle={jobTitle} show={primarySurveyorRequired} resetState={() => setPrimarySurveyorRequired(false)} sendTo={() => onBookInJobComplete("check-survey")} messageType="Resolve"></ConfirmationModal>
              </div>
            </div>
          </>
        )}


        {(showActionButtons && !sent && state !== GPS_APPROVED && !PROCESSING_STATES.includes(state) && !bookedInForJobServiceId && !isGpsAhd && !isGpsMga && !isBookInCheckSurvey && (roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) || role === "primary")) && (
          <>
            <CommentBox comment={comment} setComment={setComment} />
            <div className="row mt-4">
              <div className="mr-4 pt-2">Send to</div>
              {(workflow !== "6" && workflow !== "8") && forProjectX && (
                <div className="mr-2">
                  <button className={`btn ${processingRequired ? btnPrimary : btnOutline}`} type="button" disabled={(!uploadedRequiredFieldWorkFiles && !isAdminOrOperations) || (needToSelectUsedPlansCalculations && !isAdminOrOperations) || sendToLoading} onClick={processingRequiredHandler}>Processing</button>
                  {((processingRequired && uploadedRequiredFieldWorkFiles) || isAdminOrOperations) && (
                    <ConfirmationModal jobNo={displayJobNo} jobTitle={jobTitle} show={processingRequired} resetState={() => setProcessingRequired(false)} sendTo={sendToProcessing} messageType="Processing"></ConfirmationModal>
                  )}
                </div>
              )}
              {(workflow !== "6" && workflow !== "8") && !forProjectX && (
                <div className="mr-2">
                  <button className={`btn ${draftingRequired ? btnPrimary : btnOutlineSendToDrafting}`} type="button" disabled={(!uploadedRequiredFieldWorkFiles && !isAdminOrOperations) || (needToSelectUsedPlansCalculations && !isAdminOrOperations) || sendToLoading} onClick={draftingRequiredHandler}>Drafting Required</button>
                  {((draftingRequired && uploadedRequiredFieldWorkFiles) || isAdminOrOperations) && (
                    <ConfirmationModal jobNo={displayJobNo} jobTitle={jobTitle} show={draftingRequired} resetState={() => setDraftingRequired(false)} sendTo={sendToDrafting} messageType="Drafting"></ConfirmationModal>
                  )}
                </div>
              )}

              {((workflow === "6" || roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER)) && workflow !== "8") && (
                <div className="mr-2">
                  <button className={`btn ${regSurveyorRequired ? btnPrimary : btnOutline}`} type="button" disabled={(!uploadedMandatoryFiles && !isAdminOrOperations) || (needToSelectUsedPlansCalculations && !isAdminOrOperations) || sendToLoading} onClick={regSurveyorRequiredHandler}>Registered Surveyor</button>
                  {((regSurveyorRequired && uploadedMandatoryFiles) || isAdminOrOperations) && (
                    <ConfirmationModal jobNo={displayJobNo} jobTitle={jobTitle} show={regSurveyorRequired} resetState={() => setRegSurveyorRequired(false)} sendTo={sendToRegSurveyor} messageType="Registered Surveyor"></ConfirmationModal>
                  )}
                </div>
              )}

              {workflow === "8" && (
                <div className="mr-2">
                  <button className="btn btn-outline-primary" type="button" onClick={() => setReadyToSend(true)}>Ready to Send</button>
                  <ConfirmationModal
                    jobNo={jobNo}
                    jobTitle={jobTitle}
                    show={readyToSend}
                    resetState={() => setReadyToSend(false)}
                    sendTo={sendToReadyToSend}
                    messageType={"Complete"}>
                  </ConfirmationModal>
                </div>
              )}
            </div>
          </>
        )}

        {(showActionButtons && !sent && state !== GPS_APPROVED && bookedInForJobServiceId && !isGpsAhd && !isGpsMga && (roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) || role === "primary")) && (
          <>
            <CommentBox comment={comment} setComment={setComment} />
            <div className="row mt-4">
              <div className="mr-4 pt-2">Send to</div>
              <div className="mr-2">
                <button className={`btn ${resolveBookedInJob ? btnPrimary : btnOutline}`} type="button" disabled={!uploadedMandatoryFiles || sendToLoading || isDataOverdue || needToSelectUsedPlansCalculations} onClick={resolveBookedInJobHandler}>Resolve</button>
                {(resolveBookedInJob && uploadedMandatoryFiles) && (
                  <ConfirmationModal show={resolveBookedInJob} resetState={() => setResolveBookedInJob(false)} sendTo={sendBookedInJobHandler} title="Resolve Booked in Job?">
                    This will resolve the linked parent job.
                  </ConfirmationModal>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <CheckOutModal
        jobService={jobService}
        reloadData={reloadData}
        show={showCheckOutModal}
        surveyorAssignmentId={surveyorAssignmentId || checkoutSurveyorAssignmentId}
        productName={productName}
        assignmentsWithSameTime={assignmentsWithSameTime}
        onHide={() => { setShowCheckOutModal(false) }} />
    </>
  )
}
export default BookingConfirmed
