import EditSaveButtons from "components/EditSaveCancelButton"
import Loader from "components/Loader"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { putUser } from "requesters/userRequester"
import { sortByName } from "utils/index"
import { getProduct } from "../../../../../requesters/productRequester"
import * as actionTypes from "../../../actions/types"
import { getBaseUrl, getResource } from "../../../reducers"
import UserAddressInput from "./UserAddressInput"
import UserBasicInput from "./UserBasicInput"
import UserSurveyorSkills from "./UserSurveyorSkills"
import UserSurveyorType from "./UserSurveyorType"
import UserUploadFile from "./UserUploadFile"
import UserAvailabitlityDays from "./components/UserAvailabitlityDays"

export default function UserDetailsCard() {
  const baseUrl = useSelector(getBaseUrl)
  const dispatch = useDispatch()

  const { id } = useParams()
  const user = useSelector(state => getResource(state, { type: "user", id }))
  const userAttributes = _.get(user, "attributes", {})
  const {
    email, firstname, phone, surname, fulladdress, emergencyContact, emergencyContactNumber, surveyorId, surveyorSignaturePath, surveyorSignatureFileName,
    monday, tuesday, wednesday, thursday, friday, saturday, sunday, surveyorType, hourlyRate
  } = userAttributes

  const availabitlities = {
    monday, tuesday, wednesday, thursday, friday, saturday, sunday
  }

  const { editState, userEditableAttr, postUserState } = useSelector(state => state.manageUserPage)

  const [skills, setSkills] = useState([])
  const [mounted, setMounted] = useState(true)

  const fetchProducts = () => {
    getProduct(baseUrl)
      .then(res => {
        if (mounted && res.status === 200) {
          const products = Object.values(_.get(res.response, "product", {}))
          sortByName(products, "code", true)
          setSkills(products)
          setMounted(false)
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  useEffect(() => {
    setMounted(true)
    fetchProducts()
  }, [])

  function postUserUpdates() {
    dispatch({ type: actionTypes.MANAGE_USERS_EDIT_SAVE })
    async function postData() {
      dispatch({ type: actionTypes.USER_POST_START })
      const { response, error } = await putUser(baseUrl, id, userEditableAttr)
      if (response) dispatch({ type: actionTypes.USER_POST_SUCCESS, data: response })
      if (error) dispatch({ type: actionTypes.USER_POST_FAILURE })
    }
    postData()
  }

  function resetUserEditableAttr() {
    dispatch({ type: actionTypes.MANAGE_USERS_EDIT_CANCEL, data: user })
  }

  function updateAttr(data) {
    dispatch({ type: actionTypes.MANAGE_USERS_EDIT_UPDATE, data })
  }


  useEffect(() => {
    dispatch({ type: actionTypes.MANAGE_USERS_EDIT_RESET })
  }, []);

  return (
    <div className="card border-0 shadow-sm">
      {postUserState && (
        <div className="loader-overlay">
          <Loader />
        </div>
      )}
      <div className="card-body p-4">
        <UserBasicInput {...{
          title: "First name",
          editState,
          value: firstname,
          inputValue: userEditableAttr.firstname,
          setInputValue: value => { updateAttr({ firstname: value }) },
        }} />
        <UserBasicInput {...{
          title: "Surname",
          editState,
          value: surname,
          inputValue: userEditableAttr.surname,
          setInputValue: value => { updateAttr({ surname: value }) },
        }} />
        <UserBasicInput {...{
          title: "Email",
          hrefPrefix: "mailto",
          editState,
          value: email,
          inputValue: userEditableAttr.email,
          setInputValue: value => { updateAttr({ email: value }) }
        }} />
        <UserBasicInput {...{
          title: "Phone",
          hrefPrefix: "tel",
          editState,
          value: phone,
          inputValue: userEditableAttr.phone,
          setInputValue: value => { updateAttr({ phone: value }) }
        }} />
        <UserAddressInput {...{
          editState,
          user_id: user?.id,
          inputValue: userEditableAttr.fulladdress,
          setInputValue: value => { updateAttr({ fulladdress: value }) },
          title: "Address",
          value: fulladdress || ""
        }} />

        <UserBasicInput {...{
          editState,
          inputValue: userEditableAttr.emergency_contact,
          setInputValue: value => { updateAttr({ emergency_contact: value }) },
          title: "Emergency contact",
          value: emergencyContact || "",
        }} />

        <UserBasicInput {...{
          editState,
          inputValue: userEditableAttr.emergency_contact_number,
          setInputValue: value => { updateAttr({ emergency_contact_number: value }) },
          title: "Emergency contact number",
          value: emergencyContactNumber || "",
        }} />

        <UserBasicInput {...{
          editState,
          inputValue: userEditableAttr.surveyor_id,
          setInputValue: value => { updateAttr({ surveyor_id: value }) },
          title: "Registered Surveyor ID",
          value: surveyorId || "",
        }} />

        <UserUploadFile {...{
          editState,
          user_id: user?.id,
          title: "Surveyor Signature",
          path: surveyorSignaturePath || "",
          fileName: surveyorSignatureFileName || ""
        }} />

        <UserAvailabitlityDays
          editState={editState}
          availabitlities={availabitlities}
          title="Available days"
          updateAttr={updateAttr} />

        <UserBasicInput {...{
          editState,
          inputValue: userEditableAttr.hourly_rate,
          setInputValue: value => { updateAttr({ hourly_rate: value }) },
          title: "Hourly Rate",
          value: hourlyRate,
        }} />

        {
          surveyorType && (
            <UserSurveyorType
              editState={editState}
              updateAttr={updateAttr}
              userEditableAttr={userEditableAttr} />
          )
        }

        <UserSurveyorSkills
          editState={editState}
          user={user}
          userEditableAttr={userEditableAttr}
          updateAttr={updateAttr}
          allSkills={skills} />

        {user.attributes.surveyorAssignedManagers.length > 0 && (
          <div className="row mb-2">
            <div className="col-3">
              Surveyor Managers
            </div>
            <div className="col-9">
              {user.attributes.surveyorAssignedManagers.join(", ")}
            </div>
          </div>
        )}

        <div className="mt-4">
          <EditSaveButtons
            editState={editState}
            startEdit={() => { dispatch({ type: actionTypes.MANAGE_USERS_START_EDITING }) }}
            save={postUserUpdates}
            cancel={resetUserEditableAttr} />
        </div>
      </div>
    </div>
  )
}
