import customAxios from "./customAxios";
import normalize from "json-api-normalizer";

export const getJobs = (baseUrl, filters) => {
  const path = `${baseUrl}/jobs`;

  return customAxios.get(path, { params: filters })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};
export const getJobsByUser = (baseUrl, filters, userId) => { // user is the salesperson or operation team
  const path = `${baseUrl}/jobs/users/${userId}`;

  return customAxios.get(path, { params: filters })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};
export const getJobsByClient = (baseUrl, secureToken) => { // client is the primary contact
  const path = `${baseUrl}/external/contacts/${secureToken}/jobs`;

  return customAxios.get(path)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};
export const getJobsToBeBooked = (baseUrl, filters) => {
  const path = `${baseUrl}/jobs/to-book`;

  return customAxios.get(path, { params: filters })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const getJobsDrafting = (baseUrl, filters) => {
  const path = `${baseUrl}/jobs/drafting`;

  return customAxios.get(path, { params: filters })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const getJobsAccounts = (baseUrl, filters) => {
  const path = `${baseUrl}/jobs/accounts`;

  return customAxios.get(path, { params: filters })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const getJobsRegSurveyors = (baseUrl, filters) => {
  const path = `${baseUrl}/jobs/registered-surveyors`;

  return customAxios.get(path, { params: filters })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const getJobByIdAndToken = (baseUrl, secureToken, jobId) => {
  // varify if the job belongs to the client with this secure token
  const path = `${baseUrl}/external/contacts/${secureToken}/jobs/${jobId}`;

  return customAxios.get(path)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const getJob = (baseUrl, filters) => {
  const path = `${baseUrl}/jobs/${filters.id}`;
  return customAxios.get(path, filters)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const sendBookingInvitation = (baseUrl, jobId, domain) => {
  const path = `${baseUrl}/jobs/${jobId}/send-invitation`;
  return customAxios.put(path, { domain })
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }));
};
export const updateJobTransition = (baseUrl, jobId, transition) => {
  const path = `${baseUrl}/jobs/${jobId}/${transition}`;
  return customAxios.put(path)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }));
};
// export const updateJobBookingTime = (baseUrl, jobId, bookingTime) => {
//   // only remove existing booking if bookingTime===null
//   const path = `${baseUrl}/jobs/${jobId}/booking-time/`
//   return customAxios.put(path, {booking_time: bookingTime})
//     .then(({ data, status }) => ({ response: data.json, status }))
//     .catch((error) => ({ error }));
// }
export const updateJob = (baseUrl, jobId, params) => {
  const path = `${baseUrl}/jobs/${jobId}/`;
  return customAxios.put(path, { params })
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }));
};
export const assignSurveyor = (baseUrl, jobServiceId, params) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/assign-surveyor`
  return customAxios.put(path, params)
    .then(({ status }) => ({ response: status }))
    .catch(error => ({ error }));
}
export const sendPlan = (baseUrl, jobId, params) => {
  const path = `${baseUrl}/jobs/${jobId}/send-plan`;
  return customAxios.put(path, { params })
    .then(({ status }) => ({ response: status }))
    .catch(error => ({ error }));
};
export const getJobStates = baseUrl => {
  const path = `${baseUrl}/jobs/states`;
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }));
};
export const postBookingAsUser = (baseUrl, data) => {
  const path = `${baseUrl}/jobs/${data.jobId}/booking`;

  return customAxios.post(path, { params: { booking_time: data.bookingTime } })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const newBooking = (baseUrl, data) => {
  const path = `${baseUrl}/jobs/new-booking`;

  return customAxios.post(path, { ...data })
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }));
};

export const removeBookingAsUser = (baseUrl, data) => {
  const path = `${baseUrl}/jobs/${data.jobId}/booking`;

  return customAxios.delete(path, { params: { job_service_id: data.jobServiceId } })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};
export const sendBookingInvite = (baseUrl, jobId, domain) => {
  const path = `${baseUrl}/jobs/${jobId}/send-booking-invite`;

  return customAxios.put(path, { domain })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

//
// export const archiveJob = (baseUrl, leadId, archiveState) => {
//   const path = `${baseUrl}/leads/${leadId}/archive`
//
//   return customAxios.put(path, { archive: archiveState })
//     .then(({ data, status }) => ({ response: normalize(data), status }))
//     .catch(error => ({ error }))
// }
//
// export const createJob = (baseUrl, formData) => {
//   const path = `${baseUrl}/leads`
//   return customAxios.post(path, { params: formData })
//     .then(({ data, status }) => ({ response: normalize(data), status }))
//     .catch(error => ({ error }))
// }
//
// export const getJob = (baseUrl, leadId) => {
//   const path = `${baseUrl}/leads/${leadId}`
//   return customAxios.get(path, {})
//     .then(({ data, status }) => ({ response: normalize(data), status }))
//     .catch(error => ({ error }))
// }
