import React from "react";
import TaskTable from "./TaskTable";

const TasksByAssignedUserTable = ({
  tasks,
  setTasks,
  reloadData
}) => {
  const assignedSurveyors = _.isEmpty(tasks) ? [] : Object.keys(tasks).sort()
  const unassignedIndex = assignedSurveyors.indexOf("Unassigned")
  if (unassignedIndex !== -1) {
    assignedSurveyors.unshift(assignedSurveyors.splice(unassignedIndex, 1)[0]);
  }

  return (
    <>
      {assignedSurveyors.map(rs => {
        const surveyorTasks = tasks[rs]
        return (
          (!!surveyorTasks?.length && (
            <div key={`date-${rs}`}>
              <h3 className="text-dark pt-5">{rs}</h3>
              <TaskTable
                tasks={surveyorTasks}
                setTasks={setTasks}
                reloadData={reloadData} />
            </div>
          ))
        )
      })}
    </>
  )
}

export default TasksByAssignedUserTable