import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux"
import {
  updateJobServiceTransition,
  uploadFile
} from "requesters/jobServiceRequester"
import { Spinner } from "react-bootstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  stringEllipsis,
  DRAFTING_OVERSEAS,
  DRAFTING,
  ADMIN,
  DRAFTING_OVERSEAS_USER,
  ASSIGN_OVERSEAS_MANAGER,
  SET_TO_OVERSEAS_QA,
  OVERSEAS_STATES_ALLOWED,
  DRAFTING_OVERSEAS_LEBANON,
  DRAFTING_OVERSEAS_PHILIPPINES,
  DRAFTING_3D,
  DRAFTING_3D_OVERSEAS_PHILIPPINES_USER,
  DRAFTING_3D_OVERSEAS_LEBANON_USER,
  DRAFTING_USER,
  DRAFTING_MANAGER,
  DRAFTING_3D_MANAGER,
  LOCAL_QA,
} from "utils/constants"
import { roleCheck } from "utils/index"
import { getBaseUrl, getCurrentUser, getCurrentRole } from "../../../../reducers"
import FlagJobServiceModal from "../../modals/FlagJobServiceModal"
import CommentBox from "./CommentBox"
import DeleteFile from "../DeleteFile"
import ConfirmationModal from "../../modals/ConfirmationModal"
import { MAX_SIZE_UPLOADED_FILE, MAX_SIZE_UPLOADED_FILE_TEXT } from "../../../../../../utils/constants";

const DraftingRequired = ({
  jobService,
  reloadData,
  flagJobServiceSource,
  setFlagJobServiceSource,
  overseas,
  qa,
  systemRole,
  currentTeamRole,
  comment,
  setComment,
  noActions,
  isPrimaryDraftman
}) => {
  const {
    id, attributes: {
      draftmanName,
      draftedDocuments,
      state,
      is3D,
      isBackFromOverseas,
      workflow,
      jobNo,
      forProjectX,
      projectXJobNo,
      jobTitle,
      mandatoryFilesUploaded,
      code
    }
  } = jobService
  const displayJobNo = forProjectX ? projectXJobNo : jobNo
  const baseUrl = useSelector(getBaseUrl)
  const currentUser = useSelector(getCurrentUser)
  const currentRole = useSelector(getCurrentRole)
  const currentUserName = currentUser?.attributes?.name
  const isAdmin = systemRole === ADMIN
  const isDraftingTeam = currentRole?.includes(DRAFTING)
    || currentRole?.includes(DRAFTING_OVERSEAS)
    || currentRole?.includes(DRAFTING_3D)
    || currentRole?.includes(DRAFTING_OVERSEAS_PHILIPPINES)
    || currentRole?.includes(DRAFTING_OVERSEAS_LEBANON)
  const is2DOverseasTeam = roleCheck(systemRole, currentRole, DRAFTING_OVERSEAS)
  const isOverseasUser = currentTeamRole.includes(DRAFTING_OVERSEAS_USER)
    || currentTeamRole.includes(DRAFTING_3D_OVERSEAS_LEBANON_USER)
    || currentTeamRole.includes(DRAFTING_3D_OVERSEAS_PHILIPPINES_USER)
  const isLocalDraftingManager = currentTeamRole.includes(DRAFTING_MANAGER)
    || currentTeamRole.includes(DRAFTING_3D_MANAGER)
    || isAdmin

  const [showFlagModal, setShowFlagModal] = useState(false)
  const [file, setFile] = useState(undefined)
  const [uploading, setUploading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [registeredSurveyor, setRegisteredSurveyor] = useState(false)
  const [readyToSend, setReadyToSend] = useState(false)
  const [qaRequired, setQaRequired] = useState(false)
  const [overseas2D, setOverseas2D] = useState(false)
  const [overseas3dLebanon, setOverseas3dLebanon] = useState(false)
  const [overseas3dPhilippines, setOverseas3dPhilippines] = useState(false)
  const [showMandatoryFilesWarning, setShowMandatoryFilesWarning] = useState(false)

  const btnOutline = `btn btn-outline-${!file ? "secondary" : "primary"}`
  const btnPrimary = "btn-primary"
  const whiteListCode = ["D-STRATA", "D-SUB", "88E-EASE", "D-STUM"]

  const getCorrectNextTransition = nextState => {
    switch (nextState) {
      case "RS":
        return "move-to-registered-surveyor"
      case "ReadyToSend":
        return "move-to-accounts"
      case "QA":
        return "set-to-qa"
      default:
        return nextState
    }
  }

  const sendTo = nextState => {
    const nextTransition = getCorrectNextTransition(nextState)
    updateJobServiceTransition(baseUrl, id, nextTransition, comment)
      .then(res => {
        if (res.status === 200) {
          window.location.reload()
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  const sendToOverseas = (transition, draftingTeam = null) => {
    updateJobServiceTransition(baseUrl, id, transition, comment, null, draftingTeam)
      .then(res => {
        if (res.status === 200) {
          window.location.reload()
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  const readyToSendHandler = () => {
    setReadyToSend(!readyToSend)
    setRegisteredSurveyor(false)
    setOverseas3dPhilippines(false)
    setOverseas3dLebanon(false)
    setOverseas2D(false)
  }

  const registeredSurveyorHandler = () => {
    if (is3D && !mandatoryFilesUploaded && !isAdmin) {
      setShowMandatoryFilesWarning(true)
    } else {
      setRegisteredSurveyor(!registeredSurveyor)
      setReadyToSend(false)
      setOverseas3dPhilippines(false)
      setOverseas3dLebanon(false)
      setOverseas2D(false)
    }
  }

  const overseas2dHandler = () => {
    setOverseas2D(!overseas2D)
    setRegisteredSurveyor(false)
    setReadyToSend(false)
  }

  const overseas3dLebanonHandler = () => {
    setOverseas3dLebanon(!overseas3dLebanon)
    setOverseas3dPhilippines(false)
    setRegisteredSurveyor(false)
    setReadyToSend(false)
  }

  const overseas3dPhilippinesHandler = () => {
    setOverseas3dPhilippines(!overseas3dPhilippines)
    setOverseas3dLebanon(false)
    setRegisteredSurveyor(false)
    setReadyToSend(false)
  }

  useEffect(() => {
    if (file) {
      if (file.size > MAX_SIZE_UPLOADED_FILE) {
        setShowError(true)
      } else {
        const documentCategory = "Drafted Documents"
        const formData = new FormData();
        formData.append("file", file)
        formData.append("document_category", documentCategory)
        formData.append("created_by_user_id", currentUser.id)
        setUploading(true)
        uploadFile(baseUrl, id, formData)
          .then(res => {
            if (res.status === 200) {
              setUploading(false)
              reloadData()
            }
          })
          // eslint-disable-next-line no-console
          .catch(err => console.log(err))
      }
    }
  }, [file])

  const actionButtons = () => {
    if (isOverseasUser) {
      return (
        <>
          <div className="mr-2">
            <button
              className="btn btn-outline-secondary"
              disabled={!draftedDocuments?.length}
              // TODO: add notification to the drafting overses manager
              onClick={() => sendToOverseas(SET_TO_OVERSEAS_QA)}
              type="button">
              Drafting Overseas Manager
            </button>
          </div>
          {is2DOverseasTeam && workflow !== "5" && (
            <div className="mr-2">
              <button className={`btn ${registeredSurveyor ? btnPrimary : btnOutline}`} type="button" disabled={currentTeamRole.includes(DRAFTING_USER) && !draftmanName} onClick={registeredSurveyorHandler}>Registered Surveyor</button>
              {registeredSurveyor && (
                <ConfirmationModal jobNo={displayJobNo} jobTitle={jobTitle} show={registeredSurveyor} resetState={() => setRegisteredSurveyor(false)} sendTo={() => sendTo("RS")} messageType="Registered Surveyor"></ConfirmationModal>
              )}
            </div>
          )}
        </>
      )
    }

    const overseasButton = () => {
      if (is3D) {
        return (
          <div className="mr-2">
            <button className={`btn ${overseas3dLebanon ? btnPrimary : btnOutline} mt-2 mr-2`} type="button" onClick={overseas3dLebanonHandler}>Overseas Lebanon</button>
            {overseas3dLebanon && (
              <ConfirmationModal jobNo={displayJobNo} jobTitle={jobTitle} show={overseas3dLebanon} resetState={() => setOverseas3dLebanon(false)} sendTo={() => sendToOverseas(ASSIGN_OVERSEAS_MANAGER, DRAFTING_OVERSEAS_LEBANON)} messageType="Overseas Lebanon"></ConfirmationModal>
            )}

            <button className={`btn ${overseas3dPhilippines ? btnPrimary : btnOutline} mt-2`} type="button" onClick={overseas3dPhilippinesHandler}>Overseas Philippines</button>
            {overseas3dPhilippines && (
              <ConfirmationModal jobNo={displayJobNo} jobTitle={jobTitle} show={overseas3dPhilippines} resetState={() => setOverseas3dPhilippines(false)} sendTo={() => sendToOverseas(ASSIGN_OVERSEAS_MANAGER, DRAFTING_OVERSEAS_PHILIPPINES)} messageType="Overseas Philippines"></ConfirmationModal>
            )}
          </div>
        )
      }

      return (
        <div className="mr-2">
          <button className={`btn ${overseas2D ? btnPrimary : btnOutline} mt-2`} type="button" onClick={overseas2dHandler}>Overseas</button>
          {overseas2D && (
            <ConfirmationModal jobNo={displayJobNo} jobTitle={jobTitle} show={overseas2D} resetState={() => setOverseas2D(false)} sendTo={() => sendToOverseas(ASSIGN_OVERSEAS_MANAGER, DRAFTING_OVERSEAS)} messageType="Overseas"></ConfirmationModal>
          )}
        </div>
      )
    }

    return (
      <>
        {!OVERSEAS_STATES_ALLOWED.includes(state) ? (
          <>
            {(state !== LOCAL_QA && isLocalDraftingManager && !isBackFromOverseas) && (
              overseasButton()
            )}

            {workflow !== "5" && (
              <div className="mr-2">
                <button className={`btn ${registeredSurveyor ? btnPrimary : btnOutline} mt-2`} type="button" disabled={currentTeamRole.includes(DRAFTING_USER) && !draftmanName} onClick={registeredSurveyorHandler}>Registered Surveyor</button>
                {registeredSurveyor && (
                  <ConfirmationModal jobNo={displayJobNo} jobTitle={jobTitle} show={registeredSurveyor} resetState={() => setRegisteredSurveyor(false)} sendTo={() => sendTo("RS")} messageType="Registered Surveyor"></ConfirmationModal>
                )}
              </div>
            )}

            {workflow !== "6" && (
              <div className="mr-2">
                <button className={`btn ${readyToSend ? btnPrimary : btnOutline} mt-2`} type="button" disabled={!draftedDocuments?.length} onClick={readyToSendHandler}>Ready to Send</button>
                {readyToSend && (
                  <ConfirmationModal
                    show={readyToSend}
                    resetState={() => setReadyToSend(false)}
                    title={(
                      <span style={{ fontSize: "18px", fontWeight: "normal" }}>
                        Are you sure you have finalised your work on
                        {" "}
                        <b>{`${displayJobNo} - ${jobTitle}`}</b>
                        {" "}
                        and wish to send to
                        {" "}
                        <b>Client</b>
                        ?
                      </span>
                    )}
                    sendTo={() => sendTo("ReadyToSend")}
                    messageType="Ready to Send">
                  </ConfirmationModal>
                )}
              </div>
            )}
          </>
        )
          : (
            <>
              <div className="mr-2">
                <button className={`btn ${qaRequired ? btnPrimary : btnOutline}`} type="button" disabled={!draftedDocuments?.length} onClick={() => setQaRequired(!qaRequired)}>QA Required</button>
                {(qaRequired && !!draftedDocuments?.length) && (
                  <ConfirmationModal jobNo={displayJobNo} jobTitle={jobTitle} show={qaRequired} resetState={() => setQaRequired(false)} sendTo={() => sendTo("QA")} messageType="QA"></ConfirmationModal>
                )}
              </div>

              {is2DOverseasTeam && workflow !== "5" && (
                <div className="mr-2">
                  <button className={`btn ${registeredSurveyor ? btnPrimary : btnOutline}`} type="button" disabled={currentTeamRole.includes(DRAFTING_USER) && !draftmanName} onClick={registeredSurveyorHandler}>Registered Surveyor</button>
                  {registeredSurveyor && (
                    <ConfirmationModal jobNo={displayJobNo} jobTitle={jobTitle} show={registeredSurveyor} resetState={() => setRegisteredSurveyor(false)} sendTo={() => sendTo("RS")} messageType="Registered Surveyor"></ConfirmationModal>
                  )}
                </div>
              )}
            </>
          )}
      </>
    )
  }

  const statesAbleToFlag = ["to_review", "to_local_drafting"].includes(state) || (state === "to_overseas_drafting" && is2DOverseasTeam)
  const allowedToFlag = statesAbleToFlag && !noActions && isPrimaryDraftman && draftmanName && (workflow !== "3" || whiteListCode.includes(code))

  return (
    <div className="timeline-item mt-2">
      <div className="card border-0 shadow-sm">
        <div className="card-body p-4">
          <div className="row">
            <div className="col-sm-6">
              {(draftmanName || !!draftedDocuments?.length) && (
                <>
                  <div className="input-group mt-2 mb-3">
                    <div className="custom-file">
                      <input
                        className="custom-file-input"
                        id="customFile"
                        type="file"
                        onChange={e => setFile(e.currentTarget.files[0])} />
                      <label className="custom-file-label ml-2" htmlFor="customFile">
                        Choose file
                      </label>
                    </div>
                    {uploading && (
                      <Spinner
                        animation="border"
                        className="ml-3"
                        variant="secondary" />
                    )}
                  </div>

                  {!!draftedDocuments?.length && draftedDocuments.map(file => (
                    <div className="ml-2 mt-2" key={file?.data?.id}>
                      <a
                        className="mr-2"
                        href={file?.data?.attributes?.filePath}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={file?.data?.attributes?.fileName}>
                        {stringEllipsis(file?.data?.attributes?.fileName, 20, 25)}
                      </a>

                      {(isLocalDraftingManager || currentUserName === file?.data?.attributes?.createdByUser) && (
                        <DeleteFile
                          className=""
                          file={file}
                          reloadData={reloadData} />
                      )}

                    </div>
                  ))}
                </>
              )}
            </div>

            <SweetAlert
              show={showError}
              type="error"
              title="File size exceeded!"
              style={{ width: "600px" }}
              closeOnClickOutside
              allowEscape={false}
              onConfirm={() => setShowError(false)}>
              The File is too large (Max size is
              {" "}
              {MAX_SIZE_UPLOADED_FILE_TEXT}
              )
            </SweetAlert>

            <div className="col-sm-6 text-right">
              { allowedToFlag && (
                <button className="btn" type="button" onClick={() => { setShowFlagModal(true) }}>
                  <i className="bi bi-flag-fill mr-2"></i>
                  Add Flag
                </button>
              )}
              <FlagJobServiceModal
                show={showFlagModal}
                onHide={() => { setShowFlagModal(false) }}
                jobService={jobService}
                reloadData={reloadData}
                flagJobServiceSource={flagJobServiceSource}
                setFlagJobServiceSource={setFlagJobServiceSource} />
            </div>
          </div>
        </div>
      </div>
      {((isAdmin || isDraftingTeam) && !noActions) && (
        <>
          <CommentBox comment={comment} setComment={setComment} />
          <div className="row mt-4">
            <div className="mr-4 pt-2">Send to</div>
            {actionButtons()}
          </div>
        </>
      )}
      <SweetAlert
        show={showMandatoryFilesWarning}
        type="info"
        title="Mandatory Field Work Files Are Missing!"
        style={{ width: "600px" }}
        closeOnClickOutside
        allowEscape={false}
        onConfirm={() => setShowMandatoryFilesWarning(false)}>
        Please Upload All Field Work Files' when trying to 'Send to RS'.
      </SweetAlert>
    </div>
  )
}
export default DraftingRequired
