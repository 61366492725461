import { combineReducers } from "redux"

import * as actionTypes from "../actions/types"

function requestJobServicesState(state = false, action) {
  switch (action.type) {
    case actionTypes.SCHEDULE_PAGE_REQUEST_START:
      return true
    case actionTypes.SCHEDULE_PAGE_REQUEST_SUCCESS:
    case actionTypes.SCHEDULE_PAGE_REQUEST_FAILURE:
      return false
    default:
      return state
  }
}

function filteredJobServiceIds(state = [], action) {
  switch (action.type) {
    case actionTypes.SCHEDULE_PAGE_REQUEST_START:
    case actionTypes.SCHEDULE_PAGE_REQUEST_FAILURE:
      return []
    case actionTypes.SCHEDULE_PAGE_REQUEST_SUCCESS: {
      console.log(action)
      return _.map(Object.values(_.get(action, "data.fieldWorkJobService", {})), item => parseInt(item.id, 10), [])
    }
    default:
      return state
  }
}

export default combineReducers({
  requestJobServicesState,
  filteredJobServiceIds
})
