import React, { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
// import ErrorMessage from "./VerificationForms/ErrorMessage"

export default function Dropzone({
  wrapperClass, activeText,
  inactiveText, handleFileUpload,
  name, multiple, defaultValue, fileTypes, fileTypeNames, setUploadedFiles
}) {
  const [file, setFile] = useState(null)
  // const [errorMessage, setErrorMessage] = useState(undefined)
  const onDrop = useCallback((acceptedFiles, rejectedFile) => {
    if (acceptedFiles.length) {
      handleFileUpload({ name, value: acceptedFiles })
      setFile(acceptedFiles)
      setUploadedFiles(acceptedFiles)
      // setErrorMessage(undefined)
    } else if (rejectedFile.length) {
      // setErrorMessage(rejectedFile[0].errors[0].code)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple, accept: fileTypes })

  // DEV NOTE: in case we want the file name on box (AS)
  // function boxText() {
  //   if (file && file[0]) {
  //     return file[0].name
  //   }
  //   if (defaultValue && defaultValue[0]) {
  //     return defaultValue[0].name
  //   }
  //   return (isDragActive ? activeText : inactiveText)
  // }

  return (
    <>
      <div className={wrapperClass} {...getRootProps()}>
        <input {...getInputProps()} />
        {/* { boxText() } */}
        <p>Drag and Drop Files here, or Click here to Browse and Upload.</p>
      </div>
      {/* {errorMessage && <ErrorMessage message={errorMessage === "file-invalid-type" ? `File type must be ${fileTypeNames || fileTypes}` : errorMessage} />} */}
    </>
  )
}
