import React from "react";
import _ from "lodash"
import { format } from "date-fns"

const RowJobPack = ({ name, updatedDate }) => (
  <tr>
    <td>{_.capitalize(name)}</td>
    <td className="text-muted">Update to date</td>
    <td className="text-muted">{format(updatedDate, "d MMM yyyy")}</td>
    <td className="text-right">
      <button className="btn btn-outlined-blue btn-sm" type="button">Search</button>
    </td>
  </tr>
  )

const JobPackModal = () => (
  <div id="jobPackModal" className="modal fade" aria-hidden="true" aria-labelledby="jobPackModalLabel" role="dialog" tab-index="-1">
    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div className="modal-content">
        <div className="modal-header pb-4">
          <h2 id="jobPackModalLabel" className="modal-title">Job Pack</h2>
          <button className="close" aria-label="Close" data-dismiss="modal" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body bg-light py-4">
          <div className="table-responsive">
            <table className="table table-listing">
              <thead>
                <tr>
                  <th>Document Name</th>
                  <th></th>
                  <th>Last Updated</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="bg-white">
                <RowJobPack name="Certificate of Title" updatedDate={new Date()} />
                <RowJobPack name="DP 76543" updatedDate={new Date()} />
              </tbody>
            </table>
          </div>
        </div>
        <div className="modal-footer pt-4 justify-content-between">
          <button className="btn btn-outlined-blue mr-2" type="button">Add File</button>
          <button className="btn btn-blue" type="button">Download All</button>
        </div>
      </div>
    </div>
  </div>
  )
export default JobPackModal
