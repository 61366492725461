import React, { useState } from "react";
import { useSelector } from "react-redux"
import SweetAlert from "react-bootstrap-sweetalert"
import { deleteUpload } from "../../../../../requesters/uploadsRequester";
import { getBaseUrl } from "../../../reducers"

const DeleteFile = ({ file, reloadData }) => {
  const baseUrl = useSelector(getBaseUrl)
  const [showMessage, setShowMessage] = useState(false)

  const handleUploadDelete = id => {
    deleteUpload(baseUrl, id)
    .then(res => {
        reloadData()
        setShowMessage(false)
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }

  const btnStyle = {
    border: "none",
    boxShadow: "none"
  };

  return (
    <>
      <i
        className="bi bi-x-circle-fill text-danger delete-file"
        onClick={() => setShowMessage(true)}
        title="Delete File" />
      <SweetAlert
        show={showMessage}
        type="custom"
        customClass="text-dark"
        title="Are you sure you want to delete this file?"
        style={{ width: "600px" }}
        confirmBtnText="Delete"
        confirmBtnCssClass="btn btn-danger"
        confirmBtnStyle={btnStyle}
        closeOnClickOutside
        allowEscape={false}
        showCancel
        onCancel={() => setShowMessage(false)}
        onConfirm={() => handleUploadDelete(file.data.id)}>
        <i className="bi bi-file-earmark mr-2"></i>
        {file.data.attributes.fileName}
      </SweetAlert>
    </>
  )
}

export default DeleteFile