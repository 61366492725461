/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from "react"
import { Modal, Spinner } from "react-bootstrap"
import { createDocketDescription, updateDocketDescription } from "requesters/docketRequester"
import { useSelector } from "react-redux"
import { getBaseUrl } from "../../../reducers"

const CreateAndUpdateDocketDescriptionModal = ({
  show, setShow, isEdit, setIsEdit, dataDocketDescription, reloadData, docketId, loading, setLoading
}) => {
  const baseUrl = useSelector(getBaseUrl)
  const [docketDescription, setDocketDescription] = useState({
    descriptionOfWork: "",
    marks: 0,
    benchmarks: 0
  })
  const handleClose = () => {
    setShow(false)
    setIsEdit(false)
  }

  useEffect(() => {
    if (dataDocketDescription) {
      setDocketDescription(dataDocketDescription)
    } else {
      setDocketDescription({
        descriptionOfWork: "",
        marks: 0,
        benchmarks: 0
      })
    }
  }, [dataDocketDescription])

  const handleSubmit = async () => {
    // if (reloadData) reloadData()
    setLoading(true)
    if (isEdit && docketDescription.id) {
      updateDocketDescription(baseUrl, docketDescription.id, {
        description_of_work: docketDescription.descriptionOfWork,
        marks: docketDescription.marks,
        benchmarks: docketDescription.benchmarks
      }).then(() => {
        if (reloadData) reloadData()
      }).catch(() => {
        setLoading(true)
      })
    } else {
      createDocketDescription(baseUrl, docketId, {
        description_of_work: docketDescription.descriptionOfWork,
        marks: docketDescription.marks,
        benchmarks: docketDescription.benchmarks
      }).then(() => {
        setDocketDescription({
          descriptionOfWork: "",
          marks: 0,
          benchmarks: 0
        })
        if (reloadData) reloadData()
      })
        .catch(() => {
          setLoading(true)
        })
    }
    if (isEdit) setIsEdit(false)
    // if (reloadData) reloadData()
    setShow(false)
  }
  return (
    <Modal show={show} centered>
      <div className="modal-content">
        <div className="modal-header border-0">
          <h5 className="modal-title" id="gearIssueModalLabel">
            {isEdit ? "Update " : "Add "}
            Docket Description
          </h5>
          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={handleClose}>
            <span aria-hidden="true">x</span>
          </button>
        </div>
        <div className="modal-body">
          <form>
            <div className="form-group">
              <label>Description of works*</label>
              <textarea required value={docketDescription.descriptionOfWork} rows="5" className="form-control" placeholder="Description of works" onChange={e => setDocketDescription({ ...docketDescription, descriptionOfWork: e.target.value })} />
            </div>
            <div className="form-group">
              <label>Marks</label>
              <input step="1" value={docketDescription.marks} type="number" className="form-control" placeholder="Marks" onChange={e => setDocketDescription({ ...docketDescription, marks: Number(e.target.value).toFixed(0) })} />
            </div>
            <div className="form-group">
              <label>Benchmark</label>
              <input step="1" value={docketDescription.benchmarks} type="number" className="form-control" placeholder="Benchmark" onChange={e => setDocketDescription({ ...docketDescription, benchmarks: Number(e.target.value).toFixed(0) })} />
            </div>
          </form>
        </div>
        <div className="modal-footer border-0 justify-content-between mt-4">
          <button
            disabled={loading}
            className="btn btn-red btn-lg"
            type="button"
            onClick={handleSubmit}>
            Submit
            {loading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default CreateAndUpdateDocketDescriptionModal
