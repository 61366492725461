/* eslint-disable no-console */
import { getBaseUrl, getCurrentRole, getCurrentUser } from "apps/App/reducers";
import Loader from "components/Loader";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPublicHoliday } from "requesters/publicHolidaysRequester";
import { OPERATIONS } from "utils/constants";
import { roleCheck } from "utils/index";
import HolidayBreadcrumb from "./HolidayBreadcrumb";
import HolidayForm from "./HolidayForm";

export default function EditHoliday() {
  const { id } = useParams()
  const baseUrl = useSelector(getBaseUrl)
  const currentUser = useSelector(getCurrentUser)
  const currentRole = useSelector(getCurrentRole)
  const systemRole = currentUser.attributes.role
  const [holiday, setHoliday] = useState({})
  const [requesting, setRequesting] = useState(true)

  if (!roleCheck(systemRole, currentRole, OPERATIONS)) window.location = "/"

  useEffect(() => {
    const fetchData = async () => {
      const result = await getPublicHoliday(baseUrl, {
        id
      })

      if ("error" in result) {
        console.log(result.error)
      } else {
        setHoliday(_.get(result.response, `publicHoliday.${id}`, {}))
      }

      setRequesting(false)
    }
    fetchData()
  }, [])

  return (
    <Container fluid className="holiday-details-page pb-5">
      {
        requesting ? <Loader /> : (
          <>
            <HolidayBreadcrumb name={holiday.attributes.name} />
            <HolidayForm holiday={holiday} />
          </>
        )
      }
    </Container>
  )
}
