import React from "react";
import PDFIcon from "../../../../../../../assets/images/icons/pdf-file.png";
import DWGIcon from "../../../../../../../assets/images/icons/dwg-file.png";
import MSGIcon from "../../../../../../../assets/images/icons/msg-file.png";
import DocumentIcon from "../../../../../../../assets/images/icons/document-file.png";
import CSVIcon from "../../../../../../../assets/images/icons/csv-file.png";
import TXTIcon from "../../../../../../../assets/images/icons/txt-file.png";
import JPGIcon from "../../../../../../../assets/images/icons/jpg-file.png";
import BlankIcon from "../../../../../../../assets/images/icons/blank-file.png";

export default function FileIcon({ file }) {
  const getFileExtension = (fileName = '') => {
    const parts = fileName.split('.');
    return parts.length > 1 ? parts.pop().toLowerCase() : '';
  };

  const matchIconByExtension = (extension) => {
    switch (extension) {
      case 'pdf':
        return PDFIcon;
      case 'dwg':
        return DWGIcon;
      case 'msg':
        return MSGIcon;
      case 'csv':
        return CSVIcon;
      case 'txt':
        return TXTIcon;
      case 'jpg':
      case 'jpeg':
        return JPGIcon;
      case 'doc':
      case 'docx':
      case 'xls':
      case 'xlsx':
        return DocumentIcon;
      case '':
        return BlankIcon;
      default:
        return BlankIcon;
    }
  };

  const fileExtension = getFileExtension(file?.data?.attributes?.fileName || '');
  const icon = matchIconByExtension(fileExtension);

  return <img src={icon} alt={`${fileExtension || 'blank'} icon`} style={{ width: '20px', height: '20px' }} />;
}
