import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux"

import { getRegisterdSurveyors } from "requesters/userRequester";
import { assignRegSurveyor } from "requesters/jobServiceRequester";
import Select from "react-select"
import { getBaseUrl } from "../../../../reducers"

const SelectRegisteredSurveyor = ({ jobService, reloadData }) => {
  const { id, attributes: { jobId, workflow } } = jobService
  const baseUrl = useSelector(getBaseUrl)
  const [regSurveyorOptions, setRegSurveyorOptions] = useState([])
  const [regSurveyor, setRegSurveyor] = useState()

  const getRegisteredSurveyorHandler = () => {
    getRegisterdSurveyors(baseUrl, workflow)
      .then(res => {
        if (res.status === 200) {
          const newData = res.response.map(item => ({ label: `${item[0]} ${item[1]}`, value: item[2] }))
          setRegSurveyorOptions(newData)
          setRegSurveyor(newData.find(regSurveyor => regSurveyor.value === jobService.attributes.registeredSurveyorId))
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  const updateRegSurveyorHandler =  (jobServiceId, params) => {
   assignRegSurveyor(baseUrl, jobServiceId, params)
    .then(res => {
      if (res.status === 200) {
        setRegSurveyor(regSurveyorOptions.find(s => s.value === res.response.jobService.attributes.registeredSurveyorId))
        reloadData()
      }
    })
     // eslint-disable-next-line no-console
     .catch(err => console.log(err))
  }

  useEffect(() => {
    if (jobService) {
      getRegisteredSurveyorHandler()
    }
  }, [jobService?.registeredSurveyorId])

return (
  <div className="assign mb-3">
    <div className="d-flex align-items-center">
      <div className="pr-2">Registered Surveyor</div>
      <div style={{ width: "180px" }}>
        <Select
          name="regSurveyor"
          isSearchable
          placeholder="Select RS"
          options={regSurveyorOptions}
          value={regSurveyor}
          onChange={e => {
            setRegSurveyor(e)
            updateRegSurveyorHandler(id, { registered_surveyor_id: e.value })
            window.location.href = `/jobs/${jobId}/job_services/${id}` // TODO: to delete when the URL will not include the job anymore
          }} />
      </div>
    </div>
  </div>
  )
}
  export default SelectRegisteredSurveyor
