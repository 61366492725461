import React, { useEffect, useState, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"

import { Container } from "react-bootstrap"
import { useQueryParam, StringParam } from "use-query-params"

import { getBaseUrl, getCurrentUser, getResource } from "../../../reducers"
import { getJobByIdAndToken } from "requesters/jobRequester.js"
import CADatePicker from "../../../components/Calendar/DatePicker"
import Calendar from "../../../components/Calendar/Calendar"
import cx from "classnames"
import { eachDayOfInterval, startOfWeek, endOfWeek, addDays, isSameMinute, format, set, isAfter } from "date-fns"
import { Link } from "react-router-dom"
import SweetAlert from "react-bootstrap-sweetalert";
import data from "../../../reducers/data"
import { useParams } from "react-router-dom"
import _ from "lodash"

import { postBookingAsClient } from "requesters/jobServiceRequester.js"


export default function BookingTimeslot() {

  const baseUrl = useSelector(getBaseUrl)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [calendarDate, setCalendarDate] = useState(new Date())
  const [selectedBookingTime, setSelectedBookingTime] = useState(null)
  const { id, secureToken } = useParams()

  // sweetAlert
  const [showMessage, setShowMessage] = useState(false)
  const [alertType, setAlertType] = useState('success')
  const [alertTitle, setAlertTitle] = useState('')
  const alertTitleForWrongJobId = "You are not the owner of this job"
  const alertTitleForSuccessfulBooking = "Survey has been booked successfully"

  async function MakeBooking() {
    const data = {
      jobId: id,
      bookingTime: selectedBookingTime,
      secureToken: secureToken
    }
    console.log(data);
    const { response, error } = await postBookingAsClient(baseUrl, data);
    if (response) {
      setAlertTitle("Survey has been booked successfully");
      setAlertType("success");
      setShowMessage(true);
    }
    if (error) {
      setAlertTitle("Ooops! Fail to make a booking");
      setAlertType("error");
      setShowMessage(true);
    }
  }
  const hideAlert = () => {
    setShowMessage(false)
    if (alertTitle === alertTitleForWrongJobId || alertTitle === alertTitleForSuccessfulBooking) {
      window.location.href = `/customer-portal/${secureToken}`
    }
  }
  const MakeBookingHandler = () => {
    if (selectedBookingTime !== null) {
      MakeBooking()
    } else {
      setAlertTitle("Please choose a timeslot!");
      setAlertType("warning");
      setShowMessage(true);
    }
  }

  useEffect(() => {
    setStartDate(addDays(startOfWeek(calendarDate), 1)) // set the Monday of the week
    setEndDate(addDays(endOfWeek(calendarDate), -1)) // set the Friday of the week
  }, [calendarDate])
  useEffect(() => {
    async function getJobByIdAndTokenHandler() {
      const { response, error } = await getJobByIdAndToken(baseUrl, secureToken, id)
      if (response) {
        console.log(response);
      }
      if (error) {

        setAlertTitle(alertTitleForWrongJobId);
        setAlertType("error");
        setShowMessage(true)
      }
    }
    getJobByIdAndTokenHandler()
  }, [])

  const days = eachDayOfInterval({ start: startDate, end: endDate })
  const today = new Date()
  //******************** child component
  const TimeslotsInDay = ({ date }) => {
    const isValidDay = isAfter(date, addDays(today, 2))
    return (
      <div className="day">
        <h3 className={cx("mb-4", { "text-muted": !isValidDay })} >{format(date, "EEEE d, MMM")}</h3>
        {
          isValidDay &&
          <div>
            <Timeslot date={date} section="morning" />
            <Timeslot date={date} section="afternoon" />
          </div>
        }

      </div>
    )
  }

  const Timeslot = ({ date, section }) => {
    const bookingTime = () => {
      if (section.toLowerCase() === "morning") return set(date, { hours: 7, minutes: 0, seconds: 0 })
      if (section.toLowerCase() === "afternoon") return set(date, { hours: 12, minutes: 0, seconds: 0 })
    }

    const isActive = selectedBookingTime !== null ? isSameMinute(selectedBookingTime, bookingTime()) : false

    const timeRange = (section) => {
      if (section.toLowerCase() === "morning") return "Between 7am - 11am"
      if (section.toLowerCase() === "afternoon") return "Between 12pm - 4pm"
    }
    return (
      <div className={cx("card card-body", { active: isActive })} onClick={() => setSelectedBookingTime(bookingTime())} style={{ cursor: "pointer" }}>
        <div className="d-flex d-md-block justify-content-between justify-content-md-start">
          <h3 className="mb-0 mb-md-1">{_.capitalize(section)}</h3>
          <p>{timeRange(section)}</p>
        </div>
      </div>
    )
  }
  //****************************************************
  return (
    <>
      <Container fluid className="container-content">
        <div className="mt-5 mb-5 pb-5 pb-sm-0">
          <h2 className="text-center">Making a booking</h2>
          <div className="mb-5 text-center">
            <Calendar startDate={startDate} endDate={endDate} calendarDate={calendarDate} setCalendarDate={setCalendarDate} />
          </div>
          <div className="d-md-flex book-a-time">
            {
              days.map((day, index) => {
                return <TimeslotsInDay date={day} key={`day-${index}`} />
              })
            }

          </div>
        </div>
        <div className="d-flex justify-content-between booking-cta">
          <Link to={`/customer-portal/${secureToken}`} className="btn btn-outline-secondary btn-lg mr-2">Cancel</Link>
          <div className="btn btn-red btn-lg" onClick={() => MakeBookingHandler()} style={{ cursor: "pointer" }}>Confirm Booking</div>
        </div>
      </Container>
      <SweetAlert
        show={showMessage}
        type={alertType}
        title={alertTitle}
        closeOnClickOutside
        allowEscape={false}
        onConfirm={hideAlert}
      />
    </>
  )
}

