import React, { useState } from "react"
import Select from "react-select"
import { useSelector } from "react-redux"
import { postOrganization } from "requesters/organizationRequester"
import EmailValidator from "email-validator"
import { Spinner } from "react-bootstrap"
import CustBreadcrumb from "components/CustBreadcrumb"
import { roleCheck } from "utils/index"
import {
  ACCOUNTS_MANAGER,
  OPERATION_MANAGER,
  REGISTERED_SURVEYORS_MANAGER,
} from "utils/constants"
import { getBaseUrl, getCurrentUser } from "../../reducers"
import { FIELD_VALIDATIONS_ORGANIZATION } from "./formErrors"


export default function NewOrganizationForm({ history }) {
  const baseUrl = useSelector(getBaseUrl)
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role

  const { accountTypes } = useSelector(state => state.options)
  const accountTypeOptions = accountTypes.map(v => ({ value: v[0], label: v[1] }))
  // form fields
  const [name, setName] = useState("")
  const [address, setAddress] = useState("")
  const [accountType, setAccountType] = useState([])
  const [email, setEmail] = useState("")

  // form events
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [validForm, setValidForm] = useState(true)

  const rolesAllowed = () => {
    if (roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) || roleCheck(systemRole, currentTeamRole, ACCOUNTS_MANAGER) || roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)) {
      return true
    }
    return false
  }

  if (!rolesAllowed()) {
    window.location = "/"
  }

  function handleSubmit() {
    const organization = {
      email,
      name,
      address,
      account_type: accountType
    }


    setDisableSubmit(true)
    postOrganization(baseUrl, organization)
      .then(res => {
        if (res.status === 201) {
          history.push("/contacts?tab=organizations")
          setDisableSubmit(false)
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  function validateForm() {
    let validFields = true
    const fields = [
      {
        email,
        name,
        address,
        accountType
      }
    ]

    _.map(fields[0], (value, key) => {
      switch (key) {
        case "email":
          validFields = EmailValidator.validate(value)
          break
        default:
          if (_.isEmpty(value)) {
            validFields = false
          }
      }
    })

    if (validFields) {
      handleSubmit()
    }
    setValidForm(false)
  }
  function errorMessage(attribute, value) {
    if (!validForm) {
      const message = _.find(FIELD_VALIDATIONS_ORGANIZATION, ["field", attribute])
      let validField = true
      switch (attribute) {
        case "email":
          validField = EmailValidator.validate(email)
          break
        default:
          if (_.isEmpty(value)) {
            validField = false
          }
      }
      if (!validField) {
        return (
          <div className="err-msg">
            {message.message}
          </div>
        )
      }
      return null
    }
    return null
  }

  return (
    <div className="gear-details-page pb-5 container-fluid">
      <CustBreadcrumb links={[["Organisations", "/contacts?tab=organizations"]]} active="New" />
      <h2 className="mb-4">New Organisation</h2>
      <div className="row">
        <div className="col-md-8">
          <div className="card border-0 shadow-sm">
            <div className="card-body p-4">
              <div className="form-group row">
                <label className="col-3 col-form-label">Email*</label>
                <div className="col-9">
                  <input
                    name="email"
                    className="form-control"
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={e => setEmail(e.target.value)} />
                  {errorMessage("email", email)}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">Name*</label>
                <div className="col-9">
                  <input
                    name="name"
                    className="form-control"
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={e => setName(e.target.value)} />
                  {errorMessage("name", name)}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">Address*</label>
                <div className="col-9">
                  <input
                    name="address"
                    className="form-control"
                    type="text"
                    placeholder="Address"
                    value={address}
                    onChange={e => setAddress(e.target.value)} />
                  {errorMessage("address", address)}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">Account Type *</label>
                <div className="col-9">
                  <Select
                    name="account_type"
                    placeholder="Select"
                    value={_.filter(accountTypeOptions, ["value", accountType])}
                    options={accountTypeOptions}
                    onChange={e => setAccountType(_.get(e, "value", ""))} />
                  {errorMessage("account_type", accountType)}
                </div>
              </div>
              <div className="mt-4">
                <button
                  className="btn btn-red"
                  onClick={validateForm}
                  disabled={disableSubmit}
                  type="submit">
                  {disableSubmit
                    ? <Spinner animation="border" role="status" size="sm" />
                    : `Add Organisation`
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
