import React, { useEffect, useState } from "react"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import Loader from "components/Loader"
import { postGear } from "requesters/gearRequester"
import EditSaveButtons from "components/EditSaveCancelButton"
import { getUsers } from "requesters/userRequester"
import { getBaseUrl } from "../../../reducers"
import * as actionTypes from "../../../actions/types"
import GearBasicInput from "./GearBasicInput"
import GearCalendarSelect from "./GearCalendarSelect"

const gearStatesOptions = [
  { value: "available", label: "Available" },
  { value: "archived", label: "Archived" },
  { value: "assigned", label: "Assigned" },
  { value: "returned", label: "Returned" },
  { value: "service", label: "Service" },
  { value: "report_issue", label: "Report issue" },
]

export default function NewGearForm() {
    const baseUrl = useSelector(getBaseUrl)
    const dispatch = useDispatch()
    const { createState, gearCreatableAttr, postGearState } = useSelector(state => state.manageGearPage)
    const [users, setUsers] = useState({})
    const { gearCategories } = useSelector(state => state.options)
    const [gearIdentifierError, setGearIdentifierError] = useState(false)
    const [itemDescriptionError, setItemDescriptionError] = useState(false)
    const [categoryError, setCategoryError] = useState(false)
    const [gearIdentifier, setGearIdentifier] = useState("")
    const [itemDescription, setItemDescription] = useState("")
    const [category, setCategory] = useState("")
    const [userOptions, setUserOptions] = useState([])
    const [gearCategoriesOptions, setGearCategoriesOptions] = useState([])
    const [nextServiceError, setNextServiceError] = useState(false)
    const [nextServiceDate, setNextServiceDate] = useState("")
    const currentDate = new Date()

    const fetchData = () => {
      getUsers(baseUrl)
      .then(res => {
        if (res.status === 200) {
          setUsers(_.get(res.response, "user", {}))
          const userData = (_.get(res.response, "user", {}))
          const userList = []
          Object.keys(userData).forEach(key => {
            userList.push({
                  value:  userData[key].id,
                  label:  userData[key].attributes.name
              })
          })
          setUserOptions(userList)
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
    }

    useEffect(() => {
      fetchData()
    }, [baseUrl])

    const gearOptionsHandler = () => {
      const gearCategoriesList = []

      Object.keys(gearCategories).forEach(key => {
        gearCategoriesList.push({
            value:  gearCategories[key][1],
            label:  gearCategories[key][0]
        })
      })

      setGearCategoriesOptions(gearCategoriesList)
    }

    useEffect(() => {
      gearOptionsHandler()
    }, [gearCategories])


    const errorsHandler = () => {
      if (!gearIdentifier) {
        setGearIdentifierError(true)
      }
      if (!itemDescription) {
        setItemDescriptionError(true)
      }
      if (!category) {
        setCategoryError(true)
      }
      if (nextServiceDate) {
        setNextServiceError(nextServiceDate < currentDate)
      }
    }

    const postGearUpdates = () => {
      errorsHandler()
      if ((!nextServiceDate || nextServiceDate >= currentDate)
      && gearIdentifier && itemDescription && category) {
        dispatch({ type: actionTypes.MANAGE_GEARS_CREATE_SAVE })

        dispatch({ type: actionTypes.GEAR_POST_START })
        postGear(baseUrl, gearCreatableAttr)
          .then(res => {
            if (res.status === 201) {
              dispatch({ type: actionTypes.GEAR_POST_SUCCESS, data: res.response })
            }
          })
          .catch(err => {
            dispatch({ type: actionTypes.GEAR_POST_FAILURE })
            // eslint-disable-next-line no-console
            console.log(err)
          })
      }
    }

    function updateAttr(data) {
        dispatch({ type: actionTypes.MANAGE_GEARS_CREATE_UPDATE, data })
    }

    return (
      <div className="card border-0">
        {postGearState && (
        <div className="loader-overlay">
          <Loader />
        </div>
            )}
        <div className="card-body p-4">
          <div className="form-group row">
            <label className="col-3">Equipment Type*</label>
            <div className="col-9">
              <Select
                options={gearCategoriesOptions}
                onChange={event => {
                  updateAttr({ category: event.value })
                  setCategory(event.value)
                  setCategoryError(false)
                }} />
              {categoryError && (
                <div className="err-msg">Please choose an Equipment Type</div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3">ID/Serial No*</label>
            <div className="col-9">
              <input
                className="form-control"
                type="text"
                value={gearIdentifier}
                onChange={event => {
                  updateAttr({ gear_identifier: event.target.value })
                  setGearIdentifier(event.target.value)
                  setGearIdentifierError(false)
                  }} />
              {gearIdentifierError && (
                <div className="err-msg">Please add an Identifier</div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3">Item Description*</label>
            <div className="col-9">
              <input
                className="form-control"
                type="text"
                value={itemDescription}
                onChange={event => {
                  updateAttr({ item_description: event.target.value })
                  setItemDescription(event.target.value)
                  setItemDescriptionError(false)
                  }} />
              {itemDescriptionError && (
                <div className="err-msg">Please add a Description</div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3">Assigned to</label>
            <div className="col-9">
              <Select
                options={userOptions}
                onChange={event => { updateAttr({ user: event.value }) }} />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3">Status</label>
            <div className="col-9">
              <Select
                options={gearStatesOptions}
                defaultValue={{ value: "available", label: "Available" }}
                onChange={event => { updateAttr({ state: event.value }) }} />
            </div>
          </div>

          <div className="row mb-3 align-items-center">
            <div className="col-3">
              Service Item?
            </div>
            <div className="col-9">
              <div className="custom-control custom-switch">
                <input
                  className="custom-control-input"
                  id="gear-service-item-switch"
                  type="checkbox"
                  disabled={!createState}
                  onChange={event => {
                                    if (event.target.checked) {
                                        updateAttr({ is_service_item: "Yes" })
                                    } else {
                                        updateAttr({ is_service_item: "No" })
                                    }
                                }} />
                <label className="custom-control-label" htmlFor="gear-service-item-switch"></label>
              </div>
            </div>
          </div>
          <GearCalendarSelect {...{
                    title: "Next Service",
                    createState,
                    value: gearCreatableAttr.next_service,
                    setInputValue: value => {
                        updateAttr({ next_service: value })
                        setNextServiceDate(moment(value, "DD/MM/YYYY").toDate())
                        setNextServiceError(false)
                    },
                    errorMessage: nextServiceError && ("The date have to be in the future"),
                }} />
          <GearCalendarSelect {...{
                    title: "Date Purchased",
                    createState,
                    value: gearCreatableAttr.date_purchased,
                    setInputValue: value => {
                        updateAttr({ date_purchased: value })
                    },
                }} />
          <GearBasicInput {...{
                    title: "Value",
                    createState,
                    type: "number",
                    value: gearCreatableAttr.value,
                    setInputValue: value => {
                        updateAttr({ value })
                    },
                }} />

          <div className="mt-4">
            <EditSaveButtons
              createState={createState}
              save={postGearUpdates} />
          </div>
        </div>
      </div>
    )
}