import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Container, Pagination } from "react-bootstrap"
// import Pagination from "react-bootstrap/Pagination";
import { getUserNotifcations, markNotificationsRead } from "requesters/userRequester"
import FilterDropdown from "components/FilterDropdown"
import NotificationRow from "./NotificationRow"
import { getCurrentUser, getBaseUrl } from "../../reducers"

export default function NotificationTable() {
  const baseUrl = useSelector(getBaseUrl)
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role

  const [notifications, setNotifications] = useState([])
  const [byStatusName, setByStatusName] = useState("unread")

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(3);

  const [checkedNotifications, setCheckedNotifications] = useState([])

  const statusOptions = [["read", "read"], ["unread", "unread"]]

  const fetchData = pageNumber => {
    getUserNotifcations(baseUrl, pageNumber, { status_filter: byStatusName })
    .then(res => {
      if (res.status === 200) {
        setNotifications(res?.response?.data)
        setTotalPages(Number(res?.headers["x-total-pages"]))
        setCurrentPage(Number(res?.headers["x-current-page"]))
      }
    }).catch(err => console.error(err))
  }

  useEffect(() => {
      fetchData(currentPage)
  }, [baseUrl, byStatusName, currentPage])

  const handlePageChange = pageNumber => {
    if (pageNumber === 0) return;
    if (pageNumber === totalPages + 1) return;

    setCurrentPage(pageNumber);
  };

  const notificationsHandler = e => {
    if (e.target.checked) {
      setCheckedNotifications(notifications.map(notification => notification))
    } else {
      setCheckedNotifications([])
    }
  }

  const toggleCheck = notification => {
    if (checkedNotifications.includes(notification)) {
      setCheckedNotifications(checkedNotifications.filter(checked => checked.id !== notification.id))
    } else {
      setCheckedNotifications([...checkedNotifications, notification])
    }
    return checkedNotifications
  }

  const markNotificationsReadHandler = () => {
    const notificationIds = checkedNotifications.map(notification => notification.id)
    markNotificationsRead(baseUrl, notificationIds)
    .then(() => {
      window.location.reload()
    })
  }

  return (
    <>
      <Container fluid>
        <h2>Notifications</h2>
        <div className="today mt-4">
          <div className="table-listing-filters d-md-flex justify-content-between">
            <div>
              <div className="btn-group mr-2">
                <FilterDropdown title="Status" filteredName={byStatusName} setFilter={setByStatusName} options={statusOptions} />
              </div>
            </div>

            <Pagination size="lg">
              <Pagination.Prev disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)} />
              <Pagination.Item>{currentPage}</Pagination.Item>
              <Pagination.Next disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)} />
            </Pagination>


          </div>
          <div className="table-responsive">
            <div>
              <button onClick={() => markNotificationsReadHandler()} type="button" className="btn btn-outline-danger btn-md">Mark selected as read</button>
            </div>
            <table className="table table-listing table-hover">
              <thead>
                <tr>
                  <th>
                    <div className="custom-control custom-checkbox mr-2">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="select_all_notifications"
                        onChange={e => notificationsHandler(e)} />
                      <label className="custom-control-label" htmlFor="select_all_notifications">Select All</label>
                    </div>
                  </th>
                  <th>Sent At</th>
                  {systemRole.match(/admin/) && (
                  <th>Sent To</th>
                )}
                  <th>Title</th>
                  <th>Body</th>
                  <th>State</th>
                  <th>Path</th>
                </tr>
              </thead>
              <tbody>
                {notifications.map(notification => (
                  <NotificationRow toggleCheck={toggleCheck} key={`table-row-${notification.id}`} checkedNotifications={checkedNotifications} notification={notification} systemRole={systemRole} />
                ))}
              </tbody>
            </table>
            {notifications.length === 0 && <div>No notifications</div>}
          </div>
        </div>
      </Container>
    </>
  )
}