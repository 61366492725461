import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  REGISTERED_SURVEYORS_MANAGER,
  REGISTERED_SURVEYORS_USER,
  ADMIN,
} from "utils/constants";
import { roleCheck } from "utils/index";
import { getCurrentUser } from "../../reducers";
import DraggableRSRow from "./DraggableRSRow";

export default function RegSurveyorsTable({
  jobServices, regSurveyorOptions, fetchData, readOnly, filtersApplied
}) {
  const [jobsByRs, setJobsByRs] = useState(jobServices)
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role

  const isRegisteredSurveyorsUser = roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_USER)
  const isRegisteredSurveyorsManager = roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)

  const isAdminManager = roleCheck(systemRole, currentTeamRole, ADMIN)

  useEffect(() => {
    setJobsByRs(jobServices)
  }, [jobServices])

  return (
    <div className="table-responsive">
      <table className="table table-listing table-hover">
        <thead>
          <tr>
            <th></th>
            <th>Job No.</th>
            <th>Job Address</th>
            <th>Client Name</th>
            <th>Job Service</th>
            <th className="text-center absorbing-column">Date of Field Work</th>
            {isRegisteredSurveyorsManager && (
              <th className="text-center absorbing-column">Surveyor</th>
            )}
            <th className="text-center absorbing-column">
              <span className="mr-1">Registerd Surveyor</span>
              {/* <span type="button" onClick={() => setSwitchOrder(!switchOrder)}>
                <img src={sortIcon} />
              </span> */}
            </th>
            {/* <th>Priority</th> */}
            <th className="text-center">Status</th>
            {(isAdminManager || !isRegisteredSurveyorsUser) && (
              <th className="text-center absorbing-column">Due Date</th>
            )}
            <th></th>
          </tr>
        </thead>
        {!!jobServices.length && (
          <DraggableRSRow
            jobServices={jobsByRs}
            setJobServices={setJobsByRs}
            regSurveyorOptions={regSurveyorOptions}
            fetchData={fetchData}
            filtersApplied={filtersApplied}
            readOnly={readOnly} />
        )}
      </table>
    </div>
  );
}
