import React, { useEffect, useState } from "react";
import Select from "react-select"
import { getSurveyors } from "requesters/userRequester"
import { useSelector } from 'react-redux'
import { getBaseUrl, getResource } from "../../../reducers"
import { postBookingAsUser, removeBookingAsUser, updateJobTransition, updateJob } from "requesters/jobRequester"
import SweetAlert from "react-bootstrap-sweetalert";
import cx from "classnames"


export default function RegSurveyorStage({ job, isActive, reloadData }) {
  return (
    <div
      id="accounts"
      className={isActive ? "tab-pane fade active show" : "tab-pane fade"}>
    </div>
  )
}