import React, { useEffect, useState } from "react"
import {
  Nav,
  NavDropdown,
  Navbar,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap"
import { useSelector } from "react-redux"
import { Link, NavLink } from "react-router-dom"
import { getScopedUserNotifcations, getUserNotifcations, updateUserNotification } from "requesters/userRequester"
import {
  ACCOUNTS,
  PROCESSOR,
  DRAFTING,
  DRAFTING_3D,
  DRAFTING_OVERSEAS,
  DRAFTING_OVERSEAS_LEBANON,
  DRAFTING_OVERSEAS_PHILIPPINES,
  OPERATIONS,
  RECEPTION,
  ASSET_ONLY,
  REGISTERED_SURVEYORS,
  REGISTERED_SURVEYORS_MANAGER,
  DRAFTING_3D_MANAGER,
  DRAFTING_MANAGER,
  SALES,
  SURVEYORS
} from "utils/constants"
import { roleCheck } from "utils/index"
import {
  bellIcon,
  calendarIcon,
  draftIcon,
  flagIcon,
  folderIcon,
  homeIcon,
  invoiceIcon,
  dataProcessing,
  performanceIcon,
  settingsIcon,
  signatureIcon,
  clipboardIcon,
  userIcon
} from "components/Icons"
import { getBaseUrl, getCurrentRole, getCurrentUser } from "../reducers"

export default function NavBar() {
  const baseUrl = useSelector(getBaseUrl)
  const currentUser = useSelector(getCurrentUser)
  const currentRole = useSelector(getCurrentRole)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role
  const isSalesManager = roleCheck(systemRole, currentRole, SALES) && currentUser.attributes.default_team_role === "manager"
  const isDraftingManager = roleCheck(systemRole, currentTeamRole, DRAFTING_3D_MANAGER) || roleCheck(systemRole, currentTeamRole, DRAFTING_MANAGER)
  const isRSManager = roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)
  const isRegSurveyor = roleCheck(systemRole, currentRole, REGISTERED_SURVEYORS)

  const [notifications, setNotifications] = useState([])
  const [scopedNotifications, setScopedNotifications] = useState([])

  if (!currentUser) window.location = "/login"

  const fetchData = () => {
    getUserNotifcations(baseUrl, 1).then(res => {
      if (res.status === 200) {
        setNotifications(res?.response?.data)
      }
    }).catch(err => console.error(err))
    getScopedUserNotifcations(baseUrl)
      .then(res => {
        if (res.status === 200) {
          setScopedNotifications(res?.response?.data)
        }
      }).catch(err => console.error(err))
  }

  const updateNotification = notification => {
    if (notification.attributes.state !== "read") {
      updateUserNotification(baseUrl, notification.id).then(() => {
        fetchData()
      })
    }
  }

  const filterUnreadNotifications = _.filter(notifications, notification => notification.attributes.state === "unread");

  useEffect(() => {
    fetchData()
  }, [baseUrl])

  const {
    attributes: {
      name
    }
  } = currentUser


  const flaggedJobsAllowed = () => {
    if (
      roleCheck(systemRole, currentRole, OPERATIONS)
      || roleCheck(systemRole, currentRole, SURVEYORS)
      || roleCheck(systemRole, currentRole, DRAFTING)
      || roleCheck(systemRole, currentRole, RECEPTION)
      || roleCheck(systemRole, currentRole, DRAFTING_3D)
      || isRegSurveyor
      || roleCheck(systemRole, currentRole, ACCOUNTS)
    ) {
      return true
    }
    return false
  }

  const canAccessPerformance = isSalesManager || isDraftingManager
                              || roleCheck(systemRole, currentRole, OPERATIONS)
                              || isRSManager

  const isAssetsOnlyRole = currentRole.includes(ASSET_ONLY)
  const canAccessLiveJobs = !isAssetsOnlyRole
  const canAccessRegSurveyorActivities = isRegSurveyor

  return (
    <Navbar bg="transparent" expand="lg" className="navbar-dark main-nav" collapseOnSelect>
      <Navbar.Brand as={NavLink} to="/">CASurveyors</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className={`main-nav-items ${gon.env === "staging" ? "green-nav" : ""}`}>
          <OverlayTrigger
            placement="auto"
            overlay={<Tooltip id="tooltip-dashboard">Dashboard</Tooltip>}>
            <Nav.Link eventKey="dashboard" as={NavLink} to="/" exact>
              <div className="icon-container">
                <img alt="Dashboard" src={homeIcon} />
              </div>
              <span>Dashboard</span>
            </Nav.Link>
          </OverlayTrigger>

          {/* <Nav.Link eventKey="projects" as={NavLink} to="/jobs" exact>
            <div className="icon-container">
              <img alt="Projects" src={folderIcon} />
            </div>
            <span>Projects</span>
          </Nav.Link> */}

          { canAccessLiveJobs && (
            <OverlayTrigger
              placement="auto"
              overlay={<Tooltip id="tooltip-live-job-status">Live Job Status</Tooltip>}>
              <Nav.Link eventKey="live-job-status" as={NavLink} to="/live-job-status" exact>
                <div className="icon-container">
                  {/* TODO: check the  icon */}
                  <img alt="Live Job Status" src={folderIcon} />
                </div>
                <span>Live Job Status</span>
              </Nav.Link>
            </OverlayTrigger>
          )}

          {flaggedJobsAllowed() && (
            <OverlayTrigger
              placement="auto"
              overlay={<Tooltip id="tooltip-flagged-jobs">Flagged Jobs</Tooltip>}>
              <Nav.Link eventKey="flagged-jobs" as={NavLink} to="/flagged-jobs">
                <div className="icon-container">
                  <img alt="Flagged Jobs" src={flagIcon} />
                </div>
                <span>Flagged Jobs</span>
              </Nav.Link>
            </OverlayTrigger>
          )}

          {(roleCheck(systemRole, currentRole, OPERATIONS) || roleCheck(systemRole, currentRole, SALES) || roleCheck(systemRole, currentRole, SURVEYORS) || roleCheck(systemRole, currentRole, RECEPTION) || roleCheck(systemRole, currentRole, ACCOUNTS)) && (
            <OverlayTrigger
              placement="auto"
              overlay={<Tooltip id="tooltip-schedule">Schedule</Tooltip>}>
              <Nav.Link eventKey="schedules" as={NavLink} to="/schedules">
                <div className="icon-container">
                  <img alt="Schedule" src={calendarIcon} />
                </div>
                <span>Schedule</span>
              </Nav.Link>
            </OverlayTrigger>
          )}

          { roleCheck(systemRole, currentRole, PROCESSOR) && (
            <OverlayTrigger
              placement="auto"
              overlay={<Tooltip id="tooltip-processing">Processing</Tooltip>}>
              <Nav.Link eventKey="processing" as={NavLink} to="/processing">
                <div className="icon-container">
                  <img alt="Processing" src={invoiceIcon} />
                </div>
                <span>Processing</span>
              </Nav.Link>
            </OverlayTrigger>
          )}

          {(roleCheck(systemRole, currentRole, (DRAFTING)) || roleCheck(systemRole, currentRole, (DRAFTING_3D)) || roleCheck(systemRole, currentRole, DRAFTING_OVERSEAS) || roleCheck(systemRole, currentRole, DRAFTING_OVERSEAS_LEBANON) || roleCheck(systemRole, currentRole, DRAFTING_OVERSEAS_PHILIPPINES)) && (
            <OverlayTrigger
              placement="auto"
              overlay={<Tooltip id="tooltip-drafting">Drafting</Tooltip>}>
              <Nav.Link eventKey="drafting" as={NavLink} to="/drafting">
                <div className="icon-container">
                  <img alt="Drafting" src={draftIcon} />
                </div>
                <span>Drafting</span>
              </Nav.Link>
            </OverlayTrigger>
          )}
          {isRegSurveyor && (
            <OverlayTrigger
              placement="auto"
              overlay={<Tooltip id="tooltip-registered-surveyors">Registered Surveyors</Tooltip>}>
              <Nav.Link eventKey="registered-surveyors" as={NavLink} to="/registered-surveyors">
                <div className="icon-container">
                  <img alt="Registered Surveyors" src={signatureIcon} />
                </div>
                <span>Registered Surveyors</span>
              </Nav.Link>
            </OverlayTrigger>
          )}
          {canAccessRegSurveyorActivities && (
            <OverlayTrigger
              placement="auto"
              overlay={<Tooltip id="tooltip-registered-surveyors-activities">Registered Surveyors Activities</Tooltip>}>
              <Nav.Link eventKey="registered-surveyors-activities" as={NavLink} to="/registered-surveyors-activities">
                <div className="icon-container">
                  <img alt="Registered Surveyors Activities" src={clipboardIcon} />
                </div>
                <span>Registered Surveyors Activities</span>
              </Nav.Link>
            </OverlayTrigger>
          )}
          {roleCheck(systemRole, currentRole, ACCOUNTS) && (
            <OverlayTrigger
              placement="auto"
              overlay={<Tooltip id="tooltip-accounts">Accounts</Tooltip>}>
              <Nav.Link eventKey="accounts" as={NavLink} to="/accounts">
                <div className="icon-container">
                  <img alt="Accounts" src={invoiceIcon} />
                </div>
                <span>Accounts</span>
              </Nav.Link>
            </OverlayTrigger>
          )}

          { canAccessPerformance && (
            <OverlayTrigger
              placement="auto"
              overlay={<Tooltip id="tooltip-performance">Performance</Tooltip>}>
              <Nav.Link eventKey="performance" as={NavLink} to="/performance">
                <div className="icon-container">
                  <img alt="Performance" src={performanceIcon} />
                </div>
                <span>Performance</span>
              </Nav.Link>
            </OverlayTrigger>
          )}

          {(roleCheck(systemRole, currentRole, ACCOUNTS) || roleCheck(systemRole, currentRole, OPERATIONS) || isRSManager) && (
            <OverlayTrigger
              placement="auto"
              overlay={<Tooltip id="tooltip-contacts">Contacts</Tooltip>}>
              <Nav.Link eventKey="contacts" as={NavLink} to="/contacts">
                <div className="icon-container">
                  <img alt="Contacts" src={userIcon} />
                </div>
                <span>Contacts</span>
              </Nav.Link>
            </OverlayTrigger>
          )}

          {(roleCheck(systemRole, currentRole, ACCOUNTS) || roleCheck(systemRole, currentRole, OPERATIONS)) && (
            <OverlayTrigger
              placement="auto"
              overlay={<Tooltip id="tooltip-settings">Settings</Tooltip>}>
              <Nav.Link eventKey="settings" as={NavLink} to="/settings">
                <div className="icon-container">
                  <img alt="Settings" src={settingsIcon} />
                </div>
                <span>Settings</span>
              </Nav.Link>
            </OverlayTrigger>
          )}
        </Nav>
        <Nav className="ml-auto top-nav-item">
          {/* {role.match(/admin/) && (
            <NavDropdown title="View as:" id="view-as-dropdown">
              <NavDropdown.Item eventKey="view-as-admin" as={Link} to="#" onClick={e => updateCurrentRole(e)}>Admin</NavDropdown.Item>
              <NavDropdown.Item eventKey="view-as-sales" as={Link} to="#" onClick={e => updateCurrentRole(e)}>Sales</NavDropdown.Item>
              <NavDropdown.Item eventKey="view-as-operations" as={Link} to="#" onClick={e => updateCurrentRole(e)}>Operations</NavDropdown.Item>
              <NavDropdown.Item eventKey="view-as-drafting" as={Link} to="#" onClick={e => updateCurrentRole(e)}>Drafting</NavDropdown.Item>
              <NavDropdown.Item eventKey="view-as-registered-surveyors" as={Link} to="#" onClick={e => updateCurrentRole(e)}>Registered Surveyors</NavDropdown.Item>
              <NavDropdown.Item eventKey="view-as-accounts" as={Link} to="#" onClick={e => updateCurrentRole(e)}>Accounts</NavDropdown.Item>
              <NavDropdown.Item eventKey="view-as-reception" as={Link} to="#" onClick={e => updateCurrentRole(e)}>Reception</NavDropdown.Item>
              <NavDropdown.Item eventKey="view-as-surveyors" as={Link} to="#" onClick={e => updateCurrentRole(e)}>Surveyor</NavDropdown.Item>
            </NavDropdown>
          )} */}
          <NavDropdown title={name} alignRight id="user-dropdown">
            <NavDropdown.Item eventKey="settings/account-settings" as={Link} to="/settings/account-settings">Account Settings</NavDropdown.Item>
            <NavDropdown.Item eventKey="settings/my-gear" as={Link} to="/settings/my-gear">My Assets</NavDropdown.Item>
            <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
          </NavDropdown>
          <>
            <NavDropdown
              className="dropdown"
              title={(
                <div className="icon-container" style={{ display: "inline-block" }}>
                  <img alt="Contacts" src={bellIcon} />
                </div>
              )}
              alignRight
              id="user-dropdown">
              {scopedNotifications && scopedNotifications.map(notification => (
                <NavDropdown.Item
                  key={notification.id}
                  as={Link}
                  className="notification"
                  onClick={() => updateNotification(notification)}
                  to={notification.attributes.link_url}>
                  <div>
                    <div>
                      <strong>{(notification.attributes.job_no ? `${notification.attributes.job_no} - ` : "") + notification.attributes.title}</strong>
                      {" "}
                      &nbsp;&nbsp;
                      {" "}
                      {notification.attributes.created_at}
                    </div>
                    {notification.attributes.body}
                  </div>
                  <div>
                    <strong>{notification?.attributes?.state}</strong>
                  </div>
                </NavDropdown.Item>
              ))}
              {scopedNotifications.length === 0 && <strong>No notifications</strong>}
              <NavDropdown.Item href="/notifications">View all</NavDropdown.Item>
            </NavDropdown>
            {filterUnreadNotifications && <div className="notification-bell">{filterUnreadNotifications.length}</div>}
          </>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}
