import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Container } from "react-bootstrap"

import { getJobServicesDrafting } from "requesters/jobServiceRequester"
import { getDraftmen } from "requesters/userRequester"
import { getFilteredProducts } from "requesters/productRequester"

import {
  DRAFTING_OVERSEAS_MANAGER,
  DRAFTING_OVERSEAS,
  DRAFTING_MANAGER,
  DRAFTING_3D_MANAGER,
  TO_OVERSEAS_DRAFTING,
  DRAFTING_OVERSEAS_USER,
  DRAFTING_3D_OVERSEAS_LEBANON_MANAGER,
  DRAFTING_3D_OVERSEAS_PHILIPPINES_MANAGER,
  DRAFTING_OVERSEAS_LEBANON,
  DRAFTING_OVERSEAS_PHILIPPINES,
  DRAFTING_3D_OVERSEAS_LEBANON_USER,
  DRAFTING_3D_OVERSEAS_PHILIPPINES_USER,
  DRAFTING_3D,
  DRAFTING
} from "utils/constants"

import { roleCheck } from "utils/index"

import SearchInput from "components/SearchInput"
import FilterDropdown from "components/FilterDropdown"
import Loader from "components/Loader"
import AnchorButton from "components/AnchorButton"
import _ from "lodash"
import DraftingTable from "./DraftingTable"

import { getBaseUrl, getCurrentRole, getCurrentUser } from "../../reducers"

export default function DraftingPage() {
  const baseUrl = useSelector(getBaseUrl)
  const currentRole = useSelector(getCurrentRole)
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  // const { attributes: { role } } = currentUser
  const currentTeamRole = currentUser.attributes.current_team_role
  // const readOnly = !((/admin/i.test(role) || currentTeamRole.includes(DRAFTING_MANAGER) || currentTeamRole.includes(DRAFTING_3D_MANAGER)))
  const readOnly = !(roleCheck(systemRole, currentTeamRole, DRAFTING_MANAGER) || roleCheck(systemRole, currentTeamRole, DRAFTING_3D_MANAGER) || roleCheck(systemRole, currentTeamRole, DRAFTING_3D_OVERSEAS_PHILIPPINES_MANAGER) || roleCheck(systemRole, currentTeamRole, DRAFTING_3D_OVERSEAS_LEBANON_MANAGER))

  const liveJobsAllowed = () => {
    if (roleCheck(systemRole, currentRole, DRAFTING) || roleCheck(systemRole, currentRole, DRAFTING_3D) || roleCheck(systemRole, currentRole, DRAFTING_OVERSEAS) || roleCheck(systemRole, currentRole, DRAFTING_OVERSEAS_LEBANON) || roleCheck(systemRole, currentRole, DRAFTING_OVERSEAS_PHILIPPINES)) {
      return true
    }
    return false
  }

  if (!liveJobsAllowed()) window.location = "/"

  const [requesting, setRequesting] = useState(true)
  const [jobsDrafting, setJobsDrafting] = useState([])
  const [draftmenOptions, setDraftmenOptions] = useState([])
  const jobStatusOptions = [
    ["Drafting Required", "drafting_assignment_required"],
    ["QA Required", "local_qa"],
    ["Overseas", "overseas_drafting_assignment_required"],
    ["Overseas QA", "overseas_qa"],
    ["Report Required", "report_required"],
    ["Drafting Overseas Required", "to_overseas_drafting"],
    ["Local Drafting", "to_local_drafting"],
  ]

  const [jobTypes, setJobTypes] = useState([])

  const isOverseasTeam = currentRole?.includes(DRAFTING_OVERSEAS) || currentRole?.includes(DRAFTING_OVERSEAS_LEBANON) || currentRole?.includes(DRAFTING_OVERSEAS_PHILIPPINES)
  const isOverseasManager = currentTeamRole.includes(DRAFTING_OVERSEAS_MANAGER) || currentTeamRole.includes(DRAFTING_3D_OVERSEAS_LEBANON_MANAGER) || currentTeamRole.includes(DRAFTING_3D_OVERSEAS_PHILIPPINES_MANAGER)
  const isOverseasUser = currentTeamRole.includes(DRAFTING_OVERSEAS_USER) || currentTeamRole.includes(DRAFTING_3D_OVERSEAS_LEBANON_USER) || currentTeamRole.includes(DRAFTING_3D_OVERSEAS_PHILIPPINES_USER)
  const isLocalDrafting = currentUser.attributes.default_team === "drafting"
  const is2DTeam = currentRole.includes(DRAFTING)

  const byDraftsmanFilter = () => {
    if (isOverseasManager) {
      return undefined
    }
    if (readOnly) {
      return currentUser.id
    }
    return undefined
  }

  const initialFilters = {
    by_status: (isOverseasUser && !isLocalDrafting) ? TO_OVERSEAS_DRAFTING : undefined,
    by_draftman: byDraftsmanFilter(),
    by_job_type: undefined,
    by_keyword: undefined
  }

  const [filters, setFilters] = useState(initialFilters)
  const [isFilteredByDropDown, setIsFilteredByDropDown] = useState(false)
  const byStatusName = _.get(jobStatusOptions, `${_.findIndex(jobStatusOptions, option => option[1] === filters.by_status)}.0`)
  const byJobTypeName = _.get(jobTypes, `${_.findIndex(jobTypes, type => type[1] === filters.by_job_type)}.0`)
  const byDraftmenName = _.get(draftmenOptions, `${_.findIndex(draftmenOptions, option => option[1] === filters.by_draftman)}.0`)
  const filtersApplied = Object.entries(filters).some(([key, val]) => 
    key !== 'by_draftman' && val !== initialFilters[key]
  );

  const clearFilters = () => {
    setFilters(initialFilters)
    setIsFilteredByDropDown(true)
  }

  const setByStatus = val => {
    setFilters({ ...filters, by_status: val })
    setIsFilteredByDropDown(true)
  }

  const setByJobType = val => {
    setFilters({ ...filters, by_job_type: val })
    setIsFilteredByDropDown(true)
  }

  const setBySearch = val => {
    setFilters({ ...filters, by_keyword: val })
  }

  const setByDraftmen = val => {
    setFilters({ ...filters, by_draftman: val })
    setIsFilteredByDropDown(true)
  }

  useEffect(() => {
    setFilters({ ...filters })
  }, [])

  const fetchData = () => {
    setRequesting(true)
    getJobServicesDrafting(baseUrl, filters)
      .then(res => {
        if (res.status === 200) {
          const jobServices = _.get(res, "response", {})
          setJobsDrafting(jobServices)
          setRequesting(false)
        }
      })
      .catch(() => {
        setRequesting(false)
      })
  }

  const getDraftmenHandler = () => {
    setRequesting(true)
    getDraftmen(baseUrl).then(res => {
      if (res.status === 200) {
        const newData = res.response.map(item => {
          const { label, value, ...other } = item
          return [`${label}`, `${value}`, { ...other }]
        })
        setDraftmenOptions(newData)
      }
    })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
    setRequesting(false)
  }

  useEffect(() => {
    getFilteredProducts(baseUrl, "drafting")
      .then(res => {
        if (res.status === 200) {
          const products = Object.values(_.get(res.response, "product", {}))
          const allJobTypes = products.map(js => ([js.attributes.name, js.id]))
          setJobTypes(allJobTypes)
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }, [])

  const handleSubmit = e => {
    setRequesting(true)
    e.preventDefault()
    fetchData()
  }

  useEffect(() => {
    fetchData()
    getDraftmenHandler()
    setIsFilteredByDropDown(false)
  }, [isFilteredByDropDown, currentRole])

  return (
    <Container fluid className="">
      <div className="container-inner">
        <div className="d-flex justify-content-between align-items-center header-tabs-container">
          <div className="header-tabs">
            <h1>Drafting</h1>
          </div>
        </div>
        <div className="table-listing-filters d-md-flex justify-content-between">
          <div>
            <div className="btn-group mr-2">
              <SearchInput value={filters.by_keyword} setValue={setBySearch} handleSubmit={handleSubmit} disableSubmit={requesting} />
            </div>
            {!isOverseasTeam && (
              <div className="btn-group mr-2">
                <FilterDropdown title="Status" filteredName={byStatusName} setFilter={setByStatus} options={jobStatusOptions} />
              </div>
            )}
            <div className="btn-group mr-2">
              <FilterDropdown title="Job Service" filteredName={byJobTypeName} setFilter={setByJobType} options={jobTypes} />
            </div>
            {(isOverseasManager || !readOnly) && (
              <div className="btn-group mr-2">
                <FilterDropdown title="Draftsman" filteredName={byDraftmenName} setFilter={setByDraftmen} options={draftmenOptions} />
              </div>
            )}
            <div className="btn-group">
              <AnchorButton
                className="f-small"
                onClick={clearFilters}>
                Clear Filters
              </AnchorButton>
            </div>
          </div>
        </div>
        {requesting ? <Loader />
          : (
            <JobServiceByDraftsmanTables
              jobsDrafting={jobsDrafting}
              setJobsDrafting={setJobsDrafting}
              draftmenOptions={draftmenOptions}
              fetchData={fetchData}
              is2DTeam={is2DTeam}
              filtersApplied={filtersApplied}
              readOnly={readOnly} />
          )}
      </div>
    </Container>
  )
}


const JobServiceByDraftsmanTables = ({
  readOnly,
  filtersApplied,
  fetchData,
  draftmenOptions,
  jobsDrafting,
  setJobsDrafting,
  is2DTeam
}) => {
  const draftsmen = _.isEmpty(jobsDrafting) ? [] : Object.keys(jobsDrafting).sort()
  draftsmen.sort((a, b) => {
    if (a === "Unassigned") return -1;
    if (b === "Unassigned") return 1;
    if (a === "Unassigned Overseas") return -1;
    if (b === "Unassigned Overseas") return 1;
    return 0;
  });
  return (
    <>
      {!!draftsmen?.length && draftsmen.map(draftsman => {
        const jobServicesDraftsman = jobsDrafting[draftsman]
        return (
          (!!jobServicesDraftsman?.length && (
            <div key={`date-${draftsman}`}>
              <h3 className="text-dark pt-5">{draftsman}</h3>
              <DraftingTable
                jobsDrafting={jobServicesDraftsman}
                setJobsDrafting={setJobsDrafting}
                draftmenOptions={draftmenOptions}
                fetchData={fetchData}
                is2DTeam={is2DTeam}
                filtersApplied={filtersApplied}
                readOnly={readOnly} />
            </div>
          ))
        )
      })}
    </>
  )
}
