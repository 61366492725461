import React from "react"
import { dropdownIcon } from "components/Icons"

import { Link } from "react-router-dom"

export default function JobRow({ job }) {
  const {
    id, attributes
  } = job

  if (attributes) {
    const {
      jobNo,
      clientName,
      contact,
      jobAddress,
      jobService,
      status,
      surveyor,
      preferredBookingDate,
      notes

    } = attributes

    return (
      <tr>
        <td>{jobNo}</td>
        <td>{clientName}</td>
        <td>{contact}</td>
        <td>{jobAddress}</td>
        <td>{jobService}</td>
        <td>{status}</td>
        <td>{surveyor}</td>
        <td>{preferredBookingDate}</td>
        <td>{notes}</td>
        <td className="text-right">
          <Link to={`/jobs-new/${id}`}>Details</Link>
        </td>
      </tr>
    )
  }
  return null
}
