import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { updateInvocie } from "requesters/jobServiceRequester";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify"
import { getBaseUrl } from "../../../reducers";
import { INVOICE_TYPES } from "../../../../../utils/constants";

const EditInvoiceModal = ({
  show, onHide, jobService, invoice, reloadData
}) => {
  const invoiceId = invoice.data.id
  const {
    jobServiceLabel,
    issueDate,
    amountPaid,
    gstPaid,
  } = invoice.data.attributes;

  const baseUrl = useSelector(getBaseUrl)

  const {
    attributes: { hasFinalInvoice, hasDepositInvoice }
  } = jobService;

  const [loading, setLoading] = useState(false)
  const [invoiceType, setInvoiceType] = useState(null)
  const [price, setPrice] = useState(invoice.data.attributes.price)

  const isAllowedToEditInvoiceType = (invoice.data.attributes.invoiceType === "deposit" && !hasFinalInvoice) || (invoice.data.attributes.invoiceType === "final" && !hasDepositInvoice)

  useEffect(() => {
    if (invoice) {
      setLoading(false)
      setInvoiceType({ value: invoice.data.attributes.invoiceType, label: invoice.data.attributes.invoiceTypeLabel, title: invoice.data.attributes.invoiceTypeLabel });
      setPrice(invoice.data.attributes.price);
    }
  }, [invoice]);

  const handleInvoiceTypeChange = e => {
    if (e.value === "deposit" && hasDepositInvoice && invoice.data.attributes.invoiceType !== "deposit") {
      swal.fire(
        "Error",
        "Deposit invoice already exists.",
        "error"
      );
    } else if (e.value === "final" && hasFinalInvoice && invoice.data.attributes.invoiceType !== "final") {
      swal.fire(
        "Error",
        "Final invoice already exists.",
        "error"
      );
    } else {
      setInvoiceType(e)
    }
  }

  const handlePriceChange = e => {
    setPrice(e.target.value)
  }

  const preventMinus = e => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  const handleUpdateInvoice = () => {
    setLoading(true)
    updateInvocie(baseUrl, jobService.id, invoiceId, invoiceType.value, price)
      .then(res => {
        if (res.status === 200) {
          onHide(false);
          reloadData();
        } else {
          setLoading(false)
          toast.error(
            <div>
              <span role="img" aria-label="failure-icon">❌</span>
              <span className="ml-2">{res?.error?.response?.data?.errors[0]?.detail}</span>
            </div>,
            {
              toastId: "container-inner",
              containerId: "main"
            }
          )
        }
      })
      // eslint-disable-next-line no-console
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <Modal show={show} onHide={onHide} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title><h4 className="modal-title">Edit Invoice</h4></Modal.Title>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          <div className="form-group">
            <label>Job Service</label>
            <div>
              <input
                name="job_service"
                value={jobServiceLabel}
                disabled
                className="form-control" />
            </div>
          </div>
          <div className="form-group">
            <label>Issue Date</label>
            <div>
              <input
                name="issue_date"
                value={issueDate}
                disabled
                className="form-control" />
            </div>
          </div>
          <div className="form-group">
            <label>Invoice Type</label>
            <div>
              {isAllowedToEditInvoiceType ? (
                <Select
                  name="invoice_type"
                  onChange={handleInvoiceTypeChange}
                  options={INVOICE_TYPES}
                  value={invoiceType}
                  placeholder="Select type" />
              ) : (
                <input
                  value={invoiceType?.label}
                  disabled
                  className="form-control" />
              )}
            </div>
          </div>
          <div className="form-group">
            <label>Amount</label>
            <div>
              <input
                name="amount"
                type="number"
                min="0"
                value={price}
                onChange={handlePriceChange}
                onKeyPress={preventMinus}
                className="form-control" />
            </div>
          </div>
          <div className="form-group">
            <label>Amount Paid</label>
            <div>
              <input
                name="amount_paid"
                value={amountPaid}
                disabled
                className="form-control" />
            </div>
          </div>
          <div className="form-group">
            <label>GST Paid</label>
            <div>
              <input
                name="gst_paid"
                value={gstPaid}
                disabled
                className="form-control" />
            </div>
          </div>
        </form>
        <div className="modal-footer d-flex justify-content-between p-0 mb-4 mt-5">
          <div>
            <button
              className="btn btn-red btn-lg mr-2"
              onClick={() => {
                handleUpdateInvoice()
              }}
              type="submit"
              disabled={loading}>
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default EditInvoiceModal
