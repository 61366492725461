import customAxios from "./customAxios";
import normalize from "json-api-normalizer"

export const postComment = (baseUrl, newComment) => {
    const path = `${baseUrl}/comments/save`
    return customAxios.post(path, {
                                commentable_id: newComment.commentableId,
                                commentable_type: newComment.commentableType,
                                body: newComment.commentBody,
                                user_id: newComment.currentUserId,
                                comment_type: newComment.commentType
                            })
        .then(({ data, status }) => ({ response: data.json, status }))
        .catch(error => ({ error }))
}

export const getComment = (baseUrl, commentableId, commentableType) => {
    const path = `${baseUrl}/comments/get`
    return customAxios.get(path, { params: { commentable_id: commentableId, commentable_type: commentableType} })
        .then(({ data, status }) => ({ response: normalize(data), status }))
        .catch(error => ({ error }))
}
