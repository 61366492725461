import Loader from "components/Loader"
import React from "react"
import {
  Col,
  Row,
} from "react-bootstrap"

export default function HisotryDetail({
  gearHistory, requesting
}) {
  if (requesting) return <Loader />
  return (
    <>
      <h2 className="mb-1">History</h2>
      <Row>
        <Col md={8}>
          <div className="card border-0">
            <div className="card-body p-4">
              <div className="row mb-2">
                <div className="col-3">Action</div>
                <div className="col-9">{gearHistory.actionHumanized}</div>
              </div>
              <div className="row mb-2">
                <div className="col-3">Notes</div>
                <div className="col-9">{gearHistory?.notes}</div>
              </div>
              <div className="row mb-2">
                <div className="col-3">Created by</div>
                <div className="col-9">{gearHistory?.createdByName}</div>
              </div>
              <div className="row mb-2">
                <div className="col-3">Reported at</div>
                <div className="col-9">{gearHistory?.reportDate}</div>
              </div>

              <div className="mb-2 mt-5">
                <h5 className="card-title">Photos</h5>
              </div>
              <div className="row ml-0 mt-1">
                {gearHistory?.uploadedFiles?.data.length ? gearHistory?.uploadedFiles?.data.map(
                  file => (
                    <div className="mr-3 mt-2" key={file.id}>
                      <a
                        href={file?.attributes?.filePath}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={file?.attributes?.fileName}>
                        <img
                          src={file?.attributes?.filePath}
                          width="80"
                          height="80"
                          className="img-preview" />
                      </a>
                    </div>
                  )
                ) : (
                  <div>No Photo uploaded.</div>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}
