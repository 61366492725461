import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert"
import { useSelector } from "react-redux"
import { roleCheck } from "utils/index"
import { requestCheckSurvey } from "../../../../../../requesters/jobServiceRequester";
import { getBaseUrl, getCurrentUser, getCurrentRole } from "../../../../reducers";
import { OPERATIONS } from "../../../../../../utils/constants";

const BookInCheckSurvey = ({
  jobService, reloadData, buttonClasses
}) => {
  const {
    attributes: {
      state,
      jobId
    }
  } = jobService
  const baseUrl = useSelector(getBaseUrl)
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentRole = useSelector(getCurrentRole)
  const [requesting, setRequesting] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)

  const isAdminOrOperations = roleCheck(systemRole, currentRole, OPERATIONS)

  const onGenerateCheckSurvey = () => {
    if (requesting) return;

    setShowConfirmation(false)
    setRequesting(true)
    requestCheckSurvey(baseUrl, jobService.id)
    .then(res => {
      if (res.status === 201) {
        if (res?.response?.jobService && Object.keys(res.response.jobService).length > 0) {
          const jobServiceId = Object.keys(res.response.jobService)[0];
          if (jobServiceId) {
            const redirectUrl = `/jobs/${jobId}/job_services/${jobServiceId}`;
            window.location.href = redirectUrl;
          }
        }        
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
    .finally(() => setRequesting(false))
  }

  if (!isAdminOrOperations) return <></>;

  return (
    <React.Fragment>
      <button type="button" className={`btn btn-outlined-blue btn-md ${buttonClasses}`} disabled={requesting} onClick={() => setShowConfirmation(true)}>
        Book in Check Survey
        {requesting && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
      </button>

      <SweetAlert
        show={showConfirmation}
        type="custom"
        customClass="text-dark"
        title="Do you want to generate a Check Survey (CHK) Job?"
        style={{ width: "600px" }}
        confirmBtnText="Yes"
        cancelBtnText="No"
        confirmBtnCssClass="btn btn-primary"
        confirmBtnStyle={{ border: "none", boxShadow: "none" }}
        closeOnClickOutside
        allowEscape={false}
        showCancel
        onCancel={() => setShowConfirmation(false)}
        onConfirm={onGenerateCheckSurvey}>
      </SweetAlert>
    </React.Fragment>
  )
}

export default BookInCheckSurvey
