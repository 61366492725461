import React from "react";
import { Link } from "react-router-dom"
import { format } from "date-fns"

const HistoryTable = ({
  jobServices, jobService
}) => {
  const {
    attributes: {
      allSignedPlansZipFile
    }
  } = jobService

  const onClickJobServiceLink = (e, jobServiceLink) => {
    const isClickToNewTab = e.metaKey || e.ctrlKey

    if (!isClickToNewTab) {
      e.preventDefault()
      window.location.href = jobServiceLink
    }
  }

  const HistoryRow = jobServiceItem => {
    const {
      id,
      attributes: {
        label,
        titleizeState,
        bookingTime,
        jobId,
        bookedInForJobServiceId,
        jobServiceLabel,
        primarySurveyorName
      }
    } = jobServiceItem

    return (
      <tr className="border-top border-bottom" key={`jobService-${id}`} style={{ fontSize: bookedInForJobServiceId ? "0.8em" : "inherit" }}>
        <td style={{ width: "120px" }}>{format(new Date(bookingTime), "d MMM yyyy")}</td>
        <td style={{ paddingLeft: bookedInForJobServiceId ? "40px" : "inherit" }}>
          <Link
            to={`/jobs/${jobId}/job_services/${id}`}
            onClick={e => onClickJobServiceLink(e, `/jobs/${jobId}/job_services/${id}`)}
            className="link-no-decoration">
            {label}
          </Link>
          <br />
          <p className="text-muted">
            {bookedInForJobServiceId && "Linked Booked In Job"}
          </p>
        </td>
        <td style={{ width: "220px" }}>{jobServiceLabel}</td>
        <td>{primarySurveyorName}</td>
        <td className="text-right font-weight-bold">{titleizeState}</td>
      </tr>
    )
  }

  const allSignedPlansZipFileData = allSignedPlansZipFile.data

  return (
    <div className="table-responsive">
      <table className="table mt-4">
        <tbody className="bg-white">
          {allSignedPlansZipFile && allSignedPlansZipFile.data && (
            <tr className="border-top border-bottom">
              <td style={{ width: "120px" }}>{format(new Date(allSignedPlansZipFileData.attributes.updatedAt), "d MMM yyyy")}</td>
              <td style={{ paddingLeft: "inherit" }}>
                <a
                  target="_blank"
                  href={allSignedPlansZipFileData.attributes.filePath}
                  className="link-no-decoration"
                  rel="noreferrer">
                  {allSignedPlansZipFileData.attributes.fileName}
                </a>
              </td>
              <td style={{ width: "220px" }}></td>
              <td>
                {allSignedPlansZipFileData.attributes.createdByUser}
              </td>
              <td className="text-right font-weight-bold"></td>
            </tr>
          )}
          {jobServices.map(jobServiceItem => HistoryRow(jobServiceItem))}
        </tbody>
      </table>
    </div>
  )
}

export default HistoryTable
