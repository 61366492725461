import React, { useEffect, useState } from "react"

const UserSurveyorSkills = ({
  editState, user, userEditableAttr, updateAttr, allSkills
}) => {
  const { surveyor_skills_ids: surveyorSkillsIds } = userEditableAttr
  const surveyorSkillsStrIds = _.map(surveyorSkillsIds, id => `${id}`)
  const [currentSkills, setCurrentSkills] = useState([])

  useEffect(() => {
    setCurrentSkills(allSkills.filter(skill => surveyorSkillsStrIds.includes(skill.id)))
  }, [user])

  return (
    <>
      <div className="row mb-3 align-items-center">
      </div>
      {editState ? (
        <UserSurveyorEdit {...{
          allSkills, updateAttr, surveyorSkillsStrIds, surveyorSkillsIds, userEditableAttr,
        }} />
      ) : <UserSurveyorShow currentSkills={currentSkills} />
      }
    </>
  )
}

const UserSurveyorShow = ({ currentSkills }) => {
  if (!currentSkills?.length) return null
  return (
    <>
      {!!currentSkills?.length && (
        <div className="row mb-2">
          <div className="col-3">Skills</div>
          <div className="col-9">
            <ul className={`pl-3 mb-0 ${currentSkills?.length > 3 ? "list-2-cols" : null}`}>
              {!!currentSkills?.length && currentSkills.map(skill => (
                <li key={`skill-${skill.id}`} title={skill.attributes.name}>{skill.attributes.code}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  )
}


const UserSurveyorEdit = ({
  allSkills,
  updateAttr,
  surveyorSkillsStrIds,
  surveyorSkillsIds
}) => {
  const updateSkillsHandler = (e, id) => {
    const tmpArray = [...surveyorSkillsIds]
    if (e.target.checked) {
      tmpArray.push(parseInt(id, 10))
    } else {
      _.pull(tmpArray, parseInt(id, 10))
    }
    updateAttr({ surveyor_skills_ids: tmpArray })
  }
  return (
    <>
      <div className="row mb-2">
        <div className="col-3">Skills</div>
        <div className="col-9">
          <ul className="list-unstyled pl-0 mb-0 list-2-cols">
            {Object.values(allSkills).map(skill => {
              const { id, attributes: { code, name } } = skill
              return (
                <li key={`skill-${id}`} className="mb-2">
                  <div className="custom-control custom-checkbox">
                    <input
                      checked={surveyorSkillsStrIds.includes(id)}
                      className="custom-control-input"
                      id={`skill-${id}`}
                      type="checkbox"
                      onChange={event => {
                        updateSkillsHandler(event, id)
                      }} />
                    <label className="custom-control-label" htmlFor={`skill-${id}`} title={name}>{code}</label>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </>
  )
}


export default UserSurveyorSkills
