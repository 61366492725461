/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { StringParam, useQueryParam } from "use-query-params"

import AnchorButton from "components/AnchorButton"
import FilterDropdown from "components/FilterDropdown"
import Loader from "components/Loader"
import SearchInput from "components/SearchInput"
import { getFieldWorkJobServices } from "requesters/jobServiceRequester"
import { getFilteredProducts } from "requesters/productRequester"
import { getAssignedSurveyorMembers, getSurveyors } from "requesters/userRequester"
import { Link } from "react-router-dom"

import {
  ACCOUNTS,
  ADMIN,
  DATA_OVERDUE,
  GPS_REQUESTED,
  JOB_PACK_INCOMPLETE,
  OPERATIONS,
  OPERATION_MANAGER,
  RECEPTION,
  RECEPTION_MANAGER,
  SALES,
  SURVEYORS,
  SURVEYORS_MANAGER,
} from "utils/constants"


import {
  addDays, endOfDay,
  format, isPast, startOfDay
} from "date-fns"
import _ from "lodash"
import { roleCheck } from "utils/index"
import Calendar from "../../components/Calendar/Calendar"
import { getBaseUrl, getCurrentRole, getCurrentUser } from "../../reducers"
import FollowUpbookingModal from "../JobDetails/modals/FollowUpbookingModal"
import BlockOutsTable from "./BlockOutsTable"
import SchedulesDateTable from "./SchedulesDateTable"

export default function SchedulesPage() {
  const baseUrl = useSelector(getBaseUrl)
  const currentRole = useSelector(getCurrentRole)
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const { attributes: { role } } = currentUser
  const currentTeamRole = currentUser.attributes.current_team_role
  const readOnly = !((/admin/i.test(role) || currentTeamRole.includes(OPERATION_MANAGER)))
  const receptionTeam = currentTeamRole.includes(RECEPTION_MANAGER)
  const isAdmin = systemRole === ADMIN
  const isSurveyorsManager = !isAdmin && roleCheck(systemRole, currentTeamRole, SURVEYORS_MANAGER)
  const isSales = roleCheck(systemRole, currentRole, SALES)
  const isAccounts = roleCheck(systemRole, currentRole, ACCOUNTS)
  const [jobTypes, setJobTypes] = useState([])
  const [surveyorOptions, setSurveyorOptions] = useState([])
  const [requesting, setRequesting] = useState(false)

  const liveJobsAllowed = () => {
    if (roleCheck(systemRole, currentRole, OPERATIONS) || roleCheck(systemRole, currentRole, SALES) || roleCheck(systemRole, currentRole, SURVEYORS) || roleCheck(systemRole, currentRole, RECEPTION) || roleCheck(systemRole, currentRole, ACCOUNTS)) {
      return true
    }
    return false
  }

  if (!liveJobsAllowed()) window.location = "/"

  const jobStatusOptions = [["Data Overdue", "data_overdue"], ["Job Pack Incomplete", "job_pack_incomplete"], ["Awaiting Payment", "awaiting_payment"], ["GPS Requested", "gps_requested"], ["Field Work Ongoing", "field_work_ongoing"], ["Field Work Finished", "field_work_finished"], ["Field Work Incompleted", "field_work_incompleted"], ["Rescheduled", "rescheduled"], ["To Confirm", "to_confirm"], ["Pending", "pending"], ["Confirmed", "confirmed"], ["GPS Declined", "gps_declined"], ["GPS Completed", "gps_completed"], ["GPS Approved", "gps_approved"]]

  const params = (new URL(document.location)).searchParams;
  const filter = params.get("filter");

  const jobPackIncompleteFilter = () => {
    if (filter === JOB_PACK_INCOMPLETE) {
      return JOB_PACK_INCOMPLETE
    }
    if (filter === DATA_OVERDUE) {
      return DATA_OVERDUE
    }
    if (filter === GPS_REQUESTED) {
      return GPS_REQUESTED
    }
    return undefined
  }

  const bySurveyorFilter = () => {
    if (receptionTeam || isSales || isAccounts) {
      return undefined
    }
    if (readOnly && !isSurveyorsManager) {
      return currentUser.id
    }
    return undefined
  }

  const initialFilters = {
    by_status: jobPackIncompleteFilter(),
    by_surveyor: bySurveyorFilter(),
    by_job_type: undefined,
    by_keyword: undefined,
    by_booking_time: [
      startOfDay(localStorage.getItem("selectedDate") ? new Date(localStorage.getItem("selectedDate")) : new Date()), // start date
      endOfDay(addDays(localStorage.getItem("selectedDate") ? new Date(localStorage.getItem("selectedDate")) : new Date(), 7)) // end date
    ]
  }

  const [filters, setFilters] = useState(initialFilters)
  const [isFilteredByDropDown, setIsFilteredByDropDown] = useState(false)

  const byStatusName = _.get(jobStatusOptions, `${_.findIndex(jobStatusOptions, option => option[1] === filters.by_status)}.0`)
  const byJobTypeName = _.get(jobTypes, `${_.findIndex(jobTypes, type => type[1] === filters.by_job_type)}.0`)
  const bySurveyorName = _.get(surveyorOptions, `${_.findIndex(surveyorOptions, surveyor => surveyor[1] === Number(filters.by_surveyor))}.0`)

  const [calendarDateString, setCalendarDateString] = useQueryParam("date", StringParam)
  const [targetId, setTargetId] = useQueryParam("target_id", StringParam)
  const [calendarDate, setCalendarDate] = useState(localStorage.getItem("selectedDate") ? new Date(localStorage.getItem("selectedDate")) : new Date())
  const [hidePast, setHidePast] = useState(false)

  const [jobServicesByDate, setJobServicesByDate] = useState()
  const [showFollowUpbookingModal, setShowFollowUpbookingModal] = useState(false)
  const [daysWithEvents, setDaysWithEvents] = useState([])

  const clearFilters = () => {
    setFilters(initialFilters)
    setCalendarDate(new Date())
    setIsFilteredByDropDown(true)
    setTargetId("#")
  }

  const setByStatus = val => {
    setFilters({ ...filters, by_status: val })
    setIsFilteredByDropDown(true)
    setTargetId("#")
  }

  const setByJobType = val => {
    setFilters({ ...filters, by_job_type: val })
    setIsFilteredByDropDown(true)
    setTargetId("#")
  }

  const setBySurveyor = val => {
    setFilters({ ...filters, by_surveyor: val })
    setIsFilteredByDropDown(true)
    setTargetId("#")
  }

  const setBySearch = val => {
    setFilters({ ...filters, by_keyword: val })
    setTargetId("#")
  }

  useEffect(() => {
    setCalendarDateString(format(calendarDate, "d MMM yyyy"))
    setFilters({
      ...filters,
      by_booking_time: [
        startOfDay(calendarDate),
        endOfDay(addDays(calendarDate, 7))
      ]
    })
    localStorage.setItem("selectedDate", calendarDate);
    setIsFilteredByDropDown(true)
    setTargetId("#")
  }, [calendarDate])

  const requestJobServices = () => {
    setJobServicesByDate([])
    setDaysWithEvents([])
    setRequesting(true)
    getFieldWorkJobServices(baseUrl, filters)
      .then(res => {
        setJobServicesByDate(res?.response?.data)
        setDaysWithEvents(Object.keys(res?.response?.data))
        setRequesting(false)
      })
      .catch(() => {
        setRequesting(false)
      })
  }

  useEffect(() => {
    getFilteredProducts(baseUrl, "schedules")
      .then(res => {
        if (res.status === 200) {
          const products = Object.values(_.get(res.response, "product", {}))
          const allJobTypes = products.map(js => ([js.attributes.name, js.id]))
          setJobTypes(allJobTypes)
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }, [])

  const reloadData = () => {
    requestJobServices()
  }

  const handleSubmit = e => {
    e.preventDefault()
    reloadData()
  }

  useEffect(() => {
    reloadData()
    setIsFilteredByDropDown(false)
  }, [isFilteredByDropDown])

  const getSurveyorHandler = () => {
    if (isSurveyorsManager) {
      getAssignedSurveyorMembers(baseUrl, currentUser.id)
        .then(res => {
          if (res.status === 200) {
            const newData = res.response.data.attributes.surveyor_members.map(item => ([item.name, item.id]))
            newData.push([res.response.data.attributes.name, parseInt(res.response.data.id, 10)])

            setSurveyorOptions(newData)
          }
        })
        // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    } else {
      getSurveyors(baseUrl)
        .then(res => {
          if (res.status === 200) {
            const newData = res.response.map(item => ([`${item[0]} ${item[1]}`, item[2]]))
            setSurveyorOptions(newData)
          }
        })
        // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    }
  }

  useEffect(() => {
    getSurveyorHandler()
  }, [])

  useEffect(() => {
    if (targetId && requesting === false && targetId !== "#") {
      const element = document.getElementById(targetId)
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [targetId, requesting])

  return (
    <div className="container-fluid mb-5">
      <div className="container-inner">
        <div style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          paddingBottom: "20px",
          paddingTop: "20px",
          backgroundColor: "white"
        }}>
          <div className="d-md-flex justify-content-between align-items-center header-tabs-container">
            <div className="header-tabs">
              <h1>Schedules</h1>
            </div>

            {!readOnly && (
              <button
                className="btn btn-outlined-blue"
                onClick={() => { setShowFollowUpbookingModal(true) }}
                type="button">
                + New Booking
              </button>
            )}

            <FollowUpbookingModal
              show={showFollowUpbookingModal}
              onHide={() => { setShowFollowUpbookingModal(false) }}
              reloadData={reloadData} />

          </div>
          <div className="table-listing-filters mb-2 d-md-flex justify-content-between">
            <div>
              <div className="btn-group mr-2">
                <SearchInput value={filters.by_keyword} setValue={setBySearch} handleSubmit={handleSubmit} disableSubmit={requesting} />
              </div>


              <div className="btn-group mr-2">
                <FilterDropdown title="Status" filteredName={byStatusName} setFilter={setByStatus} options={jobStatusOptions} />
              </div>

              <div className="btn-group mr-2">
                <FilterDropdown title="Job Service" filteredName={byJobTypeName} setFilter={setByJobType} options={jobTypes} />
              </div>
              {(!readOnly || isSurveyorsManager || isSales) && (
                <div className="btn-group mr-2">
                  <FilterDropdown title="Surveyor" filteredName={bySurveyorName} setFilter={setBySurveyor} options={surveyorOptions} />
                </div>
              )}

              <div className="btn-group mr-3 ml-4 mt-1">
                <input className="form-check-input" id="show-past" type="checkbox" checked={hidePast} onChange={() => { setHidePast(!hidePast) }}></input>
                <label htmlFor="show-past">Hide Past Dates</label>
              </div>

              <div className="btn-group">
                <AnchorButton
                  className="f-small"
                  onClick={clearFilters}>
                  Clear Filters
                </AnchorButton>
              </div>
            </div>

            <Calendar startDate={filters.by_booking_time[0]} endDate={filters.by_booking_time[1]} calendarDate={calendarDate} setCalendarDate={setCalendarDate} />
          </div>
        </div>

        {requesting ? <Loader /> : (
          <JobServiceDateTables {...{
            startDate: filters.by_booking_time[0], endDate: filters.by_booking_time[1], jobServicesByDate, calendarDateString, surveyorOptions, reloadData, hidePast, readOnly, daysWithEvents
          }} />
        )}
      </div>
    </div>
  )
}

const JobServiceDateTables = ({
  calendarDateString,
  hidePast,
  jobServicesByDate,
  readOnly,
  reloadData,
  surveyorOptions,
  daysWithEvents
}) => (
  <>
    {daysWithEvents.map(date => {
      const jobServicesDay = jobServicesByDate[date]
      const assignmentsPerDay = jobServicesDay?.assignments?.data
      const dailyBlockOuts = jobServicesDay?.block_outs
      const todaysPublicHoliday = jobServicesDay?.public_holidays[0]
      const isSunday = date?.includes("Sunday")
      console.log("jobServicesDay", jobServicesDay)
      const sortedJobServiceDay = _.orderBy(jobServicesDay, ["attributes.surveyorName", "attributes.bookingDates[0]"], ["asc", "asc"])

      const sortedAssignmentsPerDay = _.orderBy(assignmentsPerDay, ["attributes.surveyor_name", obj => new Date(obj.attributes.job_service.data.attributes.booking_dates[0])], ["asc", "asc"])

      return (
        (!!sortedJobServiceDay?.length && (!hidePast || !isPast(new Date(jobServicesDay.date)))) && (
          <div key={`date-${date}`}>
            {(!!assignmentsPerDay?.length || !!dailyBlockOuts?.length || todaysPublicHoliday) && (
              <div className="d-flex align-items-center pt-5">
                <h3 className="text-dark mr-1">{date}</h3>
                <Link to={`/schedules/map-views?scheduled_date=${jobServicesDay.date}`}>SHOW JOBS ON MAP</Link>
              </div>
            )}

            {/* public holidays */}
            {todaysPublicHoliday && (
              <div className="mb-2">
                <h6 className="mt-3 mb-0 d-inline text-danger">Public Holiday: </h6>
                <span>{todaysPublicHoliday}</span>
              </div>
            )}

            {/* surveyor leave */}
            {(!!dailyBlockOuts?.length && !todaysPublicHoliday && !isSunday) && (
              <BlockOutsTable blockOuts={dailyBlockOuts} currentDate={jobServicesDay?.date} />
            )}

            {/* Job Services */}
            {!!assignmentsPerDay?.length && (
              <>
                <h6 className="mt-3 mb-0">Jobs</h6>
                <SchedulesDateTable
                  jobServicesDay={sortedAssignmentsPerDay}
                  calendarDateString={calendarDateString}
                  reloadData={reloadData}
                  surveyorOptions={surveyorOptions}
                  readOnly={readOnly} />
              </>
            )}
          </div>
        )
      )
    })}
  </>
)
