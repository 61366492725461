import React, { useState } from "react";
import { Link } from "react-router-dom"
import { Modal, Spinner } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert"
import { useSelector } from "react-redux"
import { JOB_SENT } from "../../../../../../utils/constants";
import { newWaeFin } from "../../../../../../requesters/jobServiceRequester";
import { getBaseUrl } from "../../../../reducers";

const WaeFinal = ({
  jobService, reloadData
}) => {
  const {
    attributes: {
      state,
      jobId,
      hasFinalWaeJobService,
      isPrelimWae,
      finalJobServiceId
    }
  } = jobService
  const baseUrl = useSelector(getBaseUrl)
  const [requesting, setRequesting] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)

  const onGenerateWaeFin = () => {
    if (requesting) return;

    setShowConfirmation(false)
    setRequesting(true)
    newWaeFin(baseUrl, jobService.id)
    .then(res => {
      if (res.status === 201) {
        setRequesting(false)
        reloadData()
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }

  return (
    <React.Fragment>
      {
        isPrelimWae && JOB_SENT === state && (
          <li className="activity timeline-activity" key="WAE Final Job Button">
            <div className="circle circle-outline" />
            { !hasFinalWaeJobService && (
              <button type="button" className="btn btn-outline-primary" disabled={requesting} onClick={() => setShowConfirmation(true)}>
                Create WAE-FIN Job
                {requesting && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
              </button>
            )}

            { hasFinalWaeJobService && finalJobServiceId && (
              <div className="card border-0 shadow-sm">
                <div className="card-body">
                  <div className="row px-3 flex-column">
                    <p className="mb-0">
                      Final WAE Job -
                      <Link
                        to={`/jobs/${jobId}/job_services/${finalJobServiceId}`}
                        onClick={() => { window.location.href = `/jobs/${jobId}/job_services/${finalJobServiceId}` }}
                        className="link-no-decoration ml-2">
                        Click here to Allocate it
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            )}

            <SweetAlert
              show={showConfirmation}
              type="custom"
              customClass="text-dark"
              title="Do you want to generate a WAE-FIN Job?"
              style={{ width: "600px" }}
              confirmBtnText="Yes"
              cancelBtnText="No"
              confirmBtnCssClass="btn btn-primary"
              confirmBtnStyle={{ border: "none", boxShadow: "none" }}
              closeOnClickOutside
              allowEscape={false}
              showCancel
              onCancel={() => setShowConfirmation(false)}
              onConfirm={onGenerateWaeFin}>
            </SweetAlert>
          </li>
        )
      }
    </React.Fragment>
  )
}

export default WaeFinal