import customAxios from "./customAxios";
import normalize from "json-api-normalizer"

export const getGearHistoryRecords = (baseUrl, gearId) => {
    const path = `${baseUrl}/gears/${gearId}/histories/`

    return customAxios.get(path)
        .then(({ data, status }) => ({ response: normalize(data), status }))
        .catch(error => ({ error }))
}

export const postGearHistoryRecord = (baseUrl, gearHistoryRecordData, gearId) => {
    const path = `${baseUrl}/gears/${gearId}/histories/`
    return customAxios.post(path, gearHistoryRecordData)
        .then(({ data, status }) => ({ response: data.json, status }))
        .catch(error => ({ error }))
}

export const getGearHistory = (baseUrl, gearHistoryId) => {
    const path = `${baseUrl}/histories/${gearHistoryId}`
    return customAxios.get(path)
        .then(({ data, status }) => ({ response: normalize(data), status }))
        .catch(error => ({ error }))
}
