import React from "react"
const LinkedBookInJobs = ({
  bookedInJobServices
}) => {
return (
    <div className="timeline-item mt-2">
      <div className="card border-0 shadow-sm">
        <div className="card-body p-3">
          <div className="row align-items-center">
            <div className="col-sm-6">
            </div>
          </div>
            { bookedInJobServices.map(jobService => (
              <>
                <a href={`/jobs/${jobService.jobId}/job_services/${jobService.id}`} className="text-dark">{jobService.label}</a>
                <br/>
              </>

            ))}
        </div>

      </div>
    </div>
  )
}
  export default LinkedBookInJobs