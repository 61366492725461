import { combineReducers } from "redux"

import createReducer from "components/utils/createReducer"

import * as actionTypes from "../../actions/types"

const surveyorSkillFetchSuccess = (state, { data }) => ({ ...state, ...data.surveyorSkill })

const byId = createReducer({})({
  [actionTypes.RESOURCE_FETCHED_SUCCESS]: surveyorSkillFetchSuccess,
})

export const getSurveyorSkillById = (state, userId) => state.byId[userId]

export default combineReducers({
  byId
})
