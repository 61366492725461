/* eslint-disable require-yield */
import {
  takeLatest, all, fork, select, call
} from "redux-saga/effects"

import * as actionTypes from "../actions/types"
import { getBaseUrl } from "../reducers"
import * as requester from "../requesters"

// function* fetchDepartments() {
//   const baseUrl = yield select(getBaseUrl)
// }

function* initFetch() {
  // yield all([
  //   fork(fetchDepartments)
  // ])
}

export function* watchInitSaga() {
  yield takeLatest(actionTypes.INIT_APPLICATION, initFetch)
}