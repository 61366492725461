import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { updateJobService } from "requesters/jobServiceRequester";
import { getBaseUrl } from "../../reducers";

export default function JobServiceETA({ jobService, readOnly, reloadData }) {
  const {
    attributes: {
      id,
      disabledChangeOnSchedule,
      estimatedHours
    }
  } = jobService
  const baseUrl = useSelector(getBaseUrl)
  const [show, setShow] = useState(false)
  const [currentEstimatedHours, setCurrentEstimatedHours] = useState(estimatedHours || "")
  const [loading, setLoading] = useState(false)

  const onSave = () => {
    setShow(false)
    setLoading(true)
    updateJobService(baseUrl, id, { estimated_hours: currentEstimatedHours })
      .then(res => {
        if (res.status === 200) {
          reloadData()
        }
        setLoading(false)
      })
      // eslint-disable-next-line no-console
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <React.Fragment>
      <button onClick={() => setShow(true)} className="btn dropdown-toggle" type="button">{loading ? "Updating..." : estimatedHours}</button>
      <Modal show={show && !disabledChangeOnSchedule && !readOnly} onHide={() => setShow(false)} size="sm" centered>
        <div className="modal-body p-5">
          <form>
            <div className="form-group">
              <label>Estimated Hours</label>
              <input
                min={0}
                type="number"
                className="form-control mr-5"
                value={currentEstimatedHours}
                onChange={e => setCurrentEstimatedHours(e.target.value)} />
            </div>
          </form>
          <div className="modal-footer d-flex justify-content-between p-0 mb-4 mt-5">
            <button className="btn btn-light btn-lg" data-dismiss="modal" type="button" onClick={() => setShow(false)}>Close</button>
            <button
              className="btn btn-red btn-lg"
              onClick={() => {
                onSave()
              }}
              type="submit">
              Save Changes
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}
