import React from "react"
import { Breadcrumb } from "react-bootstrap"
import { Link } from "react-router-dom"

export default function CustBreadcrumb({ links, active, breadcrumbClassNames }) {
  return (
    <Breadcrumb className={`${breadcrumbClassNames || "mb-5"}`}>
      {links.map(link => <Breadcrumb.Item key={link[1]} linkAs={Link} linkProps={{ to: link[1] }}>{link[0]}</Breadcrumb.Item>)}
      <Breadcrumb.Item active>{active}</Breadcrumb.Item>
    </Breadcrumb>
  )
}