import { format, parse } from "date-fns";
import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const UpdateNextBookingDate3DModal = ({
  show, onHide, nextBookingDate3D, setNextBookingDate3D, onChangeBookingDate
}) => {
  const [selectedDate, setSelectedDate] = useState(undefined)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (show) {
      setLoading(false)
    }
  }, [show])

  useEffect(() => {
    if (nextBookingDate3D) {
      setSelectedDate(parse(nextBookingDate3D, "dd/MM/yyyy", new Date()))
    }
  }, [nextBookingDate3D])
  return (
    <Modal show={show} onHide={onHide} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title><h5 className="modal-title">Update 3D Next Available Booking Date</h5></Modal.Title>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          <div className="form-group">
            <div style={{ fontSize: "25px" }}>
              <DatePicker
                selected={selectedDate}
                onChange={date => {
                  setSelectedDate(date)
                }}
                closeOnScroll={e => e.target === document}
                className="form-control mb-2 mt-2 ml-1 "
                dateFormat="dd/MM/yyyy" />
            </div>
          </div>
        </form>
        <div className="modal-footer d-flex justify-content-between p-0 mb-4 mt-5">
          <button className="btn btn-light btn-lg" data-dismiss="modal" type="button" onClick={onHide}>Close</button>
          <button
            disabled={loading}
            className="btn btn-red btn-lg"
            onClick={() => {
              setNextBookingDate3D(format(selectedDate, "dd/MM/yyyy", new Date()))
              onChangeBookingDate(format(selectedDate, "dd/MM/yyyy", new Date()), "next_booking_date_3d")
              setLoading(true)
            }}
            type="submit">
            Save Changes
            {loading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
          </button>
        </div>
      </div>
    </Modal>
  )
}
export default UpdateNextBookingDate3DModal
