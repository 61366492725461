import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { TagsInput } from "react-tag-input-component";
import { checkEmail } from "utils/index"

export default function SendCopyToClientModal({
  show, setShow, onSendCopy, contactsWithEmail
}) {
  const [clientEmails, setClientEmails] = useState([])
  return (
    <Modal show={show} onHide={setShow} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title><h4 className="modal-title">Supervisor / Client Email</h4></Modal.Title>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          <i>Click enter to add new email</i>
          <div className="form-group">
            <div style={{ fontSize: "18px" }}>
              <TagsInput
                classNames={{
                  input: "w-100",
                }}
                value={clientEmails}
                onChange={emails => {
                  setClientEmails(emails.filter(checkEmail))
                }}
                name="clientEmails"
                placeHolder="Enter emails" />
            </div>
          </div>
          {contactsWithEmail && contactsWithEmail.map(contactWithEmail => (
            <div className="custom-control custom-checkbox mb-2" key={contactWithEmail.id}>
              <input
                className="custom-control-input"
                id={contactWithEmail.id}
                type="checkbox"
                checked={clientEmails.includes(contactWithEmail.email)}
                onChange={e => {
                  if (e.target.checked) {
                    setClientEmails([...clientEmails, contactWithEmail.email])
                  } else {
                    setClientEmails(clientEmails.filter(email => email !== contactWithEmail.email))
                  }
                }} />
              <label className="custom-control-label" htmlFor={contactWithEmail.id}>{contactWithEmail.name}</label>
            </div>
          ))}
        </form>
        <div className="modal-footer d-flex justify-content-between p-0 mb-4 mt-5">
          <div>
            <button
              className="btn btn-red btn-lg mr-2"
              onClick={() => {
                setShow(false)
              }}
              type="submit">
              Cancel
            </button>
          </div>
          <div>
            <button
              disabled={!clientEmails.length}
              className="btn btn-primary btn-lg mr-2"
              onClick={() => {
                onSendCopy(clientEmails)
                setShow(false)
                setClientEmails([])
              }}
              type="submit">
              Send
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
