import axios from "axios";

const customAxios = axios.create();
customAxios.interceptors.request.use(
  config => config,
  error => Promise.reject(error)
);
customAxios.interceptors.response.use(
  response => Promise.resolve(response),
  error => {
    if (error.response) {
      if (error.response.status === 401) {
        window.location.href = "/logout";
        return Promise.resolve(null)
      }
    }
    return Promise.reject(error);
  }
);

export default customAxios;
