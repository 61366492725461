import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Form } from "react-bootstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import _ from "lodash"

import { closeIcon } from "components/Icons"
import AnchorButton from "components/AnchorButton"
import { createTeamUser, setDefaultTeamUser, discardTeamUser } from "requesters/userRequester"
import { getBaseUrl, getResource } from "../../../reducers"
import * as actionTypes from "../../../actions/types"
import {
  OPERATIONS,
  ACCOUNTS,
  SURVEYORS,
  RECEPTION,
  ADMIN,
  USER,
  MANAGER,
  PROCESSOR
} from "../../../../../utils/constants"

export default function UserTeamsCard() {
  const baseUrl = useSelector(getBaseUrl)
  const dispatch = useDispatch()

  const { id } = useParams()
  const user = useSelector(state => getResource(state, { type: USER, id }))
  const { relationships: { teamUsers } } = user

  const orderdTeamUsers = _.orderBy(teamUsers, "attributes.teamName")
  const { teams } = useSelector(state => state.options)
  const [showAddTeamForm, setShowAddTeamForm] = useState(false)
  const [selectedTeam, setSelectedTeam] = useState(undefined)

  function handleSubmit(event) {
    event.preventDefault()
    const form = event.target
    const data = new FormData(form)
    const dataObj = Object.fromEntries(data)
    if ([OPERATIONS, ACCOUNTS, RECEPTION, ADMIN, PROCESSOR].includes(dataObj.team_id)) {
      dataObj.team_role = MANAGER
    }

    if (!teamUsers.length) {
      dataObj.main_role = "on"
    }
    async function submitData() {
      const { response, error } = await createTeamUser(baseUrl, id, dataObj)
      if (response) {
        if (selectedTeam === SURVEYORS) {
          dispatch({ type: actionTypes.MANAGE_USERS_EDIT_UPDATE, data: { surveyor_type: "Cadastral" } })
        }
        setShowAddTeamForm(false)
        setSelectedTeam(undefined)
        dispatch({ type: actionTypes.TEAM_USER_CREATE_REQUEST_SUCCESS })
      }
      if (error) {
        console.error(error, error.response)
        const { status, data } = error.response
        const message = _.map(_.get(data, "errors", []), "detail")
        if (status >= 400 && status < 500) {
          swal.fire({
            icon: "error",
            title: "Error",
            text: message,
          })
        }
        dispatch({ type: actionTypes.TEAM_USER_CREATE_REQUEST_FAILURE })
      }
    }
    submitData()
  }

  return (
    <div className="card border-0 mt-4 shadow-sm">
      <div className="card-body p-4">
        <h3>{`Teams (${teamUsers.length})`}</h3>
        <div className="table-responsive">
          <table className="table table-listing table-hover">
            <thead>
              <tr>
                <th>Team</th>
                {(![OPERATIONS, RECEPTION, ADMIN, ACCOUNTS, PROCESSOR].includes(selectedTeam)) && (
                  <th>Role</th>
                )}
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {orderdTeamUsers.map(teamUser => <UserTeam key={`team-user-${teamUser.id}`} teamUserId={teamUser.id} />)}
              {showAddTeamForm && (
              <tr>
                <td>
                  <form id="new-team-form" onSubmit={handleSubmit}>
                    <Form.Control as="select" name="team_id" size="sm" onChange={e => setSelectedTeam(e.target.value)}>
                      {teams.map(team => <option key={`team-${team[1]}`} value={team[1]}>{team[0]}</option>)}
                    </Form.Control>
                  </form>
                </td>
                <td>
                  {(![OPERATIONS, RECEPTION, ADMIN, ACCOUNTS, PROCESSOR].includes(selectedTeam)) && (
                    <Form.Control as="select" name="team_role" size="sm" form="new-team-form">
                      <option value={USER}>User</option>
                      <option value={MANAGER}>Manager</option>
                    </Form.Control>
                  )}
                </td>
                <td>
                  <Form.Check
                    form="new-team-form"
                    name="main_role"
                    type="checkbox"
                    id="default-role"
                    label="Main Role" />
                </td>
                <td className="text-right">
                  <button
                    className="btn btn-red btn-sm"
                    form="new-team-form"
                    type="submit">
                    Save
                  </button>
                  <button
                    className="btn btn-light btn-sm ml-2"
                    type="button"
                    onClick={() => {
                      setShowAddTeamForm(false); setSelectedTeam("")
                    }}>
                    Cancel
                  </button>
                </td>
              </tr>
            )}
            </tbody>
          </table>
        </div>
        <AnchorButton
          className="btn btn-outlined-blue"
          onClick={() => { setShowAddTeamForm(true) }}>
          + Add Team
        </AnchorButton>
      </div>
    </div>
  )
}

function UserTeam({ teamUserId }) {
  const baseUrl = useSelector(getBaseUrl)
  const dispatch = useDispatch()
  const [showMessage, setShowMessage] = useState(false)
  const [alertTitle, setAlertTitle] = useState("")
  const [alertText, setAlertText] = useState("")

  const { id: userId } = useParams()
  const teamUser = useSelector(state => getResource(state, { type: "teamUser", id: teamUserId }))
  const {
    attributes: {
      teamRole: teamUserRole,
      defaultTeam
    },
    relationships:
      {
        team: {
          attributes: { name: teamName }
        },
      }
  } = teamUser

  const resetError = () => {
    setShowMessage(false)
    setAlertTitle("")
    setAlertText("")
  }

  const triggerDeleteTeamUser = () => {
    discardTeamUser(baseUrl, userId, teamUserId)
      .then(res => {
        if (res.status === 200) {
          dispatch({ type: actionTypes.TEAM_USER_DELETE_REQUEST_SUCCESS })
          window.location.reload()
        }
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
        dispatch({ type: actionTypes.TEAM_USER_DELETE_REQUEST_FAILURE })
      })
  }

  return (
    <tr>
      <td>{teamName}</td>
      <td>{teamUserRole}</td>
      <td>
        {defaultTeam && <div className="badge badge-dark">Main Role</div>}
      </td>
      <td className="text-right">
        {!defaultTeam && (
          <>
            <AnchorButton onClick={() => {
                async function submitData() {
                  const { response, error } = await setDefaultTeamUser(baseUrl, userId, teamUserId)
                  if (response) {
                    dispatch({ type: actionTypes.TEAM_USER_SET_DEFAULT_REQUEST_SUCCESS })
                  }
                  if (error) {
                    console.error(error, error.response)
                    const { status, data } = error.response
                    const message = _.map(_.get(data, "errors", []), "detail")
                    if (status >= 400 && status < 500) {
                      swal.fire({
                        icon: "error",
                        title: "Error",
                        text: message,
                      })
                    } else {
                      swal.fire({
                        icon: "error",
                        title: "Error",
                        text: "Server error",
                      })
                    }
                    dispatch({ type: actionTypes.TEAM_USER_SET_DEFAULT_REQUEST_FAILURE })
                  }
                }
                submitData()
            }}>
              Set as Main
            </AnchorButton>
            <span className="mx-2 text-muted">|</span>
          </>
        )}
        <AnchorButton onClick={() => { triggerDeleteTeamUser() }}>
          <img src={closeIcon} />
        </AnchorButton>
        <SweetAlert
          show={showMessage}
          type="error"
          title={alertTitle}
          style={{ width: "600px" }}
          closeOnClickOutside
          allowEscape={false}
          onConfirm={resetError}>
          {alertText}
        </SweetAlert>
      </td>
    </tr>
  )
}
