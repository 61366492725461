import React, { useState } from "react"
import { useSelector } from "react-redux"
import { roleCheck } from "utils/index"
import {
  OPERATION_MANAGER,
  ACCOUNTS_MANAGER,
  DRAFTING_OVERSEAS,
  DRAFTING_OVERSEAS_LEBANON,
  DRAFTING_OVERSEAS_PHILIPPINES,
  REGISTERED_SURVEYORS_MANAGER
} from "utils/constants"
import EditContactModal from "./EditContactModal";
import { getCurrentUser, getCurrentRole } from "../../reducers"
import ContactListItem from "./ContactListItem"

// this is used in both lead and job details page
export const ContactList = ({
  contacts, reloadData, jobServiceId, limitedInfo
}) => {
  const currentUser = useSelector(getCurrentUser)
  const currentRole = useSelector(getCurrentRole)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role

  const isDraftingOverseas = currentRole?.includes(DRAFTING_OVERSEAS) || currentRole?.includes(DRAFTING_OVERSEAS_PHILIPPINES) || currentRole?.includes(DRAFTING_OVERSEAS_LEBANON)

  const [showContactsModal, setShowContactsModal] = useState(false)
  const contactList =  _.values(contacts) || []
  return (
    <div className="table-description mt-5 mb-3">
      <div className="d-flex align-items-center mb-2">
        <h6 className="mr-3 mb-1">Contacts</h6>
        {(roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) || roleCheck(systemRole, currentTeamRole, ACCOUNTS_MANAGER) || roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)) && (
          <button
            type="button"
            className="btn btn-link p-0"
            onClick={() => { setShowContactsModal(true) }}>
            {contactList.length ? "Edit" : "Add"}
          </button>
        )}
      </div>
      {!!contactList?.length && contactList.map(contact => (
        <ContactListItem
          contact={contact}
          isDraftingOverseas={isDraftingOverseas}
          key={contact?.id}
          limitedInfo={limitedInfo} />
      ))}

      <EditContactModal
        show={showContactsModal}
        contacts={contacts}
        onHide={() => { setShowContactsModal(false) }}
        jobServiceId={jobServiceId}
        reloadData={reloadData} />
    </div>
  )
}
