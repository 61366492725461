import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { updateUserNotification } from "requesters/userRequester"
import { getBaseUrl } from "../../reducers"

export default function NotificationRow({
  notification, systemRole, checkedNotifications, toggleCheck
}) {
  const baseUrl = useSelector(getBaseUrl)

  const updateNotification = notification => {
    if (notification?.attributes?.state !== "read") {
      updateUserNotification(baseUrl, notification.id)
    }
  }

  const {
    attributes
  } = notification

  if (attributes) {
    const {
      body,
      created_at,
      link_url,
      state,
      title,
      user_name,
      job_no
    } = attributes

    const handleClick = () => {
      window.location.href = link_url
    }

    return (
      <tr>
        <td>
          <div className="custom-control custom-checkbox mr-2">
            <input
              type="checkbox"
              className="custom-control-input"
              id={notification.id}
              checked={checkedNotifications.includes(notification)}
              onChange={() => toggleCheck(notification)} />
            <label className="custom-control-label" htmlFor={notification.id}></label>
          </div>
        </td>
        <td onClick={handleClick} className="cursor-pointer">{created_at}</td>
        {systemRole.match(/admin/) && (
          <td onClick={handleClick} className="cursor-pointer">{user_name}</td>
        )}
        <td onClick={handleClick} className="cursor-pointer">{(job_no ? `${job_no} - ` : "") + title}</td>
        <td onClick={handleClick} className="cursor-pointer">{body}</td>
        <td onClick={handleClick} className="cursor-pointer">{state}</td>
        <td>
          <Link
            onClick={() => updateNotification(notification)}
            to={link_url}>
            View
          </Link>
        </td>
      </tr>
    )
  }
  return null
}
