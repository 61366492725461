import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";

import { getContacts, putContact } from "requesters/contactRequester";
import { createJobServiceContact, deleteContactFromJobService, updateContactType } from "requesters/jobServiceContactRequester";

import _ from "lodash";

import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { CONTACT_TYPES } from "../../../../utils/constants";
import { getBaseUrl } from "../../reducers";

const EditContactModal = ({
  show, onHide, contacts, reloadData, jobServiceId
}) => {
  const baseUrl = useSelector(getBaseUrl);
  const [contactModalList, setContactModalList] = useState(_.values(contacts) || [])
  const [existingContact, setExistingContact] = useState(false)
  const [emailOptions, setEmailOptions] = useState([])
  const [alertTitle, setAlertTitle] = useState("")
  const [loadingContactIds, setLoadingContactIds] = useState([])
  const { clientTypes } = useSelector(state => state.options)

  const clientTypeOptions = clientTypes.map(v => ({
    value: v[1],
    label: v[0],
    title: "clientType",
  }));

  useEffect(() => {
    setContactModalList(_.values(contacts) || [])

    getContacts(baseUrl)
      .then(res => {
        if (res.status === 200) {
          const emailList = _.values(_.values(res.response?.contact)).map(contact => ({
            value: contact.id,
            label: contact.attributes.email,
            attributes: contact.attributes,
          }))
          setEmailOptions(emailList)
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }, [show])

  const searchContacts = ((query, callback) => {
    getContacts(baseUrl, { by_email: query })
      .then(res => {
        if (res.status === 200) {
          const emailList = _.values(_.values(res.response?.contact)).map(contact => ({
            value: contact.id,
            label: contact.attributes.email,
            attributes: contact.attributes,
          }))
          setEmailOptions(emailList)
          callback(emailList)
        }
      })
  })

  const handleChange = (e, index) => {
    const clonedList = [...contactModalList]

    if (e?.target?.name) {
      const { name, value } = e.target
      clonedList[index].attributes = { ...clonedList[index].attributes, [name]: value }
      setContactModalList(clonedList)
    }

    if (e?.title === "clientType") {
      clonedList[index].attributes = {
        ...clonedList[index].attributes,
        clientTypeObject: e,
        clientTypeRaw: e.value,
        clientType: e.label
      }
      setContactModalList(clonedList)
    }

    if (e?.title === "contactType") {
      if (!clonedList[index]?.key) {
        const params = {
          job_service_id: jobServiceId,
          contact_id: clonedList[index].id,
          contact_type: e.value
        }

        updateContactType(baseUrl, params)
          .then(res => {
            if (res.status === 200) {
              clonedList[index].attributes = {
                ...clonedList[index].attributes,
                contactTypeObject: e
              }
              setContactModalList(_.values(contacts))
            }
          })
          // eslint-disable-next-line no-console
          .catch(err => console.log(err))
      } else {
        clonedList[index].attributes = {
          ...clonedList[index].attributes,
          contactTypeObject: e
        }
        setContactModalList(clonedList)
      }
    }
  }

  const handleSave = (currentContact, index) => {
    const data = {
      contact: {
        email: currentContact?.attributes?.email,
        first_name: currentContact?.attributes?.firstName,
        last_name: currentContact?.attributes?.lastName,
        phone_number: currentContact?.attributes?.phoneNumber,
        company: currentContact?.attributes?.company || "",
        client_type: currentContact?.attributes?.clientTypeRaw || "",
        job_service_id: currentContact?.id || ""
      }
    }
    setLoadingContactIds(elements => [...elements, currentContact?.id])
    if (!currentContact?.key) {
      putContact(baseUrl, currentContact?.id, { data })
        .then(res => {
          if (res.status === 200) {
            reloadData()
            setLoadingContactIds(elements => elements.filter(id => id !== currentContact?.id))
          }
        })
        // eslint-disable-next-line no-console
        .catch(() => {
          setLoadingContactIds(elements => elements.filter(id => id !== currentContact?.id))
        })
    } else {
      const jobServiceContactNew = {
        job_service_id: jobServiceId,
        contact_id: currentContact?.id,
        contact_type: currentContact?.attributes?.contactTypeObject?.value
      }

      // create new JobServiceContact
      createJobServiceContact(baseUrl, jobServiceContactNew)
        .then(res => {
          if (res.status === 201) {
            reloadData()
            setLoadingContactIds(elements => elements.filter(id => id !== currentContact?.id))
            const newList = [...contactModalList]
            newList[index].isReadOnly = false
            setContactModalList(newList)
          }
        })
        // eslint-disable-next-line no-console
        .catch(() => {
          setLoadingContactIds(elements => elements.filter(id => id !== currentContact?.id))
        })
    }
  }

  const handleContactSelect = (e, index) => {
    const emailList = contactModalList?.find(item => item?.attributes?.email === e.label)

    if (emailList) {
      setAlertTitle("Contact already present!")
      setExistingContact(true)
    } else {
      const newList = [...contactModalList]

      newList[index] = {
        ...newList[index],
        attributes: {
          ...e.attributes,
          contactTypeObject: {
            value: "participant",
            label: "Participant",
            title: "contactType"
          }
        },
        id: e.value,
        isReadOnly: true
      }

      setContactModalList(newList)
    }
  }

  const addContact = () => {
    setContactModalList([...contactModalList, { key: Date.now() }])
  }

  const removeContactFromJobservice = contact => {
    const params = {
      job_service_id: jobServiceId,
      contact_id: contact.id
    }

    if (!contact?.key) {
      deleteContactFromJobService(baseUrl, params)
        .then(res => {
          if (res.status === 200) {
            reloadData()
            const filteredList = contactModalList.filter(c => c.id !== contact?.id)
            setContactModalList(filteredList)
          }
        })
        // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    } else {
      const filteredList = contactModalList.filter(c => c.key !== contact?.key)
      setContactModalList(filteredList)
    }
  }

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <h2 className="modal-title">Edit Contacts</h2>
          <div className="d-flex mb-2 h6">
            <p className="mr-1">To create a new contact, please click this </p>
            <a href="/contacts/new">link</a>
          </div>
        </Modal.Title>
      </Modal.Header>
      <div className="modal-body">
        {!!contactModalList?.length && contactModalList.map((contact, index) => {
          const isLoading = loadingContactIds.includes(contact.id)
          return (
            <div key={contact?.id || contact?.key}>
              <div className="row">
                <div className="col-sm-10">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">Email Address*</label>
                    <div className="col-8 px-0">
                      <div className="row alight-items-center mb-2">
                        <div className="col-12">
                          <AsyncSelect
                            name="email"
                            value={contact?.attributes?.emailObject}
                            defaultOptions={emailOptions}
                            isSearchable
                            options={emailOptions}
                            loadOptions={searchContacts}
                            onChange={e => { handleContactSelect(e, index) }} />
                          {/* <CreatableSelect
                            name="email"
                            value={contact?.attributes?.emailObject}
                            isClearable
                            isSearchable
                            options={emailOptions}
                            onChange={e => { handleContactSelect(e, index) }} /> */}
                          {/* onCreateOption={value => { handleCreateNewContact(value, index) }} /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">Name*</label>
                    <div className="col-sm-8 p-0">
                      <div className="form-row">
                        <div className="col-6">
                          <input
                            className="form-control"
                            disabled={contact?.isReadOnly}
                            name="firstName"
                            onChange={e => handleChange(e, index)}
                            placeholder="First name"
                            defaultValue={contact?.attributes?.firstName || ""}
                            type="text" />
                        </div>
                        <div className="col-6">
                          <input
                            className="form-control"
                            disabled={contact?.isReadOnly}
                            name="lastName"
                            onChange={e => handleChange(e, index)}
                            placeholder="Last name"
                            defaultValue={contact?.attributes?.lastName || ""}
                            type="text" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      Phone Number
                    </label>
                    <input
                      className="form-control col-sm-8"
                      disabled={contact?.isReadOnly}
                      name="phoneNumber"
                      onChange={e => handleChange(e, index)}
                      defaultValue={contact?.attributes?.phoneNumber || ""}
                      type="tel" />
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      Company
                    </label>
                    <input
                      className="form-control col-sm-8"
                      disabled={contact?.isReadOnly}
                      name="company"
                      onChange={e => handleChange(e, index)}
                      defaultValue={contact?.attributes?.company || ""}
                      type="text" />
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">Client Type</label>
                    <div className="col-8 px-0">
                      <div className="row alight-items-center mb-2">
                        <div className="col-12">
                          {contact?.isReadOnly ? (
                            <input
                              className="form-control"
                              disabled
                              name="clientTypeRaw"
                              onChange={e => handleChange(e, index)}
                              defaultValue={contact?.attributes?.clientTypeObject?.label}
                              type="text" />
                          ) : (
                            <Select
                              name="clientTypeRaw"
                              onChange={e => handleChange(e, index)}
                              options={clientTypeOptions}
                              placeholder="Select"
                              value={contact?.attributes?.clientTypeObject} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">Contact Type</label>
                    <div className="col-8 px-0">
                      <div className="row alight-items-center mb-2">
                        <div className="col-12">
                          <Select
                            name="contact_type"
                            onChange={e => handleChange(e, index)}
                            options={CONTACT_TYPES}
                            placeholder="Select"
                            value={contact?.attributes?.contactTypeObject} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-2 text-right">
                  <button
                    className="btn btn-link"
                    onClick={() => removeContactFromJobservice(contact)}
                    type="button">
                    Remove
                  </button>
                </div>
              </div>
              <div className="row justify-content-end pr-4">
                <button
                  disabled={!contact?.attributes?.email || !contact?.attributes?.firstName || !contact?.attributes?.lastName || isLoading}
                  className="btn btn-red btn-lg"
                  onClick={() => handleSave(contact, index)}
                  type="button">
                  {contact?.isReadOnly ? "Add" : "Save"}
                  {isLoading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
                </button>
              </div>
              <hr className="mt-2 mb-4" />
            </div>
          )
        })}

        <div className="d-flex justify-content-between">
          <button
            className="btn btn-outlined-blue cursor-pointer"
            onClick={addContact}
            type="button">
            Link Existing Contact
          </button>
          <button
            className="btn btn-light btn-lg"
            onClick={onHide}
            type="button">
            Cancel
          </button>
        </div>

        <hr />
      </div>
      <SweetAlert
        show={existingContact}
        type="error"
        title={alertTitle}
        closeOnClickOutside
        allowEscape={false}
        onConfirm={() => setExistingContact(false)} />
    </Modal>
  )
}

export default EditContactModal
