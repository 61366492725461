import React from "react"

export default function GearBasicInput({
  title, hrefPrefix, type, createState,
  editState, value, inputValue, setInputValue,
  labelWrapperClass, inputWrapperClass, errorMessage
}) {
  if (editState) {
    return (
      <div className="form-group row">
        <label className={`${labelWrapperClass || "col-3"} col-form-label`}>{title}</label>
        <div className={inputWrapperClass || "col-9"}>
          <input
            className="form-control"
            type={type}
            value={inputValue || ""}
            onChange={event => { setInputValue(event.target.value) }} />
          {errorMessage && (
            <div className="err-msg">{errorMessage}</div>
          )}
        </div>
      </div>
    )
  }
  if (createState) {
    return (
      <div className="form-group row">
        <label className={`${labelWrapperClass || "col-3"} col-form-label`}>{title}</label>
        <div className={inputWrapperClass || "col-9"}>
          <input
            className="form-control"
            type={type}
            onChange={event => { setInputValue(event.target.value) }}
          />
          {errorMessage}
        </div>
      </div>
    )
  }
  return (
    <div className="row mb-2">
      <div className="col-3">{title}</div>
      <div className="col-9">
        {hrefPrefix ? <a href={`${hrefPrefix}:${value}`}>{value}</a> : value}
      </div>
    </div>
  )
}