import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { getUserBlockOutList } from "requesters/userRequester"
import AnchorButton from "components/AnchorButton"
import FilterDropdown from "components/FilterDropdown"
import SweetAlert from "react-bootstrap-sweetalert"
import { deleteUserBlockOut } from "requesters/userBlockOutRequester"
import { getBaseUrl } from "../../../reducers"
import UserBlockOutModal from "./components/UserBlockOutModal"


export default function UserBlockOutCard({ user }) {
  const baseUrl = useSelector(getBaseUrl)
  const [blockOutList, setBlockOutList] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [editBlockOut, setEditBlockOut] = useState(null)
  const [showMessage, setShowMessage] = useState(false)
  const [deleteBlockOut, setDeleteBlockOut] = useState(null)
  const [filteredBlockOutList, setFilteredBlockOutList] = useState([])
  const [yearList, setYearList] = useState([])
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const formattedDate = 'DD/MM/YYYY hh:mm A'

  const fetchData = () => {
    getUserBlockOutList(baseUrl, user.id)
      .then(res => {
        if (res.status === 200) {
          const blockOutList = _.orderBy(
            _.get(res.response, "userBlockOut", []),
            blockOut => moment(blockOut.attributes.startAt, "DD/MM/YYYY").toDate(),
            "asc"
          )
          setBlockOutList(blockOutList)
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  const handleDelete = () => {
    if (deleteBlockOut) {
      deleteUserBlockOut(baseUrl, deleteBlockOut)
        .then(res => {
          if (res.status === 200) {
            setShowMessage(false)
            setDeleteBlockOut(null)
            fetchData()
          }
        })
        // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    }
  }

  const filteredByYear = val => setSelectedYear(val)

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    const uniqueYears = new Set(blockOutList.map(item => moment(item.attributes.startAt, formattedDate).year()))
    setYearList(Array.from(uniqueYears).map(year => [year, year]).sort((a, b) => a[0] - b[0]))
  }, [blockOutList])

  useEffect(() => {
    const filteredBlockOutList = blockOutList.filter(item => moment(item.attributes.startAt, formattedDate).year() === selectedYear)
    setFilteredBlockOutList(filteredBlockOutList)
  }, [selectedYear, blockOutList])

  const btnStyle = {
    border: "none",
    boxShadow: "none"
  }

  return (
    <>
      <div className="card border-0 mt-4 shadow-sm">
        <div className="card-body p-4">
          <h3>Leave</h3>
          <div className="table-listing-filters">
            <FilterDropdown
              title="Year Select"
              filteredName={selectedYear}
              setFilter={filteredByYear}
              options={yearList}
              hiddenAllOption={true} />
          </div>
          { filteredBlockOutList.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-listing table-hover">
                <thead>
                  <tr>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Reason</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredBlockOutList.map(blockOut => (
                    <tr key={blockOut.id}>
                      <td>{blockOut?.attributes?.startAt}</td>
                      <td>{blockOut?.attributes?.endAt}</td>
                      <td>{blockOut?.attributes?.leaveType}</td>
                      <td className="text-right">
                        <AnchorButton
                          onClick={() => {
                              setEditBlockOut(blockOut)
                              setShowModal(true)
                            }}>
                          Edit
                        </AnchorButton>
                        <span className="text-muted mx-2">|</span>
                        <AnchorButton
                          onClick={() => {
                            setDeleteBlockOut(blockOut.id)
                            setShowMessage(true)
                            }}>
                          Delete
                        </AnchorButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="text-center">
              <p className="text-muted">None</p>
            </div>
          )}
          <AnchorButton
            className="btn btn-outlined-blue"
            onClick={() => {
              setEditBlockOut(null)
              setShowModal(true)
              }}>
            + Add Leave
          </AnchorButton>
          <UserBlockOutModal
            show={showModal}
            editable={editBlockOut}
            onHide={() => { setShowModal(false) }}
            user={user}
            reloadData={fetchData} />
        </div>
      </div>
      <SweetAlert
        show={showMessage}
        type="custom"
        customClass="text-dark"
        title="Are you sure you want to delete this leave?"
        style={{ width: "600px" }}
        confirmBtnText="Delete"
        confirmBtnCssClass="btn btn-danger"
        confirmBtnStyle={btnStyle}
        closeOnClickOutside
        allowEscape={false}
        showCancel
        onCancel={() => setShowMessage(false)}
        onConfirm={() => handleDelete()}>
        <p className="text-muted">
          This action cannot be undone.
        </p>
      </SweetAlert>
    </>
  )
}
