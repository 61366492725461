import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux"
import cx from "classnames"
import { OVERSEAS_STATES_ALLOWED, DRAFTING_OVERSEAS_LEBANON, DRAFTING_OVERSEAS_PHILIPPINES } from "utils/constants"
import { getDraftmen } from "requesters/userRequester";
import { assignDraftman, assignAdditionalDraftman, unassignAdditionalDraftman } from "requesters/jobServiceRequester";
import Select from "react-select"
import { addIcon } from "components/Icons";
import UserAvatar from "components/UserAvatar"
import { getBaseUrl } from "../../../../reducers"

const SelectDraftman = ({ jobService, reloadData, readOnly }) => {
  const {
    id,
    attributes: {
      is3D, draftmanName, draftmanNameWithInitials, draftmanId, state, draftingTeam
    }
  } = jobService
  const baseUrl = useSelector(getBaseUrl)
  const [draftmanOptions, setDraftmanOptions] = useState([])
  const [draftman, setDraftman] = useState()
  const [showDraftmanModal, setShowDraftmanModal] = useState(false)
  const [draftsmenAssignments, setDraftsmenAssignments] = useState([])
  const [assignLoading, setAssignLoading] = useState(false)
  const [draftmanIdSelected, setDraftmanIdSelected] = useState([])

  const filterDraftman = draftman => {
    if (OVERSEAS_STATES_ALLOWED.includes(state)) {
      switch (draftingTeam) {
        case DRAFTING_OVERSEAS_LEBANON:
          return draftman.filter(option => option.is_overseas_lebanon)
        case DRAFTING_OVERSEAS_PHILIPPINES:
          return draftman.filter(option => option.is_overseas_philippines)
        default:
          return draftman.filter(option => option.is_overseas)
      }
    }
  
    return draftman.filter(option => 
      is3D 
        ? option.is_draftmen_3d
        : option.is_draftmen_2d
    )
  }

  const getDraftmenHandler = () => {
    getDraftmen(baseUrl)
      .then(res => {
        if (res.status === 200) {
          const newData = res.response
          setDraftmanOptions(filterDraftman(newData))
          setDraftman(newData.find(draftman => draftman.value === draftmanId))
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  const updateDraftmanHandler = (jobServiceId, params) => {
    assignDraftman(baseUrl, jobServiceId, params)
      .then(res => {
        if (res.status === 200) {
          setDraftman(draftmanOptions.find(s => s.value === res.response.jobService.attributes.draftmanId))
          reloadData()
          // window.location.reload()
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))

    window.location.reload()
  }

  const addAdditionalDraftmanHandler = (jobServiceId, params) => {
    setAssignLoading(true)
    assignAdditionalDraftman(baseUrl, jobServiceId, params)
      .then(res => {
        if (res.response === 200) {
          window.location.reload()
        } else {
          setAssignLoading(false)
        }
      })
      .catch(() => {
        setAssignLoading(false)
      })
  }

  const removeAdditionalDraftmanHandler = (jobServiceId, params) => {
    setAssignLoading(true)
    unassignAdditionalDraftman(baseUrl, jobServiceId, params)
      .then(res => {
        if (res.response === 200) {
          window.location.reload()
        } else {
          setAssignLoading(false)
        }
      })
      .catch(() => {
        setAssignLoading(false)
      })
  }

  useEffect(() => {
    if (jobService) {
      getDraftmenHandler()
      setDraftsmenAssignments(jobService.attributes.draftsmenAssignments)
    }
  }, [draftmanId])

  useEffect(() => {
    let newDraftmanIdSelected = []
    if (draftman) {
      newDraftmanIdSelected = [draftman.value]
    }
    if (draftsmenAssignments.length > 0) {
      draftsmenAssignments.forEach(draftsmenAssignment => {
        newDraftmanIdSelected.push(draftsmenAssignment.draftsmenId)
      })
    }
    setDraftmanIdSelected(newDraftmanIdSelected)
  }, [draftman, draftsmenAssignments])

  const DraftmanModal = useCallback(() => {
    const closeModal = () => {
      setShowDraftmanModal(false)
    }
    return (
      <div
        onMouseLeave={closeModal}
        style={{ padding: "10px" }}
        className={cx("dropdown-menu shadow-sm", { "show-modal-select": showDraftmanModal })}>
        <Select
          isDisabled={readOnly}
          className="assign-dropdown"
          name="draftman"
          isSearchable
          placeholder="Select Draftsman"
          options={draftmanOptions.filter(s => !draftmanIdSelected.includes(Number(s.value)))}
          onChange={e => {
            addAdditionalDraftmanHandler(id, { draftman_id: e.value, role: "secondary" })
            setShowDraftmanModal(false)
          }} />
      </div>
    )
  }, [draftmanIdSelected, showDraftmanModal, draftmanOptions])


  return (
    <div className="assign mb-3">
      <div className="d-flex align-items-center">
        <div className="mr-1">
          Primary Draftsmen
          {readOnly && ":"}
        </div>
        {!readOnly ? (
          <Select
            isDisabled={readOnly}
            className="assign-dropdown"
            name="draftman"
            isSearchable
            placeholder="Select Draftsman"
            options={draftmanOptions}
            value={draftman}
            onChange={e => {
              setDraftman(e)
              updateDraftmanHandler(id, { draftman_id: e.value })
            }} />
        ) : (
          <div className="mr-3">
            <UserAvatar
              readOnly
              initials={draftmanNameWithInitials}
              name={draftmanName} />
          </div>
        )}
      </div>
      {is3D && (
        <div className="d-flex align-items-center mt-2" style={{ position: "relative" }}>
          <div className="mr-1">Assignees: </div>
          {draftsmenAssignments.map(draftsmenAssignment => (
            <UserAvatar
              readOnly={readOnly}
              key={draftsmenAssignment.id}
              initials={draftsmenAssignment.draftsmenInitials}
              name={draftsmenAssignment.name}
              onRemove={() => {
                removeAdditionalDraftmanHandler(id, { draftsmen_assignment_id: draftsmenAssignment.id })
              }} />
          ))}
          {!readOnly && (
            <button disabled={assignLoading || readOnly} className="btn btn-sm" type="button" onClick={() => setShowDraftmanModal(!showDraftmanModal)}>
              <img
                src={addIcon} />
            </button>
          )}
          <DraftmanModal />
        </div>
      )}
    </div>
  )
}
export default SelectDraftman
