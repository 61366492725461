import customAxios from "./customAxios";
import normalize from "json-api-normalizer"

export const getSurveyorSkills = baseUrl => {
  const path = `${baseUrl}/surveyor_skills`

  return customAxios.get(path)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}
