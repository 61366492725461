import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { assignSurveyor, updatePrimarySurveyor } from "requesters/jobServiceRequester"
import Select from "react-select"
import SweetAlert from "react-bootstrap-sweetalert"
import { getBaseUrl } from "../../../../reducers"

const SelectSurveyor = ({ jobService, reloadData }) => {
  const {
    id,
    attributes: {
      jobId,
      draftedPrimarySurveyorId,
      suggestedSurveyors,
      surveyorsList,
      draftedPrimarySurveyorBookingValid
    }
  } = jobService
  const baseUrl = useSelector(getBaseUrl)
  const [surveyor, setSurveyor] = useState()
  const [invalidPrimarySurveyorChangeError, setInvalidPrimarySurveyorChangeError] = useState("")
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (suggestedSurveyors) {
      let surveyorOptions = suggestedSurveyors.suggested || []
      surveyorOptions = surveyorOptions.concat(suggestedSurveyors.other || [])

      setSurveyor(surveyorOptions.find(surveyor => surveyor.value === draftedPrimarySurveyorId))
    }
  }, [draftedPrimarySurveyorId])

  const reloadPage = () => {
    window.location.href = `/jobs/${jobId}/job_services/${id}`
    return window.location.href
  }

  const updateSurveyorHandler = (jobServiceId, params, e) => {
      updatePrimarySurveyor(baseUrl, jobServiceId, params.surveyor_id)
        .then(res => {
          if (res.status === 200) {
            reloadPage()
            setSurveyor(e)
          } else {
            setInvalidPrimarySurveyorChangeError(res?.error?.response?.data?.errors[0]?.detail)
            setShowError(true)
          }
        })
        // eslint-disable-next-line no-console
        .catch(err => console.log(err))
  }

  const groupedOptions = [
    {
      label: "Suggested",
      options: suggestedSurveyors?.suggested
    },
    {
      label: "Other",
      options: suggestedSurveyors?.other
    }
  ];

  const controlStyle = draftedPrimarySurveyorBookingValid ? {} : { borderColor: "orange", backgroundColor: "#fffbf1" }
  const singleValueStyle = draftedPrimarySurveyorBookingValid ? {} : { color: "orange" }
  return (
    <>
      <SweetAlert
        show={showError}
        type="error"
        title="Cannot change primary surveyor!"
        style={{ width: "600px" }}
        closeOnClickOutside
        allowEscape={false}
        onConfirm={() => {
          setShowError(false)
        }}>
        {invalidPrimarySurveyorChangeError}
      </SweetAlert>
      <div className="assign mb-3">
        <div className="d-flex align-items-center">
          <div className="pr-2">Primary Surveyor</div>
          <Select
            className="assign-dropdown"
            isClearable
            isOptionDisabled={option => option.disabled}
            isSearchable
            name="surveyor"
            onChange={e => {
              updateSurveyorHandler(id, { surveyor_id: (e?.value || null), role: "primary" }, e)
            }}
            options={groupedOptions}
            placeholder="Select Surveyor"
            value={surveyor}
            styles={{
              control: baseStyles => ({ ...baseStyles, ...controlStyle }),
              singleValue: baseStyles => ({ ...baseStyles, ...singleValueStyle }),
              groupHeading: base => ({
                ...base,
                fontSize: "1rem",
                fontWeight: "bold",
                textTransform: "uppercase",
              }),
              option: base => ({
                ...base,
                paddingLeft: 20,
              }),
            }} />
        </div>
      </div>
    </>
  )
}
export default SelectSurveyor
