import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert"
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { roleCheck } from "utils/index"
import { format } from "date-fns"
import { getBaseUrl, getCurrentRole, getCurrentUser } from "../../../../reducers";
import {
  OPERATIONS,
  DRAFTING_MANAGER,
  DRAFTING_3D_MANAGER,
  REGISTERED_SURVEYORS_MANAGER
} from "../../../../../../utils/constants";
import UpdateTimeSpentModal from "../../../../../../components/modal/UpdateTimeSpentModal";
import { deleteTimeSpent } from "../../../../../../requesters/timeSpentRequester";
import AnchorButton from "components/AnchorButton";
import { closeIcon } from "components/Icons";

const TimeSpentTable = ({ timeSpents, currentState, reloadData }) => {
  const location = useLocation()
  const baseUrl = useSelector(getBaseUrl)
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentRole = useSelector(getCurrentRole)
  const currentTeamRole = currentUser.attributes.current_team_role
  const isAdminOrOperations = roleCheck(systemRole, currentRole, OPERATIONS)
  const isDraftingManager = roleCheck(systemRole, currentTeamRole, DRAFTING_3D_MANAGER) || roleCheck(systemRole, currentTeamRole, DRAFTING_MANAGER)
  const isRSManager = roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)
  const isManagerRole = isAdminOrOperations || isDraftingManager || isRSManager

  const [showingUpdateModal, setShowingUpdateModal] = useState(false)
  const [currentTimeSpent, setCurrentTimeSpent] = useState(undefined)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const isNotRegisteredSurveyorsActivity = !location.pathname.includes("registered-surveyors-activities")

  const onDeleteTimeSpent = () => {
    deleteTimeSpent(baseUrl, currentTimeSpent.id)
    .then( res => {
      if (res.status === 200) {
        setCurrentTimeSpent(undefined)
        reloadData();
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
    .finally(() => setShowConfirmation(false))
  }

  const TimeSpentRow = timeSpent => {
    const {
      attributes: {
        trackedAt,
        trackedHours,
        trackedOnState,
        trackableId,
        trackableType,
        addedByUser: { name: userName },
        notes,
        cost
      }, id
    } = timeSpent

    const canEdit = isManagerRole || trackedOnState === currentState
    const isAdditionalTimeSpent = trackableType === 'Task' && location.pathname.includes("job_services")
    
    return (
      <tr className="border-top border-bottom" key={`${id}-${trackedAt}`}>
        <td>{format(new Date(trackedAt), "d MMM yyyy")}</td>
        <td>{userName}</td>
        <td>{notes}</td>
        <td>{trackedHours}</td>
        {isManagerRole && <td>{`$${cost}`}</td>}
        {(isNotRegisteredSurveyorsActivity && isAdditionalTimeSpent) ? (
          <td>
            <Link to={`/registered-surveyors-activities/${trackableId}`} className="btn btn-link p-0">Go to Activity</Link>
          </td>
        ) : (
          <>
            <td className="action-buttons">
              {(!isAdditionalTimeSpent && canEdit) && (
                <button
                  type="button"
                  className="btn btn-link p-0"
                  onClick={() => {
                  setCurrentTimeSpent(timeSpent)
                  setShowingUpdateModal(true)
                }}>
                  Edit
                </button>
              )}
            </td>
            <td>
              {!isAdditionalTimeSpent && (
                <AnchorButton
                  onClick={() => {
                    setCurrentTimeSpent(timeSpent)
                    setShowConfirmation(true)
                  }}>
                  <img src={closeIcon} />
                </AnchorButton>
              )}
            </td>
          </>
        )}
      </tr>
    )
  }

  const totalHours = timeSpents.reduce((total, timeSpent) => total + parseFloat(timeSpent.attributes?.trackedHours), 0)
  const totalCost = timeSpents.reduce((total, timeSpent) => total + parseFloat(timeSpent.attributes?.cost), 0)

  return (
    <div className="table-responsive">
      <table className="table mt-4 bg-white">
        <thead className="bg-light">
          <tr>
            <th>Date</th>
            <th>User</th>
            <th>Notes</th>
            <th>Hours</th>
            {isManagerRole && <th>Cost</th>}
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="bg-white">
          { timeSpents.map(timeSpent => TimeSpentRow(timeSpent)) }

          <tr className="border-top border-bottom font-weight-bold" key="total-row">
            <td colSpan={3}>Total</td>
            <td>{totalHours}</td>
            {isManagerRole && <td>{`$${totalCost}`}</td>}
          </tr>
        </tbody>
      </table>

      { currentTimeSpent && (
        <UpdateTimeSpentModal
          show={showingUpdateModal}
          onHide={() => setShowingUpdateModal(false)}
          reloadData={reloadData}
          timeSpent={currentTimeSpent} />
      )}
      <SweetAlert
        show={showConfirmation}
        type="info"
        title="Are you sure?"
        style={{ width: "600px" }}
        closeOnClickOutside
        allowEscape={false}
        showCancel
        cancelBtnBsStyle="secondary"
        onCancel={() => { setShowConfirmation(false) }}
        onConfirm={onDeleteTimeSpent}>
        Are you sure you want to delete this time entry?
      </SweetAlert>
    </div>
  )
}

export default TimeSpentTable
