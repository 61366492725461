import React, { useState, useMemo } from "react";
import { useLocation } from 'react-router-dom';
import { sortByName } from "utils/index";
import { sortIcon } from "components/Icons";
import FlaggedJobRow from "./FlaggedJobRow";

export default function FlaggedJobTable({
  jobServiceList, reloadData, readOnly
}) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isCancelledFilter = searchParams.get('filter') === 'cancelled';
  const [switchOrder, setSwitchOrder] = useState(false);
  const [bookingOrder, setBookingOrder] = useState(false);

  // Sort by Surveyor Name
  const sortedBySurveyor = useMemo(() => {
    return sortByName([...jobServiceList], "surveyorName", switchOrder);
  }, [jobServiceList, switchOrder]);

  // Sort by Booking Time
  const sortedByBookingTime = useMemo(() => {
    return [...jobServiceList].sort((a, b) => {
      const timeA = new Date(a.attributes.bookingTime).getTime();
      const timeB = new Date(b.attributes.bookingTime).getTime();
      return bookingOrder ? timeB - timeA : timeA - timeB;
    });
  }, [jobServiceList, bookingOrder]);

  // Sort by Cancelled At
  const sortedByCancelledDate = useMemo(() => {
    return [...jobServiceList].sort((a, b) => {
      const timeA = new Date(a.attributes.cancelledAt || 0).getTime();
      const timeB = new Date(b.attributes.cancelledAt || 0).getTime();
      return bookingOrder ? timeB - timeA : timeA - timeB;
    });
  }, [jobServiceList, bookingOrder]);

  // Determine which sorted list to display
  const displayedJobServiceList = useMemo(() => {
    if (isCancelledFilter) {
      return sortedByCancelledDate;
    }
    return bookingOrder !== null ? sortedByBookingTime : sortedBySurveyor;
  }, [isCancelledFilter, sortedByCancelledDate, sortedByBookingTime, sortedBySurveyor, bookingOrder]);

  return (
    <div className="table-responsive">
      <table className="table table-listing table-hover">
        <thead>
          <tr>
            <th>Job No.</th>
            <th>Client Name</th>
            <th>Contact</th>
            <th>Job Address</th>
            <th>Job Service</th>
            {!readOnly && (
              <th className="text-right">Value</th>
            )}
            <th className="text-center">Status</th>
            <th className="absorbing-column">
              <span className="mr-1">Surveyor</span>
              <span type="button" onClick={() => setSwitchOrder(!switchOrder)}>
                <img src={sortIcon} alt="Sort Surveyor" />
              </span>
            </th>
            <th className="text-center">
              <span className="mr-1">{isCancelledFilter ? 'Cancelled Date' : 'Booking Date'}</span>
              <span type="button" onClick={() => setBookingOrder(!bookingOrder)}>
                <img src={sortIcon} alt={`Sort ${isCancelledFilter ? 'Cancelled Date' : 'Booking Date'}`} />
              </span>
            </th>
            <th>Notes</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!!displayedJobServiceList?.length && displayedJobServiceList.map(jobService => (
            <FlaggedJobRow
              key={`row-${jobService.id}`}
              rowData={jobService}
              bySection="flagged_jobs"
              reloadData={reloadData}
              readOnly={readOnly}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
