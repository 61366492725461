import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert"
import { writeOffAmountJobService } from "../../../../../requesters/jobServiceRequester"
import { getBaseUrl } from "../../../reducers";

const WriteOffJobServiceModal = ({
  show, onHide, reloadData, jobService
}) => {
  const {
    attributes: { availableWriteOffAmount, amountPaidCannotWriteOff }
  } = jobService
  const owingAmount = availableWriteOffAmount - amountPaidCannotWriteOff

  const baseUrl = useSelector(getBaseUrl)
  const [requesting, setRequesting] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [writeOffAmount, setWriteOffAmount] = useState(null)
  const [writeOffReason, setWriteOffReason] = useState("")
  const canSubmit = writeOffAmount?.toString().trim() && writeOffReason?.trim() && !requesting

  const onChangeWriteOffAmount = newWriteOffAmount => {
    if (Number(newWriteOffAmount) > Number(availableWriteOffAmount)) return

    setWriteOffAmount(newWriteOffAmount)
  }

  useEffect(() => {
    setWriteOffAmount(jobService.attributes.writeOffAmount > 0 ? jobService.attributes.writeOffAmount : owingAmount)
  }, [show])

  const onConfirm = () => {
    const payLoad = { write_off_amount: writeOffAmount, write_off_reason: writeOffReason }
    setShowConfirmation(false)
    setRequesting(true)
    writeOffAmountJobService(baseUrl, jobService.id, payLoad)
    .then(res => {
      if (res.status === 200 && res.response.success) {
        reloadData()
        onHide()
      }
    })
    .catch(({ err }) => console.log(err))
    .finally(() => setRequesting(false))
  }

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <div>
          <h2 className="modal-title">Write Off Job Amount</h2>
        </div>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          <div className="form-group row">
            <label className="col-3 col-form-label"> Total Amount </label>
            <div className="col-7">
              <input
                disabled
                className="form-control"
                inputMode="decimal"
                pattern="[0-9]*"
                type="number"
                value={availableWriteOffAmount} />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Amount Owing</label>
            <div className="col-7">
              <input
                disabled
                className="form-control"
                inputMode="decimal"
                pattern="[0-9]*"
                type="number"
                value={owingAmount} />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Write Off Amount*</label>
            <div className="col-7">
              <input
                className="form-control"
                type="number"
                inputMode="decimal"
                pattern="[0-9]*"
                min={0}
                max={availableWriteOffAmount}
                value={writeOffAmount}
                onChange={e => onChangeWriteOffAmount(e.target.value)} />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Reason for Write Off*</label>
            <div className="col-7">
              <input
                className="form-control"
                type="text"
                value={writeOffReason}
                onChange={e => setWriteOffReason(e.target.value)} />
            </div>
          </div>
        </form>
        <hr />
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-primary btn-lg"
          onClick={() => setShowConfirmation(true)}
          disabled={!canSubmit}
          type="submit">
          Write Off
          {requesting && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
        </button>
      </div>

      <SweetAlert
        show={showConfirmation}
        type="info"
        title="Are you sure?"
        style={{ width: "600px" }}
        closeOnClickOutside
        allowEscape={false}
        showCloseButton
        onCancel={() => setShowConfirmation(false)}
        confirmBtnText="Yes"
        onConfirm={onConfirm}>
        You want to write off $
        <strong>{writeOffAmount}</strong>
        ?
      </SweetAlert>
    </Modal>
  )
}

export default WriteOffJobServiceModal
