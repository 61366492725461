import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { parse, format } from "date-fns"
import { getBaseUrl } from "../../reducers";

import { updateAppConfig } from "../../../../requesters/appConfigRequester";
import {
  getAdminDashboardData,
  getOverdueDataCount,
  getOverdueJobsCount,
  getJobPackIncompleteCount,
  getOnHoldJobServiceCount,
  getClientDelayedJobServiceCount,
  getAwaitingSendFinalReminderJobServiceCount,
  getCanBeMovedForwardJobServiceCount,
} from "../../../../requesters/jobServiceRequester";
import DashboardCard from "./components/DashboardCard";
import UpdateNextBookingDate2DModal from "./components/UpdateNextBookingDate2DModal";
import UpdateNextBookingDate3DModal from "./components/UpdateNextBookingDate3DModal";


const DashboardForAdmin = ({ currentUser }) => {
  const { attributes: { firstname } } = currentUser

  const baseUrl = useSelector(getBaseUrl)
  const [invalidImports, setInvalidImports] = useState()
  const [gpsRequestedJobs, setGpsRequestedJobs] = useState(0)
  const [incompleteJob, setIncompleteJobs] = useState(0)
  const [overdueData, setOverdueData] = useState(0)
  const [overdueJobs, setOverdueJobs] = useState(0)
  const [jobsReady, setJobsReady] = useState(0)
  const [jobPacksIncomplete, setJobPacksIncomplete] = useState(0)
  const [unpaidBeforePayDraftingJobs, setUnpaidBeforePayDraftingJobs] = useState(0)
  const [unallocatedJobs, setUnallocatedJobs] = useState(0)
  const [upcomingServices, setUpcomingServices] = useState(0)
  const [inspectionRequired, setInspectionRequired] = useState(0)
  const [docketToReview, setDocketToReview] = useState(0)
  const [additionalInfo, setAdditionalInfo] = useState(0)
  const [createDeliverables, setCreateDeliverables] = useState(0)
  const [forPlanReport, setForPlanReport] = useState(0)
  const [resolvedFromRS, setResolvedFromRS] = useState(0)
  const [onHoldJobService, setOnHoldJobService] = useState(0)
  const [clientDelayedJobService, setClientDelayedJobService] = useState(0)
  const [collectPaymentOnsite, setCollectPaymentOnsite] = useState(0)
  const [jobsOutstandingInvoice, setJobsOutstandingInvoice] = useState(0)
  const [awaitingSendFinalReminderCount, setAwaitingSendFinalReminderCount] = useState(0)
  const [searchesToCompleteCount, setSearchesToCompleteCount] = useState(0)
  const [canBeMovedForwardCount, setCanBeMovedForwardCount] = useState(0)

  const [nextBookingDate2D, setNextBookingDate2D] = useState(undefined)
  const [nextBookingDate3D, setNextBookingDate3D] = useState(undefined)

  const [showUpdateNextBookingDate2DModal, setShowUpdateNextBookingDate2DModal] = useState(false)
  const [showUpdateNextBookingDate3DModal, setShowUpdateNextBookingDate3DModal] = useState(false)

  const fetchDashboardDataOfSimpleTrackingJobs = () => {
    getAdminDashboardData(baseUrl)
      .then(res => {
        if (res.status === 200) {
          setInvalidImports(res.response.invalid_pipedrive_imports)
          setGpsRequestedJobs(res.response.job_service_gps_requested_count)
          setIncompleteJobs(res.response.incomplete_jobs_count)
          setJobsReady(res.response.jobs_ready_count)
          setUnpaidBeforePayDraftingJobs(res.response.unpaid_pay_before_drafting_jobs_count)
          setUnallocatedJobs(res.response.unallocated_jobs_count)
          setUpcomingServices(res.response.service_required_count)
          setInspectionRequired(res.response.inspection_required_count)
          setDocketToReview(res.response.docket_to_review_count)
          setNextBookingDate2D(res.response.next_booking_date_2d)
          setNextBookingDate3D(res.response.next_booking_date_3d)
          setAdditionalInfo(res.response.additional_info_count)
          setCreateDeliverables(res.response.create_deliverables_count)
          setForPlanReport(res.response.for_plan_report_count)
          setResolvedFromRS(res.response.resolved_from_rs_count)
          setCollectPaymentOnsite(res.response.collect_payment_onsite)
          setJobsOutstandingInvoice(res.response.jobs_outstanding_invoice_count)
          setSearchesToCompleteCount(res.response.searches_to_complete_count)
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  useEffect(() => {
    fetchDashboardDataOfSimpleTrackingJobs()
  }, [])


  const onChangeBookingDate = (date, variable) => {
    updateAppConfig(baseUrl, {
      value: date,
      var: variable
    }).then(res => {
      if (res.status === 200) {
        setShowUpdateNextBookingDate2DModal(false)
        setShowUpdateNextBookingDate3DModal(false)
      }
    })
  }

  return (
    <div className="dashboard">
      <div className="row no-gutters">
        <div className="col-lg-4 col-xl-3">
          <div className="container-fluid">
            <div className="container-inner">
              <div className="greeting">
                Hello,
                {" "}
                <br />
                {" "}
                {firstname}
                {" "}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-xl-9 today">
          <div className="container-fluid">
            <div className="container-inner">
              <h2>{"today".toUpperCase()}</h2>
              <div className="row key-to-do">
                <div className="col-lg-10">
                  <div className="row">
                    {/* <DashboardCard
                      link="/leads"
                      title="complete today"
                      count="0"
                      ofTotal
                      total="43"
                      cost="123,121.00"
                      colorClass="bg-light" /> */}
                    {/* <DashboardCard
                      link="/leads"
                      title="to be booked"
                      count="2"
                      cost="1,534.00"
                      colorClass="bg-light" /> */}
                  </div>
                  <div className="row mt-4">
                    <DashboardCard
                      containerStyle={
                        {
                          cursor: "pointer"
                        }
                      }
                      title="2D Next Available Booking Date"
                      count={nextBookingDate2D ? format(parse(nextBookingDate2D, "dd/MM/yyyy", new Date()), "EEE dd/MM/yyyy") : "--- --/--/---"}
                      colorClass="bg-light"
                      onClick={() => {
                        setShowUpdateNextBookingDate2DModal(true)
                      }} />
                    <DashboardCard
                      containerStyle={
                        {
                          cursor: "pointer"
                        }
                      }
                      title="3D Next Available Booking Date"
                      count={nextBookingDate3D ? format(parse(nextBookingDate3D, "dd/MM/yyyy", new Date()), "EEE dd/MM/yyyy") : "--- --/--/---"}
                      colorClass="bg-light"
                      onClick={() => {
                        setShowUpdateNextBookingDate3DModal(true)
                      }} />
                    <DashboardCard
                      link="/flagged-jobs?filter=gps_requested"
                      title="GPS Requests"
                      count={gpsRequestedJobs}
                      colorClass={gpsRequestedJobs === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/live-job-status?filter=incomplete_jobs"
                      title="Surveyor Incomplete Jobs"
                      count={incompleteJob}
                      colorClass={incompleteJob === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/flagged-jobs?filter=data_overdue"
                      title="Surveyor Data Overdue"
                      selfFetchingCount
                      getCountRequester={getOverdueDataCount}
                      setCount={setOverdueData}
                      count={overdueData}
                      colorClass={overdueData === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/live-job-status?filter=for_plan_report"
                      title="Plan Report"
                      count={forPlanReport}
                      colorClass={forPlanReport === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/live-job-status?filter=resolved_from_rs"
                      title="Resolved from Registered Surveyor"
                      count={resolvedFromRS}
                      colorClass={resolvedFromRS === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/live-job-status?filter=can_be_moved_forward"
                      title="Jobs Can Be Moved Forward"
                      selfFetchingCount
                      getCountRequester={getCanBeMovedForwardJobServiceCount}
                      setCount={setCanBeMovedForwardCount}
                      count={canBeMovedForwardCount}
                      colorClass={canBeMovedForwardCount === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/live-job-status?filter=jobs_overdue"
                      title="Client Jobs Overdue"
                      selfFetchingCount
                      getCountRequester={getOverdueJobsCount}
                      setCount={setOverdueJobs}
                      count={overdueJobs}
                      colorClass={overdueJobs === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/live-job-status?filter=jobs_to_be_allocated"
                      title="Jobs to be allocated"
                      count={unallocatedJobs}
                      colorClass={unallocatedJobs === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/live-job-status?filter=ready_to_send"
                      title="Jobs Ready To Send"
                      count={jobsReady}
                      colorClass={jobsReady === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/live-job-status?filter=job_pack_not_uploaded"
                      title="Job Packs Incomplete until Today"
                      selfFetchingCount
                      getCountRequester={getJobPackIncompleteCount}
                      setCount={setJobPacksIncomplete}
                      count={jobPacksIncomplete}
                      colorClass={jobPacksIncomplete === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/live-job-status?filter=unpaid_pay_before_drafting"
                      title="Unpaid Pay Before Drafting"
                      count={unpaidBeforePayDraftingJobs}
                      colorClass={unpaidBeforePayDraftingJobs === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/settings/gears?by_status=service_required"
                      title="Asset Requirements"
                      count={upcomingServices}
                      colorClass={upcomingServices === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/settings/gears?by_status=inspection_required"
                      title="Asset Inspection"
                      count={inspectionRequired}
                      colorClass={inspectionRequired === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/flagged-jobs?filter=additional_info"
                      title="Additional Info"
                      count={additionalInfo}
                      colorClass={additionalInfo === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/live-job-status?filter=create_deliverables"
                      title="3D Deliverables"
                      count={createDeliverables}
                      colorClass={createDeliverables === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/live-job-status?filter=job_docket_to_review"
                      title="Dockets To Review"
                      count={docketToReview}
                      colorClass={docketToReview === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/flagged-jobs?filter=on_hold"
                      title="On Hold"
                      selfFetchingCount
                      getCountRequester={getOnHoldJobServiceCount}
                      setCount={setOnHoldJobService}
                      count={onHoldJobService}
                      colorClass={onHoldJobService === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/flagged-jobs?filter=client_delayed"
                      title="Jobs Client Delayed"
                      selfFetchingCount
                      getCountRequester={getClientDelayedJobServiceCount}
                      setCount={setClientDelayedJobService}
                      count={clientDelayedJobService}
                      colorClass={clientDelayedJobService === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/live-job-status?filter=jobs_is_outstanding_invoice"
                      title="Outstanding Invoices"
                      count={jobsOutstandingInvoice}
                      colorClass={jobsOutstandingInvoice === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/accounts?by_invoice_status=awaiting_send_final_reminder"
                      title="Jobs Awaiting Send Final Reminder"
                      selfFetchingCount
                      getCountRequester={getAwaitingSendFinalReminderJobServiceCount}
                      setCount={setAwaitingSendFinalReminderCount}
                      count={awaitingSendFinalReminderCount}
                      colorClass={awaitingSendFinalReminderCount === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/live-job-status?filter=searches_to_complete"
                      title="Searches to Complete"
                      count={searchesToCompleteCount}
                      colorClass={searchesToCompleteCount === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/live-job-status?filter=collect_payment_onsite"
                      title="Collect Payment Onsite"
                      count={collectPaymentOnsite}
                      colorClass={collectPaymentOnsite === 0 ? "bg-light" : "bg-coral"} />
                  </div>
                  <div className="row mt-4">
                    {/* <DashboardCard
                      link="/leads"
                      title="data overdue"
                      count="1"
                      cost="123,121.00"
                      colorClass="bg-coral" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <DashboardCardContainer title="Dashboard">
          <div className="row">
            <DashboardCard
              link="/backend/invalid_pipedrive_imports"
              title="Invalid Pipedrive Imports"
              count={invalidImports}
              openInNewTab
              colorClass="bg-coral" />

          </div>
          {/*<div className="row">
            <DashboardCard
              link="leads"
              title="Active Jobs"
              count="1234"
              cost="124,343.00"
              colorClass="bg-light" />
            <DashboardCard
              link="/leads"
              title="Complete This Month"
              count="4"
              cost="3,121.00"
              colorClass="bg-green" />
          </div>
          <div className="row mt-4">
            <DashboardCard
              link="/jobs"
              title="Leads"
              count="123"
              cost="721.00"
              colorClass="bg-light" />
            <DashboardCard
              link="/jobs"
              title="Field"
              count="230"
              cost="1,200.00"
              colorClass="bg-light" />
          </div>
          <div className="row mt-4">
            <DashboardCard
              link="/jobs"
              title="Overdue Invoices"
              count="3"
              cost="721.00"
              colorClass="bg-coral" />
            {currentRole === 'operations' && (
              <DashboardCard
                link="/jobs"
                title="Drafting"
                count="34"
                cost="1,200.00"
                colorClass="bg-light" />
            )}
          </div> */}
        {/* </DashboardCardContainer> */}
      </div>

      <UpdateNextBookingDate2DModal
        show={showUpdateNextBookingDate2DModal}
        onHide={() => {
          setShowUpdateNextBookingDate2DModal(false)
        }}
        nextBookingDate2D={nextBookingDate2D}
        setNextBookingDate2D={setNextBookingDate2D}
        onChangeBookingDate={onChangeBookingDate} />
      <UpdateNextBookingDate3DModal
        show={showUpdateNextBookingDate3DModal}
        onHide={() => {
          setShowUpdateNextBookingDate3DModal(false)
        }}
        nextBookingDate3D={nextBookingDate3D}
        setNextBookingDate3D={setNextBookingDate3D}
        onChangeBookingDate={onChangeBookingDate} />
    </div>
  )
}

export default DashboardForAdmin
