import React from "react";
import { Link } from "react-router-dom"
import cx from "classnames";
import {
  JOB_STATES_DISPLAY, ON_HOLD, CANCELLED, CONFIRMED, CLIENT_DELAYED
} from "utils/constants"
import { format } from "date-fns"

export default function JobServicesRow({ job }) {
  const {
    id,
    jobId,
    jobNo,
    forProjectX,
    projectXJobNo,
    jobAddress,
    clientName,
    clientNumber,
    label,
    state,
    bookingTime,
    bookingDates,
    clientDueDate,
    payBeforeDrafting,
    checkedInAt,
  } = job.attributes;
  const displayJobNo = forProjectX ? projectXJobNo : jobNo
  const isDraftedAndPayBeforeDrafting = ["deposit_invoice_drafted", "final_invoice_drafted"].includes(state) && payBeforeDrafting
  const invoiceStateColor = isDraftedAndPayBeforeDrafting ? "bg-green" : JOB_STATES_DISPLAY[state]?.bgClass
  const invoiceStateText = isDraftedAndPayBeforeDrafting ? "Invoice Sent (Pay Before Drafting)" : JOB_STATES_DISPLAY[state]?.text

  const checkInOverDue = () => {
    if (checkedInAt || state !== CONFIRMED) return false

    return (new Date() - new Date(bookingTime)) / (1000 * 60 * 60) > 2
  }

  const elementClasses = () => {
    const classes = []

    if ([CANCELLED, ON_HOLD, CLIENT_DELAYED].includes(state)) classes.push("greyout")
    else if (checkInOverDue()) classes.push("highlight")

    return classes
  }

  return (
    <tr className={elementClasses().join(" ")}>
      <td>
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{displayJobNo}</Link>
      </td>
      <td>
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{jobAddress}</Link>
      </td>
      <td>
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{clientName}</Link>
      </td>
      <td>
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{clientNumber}</Link>
      </td>
      <td>
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{label}</Link>
      </td>
      <td className={cx(`dropdown text-center ${invoiceStateColor} keep`)}>
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration font-weight-bold">{invoiceStateText}</Link>
      </td>
      <td className="text-center">
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{bookingDates && bookingDates[0] && format(new Date(bookingDates[0]), "dd MMM yyyy")}</Link>
      </td>
      <td className="text-center">
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{clientDueDate}</Link>
      </td>
      <td>
        <div className="d-flex align-items-center justify-content-end">
          <a href={`/jobs/${jobId}/job_services/${id}`}>Details</a>
        </div>
      </td>
    </tr>
  );
}
