import { sortIcon } from "components/Icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  ACCOUNTS_MANAGER,
  SURVEYORS_MANAGER,
} from "utils/constants";
import { roleCheck, sortByTypeAndName } from "utils/index";
import { getCurrentUser } from "../../reducers";
import TableRow from "./TableRow";

export default function SchedulesDateTable({
  jobServicesDay, calendarDateString, reloadData, surveyorOptions, bySection, readOnly
}) {
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role
  const [switchOrder, setSwitchOrder] = useState(true)
  const [sortedJobServices, setSortedJobServices] = useState(jobServicesDay)
  const isSurveyorManager = roleCheck(systemRole, currentTeamRole, SURVEYORS_MANAGER)
  const isAccountsManager = roleCheck(systemRole, currentTeamRole, ACCOUNTS_MANAGER)

  const sortJobServices = () => {
    setSwitchOrder(!switchOrder)
    sortByTypeAndName(jobServicesDay, "surveyor_type", "surveyor_name", switchOrder)
    setSortedJobServices(jobServicesDay)
  }

  useEffect(() => {
    sortJobServices()
  }, [jobServicesDay])

  return (
    <div className="">
      <table className="table table-listing table-hover text-md">
        <thead>
          <tr>
            <th>Job No.</th>
            <th>Client Name</th>
            <th>Job Title</th>
            <th>Job Service</th>
            {!readOnly && (
              <th className="text-right">Value</th>
            )}
            <th className="text-center w-100px">Job Status</th>
            {(isSurveyorManager || isAccountsManager || !readOnly) && (
              <th className="text-center w-100px">Invoice Status</th>
            )}
            <th className="absorbing-column text-center">
              <span className="mr-1">Surveyor</span>
              <span type="button" onClick={() => sortJobServices()}>
                <img src={sortIcon} />
              </span>
            </th>
            <th className="text-center">
              <span className="mr-1">Time</span>
              <span type="button" onClick={() => sortJobServices()}>
                <img src={sortIcon} />
              </span>
            </th>
            <th>ETA</th>
            <th>Notes</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!!sortedJobServices?.length && sortedJobServices.map(jobService => (
            <TableRow
              key={`row-${jobService.id}`}
              rowData={jobService}
              {...{
                calendarDateString, reloadData, surveyorOptions, bySection, readOnly
              }} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
