import React, { useState, useEffect, memo } from "react";
import { useSelector } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { getBaseUrl, getCurrentUser } from "../../reducers";
import { updateTaskOrderInAssignedUserScope } from "../../../../requesters/taskRequester";
import { draggableMoveAnItem, roleCheck } from "../../../../utils";
import DraggableTaskRow from "./DraggableTaskRow";
import { REGISTERED_SURVEYORS_MANAGER } from "../../../../utils/constants";

const TaskTable = ({
  tasks, setTasks, reloadData
}) => {
  const baseUrl = useSelector(getBaseUrl);
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role
  const [tasksByAssignedUser, setTasksByAssignedUser] = useState(tasks)
  const isRegisteredSurveyorsManager = roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)

  const onDragEnd = result => {
    if (!result.destination) return;
    const destinationIdx = result.destination.index
    if (destinationIdx === result.source.index) return;

    const destinationTask = tasksByAssignedUser[destinationIdx]
    updateTaskOrderInAssignedUserScope(baseUrl, result.draggableId, destinationTask.id)
    const rows = draggableMoveAnItem(
      tasksByAssignedUser,
      result.source.index,
      destinationIdx
    );
    setTasksByAssignedUser(rows);
  }

  const MemorizedRows = memo(({ tasks, setTasks, reloadData }) => tasks.map((task, index) => (
    <DraggableTaskRow
      task={task}
      setTasks={setTasks}
      reloadData={reloadData}
      index={index}
      key={task?.id} />
  )));

  const DraggableTaskRows = () => (
    <DragDropContext onDragEnd={result => {
      onDragEnd(result)
    }}>
      <Droppable droppableId="list">
        {provided => (
          <tbody ref={provided.innerRef} {...provided.droppableProps}>
            <MemorizedRows
              tasks={tasksByAssignedUser}
              setTasks={setTasksByAssignedUser}
              reloadData={reloadData} />
            {provided.placeholder}
          </tbody>
        )}
      </Droppable>
    </DragDropContext>
  )

  useEffect(() => {
    setTasksByAssignedUser(tasks)
  }, [tasks])

  return (
    <div className="table-responsive">
      <table className="table table-listing table-hover">
        <thead>
          <tr>
            <th></th>
            <th>Task Type</th>
            <th className="text-center">Status</th>
            <th>Job</th>
            <th>Job Service</th>
            <th>Finished At</th>
            <th>Start At</th>
            { isRegisteredSurveyorsManager && <th>Created by</th>}
            <th></th>
          </tr>
        </thead>
        {!!tasksByAssignedUser.length && DraggableTaskRows()}
      </table>
    </div>
  )
}

export default TaskTable