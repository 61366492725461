import React from "react"
import { Container } from "react-bootstrap"
import { format } from "date-fns"

import RowForSurveyor from "./RowForSurveyor"

const TableForSurveyor = ({ jobServices, requestJobServices, currentUser }) => {
  const date_string = format(new Date(), "dd MMM yyyy, EEEE")
  return (
    <Container fluid className="bg-white py-5 mt-5">
      <h3>{`Today, ${date_string}`}</h3>
      <div className="table-responsive">
        <table className="table table-listing table-hover">
          <thead>
            <tr>
              <th>Time</th>
              <th>Job No.</th>
              <th>Client Name</th>
              <th>Contact</th>
              <th>Job Address</th>
              <th>Survey</th>
              <th className="text-center">Check in/out</th>
              <th className="text-right"></th>
              <th className="text-center">Note</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {jobServices.map(jobService => <RowForSurveyor currentUser={currentUser} jobService={jobService} key={jobService.id} requestJobServices={requestJobServices}/>)}
          </tbody>
        </table>
      </div>
    </Container>
  )
}

export default TableForSurveyor
