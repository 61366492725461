import React from "react";
import { Link } from "react-router-dom"
import { JOB_STATES_DISPLAY } from "../../../../../utils/constants"

const RelatedJobServiceItem = ({ jobService }) => {
  const {
    id,
    attributes: {
      label,
      jobId,
      bookedInForJobServiceId,
      state,
    }
  } = jobService

  const onClickItem = link => {
    window.location.href = link
  }

  return (
    <tr className="border-top border-bottom" key={`related-jobService-${id}`}>
      <td>
        <Link
          to={`/jobs/${jobId}/job_services/${id}`}
          onClick={() => onClickItem(`/jobs/${jobId}/job_services/${id}`)}
          className="link-no-decoration">
          {label}
        </Link>
        { bookedInForJobServiceId && (
          <p className="text-muted mb-0">
            {bookedInForJobServiceId && "Linked Booked In Job"}
          </p>
        )}
      </td>
      <td>{JOB_STATES_DISPLAY[state]?.text}</td>
    </tr>
  )
}

export default RelatedJobServiceItem
