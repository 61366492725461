import customAxios from "./customAxios";
import normalize from "json-api-normalizer"

export const getLocations = (baseUrl, filters) => {
  const path = `${baseUrl}/locations`

  return customAxios.get(path, { params: filters })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}
