import React from "react"

const SummaryReport = ({ summaryRows, totalRow }) => (
  <>
    <div style={{ border: "1px solid" }} className="p-3">
      <h5>Summary Report</h5>
      <div className="table-responsivew-50">
        <table className="table table-listing table-hover table-striped">
          <thead>
            <tr>
              <th>Surveyor</th>
              <th>Jobs Complete</th>
              <th>AVG Duration</th>
              <th className="text-center">AVG Price</th>
              <th className="text-right">Total Target</th>
              <th className="text-right">Total Price</th>
              <th className="text-right">Total Target Percentage</th>
            </tr>
          </thead>
          <tbody>

            {summaryRows && summaryRows.map(row => (
              <tr key={row.surveyorName}>
                <td>{row.surveyorName}</td>
                <td>{row.jobsComplete}</td>
                <td>{row.avgDuration}</td>
                <td className="text-center">{row.avgPrice}</td>
                <td className="text-right">{row.totalTarget}</td>
                <td className="text-right">{row.totalPrice}</td>
                <td className="text-right">{`${row.totalTargetCompletedPercentage}%`}</td>
              </tr>
              ))}

            <tr className="font-weight-bold bg-secondary text-white">
              <td>Total:</td>
              <td>{totalRow.jobsComplete}</td>
              <td>{totalRow.avgDuration}</td>
              <td className="text-center">{totalRow.avgPrice}</td>
              <td className="text-right">{totalRow.totalTarget}</td>
              <td className="text-right">{totalRow.totalPrice}</td>
              <td className="text-right">{`${totalRow.totalTargetCompletedPercentage}%`}</td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  </>
  )

export default SummaryReport