import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { getGearsPerUser } from "requesters/gearRequester"
import { getBaseUrl } from "../../../reducers"
import MyGearTable from "./MyGearTable"
import NavBar from "../shared/NavBar"

const MyGear = () => {
  const baseUrl = useSelector(getBaseUrl)
  const [gears, setGears] = useState(undefined)
  const [gearIds, setGearIds] = useState([])
  const { id: userId } = useSelector(state => state.accountSettingsPage.currentUser)

  const getGearsPerUserHandler = userId => {
    getGearsPerUser(baseUrl, userId)
      .then(res => {
        if (res.status === 200) {
          setGears(_.get(res.response, "gear", {}))
          setGearIds(_.get(res.response, "meta.meta.meta.ids", []))
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getGearsPerUserHandler(userId)
  }, [baseUrl, userId])

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3 col-xl-2">
          <NavBar />
        </div>
        <div className="col-md-8 col-xl-10">
          <h1 className="mb-5">My Assets</h1>
          {gears && (
            <MyGearTable gearIds={gearIds} gears={gears} userId={userId} />
          )}
        </div>
      </div>
    </div>
  )
}

export default MyGear
