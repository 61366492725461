/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState, useCallback } from "react";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  DirectionsRenderer,
  InfoWindow
} from "react-google-maps";
import { omit } from "lodash"

const GoogleMapView = withScriptjs(
  withGoogleMap(props => {
    const {
      jobServices, tempWaypoints, selectedSurveyorId, onFetchingDirectionFailed, onFetchingDirectionSuccess, setLegs, ...otherProps
    } = props;
    const [directions, setDirections] = useState(null);
    const [locationHolder, setLocationHolder] = useState(null);

    const getWaypoints = () => {
      const waypoints = jobServices.map(jobService => ({
        location: {
          lat: Number(jobService.attributes.addressLatitude),
          lng: Number(jobService.attributes.addressLongitude)
        },
        stopover: true
      }))
      const temps = tempWaypoints?.length > 0 ? tempWaypoints.map(wp => omit(wp, ["full_address"])) : []

      return [...waypoints, ...temps]
    }

    useEffect(() => {
      if (selectedSurveyorId) {
        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
          {
            origin: { lat: jobServices[0].attributes.surveyorAddressLatitude, lng: jobServices[0].attributes.surveyorAddressLongitude },
            destination: { lat: jobServices[0].attributes.surveyorAddressLatitude, lng: jobServices[0].attributes.surveyorAddressLongitude },
            waypoints: [
              ...getWaypoints()
            ],
            // optimizeWaypoints: true,
            travelMode: window.google.maps.TravelMode.DRIVING, // Walking mode
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              setDirections(result);
              onFetchingDirectionSuccess()
              setLegs(result.routes[0].legs); // Store the leg information
            } else {
              setDirections(null);
              onFetchingDirectionFailed()
              console.error(`Error fetching directions: ${status}`);
            }
          }
        );
      } else {
        setDirections(null);
        setLegs([])
      }
    }, [setLegs, jobServices, tempWaypoints]);

    const renderMarker = useCallback(() => {
      return jobServices && jobServices.length > 0 && jobServices.map(jobService => {
        const {
          attributes: {
            addressLongitude, addressLatitude, jobNo, label, defaultJobTitle
          }
        } = jobService
        return (
          <Marker
            key={jobService.id}
            onMouseOver={() => {
              setLocationHolder({
                lat: Number(addressLatitude),
                lng: Number(addressLongitude),
                jobNo,
                defaultJobTitle,
                label,
              })
            }}
            position={{
              lat: Number(addressLatitude),
              lng: Number(addressLongitude)
            }} />
        )
      })
    }, [jobServices])

    const renderCustomMarkers = useCallback(() => {
      if (!directions) return null;
      const { legs } = directions.routes[0];
      const { waypoint_order: waypointOrder } = directions.routes[0];
      return legs.map((leg, index) => {
        const lat = leg.start_location.lat();
        const lng = leg.start_location.lng();
        const jobService = index === 0 ? {} : jobServices[waypointOrder[index - 1]];
        const jobNo = index === 0 ? "The surveyor's home" : jobService?.attributes?.jobNo;
        const label = index === 0 ? "" : jobService?.attributes?.label;
        const defaultJobTitle = index === 0 ? leg.start_address : jobService?.attributes?.defaultJobTitle;
        return (
          <Marker
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            onMouseOver={() => {
              setLocationHolder({
                lat,
                lng,
                jobNo,
                defaultJobTitle,
                label
              })
            }}
            label={index === 0 ? "H" : `${index}`}
            position={leg.start_location} />
        )
      });
    }, [directions]);

    return (
      <div>
        <GoogleMap
          {...otherProps}
          options={{
            styles: [
              {
                featureType: "poi",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "transit.station.bus",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "transit.station.rail",
                stylers: [{ visibility: "off" }],
              },
            ],
          }}>
          {!directions ? renderMarker() : <DirectionsRenderer directions={directions} options={{ suppressMarkers: true }} />}
          {/* Custom Markers */}
          {directions && renderCustomMarkers()}

          {locationHolder && (
            <InfoWindow
              onDomReady={() => {
                const element = document.getElementsByClassName("gm-style-iw-d")
                const closeButton = document.querySelector('button[title="Close"]');
                if (closeButton) {
                  closeButton.remove();
                }
                if (element[0]) {
                  element[0].style.overflow = "hidden"
                  element[0].style["padding-bottom"] = "12px"
                }
              }}
              options={{
                maxWidth: 300,
                pixelOffset: new window.google.maps.Size(0, -35)
              }}
              position={{ lat: locationHolder.lat, lng: locationHolder.lng }}>
              <div
                style={{
                  position: "relative"
                }}>
                <div
                  onMouseOut={() => {
                    setLocationHolder(null)
                  }}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "calc(100% + 24px)",
                    top: "-12px",
                    left: "-12px"
                  }}>
                </div>
                <h6>{locationHolder.jobNo}</h6>
                <p>{locationHolder.label}</p>
                <p>{locationHolder.defaultJobTitle}</p>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </div>
    );
  })
);

export default GoogleMapView;
