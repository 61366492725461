import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Modal, Spinner } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert"
import { multipleUploadFile } from "requesters/jobServiceRequester";
import Lightbox from "react-awesome-lightbox";
import { getBaseUrl, getCurrentUser } from "../../../reducers";
import FilePreview from "../tabs/FilePreview"
import Dropzone from "../../../../../components/utils/Dropzone"
import "react-awesome-lightbox/build/style.css";
import { MAX_SIZE_UPLOADED_FILE, MAX_SIZE_UPLOADED_FILE_TEXT } from "../../../../../utils/constants";

const UploadPhotosModal = ({
  show, onHide, jobService, reloadData, setUploadChecked, assignment
}) => {
  const baseUrl = useSelector(getBaseUrl)
  const currentUser = useSelector(getCurrentUser)
  const { id } = jobService

  const [uploadedPhoto, setUploadedPhoto] = useState([])
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [showError, setShowError] = useState(false)
  const [largeUploads, setLargeUploads] = useState([])
  const [showPopupGallery, setShowPopupGallery] = useState(false)
  const [galleryStartIndex, setGalleryStartIndex] = useState(0)
  const [filesCollectionError, setFilesCollectionError] = useState(false)

  const [uploading, setUploading] = useState({
    photos: false
  })

  const images = assignment?.checkInPhotos.map(photo => ({
    url: photo.data.attributes.filePath,
    title: photo.data.attributes.fileName
  }));

  const handlePreview = e => {
    setLargeUploads([])
    setUploadedPhoto([])
    const files = e.value
    const largeFiles = files?.filter(file => file.size > MAX_SIZE_UPLOADED_FILE)
    const filesCollectionSize = files?.map(file => file.size).reduce((result, size) => result + size)

    if (largeFiles?.length) {
      setLargeUploads(largeFiles)
      setUploadedPhoto([])
      setShowError(true)
    } else if (filesCollectionSize > MAX_SIZE_UPLOADED_FILE) {
      setUploadedPhoto([])
      setFilesCollectionError(true)
    } else {
      const fileWithUrl = files.map(file => ({ ...file, src: URL.createObjectURL(file) }))
      setUploadedFiles(fileWithUrl)
      setUploadedPhoto(files)
    }
  }

  const multiplePhotsUpload = () => {
    const formData = new FormData();
    uploadedPhoto.forEach((file, index) => {
        formData.append(`file-${index}`, file);
        formData.append("document_category", "Photo")
        formData.append("created_by_user_id", currentUser.id)
        formData.append("surveyor_assignment_id", assignment.surveyorAssignmentId)
      });
      setUploading({ ...uploading, photos: true })
      multipleUploadFile(baseUrl, id, formData)
      .then(res => {
        if (res.status === 201) {
          setUploadedPhoto([])
          reloadData()
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
      .finally(() => {
        setUploading({ ...uploading, photos: false });
      });
  }

  useEffect(() => {
      setUploadedFiles([])
      setUploadedPhoto([])
      setLargeUploads([])
      setUploading({
        photos: false
      })
  }, [show])

  const onSubmit = () => {
    setUploadChecked(true)
    onHide()
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <h2 className="modal-title">UPLOAD PHOTOS</h2>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <SweetAlert
          show={showError}
          type="error"
          title="File size exceeded!"
          style={{ width: "600px" }}
          closeOnClickOutside
          allowEscape={false}
          onConfirm={() => setShowError(false)}>
          Max size is {MAX_SIZE_UPLOADED_FILE_TEXT}
        </SweetAlert>
        <SweetAlert
          show={filesCollectionError}
          type="error"
          title="File sizes exceeded!"
          style={{ width: "600px" }}
          closeOnClickOutside
          allowEscape={false}
          onConfirm={() => setFilesCollectionError(false)}>
          Max size for a collection of photos is {MAX_SIZE_UPLOADED_FILE_TEXT}
        </SweetAlert>

        <div className="mt-2">
          <div className="form-group mt-4">
            <Dropzone
              wrapperClass="drag-drop"
              multiple
              name="photo"
              fileTypes={{
                "image/jpeg": [".jpeg"], "image/jpg": [".jpg"], "image/png": [".png"], "image/gif": [".gif"]
              }}
              fileTypeNames=".jpeg .png .jpg .gif"
              setUploadedFiles={setUploadedPhoto}
              handleFileUpload={handlePreview} />
          </div>

          <div className="row mb-4  ml-1">
            {!!uploadedPhoto?.length && uploadedFiles.map(file => (
              <div className="mr-3 mt-2" key={file.src}>
                <img
                  src={file.src}
                  width="80"
                  height="80"
                  className="img-preview" />
              </div>
              ))}
          </div>

          <button
            disabled={uploadedPhoto?.length === 0 || uploading.photos || largeUploads?.length}
            type="button"
            onClick={multiplePhotsUpload}
            className="btn btn-primary">
            {uploading.photos ? (
              <Spinner
                animation="border" />
              ) : (`Save Photos`)
            }
          </button>

          <hr />
          <p>{`${assignment?.checkInPhotos?.length ? "Uploaded Photos" : "No Photo uploaded."}`}</p>
          <div className="row ml-0 mt-1">
            {!!assignment?.checkInPhotos?.length && assignment?.checkInPhotos.map(
              (file, index) => (
                <FilePreview
                  canDelete={currentUser.attributes.name === file?.data?.attributes?.createdByUser}
                  file={file}
                  isGalleryButton
                  popupGallery={() => { setShowPopupGallery(true); setGalleryStartIndex(index) }}
                  key={file.data.id}
                  reloadData={reloadData} />
              )
            )}
            {showPopupGallery && <Lightbox startIndex={galleryStartIndex} images={images} onClose={() => setShowPopupGallery(false)} />}
          </div>

        </div>
        <hr />
        <div className="modal-footer d-flex justify-content-end px-0 py-4">
          <button
            className="btn btn-link btn-lg"
            onClick={onHide}
            type="submit">
            Close
          </button>
          <button
            className="btn btn-red btn-lg"
            disabled={jobService?.attributes?.uploadedPhotos?.length === 0}
            onClick={onSubmit}
            type="submit">
            Done
          </button>
        </div>
      </div>
    </Modal>
  )
  }
export default UploadPhotosModal
