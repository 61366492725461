import React from "react"
import { Link } from "react-router-dom"
import Loader from "components/Loader"
import GearHistoryRecordsTable from "./GearHistoryRecordsTable";

export default function GearHistoryRecords({
readOnly, gearHistoryRecords, requesting, gearId
}) {
    return (
      <div className="card border-0 mt-3">
        <div className="card-body p-4">
          <div className="d-flex justify-content-between align-items-center">
            <h3>History</h3>
          </div>
          { !readOnly && (
            <Link
              className="btn btn-outlined-blue btn-sm"
              to={`/settings/gears/${gearId}/gear-history-record/new`}
              data-target="#newHistoryModal"
              data-toggle="modal"
              type="button">
              + New History
            </Link>
          )}
          {requesting ? <Loader /> : <GearHistoryRecordsTable gear_history_records={gearHistoryRecords} />}
        </div>
      </div>
    )
}
