import React, { useState, useEffect, useCallback } from "react";
import {
  Spinner,
  Form
} from "react-bootstrap"
import Loader from "components/Loader"
import { nearbyJob } from "requesters/jobServiceRequester"
import { useSelector } from "react-redux"
import { useQueryParam, BooleanParam } from "use-query-params";
import { roleCheck } from "utils/index";
import _ from "lodash";
import GoogleMapView from "./nearbyJobs/GoogleMapView";
import TableNearbyJobs from "./nearbyJobs/TableNearbyJobs";
import { getBaseUrl, getCurrentUser, getCurrentRole } from "../../../reducers"
import { OPERATIONS } from "../../../../../utils/constants";

const NearbyJobs = ({ jobService }) => {
  const PER_PAGE = 15
  const {
    id,
    attributes: { addressLatitude, addressLongitude }
  } = jobService
  const baseUrl = useSelector(getBaseUrl);
  const currentRole = useSelector(getCurrentRole)
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const [loadingLoadMore, setLoadingLoadMore] = useState(false)
  const [loading, setLoading] = useState(true)
  const [nearbyJobs, setNearbyJobs] = useState([])
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [page, setPage] = useState(1)
  const [showFutureJobsOnly, setShowFutureJobsOnly] = useQueryParam("future_jobs_only", BooleanParam)

  const isAdminOrOperations = roleCheck(systemRole, currentRole, OPERATIONS)

  const loadNearbyJobs = useCallback((isFutureJobsOptionChanged = false) => {
    const payload = {
      page,
      per_page: PER_PAGE,
      future_jobs_only: isAdminOrOperations && showFutureJobsOnly
    }
    nearbyJob(baseUrl, id, payload)
      .then(res => {
        const { response, status } = res
        if (status === 200) {
          const nearbyJobsSorted = _.sortBy(response, "attributes.distance");
          const newNearbyJobs = isFutureJobsOptionChanged ? nearbyJobsSorted : [...nearbyJobs, ...nearbyJobsSorted]
          setNearbyJobs(newNearbyJobs)
          setLoadingLoadMore(false)
          if (response.length < PER_PAGE) {
            setCanLoadMore(false)
          } else {
            setCanLoadMore(true)
          }
        }
        setLoading(false)
      })
      .catch(() => {
        setLoadingLoadMore(false)
        setLoading(false)
        setCanLoadMore(true)
      })
  }, [page, showFutureJobsOnly])

  const handleLoadMore = () => {
    setLoadingLoadMore(true)
    setPage(currentPage => currentPage + 1)
  }

  useEffect(() => {
    if (addressLatitude && addressLongitude) {
      loadNearbyJobs()
    } else {
      setLoading(false)
    }
  }, [addressLatitude, addressLongitude, page])

  useEffect(() => {
    if (!isAdminOrOperations) {
      setShowFutureJobsOnly(false) // Make sure only admin/operations can see the view
    }
    if (addressLatitude && addressLongitude) {
      loadNearbyJobs(true)
    }
  }, [showFutureJobsOnly])

  const clearState = () => {
    setShowFutureJobsOnly(undefined)
    setNearbyJobs([])
  }

  useEffect(() => () => clearState(), [])

  return addressLatitude && addressLongitude ? (
    <>
      {!loading ? (
        <div className="timeline-item mb-5">
          <GoogleMapView
            defaultCenter={{ lat: Number(addressLatitude), lng: Number(addressLongitude) }}
            defaultZoom={17}
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCjB3Ue5bHZwF8QkwobkSYCUAJ35pIZCXk&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: "100%" }} />}
            containerElement={<div style={{ height: "500px" }} />}
            mapElement={<div style={{ height: "100%" }} />}
            nearbyJobs={nearbyJobs} />

          <p className="card-title mt-2 d-flex justify-content-between px-2">
            { isAdminOrOperations && (
              <span>
                <Form.Check
                  className="custom-switch sw-primary"
                  name="future_jobs_only"
                  id="future-jobs-only-switch"
                  type="switch"
                  checked={showFutureJobsOnly}
                  onChange={() => setShowFutureJobsOnly(!showFutureJobsOnly)}
                  label="Show Future Jobs Only" />
              </span>
            )}
            { showFutureJobsOnly ? <span>Nearby Future Jobs (within 2kms)</span> : <span>Nearby Jobs (within 200m)</span> }
          </p>

          <TableNearbyJobs
            showFutureJobsOnly={isAdminOrOperations && showFutureJobsOnly}
            nearbyJobs={nearbyJobs} />

          { canLoadMore && (
            <div className="d-flex justify-content-center">
              <button onClick={handleLoadMore} type="button" className="btn btn-outlined-blue">
                load more
                {" "}
                {loadingLoadMore && <Spinner className="mr-2" animation="border" role="status" size="sm" />}
              </button>
            </div>
          )}
        </div>
      ) : <Loader />}
    </>
  ) : (
    <div className="timeline-item mb-5">
      <div className="card border-0 bg-coral">
        <div className="card-body">
          Longitude and latitude location data do not exist !
        </div>
      </div>
    </div>
  )
}

export default NearbyJobs
