import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { getGearsPerUser } from "requesters/gearRequester"
import { getBaseUrl } from "../../../reducers"
import GearReturnModal from "../Gear/GearReturnModal"


export default function UserGearsCard({ user }) {
  const baseUrl = useSelector(getBaseUrl)
  const { id } = user
  const [gears, setGears] = useState([])
  const [showReturnModal, setShowReturnModal] = useState(false)
  // const [mounted, setMounted] = useState(false)

  const getGearsPerUserHandler = userId => {
    getGearsPerUser(baseUrl, userId)
    .then(res => {
      // if (mounted && res.status === 200) {
      if (res.status === 200) {
        const gearsObj = _.get(res.response, "gear", {})
        setGears(Object.values(gearsObj))
        // setMounted(false)
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }

  useEffect(() => {
    // setMounted(true)
    getGearsPerUserHandler(id)
  }, [baseUrl, user])

  return (
    <div className="card border-0 mt-4 shadow-sm">
      <div className="card-body p-4">
        <h3>{`Company assets (${gears.length})`}</h3>
        <div className="table-responsive">
          <table className="table table-listing table-hover">
            {!!gears.length && (
              <thead>
                <tr>
                  <th></th>
                  <th>ID</th>
                  <th>Item Description</th>
                </tr>
              </thead>
            )}

            <tbody>
              {!!gears.length && gears.map(gear => (
                <tr key={gear.id}>
                  <td className="">
                    <button className="btn btn-link" type="button" onClick={() => setShowReturnModal(true)}>Return</button>
                    <GearReturnModal
                      show={showReturnModal}
                      setShow={() => setShowReturnModal(false)}
                      gearId={gear.id}
                      gearIdentifier={gear.attributes.gearIdentifier}
                      currentUser={user} />
                  </td>
                  <td>{gear.attributes.gearIdentifier}</td>
                  <td>{gear.attributes.itemDescription}</td>
                </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
)
}