import { combineReducers } from "redux"

import createReducer from "components/utils/createReducer"

import * as actionTypes from "../../actions/types"

const teamsFetchSuccess = (state, { data }) => ({ ...state, ...data.team })

const byId = createReducer({})({
  [actionTypes.RESOURCE_FETCHED_SUCCESS]: teamsFetchSuccess,
})

export const getTeamById = (state, userId) => state.byId[userId]

export default combineReducers({
  byId
})
