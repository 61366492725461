import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { QueryParamProvider } from "use-query-params"

import { ConnectedRouter } from "connected-react-router"
import { ThemeProvider } from "styled-components"
import { mainTheme } from "themes"

import { Service } from "axios-middleware"
import AxiosNotifcationMiddleware from "middlewares/AxiosNotifcationMiddleware"
import AxiosResourceMiddleware from "middlewares/AxiosResourceMiddleware"
import customAxios from "../../requesters/customAxios"


import RootSaga from "./sagas"
import configureStore, { history } from "./store"

import Main from "./components/Main"

import '../../utils/customClipboard'

const startSurveyorApp = rootElem => {
  const store = configureStore()

  store.runSaga(RootSaga)

  // Configure Axios middlewares
  const service = new Service(customAxios)
  service.register([
    new AxiosNotifcationMiddleware(),
    new AxiosResourceMiddleware(store)
  ])

  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <QueryParamProvider history={history}>
          <ThemeProvider theme={mainTheme}>
            <Main />
          </ThemeProvider>
        </QueryParamProvider>
      </ConnectedRouter>
    </Provider>,
    rootElem
  )
}

export default startSurveyorApp
