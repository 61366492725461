import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Container } from "react-bootstrap"
import FilterDropdown from "components/FilterDropdown"
import SearchInput from "components/SearchInput"
import Loader from "components/Loader"
import AnchorButton from "components/AnchorButton"
import { getFlaggedJobs } from "requesters/jobServiceRequester"
import { getFilteredProducts } from "requesters/productRequester"
// import { getSurveyors } from "requesters/userRequester"
import {
  // RECEPTION_MANAGER,
  OPERATION_MANAGER,
  JOB_PACK_INCOMPLETE,
  DATA_OVERDUE,
  GPS_REQUESTED,
  OPERATIONS,
  SURVEYORS,
  RECEPTION,
  DRAFTING,
  DRAFTING_3D,
  SURVEYORS_USER,
  DRAFTING_USER,
  DRAFTING_3D_USER,
  REGISTERED_SURVEYORS,
  REGISTERED_SURVEYORS_USER,
  ACCOUNTS,
  ADDITIONAL_INFO
} from "utils/constants"
import { get, findIndex, isEmpty } from "lodash"
import { roleCheck } from "utils/index"
import { BooleanParam, StringParam, useQueryParam } from "use-query-params"
import { getBaseUrl, getCurrentRole, getCurrentUser } from "../../reducers"
import { FLAGGED_PAGE_DEFAULT_PER_PAGE } from "../../../../utils/constants"
import FlaggedJobTable from "./FlaggedJobTable"

export default function FlaggedJobs() {
  const baseUrl = useSelector(getBaseUrl)
  const currentRole = useSelector(getCurrentRole)
  const currentUser = useSelector(getCurrentUser)
  const {
    attributes: {
      role: systemRole,
      current_team_role: currentTeamRole
    },
    id: currentUserID
  } = currentUser
  const [requesting, setRequesting] = useState(false)
  const [jobServiceList, setJobServiceList] = useState([])
  const [jobTypes, setJobTypes] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(true)
  const [statusFilter, setStatusFilter] = useQueryParam("filter", StringParam)
  const [isFromSurveyor, setIsFromSurveyor] = useQueryParam("is_surveyor", BooleanParam)

  const readOnly = !((/admin/i.test(systemRole) || currentTeamRole.includes(OPERATION_MANAGER)))
  // const receptionTeam = currentTeamRole.includes(RECEPTION_MANAGER)
  const usersAllowed = roleCheck(systemRole, currentRole, OPERATIONS)
  || roleCheck(systemRole, currentRole, SURVEYORS)
  || roleCheck(systemRole, currentRole, RECEPTION)
  || roleCheck(systemRole, currentRole, DRAFTING)
  || roleCheck(systemRole, currentRole, DRAFTING_3D)
  || roleCheck(systemRole, currentRole, REGISTERED_SURVEYORS)
  || roleCheck(systemRole, currentRole, ACCOUNTS)

  if (!usersAllowed) window.location = "/"

  const isSurveyor = currentTeamRole.includes(SURVEYORS_USER)
  const isDraftingUser = currentTeamRole.includes(DRAFTING_USER) || currentTeamRole.includes(DRAFTING_3D_USER)
  const isRSUser = currentTeamRole.includes(REGISTERED_SURVEYORS_USER)

  const jobStatusOptions = [
    ["Additional Info", "additional_info"],
    ["Additional Info - Drafting", "additional_info_drafting"],
    ["Additional Info - Operations", "additional_info_operations"],
    ["Additional Info - Registered Surveyor", "additional_info_rs"],
    ["Data Overdue", "data_overdue"],
    ["Job Pack Incomplete", "job_pack_incomplete"],
    ["GPS Requested", "gps_requested"],
    ["On Hold", "on_hold"],
    ["Client Delayed", "client_delayed"],
    ["Cancelled", "cancelled"]
  ]
  const mappingInitialStatusFilterValue = () => {
    const validStatuses = jobStatusOptions.map(option => option[option.length - 1])
    return validStatuses.includes(statusFilter) ? statusFilter : undefined
  }

  const bySurveyorFilter = () => {
    if (isSurveyor) {
      return currentUserID
    }
    return undefined
  }

  const initialFilters = {
    by_status: mappingInitialStatusFilterValue(),
    by_surveyor_flagged: bySurveyorFilter(),
    by_draftman: isDraftingUser ? currentUserID : undefined,
    is_surveyor: isFromSurveyor,
    by_registered_surveyor: (!isFromSurveyor && isRSUser) ? currentUserID : undefined,
    by_job_type: undefined,
    by_keyword: undefined
  }
  const [filters, setFilters] = useState(initialFilters)
  const [isFilteredByDropDown, setIsFilteredByDropDown] = useState(false)

  const byStatusName = get(jobStatusOptions, `${findIndex(jobStatusOptions, option => option[1] === filters.by_status)}.0`)
  const byJobTypeName = get(jobTypes, `${findIndex(jobTypes, type => type[1] === filters.by_job_type)}.0`)

  const clearFilters = () => {
    setFilters({ ...initialFilters, by_status: undefined })
    setStatusFilter(undefined)
    setIsFilteredByDropDown(true)
  }

  const setBySearch = val => {
    setFilters({ ...filters, by_keyword: val })
  }

  useEffect(() => {
    setFilters({ ...filters, by_status: statusFilter })
    setIsFilteredByDropDown(true)
  }, [statusFilter])


  const setByJobType = val => {
    setFilters({ ...filters, by_job_type: val })
    setIsFilteredByDropDown(true)
  }

  const fetchData = () => {
    setRequesting(true)
    getFlaggedJobs(baseUrl, filters, { page: currentPage, per_page: FLAGGED_PAGE_DEFAULT_PER_PAGE })
      .then(res => {
        if (res.status === 200) {
          const jobs = get(res.response, "jobService", {})
          let jobList = Object.values(jobs)
          const additionalInfo = res.response.meta?.additional_info

          if (!isEmpty(additionalInfo)) {
            if (additionalInfo?.meta?.ids) {
              jobList = additionalInfo.meta.ids.map(id => jobs[id])
            }
            const hasNextPageValue = additionalInfo?.meta?.hasNextPage || false
            setHasNextPage(hasNextPageValue)
          } else {
            setHasNextPage(false)
          }

          const newJobList = currentPage > 1 ? [...jobServiceList, ...jobList] : jobList
          setJobServiceList(newJobList)
          setRequesting(false)
        }
      })
      // eslint-disable-next-line no-console
      .catch(() => {
        setRequesting(false)
      })
  }

  useEffect(() => {
    if (!isFilteredByDropDown) return
    setCurrentPage(1)
    fetchData()
    setIsFilteredByDropDown(false)
  }, [isFilteredByDropDown, currentRole])

  const handleSubmit = e => {
    setRequesting(true)
    e.preventDefault()
    setCurrentPage(1)
    fetchData()
  }

  const loadMore = () => {
    setCurrentPage(currentPage + 1);
  }

  useEffect(() => {
    fetchData();
  }, [currentPage])

  useEffect(() => {
    getFilteredProducts(baseUrl, "flagged")
      .then(res => {
        if (res.status === 200) {
          const products = Object.values(get(res.response, "product", {}))
          const allJobTypes = products.map(js => ([js.attributes.name, js.id]))
          setJobTypes(allJobTypes)
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }, [])

  return (
    <Container fluid className="">
      <div className="container-inner">
        <div className="d-flex justify-content-between align-items-center header-tabs-container">
          <div className="header-tabs">
            <h1>Flagged Jobs</h1>
          </div>
        </div>
        <div className="table-listing-filters d-md-flex justify-content-between">
          <div>
            <div className="btn-group mr-2">
              <SearchInput value={filters.by_keyword} setValue={setBySearch} handleSubmit={handleSubmit} disableSubmit={requesting} />
            </div>
            <div className="btn-group mr-2">
              <FilterDropdown title="Status" filteredName={byStatusName} setFilter={val => setStatusFilter(val)} options={jobStatusOptions} />
            </div>
            <div className="btn-group mr-2">
              <FilterDropdown title="Job Service" filteredName={byJobTypeName} setFilter={setByJobType} options={jobTypes} />
            </div>
            <div className="btn-group">
              <AnchorButton
                className="f-small"
                onClick={clearFilters}>
                Clear Filters
              </AnchorButton>
            </div>
          </div>
        </div>
        <FlaggedJobTable jobServiceList={jobServiceList} fetchData={fetchData} readOnly={readOnly} />
        <div className="d-flex justify-content-center align-items-center m-4">
          {!hasNextPage && !jobServiceList.length && (<p> No Flagged Jobs </p>)}
          {hasNextPage && <button type="button" onClick={loadMore} className="btn btn-lg btn-primary" disabled={requesting}>{requesting ? "Loading..." : "Load More"}</button>}
        </div>
      </div>
    </Container>
  )
}
