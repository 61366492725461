import React, { useState } from "react";
import { useSelector } from "react-redux";

import { getBaseUrl } from "apps/App/reducers";
import SweetAlert from "react-bootstrap-sweetalert";
import { updateJobTransition } from "requesters/jobRequester";
import cx from "classnames";
import { JOB_STATES_DISPLAY } from "utils/constants"

export default function StatusChangeDropdown({
  job, reloadData, updatableStates, updatableOptions
}) {
  const [showMessage, setShowMessage] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const baseUrl = useSelector(getBaseUrl);

  const {
    jobId,
    id,
    state,
  } = job.attributes;

  const currentState = state || "pending";
  const isUpdatable = updatableStates.includes(state);

  function updateJobState(transition) {
    async function fetchData() {
      const { response, error } = await updateJobTransition(baseUrl, jobId, transition);
      if (response?.status === "ok") {
        reloadData();
      }
      if (error) {
        setAlertTitle("Oops. Something went wrong");
        setShowMessage(true);
      }
    }
    fetchData();
  }

  const hideAlert = () => {
    setShowMessage(false);
  };

  return (
    <td className={cx(`dropdown text-center ${JOB_STATES_DISPLAY[currentState]?.bgClass} keep`)}>
      {isUpdatable ? (
        <>
          <button aria-expanded="false" aria-haspopup="true" className="btn dropdown-toggle" data-toggle="dropdown" id={`status-update-${id}`} type="button">
            {JOB_STATES_DISPLAY[currentState]?.text}
          </button>
          <div aria-labelledby={`status-update-${jobId}`} className="dropdown-menu shadow-sm">
            <p className="small mb-2">Update Status:</p>
            {updatableOptions.map(status => (
              <button
                type="button"
                key={updatableOptions.indexOf(status) + 1}
                className={`btn ${JOB_STATES_DISPLAY[status]?.btnClass} mr-2`}
                onClick={() => updateJobState(JOB_STATES_DISPLAY[status]?.api)}>
                {JOB_STATES_DISPLAY[status]?.text}
              </button>
              ))}
          </div>
        </>
      ) : (
        <button
          className="btn dropdown-toggle"
          disabled
          type="button">
          {JOB_STATES_DISPLAY[currentState]?.text}
        </button>
      )}
      <SweetAlert
        show={showMessage}
        type="error"
        title={alertTitle}
        closeOnClickOutside
        allowEscape={false}
        onConfirm={hideAlert} />
    </td>
  );
}
