import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { roleCheck } from "utils/index";

import { isPast } from "date-fns";
import moment from "moment";
import { toast } from "react-toastify";
import { updateSurveyorAssignmentDate } from "requesters/jobServiceRequester";
import { deleteScheduledAssignmentGroup } from "requesters/surveyorAssignmentRequester";
import { getBaseUrl } from "../../../../reducers";

const ShowChangeBookingDate = ({
  jobService, reloadData, systemRole, currentTeamRole, scheduledAssignment
}) => {
  const {
 attributes: {
 id, bookingTime, bookingDates, canDeleteScheduledAssignment
}
} = jobService
  const {
    scheduledAt
  } = scheduledAssignment

  const [prefBookingDate, setPrefBookingDate] = useState(scheduledAt ? new Date(scheduledAt) : null)

  const baseUrl = useSelector(getBaseUrl)
  const assignmentId = scheduledAssignment.primarySurveyorAssignmentId;
  const [showUpdate, setShowUpdate] = useState(false)
  const [showMoveForwardConfirmation, setShowMoveForwardConfirmation] = useState(false)
  const [allowMoveForward, setAllowMoveForward] = useState(false)

  const [showMessage, setShowMessage] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [alertTitle, setAlertTitle] = useState("")
  const [alertText, setAlertText] = useState("")

  const resetError = () => {
    setShowMessage(false)
    setAlertTitle("")
    setAlertText("")
  }

  const toastOptions = {
    toastId: "container-inner",
    containerId: "main"
  }

  const deleteAssignmentHandler = () => {
    setShowDeleteConfirmation(true);
  }

  const deleteAssignment = () => {
    deleteScheduledAssignmentGroup(baseUrl, assignmentId)
      .then(res => {
        if (res.status === 200) {
          reloadData();
          setShowDeleteConfirmation(false)
        }
      })
      .catch(err => console.log(err));
  }

  const editBookingTimeHandler = () => {
    if (!isPast(prefBookingDate)) {
      const isNewBookingDateExisted = bookingDates.some(date => moment(date).isSame(prefBookingDate, "day"))

      if (isNewBookingDateExisted && !allowMoveForward) {
        setShowMoveForwardConfirmation(true)
      } else {
        updateSurveyorAssignmentDate(baseUrl, id, { booking_time: scheduledAt, new_booking_time: prefBookingDate })
          .then(() => {
            setShowUpdate(false)
            setAllowMoveForward(false)
            reloadData()
          })
          .catch(err => {
            toast.error(
              <div>
                <span role="img" aria-label="failure-icon">❌</span>
                <span className="ml-2">{err?.response?.data?.errors[0]?.detail}</span>
              </div>,
              toastOptions
            )
          })
      }
    } else if (isPast(prefBookingDate)) {
        setShowMessage(true)
        setAlertTitle("Please choose an available timeslot!")
        setAlertText("You can only choose a timeslot in the future!")
        setPrefBookingDate(new Date(bookingTime))
      }
  }

  useEffect(() => {
    if (allowMoveForward) {
      setShowMoveForwardConfirmation(false)
      editBookingTimeHandler()
    }
  }, [allowMoveForward])

  // const sentJobServiceAccessEmail = () => {
  //   sendAccessEmail(baseUrl, id)
  //   .then(res => {
  //     if (res.status === 200) {
  //       // reloadData()
  //       toast.success(
  //         <div>
  //           <span role="img" aria-label="success-icon">✅</span>
  //           <span className="ml-2">Email Sent!</span>
  //         </div>,
  //           toastOptions
  //       )
  //     }
  //   })
  // .catch(err => {
  //   toast.error(
  //     <div>
  //       <span role="img" aria-label="failure-icon">❌</span>
  //       <span className="ml-2">{err?.response?.data?.errors[0]?.detail}</span>
  //     </div>,
  //       toastOptions
  //   )
  // })
  // }

  return (
    <>
      <div className="row mb-4">
        <DatePicker
          required
          selected={prefBookingDate}
          readOnly={!roleCheck(systemRole, currentTeamRole, "operations_manager")}
          onChange={e => {
            setPrefBookingDate(e);
            setShowUpdate(true);
          }}
          showTimeSelect
          closeOnScroll={e => e.target === document}
          className="form-control mb-2 mt-2 ml-1"
          dateFormat="dd MMM yyyy, h:mma" />
        <div className="ml-2 pt-2">
          {showUpdate && (
            <button type="button" className="btn btn-outline-secondary btn-md mr-2" onClick={() => editBookingTimeHandler()}>
              Update
            </button>
          )}
          {/* <button className="btn btn-outline-primary" type="button" onClick={sentJobServiceAccessEmail}>Send Confirmation Email</button> */}
        </div>

        <SweetAlert
          show={showMessage}
          type="error"
          title={alertTitle}
          style={{ width: "600px" }}
          closeOnClickOutside
          allowEscape={false}
          onConfirm={resetError}>
          {alertText}
        </SweetAlert>
        <SweetAlert
          show={showMoveForwardConfirmation}
          type="info"
          title="Are you sure?"
          style={{ width: "600px" }}
          closeOnClickOutside
          allowEscape={false}
          showCancel
          cancelBtnBsStyle="secondary"
          onCancel={() => { setShowMoveForwardConfirmation(false) }}
          onConfirm={() => setAllowMoveForward(true)}>
          The timeslot of that day already existed. Would you like to move forward the booking dates to next available day?
        </SweetAlert>
      </div>

      <div className="row">
        <div className="ml-2">
          {(canDeleteScheduledAssignment && !scheduledAssignment.anySurveyorCheckedIn) && (
            <button type="button" className="btn btn-outline-danger btn-md mr-2" onClick={() => deleteAssignmentHandler()}>
              Delete
            </button>

          )}
        </div>
        <SweetAlert
          show={showDeleteConfirmation}
          type="info"
          title="Are you sure?"
          style={{ width: "600px" }}
          closeOnClickOutside
          allowEscape={false}
          onConfirm={deleteAssignment}>
          This will delete this scheduled group of assignments, are you sure?
        </SweetAlert>

      </div>
    </>
  )
}
export default ShowChangeBookingDate
