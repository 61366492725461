import { combineReducers }  from "redux"

import users, * as fromUsers from "./users"
import gears, * as fromGears from "./gears"
import gearHistoryRecords, * as fromGearHistoryRecords from "./gearHistoryRecords";
import teams, * as fromTeams from "./teams"
import teamUsers, * as fromTeamUsers from "./teamUsers"
import surveyorSkills, * as fromSurveyorSkills from "./surveyorSkills"


// Functions to fetch data
export const getResourceFromType = (state, { type = undefined, id = undefined }) => {
  if (type === undefined) { return null }

  return {
    user: fromUsers.getUserById(state.users, id),
    gear: fromGears.getGearById(state.gears, id),
    team: fromTeams.getTeamById(state.teams, id),
    gear_history_record: fromGearHistoryRecords.getGearHistoryRecordById(state.gearHistoryRecords, id),
    teamUser: fromTeamUsers.getTeamUserById(state.teamUsers, id),
    surveyorSkill: fromSurveyorSkills.getSurveyorSkillById(state.surveyorSkills, id)
  }[type]
}

// This handles building relationships if they were an array or hash
//
export const relationshipsForResource = (state, resource, key) => {
  const data = _.get(resource.relationships, `${key}.data`)

  if (data instanceof Array) {
    // Resolve as array
    return _.compact(_.map(data, item => getResourceFromType(state, item || { type: undefined, id: undefined })))
  }

  // Resolve as hash
  return getResourceFromType(
    state,
    _.get(resource.relationships, `${key}.data`) || { type: undefined, id: undefined }
  )
}

export const getResource = (state, { type, id }) => {
  const resource = getResourceFromType(state, { type, id })

  // Handle returning null if we cannot find what we are looking for
  if (resource === undefined) { return undefined }

  // Build out relationships so we can merge them back into the main object
  const relationships = {}

  _.each(
    _.keys(resource.relationships, {}), key => (
      _.extend(relationships, {
        [key]: relationshipsForResource(state, resource, key)
      })
    )
  )

  return { ...resource, ...{ relationships } }
}

// Users
export const getUserBySlug = (state, slug) => {
  const user = fromUsers.getUserBySlug(state.users, slug)
  if (user === undefined) { return null }
  return getResource(state, { type: user.type, id: user.id })
}


export default combineReducers({
  users,
  gears,
  teams,
  teamUsers,
  gearHistoryRecords,
  surveyorSkills
})