import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Loader from "components/Loader"
import { updateMyDetails } from "requesters/userRequester"
import EditSaveButtons from "components/EditSaveCancelButton"
import * as actionTypes from "../../../actions/types"
import { getBaseUrl, getCurrentUser } from "../../../reducers"
import UserBasicInput from "../UserDetails/UserBasicInput"
import UserUploadFile from "../UserDetails/UserUploadFile"
import UserAddressInput from "../UserDetails/UserAddressInput"
import UpdatePassword from "./UpdatePassword"
import NavBar from "../shared/NavBar"


export default function AccountSettings() {
  const baseUrl = useSelector(getBaseUrl)
  const dispatch = useDispatch()
  const currentUser = useSelector(getCurrentUser)
  const { attributes: userAttributes } = useSelector(state => state.accountSettingsPage.currentUser)
  const {
    firstname, surname, email, phone, fulladdress, emergencyContact, emergencyContactNumber, surveyorId, surveyorSignaturePath, surveyorSignatureFileName
  } = userAttributes

  const { editState, userEditableAttr, postUserState } = useSelector(state => state.accountSettingsPage)

  useEffect(() => {
    dispatch({
      type: actionTypes.CURRENT_USER_SET_EDIT_FIELDS,
      data: {
        firstname, surname, email, phone, fulladdress, emergency_contact: emergencyContact, emergency_contact_number: emergencyContactNumber, surveyor_id: surveyorId
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function postUserUpdates() {
    dispatch({ type: actionTypes.CURRENT_USER_EDIT_SAVE })
    async function postData() {
      dispatch({ type: actionTypes.CURRENT_USER_POST_START })
      const { response, error } = await updateMyDetails(baseUrl, { user: userEditableAttr })
      if (response) dispatch({ type: actionTypes.CURRENT_USER_POST_SUCCESS, data: response })
      if (error) dispatch({ type: actionTypes.CURRENT_USER_POST_FAILURE })
    }
    postData()
  }

  function resetUserEditableAttr() {
    dispatch({
      type: actionTypes.CURRENT_USER_EDIT_CANCEL,
      data: {
        firstname, surname, email, phone, fulladdress, emergency_contact: emergencyContact, emergency_contact_number: emergencyContactNumber, surveyor_id: surveyorId
      }
    })
  }

  function updateAttr(data) {
    dispatch({ type: actionTypes.CURRENT_USER_EDIT_UPDATE, data })
  }


  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3 col-xl-2">
          <NavBar />
        </div>
        <div className="col-md-8 col-xl-10">
          <h1>Account Settings</h1>
          {postUserState && (
            <div className="loader-overlay">
              <Loader />
            </div>
          )}
          <UserBasicInput {...{
            title: "First name",
            editState,
            value: firstname,
            inputValue: userEditableAttr.firstname,
            setInputValue: value => { updateAttr({ firstname: value }) },
            labelWrapperClass: "col-xl-3 col-sm-4",
            inputWrapperClass: "col-sm-6 col-xl-4"
          }} />
          <UserBasicInput {...{
            title: "Surname",
            editState,
            value: surname,
            inputValue: userEditableAttr.surname,
            setInputValue: value => { updateAttr({ surname: value }) },
            labelWrapperClass: "col-xl-3 col-sm-4",
            inputWrapperClass: "col-sm-6 col-xl-4"
          }} />
          <UserBasicInput {...{
            title: "Email",
            hrefPrefix: "mailto",
            editState,
            value: email,
            inputValue: userEditableAttr.email,
            setInputValue: value => { updateAttr({ email: value }) },
            labelWrapperClass: "col-xl-3 col-sm-4",
            inputWrapperClass: "col-sm-6 col-xl-4"
          }} />
          <UserBasicInput {...{
            title: "Phone",
            hrefPrefix: "tel",
            editState,
            value: phone,
            inputValue: userEditableAttr.phone,
            setInputValue: value => { updateAttr({ phone: value }) },
            labelWrapperClass: "col-xl-3 col-sm-4",
            inputWrapperClass: "col-sm-6 col-xl-4"
          }} />

          <UserAddressInput {...{
            editState,
            user_id: currentUser?.id,
            inputWrapperClass: "col-sm-6 col-xl-4",
            labelWrapperClass: "col-xl-3 col-sm-4",
            inputValue: userEditableAttr.fulladdress,
            setInputValue: value => { updateAttr({ fulladdress: value }) },
            title: "Address",
            value: fulladdress || ""
          }} />

          <UserBasicInput {...{
            editState,
            inputValue: userEditableAttr.emergency_contact,
            inputWrapperClass: "col-sm-6 col-xl-4",
            labelWrapperClass: "col-xl-3 col-sm-4",
            setInputValue: value => { updateAttr({ emergency_contact: value }) },
            title: "Emergency contact",
            value: emergencyContact || "",
          }} />

          <UserBasicInput {...{
            hrefPrefix: "tel",
            editState,
            inputValue: userEditableAttr.emergency_contact_number,
            inputWrapperClass: "col-sm-6 col-xl-4",
            labelWrapperClass: "col-xl-3 col-sm-4",
            setInputValue: value => { updateAttr({ emergency_contact_number: value }) },
            title: "Emergency contact number",
            value: emergencyContactNumber || "",
          }} />

          <UserBasicInput {...{
            editState,
            inputValue: userEditableAttr.surveyor_id,
            inputWrapperClass: "col-sm-6 col-xl-4",
            labelWrapperClass: "col-xl-3 col-sm-4",
            setInputValue: value => { updateAttr({ surveyor_id: value }) },
            title: "Registered Surveyor ID",
            value: surveyorId || "",
          }} />

          <UserUploadFile {...{
            editState,
            user_id: currentUser?.id,
            inputWrapperClass: "col-sm-6 col-xl-4",
            labelWrapperClass: "col-xl-3 col-sm-4",
            title: "Surveyor Signature",
            path: surveyorSignaturePath || "",
            fileName: surveyorSignatureFileName || ""
          }} />

          <div className="mt-4">
            <EditSaveButtons
              editState={editState}
              startEdit={() => { dispatch({ type: actionTypes.CURRENT_USER_START_EDITING }) }}
              save={postUserUpdates}
              cancel={resetUserEditableAttr} />
          </div>
          <hr />
          <UpdatePassword />
        </div>
      </div>
    </div>
  )
}
