import { combineReducers } from "redux"

import createReducer from "components/utils/createReducer"

import * as actionTypes from "../../actions/types"

const usersFetchSuccess = (state, { data }) => ({ ...state, ...data.user })

const byId = createReducer({})({
  [actionTypes.RESOURCE_FETCHED_SUCCESS]: usersFetchSuccess,
})

export const getUserById = (state, userId) => state.byId[userId]
export const getUserBySlug = (state, slug) => _.find(state.byId, { attributes: { slug } })

export default combineReducers({
  byId
})
