/* eslint-disable no-console */
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { updateJobService } from "requesters/jobServiceRequester"
import { Modal } from "react-bootstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { getBaseUrl } from "../../../reducers"
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const CriticalNoteJobServiceModal = ({
 show, onHide, jobService, reloadData
}) => {
  const baseUrl = useSelector(getBaseUrl)
  const { id } = jobService

  const [criticalNote, setCriticalNote] = useState(jobService.attributes.criticalNote || "")
  const [showMessage, setShowMessage] = useState(false)
  const [alertType, setAlertType] = useState("error")
  const [alertTitle, setAlertTitle] = useState("")

  const handleSubmit = async () => {
    const { response, error } = await updateJobService(baseUrl, id, { critical_note: criticalNote })
    if (response) {
      reloadData()
      onHide()
    }
    if (error) {
      setAlertTitle("Ooops! Something went wrong")
      setAlertType("error")
      setShowMessage(true)
    }
  }

  const handleNoteChange = (value) => {
    if (value === "<p><br></p>") {
      setCriticalNote('')
    } else {
      setCriticalNote(value)
    }
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <div className="modal-header px-md-5 pt-4 border-0">
        <h2 className="modal-title">ADD CRITICAL NOTE</h2>
      </div>
      <div className="modal-body px-md-5">
        <form>
          <div className="form-group row pb-4">
            <div className="col-12">
              <ReactQuill
                placeholder="Enter Critical Notes..."
                value={criticalNote || ""}
                onChange={(value) => handleNoteChange(value)}
              />
            </div>
          </div>
        </form>
        <div className="modal-footer d-flex justify-content-center px-0 py-4">
          <button
            className="btn btn-red btn-lg"
            onClick={handleSubmit}
            type="submit">
            Save
          </button>
        </div>
      </div>
      <SweetAlert
        show={showMessage}
        type={alertType}
        title={alertTitle}
        closeOnClickOutside
        allowEscape={false}
        onConfirm={() => setShowMessage(false)}
      />
    </Modal>
  )
  }
export default CriticalNoteJobServiceModal
