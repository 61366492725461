import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker"
import { adjustTimeSpent } from "../../requesters/timeSpentRequester";
import { getBaseUrl } from "../../apps/App/reducers";
import { preventNegativeValues } from "../../utils";

const UpdateTimeSpentModal = ({
  show, onHide, reloadData, timeSpent
}) => {
  const {
    id,
    attributes: { trackedAt, trackedHours, notes }
  } = timeSpent
  const baseUrl = useSelector(getBaseUrl)
  const [requesting, setRequesting] = useState(false)
  const [currentTrackedHours, setCurrentTrackedHours] = useState(trackedHours)
  const [currentNotes, setCurrentNotes] = useState(notes)
  const [errorMessages, setErrorMessages] = useState({
    hours: ""
  })

  useEffect(() => {
    setCurrentTrackedHours(trackedHours);
    setCurrentNotes(notes);
  }, [trackedHours, notes]);

  const onClearStates = () => {
    setErrorMessages({ hours: "" })
  }

  const validatesInputs = () => {
    let valid = true;

    if (parseFloat(currentTrackedHours) % 0.25 !== 0) {
      valid = false;
      setErrorMessages({ ...errorMessages, hours: "The value must in 15min increments, e.g 0.25, 0.5" })
    }

    return valid
  }

  const onHoursChange = e => {
    const newHours = (parseFloat(e.target.value) > 24 || parseFloat(e.target.value) < 0) ? currentTrackedHours : e.target.value
    setCurrentTrackedHours(newHours)
  }

  const onUpdate = () => {
    setRequesting(true)
    if (!validatesInputs()) {
      setRequesting(false)
      return;
    }

    adjustTimeSpent(baseUrl, id, { tracked_hours: currentTrackedHours, notes: currentNotes })
    .then(res => {
      if (res.status === 200) {
        reloadData()
        onHide()
        onClearStates()
      }
    })
    // eslint-disable-next-line no-console
    .catch(({ err }) => console.log(err))
    .finally(() => setRequesting(false))
  }

  const canSubmit = !requesting && !!currentTrackedHours && parseFloat(currentTrackedHours) !== 0

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <div>
          <h2 className="modal-title">Adjust Time Spent</h2>
        </div>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          <div className="form-group row">
            <label className="col-3 col-form-label">Date*</label>
            <div className="col-9">
              <DatePicker
                readOnly
                id={`time-spent-${id}`}
                wrapperClassName="date_picker full-width"
                className="form-control"
                selected={new Date(trackedAt)}
                maxDate={new Date()}
                dateFormat="yyyy-MM-dd" />
            </div>
            <span className="col-12 text-danger font-085rem"><i>{errorMessages.date}</i></span>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Hours*</label>
            <div className="col-9">
              <input
                className="form-control"
                inputMode="decimal"
                min={0}
                max={24}
                step={0.25}
                pattern="[0-9]*"
                type="number"
                placeholder="e.g., 0.75"
                onChange={onHoursChange}
                onKeyDown={preventNegativeValues}
                value={currentTrackedHours} />
            </div>
            <span className="col-12 text-danger font-085rem"><i>{errorMessages.hours}</i></span>
          </div>
          <textarea
            className="form-control"
            rows="5"
            placeholder="Enter your notes here eg. What were you working on, any issues that took longer than expected (maximum 500 characters)"
            maxLength={500}
            value={currentNotes}
            onChange={e => setCurrentNotes(e.target.value)} />
        </form>
        <hr />
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-primary btn-lg"
          onClick={onUpdate}
          disabled={!canSubmit}
          type="submit">
          Update
          {requesting && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
        </button>
      </div>
    </Modal>
  )
}

export default UpdateTimeSpentModal
