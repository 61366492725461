import React, { useState } from "react"
import { Link } from "react-router-dom"
import GearIssueModal from "../Gear/GearIssueModal"

const MyGearRecord = ({ gear, userId }) => {
  const {
      id,
      gearIdentifier,
      itemDescription,
      category,
      serviceDate
  } = gear.attributes

  const [showReportIssueModal, setShowReportIssueModal] = useState(false)

  return (
    <tr>
      <td>
        <Link to={`/settings/gears/${id}`} className="link-no-decoration">{gearIdentifier}</Link>
      </td>
      <td>
        <Link to={`/settings/gears/${id}`} className="link-no-decoration">{itemDescription}</Link>
      </td>
      <td>
        <Link to={`/settings/gears/${id}`} className="link-no-decoration">{category}</Link>
      </td>
      <td>
        <Link to={`/settings/gears/${id}`} className="link-no-decoration">{serviceDate}</Link>
      </td>
      <td className="d-flex align-items-center justify-content-end">
        <button className="btn btn-link text-right pr-0" type="button" onClick={() => setShowReportIssueModal(true)}>Report Issue</button>
        <span className="text-muted mx-2">|</span>
        <Link to={`/settings/gears/${id}`}>Detail</Link>
      </td>
      <GearIssueModal
        show={showReportIssueModal}
        setShow={() => setShowReportIssueModal(false)}
        gearId={id}
        gearIdentifier={gearIdentifier}
        userId={userId} />
    </tr>
  )
}

export default MyGearRecord