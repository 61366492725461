import React, { forwardRef, useState } from "react"
import DatePicker, { CalendarContainer } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useSelector } from "react-redux"

import {
  isPast, isEqual
} from "date-fns"
import SweetAlert from "react-bootstrap-sweetalert"
import { toast } from "react-toastify"
import { updateSurveyorAssignmentDate } from "requesters/jobServiceRequester"
import { getBaseUrl } from "../../reducers"

const years = _.range(2000, (new Date()).getFullYear() + 3, 1)
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
]

export default function JobServiceTableBookingTime({
  jobService, reloadData, readOnly, bySection, bookingTime
}) {
  const baseUrl = useSelector(getBaseUrl)
  const {
    attributes: {
      id,
      disabledChangeOnSchedule
    }
  } = jobService

  // need to use sepreate date to save time for calendar
  const [newBookingTime, setNewBookingTime] = useState(bookingTime && new Date(bookingTime))
  const [updatingBookingTime, setUpdatingBookingTime] = useState(false)
  const [showMessage, setShowMessage] = useState(false);
  const [alertType, setAlertType] = useState("error");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertText, setAlertText] = useState("");
  const hideAlert = () => {
    setShowMessage(false);
  };

  // const showDateTime = () => {
  //   if (bySection === "schedules") {
  //     return "h:mm aa"
  //   }

  //   return "MMMM d, yyyy h:mm aa"
  // }

  async function updateBookingTime() {
    if (!bookingTime || isEqual(new Date(bookingTime), newBookingTime)) return

    if (!isPast(newBookingTime)) {
      setUpdatingBookingTime(true)
      updateSurveyorAssignmentDate(baseUrl, id, { booking_time: bookingTime, new_booking_time: newBookingTime })
        .then(() => {
          reloadData()
        })
        .catch(err => {
          setUpdatingBookingTime(false)
          toast.error(
            <div>
              <span role="img" aria-label="failure-icon">❌</span>
              <span className="ml-2">{err?.response?.data?.errors[0]?.detail}</span>
            </div>,
            {
              toastId: "container-inner",
              containerId: "main"
            }
          )
        })
    } else if (isPast(newBookingTime)) {
        setShowMessage(true)
        setAlertTitle("Please choose an available time slot!")
        setAlertText("You can only choose a time slot in the future!")
        setNewBookingTime(new Date(bookingTime))
      }
  }

  const MyContainer = ({ className, children }) => (
    <div className="ca-custom">
      <CalendarContainer className={className}>
        {children}
      </CalendarContainer>
    </div>
  )

  const handleTimeChange = newTime => {
    if (!readOnly) {
      setNewBookingTime(newTime)
    }
  }


  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => {
    if (updatingBookingTime) {
      return <div ref={ref}>Updating</div>
    }
    return (
      <button ref={ref} type="button" className="btn btn-block dropdown-toggle" onClick={onClick}>
        {value}
      </button>
    )
  })

  const CustomDateTimeSelect = ({
    date,
    changeYear,
    changeMonth,
  }) => (
    <div className="custom-calendar-header">
      <select
        value={months[date.getMonth()]}
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))
        }>
        {months.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select
        value={date.getFullYear()}
        onChange={({ target: { value } }) => changeYear(value)}>
        {years.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  )
  // eslint-disable-next-line no-restricted-globals
  return newBookingTime && !isNaN(newBookingTime.getTime()) ? (
    <td className="bg-light text-center">
      <DatePicker
        closeOnScroll
        readOnly={readOnly || disabledChangeOnSchedule}
        showTimeSelect
        popperPlacement="bottom-end"
        calendarContainer={MyContainer}
        selected={new Date(newBookingTime)}
        customInput={<ExampleCustomInput />}
        dateFormat={bySection === "flagged_jobs" ? "dd MMM yyyy, h:mm aa" : "h:mm aa"}
        renderCustomHeader={CustomDateTimeSelect}
        onChange={newTime => { handleTimeChange(newTime) }}
        onCalendarClose={() => updateBookingTime()}
        onClickOutside={() => { setShowMessage(false) }} />
      <SweetAlert
        show={showMessage}
        type={alertType}
        title={alertTitle}
        style={{ width: "600px" }}
        closeOnClickOutside
        allowEscape={false}
        onConfirm={hideAlert}>
        {alertText}

      </SweetAlert>
    </td>
  ) : <td></td>
}
