import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import Loader from "components/Loader"
import { putGear } from "requesters/gearRequester"
import EditSaveButtons from "components/EditSaveCancelButton"
import Select from "react-select"
import moment from "moment"
import { getBaseUrl, getResource } from "../../../reducers"
import * as actionTypes from "../../../actions/types"
import GearBasicInput from "./GearBasicInput"
import GearCalendarSelect from "./GearCalendarSelect"
import GearIssueModal from "../Gear/GearIssueModal"
import AssetInspectionModal from "../Gear/AssetInspectionModal"
import { GEAR_CATEGORIES_REQUIRED_INSPECTION } from "../../../../../utils/constants"

export default function GearDetailsCard({
  currentUser, readOnly, users, onUpdateGear
}) {
  const [showReportIssueModal, setShowReportIssueModal] = useState(false)
  const [showAssetInspectionModal, setShowAssetInspectionModal] = useState(false)
  const baseUrl = useSelector(getBaseUrl)
  const dispatch = useDispatch()

  const { id } = useParams()
  const gear = useSelector(state => getResource(state, { type: "gear", id }))
  const { gearCategories } = useSelector(state => state.options)
  const [gearCategoriesOptions, setGearCategoriesOptions] = useState([])
  const [category, setCategory] = useState("")
  const [gearIdentifierError, setGearIdentifierError] = useState(false)
  const [itemDescriptionError, setItemDescriptionError] = useState(false)
  const [nextServiceError, setNextServiceError] = useState(false)
  const currentDate = new Date()
  const gearAttributes = _.get(gear, "attributes", {})
  const {
    isServiceItem,
  } = gearAttributes

  const { editState, gearEditableAttr, putGearState } = useSelector(
    state => state.manageGearPage
  )

  const nextServiceDate = moment(gearEditableAttr.next_service, "DD/MM/YYYY").toDate()

  const userOptions = []

  Object.keys(users).forEach(key => {
    userOptions.push({
      value: users[key].id,
      label: users[key].attributes.name,
    })
  })

  const gearOptionsHandler = () => {
    const gearCategoriesList = []

    Object.keys(gearCategories).forEach(key => {
      gearCategoriesList.push({
        value: gearCategories[key][1],
        label: gearCategories[key][0]
      })
    })

    setGearCategoriesOptions(gearCategoriesList)
    setCategory(gearCategoriesList.find(option => option.value === gearEditableAttr.category))
  }

  useEffect(() => {
    gearOptionsHandler()
  }, [gearCategories])

  const errorsHandler = () => {
    if (!gearEditableAttr.gear_identifier) {
      setGearIdentifierError(true)
    }
    if (!gearEditableAttr.item_description) {
      setItemDescriptionError(true)
    }
    if (nextServiceDate) {
      setNextServiceError(nextServiceDate < currentDate)
    }
  }

  const putGearUpdates = () => {
    errorsHandler()
    if ((!gearEditableAttr.next_service || nextServiceDate >= currentDate)
      && gearEditableAttr.gear_identifier && gearEditableAttr.item_description) {
      dispatch({ type: actionTypes.MANAGE_GEARS_EDIT_SAVE })
      dispatch({ type: actionTypes.GEAR_PUT_START })
      putGear(baseUrl, id, gearEditableAttr)
        .then(res => {
          if (res.status === 200) {
            dispatch({ type: actionTypes.GEAR_PUT_SUCCESS, data: res.response })
            if (onUpdateGear) {
              onUpdateGear()
            }
          }
        })
        .catch(err => {
          dispatch({ type: actionTypes.GEAR_PUT_FAILURE })
          // eslint-disable-next-line no-console
          console.log(err)
        })
    }
  }

  function resetGearEditableAttr() {
    setCategory(gearCategoriesOptions.find(option => option.value === gearAttributes.category))
    dispatch({ type: actionTypes.MANAGE_GEARS_EDIT_CANCEL, data: gear })
  }

  function updateAttr(data) {
    dispatch({ type: actionTypes.MANAGE_GEARS_EDIT_UPDATE, data })
  }

  return (
    <div className="card border-0">
      {putGearState && (
        <div className="loader-overlay">
          <Loader />
        </div>
      )}
      <div className="card-body p-4">
        <div className="form-group row">
          <label className="col-3">Equipment Type</label>
          <div className="col-9">
            {editState && (
              <Select
                options={gearCategoriesOptions}
                disabled={!editState}
                onChange={event => {
                  setCategory(event)
                  updateAttr({ category: event.value })
                }}
                value={category} />
            )}
            {!editState && category?.label}
          </div>
        </div>
        <GearBasicInput
          {...{
            title: "ID/Serial No*",
            editState,
            type: "text",
            value: gearEditableAttr.gear_identifier,
            inputValue: gearEditableAttr.gear_identifier,
            setInputValue: value => {
              updateAttr({ gear_identifier: value })
              setGearIdentifierError(false)
            },
            errorMessage: gearIdentifierError && ("Please add an Identifier"),
          }} />
        <GearBasicInput
          {...{
            title: "Item Description*",
            editState,
            type: "text",
            value: gearEditableAttr.item_description,
            inputValue: gearEditableAttr.item_description,
            setInputValue: value => {
              updateAttr({ item_description: value })
              setItemDescriptionError(false)
            },
            errorMessage: itemDescriptionError && ("Please add a Description"),
          }} />
        <div className="form-group row">
          <label className="col-3">Assigned to</label>
          <div className="col-9">
            {editState && (
              <Select
                options={userOptions}
                disabled={!editState}
                onChange={event => {
                  updateAttr({ user_name: event.label })
                  updateAttr({ user_id: event.value })
                }}
                value={userOptions.filter(
                  option => option.label === gearEditableAttr.user_name
                )} />
            )}
            {!editState
              && userOptions.length !== 0
              && userOptions !== []
              && userOptions.filter(
                option => option.label === gearEditableAttr.user_name
              )[0]?.label}
          </div>
        </div>

        <div className="row mb-3 align-items-center">
          <div className="col-3">Service Item?</div>
          <div className="col-9">
            <div className="custom-control custom-switch">
              <input
                checked={
                  (editState
                    ? gearEditableAttr.is_service_item
                    : isServiceItem) === "Yes"
                }
                className="custom-control-input"
                id="gear-service-item-switch"
                type="checkbox"
                disabled={!editState}
                onChange={event => {
                  if (event.target.checked) {
                    updateAttr({ is_service_item: "Yes" })
                  } else {
                    updateAttr({ is_service_item: "No" })
                  }
                }} />
              <label
                className="custom-control-label"
                htmlFor="gear-service-item-switch" />
            </div>
          </div>
        </div>
        <GearCalendarSelect
          {...{
            title: "Next Service",
            editState,
            value: gearEditableAttr.next_service,
            inputValue: gearEditableAttr.next_service,
            setInputValue: value => {
              updateAttr({ next_service: value })
              setNextServiceError(false)
            },
            errorMessage: nextServiceError && "The date have to be in the future",
          }} />
        <GearCalendarSelect
          {...{
            title: "Date Purchased",
            editState,
            value: gearEditableAttr.date_purchased,
            inputValue: gearEditableAttr.date_purchased,
            setInputValue: value => {
              updateAttr({ date_purchased: value })
            },
          }} />
        <GearBasicInput
          {...{
            title: "Value",
            editState,
            type: "number",
            value: gearEditableAttr.value,
            inputValue: gearEditableAttr.value,
            setInputValue: value => {
              updateAttr({ value })
            },
          }} />

        <div className="mt-4">
          { GEAR_CATEGORIES_REQUIRED_INSPECTION.includes(gear.attributes.category) && (
            <>
              <button className="btn btn-outlined-blue mr-2" type="button" onClick={() => setShowAssetInspectionModal(true)}>
                + Inspection
              </button>
              <AssetInspectionModal
                show={showAssetInspectionModal}
                setShow={setShowAssetInspectionModal}
                gearId={id}
                gearIdentifier={gearEditableAttr.gear_identifier}
                userId={currentUser.id} />
            </>
          )}
          <button className="btn btn-red mr-2" type="button" onClick={() => setShowReportIssueModal(true)}>
            Report Issue
          </button>
          <GearIssueModal
            show={showReportIssueModal}
            setShow={() => setShowReportIssueModal(false)}
            gearId={id}
            gearIdentifier={gearEditableAttr.gear_identifier}
            userId={currentUser.id} />
          {!readOnly && (
            <EditSaveButtons
              editState={editState}
              startEdit={() => {
                dispatch({ type: actionTypes.MANAGE_GEARS_START_EDITING })
              }}
              save={putGearUpdates}
              cancel={resetGearEditableAttr} />
          )}
        </div>
      </div>
    </div>
  )
}
