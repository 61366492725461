import React, { useCallback, useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import { useSelector } from "react-redux"
import { ACCOUNTS_MANAGER } from "utils/constants"
import { roleCheck } from "utils/index"

import { getJobServicesAccounts, getJobServicesAccountsConstants } from "requesters/jobServiceRequester"

import AnchorButton from "components/AnchorButton"
import FilterDropdown from "components/FilterDropdown"
import SearchInput from "components/SearchInput"
import _ from "lodash"
import debounce from "lodash.debounce"
import { StringParam, useQueryParam } from "use-query-params"
import Loader from "components/Loader"
import AccountsTable from "./AccountsTable"

import { getBaseUrl, getCurrentRole, getCurrentUser } from "../../reducers"

export default function Accounts() {
  const baseUrl = useSelector(getBaseUrl)
  const currentRole = useSelector(getCurrentRole)
  const [requesting, setRequesting] = useState(true)
  const [accounts, setAccounts] = useState([])
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role
  const [statesFilter, setStatesFilter] = useQueryParam("by_invoice_status", StringParam)

  if (!roleCheck(systemRole, currentTeamRole, ACCOUNTS_MANAGER)) window.location = "/"

  const [accountTypes, setAccountTypes] = useState([])
  const [jobStatusOptions, setJobStatusOptions] = useState([])
  const [invoiceTypeOptions, setInvoiceTypeOptions] = useState([])

  const initialFilters = {
    by_invoice_status: undefined,
    by_keyword: undefined,
    by_account_type: undefined,
    by_invoice_type: undefined
  }
  const [filters, setFilters] = useState(initialFilters)
  const [, setIsFilteredByDropDown] = useState(false)
  const byStatusName = _.get(jobStatusOptions, `${_.findIndex(jobStatusOptions, status => status[1] === filters.by_invoice_status)}.0`)
  const byAccountTypeName = _.get(accountTypes, `${_.findIndex(accountTypes, type => type[1] === filters.by_account_type)}.0`)
  const byInvoiceTypeName = _.get(invoiceTypeOptions, `${_.findIndex(invoiceTypeOptions, type => type[1] === filters.by_invoice_type)}.0`)
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true)

  const clearFilters = () => {
    setFilters(initialFilters)
    setStatesFilter(undefined)
    setIsFilteredByDropDown(true)
  }

  const setByStatus = val => {
    setFilters({ ...filters, by_invoice_status: val })
    setStatesFilter(val)
    setIsFilteredByDropDown(true)
  }

  const setByAccountType = val => {
    setFilters({ ...filters, by_account_type: val })
    setIsFilteredByDropDown(true)
  }

  const setByInvoiceType = val => {
    setFilters({ ...filters, by_invoice_type: val })
    setIsFilteredByDropDown(true)
  }

  const setBySearch = val => {
    setFilters({ ...filters, by_keyword: val })
  }

  useEffect(() => {
    getJobServicesAccountsConstants(baseUrl)
      .then(res => {
        setAccountTypes(res.data.accountTypeOptions)
        setJobStatusOptions(res.data.jobStatusOptions)
        setInvoiceTypeOptions(res.data.invoiceTypeOptions)
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }, [])

  // console.log(filters.by_status)

  const nextPageHandler = jobList => {
    if (!jobList || Object.keys(jobList).length < 40) {
      setHasNextPage(false)
    } else {
      setHasNextPage(true)
    }
  }

  useEffect(() => {
    if (statesFilter) {
      setFilters({ ...filters, by_invoice_status: statesFilter })
    }
  }, [statesFilter])

  const fetchData = (pageQuery = 1, filters = {}) => {
    setRequesting(true)
    getJobServicesAccounts(baseUrl, {
      ...filters,
      page: pageQuery,
      per_page: 40,
    }).then(res => {
      if (res.status === 200) {
        const jobs = _.get(res.response, "jobService", {})
        const jobList = Object.keys(accounts).length > 0 && pageQuery > 1 ? [...accounts, ...Object.values(jobs)] : Object.values(jobs)
        setAccounts(jobList)
        setRequesting(false)
        nextPageHandler(res.response?.jobService)
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }

  const debouncedSearchAccounts = useCallback(debounce(fetchData, 500), []);

  const handleSubmit = e => {
    e.preventDefault()
    debouncedSearchAccounts()
  }

  useEffect(() => {
    // fetchData(1)
    debouncedSearchAccounts(1, filters)
    setIsFilteredByDropDown(false)
  }, [filters, currentRole])

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchData(nextPage, filters);
  }

  return (
    <Container fluid className="">
      <div className="container-inner">
        <div className="d-flex justify-content-between align-items-center header-tabs-container">
          <div className="header-tabs">
            <h1>ACCOUNTS</h1>
          </div>
          {/* <div className="d-flex align-items-center">
            <button
              className="btn btn-outlined-blue"
              type="button">
              + New Docket
            </button>
          </div> */}
        </div>
        <div className="table-listing-filters d-md-flex justify-content-between">
          <div>
            <div className="btn-group mr-2">
              <SearchInput value={filters.by_keyword} disableSubmit setValue={setBySearch} handleSubmit={handleSubmit} />
            </div>
            <div className="btn-group mr-2">
              <FilterDropdown
                title="Status"
                filteredName={byStatusName}
                setFilter={setByStatus}
                options={jobStatusOptions} />
            </div>
            <div className="btn-group mr-2">
              <FilterDropdown
                title="Invoice Type"
                filteredName={byInvoiceTypeName}
                setFilter={setByInvoiceType}
                options={invoiceTypeOptions} />
            </div>
            <div className="btn-group mr-2">
              <FilterDropdown
                title="Account Type"
                filteredName={byAccountTypeName}
                setFilter={setByAccountType}
                options={accountTypes} />
            </div>
            <div className="btn-group">
              <AnchorButton
                className="f-small"
                onClick={clearFilters}>
                Clear Filters
              </AnchorButton>
            </div>
          </div>
        </div>
        {requesting ? <Loader /> : <AccountsTable jobs={accounts} fetchData={fetchData} />}
        <div className="d-flex justify-content-center align-items-center m-4">
          {hasNextPage && <button type="button" onClick={loadMore} className="btn btn-lg btn-primary" disabled={requesting}>{requesting ? "Loading..." : "Load More"}</button>}
        </div>
      </div>
    </Container>
  )
}
