import React, { useState } from "react"
import { useSelector } from "react-redux"
import { 
  notepadIcon, 
  notepadIconDark, 
  notepadIconRed, 
  operationsNotepadIconDark,
  clientNoteIconDark,
  clientNoteIconRed,
} from "components/Icons"
import {
  RECEPTION_MANAGER,
  OPERATION_MANAGER,
  ACCOUNTS_MANAGER,
  stringEllipsis
} from "utils/constants"
import { roleCheck } from "utils/index"
import EditNoteModalSchedules  from "./EditNoteModalSchedules"
import { getCurrentUser } from "../../reducers"


export default function JobServiceSchedulesTableNote({ rowData, reloadData, readOnly }) {
  const [showNotesModal, setShowNotesModal] = useState(false)
  const [showOperationsNotesModal, setShowOperationsNotesModal] = useState(false)
  const [showClientNotesModal, setShowClientNotesModal] = useState(false)

  const {
    attributes: {
                job_service: {
                              data: {
                                      attributes: {
                                                    job_notes: jobNotes,
                                                    operations_notes: operationsNotes,
                                                    client_notes: clientNotes,
                                                  }
                                    }
                              }
              }
    } = rowData

  const currentUser = useSelector(getCurrentUser)
  const currentTeamRole = currentUser.attributes.current_team_role
  const systemRole = currentUser.attributes.role
  const receptionTeam = currentTeamRole.includes(RECEPTION_MANAGER)
  const operationTeam = currentTeamRole.includes(OPERATION_MANAGER)

  return (
    <td className="keep">
      <div className="dropdown">
        <img
          src={jobNotes ? notepadIconDark : notepadIcon}
          className="cursor-pointer"
          data-toggle="dropdown"
          id="dropdownMenuLink" />
        <div className="dropdown-menu dropdown-menu-right p-3 note-dropdown">
          {!receptionTeam && !readOnly && (
            <div className="text-right">
              <button
                type="button"
                className="btn btn-link p-0"
                onClick={() => { setShowNotesModal(true) }}>
                {`${jobNotes ? "Edit" : "Add Notes"}`}
              </button>
            </div>
          )}
          <div className="mb-0">
            <div dangerouslySetInnerHTML={ { __html: stringEllipsis(jobNotes, 60, 60) || "" } }></div>
            {jobNotes?.length > 60 && (
              <button
                type="button"
                onClick={() => { setShowNotesModal(true) }}
                className="btn btn-link p-0 ml-2">
                More
              </button>
            )}
          </div>

        </div>
        <EditNoteModalSchedules
          readOnly={receptionTeam || readOnly}
          show={showNotesModal}
          noteType="jobNotes"
          title={jobNotes ? "Edit Notes" : "Add Notes"}
          onHide={() => { setShowNotesModal(false) }}
          jobService={rowData}
          currentUser={currentUser}
          reloadData={reloadData} />
      </div>
      {(roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) || roleCheck(systemRole, currentTeamRole, ACCOUNTS_MANAGER)) && (
        <div className="dropdown">
          <img
            src={operationsNotes ? notepadIconRed : operationsNotepadIconDark}
            className="cursor-pointer"
            data-toggle="dropdown"
            id="dropdownMenuLink" />
          <div className="dropdown-menu dropdown-menu-right p-3 note-dropdown">
            <div className="text-right">
              <button
                type="button"
                className="btn btn-link p-0"
                onClick={() => { setShowOperationsNotesModal(true) }}>
                {`${operationsNotes ? "Edit" : "Add Operations Notes"}`}
              </button>
            </div>

            <div className="mb-0">
              <div dangerouslySetInnerHTML={ { __html: stringEllipsis(operationsNotes, 60, 60) || "" } }></div>
              {operationsNotes?.length > 60 && (
                <button
                  type="button"
                  onClick={() => { setShowOperationsNotesModal(true) }}
                  className="btn btn-link p-0 ml-2">
                  More
                </button>
              )}
            </div>

          </div>
          <EditNoteModalSchedules
            readOnly={readOnly}
            show={showOperationsNotesModal}
            noteType="operationsNotes"
            title={operationsNotes ? "Edit Operations Notes" : "Add Operations Notes"}
            onHide={() => { setShowOperationsNotesModal(false) }}
            jobService={rowData}
            currentUser={currentUser}
            reloadData={reloadData} />
        </div>
      )}
      {(roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) || roleCheck(systemRole, currentTeamRole, ACCOUNTS_MANAGER)) && (
        <div className="dropdown">
          <img
            src={clientNotes ? clientNoteIconRed : clientNoteIconDark}
            className="cursor-pointer"
            data-toggle="dropdown"
            id="dropdownMenuLink" />
          <div className="dropdown-menu dropdown-menu-right p-3 note-dropdown">
            <div className="text-right">
              <button
                type="button"
                className="btn btn-link p-0"
                onClick={() => { setShowClientNotesModal(true) }}>
                {`${clientNotes ? "Edit" : "Add Client Notes"}`}
              </button>
            </div>

            <div className="mb-0">
              <div dangerouslySetInnerHTML={ { __html: stringEllipsis(clientNotes, 60, 60) || "" } }></div>
              {operationsNotes?.length > 60 && (
                <button
                  type="button"
                  onClick={() => { setShowClientNotesModal(true) }}
                  className="btn btn-link p-0 ml-2">
                  More
                </button>
              )}
            </div>

          </div>
          <EditNoteModalSchedules
            readOnly={readOnly}
            show={showClientNotesModal}
            noteType="clientNotes"
            title={operationsNotes ? "Edit Operations Notes" : "Add Operations Notes"}
            onHide={() => { setShowClientNotesModal(false) }}
            jobService={rowData}
            currentUser={currentUser}
            reloadData={reloadData} />
        </div>
      )}
    </td>
  )
}
