import normalize from "json-api-normalizer"
import customAxios from "./customAxios";

export const getOrganizations = (baseUrl, filters) => {
  const path = `${baseUrl}/organizations`

  return customAxios.get(path, { params: filters })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const postOrganization = (baseUrl, data) => {
  const path = `${baseUrl}/organizations`
  return customAxios.post(path, data)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const putOrganization = (baseUrl, organizationId, data) => {
  const path = `${baseUrl}/organizations/${organizationId}`
  return customAxios.put(path, data)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}


export const deleteOrganization = (baseUrl, organizationId) => {
  const path = `${baseUrl}/organizations/${organizationId}`
  return customAxios.delete(path)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const getOrganization = (baseUrl, organizationId) => {
  const path = `${baseUrl}/organizations/${organizationId}`
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}
