import React from "react"
import { combineReducers } from "redux"
import { toast } from "react-toastify"

import * as actionTypes from "../actions/types"

const toastOptions =  {
    toastId: "gear-details-page",
    containerId: "main"
}

function requestGearState(state = false, action) {
    switch (action.type) {
        case actionTypes.GEAR_REQUEST_START:
            return true
        case actionTypes.GEAR_REQUEST_SUCCESS:
        case actionTypes.GEAR_REQUEST_FAILURE:
            return false
        default:
            return state
    }
}

function putGearState(state = false, action) {
    switch (action.type) {
        case actionTypes.GEAR_PUT_START:
            return true
        case actionTypes.GEAR_PUT_SUCCESS:
            toast.success(
              <div>
                <span role="img" aria-label="success-icon">✅</span>
                <span className="ml-2">Gear updated!</span>
              </div>,
                toastOptions
            )
            return false
        case actionTypes.GEAR_PUT_FAILURE:
            toast.error(
              <div>
                <span role="img" aria-label="failure-icon">❌</span>
                <span className="ml-2">Gear update error!</span>
              </div>,
                toastOptions
            )
            return false
        default:
            return state
    }
}

function postGearState(state = false, action) {
    switch (action.type) {
        case actionTypes.GEAR_POST_START:
            return true
        case actionTypes.GEAR_POST_SUCCESS:
            toast.success(
                <div>
                    <span role="img" aria-label="success-icon">✅</span>
                    <span className="ml-2">Gear created!</span>
                </div>,
                toastOptions
            )
            window.location.href = '/settings/gears';
        case actionTypes.GEAR_POST_FAILURE:
            toast.error(
                <div>
                    <span role="img" aria-label="failure-icon">❌</span>
                    <span className="ml-2">Gear add error!</span>
                </div>,
                toastOptions
            )
            return false
        default:
            return state
    }
}

function editState(state = false, action) {
    switch (action.type) {
        case actionTypes.MANAGE_GEARS_START_EDITING:
            return true
        case actionTypes.MANAGE_GEARS_EDIT_SAVE:
        case actionTypes.MANAGE_GEARS_EDIT_CANCEL:
        case actionTypes.MANAGE_GEARS_START_CREATING:
        case actionTypes.MANAGE_GEARS_CREATE_SAVE:
        case actionTypes.MANAGE_GEARS_CREATE_CANCEL:
            return false
        default:
            return state
    }
}

function createState(state = false, action) {
    switch (action.type) {
        case actionTypes.MANAGE_GEARS_START_CREATING:
            return true
        case actionTypes.MANAGE_GEARS_START_EDITING:
        case actionTypes.MANAGE_GEARS_EDIT_SAVE:
        case actionTypes.MANAGE_GEARS_EDIT_CANCEL:
        case actionTypes.MANAGE_GEARS_CREATE_SAVE:
        case actionTypes.MANAGE_GEARS_CREATE_CANCEL:
            return false
        default:
            return state
    }
}

function gearEditableAttr(state = {}, action) {
    switch (action.type) {
        case actionTypes.GEAR_PUT_SUCCESS:
        case actionTypes.GEAR_REQUEST_SUCCESS: {
            const { data: { gear } } = action
            const gearAttributes = _.get(Object.values(gear), "0.attributes", {})
            const {
                gearIdentifier,
                itemDescription,
                isServiceItem,
                value,
                valueNumber,
                userName,
                userId,
                datePurchased,
                nextService,
                category
            } = gearAttributes
            return {
                gear_identifier: gearIdentifier,
                item_description: itemDescription,
                is_service_item: isServiceItem,
                value: valueNumber,
                category,
                // value_number: valueNumber,
                user_name: userName,
                user_id: userId,
                date_purchased: datePurchased,
                next_service: nextService
            }
        }
        case actionTypes.MANAGE_GEARS_EDIT_CANCEL: {
            const { data } = action
            const gearAttributes = _.get(data, "attributes", {})
            const {
                gearIdentifier,
                itemDescription,
                isServiceItem,
                value,
                valueNumber,
                userName,
                userId,
                datePurchased,
                nextService
            } = gearAttributes
            return {
                gear_identifier: gearIdentifier,
                item_description: itemDescription,
                is_service_item: isServiceItem,
                value: valueNumber,
                // value_number: valueNumber,
                user_name: userName,
                user_id: userId,
                date_purchased: datePurchased,
                next_service: nextService
            }
        }
        case actionTypes.MANAGE_GEARS_EDIT_UPDATE: {
            return { ...state, ...action.data }
        }
        default:
            return state
    }
}

function gearCreatableAttr(state = {}, action) {
    switch (action.type) {
        case actionTypes.MANAGE_GEARS_START_CREATING:
        case actionTypes.MANAGE_GEARS_CREATE_CANCEL:
        case actionTypes.GEAR_POST_SUCCESS: {
            const { data } = action
            const gearAttributes = _.get(data, "attributes", {})
            const {
                gearIdentifier,
                itemDescription,
                isServiceItem,
                valueNumber,
                user,
                datePurchased,
                nextService,
                status
            } = gearAttributes
            return {
                gear_identifier: gearIdentifier,
                item_description: itemDescription,
                is_service_item: isServiceItem,
                value: valueNumber,
                // value_number: valueNumber,
                user,
                date_purchased: datePurchased,
                next_service: nextService,
                status
            }
        }
        case actionTypes.MANAGE_GEARS_CREATE_UPDATE: {
            return { ...state, ...action.data }
        }
        default:
            return state
    }
}

export default combineReducers({
    requestGearState,
    postGearState,
    putGearState,
    gearEditableAttr,
    gearCreatableAttr,
    editState,
    createState
})