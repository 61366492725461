import React, { useCallback, useState, useEffect } from "react"
import { Modal, Spinner } from "react-bootstrap"
import { useSelector } from "react-redux"
import { checkoutJobService } from "requesters/jobServiceRequester"
import { PRODUCT_CODE_BCR } from "utils/constants"
import { getBaseUrl } from "../../../reducers"

const CheckOutModal = ({
  show, onHide, jobService, reloadData, surveyorAssignmentId, productName, assignmentsWithSameTime
}) => {
  const baseUrl = useSelector(getBaseUrl)
  const { id, attributes: { code } } = jobService
  const [notes, setNotes] = useState("")
  const [percentage, setPercentage] = useState("")
  const [loading, setLoading] = useState(false)

  const [multipleCheckouts, setMultipleCheckouts] = useState(assignmentsWithSameTime.map(assignment => ({
    job_service_id: assignment.jobServiceId,
    surveyor_assignment_id: assignment.surveyorAssignmentId,
    notes: "",
    percentage: "",
    product_name: assignment.productName,
    product_code: assignment.productCode
  })))

  const handlePercentageChange = event => {
    setPercentage(event.target.value)
  }

  const handlePercentageChangeForMultipleCheckouts = (event, assignmentId) => {
    const multipleCheckout = multipleCheckouts.findIndex(checkout => checkout.surveyor_assignment_id === assignmentId)
    if (multipleCheckout > -1) {
      // eslint-disable-next-line no-underscore-dangle
      const _multipleCheckouts = [...multipleCheckouts]
      _multipleCheckouts[multipleCheckout].percentage = event.target.value
      setMultipleCheckouts(_multipleCheckouts)
    }
  }

  const handleNoteChangeForMultipleCheckouts = (event, assignmentId) => {
    const multipleCheckout = multipleCheckouts.findIndex(checkout => checkout.surveyor_assignment_id === assignmentId)
    if (multipleCheckout > -1) {
      // eslint-disable-next-line no-underscore-dangle
      const _multipleCheckouts = [...multipleCheckouts]
      _multipleCheckouts[multipleCheckout].notes = event.target.value
      setMultipleCheckouts(_multipleCheckouts)
    }
  }

  const jobCheckOutHandler = () => {
    setLoading(true)
    checkoutJobService(baseUrl, id, surveyorAssignmentId, notes, percentage, multipleCheckouts)
      .then(res => {
        if (res.status === 200) {
          reloadData()
        }
        onHide()
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (show) {
      setLoading(false)
    }
  }, [show])

  const renderMultipleCheckouts = useCallback(() => multipleCheckouts && multipleCheckouts.map((assignment, index) => (
    <>
      <div className="mb-1 h5">
        <p>
          {assignment.product_name}
        </p>
      </div>
      <div className="mb-3">
        <label className="mr-4 cursor-pointer">
          <input
            className="mr-1 cursor-pointer"
            name={`radio-${index}`}
            type="radio"
            value="100%"
            checked={multipleCheckouts[index].percentage === "100%"}
            onChange={event => {
              handlePercentageChangeForMultipleCheckouts(event, assignment.surveyor_assignment_id)
            }} />
          100%
        </label>
        <label className="mr-4 cursor-pointer">
          <input
            className="mr-1 cursor-pointer"
            name={`radio-${index}`}
            type="radio"
            value="75%"
            checked={multipleCheckouts[index].percentage === "75%"}
            onChange={event => {
              handlePercentageChangeForMultipleCheckouts(event, assignment.surveyor_assignment_id)
            }} />
          75%
        </label>
        <label className="mr-4 cursor-pointer">
          <input
            className="mr-1 cursor-pointer"
            name={`radio-${index}`}
            type="radio"
            value="50%"
            checked={multipleCheckouts[index].percentage === "50%"}
            onChange={event => {
              handlePercentageChangeForMultipleCheckouts(event, assignment.surveyor_assignment_id)
            }} />
          50%
        </label>
        <label className="mr-4 cursor-pointer">
          <input
            className="mr-1 cursor-pointer"
            name={`radio-${index}`}
            type="radio"
            value="25%"
            checked={multipleCheckouts[index].percentage === "25%"}
            onChange={event => {
              handlePercentageChangeForMultipleCheckouts(event, assignment.surveyor_assignment_id)
            }} />
          25%
        </label>
        <label className="mr-4 cursor-pointer">
          <input
            className="mr-1 cursor-pointer"
            name={`radio-${index}`}
            type="radio"
            value="0%"
            checked={multipleCheckouts[index].percentage === "0%"}
            onChange={event => {
              handlePercentageChangeForMultipleCheckouts(event, assignment.surveyor_assignment_id)
            }} />
          0%
        </label>
        {PRODUCT_CODE_BCR.includes(assignment.product_code) && (
          <label className="mr-4 cursor-pointer">
            <input
              className="mr-1 cursor-pointer"
              name={`radio-${index}`}
              type="radio"
              value="BCR"
              checked={multipleCheckouts[index].percentage === "BCR"}
              onChange={event => {
                handlePercentageChangeForMultipleCheckouts(event, assignment.surveyor_assignment_id)
              }} />
            Boundary Calculation Required
          </label>
        )}

      </div>
      <div className="form-group row mb-2 pb-4 border-bottom">
        <textarea
          name="notes"
          className="form-control mr-5"
          placeholder="Reason required if Percentage is different then 100%"
          rows="3"
          value={multipleCheckouts[index].notes}
          onChange={e => handleNoteChangeForMultipleCheckouts(e, assignment.surveyor_assignment_id)}>
        </textarea>
      </div>
    </>
  )), [multipleCheckouts])
  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <h2 className="modal-title">Surveyor Check Out</h2>
          <div className="mb-2 h6">
            {/* <p className="mr-1">Select the Job completed percentage</p> */}
          </div>
        </Modal.Title>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          <div className="mb-1 h5">
            <p>
              {productName}
              {" "}
              <i>(current job service)</i>
            </p>
          </div>
          <div className="mb-3">
            <label className="mr-4 cursor-pointer">
              <input
                className="mr-1 cursor-pointer"
                name="percentage"
                type="radio"
                value="100%"
                checked={percentage === "100%"}
                onChange={handlePercentageChange} />
              100%
            </label>
            <label className="mr-4 cursor-pointer">
              <input
                className="mr-1 cursor-pointer"
                name="percentage"
                type="radio"
                value="75%"
                checked={percentage === "75%"}
                onChange={handlePercentageChange} />
              75%
            </label>
            <label className="mr-4 cursor-pointer">
              <input
                className="mr-1 cursor-pointer"
                name="percentage"
                type="radio"
                value="50%"
                checked={percentage === "50%"}
                onChange={handlePercentageChange} />
              50%
            </label>
            <label className="mr-4 cursor-pointer">
              <input
                className="mr-1 cursor-pointer"
                name="percentage"
                type="radio"
                value="25%"
                checked={percentage === "25%"}
                onChange={handlePercentageChange} />
              25%
            </label>
            <label className="mr-4 cursor-pointer">
              <input
                className="mr-1 cursor-pointer"
                name="percentage"
                type="radio"
                value="0%"
                checked={percentage === "0%"}
                onChange={handlePercentageChange} />
              0%
            </label>
            {PRODUCT_CODE_BCR.includes(code) && (
              <label className="mr-4 cursor-pointer">
                <input
                  className="mr-1 cursor-pointer"
                  name="percentage"
                  type="radio"
                  value="BCR"
                  checked={percentage === "BCR"}
                  onChange={handlePercentageChange} />
                Boundary Calculation Required
              </label>
            )}
          </div>
          <div className="form-group row mb-2 pb-4 border-bottom">
            <textarea
              name="notes"
              className="form-control mr-5"
              placeholder="Reason required if Percentage is different then 100%"
              rows="3"
              value={notes}
              onChange={e => setNotes(e.target.value)}>
            </textarea>
          </div>
          {renderMultipleCheckouts()}
        </form>
        <div className="modal-footer d-flex justify-content-end px-0 py-4">
          <button
            className="btn btn-link btn-lg"
            onClick={onHide}
            type="button">
            Close
          </button>
          <button
            className="btn btn-red btn-lg"
            onClick={jobCheckOutHandler}
            disabled={loading || !percentage || (percentage !== "100%" && !notes) || (multipleCheckouts.length > 0 && !!multipleCheckouts.find(checkout => !checkout.percentage || (checkout.percentage !== "100%" && !checkout.notes)))}
            type="submit">
            Check Out
            {loading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
          </button>
        </div>
      </div>
    </Modal>
  )
}
export default CheckOutModal
