import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { updateFile } from "requesters/uploadsRequester"
import { Modal } from "react-bootstrap"
import Select from "react-select"
import {
  createFileOptions,
  FILE_CATEGORIES,
  OPERATION_MANAGER,
  MASTER_BOUNDARY_FILE,
  } from "utils/constants"
  import { roleCheck } from "utils/index"
import { getBaseUrl, getCurrentUser } from "../../../reducers"

const EditFileModal = ({
  show, onHide, reloadData, file, fileOptions
}) => {
  console.log("🚀 ~ file: EditFileModal.jsx:18 ~ file:", file)
  const baseUrl = useSelector(getBaseUrl)

  const {
    id,
    attributes: {
                  documentCategory,
                  fileNameNoExtention,
                }
  } = file.data

  const [newCategory, setNewCategory] = useState({ value: documentCategory, label: documentCategory })
  const [newFileName, setNewFileName] = useState(fileNameNoExtention)
  const [validForm, setValidForm] = useState(true)

  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role

  const isAdminOrOperations = roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER)

  const resolveFileList = () => {
    if(documentCategory === MASTER_BOUNDARY_FILE) { return createFileOptions([MASTER_BOUNDARY_FILE])}

    return isAdminOrOperations ? createFileOptions(FILE_CATEGORIES) : createFileOptions(fileOptions)
  }

  const fileList = resolveFileList()

  const onSubmit = () => {
    if (!newFileName || !newCategory?.value) {
      setValidForm(false)
    } else {
      const params = {
        document_category: newCategory.value,
        file_name: newFileName
      }
      updateFile(baseUrl, id, params)
      .then(() => {
        reloadData()
        onHide()
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
    }
  }

  const showErrorMessage = attributes => {
    if (!validForm && !attributes) {
      return true
    }
    return false
  }

  useEffect(() => {
    setNewFileName(fileNameNoExtention)
    setNewCategory({ value: documentCategory, label: documentCategory })
  }, [onHide])

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title><h2 className="modal-title">Edit File</h2></Modal.Title>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          <div className="row mb-3">
            <label className="col-4">File Name</label>
            <div className="col-8">
              <input
                className="form-control"
                onChange={e => setNewFileName(e.target.value)}
                value={newFileName} />
              {showErrorMessage(newFileName) && (
                <div className="err-msg">Required Field</div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-4 col-form-label">File Category</label>
            <div className="col-8">
              <Select
                name="category"
                isSearchable
                onChange={e => setNewCategory(e)}
                options={fileList || []}
                placeholder="Select a Category"
                value={newCategory} />

              {showErrorMessage(newCategory?.value) && (
                <div className="err-msg">Required Field</div>
              )}
            </div>
          </div>
        </form>
        <div className="modal-footer d-flex justify-content-end p-0 mb-4 mt-5">
          <button
            className="btn btn-link btn-lg"
            onClick={onHide}
            type="submit">
            Close
          </button>
          <button
            className="btn btn-red btn-lg"
            onClick={onSubmit}
            disabled={newFileName === fileNameNoExtention && newCategory.value === documentCategory}
            type="submit">
            Save Changes
          </button>
        </div>
      </div>
    </Modal>
  )
}
export default EditFileModal
