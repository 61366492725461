import customAxios from "./customAxios";
import normalize from "json-api-normalizer"

export const createJobServiceContact = (baseUrl, params) => {
  const path = `${baseUrl}/job_service_contacts/create`
  return customAxios.post(path, { ...params })
      .then(({ data, status }) => ({ response: normalize(data), status }))
      .catch(error => ({ error }))
}

export const deleteContactFromJobService = (baseUrl, params) => {
  const path = `${baseUrl}/job_service_contacts/delete-from-job-service`
  return customAxios.delete(path, { params: { ...params } })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const updateContactType = (baseUrl, params) => {
  const path = `${baseUrl}/job_service_contacts/update-contact-type`
  return customAxios.put(path, params)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}
