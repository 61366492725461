import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Container, Row, Col } from "react-bootstrap"


import CustBreadcrumb from "components/CustBreadcrumb"
import { getBaseUrl } from "../../../reducers"
import * as actionTypes from "../../../actions/types"
import NewGearForm from "./NewGearForm";


export default function NewGearCard() {
    const dispatch = useDispatch()
    const baseUrl = useSelector(getBaseUrl)
    useEffect(() => {
      dispatch({ type: actionTypes.MANAGE_GEARS_START_CREATING })
    }, [])

    return (
      <Container fluid className="gear-details-page pb-5">
        <GearBreadcrumb />
        <GearBody />
      </Container>
    )
}

function GearBreadcrumb() {
    return <CustBreadcrumb links={[["Settings", "/settings"], ["Company Assets", "/settings/gears"]]} active="New" />
}

function GearBody() {
    return (
      <>
        <h2 className="mb-1">New Asset</h2>
        <Row>
          <Col md={8}>
            <NewGearForm />
          </Col>
        </Row>
      </>
    )
}