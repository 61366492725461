/* eslint-disable no-unused-expressions */
import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useState } from "react"
import { getUsers } from "requesters/userRequester"
import EditSaveButtons from "components/EditSaveCancelButton"
import { postGearHistoryRecord } from "requesters/gearHistoryRecordRequester"
import Select from "react-select"
import Loader from "components/Loader"
import { useParams } from "react-router-dom"
import GearCalendarSelect from "../GearCalendarSelect"
import GearBasicInput from "../GearBasicInput"
import * as actionTypes from "../../../../actions/types"
import { getBaseUrl } from "../../../../reducers"

const errorMessageList = [
    { attribute: "action", message: "Please choose an action" },
    { attribute: "assigned_to", message: "Please assgin the action to a user" },
    { attribute: "report_date", message: "Please provide a valid date" },
    { attribute: "notes", message: "Notes cannot be empty" },
]

export default function NewGearHistoryRecordForm({ gear }) {
  const gearState = gear?.attributes.state
  const gearStatusText = gear?.attributes.statusText
  const baseUrl = useSelector(getBaseUrl)
  const dispatch = useDispatch()
  const { createState, gearHistoryRecordCreatableAttr, postGearHistoryRecordState } = useSelector(state => state.manageGearHistoryRecordPage)
  const {
        action, user, report_date, notes
        } = gearHistoryRecordCreatableAttr
  const [users, setUsers] = useState({})
  const { id } = useParams()
  const gearId = parseInt(id, 10)
  const [errors, setErrors] = useState({
      action: true,
      assigned_to: true,
      report_date: true,
      notes: true,
  })
  const [invalidForm, setInValidForm] = useState(false)

  const fetchData = () => {
    getUsers(baseUrl)
    .then(res => {
        if (res.status === 200) {
          setUsers(_.get(res.response, "user", {}))
        }
    })
    .catch(err => console.error(err))
  }

  useEffect(() => {
      fetchData()
  }, [baseUrl])

  const errorHendler = () => {
    let [actionError, assignedToError, reportDateError] = [true, true, true]
    action === undefined ? actionError = true : actionError = false
    user === undefined ? assignedToError = true : assignedToError = false
    const pattern = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/
    if (report_date !== undefined) {
        report_date.match(pattern) ? reportDateError = false : reportDateError = true
    }

    const newErrors = {
        action: actionError,
        assigned_to: assignedToError,
        report_date: reportDateError,
    }
    setErrors(newErrors)
  }

  useEffect(() => {
    errorHendler()
  }, [action, user, report_date, notes])

  const errorMessage = attribute => {
      const message = _.find(errorMessageList, { attribute })
        if (invalidForm && errors[attribute] === true) {
          return <div className="err-msg">{message.message}</div>
      }
          return null
  }

  const userOptions = []

  Object.keys(users).forEach(key => {
      userOptions.push({
          value: users[key].id,
          label: users[key].attributes.name
      })
  })

  let actionOptions = []

  // set actionOptions allowed per each state
  if (gearState === "available") {
    actionOptions = [
      { value: "assigned_to", label: "Assigned to" },
      { value: "service", label: "Service" },
      { value: "archive", label: "Archive" }
    ]
  }
  if (gearState === "assigned") {
    actionOptions = [
      { value: "report_issue", label: "Report Issue" },
      { value: "returned_by", label: "Returned by" }
    ]
  }
  if (gearState === "returned") {
    actionOptions = [
      { value: "service", label: "Service" },
      { value: "archive", label: "Archive" },
      { value: "available", label: "Set as Available" },
    ]
  }
  if (gearState === "archived") {
    actionOptions = [
      { value: "available", label: "Set as Available" }
    ]
  }
  if (gearState === "service") {
    actionOptions = [
      { value: "available", label: "Set as Available" }
    ]
  }
  if (gearState === "report_issue") {
    actionOptions = [
      { value: "assigned_to", label: "Assigned to" },
      { value: "available", label: "Set as Available" }
    ]
  }

const postGearHistoryRecordUpdates = () => {
  if (errors.action || errors.assigned_to || errors.report_date) {
    setInValidForm(true)
  }

  if (!errors.action && !errors.assigned_to && !errors.report_date) {
    dispatch({ type: actionTypes.MANAGE_GEAR_HISTORY_RECORDS_CREATE_SAVE })

    dispatch({ type: actionTypes.GEAR_HISTORY_RECORD_POST_START })
    postGearHistoryRecord(baseUrl, gearHistoryRecordCreatableAttr, gearId)
    .then(res => {
      if (res.status === 201) {
        dispatch({ type: actionTypes.GEAR_HISTORY_RECORD_POST_SUCCESS, data: res.response })
        window.location.href = `/settings/gears/${gearId}`
      }
    })
    .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
        dispatch({ type: actionTypes.GEAR_HISTORY_RECORD_POST_FAILURE })
      })
    }
  }

  const updateAttr = data => {
      dispatch({ type: actionTypes.MANAGE_GEAR_HISTORY_RECORDS_CREATE_UPDATE, data })
  }

  return (
    <div className="card border-0">
      {postGearHistoryRecordState && (
      <div className="loader-overlay">
        <Loader />
      </div>
          )}
      <div className="card-body p-4">
        <div className="form-group row">
          <label className="col-3 col-md-4">Gear Status</label>
          <div className="col-9 col-md-8">
            <p>{gearStatusText}</p>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3 col-md-4">Action</label>
          <div className="col-9 col-md-8">
            <Select
              options={actionOptions}
              onChange={event => { updateAttr({ action: event.value }) }} />
            {errorMessage("action")}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3 col-md-4">Action User</label>
          <div className="col-9 col-md-8">
            <Select
              options={userOptions}
              onChange={event => { updateAttr({ user: event.value }) }} />
            {errorMessage("assigned_to")}
          </div>
        </div>
        <GearCalendarSelect {...{
                  title: "Action Date",
                  labelWrapperClass: "col-3 col-md-4",
                  inputWrapperClass: "col-9 col-md-8",
                  createState,
                  value: gearHistoryRecordCreatableAttr.report_date,
                  setInputValue: value => {
                      updateAttr({ report_date: value })
                  },
                  errorMessage: errorMessage("report_date")
              }} />
        <GearBasicInput {...{
                  title: "Notes",
                  labelWrapperClass: "col-3 col-md-4",
                  inputWrapperClass: "col-9 col-md-8",
                  createState,
                  type: "text",
                  value: gearHistoryRecordCreatableAttr.notes,
                  setInputValue: value => {
                      updateAttr({ notes: value })
                  },
              }} />
        <div className="mt-4">
          <EditSaveButtons
            createState={createState}
            save={postGearHistoryRecordUpdates} />
        </div>
      </div>
    </div>
  )
}