import { combineReducers } from "redux"
import { connectRouter } from "connected-react-router"

import createReducer from "components/utils/createReducer"

import data, * as fromData from "./data"
import manageUserPage from "./manageUserPage"
import manageGearPage from "./manageGearPage"
import manageGearHistoryRecordPage from "./manageGearHistoryRecordPage";
import accountSettingsPage from "./accountSettingsPage"
import schedulePage from "./schedulePage"
import * as actionTypes from "../actions/types"


const config = createReducer(
  function () {
    const { ...initialState } = gon
    return initialState
  }()
)({})

function currentRole(state = gon.current_role, action) {
  switch (action.type) {
    case actionTypes.CURRENT_USER_UPDATE_CURRENT_ROLE_REQUEST_START:
      return action.history.currentRole
    default:
      return state
  }
}

const options = createReducer(
  function () {
    return gon.options
  }()
)({})

function flash(state = gon.flash || [], action) {
  switch (action.type) {
    case actionTypes.FLASH_TRIGGERED:
      return []
    default:
      return state
  }
}

export default history => combineReducers({
  router: connectRouter(history),
  config,
  currentRole,
  options,
  flash,
  data,
  manageUserPage,
  manageGearPage,
  manageGearHistoryRecordPage,
  accountSettingsPage,
  schedulePage
})

// Config
export const getBaseUrl = state => state.config.urls.api

// Routes
export const getRouter = state => state.router

// Current User
export const getCurrentUser = state => state.config.current_user
export const getCurrentRole = state => state.currentRole
export const isLoggedIn = state => getCurrentUser(state) !== undefined

export const getResource = (state, { type, id }) => fromData.getResource(state.data, { type, id })
