import React, { useState } from "react"
import { useSelector } from "react-redux"
import { updateJobServiceTransition } from "requesters/jobServiceRequester"
import { getBaseUrl } from "../../../../reducers"
import CommentBox from "./CommentBox"
import ConfirmationModal from "../../modals/ConfirmationModal"

const ReportRequired = ({
  jobService,
  reloadData,
  comment,
  setComment
}) => {
  const {
    id, attributes: {
      jobNo,
      forProjectX,
      projectXJobNo,
      jobTitle
    }
  } = jobService
  const displayJobNo = forProjectX ? projectXJobNo : jobNo
  const baseUrl = useSelector(getBaseUrl)
  const [registeredSurveyor, setRegisteredSurveyor] = useState(false)
  const btnOutline = "btn btn-outline-secondary"
  const btnPrimary = "btn-primary"

  const SendToRegSurveyorHandler = () => {
    updateJobServiceTransition(baseUrl, id, "move-to-registered-surveyor", comment)
      .then(res => {
        if (res.status === 200) {
          window.location.reload()
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  return (
    <div className="timeline-item mt-2">
      <div className="card border-0 shadow-sm">
        <div className="card-body p-4">
          <div className="row py-3 mx-2 border-top border-bottom align-items-center">
            <div className="col-sm-12">
              Upload the report in Files tab under &quot;Other&quot; category
            </div>
            {/* <div className="col-sm-6 text-right"> */}
            {/* TODO: add functionality to this button */}
            {/* <button className="btn btn-outline-secondary" type="button">Generate Report</button> */}
            {/* </div> */}
          </div>
        </div>
      </div>
      <CommentBox comment={comment} setComment={setComment} />
      <div className="row mt-4">
        <div className="mr-4 pt-2">Send to</div>
        <div className="mr-2">
          <button className={`btn ${registeredSurveyor ? btnPrimary : btnOutline}`} type="button" onClick={() => setRegisteredSurveyor(!registeredSurveyor)}>Registered Surveyor</button>
          {registeredSurveyor && (
            <ConfirmationModal jobNo={displayJobNo} jobTitle={jobTitle} show={registeredSurveyor} sendFieldWorkHandler={SendToRegSurveyorHandler} messageType="Registered Surveyor"></ConfirmationModal>
          )}
        </div>
      </div>
    </div>
  )
}
export default ReportRequired
