import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Form } from "react-bootstrap"
import SweetAlert from "react-bootstrap-sweetalert"

import { getAssignedSurveyorMembers, getSurveyorsUsers, removeSurveyorMember } from "requesters/userRequester"

import { closeIcon } from "components/Icons"
import AnchorButton from "components/AnchorButton"
import { getBaseUrl } from "../../../reducers"
import UserSurveyorMembersModal from "./components/UserSurveyorMemberModal"

const UserSurveyorMembersCard = user => {
  const baseUrl = useSelector(getBaseUrl)

  const [assignedSurveyors, setAssignedSurveyors] = useState([])
  const [showSurveyorMemberModal, setShowSurveyorMemberModal] = useState(false)
  const [unassignedSurveyors, setUnassignedSurveyors] = useState([])
  const [surveyorToRemove, setSurveyorToRemove] = useState(null)
  const [showMessage, setShowMessage] = useState(false)

  const filterUnassignedSurveyors = surveyorOptions => {
    const unassignedOptions = surveyorOptions.filter(option => !assignedSurveyors.some(surveyor => surveyor.id === option.value));
    setUnassignedSurveyors(unassignedOptions);
  }

  const getAllSurveyorsUsers = () => {
    getSurveyorsUsers(baseUrl)
      .then(res => {
        const surveyorOptions = res.response.map(s => ({ label: `${s[0]} ${s[1]}`, value: s[2] }))
        filterUnassignedSurveyors(surveyorOptions)
      })
      .catch(err => console.error(err))
  }

  const assignedSurveyorMembers = () => {
    getAssignedSurveyorMembers(baseUrl, user.user.id)
      .then(res => {
        setAssignedSurveyors(res.response.data.attributes.surveyor_members)
      })
      .catch(err => console.error(err))
  }

  const removeSurveyorMemberHandler = () => {
    removeSurveyorMember(baseUrl, surveyorToRemove, user.user.id)
      .then(res => {
        if (res.status === 200) {
          setShowMessage(false)
          setSurveyorToRemove(null)
          assignedSurveyorMembers()
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  const btnStyle = {
    border: "none",
    boxShadow: "none"
  }

  useEffect(() => {
    assignedSurveyorMembers();
  }, []);

  return (
    <div className="card border-0 mt-4 shadow-sm">
      <div className="card-body p-4">
        {/* Only show this entire component for surveyor managers */}
        <h3>{`Surveyors (${user.user.attributes.surveyorMembers.length})`}</h3>
        <div className="table-responsive">
          <table className="table table-listing table-hover">
            <thead>
              <tr>
                <th>Surveyor</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {assignedSurveyors.map(surveyor => (
                <tr key={surveyor?.id}>
                  <td>{surveyor?.name}</td>
                  <td className="text-right">
                    <AnchorButton
                      onClick={() => {
                        setSurveyorToRemove(surveyor.id)
                        setShowMessage(true)
                        }}>
                      Remove
                    </AnchorButton>
                  </td>
                </tr>
              ))}
              <UserSurveyorMembersModal
                show={showSurveyorMemberModal}
                onHide={() => setShowSurveyorMemberModal(false)}
                unassignedSurveyors={unassignedSurveyors}
                user={user}
                reloadData={assignedSurveyorMembers} />
            </tbody>
          </table>
        </div>
        <AnchorButton
          className="btn btn-outlined-blue"
          onClick={() => { setShowSurveyorMemberModal(true); getAllSurveyorsUsers() }}>
          + Assign Surveyor
        </AnchorButton>
      </div>

      <SweetAlert
        show={showMessage}
        type="custom"
        customClass="text-dark"
        title="Are you sure you want to remove this assigned surveyor?"
        style={{ width: "600px" }}
        confirmBtnText="Remove"
        confirmBtnCssClass="btn btn-danger"
        confirmBtnStyle={btnStyle}
        closeOnClickOutside
        allowEscape={false}
        showCancel
        onCancel={() => setShowMessage(false)}
        onConfirm={() => removeSurveyorMemberHandler()}>
        <p className="text-muted">
          This action cannot be undone.
        </p>
      </SweetAlert>
    </div>
  )
}

export default UserSurveyorMembersCard