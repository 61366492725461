import React from "react"
import moment from "moment"

const BlockOutsTable = ({ blockOuts, currentDate }) => {
  const sortedBlockOuts = blockOuts.slice().sort((a, b) => {
    if (a.user < b.user) return -1
    if (a.user > b.user) return 1
    return 0
  })

  return (
    <div className="mb-2">
    <h6 className="mt-3 mb-0 d-inline">Surveyor On Leave</h6>
    <div className="table-responsive">
      <table className="table table-listing table-hover">
        <thead>
          <tr>
            <th>Suveyor</th>
            <th>Leave Type</th>
            <th>Start At</th>
            <th>End At</th>
          </tr>
        </thead>
        <tbody>
          {
            sortedBlockOuts?.map((bo) => {
              const startTime = moment(bo?.start_at, "DD/MM/YYYY hh:mm A")
              const endTime = moment(bo?.end_at, "DD/MM/YYYY hh:mm A")
              const currentDateStartTime = moment(`${currentDate} 08:00 AM`, "YYYY-MM-DD hh:mm A")
              const currentDateEndTime = moment(`${currentDate} 06:00 PM`, "YYYY-MM-DD hh:mm A")
              const displayStartTime = currentDateStartTime.isAfter(startTime) ? currentDateStartTime : startTime
              const displayEndTime = currentDateEndTime.isAfter(endTime) ? endTime : currentDateEndTime

              return (
                <tr key={bo?.id}>
                  <td>{bo?.user}</td>
                  <td>{bo?.leave_type}</td>
                  <td>{displayStartTime.format("DD/MM/YYYY hh:mm A")}</td>
                  <td>{displayEndTime.format("DD/MM/YYYY hh:mm A")}</td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
    {/* {blockOuts?.map((bo, index) => (
      <>
        <span
          className="undreline-dotted"
          title={`${bo?.leave_type}\nStart: ${bo?.start_at}\nEnd: ${bo?.end_at}`}
          key={bo?.id}>
          {bo?.user}
        </span>

        {((blockOuts?.length - 1) !== index) && (
          <span>, </span>
        )}
      </>
    ))} */}
  </div>
  )
}

export default BlockOutsTable
