/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { roleCheck } from "utils/index"
import { OPERATION_MANAGER, SURVEYORS_MANAGER } from "utils/constants"
import { toast } from "react-toastify"

import {
  Container, Row, Col, Card
} from "react-bootstrap"


import { getUser, resendInvitation } from "requesters/userRequester"
import { getSurveyorSkills } from "requesters/surveyorSkillsRequesters"
import CustBreadcrumb from "components/CustBreadcrumb"
import Loader from "components/Loader"
import formatDateString from "components/utils/formatDateString"
import { getBaseUrl, getCurrentUser } from "../../../reducers"
import * as actionTypes from "../../../actions/types"

import UserDetailsCard from "./UserDetailsCard"
import UserTeamsCard from "./UserTeamsCard"
import UserGearsCard from "./UserGearsCard"
import UserBlockOutCard from "./UserBlockOutCard"
import UserSurveyorMembersCard from "./UserSurveyorMembersCard"

export default function UserDetails() {
  const { id } = useParams()
  const userId = parseInt(id, 10)
  const dispatch = useDispatch()
  const baseUrl = useSelector(getBaseUrl)
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role

  if (!roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER)) window.location = "/"

  // fetchData
  useEffect(() => {
    async function fetchData() {
      dispatch({ type: actionTypes.USER_REQUEST_START })
      const { response, error } = await getUser(baseUrl, id)
      if (response) dispatch({ type: actionTypes.USER_REQUEST_SUCCESS, data: response })
      if (error) dispatch({ type: actionTypes.USER_REQUEST_FAILURE })
    }
    fetchData()

    async function fetchSurveyorSkills() {
      dispatch({ type: actionTypes.SURVEYOR_SKILL_REQUEST_START })
      const { response, error } = await getSurveyorSkills(baseUrl)
      if (response) dispatch({ type: actionTypes.SURVEYOR_SKILL_REQUEST_SUCCESS })
      if (error) dispatch({ type: actionTypes.SURVEYOR_SKILL_REQUEST_FAILURE })
    }
    fetchSurveyorSkills()
  }, [baseUrl, userId])

  return (
    <Container fluid className="user-details-page pb-5">
      <UserBreadcrumb />
      <UserBody />
    </Container>
  )
}

function UserBreadcrumb() {
  const { id } = useParams()
  const user = _.get(useSelector(state => state.data.users.byId), id)
  const { requestUserState } = useSelector(state => state.manageUserPage)
  const name = requestUserState ? "..." : _.get(user, `attributes.name`, "error")
  return <CustBreadcrumb links={[["Settings", "/settings"], ["Manage Users", "/settings/manage-users"]]} active={name} />
}

function UserBody() {
  const { id } = useParams()
  const userId = parseInt(id, 10)
  const baseUrl = useSelector(getBaseUrl)
  const user = _.get(useSelector(state => state.data.users.byId), id)
  const { requestUserState } = useSelector(state => state.manageUserPage)
  if (requestUserState) return <Loader />
  if (!user) return <h1>{`User with id: ${id} doesn't exist!`}</h1>
  const {
 attributes: {
 name, createdAt, invitationPending, invitationAccepted, invitationAcceptedAt
}
} = user
  const userSystemRole = user.attributes.role
  const userCurrentTeamRole = user.attributes.currentTeamRole
  const joinedDate = formatDateString(createdAt)
  const invitationAcceptedDate = formatDateString(invitationAcceptedAt)

  const toastOptions =  {
    toastId: "rails-flash",
    containerId: "main"
  }

  function triggerResendInvitation(userId) {
    async function postResendInvitation() {
      const { response, error } = await resendInvitation(baseUrl, userId)
      if (response) {
        toast.success(
          <div>
            <span role="img" aria-label="success-icon">✅</span>
            <span className="ml-2">{`Invitation has been successfully resent to ${user.attributes.email}!`}</span>
          </div>,
          toastOptions
        )
      }
      if (error) {
        toast.error(
          <div>
            <span role="img" aria-label="errir-icon">✅</span>
            <span className="ml-2">{`Error while resending invitation to ${user.attributes.email}!`}</span>
          </div>,
          toastOptions
        )
      }
    }
    postResendInvitation()
  }

  return (
    <>
      <h2 className="mb-1">{name}</h2>
      {invitationAccepted && <p className="text-muted">{`Joined ${invitationAcceptedDate}`}</p>}
      {invitationPending && (<button type="button" className="btn btn-red mb-4" onClick={() => triggerResendInvitation(userId)}>Resend Invite</button>)}
      <Row>
        <Col md={8}>
          <UserDetailsCard />
          {roleCheck(userSystemRole, userCurrentTeamRole, SURVEYORS_MANAGER) && (
            <UserSurveyorMembersCard user={user} />
          )}
          <UserTeamsCard />
          <UserBlockOutCard user={user} />
          <UserGearsCard user={user} />
        </Col>
        <Col md={4} className="mt-3 mt-md-0">
          <Card className="border-0">
            <Card.Body className="p-3">
              <h3>Reviews</h3>
              <p className="text-muted">No reviews</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}
