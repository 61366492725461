import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getBaseUrl } from "../../reducers";
import { createAssetsUser } from "../../../../requesters/userRequester";

const AddUserModal = ({
  forAssetsOnly,
  show,
  onHide,
  reloadData
}) => {
  const baseUrl = useSelector(getBaseUrl)
  const [requesting, setRequesting] = useState(false)
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const onClearStates = () => {
    setUser({})
  }

  const handleChange = e => {
    const { name, value } = e.target;
    setUser(prevAttrs => ({
      ...prevAttrs,
      [name]: value,
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!forAssetsOnly) return; // For now, the modal only handle the flow forAssetsOnly user, others flow maybe have in future

    setRequesting(true)
    const payLoad = { firstname: user.firstName, surname: user.lastName, email: user.email }
    createAssetsUser(baseUrl, payLoad)
    .then(res => {
      if (res.status === 201) {
        reloadData()
        onHide()
        onClearStates()
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
    .finally(() => setRequesting(false))
  };

  const modalTitle = forAssetsOnly ? "Add Asset' User" : "Add New User"

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <h2 className="modal-title">{modalTitle}</h2>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form onSubmit={handleSubmit}>
          <div className="form-group row">
            <label className="col-3 col-form-label">First Name</label>
            <div className="col-7">
              <input
                className="form-control"
                type="text"
                name="firstName"
                value={user.firstName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Last Name</label>
            <div className="col-7">
              <input
                className="form-control"
                type="text"
                name="lastName"
                value={user.lastName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Email*</label>
            <div className="col-7">
              <input
                className="form-control"
                type="email"
                name="email"
                value={user.email}
                onChange={handleChange}
              />
            </div>
          </div>
          <hr />
          <div className="modal-footer">
            <button className="btn btn-primary btn-lg" type="submit">
              Save
              {requesting && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddUserModal;