import React, { useEffect, useState } from "react";
import Loader from "components/Loader"
import DatePicker from "react-datepicker"
import moment from "moment"
import { useSelector } from "react-redux";
import FilterDropdown from "components/FilterDropdown"
import {
  useQueryParam,
  StringParam,
  withDefault
} from "use-query-params"
import { getBaseUrl } from "../../reducers";
import { getDraftingReports } from "../../../../requesters/performanceReportRequester";
import ReportTable from "./DraftingReport/ReportTable";

const getDate = val => {
  const momentObj = moment(val)
  const dateString = momentObj.format("YYYY-MM-DD")

  return dateString
}

const customDateParam = withDefault(StringParam, getDate(new Date()))

export default function DraftingReports({
  isAdminOrOperations, is3dDraftingManager
}) {
  const baseUrl = useSelector(getBaseUrl)
  const [requesting, setRequesting] = useState(false)
  const [summaryRows, setSummaryRows] = useState([])
  const [totalRow, setTotalRow] = useState([])
  const [detailedRows, setDetailedRows] = useState([])
  const [finishDate, setFinishDate] = useQueryParam("drafting_finish_date", customDateParam)

  const defaultDraftingType = () => {
    if (isAdminOrOperations) return ""
    if (is3dDraftingManager) return "3d"

    return "2d"
  }

  const DraftingTypeOptions = () => {
    const defaultOptions = [
      ["All", "all"],
      ["2D Drafting", "2d"],
      ["3D Drafting", "3d"]
    ]

    if (isAdminOrOperations) return defaultOptions
    if (is3dDraftingManager) return [["3D Drafting", "3d"]]

    return [["2D Drafting", "2d"]]
  }

  const defaultDraftingTypeParam = withDefault(StringParam, defaultDraftingType())
  const [draftingType, setDraftingType] = useQueryParam("drafting_type", defaultDraftingTypeParam)

  const initialFilter = {
    drafting_type: draftingType,
    finish_date: finishDate
  }
  const [filters, setFilters] = useState(initialFilter)

  const fetchData = () => {
    setRequesting(true)
    getDraftingReports(baseUrl, filters)
      .then(res => {
        if (res.status === 200) {
          setSummaryRows(res.data.json.summary)
          setTotalRow(res.data.json.total)
          setDetailedRows(res.data.json.detail)
        }
      })
      .catch(err => console.log(err));
      setRequesting(false)
  }

  const onFinishDateChange = val => {
    const newFinishDate = getDate(val)
    setFinishDate(newFinishDate)
    setFilters({ ...filters, finish_date: newFinishDate })
  }

  const onSelectDraftingType = val => {
    setDraftingType(val)
    setFilters({ ...filters, drafting_type: val })
  }

  useEffect(() => {
    fetchData()
  }, [filters])

  const clearStates = () => {
    setFinishDate(undefined)
    setDraftingType(undefined)
    setFilters({})
  }

  useEffect(() => () => clearStates(), [])

  const finishDateObject = moment(finishDate, "YYYY-MM-DD").toDate()

  return (
    <React.Fragment>
      <div className="container-inner">
        <div className="table-listing-filters d-md-flex flex-row align-items-center">
          <div className="mr-2">
            <DatePicker
              selected={finishDateObject}
              onChange={onFinishDateChange}
              closeOnScroll={e => e.target === document}
              className="form-control"
              dateFormat="dd/MM/yyyy" />
          </div>
          <FilterDropdown
            title="Drafting Type"
            hiddenAllOption
            filteredName={draftingType}
            setFilter={onSelectDraftingType}
            options={DraftingTypeOptions()} />
        </div>
      </div>
      {requesting ? <Loader /> : <ReportTable summaryRows={summaryRows} totalRow={totalRow} detailedRows={detailedRows} />}
    </React.Fragment>
  )
}