import React, { useState } from "react"
import { roleCheck } from "utils/index"
import {
  OPERATION_MANAGER,
  SURVEYORS_USER,
} from "utils/constants"
import CadastralRequestModal from "../../modals/CadastralRequestModal"

const CadastralRequestActivity = ({
  jobService,
  reloadData,
  systemRole,
  currentTeamRole,
}) => {
  const {
    id, attributes: {
      state,
      forProjectX,
    }
  } = jobService
  const bgCadestralPending = ((state === "cadastral_requested") && currentTeamRole.includes(SURVEYORS_USER)) ? "bg-coral" : null
  const [showDeclineModal, setShowDeclineModal] = useState(false)

  const approveCadastralRequest = () => {
    
  }

  return (
    state === "cadastral_requested" && forProjectX && (
      <div className="timeline-item mt-2 mb-2">
        <div className={`card border-0 shadow-sm ${bgCadestralPending}`}>
          <div className="card-body">
            <div className="row px-3 flex-column">
              <p className="mb-0">
                Job Pending Cadastral Approval from Operations
              </p>
            </div>
          </div>
        </div>
        {(roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) && state === "cadastral_requested") && (
          <div className="row mt-3 ml-1">
            <div className="mr-2">
              <button
                className="btn btn-outlined-blue"
                onClick={approveCadastralRequest}
                type="button">
                Approve
              </button>
            </div>
            <div className="mr-2">
              <button
                className="btn btn-outlined-red"
                onClick={() => setShowDeclineModal(true)}
                type="button">
                Decline
              </button>
              <CadastralRequestModal
                action="decline"
                jobService={jobService}
                reloadData={reloadData}
                show={showDeclineModal}
                onHide={() => setShowDeclineModal(false)} />
            </div>
          </div>
        )}
      </div>
    )
  )
}
export default CadastralRequestActivity
