import React from "react";

const SummarySection = ({
  summaryRows, totalRow
}) => {
  const TotalRow = () => (
    <tr className="font-weight-bold bg-secondary text-white">
      <td>Total:</td>
      <td>{totalRow.jobsComplete}</td>
    </tr>
  )

  return (
    <React.Fragment>
      <div style={{ border: "1px solid" }} className="p-3">
        <h5>Summary Report</h5>
        <div className="table-responsivew-50">
          <table className="table table-listing table-hover table-striped">
            <thead>
              <tr>
                <th>Surveyor</th>
                <th>Jobs Complete</th>
              </tr>
            </thead>
            <tbody>
              { summaryRows.map(row => (
                <tr key={row.surveyorName}>
                  <td>{row.surveyorName}</td>
                  <td>{row.jobsComplete}</td>
                </tr>
              ))}
              {TotalRow()}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SummarySection