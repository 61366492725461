import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import Select from "react-select"
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker"
import { linkInvoiceFromXero } from "../../../../../requesters/jobServiceRequester";
import { getBaseUrl } from "../../../reducers";
import { XERO_INVOICE_STATUSES } from "../../../../../utils/constants";
import { capitalizeString } from "../../../../../utils";

const ManualLinkInvoiceModal = ({
  show, onHide, jobService, reloadData
}) => {
  const {
    id, attributes: {
      totalCost, xeroAccounts
    }
  } = jobService;
  const initialStates = {
    price: totalCost,
    xero_invoice_id: "",
    invoice_number: "",
    online_invoice_url: "",
    amount_paid: 0,
    gst_paid: 0,
    issue_date: new Date(),
    invoice_type: "",
    xero_account: xeroAccounts[0],
    state: "authorised"
  }
  const baseUrl = useSelector(getBaseUrl)
  const [invoiceData, setInvoiceData] = useState(initialStates)
  const [requesting, setRequesting] = useState(false)

  const onLinkInvoice = () => {
    setRequesting(true)
    const payload = {
      ...invoiceData,
      xero_account: invoiceData.xero_account.value,
      invoice_type: invoiceData.invoice_type.value
    }
    linkInvoiceFromXero(baseUrl, id, payload, "manual")
    .then(res => {
      if (res.status === 200) {
        reloadData()
        onHide()
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
    .finally(() => setRequesting(false))
  }

  const invoiceTypes = [
    { value: "deposit", label: "Deposit" },
    { value: "final", label: "Final" },
    { value: "other", label: "Other" }
  ]
  const xeroInvoiceStates = XERO_INVOICE_STATUSES.map(status => ({ value: status.toLowerCase(), label: capitalizeString(status) }))

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <h2 className="modal-title">MANUAL LINK INVOICE</h2>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          <div className="form-group row mb-3">
            <label className="col-3">Price</label>
            <div className="col-8">
              <input
                id="invoice-price"
                name="invoice_price"
                className="form-control"
                type="text"
                value={invoiceData.price}
                onChange={e => setInvoiceData({ ...invoiceData, price: e.target.value })} />
            </div>
          </div>
          <div className="form-group row mb-3">
            <label className="col-3">Xero Invoice ID</label>
            <div className="col-8">
              <input
                id="xero-invoice-id"
                name="xero_invoice_id"
                className="form-control"
                type="text"
                placeholder="Xero Invoice ID"
                value={invoiceData.xero_invoice_id}
                onChange={e => setInvoiceData({ ...invoiceData, xero_invoice_id: e.target.value })} />
            </div>
          </div>
          <div className="form-group row mb-3">
            <label className="col-3">Xero Invoice Number</label>
            <div className="col-8">
              <input
                id="xero-invoice-number"
                name="xero_invoice_number"
                className="form-control"
                type="text"
                placeholder="Xero Invoice number"
                value={invoiceData.invoice_number}
                onChange={e => setInvoiceData({ ...invoiceData, invoice_number: e.target.value })} />
            </div>
          </div>
          <div className="form-group row mb-3">
            <label className="col-3">Online Invoice Url</label>
            <div className="col-8">
              <input
                id="online-invoice-url"
                name="xero_online_invoice_url"
                className="form-control"
                type="text"
                placeholder="Xero Online Invoice Url"
                value={invoiceData.online_invoice_url}
                onChange={e => setInvoiceData({ ...invoiceData, online_invoice_url: e.target.value })} />
            </div>
          </div>
          <div className="form-group row mb-3">
            <label className="col-3">Amount paid</label>
            <div className="col-8">
              <input
                id="amount-paid"
                name="amount_paid"
                className="form-control"
                type="number"
                value={invoiceData.amount_paid}
                onChange={e => setInvoiceData({ ...invoiceData, amount_paid: e.target.value })} />
            </div>
          </div>
          <div className="form-group row mb-3">
            <label className="col-3">GST paid</label>
            <div className="col-8">
              <input
                id="gst-paid"
                name="gst_paid"
                className="form-control"
                type="number"
                value={invoiceData.gst_paid}
                onChange={e => setInvoiceData({ ...invoiceData, gst_paid: e.target.value })} />
            </div>
          </div>
          <div className="form-group row mb-3">
            <label className="col-3">Issue Date</label>
            <div className="col-8">
              <DatePicker
                id="issue-date"
                wrapperClassName="date_picker full-width w-100"
                className="form-control"
                disabled={requesting}
                selected={invoiceData.issue_date ? moment(invoiceData.issue_date, "yyyy-MM-DD").toDate() : null}
                onChange={date => setInvoiceData({ ...invoiceData, issue_date: date })}
                dateFormat="yyyy-MM-dd" />
            </div>
          </div>
          <div className="form-group row mb-3">
            <label className="col-3">Invoice Type</label>
            <div className="col-8">
              <Select
                id="invoice-type"
                name="invoice_type"
                disabled={requesting}
                placeholder="Choose invoice type"
                onChange={e => setInvoiceData({ ...invoiceData, invoice_type: e })}
                options={invoiceTypes}
                value={invoiceData.invoice_type} />
            </div>
          </div>
          <div className="form-group row mb-3">
            <label className="col-3">Xero Account</label>
            <div className="col-8">
              <Select
                id="xero-account"
                name="xero_account"
                disabled={requesting}
                placeholder="Choose one account"
                onChange={e => setInvoiceData({ ...invoiceData, xero_account: e })}
                options={xeroAccounts}
                value={invoiceData.xero_account} />
            </div>
          </div>
          <div className="form-group row mb-3">
            <label className="col-3">Xero Invoice State</label>
            <div className="col-8">
              <Select
                id="xero-invoice-state"
                name="xero_invoice_state"
                disabled={requesting}
                placeholder="Choose Xero invoice state"
                onChange={e => setInvoiceData({
                  ...invoiceData,
                  state: xeroInvoiceStates.find(option => option.value === e.value)?.value
                })}
                options={xeroInvoiceStates}
                value={xeroInvoiceStates.find(o => o.value === invoiceData.state)} />
            </div>
          </div>
        </form>
        <hr />
        <div className="modal-footer d-flex justify-content-end px-0 py-3">
          <button
            className="btn btn-red btn-lg"
            onClick={onLinkInvoice}
            disabled={requesting}
            type="submit">
            {requesting ? <Spinner animation="border" role="status" size="sm" /> : "Link" }
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ManualLinkInvoiceModal