import customAxios from "./customAxios";
import normalize from "json-api-normalizer"

export const getSurveyorTypes = baseUrl => {
  const path = `${baseUrl}/surveyor_types`

  return customAxios.get(path)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}
