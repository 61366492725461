import React from "react"

const CustomCheckItem = ({
  id, name, label, checked, setChecked, iconClasses, labelClasses
}) => (
  <span className="custom-checkbox-element user-select-none mb-0">
    <input
      className="d-none"
      id={id}
      name={name}
      type="checkbox"
      checked={checked}
      onChange={e => setChecked(e, id, name)} />
    <label htmlFor={id} className="cursor-pointer mb-0">
      <span className={`bi ${checked ? "bi-check-circle-fill text-success" : "bi-circle text-muted" } ${iconClasses}`}></span>
      <span className={`ml-2 ${labelClasses}`}>
        { label }
      </span>
    </label>
  </span>
)

export default CustomCheckItem