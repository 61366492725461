import React from "react";
import { Link } from "react-router-dom"

const BookingRow = ({ date, booking }) => {
  const {
    bookingTime, checkIn, checkOut, duration, dateAndTimeSentToDrafting, address, type,
    percentage, price, jobServiceId, jobId
  } = booking

  return (
    <tr>
      <td>{bookingTime}</td>
      <td>{checkIn}</td>
      <td>{checkOut}</td>
      <td>{duration}</td>
      <td>{dateAndTimeSentToDrafting}</td>
      <td>
        <Link to={`/jobs/${jobId}/job_services/${jobServiceId}`} target="_blank" className="link-no-decoration">{address}</Link>
      </td>
      <td>{type}</td>
      <td>{percentage}</td>
      <td className="text-right">{price}</td>
    </tr>
  )
}

export default BookingRow