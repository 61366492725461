import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import Loader from "components/Loader"
import BarLoader from "components/BarLoader"
import { getBaseUrl } from "../../../reducers";

export default function DashboardCard(props) {
  const {
    link, title, count, cost, colorClass, ofTotal, total, openInNewTab, containerStyle = {}, onClick,
    selfFetchingCount, getCountRequester, setCount
  } = props
  // The format is `${count} of ${total}` if ofTotal = true
  const baseUrl = useSelector(getBaseUrl)
  const [requesting, setRequesting] = useState(false)

  useEffect(() => {
    if (selfFetchingCount) {
      setRequesting(true)
      getCountRequester(baseUrl)
      .then(res => {
        if (res.status === 200) {
          setCount(res.response?.job_count)
          setRequesting(false)
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
    }
  }, [])

  const JobsCountRow = () => (
    <React.Fragment>
      {
        ofTotal === true
        ? (
          <div className="h2 mb-0">
            {count || 0}
            <span className="text-lowercase"> of </span>
            {total}
          </div>
        )
        : <div className="h2 mb-0">{count || 0}</div>
      }
    </React.Fragment>
  )

  return (
    <div
      style={{ ...containerStyle }}
      className="col-6 mt-3"
      onClick={() => {
        if (onClick) {
          onClick()
        }
      }}>
      <a href={link} target={openInNewTab && "_blank"}>
        <div className={`card ${colorClass}`}>
          <div className="card-body" style={{ padding: "0.8rem" }}>
            {title}
            { requesting ? (
              <div className="mt-3">
                <BarLoader />
              </div>
              )
              : <JobsCountRow />
            }
            {cost && <div className="mt-2 text-muted">{`$${cost}`}</div>}
          </div>
        </div>
      </a>
    </div>
  )
}
