import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import Loader from "components/Loader";
import { getBaseUrl } from "../../../reducers";
import { getTimeSpents } from "../../../../../requesters/timeSpentRequester";
import { getJobService } from "../../../../../requesters/jobServiceRequester";
import TimeSpentTableSimple from "../tabs/TimeSpent/TimeSpentTableSimple";

const TimeSpentConfirmationModal = ({ show, sendTo, resetState }) => {
  const baseUrl = useSelector(getBaseUrl);
  const [timeSpents, setTimeSpents] = useState([]);
  const [jobService, setJobService] = useState(null);
  const [requesting, setRequesting] = useState(true);

  const pathSegments = window.location.pathname.split("/");
  const jobServiceId = pathSegments[pathSegments.indexOf("job_services") + 1];

  const fetchJobServiceAndTimeSpents = async () => {
    setRequesting(true);
    try {
      const jobServiceRes = await getJobService(baseUrl, { job_service_id: jobServiceId });
      if (jobServiceRes.status === 200) {
        const jobServiceData = jobServiceRes.response.jobService[jobServiceId];
        setJobService(jobServiceData);

        const timeSpentsRes = await getTimeSpents(
          baseUrl,
          jobServiceData.id,
          jobServiceData.type
        );

        if (timeSpentsRes.status === 200) {
          const timeSpentData = get(timeSpentsRes.response, "timeSpent", {});
          let listOfTimeSpent = Object.values(timeSpentData);
          const additionalInfo = timeSpentsRes.response.meta?.additional_info;

          if (!isEmpty(additionalInfo)) {
            listOfTimeSpent = additionalInfo.meta?.ids.map(
              (id) => timeSpentData[id]
            );
          }
          setTimeSpents(listOfTimeSpent);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    if (show) {
      fetchJobServiceAndTimeSpents();
    }
  }, [show])

  return (
    <Modal show={show} onHide={resetState} centered className="time-spent-confirmation-modal">
      <div className="modal-content" style={{ width: "600px", margin: "0 auto" }}>
        <div className="modal-header border-0 d-flex justify-content-center">
          <AiOutlineInfoCircle style={{ fontSize: "80px", color: "rgb(70, 184, 218)" }} />
        </div>
        <div className="modal-body text-center">
          <p style={{ fontSize: "18px", fontWeight: "normal" }}>
            Please Confirm Your Time Spent is Correct
          </p>
          {requesting ? (
            <Loader />
          ) : (
            <TimeSpentTableSimple
              timeSpents={timeSpents}
              jobService={jobService}
              reloadData={fetchJobServiceAndTimeSpents}
            />
          )}
        </div>
        <div className="modal-footer d-flex justify-content-center border-0 mt-4">
          <Button variant="light" onClick={resetState}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => { sendTo(); resetState(); }}>
            Send
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default TimeSpentConfirmationModal;
