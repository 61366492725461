import React from "react"
import MyGearRecord from "./MyGearRecord"

const MyGearTable = ({ gears, userId, gearIds }) => (
  <div className="table-responsive">
    <table className="table table-listing table-hover">
      <thead>
        <tr>
          <th>ID</th>
          <th>Item Description</th>
          <th className="absorbing-column">Equipment Type</th>
          <th>Service Date</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {gearIds.map(id => gears[id] && <MyGearRecord key={`table-row-${id}`} gear={gears[id]} userId={userId} />)}
      </tbody>
    </table>
  </div>
)

export default MyGearTable
