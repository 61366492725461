import { arrowDownIcon } from "components/Icons";
import { format } from "date-fns";
import { groupBy, keys } from "lodash";
import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { roleCheck } from "utils/index";
import AddTimeSpent from "../../../../../../components/AddTimeSpent";
import {
  DRAFTING_3D_MANAGER,
  DRAFTING_MANAGER,
  OPERATIONS,
  REGISTERED_SURVEYORS_MANAGER
} from "../../../../../../utils/constants";
import { getCurrentRole, getCurrentUser } from "../../../../reducers";

const TimeSpentTableSimple = ({ reloadData, timeSpents = [], jobService = {} }) => {
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentRole = useSelector(getCurrentRole)
  const currentTeamRole = currentUser.attributes.current_team_role
  const isAdminOrOperations = roleCheck(systemRole, currentRole, OPERATIONS)
  const isDraftingManager = roleCheck(systemRole, currentTeamRole, DRAFTING_3D_MANAGER) || roleCheck(systemRole, currentTeamRole, DRAFTING_MANAGER)
  const isRSManager = roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)
  const isManagerRole = isAdminOrOperations || isDraftingManager || isRSManager
  const [openSections, setOpenSections] = useState({})

  const toggleSection = (name) => {
    setOpenSections((prev) => ({
      ...prev,
      [name]: !prev[name],
    }))
  }

  const TimeSpentRow = ({ timeSpent }) => {
    if (!timeSpent) return null;

    const {
      attributes: {
        trackedAt,
        trackedHours,
      } = {},
    } = timeSpent;

    return (
      <div className="row py-3">
        <div className="col-5">{format(new Date(trackedAt), "d MMM yyyy")}</div>
        <div className="col-4">{trackedHours}</div>
        <div className="col-3">
          <Link to={`/jobs/${jobService.attributes.jobId}/job_services/${jobService.id}?tab=Time%20Spent`} className="link-no-decoration">
            Edit
          </Link>
        </div>
      </div>
    );
  };

  const totalHours = timeSpents.reduce(
    (total, timeSpent) => total + parseFloat(timeSpent?.attributes?.trackedHours || 0),
    0
  );

  const arrowStyle = isUp => {
    if (isUp) {
      return { transform: "rotate(-180deg)", transition: "0.2s ease-in-out" }
    }
    return { transform: "none", transition: "0.2s ease-in-out" }
  }

  const ManagerView = () => {
    const groupedTimeSpents = groupBy(timeSpents, "attributes.addedByUser.name")

    return (
      <React.Fragment>
        <div className="container">
          <div className="row border-bottom py-3 font-weight-bold">
            <div className="col-5">Date</div>
            <div className="col-4">Hours</div>
            <div className="col-3"></div>
          </div>
        </div>
        {keys(groupedTimeSpents).map(name => {
          const listTimeSpentsOfUser = groupedTimeSpents[name]
          const totalHours = listTimeSpentsOfUser.reduce((total, timeSpent) => total + parseFloat(timeSpent.attributes?.trackedHours), 0)
          const isOpen = openSections[name] || false

          return (
            <Accordion key={name}>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey={name} onClick={() => toggleSection(name)} className="cursor-pointer">
                  <div className="row font-weight-bold">
                    <div className="col-5">
                      {name}
                      {" "}
                      <img
                        src={arrowDownIcon}
                        className="img-fluid"
                        style={arrowStyle(isOpen)} />
                    </div>
                    <div className="col-4">{totalHours}</div>
                    <div className="col-3">
                      <Link to={`/jobs/${jobService.attributes.jobId}/job_services/${jobService.id}?tab=Time%20Spent`} className="link-no-decoration">
                        Edit
                      </Link>
                    </div>
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={name}>
                  <div className="text-center">
                    {listTimeSpentsOfUser.map(timeSpent => {
                      const {
                        attributes: {
                          trackedAt,
                          trackedHours
                        }
                      } = timeSpent

                      return (
                        <div className="row border-bottom p-3">
                          <div className="col-5">{format(new Date(trackedAt), "d MMM yyyy")}</div>
                          <div className="col-4">{trackedHours}</div>
                          <div className="col-3"></div>
                        </div>
                      )
                    })}
                  </div>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          )
        })}
        <div className="container">
          <div className="row py-3 font-weight-bold">
            <div className="col-5">Total</div>
            <div className="col-4">{totalHours}</div>
            <div className="col-3">
              <AddTimeSpent
                buttonText="Add Time"
                reloadData={reloadData}
                trackableId={jobService.id}
                trackableType={jobService.type}
                trackedOnState={jobService.attributes.state} />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  const normalUserView = () => (
    <div className="container">
      <div className="row border-bottom py-3 font-weight-bold">
        <div className="col-5">Date</div>
        <div className="col-4">Hours</div>
        <div className="col-3"></div>
      </div>
      {timeSpents.map(timeSpent => (
        <TimeSpentRow key={timeSpent?.id || Math.random()} timeSpent={timeSpent} />
      ))}
      <div className="row py-3 font-weight-bold">
        <div className="col-5">Total</div>
        <div className="col-4">{totalHours}</div>
        <div className="col-3">
          <AddTimeSpent
            buttonText="Add Time"
            reloadData={reloadData}
            trackableId={jobService.id}
            trackableType={jobService.type}
            trackedOnState={jobService.attributes.state} />
        </div>
      </div>
    </div>
  )

  return (
    <div className="table-responsive">
      {isManagerRole ? ManagerView() : normalUserView()}
    </div>
  );
};

export default TimeSpentTableSimple;
