import { combineReducers } from "redux"

import createReducer from "components/utils/createReducer"

import * as actionTypes from "../../actions/types"

const teamUsersFetchSuccess = (state, { data }) => ({ ...state, ...data.teamUser })

const byId = createReducer({})({
  [actionTypes.RESOURCE_FETCHED_SUCCESS]: teamUsersFetchSuccess,
})

export const getTeamUserById = (state, teamUserId) => state.byId[teamUserId]

export default combineReducers({
  byId
})