import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker"
import SweetAlert from "react-bootstrap-sweetalert"
import { addTimeSpent } from "../../requesters/timeSpentRequester";
import { getBaseUrl } from "../../apps/App/reducers";
import { preventNegativeValues } from "../../utils";

const AddTimeSpentModal = ({
  show, onHide, reloadData, trackableId, trackableType, trackedOnState, trackedForDivision
}) => {
  const baseUrl = useSelector(getBaseUrl)
  const [requesting, setRequesting] = useState(false)
  const [trackedDate, setTrackedDate] = useState(new Date())
  const [trackedHours, setTrackedHours] = useState(undefined)
  const [notes, setNotes] = useState("")
  const [errorMessages, setErrorMessages] = useState({
    date: "",
    hours: ""
  })

  const onClearStates = () => {
    setTrackedDate(new Date())
    setTrackedHours(undefined)
    setNotes("")
    setErrorMessages({ date: "", hours: "" })
  }

  const validatesInputs = () => {
    let valid = true;

    if (parseFloat(trackedHours) % 0.25 !== 0) {
      valid = false;
      setErrorMessages({ ...errorMessages, hours: "The value must in 15min increments, e.g 0.25, 0.5" })
    }

    return valid
  }

  const onHoursChange = e => {
    const newHours = (parseFloat(e.target.value) > 24 || parseFloat(e.target.value) < 0) ? trackedHours : e.target.value
    setTrackedHours(newHours)
  }

  const onAddingTimeSpent = () => {
    setRequesting(true)
    if (!validatesInputs()) {
      setRequesting(false)
      return;
    }

    const payload = {
      notes,
      tracked_at: trackedDate,
      tracked_hours: trackedHours,
      trackable_id: trackableId,
      trackable_type: trackableType,
      tracked_on_state: trackedOnState,
      tracked_for_division: trackedForDivision
    }
    addTimeSpent(baseUrl, payload)
      .then(res => {
        if (res.status === 201) {
          reloadData()
          onHide()
          onClearStates()
        }
      })
      // eslint-disable-next-line no-console
      .catch(({ err }) => console.log(err))
      .finally(() => setRequesting(false))
  }

  const canSubmit = !requesting && !!trackedHours && parseFloat(trackedHours) !== 0 && !!trackedDate

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <div>
          <h2 className="modal-title">Add Time Spent</h2>
        </div>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          <div className="form-group row">
            <label className="col-3 col-form-label">Date*</label>
            <div className="col-9">
              <DatePicker
                id={`new-time-spent-${trackableType}-${trackableId}`}
                wrapperClassName="date_picker full-width"
                className="form-control"
                selected={trackedDate}
                onChange={date => setTrackedDate(date)}
                maxDate={new Date()}
                dateFormat="yyyy-MM-dd" />
            </div>
            <span className="col-12 text-danger font-085rem"><i>{errorMessages.date}</i></span>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Hours*</label>
            <div className="col-9">
              <input
                className="form-control"
                inputMode="decimal"
                min={0}
                max={24}
                step={0.25}
                pattern="[0-9]*"
                type="number"
                placeholder="e.g., 0.75"
                onChange={onHoursChange}
                onKeyDown={preventNegativeValues}
                value={trackedHours} />
            </div>
            <span className="col-12 text-danger font-085rem"><i>{errorMessages.hours}</i></span>
          </div>
          <textarea
            className="form-control"
            rows="5"
            placeholder="Enter your notes here eg. What were you working on, any issues that took longer than expected (maximum 500 characters)"
            maxLength={500}
            value={notes}
            onChange={e => setNotes(e.target.value)} />
        </form>
        <hr />
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-primary btn-lg"
          onClick={onAddingTimeSpent}
          disabled={!canSubmit}
          type="submit">
          Add
          {requesting && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
        </button>
      </div>
    </Modal>
  )
}

export default AddTimeSpentModal
