import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { format } from "date-fns"
import { Modal } from "react-bootstrap"
import Select from "react-select"
import DatePicker from "react-datepicker";
import { updateJobServiceTransition, assignSurveyor, unassignSurveyor } from "requesters/jobServiceRequester"
import { getSurveyors } from "requesters/performanceReportRequester"
import { toast } from "react-toastify"
import SweetAlert from "react-bootstrap-sweetalert"
import { getBaseUrl } from "../../../reducers"

const AssignSurveyorModal = ({
  show, onHide, jobService, reloadData, scheduledAssignment, userInitColorRendering, handleOpenCheckOutModal
}) => {
  const {
    assignments: originalAssignments, scheduledAt, primarySurveyorAssignmentEndAt, canAssign
  } = scheduledAssignment
  const baseUrl = useSelector(getBaseUrl)
  const { id, attributes: { surveyorsList } } = jobService
  const [assignments, setAssignments] = useState(originalAssignments)
  const [surveyor, setSurveyor] = useState(null)
  const [role, setRole] = useState(null)
  const [startAt, setStartAt] = useState(new Date(scheduledAt))
  const [endAt, setEndAt] = useState(new Date(primarySurveyorAssignmentEndAt))
  const [filteredSurveyors, setFilteredSurveyors] = useState([])
  const [showMessage, setShowMessage] = useState(false)
  const [alertTitle, setAlertTitle] = useState("")
  const [alertText, setAlertText] = useState("")

  const resetError = () => {
    setShowMessage(false)
    setAlertTitle("")
    setAlertText("")
  }

  const surveyorRoles = [
    {
      label: "Secondary Surveyor",
      value: "secondary",
    },
    {
      label: "Job Assistant",
      value: "assistant",
    },
  ]

  const toastOptions =  {
    toastId: "container-inner",
    containerId: "main"
  }

  const getAllSurveyors = () => {
    getSurveyors(baseUrl)
      .then(res => {
        const options = []
        res.data.forEach(s => options.push({ label: `${s[0]} ${s[1]}`, value: s[2] }))
        setFilteredSurveyors(options.filter(s => !assignments.map(a => a.surveyorId).includes(s.value)))
      })
      .catch(err => console.error(err))
  }


  useEffect(() => {
    setFilteredSurveyors([])

    if (role?.value === "secondary") {
      const assignedSurveyors = assignments.map(a => a.surveyorId)
      setFilteredSurveyors(surveyorsList.filter(s => !assignedSurveyors.includes(s.value)))
    } else {
      getAllSurveyors()
    }
  }, [role])

  const createSurveyorAssignment = () => {
    const dateStartString = moment(startAt).format("DD/MM/YYYY HH:mm:ss")
    const dateEndString = moment(endAt).format("DD/MM/YYYY HH:mm:ss")
    const params = {
      surveyor_id: surveyor.value,
      role: role.value,
      booking_date: scheduledAt,
      start_at: dateStartString,
      end_at: dateEndString,
    }
    assignSurveyor(baseUrl, id, params)
      .then(res => {
        if (res.status === 200) {
          reloadData()
          setSurveyor(null)
          setRole(null)
        } else {
          setShowMessage(true)
          setAlertTitle("Secondary Surveyor cannot be added!")
          setAlertText(res?.error?.response?.data?.errors[0]?.detail || "Check the Secondary Surveyor's booking time.")
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
}

  const discardSurveyorAssignment = assignmentId => {
    const params = {
      surveyor_assignment_id: assignmentId,
    }
    unassignSurveyor(baseUrl, id, params)
    .then(res => {
      if (res.status === 200) {
        reloadData()
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }

  const formatCheckedTime = assignment => {
    if (!assignment?.checkedInAt) return "";
    if (!assignment?.checkedOutAt) return `${format(new Date(assignment.checkedInAt), "p")} - In progress`;
    return `${format(new Date(assignment?.checkedInAt), "p")} - ${format(new Date(assignment?.checkedOutAt), "p")}`;
  }

  const checkInHandler = surveyorAssignmentId => {
    updateJobServiceTransition(baseUrl, id, "check-in", null, surveyorAssignmentId)
      .then(res => {
        if (res.status === 200) {
          reloadData()
        } else {
          toast.error(
            <div>
              <span><i className="fa fa-exclamation mr-2" /></span>
              <span className="ml-2">{res?.error?.response?.data?.errors[0]?.detail}</span>
            </div>,
          toastOptions
        )
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  const checkOutHandler = (surveyorAssignmentId, role) => {
    if (role === "assistant") {
      updateJobServiceTransition(baseUrl, id, "check-out", null, surveyorAssignmentId)
      .then(res => {
        if (res.status === 200) {
          reloadData()
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
    } else {
      handleOpenCheckOutModal(surveyorAssignmentId)
    }
  }

  useEffect(() => {
    setAssignments(originalAssignments)
  }, [scheduledAssignment])

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <h2 className="modal-title">Manage Surveyor</h2>
        <div className="ml-3 pt-3">{format(new Date(scheduledAt), "dd MMM yyyy, EEEE haa")}</div>
      </Modal.Header>
      <div className="modal-body pb-4">
        { canAssign && (
          <>
            <div className="d-flex mb-2">
              <div className="text-left">
                <DatePicker
                  required
                  selected={startAt}
                  onChange={date => {
                  setStartAt(date)
                  }}
                  showTimeSelect
                  includeDates={[startAt]}
                  closeOnScroll={e => e.target === document}
                  className="form-control"
                  dateFormat="dd/MM/yyyy, hh:mm aa" />
              </div>
              <div className="text-left ml-2">
                <DatePicker
                  required
                  selected={endAt}
                  onChange={date => {
                  setEndAt(date)
                  }}
                  includeDates={[endAt]}
                  showTimeSelect
                  closeOnScroll={e => e.target === document}
                  className="form-control"
                  dateFormat="dd/MM/yyyy, hh:mm aa" />
              </div>
            </div>
            <div className="d-flex mb-4">
              <div className="col-5 mr-2 px-0">
                <Select
                  isClearable
                  isOptionDisabled={option => option.disabled}
                  isSearchable
                  name="role"
                  onChange={e => setRole(e)}
                  value={role}
                  options={surveyorRoles}
                  placeholder="Select Role" />
              </div>

              <div className="col-5 mr-2 px-0">
                <Select
                  isClearable
                  isOptionDisabled={option => option.disabled}
                  isSearchable
                  name="surveyor"
                  onChange={e => setSurveyor(e)}
                  value={surveyor}
                  options={filteredSurveyors}
                  placeholder="Select Surveyor" />
              </div>

              <div className="col-2 text-right">
                <button
                  className="btn btn-outlined-blue"
                  onClick={createSurveyorAssignment}
                  type="button">
                  Add
                </button>
              </div>
            </div>
          </>
        ) }

        {!assignments?.length && (<div>No Surveyor Assigned yet.</div>)}
        {assignments?.length > 0 && (
          <table className="table table-listing">
            <tbody>
              {assignments?.map(ass => (
                <tr key={ass}>
                  <td><div className={userInitColorRendering(ass.role)}>{ass?.surveyorInitials}</div></td>
                  <td>{ass?.surveyorName}</td>
                  <td>{ass?.roleLabel}</td>
                  {ass?.role !== "primary" ? (
                    <td>
                      { format(new Date(ass?.startAt), "p") }
                      {" "}
                      -
                      {" "}
                      { format(new Date(ass?.endAt), "p") }
                    </td>
                  ) : (<td></td>)}

                  <td>
                    {formatCheckedTime(ass)}
                  </td>
                  <td></td>

                  <td>
                    { ass?.checkedInAt && !ass?.checkedOutAt && (
                      <button
                        className="btn btn-link"
                        onClick={() => checkOutHandler(ass.surveyorAssignmentId, ass.role)}
                        type="button">
                        Check out
                      </button>
                    )}
                    { !ass?.checkedInAt && !ass?.checkedOutAt && (
                      <button
                        className="btn btn-link"
                        onClick={() => checkInHandler(ass.surveyorAssignmentId)}
                        type="button">
                        Check in
                      </button>
                    )}
                  </td>
                  <td>
                    { ass?.role !== "primary" && !ass?.checkedInAt && !ass?.checkedOutAt && (
                      <button
                        className="btn btn-link"
                        onClick={() => discardSurveyorAssignment(ass.surveyorAssignmentId)}
                        type="button">
                        Unassign
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <SweetAlert
          show={showMessage}
          type="error"
          title={alertTitle}
          style={{ width: "600px" }}
          closeOnClickOutside
          allowEscape={false}
          onConfirm={resetError}>
          {alertText}
        </SweetAlert>
      </div>
    </Modal>
  )
  }
export default AssignSurveyorModal
