import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import TimeSpentConfirmationModal from "./TimeSpentConfirmationModal";
import { AiOutlineInfoCircle } from "react-icons/ai";

const ConfirmationModal = ({
  show,
  sendTo,
  messageType,
  jobNo,
  jobTitle,
  resetState,
  title,
  children,
}) => {
  const [showTimeSpentModal, setShowTimeSpentModal] = useState(false);
  const [showAlert, setShowAlert] = useState(show);

  useEffect(() => {
    setShowAlert(show);
  }, [show]);

  const customIcon = (
    <div>
      <AiOutlineInfoCircle
        style={{
          fontSize: "80px",
          color: "rgb(70, 184, 218)",
          margin: "20px auto",
        }}
      />
    </div>
  );

  const alertTitle = title || (
    <span style={{ fontSize: "18px", fontWeight: "normal" }}>
      Are you sure you have finalized your work on <b>{`${jobNo} - ${jobTitle}`}</b> and wish to send to{" "}
      <b>{messageType}</b>?
    </span>
  );

  const handleMainConfirm = () => {
    setShowAlert(false);
    setShowTimeSpentModal(true)
  };

  const handleCancel = () => {
    setShowTimeSpentModal(false);
    resetState();
  };

  const handleTimeSpentConfirm = () => {
    setShowTimeSpentModal(false);
    sendTo();
    resetState();
  };

  return (
    <>
      {showAlert && (
        <SweetAlert
          custom={title ? undefined : true}
          showCancel
          showCloseButton
          show={showAlert}
          type={title ? "info" : undefined}
          title={alertTitle}
          style={{ width: "600px" }}
          closeOnClickOutside
          allowEscape={false}
          cancelBtnBsStyle="light"
          confirmBtnText="Send"
          customIcon={!title ? customIcon : undefined}
          onCancel={resetState}
          onConfirm={handleMainConfirm}
        >
          {children}
        </SweetAlert>
      )}

      <TimeSpentConfirmationModal
        show={showTimeSpentModal}
        resetState={handleCancel}
        sendTo={handleTimeSpentConfirm}
      />
    </>
  );
};

export default ConfirmationModal;
