import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom";

import { Spinner } from "react-bootstrap"
import AnchorButton from "components/AnchorButton"

import { archiveGear } from "requesters/gearRequester"
import { getBaseUrl } from "../../../reducers"

export default function GearRecord({ gear, fetchData }) {
  const baseUrl = useSelector(getBaseUrl)
  const [archiveRequested, setArchiveRequested] = useState(false)

  if (gear.attributes) {
    const {
        id,
        gearIdentifier,
        itemDescription,
        categoryText,
        statusColour,
        statusText,
        serviceDate,
        userName,
        archived
    } = gear.attributes

  const archiveGearHandler = (gearId, archiveStatus) => {
    archiveGear(baseUrl, gearId, archiveStatus)
    .then(res => {
      if (res.status === 200) {
        fetchData()
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }

  const archiveButtonText = archived ? "Unarchive" : "Archive"

        return (
          <tr>
            <td>
              <Link to={`/settings/gears/${id}`} className="link-no-decoration">{gearIdentifier}</Link>
            </td>
            <td>
              <Link to={`/settings/gears/${id}`} className="link-no-decoration">{itemDescription}</Link>
            </td>
            <td>
              <Link to={`/settings/gears/${id}`} className="link-no-decoration">{categoryText}</Link>
            </td>
            <td className={`text-center ${statusColour}`}>
              <Link to={`/settings/gears/${id}`} className="link-no-decoration">{statusText}</Link>
            </td>
            <td className="text-center bg-light">
              <Link to={`/settings/gears/${id}`} className="link-no-decoration">{serviceDate}</Link>
            </td>
            <td>
              <Link to={`/settings/gears/${id}`} className="link-no-decoration">{userName}</Link>
            </td>
            <td className="d-flex align-items-center justify-content-end">
              <Link to={`/settings/gears/${id}`}>Detail</Link>
              <span className="text-muted mx-2">|</span>
              <AnchorButton
                className="w-4rem mr-2"
                onClick={() => {
                  archiveGearHandler(id, !archived)
                  setArchiveRequested(true)
                }}>
                {archiveRequested ? (
                  <Spinner
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true" />
                ) : archiveButtonText}
              </AnchorButton>
            </td>
          </tr>
        )
    }
    return null
}
