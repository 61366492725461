import React from "react";
import _ from "lodash";

export default function HolidayBasicInput({
  field, inputValue, setInputValue
}) {
  return (
    <div className="form-group row">
      <label className="col-3 col-form-label">{_.startCase(field)}</label>
      <div className="col-9">
        {
          (field === "year")
            ? (
              <input
                className="form-control"
                type="number"
                value={inputValue || ""}
                onChange={event => { setInputValue(event.target.value) }} />
            )
          : (
            <input
              className="form-control"
              type="text"
              value={inputValue || ""}
              onChange={event => { setInputValue(event.target.value) }} />
            )
        }
      </div>
    </div>
  )
}
