import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { checkoutJobService } from "requesters/jobServiceRequester";
import { PRODUCT_CODE_BCR } from "utils/constants";
import { getBaseUrl } from "../../../reducers";

const EditCheckOutModal = ({ show, onHide, jobService, reloadData, surveyorAssignmentId, assignment, productName }) => {
  const baseUrl = useSelector(getBaseUrl);
  const { id, attributes: { code } } = jobService;
  const [notes, setNotes] = useState("");
  const [percentage, setPercentage] = useState("");
  const [loading, setLoading] = useState(false);

  const handlePercentageChange = (event) => {
    setPercentage(event.target.value);
  };

  const jobCheckOutHandler = () => {
    setLoading(true);
    checkoutJobService(baseUrl, id, surveyorAssignmentId, notes, percentage)
      .then((res) => {
        if (res.status === 200) {
          reloadData();
        }
        onHide();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (show) {
      setLoading(false);
      setNotes("");
      setPercentage(assignment.percentage || "");
    }
  }, [show, assignment]);

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <h2 className="modal-title">Surveyor Edit Check Out</h2>
        </Modal.Title>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          <div className="mb-1 h5">
            <p>
              {productName} <i>(current job service)</i>
            </p>
          </div>
          <div className="mb-3">
            {["100%", "75%", "50%", "25%", "0%"].map((value) => (
              <label key={value} className="mr-4 cursor-pointer">
                <input
                  className="mr-1 cursor-pointer"
                  name="percentage"
                  type="radio"
                  value={value}
                  checked={percentage === value}
                  onChange={handlePercentageChange}
                />
                {value}
              </label>
            ))}
            {PRODUCT_CODE_BCR.includes(code) && (
              <label className="mr-4 cursor-pointer">
                <input
                  className="mr-1 cursor-pointer"
                  name="percentage"
                  type="radio"
                  value="BCR"
                  checked={percentage === "BCR"}
                  onChange={handlePercentageChange}
                />
                Boundary Calculation Required
              </label>
            )}
          </div>
          <div className="form-group row mb-2 pb-4 border-bottom">
            <textarea
              name="notes"
              className="form-control mr-5"
              placeholder="Reason required if Percentage is different than 100%"
              rows="3"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>
        </form>
        <div className="modal-footer d-flex justify-content-end px-0 py-4">
          <button className="btn btn-link btn-lg" onClick={onHide} type="button">
            Close
          </button>
          <button
            className="btn btn-red btn-lg"
            onClick={jobCheckOutHandler}
            disabled={loading || !percentage || (percentage !== "100%" && !notes)}
            type="submit"
          >
            Check Out
            {loading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditCheckOutModal;
