import React from "react";
import SummarySection from "./SummarySection";
import DetailsSection from "./DetailsSection";

const ReportTable = ({
  summaryRows, totalRow, detailedRows
}) => {
  const SummaryView = () => (
    <>
      {
        summaryRows?.length ? (<SummarySection summaryRows={summaryRows} totalRow={totalRow} />)
        : <p>No Data Available</p>
      }
    </>
  )

  return (
    <React.Fragment>
      <div className="my-5">
        {SummaryView()}
      </div>
      <div>
        {
          detailedRows.map(group => <DetailsSection rows={group} key={group.name} />)
        }
      </div>
    </React.Fragment>
  )
}

export default ReportTable;