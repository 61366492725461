/* eslint-disable no-console */
import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { updateJobServiceTransition, bookInFlaggedJob } from "requesters/jobServiceRequester"
import { getFlagTypeOptionsByJobService, uploadFlaggedFiles, postFlag } from "requesters/flagRequester"
import { Modal, Spinner } from "react-bootstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import Dropzone from "../../../../../components/utils/Dropzone"
import CustomCheckItem from "../../../../../components/CustomCheckItem"
import {
  MAX_SIZE_UPLOADED_FILE,
  MAX_SIZE_UPLOADED_FILE_TEXT,
  PRODUCT_CODE_PASS_THE_RESOLVE_FLAGGING_STEP
} from "../../../../../utils/constants"
import { getBaseUrl } from "../../../reducers"

const FlagJobServiceModal = ({
 show, onHide, jobService, reloadData, flagJobServiceSource,
}) => {
  const baseUrl = useSelector(getBaseUrl)
  const { id, attributes: { state, workflow, code, activeFlag, uploadedFlagFiles } } = jobService
  const [usedFlagFileIds, setUsedFlagFileIds] = useState([]) 
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [largeUploads, setLargeUploads] = useState([])
  const [uploading, setUploading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [sendTo, setSendTo] = useState(workflow === "3" ? "drafting" : "operations")
  const [flagNotes, setFlagNotes] = useState("")
  const [flagTypeOptions, setFlagTypeOptions] = useState({})
  const [sendToOptions, setSendToOptions] = useState(null)
  const [selectedSendTo, setSelectedSendTo] = useState(null)
  const [currentFlagTypeOptions, setCurrentFlagTypeOptions] = useState([])
  const [selectedFlagType, setSelectedFlagType] = useState(null)

  useEffect(() => {
    getFlagTypeOptionsByJobService(baseUrl, jobService.id)
    .then(res => {
      const [firstKey] = Object.keys(res.response);
      setCurrentFlagTypeOptions(res.response[firstKey]);
      setSendToOptions(Object.keys(res.response));
      setFlagTypeOptions(res.response);
      firstKey ? setSelectedFlagType(res.response[firstKey][0]?.value) : undefined
      setSelectedSendTo(firstKey)
    })
  }, [])

  useEffect(() => {
    setUsedFlagFileIds(uploadedFlagFiles.filter(file => state === 'to_review' ? file?.data?.attributes?.isFileUsedForReviewFlag : file?.data?.attributes?.isFileUsedForDraftingFlag).map(file => file.data.id))
  }, [jobService])

  const handleChangeSelectedSendTo = value => {
    setSelectedSendTo(value)
    setCurrentFlagTypeOptions(flagTypeOptions[value])
  }

  const handlePreview = e => {
    const files = e.value
    const largeFiles = files?.filter(file => file.size > MAX_SIZE_UPLOADED_FILE)
    if (largeFiles?.length) {
      setLargeUploads(largeFiles)
      setUploadedFiles([])
      setShowError(true)
    } else {
      setUploadedFiles([...uploadedFiles, ...files])
    }
  }

  const handleDeleteFile = fileToDelete => {
    const updatedFiles = uploadedFiles.filter(file => file !== fileToDelete);
    setUploadedFiles(updatedFiles);
  }

  const handleBookInFlaggedJob = () => {
    bookInFlaggedJob(baseUrl, id)
      .then(res => {
        const jobServiceId = Object.keys(res?.response?.jobService)[0];
        window.location.href = `/jobs/${jobService.attributes.jobId}/job_services/${jobServiceId}`
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }

  const UploadedFileElement = file => {
    const { id, attributes: { fileName } 
    } = file
    const isUsedFile = usedFlagFileIds.includes(id)

    return (
      <div key={id} className={`row mb-0 py-2 ${isUsedFile ? "hightlighted hl-warning" : ""}`}>
        <div className="col-6">
          <i className="bi bi-file-earmark mr-2"></i>
          {fileName}
        </div>
        <div className="col-6 text-right">
          <CustomCheckItem
            id={`uploaded-file-${id}`}
            name={fileName}
            label="Used File"
            iconClasses="font-085rem"
            checked={isUsedFile}
            setChecked={onChangeUsedFiles} />
        </div>
      </div>
    )
  }

  const handleUploadFiles = () => {
    const data = new FormData()
    data.append("job_service_id", id)
    data.append("flag_id", activeFlag.id)
    data.append("flag_type", selectedFlagType)
    uploadedFiles.forEach(file => data.append(`uploaded_files[]`, file))
    
    setUploading(true)
    uploadFlaggedFiles(baseUrl, data)
      .finally(() => {
        setUploadedFiles([])
        setUploading(false)
        reloadData()
      })
  }

  const handleSubmit = () => {
    if (flagNotes) {
      const data = new FormData()
      data.append("job_service_id", id)
      data.append("flag_type", selectedFlagType)
      data.append("sent_to", selectedSendTo)
      data.append("notes", flagNotes)
      data.append("from_at", state)
      data.append("used_flag_file_ids", usedFlagFileIds)

      setDisableSubmit(true)
      postFlag(baseUrl, data)
        .then(res => {
          if (res.status === 201) {
            updateJobServiceTransition(baseUrl, id, "require-additional-info", `Flagged Added: ${flagNotes}`)
            .then(res => {
              if (res.status === 200) {
                window.location.reload()
              }

              // Create Book in Job immediately and skip the Resolve Flag Step
              if (PRODUCT_CODE_PASS_THE_RESOLVE_FLAGGING_STEP.includes(code) && selectedFlagType === "PLAN-REQ") {
                handleBookInFlaggedJob()
              }
            })
            .catch(err => console.log(err))
          }
        })
        .catch(err => console.log(err))
    }
  }

  useEffect(() => {
    setLargeUploads([])
    if (flagNotes) {
      setDisableSubmit(false)
    } else {
      setDisableSubmit(true)
    }
  }, [flagNotes, show])

  const onChangeUsedFiles = (e, elementID) => {
    const checkboxChecked = e.target.checked
    const uploadedFileID = elementID.split("-")?.pop()
    const isSelectedForFlagged = usedFlagFileIds.includes(uploadedFileID)
    if (checkboxChecked && !isSelectedForFlagged) {
      setUsedFlagFileIds([...usedFlagFileIds, uploadedFileID])
    } else if (!checkboxChecked && isSelectedForFlagged) {
      const newSelectedFile = usedFlagFileIds.filter(selectedId => selectedId !== uploadedFileID)
      setUsedFlagFileIds(newSelectedFile)
    }
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <div className="modal-header px-md-5 pt-4 border-0">
        <h2 className="modal-title">ADD FLAG</h2>
      </div>
      <div className="modal-body px-md-5">
        <form>
          <div className="form-group row mb-3">
            <label className="col-4">Send to</label>
            <div className="col-6">
              <select className="form-control" value={selectedSendTo} onChange={e => handleChangeSelectedSendTo(e.target.value)}>
                {sendToOptions && sendToOptions.map(team => (
                  <option value={team} key={team}>{team.charAt(0).toUpperCase() + team.slice(1)}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-group row mb-5">
            <label className="col-4">Flag type</label>
            <div className="col-6">
              <select className="form-control" value={selectedFlagType} onChange={e => setSelectedFlagType(e.target.value)}>
                {currentFlagTypeOptions?.map(flagType => (
                  <option value={flagType.value} key={flagType.value}>{flagType.label}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-group row border-bottom pb-4">
            <div className="col-12 mb-5">
              <textarea
                name="flag_notes"
                className="form-control"
                placeholder="Notes*"
                rows="4"
                value={flagNotes}
                onChange={e => setFlagNotes(e.target.value)}>
              </textarea>
            </div>
            <div className="col-12 mb-5">
              <Dropzone
                wrapperClass="drag-drop"
                multiple
                name="photo"
                setUploadedFiles={setUploadedFiles}
                handleFileUpload={handlePreview} />
            </div>
            <div className="col-12 mb-5">
              {!!uploadedFiles?.length && uploadedFiles.map(file => (
                <div key={file.path} className="file-list-item d-flex align-items-center justify-content-between">
                  <div>
                    <i className="bi bi-file-earmark mr-2"></i>
                    {file.path}
                  </div>
                  <i
                    className="bi bi-x-circle-fill text-danger delete-file"
                    onClick={() => handleDeleteFile(file)}
                    title="Delete File" />
                </div>
              ))}
            </div>
            <div className="col-12 text-right">
              <button
                className="btn btn-outline-primary btn-sm"
                disabled={!uploadedFiles?.length|| uploading}
                onClick={handleUploadFiles}
                type="submit">
                {uploading ? <Spinner animation="border" /> : `Upload files` }
              </button>
            </div>
          </div>
        </form>
        { uploadedFlagFiles?.length > 0 ? (
          <div>
            <p>Uploaded Files</p>
            <div className="ml-0 mt-1">
              { uploadedFlagFiles.map(file => UploadedFileElement(file.data)) }
            </div>
          </div>
        ) : (
          <p>
            No Existing Received Files - Please Upload
          </p>
        )}
        <div className="modal-footer d-flex justify-content-center px-0 py-4">
          <button
            className="btn btn-red btn-lg"
            onClick={handleSubmit}
            disabled={disableSubmit}
            type="submit">
            Confirm Flag
          </button>
        </div>
      </div>

      <SweetAlert
        show={showError}
        type="error"
        title="File size exceeded!"
        style={{ width: "600px" }}
        closeOnClickOutside
        allowEscape={false}
        onConfirm={() => {
          setShowError(false)
          setLargeUploads([])
          }}>
        Max size is {MAX_SIZE_UPLOADED_FILE_TEXT}
      </SweetAlert>
    </Modal>
  )
  }
export default FlagJobServiceModal
