import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import { createNote } from "requesters/jobServiceRequester";
import { roleCheck } from "utils/index";
import {
  ACCOUNTS,
  OPERATIONS
} from "../../../../../utils/constants";
import { getBaseUrl, getCurrentRole, getCurrentUser } from "../../../reducers";

const ConfirmationModal = ({
  show, onHide, reloadData, jobService
}) => {
  const baseUrl = useSelector(getBaseUrl)
  const { id } = jobService
  const currentUser = useSelector(getCurrentUser)
  const userId = currentUser.id
  const systemRole = currentUser.attributes.role
  const currentRole = useSelector(getCurrentRole)
  const isAdminOrOperationsOrAccounts = roleCheck(systemRole, currentRole, OPERATIONS) || roleCheck(systemRole, currentRole, ACCOUNTS)

  const [notes, setNotes] = useState("")
  const [notesForAdmin, setNotesForAdmin] = useState(isAdminOrOperationsOrAccounts)
  const [loading, setLoading] = useState(false)

  const createNotesHandler = () => {
    setLoading(true)
    createNote(baseUrl, id, userId, { notes, for_admin: notesForAdmin })
      .then(res => {
        if (res.status === 201) {
          reloadData()
          onHide()
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <div>
          <h2 className="modal-title">Add Notes</h2>
        </div>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          <div className="form-group" style={{ height: "300px" }}>
            <label>Notes</label>
            <ReactQuill
              style={{ height: "200px", display: "block" }}
              placeholder="e.g. existing dwelling, any substantial trees"
              value={notes || ""}
              onChange={value => setNotes(value)} />
          </div>
          {isAdminOrOperationsOrAccounts && (
            <div className="form-group">
              <div className="for-admin-checkbox-element mt-2 user-select-none">
                <input
                  className="d-none"
                  id="for-admin-checkbox"
                  type="checkbox"
                  checked={notesForAdmin}
                  onChange={() => setNotesForAdmin(!notesForAdmin)} />
                <label htmlFor="for-admin-checkbox" className="cursor-pointer">
                  <span className={`bi ${notesForAdmin ? "bi-check-circle-fill text-success" : "bi-circle text-muted"}`}></span>
                  <span className="ml-2"> Accounts/Operations Note </span>
                </label>
              </div>
            </div>
          )}
        </form>
        <hr />
      </div >
      <div className="modal-footer">
        <button
          className="btn btn-red btn-lg"
          onClick={() => createNotesHandler()}
          disabled={!notes || loading}
          type="submit">
          Add Note
          {loading && <Spinner animation="border" />}
        </button>
      </div>
    </Modal >
  )
}
export default ConfirmationModal
