import React, { useState, useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { Modal, Spinner } from "react-bootstrap"
import Select from "react-select"
import { toast } from "react-toastify"
import ReactQuill from "react-quill"
import { sendReleasedFiles } from "../../../../../requesters/jobServiceRequester"
import { getBaseUrl } from "../../../reducers"
import "react-quill/dist/quill.snow.css"
import {
  PLAN_OF_DELIMITATION_RELEASING_CONTENT,
  PLAN_OF_REDEFINITION_OR_PLAN_OF_CONSOLIDATION_RELEASING_CONTENT,
  PLAN_OF_SUBDIVISION_RELEASING_CONTENT,
  _88E_OR_88E_EASE_RELEASING_CONTENT
} from "../../../../../utils/constants"

const SendReleasedFilesEmailModal = ({
  show, onHide, jobService, file
}) => {
  const {
    attributes: {
      toContactOptions,
      ccContactOptions,
      criticalNote,
      productName,
      code,
      jobNo,
      forProjectX,
      projectXJobNo,
      dropboxFileUrl,
      outstandingInvoiceFlagged,
      unmatchedInvoiceFlagged
    }
  } = jobService
  const displayJobNo = forProjectX ? projectXJobNo : jobNo
  const baseUrl = useSelector(getBaseUrl)
  const mainPersons = toContactOptions.filter(jobContact => jobContact.contactType === "Main Person")
  const invoicePersons = toContactOptions.filter(jobContact => jobContact.contactType === "Invoice")
  const defaultOption = invoicePersons.length ? invoicePersons[0] : mainPersons[0]
  const toContactOptionsList = toContactOptions.map(jobContact => ({ value: jobContact.value, label: jobContact.label }))
  const [currentContact, setCurrentContact] = useState({})

  const [contactsToBeCc, setContactsToBeCc] = useState([])
  const [displayedCcContacts, setDisplayedCcContacts] = useState([])
  const isSpecialWAE = ["WAE-STW", "WAE-ADD"].includes(jobService.attributes?.code)
  const isPlanOfDelimitation = jobService.attributes?.code === "DELIM"
  const isPlanOfRedefinitionOrPlanOfConsolidation = ["REDEF", "CONSOL"].includes(jobService.attributes?.code)
  const isPlanOfSubdivision = jobService.attributes?.code === "SUB"
  const is_88e_Or_88e_Ease = ["88E", "88E-EASE"].includes(jobService.attributes?.code) // Define name like this to able to read
  const fileUrl = dropboxFileUrl ? `<a href=${dropboxFileUrl}>${dropboxFileUrl}</a>` : "{{FileURL}}"
  const additionalInformation3dProduct=["3D-ADD", "3D-ADD-DRAFT", "3D-ADD-MISS"]
  const additionalInformationProduct=["ADD-INFO", "ADD-INFO-DRAFT", "ADD-INFO-MISS", "ADD-INFO-RS", "WAE-ADD"]
  const isAddInfo3dProduct = additionalInformation3dProduct.includes(code)
  const isAddInfoProduct = additionalInformationProduct.includes(code)
  const productNameLabel = isAddInfoProduct ? "Additional Information" : isAddInfo3dProduct ? "3d Additional Information" : productName

  const emailTemplate = `
    <p>Hi {{ToName}},</p>
    <p>Thank you for using C&amp;A Surveyors - your trusted local land surveyors.</p>
    ${isSpecialWAE ? "<p>This is a preliminary Work as Executed (WAE) plan, it is intended for review by the hydraulic engineer. Please forward any comments or feedback from the engineer and / or client to me.</p>" : ""}
    ${criticalNote ? `<p>Please review these notes from our Registered Surveyor:</p><b><i>${criticalNote}</i></b>` : ""}
    <p>Your files for ${productNameLabel} are ready to download, please use the link below to access. For security reasons, the link will only be valid for 7 days.</p>
    <p>${fileUrl}</p>
    ${isPlanOfDelimitation ? PLAN_OF_DELIMITATION_RELEASING_CONTENT : ""}
    ${isPlanOfRedefinitionOrPlanOfConsolidation ? PLAN_OF_REDEFINITION_OR_PLAN_OF_CONSOLIDATION_RELEASING_CONTENT : ""}
    ${isPlanOfSubdivision ? PLAN_OF_SUBDIVISION_RELEASING_CONTENT : ""}
    ${is_88e_Or_88e_Ease ? _88E_OR_88E_EASE_RELEASING_CONTENT : ""}
    <p>If you have any questions regarding the job, please reply to this email or call us on (02) 9630 9299.</p>
  `;

  const [message, setMessage] = useState(emailTemplate)

  const [subject, setSubject] = useState(`${displayJobNo} - ${jobService.jobTitle}`)
  const [additionalCc, setAdditionalCc] = useState("")
  const [loading, setLoading] = useState(false)

  const toastOptions = {
    toastId: "container-inner",
    containerId: "main"
  }

  const handleContacts = (e, contact) => {
    const tmpArray = [...contactsToBeCc]
    if (e.target.checked) {
      tmpArray.push(parseInt(contact.value, 10))
    } else {
      _.pull(tmpArray, parseInt(contact.value, 10))
    }

    setContactsToBeCc(tmpArray)
  }

  const sendReleasedFilesHandler = () => {
    const emailOptions = {
      to: currentContact.value,
      cc: contactsToBeCc,
      additional_cc: additionalCc,
      message,
      subject,
    }

    sendReleasedFiles(baseUrl, emailOptions, message, jobService.id, file.data.id)
      .then(res => {
        if (res.status === 200) {
          toast.success(
            <div>
              <span role="img" aria-label="success-icon">✅</span>
              <span className="ml-2">Email Sent To Client!</span>
            </div>,
            toastOptions
          )
          window.location.reload()
        }
      })
      .catch(err => {
        toast.error(
          <div>
            <span role="img" aria-label="failure-icon">❌</span>
            <span className="ml-2">{err?.response?.data?.errors[0]?.detail}</span>
          </div>,
          toastOptions
        )
      })
  }

  useMemo(() => {
    setSubject(`${displayJobNo} - ${jobService.jobTitle}`)
  }, [jobService])

  useEffect(() => {
    // If the Current Selected Contact is IN the CC List, Remove them
    const newDisplayedCcContacts = ccContactOptions.map(jobContact => ({ value: jobContact.value, label: jobContact.label }))
    if (newDisplayedCcContacts.some(jobContact => jobContact.value === currentContact.value)) {
      const updatedCcList = newDisplayedCcContacts.filter(jobContact => jobContact.value !== currentContact.value)
      setDisplayedCcContacts(updatedCcList)
    }
  }, [currentContact])


  useEffect(() => {
    const mainPersons = toContactOptions.filter(jobContact => jobContact.contactType === "Main Person")
    const invoicePersons = toContactOptions.filter(jobContact => jobContact.contactType === "Invoice")
    const defaultOption = invoicePersons.length ? invoicePersons[0] : mainPersons[0]
    if (defaultOption) {
      setCurrentContact({ value: defaultOption.value, label: defaultOption.label })
    } else {
      setCurrentContact({})
    }
  }, [toContactOptions])

  const onSubmit = () => {
    setLoading(true)
    sendReleasedFilesHandler()
    onHide()
  }

  useEffect(() => {
    if (currentContact && currentContact.value) {
      const contact = toContactOptions.find(c => c.value === currentContact.value)
      if (contact) {
        const newEmailTemplate = emailTemplate.replace("{{ToName}}", contact.fullName)
        setMessage(newEmailTemplate)
      }
    }
  }, [currentContact])

  useEffect(() => {
    if (show) {
      setLoading(false)
      setDisplayedCcContacts(ccContactOptions.length ? ccContactOptions.map(jobContact => ({ value: jobContact.value, label: jobContact.label })) : [])
      setCurrentContact(defaultOption ? { value: defaultOption.value, label: defaultOption.label } : {})
    }
  }, [show])


  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header
        className="pb-3"
        closeButton>
        <Modal.Title>
          <h3 className="modal-title">Choose Who to Send To</h3>
        </Modal.Title>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <div className="form-group row">
          <label className="col-2 col-form-label">To</label>
          <div className="col-10">
            <Select
              className="assign-dropdown w-75"
              name="client"
              isSearchable
              placeholder="Select Client"
              options={toContactOptionsList.length ? toContactOptionsList : []}
              value={currentContact}
              onChange={e => {
                setCurrentContact(e);
              }} />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-2 col-form-label">CC</label>
          <form className="col-10">
            <div className="mb-4">
              {!!displayedCcContacts?.length && displayedCcContacts.map(contact => (
                <div className="custom-control custom-checkbox mb-2" key={contact.value}>
                  <input
                    className="custom-control-input"
                    id={contact.value}
                    type="checkbox"
                    onChange={e => handleContacts(e, contact)} />
                  <label className="custom-control-label" htmlFor={contact.value}>{contact.label}</label>
                </div>
              ))}
            </div>
          </form>
        </div>

        <div className="form-group row">
          <div className="col-2"></div>
          <div className="col-10">
            <label htmlFor="email">Additional Non-Contacts Cc</label>
            <input
              name="additional_cc"
              className="form-control"
              type="email"
              id="additional_cc"
              placeholder="Email"
              onChange={e => setAdditionalCc(e.target.value)} />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-2 col-form-label">Subject</label>
          <div className="col-10">
            <input
              name="Subject"
              className="form-control"
              value={subject}
              onChange={e => setSubject(e.target.value)} />
          </div>
        </div>

        <div className="form-group">
          <ReactQuill
            value={message}
            onChange={value => setMessage(value)} />
        </div>
        <div className="modal-footer d-flex align-items-center justify-content-between px-0 py-4">
          <button
            className="btn btn-blue btn-lg"
            onClick={onHide}
            type="button">
            Back
          </button>
          <button
            className="btn btn-red btn-lg"
            onClick={onSubmit}
            disabled={!toContactOptionsList?.length || loading || outstandingInvoiceFlagged || unmatchedInvoiceFlagged}
            type="submit">
            Send to Client
            {loading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
          </button>
        </div>
      </div>
    </Modal>
  )
}
export default SendReleasedFilesEmailModal
