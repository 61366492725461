import cx from "classnames";
import {
  notepadIcon,
  notepadIconDark,
  reorderDots,
  brokenLink
} from "components/Icons";
import StatusChangeDropdown from "components/StatusChangeDropdown";
import { format } from "date-fns";
import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { assignRegSurveyor } from "requesters/jobServiceRequester";
import {
  ADMIN,
  REGISTERED_SURVEYORS_MANAGER,
  REGISTERED_SURVEYORS_USER,
  PRODUCT_CODE_REQUIRED_REPORT
} from "utils/constants";
import { roleCheck } from "utils/index";
import { getBaseUrl, getCurrentUser } from "../../reducers";

const jobStates = {
  pending: {
    text: "Pending",
    bgClass: "bg-yellow"
  },
  additional_info: {
    text: "Additional Info",
    bgClass: "bg-coral",
    api: "additional-info",
    btnClass: "btn-light-blue"
  },
  to_review: {
    text: "To Review",
    bgClass: "bg-light-blue",
    api: "",
    btnClass: "btn-light-blue"
  },
  completed: {
    text: "Completed",
    bgClass: "bg-green",
    api: "",
    btnClass: "btn-green"
  },
  ready_to_send: {
    text: "Ready To Send",
    bgClass: "bg-green",
    api: "set-ready-to-send",
    btnClass: "btn-green"
  },
  ready_to_collect: {
    text: "Ready to Collect",
    bgClass: "bg-green",
    api: "",
    btnClass: "btn-green"
  },
};

const updatableStates = [];
// const updatableStates = ["to_review", "ready_to_send", "ready_to_collect"];
const updatableOptions = ["additional_info", "ready_to_send"];


export default function RegSurveyorsRow({
  jobService, regSurveyorOptions, fetchData, readOnly, disableDrag
}) {
  const baseUrl = useSelector(getBaseUrl);
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role

  const isRegisteredSurveyorsUser = roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_USER)

  const [showMessage, setShowMessage] = useState(false);
  const [alertType, setAlertType] = useState("error");
  const [alertTitle, setAlertTitle] = useState("");
  const [showRegisteredSurveyorModal, setShowRegisteredSurveyorModal] = useState(false);
  const {
    id, attributes:
    {
      code,
      job_id,
      job_no,
      client_name,
      job_address,
      label,
      registered_surveyor_id,
      registered_surveyor_name,
      checked_out_at,
      highlighted,
      client_due_date,
      client_due_date_overdue,
      primary_surveyor_name,
      registered_surveyor_reports_is_exist,
      job_has_multiple_job_services,
    }
  } = jobService


  const clientDueDateObj = new Date(client_due_date)
  const currentDate = new Date()
  const dueDateOverdue = client_due_date_overdue
  const tomorrow = new Date()

  tomorrow.setDate(currentDate.getDate() + 1);
  const dueTomorrow = tomorrow.toLocaleDateString() === clientDueDateObj.toLocaleDateString()

  const isRegisteredSurveyorsManager = roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)
  const isAdminManager = roleCheck(systemRole, currentTeamRole, ADMIN)

  const dueDateHandler = () => {
    if (!client_due_date) {
      return ``
    }
    if (dueTomorrow) {
      return `bg-yellow`
    }
    if (dueDateOverdue || highlighted) {
      return `bg-coral`
    }

    return ""
  }

  const updateJobHandler = params => {
    assignRegSurveyor(baseUrl, id, params)
      .then(() => {
        // if (res.status === 200) {
        fetchData()
        // }
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
        setAlertTitle("Ooops! Something went wrong")
        setAlertType("error")
        setShowMessage(true)
      })
  }

  const handleRegSurvModal = () => {
    if (!readOnly) {
      setShowRegisteredSurveyorModal(true)
    }
  }

  const RegisteredSurveyorModal = ({ setShowRegisteredSurveyorModal }) => {
    const closeModal = () => {
      setShowRegisteredSurveyorModal(false);
    };
    const ref = useDetectClickOutside({ onTriggered: closeModal });
    return (
      <div
        onMouseLeave={closeModal}
        className={cx("dropdown-menu shadow-sm", { "show-modal": showRegisteredSurveyorModal })}
        ref={ref}>
        <p className="small mb-2">Select Registered Surveyor</p>
        <select
          className="form-control"
          value={registered_surveyor_id || ""}
          onChange={e => {
            updateJobHandler({ registered_surveyor_id: e.target.value })
            setShowRegisteredSurveyorModal(false)
          }}>
          <option value="" key="">Select Registered Surveyor</option>
          {
            regSurveyorOptions.map(option => (
              <option
                value={option[1]}
                key={`-${option[1]}`}>
                {option[0]}
              </option>
            ))
          }
        </select>
      </div>
    );
  };

  return (
    <>
      <td className="bg-white">
        {!disableDrag && (
          <img src={reorderDots} style={{ width: 20 }} />
        )}
      </td>
      <td className={`${highlighted && "bg-coral"}`}>
        <Link to={`/jobs/${job_id}/job_services/${id}`} className="link-no-decoration">{job_no}</Link>
      </td>
      <td className={`${highlighted && "bg-coral"}`}>
        <Link to={`/jobs/${job_id}/job_services/${id}`} className="link-no-decoration">{job_address}</Link>
      </td>
      <td className={`${highlighted && "bg-coral"}`}>
        <Link to={`/jobs/${job_id}/job_services/${id}`} className="link-no-decoration">{client_name}</Link>
      </td>
      <td className={`${highlighted && "bg-coral"}`}>
        <Link to={`/jobs/${job_id}/job_services/${id}`} className="link-no-decoration">{label}</Link>
      </td>
      <td className={`${highlighted && "bg-coral"} text-center`}>
        <Link to={`/jobs/${job_id}/job_services/${id}`} className="link-no-decoration">{checked_out_at ? format(new Date(checked_out_at), "dd MMM yyyy") : ""}</Link>
      </td>
      {isRegisteredSurveyorsManager && (
        <td className={`${highlighted && "bg-coral"}`}>
          <Link to={`/jobs/${job_id}/job_services/${id}`} className="link-no-decoration">{primary_surveyor_name}</Link>
        </td>
      )}
      <td className={`${highlighted ? "bg-coral" : "bg-light"} dropdown`}>
        <button
          className="btn btn-block"
          type="button"
          onClick={handleRegSurvModal}>
          {registered_surveyor_name}
        </button>
        <RegisteredSurveyorModal setShowRegisteredSurveyorModal={setShowRegisteredSurveyorModal} />
      </td>
      {/* <td className="text-center">
        {index + 1}
      </td> */}
      <StatusChangeDropdown job={jobService} reloadData={fetchData} jobStates={jobStates} updatableStates={updatableStates} updatableOptions={updatableOptions} />
      {(isAdminManager || !isRegisteredSurveyorsUser) && (
        <td className={`${dueDateHandler()} text-center`}>{client_due_date}</td>
      )}
      <td className={`${highlighted && "bg-coral"}`}>
        {
          PRODUCT_CODE_REQUIRED_REPORT.includes(code) && (
            <img
              src={registered_surveyor_reports_is_exist ? notepadIconDark : notepadIcon}
              className="cursor-pointer mb-2"
              data-toggle="dropdown"
              id="dropdownMenuLink" />
          )
        }
        { job_has_multiple_job_services && <img src={brokenLink} className="icon-size-18" id="has-child-jobs-icon" /> }
      </td>
      <td className={`${highlighted && "bg-coral"}`}>
        <div className="d-flex align-items-center justify-content-end">
          <Link to={`/jobs/${job_id}/job_services/${id}`}>Details</Link>
        </div>
      </td>
      <SweetAlert
        show={showMessage}
        type={alertType}
        title={alertTitle}
        closeOnClickOutside
        allowEscape={false}
        onConfirm={() => setShowMessage(false)} />
    </>
  );
}
