import React, { useState, useEffect, useRef } from "react"
import DatePicker from "react-datepicker"
import Select from "react-select"
import { Modal } from "react-bootstrap"
import { useSelector } from "react-redux"
import { createGlobalStyle } from "styled-components";
import moment from "moment"
import { getBaseUrl } from "../../../../reducers"
import { postUserBlockOut, putUserBlockOut } from "../../../../../../requesters/userBlockOutRequester"

const DatePickerWrapperStyles = createGlobalStyle`
.date_picker.full-width {
    width: 100%;
}
`;

const UserBlockOutModal = ({
  user, show, onHide, reloadData, editable
}) => {
  const baseUrl = useSelector(getBaseUrl)
  const [startAt, setStartAt] = useState(null)
  const [endAt, setEndAt] = useState(null)
  const endDateRef = useRef(null);
  const [isEndAtError, setIsEndAtError] = useState(false)
  const [leaveType, setLeaveType] = useState(null)
  const { leaveTypes } = useSelector(state => state.options)
  const [leaveCategoriesOptions, setLeaveCategoriesOptions] = useState([])


  const handleLeaveTypes = () => {
    const leaveCategoriesList = Object.keys(leaveTypes).map(key => ({
      value: leaveTypes[key][1],
      label: leaveTypes[key][0]
    })).sort((a, b) => a.label.localeCompare(b.label))

    setLeaveCategoriesOptions(leaveCategoriesList)
  }

  useEffect(() => {
    if (editable) {
      setLeaveType({ value: editable?.attributes?.leaveType.toLowerCase().replace(" ", "_"), label: editable?.attributes?.leaveType })
      setStartAt(moment(editable?.attributes?.startAt, "DD/MM/YYYY hh:mm a").valueOf())
      setEndAt(moment(editable?.attributes?.endAt, "DD/MM/YYYY hh:mm a").valueOf())
    } else {
      setLeaveType(null)
      setStartAt(new Date().setHours(8, 0, 0, 0))
      setEndAt(new Date().setHours(18, 0, 0, 0))
    }
  }, [editable])

  useEffect(() => {
    handleLeaveTypes()
  }, [leaveTypes])

  const errorsHandler = () => {
    if (endAt < startAt) {
      setIsEndAtError(true)
    }
  }

  const handleBlockOut = () => {
    errorsHandler()
    if (endAt > startAt) {
      setIsEndAtError(false)
      const dateStartString = moment(startAt).format("DD/MM/YYYY HH:mm:ss")
      const dateEndString = moment(endAt).format("DD/MM/YYYY HH:mm:ss")

      const params = {
        start_at: dateStartString,
        end_at: dateEndString,
        leave_type: leaveType.value,
        user_id: user.id
      }
      if (editable) {
        putUserBlockOut(baseUrl, editable.id, params)
          .then(res => {
            if (res.status === 200) {
              onHide()
              setLeaveType(null)
              reloadData()
            }
          })
          // eslint-disable-next-line no-console
          .catch(err => { console.log(err) })
      } else {
        postUserBlockOut(baseUrl, params)
          .then(res => {
            if (res.status === 201) {
              onHide()
              setLeaveType(null)
              reloadData()
            }
          })
          // eslint-disable-next-line no-console
          .catch(err => { console.log(err) })
      }
    }
  }

  const handleStartDateChange = date => {
    setStartAt(date)
    const diffInDays = moment(endAt).diff(moment(startAt), "days")
    const newEndDate = moment(date).add(diffInDays, "days").set({ hour: 17, minute: 0, second: 0, millisecond: 0 }).toDate()
    setEndAt(newEndDate)
  }

  const onStartAtClose = () => {
    if (endDateRef.current) {
      endDateRef.current.setOpen(true);
    }
  }

  const minStartDate = (startAt ? moment(startAt) : moment()).subtract(14, 'days').toDate()

  return (
    <Modal show={show} onHide={onHide} size="lg" backdrop="static">
      <Modal.Header closeButton>
        <div>
          <h2 className="modal-title">{editable ? "Edit Leave" : "New Leave"}</h2>
        </div>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          <div className="form-group row">
            <label htmlFor="start-date" className="col-3 col-form-label">Start Date</label>
            <div className="col-7">
              <DatePicker
                id="start-date"
                wrapperClassName="date_picker full-width"
                className="form-control"
                selected={startAt}
                onChange={handleStartDateChange}
                onCalendarClose={onStartAtClose}
                showTimeSelect
                timeFormat="hh:mm aa"
                timeIntervals={15}
                timeCaption="time"
                minDate={minStartDate}
                dateFormat="dd/MM/yyyy, hh:mm aa" />
              <DatePickerWrapperStyles />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="end-date" className="col-3 col-form-label">End Date</label>
            <div className="col-7">
              <DatePicker
                id="end-date"
                ref={endDateRef}
                wrapperClassName="date_picker full-width"
                className="form-control"
                selected={endAt}
                onChange={date => setEndAt(date)}
                preventOpenOnFocus={false}
                showTimeSelect
                timeFormat="hh:mm aa"
                timeCaption="time"
                minDate={startAt || new Date()}
                dateFormat="dd/MM/yyyy, hh:mm aa" />
              <DatePickerWrapperStyles />
              {isEndAtError && <div className="err-msg">The end date must be greater than the start date</div>}
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="reason" className="col-3 col-form-label">Reason</label>
            <div className="col-7">
              <Select
                id="reason"
                name="leaveType"
                value={leaveType}
                onChange={value => setLeaveType(value)}
                options={leaveCategoriesOptions}
                placeholder="Select a reason" />
            </div>
          </div>
        </form>
        <hr />
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-red btn-lg"
          onClick={handleBlockOut}
          disabled={!leaveType || !startAt || !endAt}
          type="submit">
          {editable ? "Update Leave" : "Add Leave"}
        </button>
      </div>
    </Modal>
  )
}
export default UserBlockOutModal
