import {
  all, fork
} from "redux-saga/effects"

import { watchInitSaga } from "./sagas/init"

export default function* RootSaga() {
  yield all([
    fork(watchInitSaga)
  ])
}
