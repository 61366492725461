import React from "react"
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment"

export default function GearCalendarSelect({
  title, hrefPrefix, createState,
  editState, value, inputValue, setInputValue,
  labelWrapperClass, inputWrapperClass, errorMessage
}) {
  if (editState) {
    return (
      <div className="form-group row">
        <label className={`${labelWrapperClass || "col-3"} col-form-label`}>{title}</label>
        <div className={inputWrapperClass || "col-9"}>
          <Datetime
            dateFormat="DD/MM/YYYY"
            initialValue={inputValue}
            timeFormat={false}
            onChange={event => {
              if (moment.isMoment(event)) {
                setInputValue(event.format("DD/MM/YYYY"))
              } else {
                setInputValue(event)
              }
            }}
            value={value}
            closeOnSelect
            inputProps={{
              placeholder: "DD/MM/YYYY"
            }} />
          <div className="err-msg">{errorMessage}</div>
        </div>
      </div>
    )
  }
  if (createState) {
    return (
      <div className="form-group row">
        <label className={`${labelWrapperClass || "col-3"} col-form-label`}>{title}</label>
        <div className={inputWrapperClass || "col-9"}>
          <Datetime
            dateFormat="DD/MM/YYYY"
            timeFormat={false}
            onChange={event => {
              if (moment.isMoment(event)) {
                setInputValue(event.format("DD/MM/YYYY"))
              } else {
                setInputValue(event)
              }
            }}
            value={value}
            closeOnSelect
            inputProps={{
              placeholder: "DD/MM/YYYY"
            }} />
          <div className="err-msg">{errorMessage}</div>
        </div>
      </div>
    )
  }
  return (
    <div className="row mb-2">
      <div className="col-3">{title}</div>
      <div className="col-9">
        {hrefPrefix ? <a href={`${hrefPrefix}:${value}`}>{value}</a> : value}
      </div>
    </div>
  )
}