/* eslint-disable no-nested-ternary */
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { updateRsOrder } from "requesters/jobServiceRequester"
import {
  ON_HOLD,
  REGISTERED_SURVEYORS_USER,
  REGISTERED_SURVEYORS_MANAGER,
  CLIENT_DELAYED
} from "utils/constants"
import { roleCheck } from "utils/index"
import RegSurveyorsRow from "./RegSurveyorsRow"
import { getBaseUrl, getCurrentUser } from "../../reducers"

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function TableRow({
  jobService,
  index,
  regSurveyorOptions,
  fetchData,
  filtersApplied,
  readOnly
}) {
  const currentUser = useSelector(getCurrentUser)
  const currentTeamRole = currentUser.attributes.current_team_role
  const systemRole = currentUser.attributes.role
  const isRegisteredSurveyorsManager = roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)
  const disableDrag = !!((currentTeamRole.includes(REGISTERED_SURVEYORS_USER))) || filtersApplied

  return (
    <Draggable draggableId={jobService?.id} index={index} isDragDisabled={disableDrag}>
      {provided => (
        <>
          {(index === 10 && isRegisteredSurveyorsManager) && (
            <tr>
              <td
                colSpan="100%"
                style={{
                  backgroundColor: "#1C2E50",
                  height: "1px",
                  padding: "1px"
                }}>
              </td>
            </tr>
          )}
          <tr
            className={[CLIENT_DELAYED, ON_HOLD].includes(jobService.attributes.state) ? "greyout" : ""}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}>
            <RegSurveyorsRow
              key={jobService.id}
              disableDrag={disableDrag}
              jobService={jobService}
              regSurveyorOptions={regSurveyorOptions}
              fetchData={fetchData}
              filtersApplied={filtersApplied}
              readOnly={readOnly}
              index={index} />
          </tr>
        </>
      )}
    </Draggable>
  );
}

const RowList = memo(({
  jobServices,
  regSurveyorOptions,
  setJobServices,
  fetchData,
  filtersApplied,
  readOnly
}) => jobServices.map((jobService, index) => (
  <TableRow
    jobService={jobService}
    regSurveyorOptions={regSurveyorOptions}
    fetchData={fetchData}
    filtersApplied={filtersApplied}
    readOnly={readOnly}
    setJobServices={setJobServices}
    index={index}
    key={jobService?.id} />
)));

function DraggableRSRow({
  regSurveyorOptions,
  jobServices,
  setJobServices,
  fetchData,
  filtersApplied,
  readOnly
}) {
  const baseUrl = useSelector(getBaseUrl);
  const updateSortingIndex = (id, index) => {
    updateRsOrder(baseUrl, id, index)
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    updateSortingIndex(result.draggableId, result.destination.index)

    const rows = reorder(
      jobServices,
      result.source.index,
      result.destination.index
    );

    setJobServices(rows);
  }

  return (
    <DragDropContext onDragEnd={result => {
      onDragEnd(result)
    }}>
      <Droppable droppableId="list">
        {provided => (
          <tbody ref={provided.innerRef} {...provided.droppableProps}>
            <RowList
              regSurveyorOptions={regSurveyorOptions}
              jobServices={jobServices}
              setJobServices={setJobServices}
              fetchData={fetchData}
              filtersApplied={filtersApplied}
              readOnly={readOnly} />
            {provided.placeholder}
          </tbody>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DraggableRSRow
