import { combineReducers } from "redux"

import createReducer from "components/utils/createReducer"

import * as actionTypes from "../../actions/types"

const gearsFetchSuccess = (state, { data }) => ({ ...state, ...data.gear })

const byId = createReducer({})({
    [actionTypes.RESOURCE_FETCHED_SUCCESS]: gearsFetchSuccess,
})

export const getGearById = (state, equipmentId) => state.byId[equipmentId]
export const getGearBySlug = (state, slug) => _.find(state.byId, { attributes: { slug } })

export default combineReducers({
    byId
})