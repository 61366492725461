import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { createGlobalStyle } from "styled-components";
import ReactQuill from "react-quill";
import { newBooking } from "../../../../../requesters/jobRequester";
import { getLocations } from "../../../../../requesters/locationRequester";
import { getProduct } from "../../../../../requesters/productRequester";
import { getBaseUrl } from "../../../reducers";

const DatePickerWrapperStyles = createGlobalStyle`
.date_picker.full-width {
    width: 100%;
}
`;

const FollowUpbookingModal = ({
  show, onHide, job, jobService
}) => {
  const baseUrl = useSelector(getBaseUrl)

  const [currentJobAddress, setCurrentJobAddress] = useState(undefined)
  const [currentNotes, setCurrentNotes] = useState("")
  const [newBookingDate, setNewBookingdate] = useState(null)
  const [price, setPrice] = useState("")
  const [jobList, setJobList] = useState([])
  const [locationList, setLocationList] = useState([])
  const [products, setProducts] = useState([])
  const [product, setProduct] = useState(undefined)
  const [contacts, setContacts] = useState([])
  const [currentJsId, setCurrentJsId] = useState("")
  const [loading, setLoading] = useState(false)

  const getDefaultProduct = () => {
    const defaultProduct = products.find(({ attributes }) => attributes.code === jobService.attributes.code)
    if (defaultProduct && defaultProduct.attributes) {
      const { attributes: { name, workflow }, id } = defaultProduct
      return { label: name, value: id, workflow }
    }
    return undefined
  }

  const getDefaultBookingTime = () => {
    // Set default booking date to tomorrow at 7:00 AM
    const tomorrowAtSeven = new Date();
    tomorrowAtSeven.setDate(tomorrowAtSeven.getDate() + 1);
    tomorrowAtSeven.setHours(7, 0, 0, 0);
    return tomorrowAtSeven
  }

  const clearStates = () => {
    const defaultProduct = jobService ? getDefaultProduct() : undefined
    setCurrentNotes("");
    setNewBookingdate(null);
    setPrice("");
    setContacts([]);
    setLoading(false);
    setProduct(defaultProduct);
  }

  useEffect(() => {
    clearStates()
    setNewBookingdate(getDefaultBookingTime());
  }, [show]);

  const checkAddress = () => {
    if (job && jobService) {
      const { attributes: { jobAddress } } = job
      const { id, attributes: { locationId } } = jobService

      setCurrentJobAddress({ value: locationId, label: jobAddress })
      setCurrentJsId(id)
    }
  }

  const fetchProducts = () => {
    getProduct(baseUrl)
      .then(res => {
        if (res.status === 200) {
          setProducts(Object.values(_.get(res.response, "product", {})))
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  useEffect(() => {
    const productList = products?.map(product => ({ label: product.attributes.name, value: product.id, workflow: product.attributes.workflow }))
    setJobList((Object.values(_.orderBy(productList, "label", "asc"))))
  }, [products])

  const fetchLocations = (query, callback) => {
    getLocations(baseUrl, { by_full_address: query })
      .then(res => {
        if (res.status === 200) {
          const locations = Object.values(_.get(res.response, "location", {}))
          const addressList = locations?.map(location => ({ label: location.attributes.fullAddress, value: location.id }))
          setLocationList(addressList)
          callback(addressList)
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  const debouncedFetchLocations = useCallback(debounce(fetchLocations, 500), [])

  useEffect(() => {
    fetchProducts()
    fetchLocations()
    checkAddress()
  }, [])

  const onSaveHandler = () => {
    const params = {
      location_id: Number(currentJobAddress.value),
      product_id: Number(product.value),
      preferred_booking_date: newBookingDate,
      notes: currentNotes,
      cost: price || 0,
      contact_ids: contacts,
      current_job_service_id: currentJsId
    }
    setLoading(true)
    newBooking(baseUrl, params)
      .then(res => {
        if (res.status === 201) {
          window.location.href = `/jobs/${res?.response?.job_id}/job_services/${res?.response?.job_service_id}`
          setLoading(false)
          onHide()
        } else {
          onHide()
        }
      })
      .catch(() => {
        onHide()
        setLoading(false)
      })
  }

  const handleContacts = (e, contact) => {
    const tmpArray = [...contacts]
    if (e.target.checked) {
      tmpArray.push(parseInt(contact.id, 10))
    } else {
      _.pull(tmpArray, parseInt(contact.id, 10))
    }

    setContacts(tmpArray)
  }

  const handleProduct = e => {
    // set default booking date to today for workflows which don't need booking
    if (["3", "4", "7"].includes(e?.workflow)) {
      const date = new Date()
      setNewBookingdate(date)
    }
    setProduct(e)
  }

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <div>
          <h2 className="modal-title">New Job</h2>
          {/* <p>For ongoing jobs requiring additional site visit</p> */}
        </div>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <div className="mb-4">
          <strong>Job Details</strong>
        </div>
        <form>
          <div className="form-group row">
            <label className="col-3 col-form-label">Job Address*</label>
            <div className="col-7">
              <AsyncSelect
                name="locations"
                isClearable
                isSearchable
                defaultOptions={locationList}
                loadOptions={debouncedFetchLocations}
                onChange={e => setCurrentJobAddress(e)}
                options={locationList}
                placeholder="Select an Address"
                value={currentJobAddress} />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Job Service*</label>
            <div className="col-7">
              <Select
                name="jobServices"
                isClearable
                isSearchable
                onChange={e => handleProduct(e)}
                options={jobList}
                placeholder="Select a Job Service"
                value={product} />
            </div>
          </div>
          {!["3", "4", "7"].includes(product?.workflow) && (
            <div className="form-group row">
              <label className="col-3 col-form-label">Preferred Booking*</label>
              <div className="col-7">
                <div className="d-block">
                  <DatePicker
                    wrapperClassName="date_picker full-width"
                    required
                    selected={newBookingDate}
                    onChange={e => setNewBookingdate(e)}
                    showTimeSelect
                    closeOnScroll={e => e.target === document}
                    className="form-control"
                    dateFormat="dd MMM yyyy, ha" />
                  <DatePickerWrapperStyles />
                </div>
              </div>
            </div>
          )}

          <div className="form-group row">
            <label className="col-3 col-form-label">Price</label>
            <div className="col-7 input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">$</div>
              </div>
              <input
                className="form-control"
                inputMode="decimal"
                min={0}
                pattern="[0-9]*"
                placeholder="Optional"
                step="1"
                type="number"
                onChange={e => setPrice(e.target.value)}
                value={price} />
            </div>
          </div>
          <div className="form-group row" style={{ height: "150px" }}>
            <label className="col-3 col-form-label">Job Comments</label>
            <div className="col-7">
              <ReactQuill
                style={{ height: "100px" }}
                placeholder="Optional"
                type="text"
                onChange={value => setCurrentNotes(value)}
                value={currentNotes} />
            </div>
          </div>

          {!!jobService?.attributes?.contacts?.length && (
            <div className="form-group row mb-3">
              <label className="col-3">Contacts</label>
              <div className="col-8">
                {!!jobService?.attributes?.contacts?.length && jobService?.attributes?.contacts.map(contact => (
                  <div className="custom-control custom-checkbox" key={contact.id}>
                    <input
                      checked={contacts.includes(contact.id)}
                      className="custom-control-input"
                      id={contact.id}
                      type="checkbox"
                      onChange={e => handleContacts(e, contact)} />
                    <label className="custom-control-label" htmlFor={contact.id}>{`${contact.firstName} ${contact.lastName}`}</label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </form>
        <hr />
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-red btn-lg"
          onClick={onSaveHandler}
          disabled={!currentJobAddress?.value || !product?.value || !newBookingDate || loading}
          type="submit">
          Add Booking
          {loading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
        </button>
      </div>
    </Modal>
  )
}
export default FollowUpbookingModal
