import normalize from "json-api-normalizer"
import customAxios from "./customAxios";

export const getContacts = (baseUrl, filters) => {
  const path = `${baseUrl}/contacts`

  return customAxios.get(path, { params: filters })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const getContact = (baseUrl, contactId) => {
  const path = `${baseUrl}/contacts/${contactId}`
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const postContact = (baseUrl, formData) => {
  const path = `${baseUrl}/contacts`
  return customAxios.post(path, { contact: formData })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const putContact = (baseUrl, contactId, formData) => {
  const path = `${baseUrl}/contacts/${contactId}`

  return customAxios.put(path, { contact: formData })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const putXeroContactIds = (baseUrl, contactId, integrationContacts) => {
  const path = `${baseUrl}/contacts/${contactId}/update_xero_contact_ids`

  return customAxios.put(path, {
    integration_contacts: integrationContacts,
  })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }));
}

export const updateContactsForJob = (baseUrl, contacts) => {
  const path = `${baseUrl}/contacts/for-job`

  return customAxios.post(path, { contacts })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const deleteContactFromJob = (baseUrl, params) => {
  const path = `${baseUrl}/contacts/delete-from-job`

  return customAxios.delete(path, { params })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const deleteContact = (baseUrl, contactId) => {
  const path = `${baseUrl}/contacts/${contactId}`
  return customAxios.delete(path)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}
