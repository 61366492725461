import CustBreadcrumb from "components/CustBreadcrumb";
import React from "react";
import { Container } from "react-bootstrap";
import HolidayForm from "./HolidayForm";

export default function NewHoliday() {
  return (
    <Container fluid className="holiday-details-page pb-5">
      <CustBreadcrumb links={[["Settings", "/settings"], ["Manage Public Holidays", "/settings/manage-holidays"]]} active="New" />
      <HolidayForm />
    </Container>
  )
}
