import React, { useState } from "react"
import { useSelector } from "react-redux"
import { bookInFlaggedJob, updateJobServiceTransition } from "requesters/jobServiceRequester"
import { getBaseUrl } from "../../../../reducers"

const FlaggedJobService = ({
  previewOnly,
  flag,
  jobService,
  reloadData,
  comment,
  setComment
}) => {
  const {
    id: jobServiceId, attributes: {
      canBookInJobFlag, hasActiveBookInJob, activeFlag, boundaryCalculationRequired
    }
  } = jobService
  const { attributes: { flagTypeLabel, notes, uploadedFiles } } = flag?.data || activeFlag?.data

  const baseUrl = useSelector(getBaseUrl)
  const [disableBookInBtn, setDisableBookInBtn] = useState(false)

  const resolveFlag = () => {
    updateJobServiceTransition(baseUrl, jobServiceId, "resolve-flag", comment)
      .then(() => {
        reloadData()
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  const handleBookInFlaggedJob = () => {
    setDisableBookInBtn(true)
    bookInFlaggedJob(baseUrl, jobServiceId)
      .then(res => {
        const jobServiceId = Object.keys(res?.response?.jobService)[0];
        window.location.href = `/jobs/${jobService.attributes.jobId}/job_services/${jobServiceId}`
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  // const ResolveFlagHandler =  () => {
  //   let transition
  //   if (unflag) {
  //     switch (flagType) {
  //       case "additional_info_drafting":
  //         transition = "to-draft"
  //         break
  //       case "additional_info_reg_surveyor":
  //         transition = "to-review"
  //         break
  //       case "additional_info_amendments":
  //         transition = "to-draft"
  //         break
  //       default:
  //         transition = "additional-info"
  //         break
  //     }

  //     unflagJobservice(baseUrl, "resolve-flag", { id: flagid, resolved: unflag })
  //     .then(res => {
  //       if (res.status === 200) {
  //         if (comment) {
  //           saveComment(res.response.latest_activity_id, comment)
  //         }
  //         updateJobServiceTransition(baseUrl, jobServiceId, transition)
  //           .then(res => {
  //             if (res.status === 200) {
  //               reloadData()
  //             }
  //           })
  //           .catch(err => console.log(err))
  //         }
  //     })
  //     .catch(err => console.log(err))
  //   }
  // }

  // useEffect(() => {
  //   ResolveFlagHandler()
  // }, [unflag])

  return (
    <div className="timeline-item mt-2">
      <div className="card border-0 shadow-sm bg-coral">
        <div className="card-body p-4">
          <div className="row mx-2 align-items-center">
            <div className="col-sm-6">
              <strong>{flagTypeLabel}</strong>
              <p>{notes}</p>
              {uploadedFiles?.data?.map(file => (
                <div className="my-2" key={file.id}>
                  <a
                    href={file?.attributes?.filePath}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-block text-dark">
                    <i className="bi bi-file-earmark mr-2"></i>
                    {file?.attributes?.fileName}
                  </a>
                </div>
              ))}

              {!previewOnly && !hasActiveBookInJob && (
                <textarea
                  name="flag_notes"
                  className="form-control mr-5"
                  onChange={e => setComment(e.target.value)}
                  placeholder="Resolve Notes*"
                  rows="2"
                  value={comment}>
                </textarea>
              )}
            </div>
            {!previewOnly && !hasActiveBookInJob && (
              <div className="col-sm-6 text-right">
                <button
                  className="btn btn-outline-primary bg-light"
                  disabled={!comment}
                  onClick={resolveFlag}
                  type="button">
                  Resolve
                </button>
                {canBookInJobFlag && !boundaryCalculationRequired && (
                  <button
                    className="btn btn-outline-primary bg-light ml-3"
                    onClick={handleBookInFlaggedJob}
                    disabled={disableBookInBtn}
                    type="button">
                    Book In Job
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default FlaggedJobService
