import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Container, Spinner } from "react-bootstrap"
import { useQueryParam, StringParam } from "use-query-params"
import { Link } from "react-router-dom"

import SearchInput from "components/SearchInput"
import FilterDropdown from "components/FilterDropdown"
import Loader from "components/Loader"
import AnchorButton from "components/AnchorButton"
import { archiveHoliday, getPublicHolidays } from "requesters/publicHolidaysRequester"
import { roleCheck } from "utils/index"
import { OPERATIONS } from "utils/constants"
import { getCurrentRole, getCurrentUser, getBaseUrl } from "../../reducers"
import _ from "lodash"


export default function ManageHolidays() {
  const baseUrl = useSelector(getBaseUrl)
  const currentUser = useSelector(getCurrentUser)
  const currentRole = useSelector(getCurrentRole)
  const systemRole = currentUser.attributes.role

  const currentYear = new Date().getFullYear()
  const previousYear = currentYear - 1
  const [years, setYears] = useState([previousYear, currentYear].map(y => [y.toString(), y.toString()]))
  // const { teamRoles, userStatus } = useSelector(state => state.options)
  const [bySearch, setBySearch] = useQueryParam("by_search", StringParam)
  const [byYear, setByYear] = useQueryParam("by_year", StringParam)
  const [holidays, setHolidays] = useState({})
  const [requesting, setRequesting] = useState(false)
  const [firstTimeLoadPage, setFirstTimeLoadPage] = useState(true)

  if (!roleCheck(systemRole, currentRole, OPERATIONS)) window.location = "/"

  function triggerArchiveHoliday(holidayId, archiveState) {
    async function archive() {
      const { response, error } = await archiveHoliday(baseUrl, holidayId, archiveState)
      if (response) {
        const { publicHoliday } = response
        setHolidays({ ...holidays, publicHoliday: {...holidays.publicHoliday, ...publicHoliday}})
      }
      if (error) {
        console.error(error)
      }
    }
    archive()
  }

  function clearFilters() {
    setBySearch(undefined)
    setByYear(undefined)
  }

  useEffect(() => {
    if (_.isEmpty(byYear)) {
      setByYear(currentYear)
    }
  }, [])

  useEffect(() => {
    async function fetchData() {
      setRequesting(true)
      const { response, error } = await getPublicHolidays(baseUrl, {
        by_name: bySearch, by_year: byYear
      })
      if (response) {
        setHolidays(response)
        setYears(response.meta.meta.meta.yearList.map(y => [y.toString(), y.toString()]))
      }
      if (error) {
        console.error(error)
      }
      setRequesting(false)
    }

    if (firstTimeLoadPage && _.isEmpty(byYear)) {
      setFirstTimeLoadPage(false)
    } else {
      fetchData()
    }
  }, [baseUrl, bySearch, byYear])

  return (
    <Container fluid className="manage-holidays-page">
      <div className="d-flex justify-content-between align-items-center header-tabs-container">
        <div className="header-tabs">
          <h1>Manage Public Holidays</h1>
        </div>
        <Link
          className="btn btn-outlined-blue"
          to="/settings/manage-holidays/new"
          type="button">
          + New Holiday
        </Link>
      </div>
      <div className="table-listing-filters">
        <div className="btn-group mr-2">
          <SearchInput value={bySearch} setValue={setBySearch} placeholder="Search by name" />
        </div>
        <div className="btn-group mr-2">
          <FilterDropdown title="Year" filteredName={byYear} setFilter={setByYear} options={years} />
        </div>
        <div className="btn-group">
          <AnchorButton
            className="f-small"
            onClick={clearFilters}>
            Clear&#xa0;Filters
          </AnchorButton>
        </div>
      </div>
      {requesting || _.isEmpty(holidays) ? <Loader /> : <HolidayTable holidays={holidays.publicHoliday} ids={holidays?.meta?.meta?.meta?.ids} triggerArchiveHoliday={triggerArchiveHoliday} />}
    </Container>

  )
}

function HolidayTable({ holidays = [], ids, triggerArchiveHoliday }) {
  return (
    <div className="table-responsive">
      <table className="table table-listing table-hover">
        <thead>
          <tr>
            <th>Name</th>
            <th>Start At</th>
            <th>End At</th>
            <th style={{ width: "5%" }}>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {ids.map(id => (
            <HolidayRow key={`table-row-${id}`} holiday={holidays[id]} triggerArchiveHoliday={triggerArchiveHoliday} />
          ))}
        </tbody>
      </table>
      {holidays?.length === 0 && <div className="text-center">Table is Empty</div>}
    </div>
  )
}

function HolidayRow({ holiday, triggerArchiveHoliday}) {
  const {
    id, attributes
  } = holiday
  const archiveState = _.get(attributes, "archived", false)
  const [archiveRequested, setArchiveRequested] = useState(false)
  useEffect(() => {
    setArchiveRequested(false)
  }, [archiveState])
  
  if (attributes) {
    const statusText = attributes.archived ? "Archived" : "Active"
    const archiveButtonText = attributes.archived ? "Unarchive" : "Archive"
    return (
      <tr>
        <td>{attributes.name}</td>
        <td>{attributes.startAt}</td>
        <td>{attributes.endAt}</td>
        <td>{statusText}</td>
        <td className="text-right">
          <Link to={`/settings/manage-holidays/${id}`}>Detail</Link>
          <span className="text-muted mx-2">|</span>
          <AnchorButton
            className="w-4rem mr-2"
            onClick={() => {
              triggerArchiveHoliday(id, !attributes.archived)
              setArchiveRequested(true)
            }}>
            {archiveRequested ? (
              <Spinner
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true" />
            ) : archiveButtonText}
          </AnchorButton>
        </td>
      </tr>
    )
  }
  return null
}
