import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Container, Card } from "react-bootstrap"
import { roleCheck } from "utils/index"
import { OPERATIONS, ACCOUNTS } from "utils/constants"
import { getCurrentRole, getCurrentUser } from "../../reducers"

export default function Settings() {
  const currentUser = useSelector(getCurrentUser)
  const currentRole = useSelector(getCurrentRole)
  const systemRole = currentUser.attributes.role

  const rolesAllowed = () => {
    if (roleCheck(systemRole, currentRole, OPERATIONS) || roleCheck(systemRole, currentRole, ACCOUNTS)) {
      return true
    }
    return false
  }

  if (!rolesAllowed()) window.location = "/"

  return (
    <Container fluid>
      <h1>Settings</h1>
      <div className="today">
        <div className="key-to-do">
          <div className="row">
            {roleCheck(systemRole, currentRole, OPERATIONS) && (
              <Link to="/settings/manage-users" className="col-sm-1 col-md-6 col-lg-4">
                <Card className="settings-card">
                  <h3>Manage Users </h3>
                  <p className="mb-0"> Manage access of teams and users</p>
                </Card>
              </Link>
            )}
            <Link to="/settings/gears" className="col-sm-1 col-md-6 col-lg-4">
              <Card className="settings-card pr-2">
                <h3>Company Assets</h3>
                <p className="mb-0"> Manage company assets and service history</p>
              </Card>
            </Link>
            {roleCheck(systemRole, currentRole, OPERATIONS) && (
              <Link to="/settings/manage-holidays" className="col-sm-1 col-md-6 col-lg-4 mt-md-4 mt-lg-0">
                <Card className="settings-card">
                  <h3>Manage Public Holidays </h3>
                  <p className="mb-0"> Manage public holidays and other office closures</p>
                </Card>
              </Link>
            )}
          </div>
        </div>
      </div>
    </Container>
  )
}
