import React, { useState, useEffect } from "react"
import Select from "react-select"
import { useSelector } from "react-redux"
import { putOrganization, getOrganization } from "requesters/organizationRequester"
import EmailValidator from "email-validator"
import { Spinner } from "react-bootstrap"
import CustBreadcrumb from "components/CustBreadcrumb"
import { roleCheck } from "utils/index"
import {
  ACCOUNTS_MANAGER,
  OPERATION_MANAGER,
  REGISTERED_SURVEYORS_MANAGER,
} from "utils/constants"
import { useParams } from "react-router-dom"
import { getAllJobServices } from "requesters/jobServiceRequester"
import { toast } from "react-toastify"
import { getBaseUrl, getCurrentUser } from "../../reducers"
import { FIELD_VALIDATIONS_ORGANIZATION } from "./formErrors"
import JobServicesTable from "../JobServices/JobServicesTable"


export default function OrganizationEdit() {
  const baseUrl = useSelector(getBaseUrl)
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role
  const { id } = useParams()
  const { accountTypes } = useSelector(state => state.options)
  const accountTypeOptions = accountTypes.map(v => ({ value: v[0], label: v[1] }))
  const [jobServices, setJobServices] = useState([])

  // form fields
  const [name, setName] = useState("")
  const [address, setAddress] = useState("")
  const [accountType, setAccountType] = useState([])
  const [email, setEmail] = useState("")
  const [jobServicesCount, setJobServicesCount] = useState(0)

  // form events
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [validForm, setValidForm] = useState(true)
  const [loadingJobServices, setLoadingJobServices] = useState(false)
  const [page, setPage] = useState(1)
  const rolesAllowed = () => {
    if (roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) || roleCheck(systemRole, currentTeamRole, ACCOUNTS_MANAGER) || roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)) {
      return true
    }
    return false
  }

  const fetchData = () => {
    getOrganization(baseUrl, id)
      .then(res => {
        if (res.status === 200) {
          const organizationData = Object.values(_.get(res.response, "organization", {}))
          const organizationDetail = _.find(organizationData, item => item.id === id, {})
          setName(organizationDetail?.attributes?.name || "")
          setAddress(organizationDetail?.attributes?.address || "")
          setEmail(organizationDetail?.attributes?.email || "")
          setAccountType(organizationDetail?.attributes?.accountType)
          setJobServicesCount(organizationDetail?.attributes?.jobServicesCount)
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  const fetchJobServices = () => {
    setLoadingJobServices(true)
    getAllJobServices(baseUrl, {
      organization_id: id, page, per_page: 10, sort_by: "desc"
    })
      .then(res => {
        if (res.status === 200) {
          const jobs = _.get(res.response, "jobService", {})
          const jobList = Object.values(jobs)
          setJobServices(currentJobList => [...currentJobList, ...jobList])
        }
      })
      .then(() => {
        setLoadingJobServices(false)
        // scroll to bottom
        setTimeout(() => {
          const element = document.getElementById("bottom-of-page")
          element.scrollIntoView({ behavior: "smooth" })
        }, 100)
      })
      .catch(() => {
        setLoadingJobServices(false)
      })
  }


  useEffect(() => {
    if (!rolesAllowed()) {
      window.location = "/"
    } else {
      fetchData()
      fetchJobServices()
    }
  }, [id])

  function handleSubmit() {
    const organization = {
      email,
      name,
      address,
      account_type: accountType
    }

    setDisableSubmit(true)
    putOrganization(baseUrl, id, organization)
      .then(res => {
        setDisableSubmit(false)
        if (res.status === 200) {
          toast.success(
            <div>
              <span role="img" aria-label="success-icon">✅</span>
              <span className="ml-2">Save Organisation Successfully!</span>
            </div>,
            {
              toastId: "rails-flash",
              containerId: "main"
            }
          )
        }
      })
      .then(() => setDisableSubmit(false))
      .catch(() => setDisableSubmit(false))
  }

  function validateForm() {
    let validFields = true
    const fields = [
      {
        email,
        name,
        address,
        accountType
      }
    ]

    _.map(fields[0], (value, key) => {
      switch (key) {
        case "email":
          validFields = EmailValidator.validate(value)
          break
        default:
          if (_.isEmpty(value)) {
            validFields = false
          }
      }
    })

    if (validFields) {
      handleSubmit()
    }
    setValidForm(false)
  }
  function errorMessage(attribute, value) {
    if (!validForm) {
      const message = _.find(FIELD_VALIDATIONS_ORGANIZATION, ["field", attribute])
      let validField = true
      switch (attribute) {
        case "email":
          validField = EmailValidator.validate(email)
          break
        default:
          if (_.isEmpty(value)) {
            validField = false
          }
      }
      if (!validField) {
        return (
          <div className="err-msg">
            {message.message}
          </div>
        )
      }
      return null
    }
    return null
  }

  useEffect(() => {
    if (page > 1) {
      fetchJobServices()
    }
  }, [page])

  return (
    <div className="gear-details-page pb-5 container-fluid">
      <CustBreadcrumb links={[["Organisations", "/contacts?tab=organizations"]]} active="Edit" />
      <h2 className="mb-4">Edit Organisation</h2>
      <div className="row">
        <div className="col-md-8">
          <div className="card border-0 shadow-sm">
            <div className="card-body p-4">
              <div className="form-group row">
                <label className="col-3 col-form-label">Email*</label>
                <div className="col-9">
                  <input
                    name="email"
                    className="form-control"
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={e => setEmail(e.target.value)} />
                  {errorMessage("email", email)}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">Name*</label>
                <div className="col-9">
                  <input
                    name="name"
                    className="form-control"
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={e => setName(e.target.value)} />
                  {errorMessage("name", name)}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">Address*</label>
                <div className="col-9">
                  <input
                    name="address"
                    className="form-control"
                    type="text"
                    placeholder="Address"
                    value={address}
                    onChange={e => setAddress(e.target.value)} />
                  {errorMessage("address", address)}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">Account Type *</label>
                <div className="col-9">
                  <Select
                    name="account_type"
                    placeholder="Select"
                    value={_.filter(accountTypeOptions, ["value", accountType])}
                    options={accountTypeOptions}
                    onChange={e => setAccountType(_.get(e, "value", ""))} />
                  {errorMessage("account_type", accountType)}
                </div>
              </div>
              <div className="mt-4">
                <button
                  className="btn btn-red"
                  onClick={validateForm}
                  disabled={disableSubmit}
                  type="submit">
                  Save Organisation
                  {disableSubmit && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {jobServicesCount > 0 && jobServices.length === 0 && (
        <div className="d-flex justify-content-center mt-4">
          Load Related Job Services...
        </div>
      )}
      {jobServices.length > 0 && (
        <div className="card border-0 mt-4">
          <div className="card-body p-4">
            <JobServicesTable jobServiceList={jobServices} />
            {(jobServices.length > 0 && jobServices.length < jobServicesCount) && (
              <div className="d-flex justify-content-center mt-4">
                <button
                  disabled={loadingJobServices}
                  className="btn btn-red"
                  type="button"
                  onClick={() => {
                    setPage(page + 1)
                  }}>
                  Load More
                  {loadingJobServices && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      <span id="bottom-of-page" />
    </div>
  )
}
