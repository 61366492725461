import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert"
import { cancelJobService } from "../../../../../requesters/jobServiceRequester"
import { getBaseUrl } from "../../../reducers";


const CancelBookingModal = ({
  show, onHide, job, reloadData, jobService
}) => {
  const baseUrl = useSelector(getBaseUrl)

  const [currentJobAddress, setCurrentJobAddress] = useState(undefined)
  const [currentNotes, setCurrentNotes] = useState("")
  const [refundType, setRefundType] = useState("partial")
  const [cancellationFee, setCancellationFee] = useState(0)
  const [currentJsId, setCurrentJsId] = useState("")
  const [loading, setLoading] = useState(false)
  const [showMessage, setShowMessage] = useState(false)

  useEffect(() => {
    setCurrentNotes("")
    setCancellationFee(0)
  }, [show])

  const checkAddress = () => {
    if (job && jobService) {
      const { attributes: { jobAddress } } = job
      const { id, attributes: { locationId } } = jobService

      setCurrentJobAddress({ value: locationId, label: jobAddress })
      setCurrentJsId(id)
    }
  }

  useEffect(() => {
    checkAddress()
  }, [])

  const onSaveHandler = () => {
    const params = {
      comment: currentNotes,
      refund_type: refundType,
      cancellation_fee: cancellationFee
    }

    cancelJobService(baseUrl, currentJsId, params)
      .then(() => {
        reloadData()
        onHide()
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <div>
          <h2 className="modal-title">Cancel Job</h2>
          {/* <p>For ongoing jobs requiring additional site visit</p> */}
        </div>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <div className="mb-4">
          <strong>Job Details</strong>
        </div>
        <form>
          <div className="form-group row">
            <label className="col-3 col-form-label">Job Address*</label>
            <div className="col-7">
              <input
                className="form-control"
                disabled
                type="text"
                value={currentJobAddress?.label} />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Job Service*</label>
            <div className="col-7">
              <input
                className="form-control"
                disabled
                type="text"
                value={jobService.attributes.code} />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Refund Type</label>
            <div className="col-7">
              <select name="refund_type" className="form-control custom-select" value={refundType} onChange={e => setRefundType(e.target.value)}>
                <option key="partial" value="partial">Partial Refund</option>
                <option key="full" value="full">Full Refund</option>
                <option key="none" value="none">No Refund</option>
              </select>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Cancellation Fee</label>
            <div className="col-7">
              <input
                className="form-control"
                placeholder="Optional"
                type="decimal"
                onChange={e => setCancellationFee(e.target.value)}
                value={cancellationFee} />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Comments*</label>
            <div className="col-7">
              <input
                className="form-control"
                type="text"
                onChange={e => setCurrentNotes(e.target.value)}
                value={currentNotes} />
            </div>
          </div>
        </form>
        <hr />
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-red btn-lg"
          onClick={() => {
            setLoading(true)
            setShowMessage(true)
          }}
          disabled={!currentNotes || loading}
          type="submit">
          Cancel Booking
          {loading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
        </button>
      </div>

      <SweetAlert
        show={showMessage}
        type="warning"
        title="Are you sure?"
        style={{ width: "600px" }}
        closeOnClickOutside
        allowEscape={false}
        showCloseButton
        onCancel={() => {
          setShowMessage(false)
          setLoading(false)
        }}
        confirmBtnText="Proceed"
        onConfirm={onSaveHandler}>
        Cancelling with comment:
        <br />
        <strong>{currentNotes}</strong>
      </SweetAlert>
    </Modal>
  )
}
export default CancelBookingModal
