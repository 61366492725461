import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import Select from "react-select"
import { toast } from "react-toastify"
import { useSelector } from "react-redux";
import { linkInvoiceFromXero } from "../../../../../requesters/jobServiceRequester";
import { getBaseUrl } from "../../../reducers";

const AutoLinkInvoiceModal = ({
  show, onHide, jobService, reloadData, onSuccess, onFail
}) => {
  const invoiceTypes = [
    { value: "deposit", label: "Deposit" },
    { value: "final", label: "Final" },
    { value: "other", label: "Other" }
  ]
  const {
    id, attributes: {
      xeroAccounts
    }
  } = jobService;
  const [xeroAccount, setXeroAccount] = useState(undefined)
  const [xeroInvoiceId, setXeroInvoiceId] = useState("")
  const [invoiceType, setInvoiceType] = useState(undefined)
  const [requesting, setRequesting] = useState(false)
  const baseUrl = useSelector(getBaseUrl)

  const onLinkInvoice = () => {
    setRequesting(true)
    const payload = {
      xero_account: xeroAccount.value,
      invoice_type: invoiceType.value,
      xero_invoice_id: xeroInvoiceId
    }
    linkInvoiceFromXero(baseUrl, id, payload)
    .then(res => {
      if (res.status === 200) {
        if (res.response.success) {
          reloadData()
          onSuccess()
        } else {
          toast.error(
            <span className="ml-2">{res.response.error_message}</span>,
            { toastId: "container-inner", containerId: "main" }
          )
          onFail()
        }
        onHide()
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
    .finally(() => setRequesting(false))
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <h2 className="modal-title">LINK INVOICE</h2>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          <div className="form-group row mb-3">
            <label className="col-3">Xero Account</label>
            <div className="col-8">
              <Select
                id="xero-account"
                name="xero_account"
                disabled={requesting}
                placeholder="Choose one account"
                onChange={e => setXeroAccount(e)}
                options={xeroAccounts}
                value={xeroAccount} />
            </div>
          </div>
          <div className="form-group row mb-3">
            <label className="col-3">Invoice Type</label>
            <div className="col-8">
              <Select
                id="invoice-type"
                name="invoice_type"
                disabled={requesting}
                placeholder="Choose invoice type"
                onChange={e => setInvoiceType(e)}
                options={invoiceTypes}
                value={invoiceType} />
            </div>
          </div>
          <div className="form-group row mb-3">
            <label className="col-3">Xero Invoice ID</label>
            <div className="col-8">
              <input
                id="xero-invoice-id"
                name="xero_invoice_id"
                className="form-control"
                type="text"
                placeholder="Xero Invoice ID or Invoice Number"
                value={xeroInvoiceId}
                onChange={e => setXeroInvoiceId(e.target.value)} />
            </div>
          </div>
        </form>
        <hr />
        <div className="modal-footer d-flex justify-content-end px-0 py-3">
          <button
            className="btn btn-red btn-lg"
            onClick={onLinkInvoice}
            disabled={requesting || !xeroAccount || !xeroInvoiceId}
            type="submit">
            {requesting ? <Spinner animation="border" role="status" size="sm" /> : "Link" }
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default AutoLinkInvoiceModal