import React, { Fragment } from "react"
import { useSelector } from "react-redux"


import {
 DRAFTING, OPERATIONS, RECEPTION, SALES, ACCOUNTS, REGISTERED_SURVEYORS, SURVEYORS, ADMIN
} from "utils/constants";
import { getCurrentUser } from "../../reducers"


import DashboardForSales from "./DashboardForSales";
import DashboardForDrafting from "./DraftingDashboard/DashboardForDrafting";
import DashboardForReception from "./DashboardForReception";
import DashboardForOperations from "./DashboardForOperations";
import DashboardForAdmin from "./DashboardForAdmin";
import DashboardForAccounts from "./DashboardForAccounts";
import DashboardForSurveyor from "./SurveyorDashboard/DashboardForSurveyor";
import DashboardForRegisteredSurveyor from "./DashboardForRegisteredSurveyor";
import DashboardNoTeam from "./DashboardNoTeam";


export default function Homepage() {
  const currentUser = useSelector(getCurrentUser)


  const defaultRole = currentUser.attributes.default_role
  const defaultTeam = currentUser.attributes.default_team
  const showNumber = number => (number === null ? "-" : number)
  return (
    <Fragment>
      {!defaultRole ? (<DashboardNoTeam currentUser={currentUser} />)
      : (
        <>
          {(defaultRole.includes(DRAFTING)) && <DashboardForDrafting currentUser={currentUser} showNumber={showNumber} />}
          {(defaultRole.includes(OPERATIONS)) && <DashboardForOperations currentUser={currentUser} showNumber={showNumber} />}
          {(defaultRole.includes(RECEPTION)) && <DashboardForReception currentUser={currentUser} showNumber={showNumber} />}
          {(defaultRole.includes(SALES)) && <DashboardForSales currentUser={currentUser} showNumber={showNumber} />}
          {(defaultTeam === SURVEYORS) && <DashboardForSurveyor currentUser={currentUser} showNumber={showNumber} />}
          {(defaultRole.includes(ACCOUNTS)) && <DashboardForAccounts currentUser={currentUser} showNumber={showNumber} />}
          {(defaultRole.includes(ADMIN)) && <DashboardForAdmin currentUser={currentUser} showNumber={showNumber} />}
          {(defaultTeam.includes(REGISTERED_SURVEYORS)) && <DashboardForRegisteredSurveyor currentUser={currentUser} showNumber={showNumber} />}
        </>
        )
      }
    </Fragment>
  )
}
