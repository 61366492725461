import React, { useEffect, useState } from "react"
import { getJobServiceByAddress, zipAllSignedPlans, checkZipAllSignedPlans } from "requesters/jobServiceRequester"
import { useSelector } from "react-redux"
import Loader from "components/Loader"
import { get, isEmpty } from "lodash"
import { roleCheck } from "utils/index"
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { getBaseUrl, getCurrentRole, getCurrentUser } from "../../../../reducers"
import HistoryTable from "./HistoryTable"
import { DEFAULT_PER_PAGE, OPERATIONS } from "../../../../../../utils/constants"

export default function History({ jobService, reloadData }) {
  const baseUrl = useSelector(getBaseUrl)
  const [jobServices, setJobServices] = useState([])
  const [requesting, setRequesting] = useState(true)
  const [hasNextPage, setHasNextPage] = useState(true)
  const [zipAllSignedPlansLoading, setZipAllSignedPlansLoading] = useState(false)
  const [canZipAllFile, setCanZipAllFile] = useState(true)
  const [currentPage, setCurrentPage] = useState(1);
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentRole = useSelector(getCurrentRole)
  const isAdminOrOperations = roleCheck(systemRole, currentRole, OPERATIONS)

  const fetchData = () => {
    setRequesting(true)
    getJobServiceByAddress(baseUrl, jobService.id, currentPage, DEFAULT_PER_PAGE)
      .then(res => {
        if (res.status === 200) {
          const jobServicesData = get(res.response, "jobService", {})
          let listOfJobService = Object.values(jobServicesData)
          const additionalInfo = res.response.meta?.additional_info
          const hasNextPageValue = additionalInfo && additionalInfo?.meta?.hasNextPage
          if (!isEmpty(additionalInfo)) {
            listOfJobService = additionalInfo.meta?.ids.map(id => jobServicesData[id])
          }
          const newJobList = currentPage > 1 ? [...jobServices, ...listOfJobService] : listOfJobService
          setHasNextPage(hasNextPageValue)
          setJobServices(newJobList)
          setRequesting(false)
        }
        return checkZipAllSignedPlans(baseUrl, jobService.id)
      })
      .then(res => {
        if (res.status === 200) {
          setCanZipAllFile(res.response.can_zip_all_file)
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  const handleZipAllSignedPlans = () => {
    setZipAllSignedPlansLoading(true)
    zipAllSignedPlans(baseUrl, jobService.id)
      .then(res => {
        if (res.status === 200) {
          toast.info(
            <div>
              <span><i className="fa fa-exclamation mr-2" /></span>
              <span className="ml-2">Zip All file successfully, please wait and refresh after a few minutes...</span>
            </div>,
            {
              toastId: "container-inner",
              containerId: "main"
            }
          )
          reloadData()
        }
        setZipAllSignedPlansLoading(false)
      })
      .catch(() => {
        setZipAllSignedPlansLoading(false)
      })
  }

  const loadMore = () => {
    setCurrentPage(currentPage + 1)
  }

  useEffect(() => {
    fetchData()
  }, [currentPage])

  return (
    <div className="timeline-item">
      <div className="card border-0 shadow-sm timeline-card">
        <div className="card-body p-lg-5">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="card-title">History</h5>
            {isAdminOrOperations && (
              <div>
                <button disabled={requesting || zipAllSignedPlansLoading || !canZipAllFile} className="btn btn-outline-primary" type="button" onClick={() => handleZipAllSignedPlans()}>
                  Zip All Signed Plans
                  {zipAllSignedPlansLoading && <Spinner className="ml-2" animation="border" role="status" size="sm" />}
                </button>
                {!canZipAllFile && (
                  <p style={{
                    fontSize: "15px", color: "red", fontStyle: "italic"
                  }}>
                    No files to zip
                  </p>
                )}
              </div>
            )}
          </div>
          <HistoryTable jobServices={jobServices} jobService={jobService} />
          {hasNextPage && !requesting && (
            <div className="d-flex justify-content-center align-items-center m-4">
              <button
                type="button"
                onClick={loadMore}
                className="btn btn-lg btn-primary"
                disabled={requesting}>
                Load More
              </button>
            </div>
          )}
          {requesting && <Loader />}
        </div>
      </div>
    </div>
  )
}
