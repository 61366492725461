import { combineReducers } from "redux"

import createReducer from "components/utils/createReducer"

import * as actionTypes from "../../actions/types"

const gearHistoryRecordsFetchSuccess = (state, { data }) => ({ ...state, ...data.gear_history_record })

const byId = createReducer({})({
    [actionTypes.RESOURCE_FETCHED_SUCCESS]: gearHistoryRecordsFetchSuccess,
})
export const getGearHistoryRecordById = (state, recordId) => state.byId[recordId]
export default combineReducers({
    byId
})