import React from "react";
import { Modal, Table } from "react-bootstrap";

const AdditionalLogModal = ({ show, onHide, additionalLog }) => (
  <Modal show={show} onHide={onHide} size="lg" centered>
    <Modal.Header closeButton>
      <h2 className="modal-title">ADDITIONAL LOG</h2>
    </Modal.Header>
    <Modal.Body className="px-md-5" style={{ maxHeight: "calc(100vh - 300px)", overflowY: "scroll" }}>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Date</th>
            <th>User / (IPv6)</th>
            <th>Action</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
          {additionalLog
            && additionalLog.map((log, i) => {
              const data = log.data || {}
              return (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={i}>
                  <td>{data.timestamp}</td>
                  <td>
                    {`${data.userName} / ${data.ipAddress}`}
                  </td>
                  <td>{data.event}</td>
                  <td className="text-break">{data.label}</td>
                </tr>
              )
            })}
        </tbody>
      </Table>
    </Modal.Body>
    <Modal.Footer>
      <button type="button" className="btn btn-red btn-lg" onClick={onHide}>
        Close
      </button>
    </Modal.Footer>
  </Modal>
);

export default AdditionalLogModal;
