import customAxios from "./customAxios";
import normalize from "json-api-normalizer"

export const getFlagByJobService = (baseUrl, jobServiceId) => {
    const path = `${baseUrl}/flags`
    return customAxios.get(path, { params: { job_service_id: jobServiceId } })
        .then(({ data, status }) => ({ response: normalize(data), status }))
        .catch(error => ({ error }))
}

export const postFlag = (baseUrl, flaggedData) => {
    const path = `${baseUrl}/flags`
    return customAxios.post(path, flaggedData)
        .then(({ data, status }) => ({ response: data.json, status }))
        .catch(error => ({ error }))
}

export const unflagJobservice = (baseUrl, transition, params) => {
    const path = `${baseUrl}/flags/${transition}`
    return customAxios.put(path, params)
        .then(({ data, status }) => ({ response: data.json, status }))
        .catch(error => ({ error }))
}

export const getFlagTypeOptionsByJobService = (baseUrl, jobServiceId) => {
    const path = `${baseUrl}/job_services/${jobServiceId}/flags/flag-type-options`
    return customAxios.get(path)
        .then(({ data, status }) => ({ response: data, status }))
        .catch(error => ({ error }))
}

export const uploadFlaggedFiles = (baseUrl, params) => {
    const path = `${baseUrl}/flags/upload-flagged-files`
    return customAxios.put(path, params)
        .then(({ data, status }) => ({ response: data.json, status }))
        .catch(error => ({ error }))
}
