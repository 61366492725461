import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert"
import { updateJobServicePrice } from "../../../../../requesters/jobServiceRequester"
import { getBaseUrl } from "../../../reducers";

const UpdateJobServicePriceModal = ({
  show, onHide, reloadData, jobService
}) => {
  const baseUrl = useSelector(getBaseUrl)
  const [requesting, setRequesting] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [currentReason, setCurrentReason] = useState("")
  const [newPrice, setNewPrice] = useState("")

  const onClearStates = () => {
    setNewPrice("")
    setCurrentReason("")
  }

  const onConfirm = () => {
    setShowConfirmation(false)
    setRequesting(true)
    updateJobServicePrice(baseUrl, jobService.id, { amount: newPrice, reason: currentReason })
    .then(res => {
      if (res.status === 200 && res.response.success) {
        reloadData()
        onHide()
        onClearStates()
      }
    })
    .catch(({ err }) => console.log(err))
    .finally(() => setRequesting(false))
  }

  const canSubmit = currentReason?.trim() && newPrice?.trim() && !requesting

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <div>
          <h2 className="modal-title">Update Job Price</h2>
        </div>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          <div className="form-group row">
            <label className="col-3 col-form-label"> Current Job Amount </label>
            <div className="col-7">
              <input
                disabled
                className="form-control"
                inputMode="decimal"
                pattern="[0-9]*"
                type="number"
                value={jobService?.attributes?.cost} />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label"> Docket Amounts </label>
            <div className="col-7">
              <input
                disabled
                className="form-control"
                inputMode="decimal"
                pattern="[0-9]*"
                type="number"
                value={jobService?.attributes?.docketAmounts} />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">New Job Amount*</label>
            <div className="col-7">
              <input
                className="form-control"
                inputMode="decimal"
                min={0}
                pattern="[0-9]*"
                step="1"
                type="number"
                onChange={e => setNewPrice(e.target.value)}
                value={newPrice} />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Reason for Change*</label>
            <div className="col-7">
              <input
                className="form-control"
                type="text"
                onChange={e => setCurrentReason(e.target.value)}
                value={currentReason} />
            </div>
          </div>
        </form>
        <hr />
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-primary btn-lg"
          onClick={() => setShowConfirmation(true)}
          disabled={!canSubmit}
          type="submit">
          Apply new price
          {requesting && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
        </button>
      </div>

      <SweetAlert
        show={showConfirmation}
        type="info"
        title="Are you sure?"
        style={{ width: "600px" }}
        closeOnClickOutside
        allowEscape={false}
        showCloseButton
        onCancel={() => setShowConfirmation(false)}
        confirmBtnText="Update"
        onConfirm={onConfirm}>
        Apply new price with Reason:
        <br />
        <strong>{currentReason}</strong>
      </SweetAlert>
    </Modal>
  )
}

export default UpdateJobServicePriceModal
