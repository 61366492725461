import React, { useEffect, useState } from "react"
import { connect, useSelector } from "react-redux"
import { Container } from "react-bootstrap"

import { getJobServicesRegSurveyors } from "requesters/jobServiceRequester"
import { getFilteredProducts } from "requesters/productRequester"
import { getRegisterdSurveyors } from "requesters/userRequester"
import { roleCheck } from "utils/index"
import {
  REGISTERED_SURVEYORS_MANAGER, REVIEW_REPORT, REGISTERED_SURVEYORS_USER
} from "utils/constants"

import SearchInput from "components/SearchInput"
import FilterDropdown from "components/FilterDropdown"
import Loader from "components/Loader"
import AnchorButton from "components/AnchorButton"
import _ from "lodash"
import RegSurveyorsTable from "./RegSurveyorsTable"

import { getBaseUrl, getCurrentRole, getCurrentUser } from "../../reducers"

function RegisteredSurveyors() {
  const baseUrl = useSelector(getBaseUrl)
  const currentRole = useSelector(getCurrentRole)
  const currentUser = useSelector(getCurrentUser)
  const { attributes: { role } } = currentUser
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role
  const readOnly = !((/admin/i.test(role) || currentTeamRole.includes(REGISTERED_SURVEYORS_MANAGER)))
  const [requesting, setRequesting] = useState(false)
  const [jobServices, setJobServices] = useState([])
  const [regSurveyorOptions, setRegSurveyorOptions] = useState([])
  const [jobTypes, setJobTypes] = useState([])
  const jobStatusOptions = [["To Review", "to_review"], ["Additional Info", "additional_info"], ["Report Required", REVIEW_REPORT]]

  const rolesAllowed = () => {
    if (roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)
      || roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_USER)) {
      return true
    }
    return false
  }

  if (!rolesAllowed()) {
    window.location.href = "/"
  }

  const initialFilters = {
    by_status: undefined,
    by_registered_surveyor: (readOnly ? currentUser.id : undefined),
    by_job_type: undefined,
    by_keyword: undefined
  }

  const [filters, setFilters] = useState(initialFilters)
  const [isFilteredByDropDown, setIsFilteredByDropDown] = useState(false)
  const byStatusName = _.get(jobStatusOptions, `${_.findIndex(jobStatusOptions, status => status[1] === filters.by_status)}.0`)
  const byJobTypeName = _.get(jobTypes, `${_.findIndex(jobTypes, type => type[1] === filters.by_job_type)}.0`)
  const byRegSurveyorName = _.get(regSurveyorOptions, `${_.findIndex(regSurveyorOptions, option => option[1] === Number(filters.by_registered_surveyor))}.0`)
  const filtersApplied = Object.entries(filters).some(([key, val]) => 
    key !== 'by_registered_surveyor' && val !== initialFilters[key]
  );

  const clearFilters = () => {
    setFilters(initialFilters)
    setIsFilteredByDropDown(true)
  }

  const setByStatus = val => {
    setFilters({ ...filters, by_status: val })
    setIsFilteredByDropDown(true)
  }

  const setByJobType = val => {
    setFilters({ ...filters, by_job_type: val })
    setIsFilteredByDropDown(true)
  }

  const setBySearch = val => {
    setFilters({ ...filters, by_keyword: val })
  }

  const setByRegSurveyor = val => {
    setFilters({ ...filters, by_registered_surveyor: val })
    setIsFilteredByDropDown(true)
  }

  useEffect(() => {
    setFilters({ ...filters })
  }, [])

  const fetchData = () => {
    setRequesting(true)
    getJobServicesRegSurveyors(baseUrl, filters)
      .then(res => {
        if (res.status === 200) {
          const jobs = _.get(res, "response", {})
          setJobServices(jobs)
        }
        setRequesting(false)
      })
      .catch(() => {
        setRequesting(false)
      })
  }

  useEffect(() => {
    getFilteredProducts(baseUrl, "registered_surveyors")
      .then(res => {
        if (res.status === 200) {
          const products = Object.values(_.get(res.response, "product", {}))
          const allJobTypes = products.map(js => ([js.attributes.name, js.id]))
          setJobTypes(allJobTypes)
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }, [])

  const getRegisterdSurveyorsHandler = () => {
    getRegisterdSurveyors(baseUrl)
      .then(res => {
        if (res.status === 200) {
          const newData = res.response.map(item => ([`${item[0]} ${item[1]}`, item[2]]))
          setRegSurveyorOptions(newData)
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  const handleSubmit = e => {
    e.preventDefault()
    fetchData()
  }

  useEffect(() => {
    fetchData()
    getRegisterdSurveyorsHandler()
    setIsFilteredByDropDown(false)
  }, [isFilteredByDropDown, currentRole])

  return (
    <Container fluid className="">
      <div className="container-inner">
        <div className="d-flex justify-content-between align-items-center header-tabs-container">
          <div className="header-tabs">
            <h1>REGISTERED SURVEYORS</h1>
          </div>
        </div>
        <div className="table-listing-filters d-md-flex justify-content-between">
          <div>
            <div className="btn-group mr-2">
              <SearchInput value={filters.by_keyword} setValue={setBySearch} handleSubmit={handleSubmit} disableSubmit={requesting} />
            </div>
            <div className="btn-group mr-2">
              <FilterDropdown title="Status" filteredName={byStatusName} setFilter={setByStatus} options={jobStatusOptions} />
            </div>
            <div className="btn-group mr-2">
              <FilterDropdown title="Job Service" filteredName={byJobTypeName} setFilter={setByJobType} options={jobTypes} />
            </div>
            {
              !readOnly && (
                <div className="btn-group mr-2">
                  <FilterDropdown title="Registerd Surveyors" filteredName={byRegSurveyorName} setFilter={setByRegSurveyor} options={regSurveyorOptions} />
                </div>
              )}
            <div className="btn-group">
              <AnchorButton
                className="f-small"
                onClick={clearFilters}>
                Clear Filters
              </AnchorButton>
            </div>
          </div>
        </div>
        {requesting ? <Loader />
          : (
            <JobServiceByRSTables
              jobServices={jobServices}
              setJobServices={setJobServices}
              regSurveyorOptions={regSurveyorOptions}
              fetchData={fetchData}
              filtersApplied={filtersApplied}
              readOnly={readOnly} />
          )}
      </div>
    </Container>
  )
}

const JobServiceByRSTables = ({
  readOnly,
  filtersApplied,
  fetchData,
  regSurveyorOptions,
  jobServices,
  setJobServices
}) => {
  const regiteredSurveyors = _.isEmpty(jobServices) ? [] : Object.keys(jobServices).sort()
  const unassignedIndex = regiteredSurveyors.indexOf("Unassigned")
  if (unassignedIndex !== -1) {
    regiteredSurveyors.unshift(regiteredSurveyors.splice(unassignedIndex, 1)[0]);
  }
  return (
    <>
      {!!regiteredSurveyors?.length && regiteredSurveyors.map(rs => {
        const jobServicesRegSurv = jobServices[rs]
        return (
          (!!jobServicesRegSurv?.length && (
            <div key={`date-${rs}`}>
              <h3 className="text-dark pt-5">{rs}</h3>
              <RegSurveyorsTable
                jobServices={jobServicesRegSurv}
                setJobServices={setJobServices}
                regSurveyorOptions={regSurveyorOptions}
                fetchData={fetchData}
                filtersApplied={filtersApplied}
                readOnly={readOnly} />
            </div>
          ))
        )
      })}
    </>
  )
}

const mapStateToProps = state => ({
  currentRole: getCurrentRole(state)
})

export default connect(mapStateToProps, {})(RegisteredSurveyors)
