import React from "react"
import JobRow from "./JobRow"

export default function JobsTable({ jobs }) {
  return (
    <div className="table-responsive">
      <table className="table table-listing table-hover">
        <thead>
          <tr>
            <th>Job No.</th>
            <th>Client Name</th>
            <th>Contact</th>
            <th>Job Address</th>
            <th>Job Service</th>
            <th>Status</th>
            <th>Surveyor</th>
            <th>Time</th>
            <th>Notes</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {jobs.map(job => <JobRow key={`table-row-${job.id}`} job={job} />)}
        </tbody>
      </table>
      {jobs.length === 0 && <div>Table is Empty</div>}
    </div>
  )
}
