import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";

const UpdateDocketAmountModal = ({
  show, onHide, onUpdateTotalAmount, docket, loading
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [currentReason, setCurrentReason] = useState("")
  const [newPrice, setNewPrice] = useState("")

  const clearStates = () => {
    setShowConfirmation(false)
    setCurrentReason("")
    setNewPrice("")
  }

  const onConfirm = () => {
    onUpdateTotalAmount(newPrice, currentReason)
    onHide()
    clearStates()
  }

  const canSubmit = currentReason?.trim() && newPrice?.trim() && !loading

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <div>
          <h2 className="modal-title">
            Update Docket
            {` ${docket.number} `}
            Amount
          </h2>
        </div>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          <div className="form-group row">
            <label className="col-3 col-form-label"> Docket Amounts </label>
            <div className="col-7">
              <input
                disabled
                className="form-control"
                inputMode="decimal"
                pattern="[0-9]*"
                type="number"
                value={docket.totalAmount} />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">New Docket Amount*</label>
            <div className="col-7">
              <input
                className="form-control"
                inputMode="decimal"
                min={0}
                pattern="[0-9]*"
                step="1"
                type="number"
                onChange={e => setNewPrice(e.target.value)}
                value={newPrice} />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Reason for Change*</label>
            <div className="col-7">
              <input
                className="form-control"
                type="text"
                onChange={e => setCurrentReason(e.target.value)}
                value={currentReason} />
            </div>
          </div>
        </form>
        <hr />
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-primary btn-lg"
          onClick={() => setShowConfirmation(true)}
          disabled={!canSubmit}
          type="submit">
          Apply new Amount
          {loading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
        </button>
      </div>

      <SweetAlert
        show={showConfirmation}
        type="info"
        title="Are you sure?"
        style={{ width: "600px" }}
        closeOnClickOutside
        allowEscape={false}
        showCloseButton
        onCancel={() => setShowConfirmation(false)}
        confirmBtnText="Update"
        onConfirm={onConfirm}>
        Apply new Amount with Reason:
        <br />
        <strong>{currentReason}</strong>
      </SweetAlert>
    </Modal>
  )
}

export default UpdateDocketAmountModal
