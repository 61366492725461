import React, { useState } from "react"
import { searchIcon } from "components/Icons"
import cx from "classnames"

export default function SearchInput({
  value, setValue, handleSubmit, placeholder, dataProcessing, disableSubmit
}) {
  const [searchInputActive, setSearchInputActive] = useState(!!value)
  function handleMouseLeaveSearch() {
    if (!value) {
      setSearchInputActive(false)
    }
  }

  return (
    <form className="search-form" onSubmit={handleSubmit}>
      <div
        className={cx("btn btn-lg rounded-pill search-input", { active: searchInputActive })}
        onFocus={() => { setSearchInputActive(true) }}
        onMouseOver={() => { setSearchInputActive(true) }}
        onBlur={handleMouseLeaveSearch}>
        <input
          className={`${searchInputActive ? "mr-2" : ""}`}
          type="search"
          placeholder={placeholder || "Search by job no, name or address"}
          value={value || ""}
          onChange={e => setValue(e.target.value)}
          disabled={!!dataProcessing} />
        <button type="submit" disabled={disableSubmit || !!dataProcessing}>
          <img src={searchIcon} />
        </button>
      </div>
    </form>
  )
}