/* eslint-disable arrow-body-style */
import React from "react"
import RowComponent from "./components/RowComponent"

const DetailedReport = ({ detailedRows }) => {
  const { days, name, total, total_target, total_target_completed_percentage } = detailedRows

  return (
    <div className="my-5">
      <h5>{name}</h5>
      <div className="table-responsive">
        <table className="table table-listing table-hover table-striped">
          <thead>
            <tr>
              <th>Booking Time</th>
              <th>Check In</th>
              <th>Check Out</th>
              <th>Duration</th>
              <th>Date And Time Sent to Drafting</th>
              <th>Address</th>
              <th>Type</th>
              <th>Percentage</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            <RowComponent days={days} />
            <tr>
              <td colSpan={9} className="font-weight-bold text-right text-white bg-dark">
                <span>{`Target: ${total_target} (${total_target_completed_percentage}%)`}</span>
                <span className="ml-3">{total}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DetailedReport