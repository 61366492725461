import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NEXT_VISIT_SITE_NOTES } from  "utils/constants";
import ActivityTimelinePoint from "./ActivityTimelinePoint";
import { getBaseUrl } from "../../../../../reducers";
import { updateNote } from "../../../../../../../requesters/jobServiceRequester";

const ActivityTimelineNotesItem = ({
  jobService, reloadData, activity, lastActivity, isAdmin
}) => {
  const {
    data: {
      event, nextState, userName, label, timestamp, id, notes, forAdmin
    }
  } = activity

  const baseUrl = useSelector(getBaseUrl)
  const [onEditView, setOnEditView] = useState(false)
  const [currentNotes, setCurrentNotes] = useState(notes)
  const [currentForAdmin, setCurrentForAdmin] = useState(forAdmin)
  const canEdit = isAdmin && label !== NEXT_VISIT_SITE_NOTES

  const updateNotes = () => {
    const payLoad = {
      note_id: id,
      notes: currentNotes,
      for_admin: currentForAdmin,
      is_delete_note: currentNotes === ""
    }
    updateNote(baseUrl, jobService.id, payLoad)
    .then(res => {
      if (res.status === 200 && res.response?.success) {
        setOnEditView(false)
        reloadData()
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }

  const onCancelEdit = () => {
    setOnEditView(false)
    setCurrentNotes(notes)
    setCurrentForAdmin(forAdmin)
  }

  const AccountOperationNotesCheckbox = () => (
    <p className="for-admin-checkbox-element mt-2 user-select-none mb-0">
      <input
        className="d-none"
        id={`note-${id}-for-admin-checkbox`}
        type="checkbox"
        checked={currentForAdmin}
        onChange={() => setCurrentForAdmin(!currentForAdmin)} />
      <label htmlFor={`note-${id}-for-admin-checkbox`} className="cursor-pointer">
        <span className={`bi ${currentForAdmin ? "bi-check-circle-fill text-success" : "bi-circle text-muted" }`}></span>
        <span className="ml-2"> Accounts/Operations Note </span>
      </label>
    </p>
  )

  const NotesElement = () => {
    if (!onEditView) {
      // eslint-disable-next-line react/no-danger
      return <div dangerouslySetInnerHTML={{ __html: currentNotes }} />
    }

    return (
      <>
        <textarea
          className="form-control mr-5"
          rows="4"
          value={currentNotes}
          onChange={e => setCurrentNotes(e.target.value)} />
        {AccountOperationNotesCheckbox()}
        <button type="button" className="btn btn-outline-secondary mt-2 mr-2" onClick={onCancelEdit}>
          Cancel
        </button>
        <button type="button" className="btn btn-outline-primary mt-2" onClick={updateNotes}>
          Update
        </button>
      </>
    )
  }

  const timelineTitle = () => (
    <div className="grey-sub-activities">
      <span>
        {label?.split("\n").map((line, index) => (
          <span key={`${line}-${index}`}>
            {line}
            {index < label.split("\n").length - 1 && <br />}
          </span>
        ))}
      </span>
      <span className="activities-timestamp ml-3">{[timestamp, userName].filter(value => !!value).join(" - ")}</span>
      { canEdit && (
        <button
          disabled={!canEdit}
          onClick={() => setOnEditView(true)}
          type="button"
          className="btn btn-link">
          Edit
        </button>
      )}
    </div>
  )

  return (
    <React.Fragment>
      <ActivityTimelinePoint event={event} state={nextState} mainActivity={false} lastActivity={lastActivity} />
      <div className="d-flex justify-content-between">
        <div className="activity-status">
          <div className="grey-sub-activities">
            {timelineTitle()}
          </div>
          <div className="card border-0 shadow-sm mt-3">
            <div className="card-body p-4">
              {NotesElement()}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ActivityTimelineNotesItem
