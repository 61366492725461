import React from "react"
import { AiOutlineInfoCircle } from "react-icons/ai"


export default function AiOutlineInfoCircleCustom(props) {
  const { style } = props
  return (
    <div>
      <AiOutlineInfoCircle style={{
        fontSize: "80px", margin: "20px auto", color: "rgb(70, 184, 218)", ...style
      }} />
    </div>
  )
}
