import React, { useMemo, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import cx from "classnames";
import {
  ADDITIONAL_INFO,
  CLIENT_DELAYED,
  ON_HOLD,
  AWAITING_PAYMENT,
  INVOICE_AWAITING_PAYMENT_STATES,
  TO_INVOICE_STATE,
  WORKFLOW_STEPS
} from "utils/constants";
import DraftingStage from "./stages/DraftingStage";
import FieldWorkStage from "./stages/FieldWorkStage";
import ReadyToSendStage from "./stages/ReadyToSendStage";
import RegSurveyorStage from "./stages/RegSurveyorStage";
import DetailsTabs from "./DetailsTabs";

const steps = WORKFLOW_STEPS

const Tab = ({
  state, tabName, hide, isLast = false, tabIndex, currentTabIndex, setActiveTabIndex
}) => {
  if (hide) return <></>;

  const onClick = () => {
    if (tabIndex <= currentTabIndex) {
      setActiveTabIndex(tabIndex)
    }
  }

  const tabStyles = cx(
    "nav-link p-0 status-item",
    { "bg-green": tabIndex < currentTabIndex },
    { [steps[state][0]]: tabIndex === currentTabIndex },
    { "bg-light": tabIndex > currentTabIndex },
  )

  const title = tabIndex === currentTabIndex ? steps[state][1] : tabName

  return (
    <li className="nav-item" role="presentation" onClick={onClick}>
      <div className={tabStyles}>
        {/* <div className="status-item-text">{tabIndex === currentTabIndex ? capitalizeString(steps[state][1]) : tabName}</div> */}
        <div className="status-item-text">{title}</div>
        {!isLast && <div id="triangle-right"></div>}
      </div>
    </li>
  )
}

export default function JobWorkflow({
  job, jobService, reloadData, activities, baseUrl, currentRole
}) {
  const {
    attributes: {
      activeFlag, workflow, previousState, stateWorkflow, invoiceState, state, forProjectX
    }
  } = jobService
  const sentTo = activeFlag?.data?.attributes?.sentTo
  const isAwaitingPayment = INVOICE_AWAITING_PAYMENT_STATES.includes(invoiceState) && state === AWAITING_PAYMENT

  const getCurrentTabIndex = () => {
    let activeTabRender = steps[stateWorkflow][2]
    if ((stateWorkflow === ADDITIONAL_INFO && sentTo === "drafting") || (stateWorkflow === TO_INVOICE_STATE && ["3"].includes(workflow))) { activeTabRender = 2 }
    if (stateWorkflow === TO_INVOICE_STATE && ["4", "7"].includes(workflow)) { activeTabRender = 3 }
    // eslint-disable-next-line prefer-destructuring
    if ([ON_HOLD, CLIENT_DELAYED].includes(stateWorkflow)) { activeTabRender = steps[previousState || stateWorkflow][2] }

    return activeTabRender
  }

  const currentTabIndex = useMemo(() => getCurrentTabIndex(), [stateWorkflow]);
  const [activeTabIndex, setActiveTabIndex] = useState(currentTabIndex);
  const tabs = ["Deposit", "Field Work", "Processing", "Drafting", "Registered Surveyors*", "Payment"];

  return (
    <div className="col-lg-8">
      <div className="container-fluid">
        <div className="container-inner">
          <ul id="myTab" className="nav status-flow nav-justified" role="tablist">
            {
              tabs.map((tabName, index) => (
                <Tab
                  // eslint-disable-next-line react/no-array-index-key
                  key={`tab-${index}`}
                  state={isAwaitingPayment ? "awaiting_payment" : stateWorkflow}
                  tabName={tabName}
                  tabIndex={index}
                  hide={tabName === "Processing" && !forProjectX}
                  isLast={tabs.length === (index + 1)}
                  currentTabIndex={currentTabIndex}
                  setActiveTabIndex={setActiveTabIndex} />
              ))
            }
          </ul>
          <div id="myTabContent" className="tab-content">
            <FieldWorkStage job={job} reloadData={reloadData} isActive={activeTabIndex === 1} />
            <DraftingStage job={job} reloadData={reloadData} isActive={activeTabIndex === 2} />
            <RegSurveyorStage job={job} reloadData={reloadData} isActive={activeTabIndex === 3} />
            <ReadyToSendStage job={job} reloadData={reloadData} isActive={activeTabIndex === 4} />
          </div>
          <DetailsTabs job={job} jobService={jobService} currentRole={currentRole} reloadData={reloadData} baseUrl={baseUrl} activities={Object.values(activities)} />
        </div>
      </div>
    </div>
  );
}
