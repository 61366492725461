import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { parse, format } from "date-fns"
import { getBaseUrl } from "../../reducers";
import {
  getAwaitingPaymentDepositJobServiceCount,
  getAwaitingPaymentFinalJobServiceCount,
  getCancelledJobServiceCount,
  getClientDelayedJobServiceCount,
  getNonMatchingInvoiceAmounts,
  getOnHoldJobServiceCount,
  getToInvoiceJobServiceCount,
  getOverdueJobsCount,
  getAccountsDashboardData,
  getAwaitingSendFinalReminderJobServiceCount
} from "../../../../requesters/jobServiceRequester";
import DashboardCard from "./components/DashboardCard";
import UpdateNextBookingDate2DModal from "./components/UpdateNextBookingDate2DModal";
import UpdateNextBookingDate3DModal from "./components/UpdateNextBookingDate3DModal";
import { updateAppConfig } from "../../../../requesters/appConfigRequester";

export default function DashboardForAccounts({ currentUser }) {
  const baseUrl = useSelector(getBaseUrl)
  const { attributes: { firstname } } = currentUser
  const [awaitingPaymentDepositJobService, setAwaitingPaymentDepositJobService] = useState(0)
  const [awaitingPaymentFinalJobService, setAwaitingPaymentFinalJobService] = useState(0)
  const [toInvoiceJobService, setToInvoiceJobService] = useState(0)
  const [onHoldJobService, setOnHoldJobService] = useState(0)
  const [clientDelayedJobService, setClientDelayedJobService] = useState(0)
  const [cancelledJobService, setCancelledJobService] = useState(0)
  const [nonMatchingInvoiceAmounts, setNonMatchingInvoiceAmounts] = useState(0)
  const [overdueJobs, setOverdueJobs] = useState(0)
  const [collectPaymentOnsite, setCollectPaymentOnsite] = useState(0)
  const [jobsOutstandingInvoice, setJobsOutstandingInvoice] = useState(0)
  const [awaitingSendFinalReminderCount, setAwaitingSendFinalReminderCount] = useState(0)
  const [showUpdateNextBookingDate2DModal, setShowUpdateNextBookingDate2DModal] = useState(false)
  const [showUpdateNextBookingDate3DModal, setShowUpdateNextBookingDate3DModal] = useState(false)
  const [nextBookingDate2D, setNextBookingDate2D] = useState(undefined)
  const [nextBookingDate3D, setNextBookingDate3D] = useState(undefined)


  const onChangeBookingDate = (date, variable) => {
    updateAppConfig(baseUrl, {
      value: date,
      var: variable
    }).then(res => {
      if (res.status === 200) {
        setShowUpdateNextBookingDate2DModal(false)
        setShowUpdateNextBookingDate3DModal(false)
      }
    })
  }


  const fetchDashboardDataOfSimpleTrackingJobs = () => {
    getAccountsDashboardData(baseUrl)
      .then(res => {
        if (res.status === 200) {
          setCollectPaymentOnsite(res.response.collect_payment_onsite)
          setJobsOutstandingInvoice(res.response.jobs_outstanding_invoice_count)
          setNextBookingDate2D(res.response.next_booking_date_2d)
          setNextBookingDate3D(res.response.next_booking_date_3d)
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  useEffect(() => {
    fetchDashboardDataOfSimpleTrackingJobs()
  }, [])

  return (
    <div className="dashboard">
      <div className="row no-gutters">
        <div className="col-lg-4 col-xl-3">
          <div className="container-fluid">
            <div className="container-inner">
              <div className="greeting">
                Hello,
                {" "}
                <br />
                {" "}
                {firstname}
                {" "}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-xl-9 today">
          <div className="container-fluid">
            <div className="container-inner">
              <h2>{"today".toUpperCase()}</h2>
              <div className="row key-to-do">
                <div className="col-lg-10">
                  <div className="row mt-4">
                    <DashboardCard
                      link="/accounts?by_invoice_status=invoice_pending"
                      title="Job 'To Invoice' count"
                      selfFetchingCount
                      getCountRequester={getToInvoiceJobServiceCount}
                      setCount={setToInvoiceJobService}
                      count={toInvoiceJobService}
                      colorClass={toInvoiceJobService === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/flagged-jobs?filter=cancelled"
                      title="Cancelled Jobs (Last 60 Days)"
                      selfFetchingCount
                      getCountRequester={getCancelledJobServiceCount}
                      setCount={setCancelledJobService}
                      count={cancelledJobService}
                      colorClass={cancelledJobService === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/accounts?by_invoice_status=deposit_invoice_drafted"
                      title="Jobs Awaiting Payment (Deposit)"
                      selfFetchingCount
                      getCountRequester={getAwaitingPaymentDepositJobServiceCount}
                      setCount={setAwaitingPaymentDepositJobService}
                      count={awaitingPaymentDepositJobService}
                      colorClass={awaitingPaymentDepositJobService === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/accounts?by_invoice_status=final_invoice_drafted"
                      title="Jobs Awaiting Payment (Final)"
                      selfFetchingCount
                      getCountRequester={getAwaitingPaymentFinalJobServiceCount}
                      setCount={setAwaitingPaymentFinalJobService}
                      count={awaitingPaymentFinalJobService}
                      colorClass={awaitingPaymentFinalJobService === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/flagged-jobs?filter=on_hold"
                      title="Jobs On Hold"
                      selfFetchingCount
                      getCountRequester={getOnHoldJobServiceCount}
                      setCount={setOnHoldJobService}
                      count={onHoldJobService}
                      colorClass={onHoldJobService === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/flagged-jobs?filter=client_delayed"
                      title="Jobs Client Delayed"
                      selfFetchingCount
                      getCountRequester={getClientDelayedJobServiceCount}
                      setCount={setClientDelayedJobService}
                      count={clientDelayedJobService}
                      colorClass={clientDelayedJobService === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/accounts?by_invoice_status=non_matching_final_invoice"
                      title="Non matching Invoice Amounts"
                      selfFetchingCount
                      getCountRequester={getNonMatchingInvoiceAmounts}
                      setCount={setNonMatchingInvoiceAmounts}
                      count={nonMatchingInvoiceAmounts}
                      colorClass={nonMatchingInvoiceAmounts === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/live-job-status?filter=jobs_overdue"
                      title="Client Jobs Overdue"
                      selfFetchingCount
                      getCountRequester={getOverdueJobsCount}
                      setCount={setOverdueJobs}
                      count={overdueJobs}
                      colorClass={overdueJobs === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/live-job-status?filter=jobs_is_outstanding_invoice"
                      title="Outstanding Invoices"
                      count={jobsOutstandingInvoice}
                      colorClass={jobsOutstandingInvoice === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/live-job-status?filter=collect_payment_onsite"
                      title="Collect Payment Onsite"
                      count={collectPaymentOnsite}
                      colorClass={collectPaymentOnsite === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/live-job-status?filter=jobs_is_outstanding_invoice"
                      title="Outstanding Invoices"
                      count={jobsOutstandingInvoice}
                      colorClass={jobsOutstandingInvoice === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      link="/accounts?by_invoice_status=awaiting_send_final_reminder"
                      title="Jobs Awaiting Send Final Reminder"
                      selfFetchingCount
                      getCountRequester={getAwaitingSendFinalReminderJobServiceCount}
                      setCount={setAwaitingSendFinalReminderCount}
                      count={awaitingSendFinalReminderCount}
                      colorClass={awaitingSendFinalReminderCount === 0 ? "bg-light" : "bg-coral"} />
                    <DashboardCard
                      containerStyle={
                        {
                          cursor: "pointer"
                        }
                      }
                      title="2D Next Available Booking Date"
                      count={nextBookingDate2D ? format(parse(nextBookingDate2D, "dd/MM/yyyy", new Date()), "EEE dd/MM/yyyy") : "--- --/--/---"}
                      colorClass="bg-light"
                      onClick={() => {
                        setShowUpdateNextBookingDate2DModal(true)
                      }} />
                    <DashboardCard
                      containerStyle={
                        {
                          cursor: "pointer"
                        }
                      }
                      title="3D Next Available Booking Date"
                      count={nextBookingDate3D ? format(parse(nextBookingDate3D, "dd/MM/yyyy", new Date()), "EEE dd/MM/yyyy") : "--- --/--/---"}
                      colorClass="bg-light"
                      onClick={() => {
                        setShowUpdateNextBookingDate3DModal(true)
                      }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <UpdateNextBookingDate2DModal
          show={showUpdateNextBookingDate2DModal}
          onHide={() => {
            setShowUpdateNextBookingDate2DModal(false)
          }}
          nextBookingDate2D={nextBookingDate2D}
          setNextBookingDate2D={setNextBookingDate2D}
          onChangeBookingDate={onChangeBookingDate} />
        <UpdateNextBookingDate3DModal
          show={showUpdateNextBookingDate3DModal}
          onHide={() => {
            setShowUpdateNextBookingDate3DModal(false)
          }}
          nextBookingDate3D={nextBookingDate3D}
          setNextBookingDate3D={setNextBookingDate3D}
          onChangeBookingDate={onChangeBookingDate} />
      </div>
    </div>
  )
}
