import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { updateJobServiceTransition } from "requesters/jobServiceRequester"
import { Modal, Spinner } from "react-bootstrap"
import { REQUEST_CADASTRAL, DECLINE_CADASTRAL } from "utils/constants"
import { getBaseUrl, getCurrentUser } from "../../../reducers"

const DECLINE_PROPERTIES = {
  header: "Decline Cadastral request",
  subHeader: "Enter the reasons why the request is declined",
  apiTransition: DECLINE_CADASTRAL,
  buttonLabel: "Decline Request",
}

const CadastralRequestModal = ({
  show, onHide, jobService, reloadData, action
}) => {
  const { id } = jobService
  const baseUrl = useSelector(getBaseUrl)
  const currentUser = useSelector(getCurrentUser)
  const [modalContent, setModalContent] = useState({})
  const [notes, setNotes] = useState("")
  const [requesting, setRequesting] = useState(false)

  const getContent = () => {
    if (action === "decline") setModalContent(DECLINE_PROPERTIES)
  }

  const onSubmit = () => {
    setRequesting(true)
    updateJobServiceTransition(baseUrl, id, modalContent?.apiTransition, notes)
    .then(res => {
      if (res.status === 200) {
        reloadData()
        onHide()
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
    .finally(() => setRequesting(false))
  }

  useEffect(() => {
    if (show) {
      getContent()
      setRequesting(false)
    }
  }, [show])

  const canSubmit = notes?.trim() && !requesting

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <h2 className="modal-title">{modalContent?.header}</h2>
          <div className="mb-2 h6">
            <p className="mr-1">{modalContent?.subHeader}</p>
          </div>
        </Modal.Title>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          <div className="form-group row mb-4 pb-4 border-bottom">
            <textarea
              name="flag_notes"
              className="form-control mr-5"
              placeholder="Notes*"
              rows="4"
              value={notes}
              onChange={e => setNotes(e.target.value)}>
            </textarea>
          </div>
        </form>
        <div className="modal-footer d-flex justify-content-end px-0 py-4">
          <button
            className="btn btn-link btn-lg"
            onClick={onHide}
            type="submit">
            Close
          </button>
          <button
            className="btn btn-red btn-lg"
            onClick={onSubmit}
            disabled={!canSubmit}
            type="submit">
            {modalContent?.buttonLabel}
            {requesting && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
          </button>
        </div>
      </div>
    </Modal>
  )
}
export default CadastralRequestModal
