
import React, { useState, useEffect } from "react"
import { Modal } from "react-bootstrap"
import { useSelector } from "react-redux"
import "react-datepicker/dist/react-datepicker.css"
import { format } from "date-fns"
import { postGearHistoryRecord } from "requesters/gearHistoryRecordRequester"
import SweetAlert from "react-bootstrap-sweetalert"
import Dropzone from "../../../../../components/utils/Dropzone"
import { getBaseUrl } from "../../../reducers"
import { MAX_SIZE_UPLOADED_FILE, MAX_SIZE_UPLOADED_FILE_TEXT } from "../../../../../utils/constants"

const GearIssueModal = ({
 gearId, gearIdentifier, show, setShow, userId
}) => {
  const baseUrl = useSelector(getBaseUrl)
  const [issue, setIssue] = useState("")
  const [disableSubmit, setDisableSubmit] = useState(true)
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [showError, setShowError] = useState(false)
  const [largeUploads, setLargeUploads] = useState([])
  const [uploading, setUploading] = useState(false)

  const handlePreview = e => {
    const files = e.value
    const largeFiles = files?.filter(file => file.size > MAX_SIZE_UPLOADED_FILE)
    if (largeFiles?.length) {
      setLargeUploads(largeFiles)
      setUploadedFiles([])
      setShowError(true)
    } else {
      setUploadedFiles(files)
    }
  }

  useEffect(() => {
    if (issue) {
      setDisableSubmit(false)
    } else {
      setDisableSubmit(true)
    }
  }, [issue])

  const handleClose = () => {
    setShow(false)
    setIssue("")
  }

  const handleIssue = e => {
    setIssue(e.target.value)
  }

  const handleSubmit = () => {
    if (issue) {
      const issueDate = format(new Date(), "dd/MM/yyyy")
      const data = new FormData()
      data.append("user", userId)
      data.append("action", "report_issue")
      data.append("notes", issue)
      data.append("report_date", issueDate)
      data.append("document_category", "gear issue")

      uploadedFiles.forEach(file => data.append(`uploaded_files[]`, file))

      setUploading(true)
      postGearHistoryRecord(baseUrl, data, gearId)
      .then(res => {
        if (res.status === 201) {
          setUploading(false)
          window.location.reload()
        }
      })
      .catch(err => console.log(err))
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered>
        <div className="modal-content">
          <div className="modal-header border-0">
            <h5 className="modal-title" id="gearIssueModalLabel">
              Report Issue
            </h5>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={handleClose}>
              <span aria-hidden="true"> ×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row mb-4">
              <div className="col-md-4">Item ID</div>
              <div className="col-md-8">{gearIdentifier}</div>
            </div>
            <textarea
              className="form-control"
              placeholder="Enter issue..."
              rows="3"
              value={issue}
              onChange={handleIssue} />
            <div className="mt-4">
              <Dropzone
                wrapperClass="drag-drop"
                multiple
                name="photo"
                setUploadedFiles={setUploadedFiles}
                handleFileUpload={handlePreview} />
            </div>

            <div className="mt-4">
              {!!uploadedFiles?.length && uploadedFiles.map(file => (
                <p key={file.path} className="file-list-item mb-0">
                  <i className="bi bi-file-earmark mr-2"></i>
                  {file.path}
                </p>
              ))}
            </div>
          </div>
          <div className="modal-footer border-0 justify-content-between mt-4">
            <button
              className="btn btn-light btn-lg"
              onClick={handleClose}
              type="button">
              Cancel
            </button>
            <button
              className="btn btn-red btn-lg"
              type="button"
              onClick={handleSubmit}
              disabled={disableSubmit}>
              Submit
            </button>
          </div>
        </div>

        <SweetAlert
          show={showError}
          type="error"
          title="File size exceeded!"
          style={{ width: "600px" }}
          closeOnClickOutside
          allowEscape={false}
          onConfirm={() => {
            setShowError(false)
            setLargeUploads([])
            }}>
          Max size is {MAX_SIZE_UPLOADED_FILE_TEXT}
        </SweetAlert>
      </Modal>
    </>
  )
}

export default GearIssueModal