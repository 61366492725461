/* eslint-disable no-unused-vars */
import React from "react"
import "react-datepicker/dist/react-datepicker.css"


import { caretLeftIcon, caretRightIcon } from "components/Icons"

import AnchorButton from "components/AnchorButton"
import { addWeeks } from "date-fns"
import format from "date-fns/format"
import CADatePicker from "./DatePicker"

export default function Calendar({
  startDate, endDate, calendarDate, setCalendarDate
}) {
  function minusWeek() {
    setCalendarDate(addWeeks(calendarDate, -1))
  }

  function addWeek() {
    setCalendarDate(addWeeks(calendarDate, 1))
  }

  return (
    <div className="calendar">
      <ul className="list-inline mb-0">
        <AnchorButton className="list-inline-item" type="button" onClick={() => minusWeek()}>
          <img src={caretLeftIcon} />
        </AnchorButton>
        <li className="list-inline-item px-3">{`${format(startDate, "d MMMM")} - ${format(endDate, "d MMMM")}`}</li>
        <AnchorButton className="list-inline-item" type="button" onClick={() => addWeek()}>
          <img src={caretRightIcon} />
        </AnchorButton>
        <li className="list-inline-item">
          <CADatePicker {...{ calendarDate, setCalendarDate }} />
        </li>
      </ul>
    </div>

  )
}
