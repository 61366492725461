import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom"
import { roleCheck } from "utils/index"
import ActivityTimelineAuditItem from "./ActivityTimelineAuditItem";
import ActivityTimelineNotesItem from "./ActivityTimelineNotesItem";
import { getCurrentUser, getCurrentRole } from "../../../../../reducers";
import {
  ADMIN,
  OPERATIONS,
  ACCOUNTS
} from "../../../../../../../utils/constants";

const ActivityTimelines = ({
  activityLog, jobService, reloadData
}) => {
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentRole = useSelector(getCurrentRole)
  const isAdmin = currentRole.includes(ADMIN)
  const isAdminOrOperationsOrAccounts = roleCheck(systemRole, currentRole, OPERATIONS) || roleCheck(systemRole, currentRole, ACCOUNTS)
  
  const bookedInCheckSurvey = () => {
    const {
      attributes: { isBookInCheckSurvey, bookInJobParent }
    } = jobService

    if (!(isBookInCheckSurvey && bookInJobParent)) return <></>;
    const url = `/jobs/${bookInJobParent.jobId}/job_services/${bookInJobParent.id}?tab=Activity`;

    return (
      <li className="activity timeline-activity">
        <div className="circle-small"></div>
        <div className="d-flex justify-content-between">
          <div className="activity-status">
            <div className="grey-sub-activities">
              <div>
                <div className="grey-sub-activities">
                  <span>
                    <span>View Parent Job</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="timeline-item mt-2 mb-2">
          <div className="card border-0 shadow-sm">
            <div className="card-body">
              <div className="row px-3 flex-column">
                <p className="mb-0">
                  <Link
                    to={url}
                    onClick={() => { window.location.href = url }}
                    className="link-no-decoration">
                    View Parent Job
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className=" mt-2 py-2 px-3 rounded"></div>
      </li>
    )
  }

  return (
    <>
      {bookedInCheckSurvey()}
      {activityLog.map((activity, index, arr) => {
        const {
          type,
          data: {
            label, timestamp, id, forAdmin, event
          }
        } = activity
        const hideActivityAuditLog = type === "audit" && ["requested_collect_payment_onsite", "revoked_collect_payment_onsite", "approved_collect_payment_onsite", "write_off"].includes(event) && !isAdminOrOperationsOrAccounts

        if (type === "note" && forAdmin && !isAdminOrOperationsOrAccounts) {
          return <></>
        }

        return (
          <li className="activity timeline-activity" key={`activity-${id}-${label}-${timestamp}`}>
            { type === "audit" && !hideActivityAuditLog && (
              <ActivityTimelineAuditItem
                jobService={jobService}
                reloadData={reloadData}
                isAdmin={isAdmin}
                activity={activity}
                lastActivity={arr.length === index + 1} />
            )}
            { type === "note" && (
              <ActivityTimelineNotesItem
                jobService={jobService}
                reloadData={reloadData}
                isAdmin={isAdmin}
                activity={activity}
                lastActivity={arr.length === index + 1} />
            )}
          </li>
        )
      })}
    </>
  )
}

export default ActivityTimelines
