import React, { Fragment, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { getBaseUrl } from "../../reducers"

import CustBreadcrumb from "components/CustBreadcrumb"
import { useParams } from "react-router-dom"
import { Link } from "react-router-dom"
import Loader from "components/Loader"

import { getContacts } from "requesters/contactRequester"
import { getJobs } from "requesters/jobRequester"

export default function ContactView({ history }) {
  const baseUrl = useSelector(getBaseUrl)
  const { id } = useParams()

  const [jobs, setJobs] = useState({})
  const [requesting, setRequesting] = useState(true)

  // form fields
  const [email, setEmail] = useState("")
  const [fullName, setFullName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [company, setCompany] = useState("")
  const [clientType, setClientType] = useState("") //This is clientTypeRaw

  useEffect(() => {
    async function fetchContactData() {
      const { response, error } = await getContacts(baseUrl, {by_job_id: id})
      if (response) {
        const { attributes, type } = _.find(response.contact, (item) => { return item.id === id }, {})  //type: contact
        attributes.email !== null && setEmail(attributes.email)
        attributes.firstName && attributes.lastName !== null && setFullName(`${attributes.firstName} ${attributes.lastName}`)
        attributes.phoneNumber !== null && setPhoneNumber(attributes.phoneNumber)
        attributes.company !== null && setCompany(attributes.company)
        attributes.clientType !== null && setClientType(attributes.clientType)
      }
      if (error) {
        console.error(error)
      }
      console.log(response);
    }
    fetchContactData()
  }, [])
  useEffect(() => {

    async function fetchJobsData() {
      const { response, error } = await getJobs(baseUrl, {})
      if (response) {
        const { job = [] } = response
        setJobs(job)
      }
      if (error) {
        console.error(error)
      }
      setRequesting(false)
    }
    requesting && fetchJobsData()

  }, [requesting])

  return (
    <Fragment>
      <div className="container-fluid">
        <CustBreadcrumb links={[["Contacts", "/contacts"]]} active={fullName} />
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <h2 className="mb-1">{fullName}</h2>
            <div className="card border-0 mt-4">
              <div className="card-body p-4">
                <div className="row mb-2">
                  <div className="col-3">Email</div>
                  <div className="col-9">{email}</div>
                </div>
                <div className="row mb-2">
                  <div className="col-3">Phone</div>
                  <div className="col-9">{phoneNumber} </div>
                </div>
                <div className="row mb-2">
                  <div className="col-3">Client Type</div>
                  <div className="col-9">{clientType}</div>
                </div>
                <div className="row mb-2">
                  <div className="col-3">Company</div>
                  <div className="col-9">{company}</div>
                </div>
                <Link to={`/contacts/${id}/edit`}>
                  <div
                    className="btn btn-light mt-4"
                  >
                    Edit
                    </div>
                </Link>
              </div>
            </div>
            {requesting ? <Loader /> :
              <div className="card border-0 mt-3">
                <div className="card-body p-4">
                  <h3>Jobs ({jobs.length})</h3>
                  <table className="table table-listing table-hover">
                    <thead>
                      <tr>
                        <th>Job ID</th>
                        <th>Address</th>
                        <th>Job Type</th>
                        <th>Role</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        jobs.map(job => {
                          const { jobId, address, jobType, role } = job
                          return (
                            <tr>
                              <td>{jobId}</td>
                              <td>{address}</td>
                              <td>{jobType}</td>
                              <td>{role}</td>
                              <td className="text-right">
                                {/* need to update here to remove the job and setRequesting(true) to reload */}
                                <Link to="#">
                                  Remove
                                </Link>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </Fragment>
  )
}

