/* eslint-disable no-return-assign */
import normalize from "json-api-normalizer"
import _ from "lodash"
import customAxios from "./customAxios";

export const getPublicHolidays = (baseUrl, filters) => {
  const path = `${baseUrl}/public_holidays?sort=start_at`
  return customAxios.get(path, { params: filters })
    .then(({ data, status }) => ({ response: normalize(data, { endpoint: "meta" }), status }))
    .catch(error => ({ error }))
}

export const getPublicHoliday = (baseUrl, { id }) => {
  const path = `${baseUrl}/public_holidays/${id}`

  return customAxios.get(path)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const createPublicHoliday = (baseUrl, { id, attributes }) => {
  const path = `${baseUrl}/public_holidays`
  const transformedAttrs = _.transform(attributes, (result, value, key) => result[_.snakeCase(key)] = value)

  return customAxios.post(path, { public_holiday: transformedAttrs })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const updatePublicHoliday = (baseUrl, { id, attributes }) => {
  const path = `${baseUrl}/public_holidays/${id}`
  const transformedAttrs = _.transform(attributes, (result, value, key) => result[_.snakeCase(key)] = value)

  return customAxios.put(path, { public_holiday: transformedAttrs })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const archiveHoliday = (baseUrl, holidayId, archiveState) => {
  const path = `${baseUrl}/public_holidays/${holidayId}/archive`

  return customAxios.put(path, { archive: archiveState })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
}
