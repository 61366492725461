import React, { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { StringParam, useQueryParam } from "use-query-params"

import FilterDropdown from "components/FilterDropdown"
import SearchInput from "components/SearchInput"
import debounce from "lodash.debounce"
import { getContacts } from "requesters/contactRequester"
import { getBaseUrl } from "../../reducers"

import ContactsTable from "./ContactsTable"

export default function ContactTab() {
  const baseUrl = useSelector(getBaseUrl)
  const { clientTypes, role } = useSelector(state => state.options)

  const [byClientType, setByClientType] = useQueryParam("by_client_type", StringParam)
  const [byRole, setByRole] = useQueryParam("by_role", StringParam)
  const [bySearch, setBySearch] = useQueryParam("by_search", StringParam)

  const [contacts, setContacts] = useState([])
  const [requesting, setRequesting] = useState(true)
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true)

  const nextPageHandler = contactsList => {
    if (!contactsList || Object.keys(contactsList).length < 25) {
      setHasNextPage(false)
    } else {
      setHasNextPage(true)
    }
  }

  const searchContacts = input => {
    setRequesting(true)
    getContacts(baseUrl, {
      by_client_type: byClientType,
      by_role: byRole,
      by_keyword: input,
      page,
      per_page: 25
    }).then(res => {
      if (res.status === 200) {
        const contactList = page > 1 ? { ...contacts, ..._.get(res.response, "contact", {}) } : _.get(res.response, "contact", {})
        setContacts(contactList)
        nextPageHandler(res.response?.contact)
      }
      setRequesting(false)
    }).catch(() => { setRequesting(false) })
  }

  const debouncedSearchContacts = useCallback(
    debounce(input => {
      searchContacts(input)
    }, 500),
    [searchContacts]
  )

  const handleSearchInput = input => {
    setBySearch(input);

    debouncedSearchContacts(input)
  }

  useEffect(() => {
    searchContacts(bySearch);
  }, [baseUrl, byClientType, byRole, page])

  useEffect(() => {
    setPage(1)
  }, [byClientType, bySearch])

  const loadMore = () => {
    setPage(page + 1)
  }

  const onDeleteContact = contactId => {
    const listContacts = Object.values(contacts).filter(contact => contact.id !== contactId)
    setContacts(listContacts)
  }

  return (
    <>
      <div className="table-listing-filters">
        <div className="btn-group mr-2">
          <SearchInput value={bySearch} disableSubmit setValue={handleSearchInput} placeholder="Search by name, email or phone" />
        </div>
        <div className="btn-group mr-2">
          <FilterDropdown title="Client Type" filteredName={byClientType} setFilter={setByClientType} options={clientTypes} />
        </div>
        <div className="btn-group mr-2">
          <FilterDropdown title="Role" filteredName={byRole} setFilter={setByRole} options={role} />
        </div>
      </div>
      <ContactsTable contacts={Object.values(contacts)} onDeleteContact={onDeleteContact} />
      <div className="d-flex justify-content-center align-items-center m-4">
        {hasNextPage && <button type="button" onClick={loadMore} className="btn btn-lg btn-primary" disabled={requesting}>{requesting ? "Loading..." : "Load More"}</button>}
      </div>
    </>
  )
}
