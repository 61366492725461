import React, { useEffect, useState, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"

import { Container } from "react-bootstrap"
import { getBaseUrl, getCurrentUser, getResource } from "../../../reducers"
import Row from "./Row"
import { getJobServicesByClient } from "requesters/jobServiceRequester.js"
import { useParams } from "react-router-dom"
import SweetAlert from "react-bootstrap-sweetalert"

export default function CustomerPortal() {
  const [jobServices, setJobServices] = useState([])
  const baseUrl = useSelector(getBaseUrl)
  const currentUser = useSelector(getCurrentUser)
  const { secureToken } = useParams()
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    async function requestJobs() {
      const { response, error } = await getJobServicesByClient(baseUrl, secureToken)
      if (response) {
        const jobs = Object.values(response.jobService || {})
        setJobServices(jobs)
      }
      if (error) {
        setShowMessage(true)
      }
    }
    requestJobs()
  }, [])
  const hideAlert = () => {
    setShowMessage(false);
  };

  return (
    <>
      <div className="customer-portal bg-light">
        <Container fluid className="container-content">
          <div className="pt-4 pt-sm-5">
            <div className="d-md-flex justify-content-between align-items-center header-tabs-container">
              <ul className="list-inline mb-0 header-tabs">
                <li className="list-inline-item active">
                  <h1>Active Job Services</h1>
                </li>
                <li className="list-inline-item ml-4 ml-sm-5 pl-sm-4">
                  <h1>Completed</h1>
                </li>
              </ul>
            </div>
          </div>
          {
            jobServices && jobServices.map((job, index) => {
              return (<Row attributes={job.attributes} jobServiceId={job.id} key={`job-${index}`} />)
            })
          }

        </Container>
        <SweetAlert
          show={showMessage}
          type={"error"}
          title={"Please provide a valid link"}
          closeOnClickOutside
          allowEscape={false}
          onConfirm={hideAlert}
        />
      </div>
    </>
  )
}
