import React from "react"

export default function AnchorButton({
  className, onClick, children, disabled
}) {
  return (
    <button
      disabled={disabled}
      type="button"
      onClick={onClick}
      className="anchor-button">
      <span className={className}>{children}</span>
    </button>
  )
}
