import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { DRAFTING_3D_MANAGER } from "utils/constants"
import { roleCheck } from "utils/index"
import { getDraftingManagerDashboardData, getJobServiceAsssignedDraftsman } from "../../../../../requesters/jobServiceRequester"
import { getBaseUrl, getCurrentRole } from "../../../reducers"
import DashboardCard from "../components/DashboardCard"
import DashboardCardContainer from "../components/DashboardCardContainer"
import TableForDrafting from "./TableForDrafting"

const DashboardForDrafting = ({ currentUser }) => {
  const { id, attributes: { firstname, current_team_role, role } } = currentUser
  const baseUrl = useSelector(getBaseUrl)
  const currentRole = useSelector(getCurrentRole)
  const [jobServices, setJobServices] = useState([])
  const [createDeliverables, setCreateDeliverables] = useState(0)
  const [additionalInfoCount, setAdditionalInfoCount] = useState(0)
  const is3dDraftingManager = roleCheck(role, current_team_role, DRAFTING_3D_MANAGER)
  const draftingType = is3dDraftingManager ? "3d" : "2d"
  const requestJobServices = draftmanId => {
    getJobServiceAsssignedDraftsman(baseUrl, { draftman_id: draftmanId })
      .then(res => {
        if (res.status === 200) {
          const newData = Object.values(_.get(res.response, "jobService", {}))
          setJobServices(newData)
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  const fetchDashboardData = () => {
    getDraftingManagerDashboardData(baseUrl, draftingType)
      .then(res => {
        if (res.status === 200) {
          setCreateDeliverables(res.response.create_deliverables_count)
          setAdditionalInfoCount(res.response.additional_info_count)
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  useEffect(() => {
    if (is3dDraftingManager) {
      fetchDashboardData()
    }
  }, [])

  useEffect(() => {
    requestJobServices(id)
  }, [currentUser, currentRole])

  return (
    <div className="dashboard">
      <div className="row no-gutters">
        <div className="col-lg-4 col-xl-3">
          <div className="container-fluid">
            <div className="container-inner">
              <div className="greeting">
                Hello,
                <br />
                {firstname}
              </div>
            </div>
          </div>
        </div>
        <DashboardCardContainer title="today">
          { is3dDraftingManager && (
            <div className="row mt-4">
              <DashboardCard
                link="/live-job-status?filter=create_deliverables"
                title="3D Deliverables"
                count={createDeliverables}
                colorClass={createDeliverables === 0 ? "bg-light" : "bg-coral"} />
              <DashboardCard
                link="/flagged-jobs?filter=additional_info_drafting"
                title="Additional Info"
                count={additionalInfoCount}
                colorClass={additionalInfoCount === 0 ? "bg-light" : "bg-coral"} />
            </div>
          )}
        </DashboardCardContainer>
      </div>
      {!!jobServices.length && (<TableForDrafting activities={jobServices} />)}
    </div>
  )
}

export default DashboardForDrafting
