import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import "react-datepicker/dist/react-datepicker.css";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getActivities } from "requesters/activityRequester";
import { getContacts } from "requesters/contactRequester";
import { getJob } from "requesters/jobRequester";
import { getJobService, updateJobServiceTransition } from "requesters/jobServiceRequester";
import {
  ACCOUNTS,
  ACCOUNTS_MANAGER,
  ADMIN,
  CLIENT_DELAYED,
  DRAFTING_3D_MANAGER,
  DRAFTING_OVERSEAS,
  DRAFTING_OVERSEAS_LEBANON,
  DRAFTING_OVERSEAS_PHILIPPINES,
  DRAFTING_USER,
  JOB_ON_HOLD_STATES_NOT_ALLOWED,
  ON_HOLD,
  OPERATION_MANAGER,
  OPERATIONS,
  REGISTERED_SURVEYORS_MANAGER,
  REGISTERED_SURVEYORS_USER,
  RESCHEDULE_STATES_ALLOWED,
  SURVEYORS,
  SURVEYORS_MANAGER,
  TEAMS_MANAGERS,
  DRAFTING_MANAGER
} from "utils/constants";
import { roleCheck } from "utils/index";
import { triangleAlert } from "components/Icons";
import * as reducers from "../../reducers";
import { getBaseUrl, getCurrentRole, getCurrentUser } from "../../reducers";
import { ContactList } from "./ContactList";
import JobPackModal from "./JobPackModal";
import JobWorkflow from "./JobWorkflow";
import { OrganizationPanel } from "./OrganizationPanel";
import RelatedJobServices from "./RelatedJobServices/RelatedJobServices";
import CancelBookingModal from "./modals/CancelBookingModal";
import EditProjectModal from "./modals/EditProjectModal";
import FollowUpbookingModal from "./modals/FollowUpbookingModal";
import JobOnHoldModal from "./modals/JobOnHoldModal";
import RescheduleBookingModal from "./modals/RescheduleBookingModal";
import UpdateJobServicePriceModal from "./modals/UpdateJobServicePriceModal";
import UpdateJobServiceCodeModal from "./modals/UpdateJobServiceCodeModal";
import UpdateJobServiceRescheduleFeeModal from "./modals/UpdateJobServiceRescheduleFeeModal";
import UpdateJobServiceCancellationFeeModal from "./modals/UpdateJobServiceCancellationFeeModal";

function JobDetail() {
  const baseUrl = useSelector(getBaseUrl);
  const currentRole = useSelector(getCurrentRole)
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role
  const { id, job_service_id } = useParams();

  const [job, setJob] = useState(null);
  const [secureToken, setSecureToken] = useState("")
  const [jobService, setJobService] = useState(null)
  const [jobServiceState, setJobServiceState] = useState(null)
  const [contacts, setContacts] = useState([])
  const [activities, setActivities] = useState([])
  const [organization, setOrganization] = useState({})
  const [showEditProjectModal, setShowEditProjectModal] = useState(false)
  const [showFollowUpbookingModal, setShowFollowUpbookingModal] = useState(false)
  const [showRescheduleBookingModal, setShowRescheduleBookingModal] = useState(false)
  const [showCancelBookingModal, setShowCancelBookingModal] = useState(false)
  const isManagerRole = TEAMS_MANAGERS.map(team => (roleCheck(systemRole, currentTeamRole, team))).includes(true)
  const [showJobOnHoldModal, setShowJobOnHoldModal] = useState(false)
  const isSurveyorsManager = roleCheck(systemRole, currentTeamRole, SURVEYORS_MANAGER)
  const isOperationManager = roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER)
  const isAccountsManager = roleCheck(systemRole, currentTeamRole, ACCOUNTS_MANAGER)
  const isRegisterSurveyorManager = roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)
  const isRegisteredSurveyorsUser = roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_USER) && !currentRole?.includes(ADMIN)
  const isDatingUser = roleCheck(systemRole, currentTeamRole, DRAFTING_USER) && !currentRole?.includes(ADMIN)
  const [onReloadData, setOnReloadData] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [showUpdatePriceModal, setShowUpdatePriceModal] = useState(false)
  const [showUpdateRescheduleFeeModal, setShowUpdateRescheduleFeeModal] = useState(false)
  const [showUpdateCancellationFeeModal, setShowUpdateCancellationFeeModal] = useState(false)
  const [showChangeJobServiceCodeModal, setShowChangeJobServiceCodeModal] = useState(false)

  const hideAlert = () => {
    setShowMessage(false);
  }

  const limitedInfo = !!((isRegisteredSurveyorsUser || isDatingUser || currentRole?.includes(SURVEYORS) || currentRole?.includes(DRAFTING_OVERSEAS) || currentRole?.includes(DRAFTING_OVERSEAS_PHILIPPINES) || currentRole?.includes(DRAFTING_OVERSEAS_LEBANON)))
  const jobPriceWhiteList = currentRole?.includes(ADMIN)
    || roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER)
    || roleCheck(systemRole, currentTeamRole, ACCOUNTS_MANAGER)
    || roleCheck(systemRole, currentTeamRole, DRAFTING_3D_MANAGER)

  const isAdminOrOperationsOrAccounts = roleCheck(systemRole, currentRole, OPERATIONS) || roleCheck(systemRole, currentRole, ACCOUNTS)

  const outstandingInvoiceWhiteList = currentRole?.includes(ADMIN)
    || roleCheck(systemRole, currentRole, OPERATIONS)
    || roleCheck(systemRole, currentRole, ACCOUNTS)
    || roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)
    || roleCheck(systemRole, currentTeamRole, DRAFTING_3D_MANAGER)
    || roleCheck(systemRole, currentTeamRole, DRAFTING_MANAGER)

  const unmatchedInvoiceWhiteList = outstandingInvoiceWhiteList

  const canEditJobPrice = isAdminOrOperationsOrAccounts
  const canChangeJobServiceCode = currentRole?.includes(ADMIN)

  const reloadData = () => {
    setOnReloadData(true)
    Promise.all([
      getJob(baseUrl, { id, by_roles: currentRole }),
      getJobService(baseUrl, { job_service_id, by_roles: currentRole }),
      getActivities(baseUrl, { job_service_id, job_id: id, by_roles: currentRole }),
      getContacts(baseUrl, { job_service_id })
    ]).then(([resGetJob, resGetJobService, resGetActivities, resGetContacts]) => {
      if (resGetJob.status === 200) {
        const newJob = resGetJob.response.job[id]
        setJob(newJob)
        setOrganization(_.get(newJob, "attributes.organization", {}))
      }
      if (resGetJobService.status === 200) {
        setJobService(resGetJobService.response.jobService[job_service_id])
        setJobServiceState(resGetJobService.response.jobService[job_service_id].attributes.state)
      }
      if (resGetActivities.status === 200) {
        setActivities(_.orderBy(resGetActivities.response.activity, "attributes.createdAt", "desc"))
      }
      if (resGetContacts.status === 200) {
        setContacts(resGetContacts.response.contact)
        if (resGetContacts.response?.contact) {
          const key = Math.min(...Object.keys(resGetContacts.response.contact))
          setSecureToken(resGetContacts.response.contact[key].attributes.secureToken)
        }
      }
      setOnReloadData(false)
      return null
    }).catch(() => {
      setOnReloadData(false)
    })
    return {}
  }

  useEffect(() => {
    reloadData();
  }, [baseUrl, id, currentRole]);

  const renderJobWorkflow = useCallback(() => job && jobService && (
    <JobWorkflow
      job={job}
      jobService={jobService}
      activities={Object.values(activities)}
      baseUrl={baseUrl}
      currentRole={currentRole}
      reloadData={reloadData} />
  ), [onReloadData, job, jobService])

  if (job && jobService) {
    const {
      attributes: {
        jobNo,
        leadJobName,
        jobTitle,
        leadType,
        leadSource,
        accountType,
        clientType,
        pipedriveDealId,
        quoter,
        quoteAppLeadId,
        jobPurpose
      }
    } = job

    const {
      attributes: {
        label,
        clientName,
        code,
        forProjectX,
        projectXJobNo,
        jobNotes,
        estimatedHours,
        clientDueDateCount,
        rescheduleFee,
        cancellationFee,
        refundType,
        isGpsAhd,
        isGpsMga,
        state,
        jobServiceLabel,
        operationsNotes,
        clientNotes,
        organization: jobServiceOrganization,
        sameWithOrganizationAccountType,
        jobHasMultipleJobServices,
        outstandingInvoiceFlagged,
        unmatchedInvoiceFlagged
      }
    } = jobService
    const displayJobNo = forProjectX ? projectXJobNo : jobNo

    const resumeJob = () => {
      setOnReloadData(true)
      updateJobServiceTransition(baseUrl, jobService?.id, "resume")
        .then(() => {
          reloadData()
        })
        .catch(() => {
          setOnReloadData(false)
        })
    }

    const JobPriceElement = () => (
      <React.Fragment>
        {
          canEditJobPrice ? (
            <>
              <span>
                <button
                  disabled={!canEditJobPrice}
                  onClick={() => setShowUpdatePriceModal(true)}
                  type="button"
                  className="btn bg-white">
                  <b>{`$${jobService?.attributes?.totalCost}`}</b>
                </button>
                <span>{" | "}</span>
              </span>
              <UpdateJobServicePriceModal
                show={showUpdatePriceModal}
                onHide={() => setShowUpdatePriceModal(false)}
                jobService={jobService}
                reloadData={reloadData} />
            </>
          )
            : <>{`$${jobService?.attributes?.totalCost} | `}</>
        }
      </React.Fragment>
    )

    const JobServiceCodeElement = () => (
      <React.Fragment>
        {
          canChangeJobServiceCode ? (
            <>
              <button
                disabled={!canChangeJobServiceCode}
                onClick={() => setShowChangeJobServiceCodeModal(true)}
                type="button"
                className="btn bg-white">
                <b>{jobService?.attributes?.code}</b>
              </button>
              <UpdateJobServiceCodeModal
                show={showChangeJobServiceCodeModal}
                onHide={() => setShowChangeJobServiceCodeModal(false)}
                jobService={jobService}
                reloadData={reloadData} />
            </>
          ) : jobService?.attributes?.code
        }
      </React.Fragment>
    )

    const changeRescheduleJobServiceFee = () => {
      return (
        <React.Fragment>
          {
            canEditJobPrice ? (
              <>
                <span>
                  <button
                    disabled={!canEditJobPrice}
                    onClick={() => setShowUpdateRescheduleFeeModal(true)}
                    type="button"
                    className="btn bg-white">
                    <b>${rescheduleFee}</b>
                  </button>
                </span>
                <UpdateJobServiceRescheduleFeeModal
                  show={showUpdateRescheduleFeeModal}
                  onHide={() => setShowUpdateRescheduleFeeModal(false)}
                  jobService={jobService}
                  reloadData={reloadData} />
              </>
            ) : <>${rescheduleFee}</>
          }
        </React.Fragment>
      )
    }

    const changeCancellationJobServiceFee = () => {
      return (
        <React.Fragment>
          {
            canEditJobPrice ? (
              <>
                <span>
                  <button
                    disabled={!canEditJobPrice}
                    onClick={() => setShowUpdateCancellationFeeModal(true)}
                    type="button"
                    className="btn bg-white">
                    <b>${cancellationFee}</b>
                  </button>
                </span>
                <UpdateJobServiceCancellationFeeModal
                  show={showUpdateCancellationFeeModal}
                  onHide={() => setShowUpdateCancellationFeeModal(false)}
                  jobService={jobService}
                  reloadData={reloadData} />
              </>
            ) : <>${cancellationFee}</>
          }
        </React.Fragment>
      )
    }

    return (
      <>
        <div className="split-screen">
          <div className="row no-gutters">
            <div className="col-lg-4">
              <div className="container-fluid">
                <div className="container-inner">
                  <div className="row justify-content-between">
                    <h5 className="text-danger pl-sm-3">{displayJobNo}</h5>
                    {(isSurveyorsManager || isAccountsManager || roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) || (isManagerRole && (!JOB_ON_HOLD_STATES_NOT_ALLOWED.includes(state) || state === ON_HOLD || state === CLIENT_DELAYED))) && (
                      <div className="dropdown">
                        <button className="btn mr-2" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i className="bi bi-three-dots" />
                        </button>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                          <>
                            {(isSurveyorsManager || isAccountsManager || roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER)) && (
                              <button className="dropdown-item mr-2" type="button" onClick={() => { setShowEditProjectModal(true) }}>Edit</button>
                            )}
                            {roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) && (
                              <>
                                <button className="dropdown-item" type="button" onClick={() => { setShowFollowUpbookingModal(true) }}>New Booking</button>

                                { RESCHEDULE_STATES_ALLOWED.includes(jobServiceState) && (
                                  <button className="dropdown-item" type="button" onClick={() => { setShowRescheduleBookingModal(true) }}>Reschedule Booking</button>
                                )}
                              </>
                            )}
                          </>
                          {(isOperationManager || isAccountsManager || isRegisterSurveyorManager) && (
                            !["cancelled", "rescheduled", "ready_to_send", "job_sent"].includes(jobServiceState) && (
                              <button className="dropdown-item" type="button" onClick={() => { setShowCancelBookingModal(true) }}>Cancel Booking</button>
                            )
                          )}
                          {(!JOB_ON_HOLD_STATES_NOT_ALLOWED.includes(state) && isManagerRole || (!JOB_ON_HOLD_STATES_NOT_ALLOWED.includes(state) && isAccountsManager)) && (
                            <>
                              <button className="dropdown-item mr-2" type="button" onClick={() => { setShowJobOnHoldModal(true) }}>Job On Hold</button>
                              <JobOnHoldModal
                                show={showJobOnHoldModal}
                                onHide={() => { setShowJobOnHoldModal(false) }}
                                jobService={jobService}
                                reloadData={reloadData} />
                            </>
                          )}

                          {(state === ON_HOLD || state === CLIENT_DELAYED) && (
                            (isManagerRole || roleCheck(systemRole, currentRole, ACCOUNTS)) && (
                              <button disabled={onReloadData} className="dropdown-item mr-2" type="button" onClick={resumeJob}>Resume Job</button>
                            )
                          )}
                        </div>
                      </div>
                    )}

                    <EditProjectModal
                      show={showEditProjectModal}
                      onHide={() => { setShowEditProjectModal(false) }}
                      job={job}
                      jobService={jobService}
                      reloadData={reloadData}
                      limitedInfo={limitedInfo} />
                    <FollowUpbookingModal
                      show={showFollowUpbookingModal}
                      onHide={() => { setShowFollowUpbookingModal(false) }}
                      job={job}
                      jobService={jobService}
                      reloadData={reloadData} />

                    { RESCHEDULE_STATES_ALLOWED.includes(jobServiceState) && (
                      <RescheduleBookingModal
                        show={showRescheduleBookingModal}
                        onHide={() => { setShowRescheduleBookingModal(false) }}
                        job={job}
                        jobService={jobService}
                        reloadData={reloadData} />
                    )}

                    {!["rescheduled", "cancelled"].includes(jobServiceState) && (
                      <CancelBookingModal
                        show={showCancelBookingModal}
                        onHide={() => { setShowCancelBookingModal(false) }}
                        job={job}
                        jobService={jobService}
                        reloadData={reloadData} />
                    )}
                  </div>
                  <h2 className="mb-3">{jobTitle}</h2>
                  {roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) && (
                    <p className="mb-3">{leadJobName}</p>
                  )}
                  <h6 className="job-detail-cost">
                    <b>
                      {jobPriceWhiteList && JobPriceElement()}
                      {clientName}
                    </b>
                  </h6>
                  {(outstandingInvoiceFlagged && outstandingInvoiceWhiteList) && (
                    <a href={`/live-job-status?filter=jobs_is_outstanding_invoice&job_service_id=${jobService?.id}`} target="_blank" rel="noreferrer">
                      <div className="bg-red p-3 rounded">
                        <h6 className="mb-0 text-white">Outstanding Invoices</h6>
                      </div>
                    </a>
                  )}
                  {(unmatchedInvoiceFlagged && unmatchedInvoiceWhiteList) && (
                    <a href="/accounts?by_invoice_status=non_matching_final_invoice" target="_blank" rel="noreferrer">
                      <div className="bg-red p-3 rounded mt-1">
                        <h6 className="mb-0 text-white">Unmatched Invoice</h6>
                      </div>
                    </a>
                  )}
                  <br />
                  <table className="table-sm table-borderless table-description mt-4">
                    <tbody>
                      {!limitedInfo && (
                        <>
                          <tr>
                            <td>Client Type</td>
                            <td>{clientType}</td>
                          </tr>
                          <tr>
                            <td>Lead Type</td>
                            <td>{leadType}</td>
                          </tr>
                          <tr>
                            <td>Lead Source</td>
                            <td>{leadSource}</td>
                          </tr>
                          <tr>
                            <td>Account Type</td>
                            <td>
                              <p className="mb-0 d-flex align-items-center">
                                <span>{accountType}</span>
                                {!sameWithOrganizationAccountType && (
                                  <span className="ml-2 d-flex align-items-center justify-content-center" data-toggle="tooltip" title={`Organization is "${jobServiceOrganization?.accountTypeLabel}"`}>
                                    <img src={triangleAlert} className="icon-size-15" id="not-same-org-account-type" />
                                  </span>
                                )}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>Estimated Hours</td>
                            <td>{estimatedHours}</td>
                          </tr>
                          <tr>
                            <td>Client Due Date Count</td>
                            <td>{clientDueDateCount}</td>
                          </tr>

                          {jobServiceState === "rescheduled" && (
                            <tr>
                              <td>Reschedule Fee</td>
                              <td>{changeRescheduleJobServiceFee()}</td>
                            </tr>
                          )}

                          {jobServiceState === "cancelled" && (
                            <>
                              <tr>
                                <td>Cancellation Fee</td>
                                <td>{changeCancellationJobServiceFee()}</td>
                              </tr>

                              <tr>
                                <td>Refund Type</td>
                                <td>{refundType}</td>
                              </tr>
                            </>
                          )}
                        </>
                      )}

                      <tr>
                        <td>Job Service</td>
                        <td title={label}>{JobServiceCodeElement()}</td>
                      </tr>

                      <tr>
                        <td>
                          Quoter
                        </td>
                        <td>
                          {quoter}
                        </td>
                      </tr>

                      <tr>
                        <td>Job Purpose</td>
                        <td>{jobPurpose}</td>
                      </tr>

                      <tr>
                        <td>Job Comments</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          {jobNotes && (
                            <div className="job-notes-container py-2 px-3 rounded" dangerouslySetInnerHTML={{ __html: jobNotes || "" }}></div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Job Label</td>
                        <td colSpan={2}>
                          {jobServiceLabel}
                        </td>
                      </tr>
                      {(isOperationManager || isAccountsManager) && (
                        <>
                          <tr>
                            <td>Operations Notes</td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              {operationsNotes && (
                                <div className="bg-yellow py-2 px-3 rounded" dangerouslySetInnerHTML={{ __html: operationsNotes || "" }}></div>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Client Notes</td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              {clientNotes && (
                                <div className="bg-yellow py-2 px-3 rounded" dangerouslySetInnerHTML={{ __html: clientNotes || "" }}></div>
                              )}
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                  <div className="table-description mt-5 mb-3">
                    <ContactList
                      contacts={contacts}
                      jobServiceId={jobService.id}
                      limitedInfo={limitedInfo}
                      reloadData={reloadData} />
                  </div>
                  <div className="table-description mt-5 mb-3">
                    <OrganizationPanel
                      organisation={jobService.attributes.organization}
                      jobServiceId={jobService.id}
                      limitedInfo={limitedInfo}
                      reloadData={reloadData} />
                  </div>

                  {jobHasMultipleJobServices && (
                    <RelatedJobServices
                      jobId={job.id}
                      jobServiceId={jobService.id}
                      title="Related Job Services" />
                  )}

                  {pipedriveDealId && (
                    <div className="grey-attrs">
                      Pipedrive #
                      {pipedriveDealId}
                    </div>
                  )}

                  {quoteAppLeadId && (
                    <div className="grey-attrs">
                      Quote App #
                      {quoteAppLeadId}
                    </div>
                  )}

                  {/* {secureToken && (
                    // <div className="customer-portal">
                    <Link to={`/customer-portal/${secureToken}`}>Customer Portal</Link>
                    // </div>
                  )} */}
                </div>
              </div>
            </div>
            {renderJobWorkflow()}
          </div>
        </div>
        <SweetAlert
          show={showMessage}
          type="success"
          title="The link has been copied to clipboard!"
          closeOnClickOutside
          allowEscape={false}
          onConfirm={hideAlert} />
        <JobPackModal />
      </>
    )
  }
  return null;
}

const mapStateToProps = state => ({
  currentRole: reducers.getCurrentRole(state)
});

export default connect(mapStateToProps, {})(JobDetail);
