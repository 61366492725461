import React, { useState } from "react"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { roleCheck } from "utils/index"
import {
  OPERATION_MANAGER,
  GPS_STATES,
  GPS_REQUESTED,
  GPS_DECLINED,
  GPS_APPROVED,
  APPROVE_GPS,
  FIELD_WORK_STATE,
  FIELD_WORK_FINISHED,
  SURVEYORS_USER,
  DATA_OVERDUE,
} from "utils/constants"
import { newGpsJobService, updateJobServiceTransition } from "requesters/jobServiceRequester"
import { getBaseUrl } from "../../../../reducers"
import RequestGpsModal from "../../modals/RequestGpsModal"

const GpsActivity = ({
  jobService,
  reloadData,
  systemRole,
  currentTeamRole,
}) => {
  const {
    id, attributes: {
      gpsRequestComment,
      gpsDeclineComment,
      state,
      jobId,
      linkedJobServiceId,
      activeGpsRescheduledJob,
      previousState
    }
  } = jobService
  const baseUrl = useSelector(getBaseUrl)
  const [showDeclineModal, setShowDeclineModal] = useState(false)
  const bgGpsPending = ((state !== FIELD_WORK_STATE && !gpsDeclineComment) && currentTeamRole.includes(SURVEYORS_USER)) ? "bg-coral" : null
  const isOverdueAfterFieldWorkFinished = state === DATA_OVERDUE && previousState === FIELD_WORK_FINISHED
  const [loadingApproveGps, setLoadingApproveGps] = useState(false)

  const notesRender = () => {
    if ([GPS_DECLINED, FIELD_WORK_FINISHED].includes(state) && gpsDeclineComment) {
      return (
        <>
          <p className="mb-0">
            <strong>Surveyor Notes: </strong>
            {gpsRequestComment?.body}
          </p>
          <p className="mb-0">
            <strong>Operation Notes: </strong>
            {gpsDeclineComment?.body}
          </p>
        </>
      )
    }

    return (
      <p className="mb-0">
        <strong>Surveyor Notes: </strong>
        {gpsRequestComment?.body}
      </p>
    )
  }

  const approveGPS = () => {
    const params = {
      notes: gpsRequestComment?.body
    }
    setLoadingApproveGps(true)
    newGpsJobService(baseUrl, id, params)
      .then(res => {
        if (res.status === 201) {
          updateJobServiceTransition(baseUrl, id, APPROVE_GPS)
            .then(res => {
              if (res.status === 200) {
                reloadData()
              }
              setLoadingApproveGps(false)
            })
            // eslint-disable-next-line no-console
            .catch(err => {
              console.log(err)
              setLoadingApproveGps(false)
            })
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => {
        console.log(err)
        setLoadingApproveGps(false)
      })
  }

  const gpsStatesHandler = () => {
    if (isOverdueAfterFieldWorkFinished) return "GPS Complete"

    return GPS_STATES[state]
  }

  const getGPSJobLink = () => {
    let link = `/jobs/${jobId}/job_services/${linkedJobServiceId}`
    if (activeGpsRescheduledJob?.id && activeGpsRescheduledJob?.id !== linkedJobServiceId && activeGpsRescheduledJob?.jobId) {
      link = `/jobs/${activeGpsRescheduledJob.jobId}/job_services/${activeGpsRescheduledJob.id}`
    }
    return link
  }

  return (
    state !== FIELD_WORK_STATE && (
      <div className="timeline-item mt-2 mb-2">
        <div className={`card border-0 shadow-sm ${bgGpsPending}`}>
          <div className="card-body">
            <div className="row px-3 flex-column">
              <p className="mb-0">
                {(state === FIELD_WORK_FINISHED && gpsDeclineComment) ? GPS_STATES.gps_declined : gpsStatesHandler()}
                {([FIELD_WORK_STATE, GPS_APPROVED].includes(state) || isOverdueAfterFieldWorkFinished || (state === FIELD_WORK_FINISHED && !gpsDeclineComment)) && (
                  (roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) && state === GPS_APPROVED) && (
                    <Link
                      to={getGPSJobLink()}
                      onClick={() => { window.location.href = getGPSJobLink() }}
                      className="link-no-decoration ml-2">
                      Click here to Schedule Job
                    </Link>
                  )
                )}
              </p>
              {notesRender()}
            </div>
          </div>
        </div>
        {(roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) && state === GPS_REQUESTED) && (
          <div className="row mt-3 ml-1">
            <div className="mr-2">
              <button
                disabled={loadingApproveGps}
                className="btn btn-outlined-blue"
                onClick={approveGPS}
                type="button">
                Approve
              </button>
            </div>
            <div className="mr-2">
              <button
                className="btn btn-outlined-red"
                onClick={() => setShowDeclineModal(true)}
                type="button">
                Decline
              </button>
            </div>
            <RequestGpsModal
              jobService={jobService}
              reloadData={reloadData}
              show={showDeclineModal}
              decline
              onHide={() => setShowDeclineModal(false)} />
          </div>
        )}
      </div>
    )
  )
}
export default GpsActivity
