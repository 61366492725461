import React, { useState } from "react"
import { useSelector } from "react-redux"
import {
  Container,
} from "react-bootstrap"
import { roleCheck } from "utils/index"
import {
  OPERATIONS,
  DRAFTING_3D_MANAGER,
  REGISTERED_SURVEYORS_MANAGER,
  DRAFTING_MANAGER,
} from "utils/constants"
import { getCurrentRole, getCurrentUser } from "../../reducers"
import PerformanceReportsTab from "./components/PerformanceReportsTab"
import OperationsReports from "./OperationsReports"
import DraftingReports from "./DraftingReports"
import RegisteredSurveyorReports from "./RegisteredSurveyorReports"

const Performance = () => {
  const currentUser = useSelector(getCurrentUser)
  const currentRole = useSelector(getCurrentRole)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role
  const isAdminOrOperations = roleCheck(systemRole, currentRole, OPERATIONS)
  const is3dDraftingManager = roleCheck(systemRole, currentTeamRole, DRAFTING_3D_MANAGER)
  const isDraftingManager = is3dDraftingManager || roleCheck(systemRole, currentTeamRole, DRAFTING_MANAGER)
  const isRSManager = roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)

  const defaultTab = () => {
    if (isAdminOrOperations) return "Operations"
    if (isDraftingManager) return "Drafting"
    if (isRSManager) return "Registered Surveyor"

    return "Operations"
  }

  const tabOptions = isAdminOrOperations ? ["Operations", "Drafting", "Registered Surveyor"] : [defaultTab()]
  const [activeTab, setActiveTab] = useState(defaultTab())

  return (
    <Container fluid className="">
      <div className="container-inner">
        <div className="container justify-content-between align-items-center header-tabs-container p-0 m-0">
          <h1>Performance Report</h1>
          <PerformanceReportsTab activeTab={activeTab} setActiveTab={setActiveTab} tabOptions={tabOptions} />
        </div>
        { activeTab === "Operations" && <OperationsReports isAdminOrOperations={isAdminOrOperations} /> }
        { activeTab === "Drafting" && <DraftingReports isAdminOrOperations={isAdminOrOperations} is3dDraftingManager={is3dDraftingManager} /> }
        { activeTab === "Registered Surveyor" && <RegisteredSurveyorReports isAdminOrOperations={isAdminOrOperations} /> }
      </div>
    </Container>
  )
}

export default Performance