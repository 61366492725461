import React from "react"
import DeleteFile from "./DeleteFile"

const FilePreview = ({
  file, reloadData, isGalleryButton, popupGallery, canDelete
}) => (
  <div className="mr-3 mt-2">
    {isGalleryButton ? (
      <>
        <button className="btn btn-outline-secondary p-0" onClick={popupGallery} type="button">
          <img
            src={file.data.attributes.filePath}
            width="80"
            height="80"
            className="img-preview" />
        </button>
        {canDelete && (
          <DeleteFile
            file={file}
            reloadData={reloadData} />
        )}
      </>
    ) : (
      <>
        <a
          href={file.data.attributes.filePath}
          target="_blank"
          rel="noopener noreferrer"
          title={file.data.attributes.fileName}>
          <img
            src={file.data.attributes.filePath}
            width="80"
            height="80"
            className="img-preview" />
        </a>
        {canDelete && (
          <DeleteFile
            file={file}
            reloadData={reloadData} />
        )}
      </>
    )}
  </div>
  )

export default FilePreview