import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Container } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useQueryParam, StringParam } from "use-query-params";
import {
  ACCOUNTS_MANAGER,
  OPERATION_MANAGER,
  REGISTERED_SURVEYORS_MANAGER,
} from "utils/constants"
import { roleCheck } from "utils/index"
import ContactTab from "./ContactTab"
import OrganizationTab from "./OrganizationTab"
import { getCurrentUser } from "../../reducers"

export default function Contacts() {
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role
  const [activeTab, setActiveTab] = useQueryParam("tab", StringParam)
  const rolesAllowed = () => {
    if (roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) || roleCheck(systemRole, currentTeamRole, ACCOUNTS_MANAGER) || roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (!rolesAllowed()) {
      window.location = "/"
    }
  }, [])

  return (
    <Container fluid className="manage-leads-page">
      <div className="container-inner">
        <div className="d-flex justify-content-between align-items-center header-tabs-container">
          <div className="header-tabs">
            <h1>{activeTab === "organizations" ? "Organisations" : activeTab || "Contacts"}</h1>
          </div>
          {activeTab !== "organizations" ? (
            <Link
              className="btn btn-outlined-blue"
              to="/contacts/new"
              data-target="#newEquipmentModal"
              data-toggle="modal"
              type="button">
              + New Contact
            </Link>
          ) : (
            <Link
              className="btn btn-outlined-blue"
              to="/organizations/new"
              data-target="#newEquipmentModal"
              data-toggle="modal"
              type="button">
              + New Organisation
            </Link>
          )}
        </div>
        <div className="d-flex justify-content-between">
          <ul id="contact-tab" className="nav status-flow nav-justified" role="tablist">
            <li
              className="nav-item cursor-pointer"
              role="presentation"
              onClick={() => {
                setActiveTab("contacts")
              }}>
              <div
                style={{ opacity: activeTab === "contacts" || !activeTab ? "1" : "0.3" }}
                className="nav-link"
                role="tab">
                <h6 className="mb-0">Contacts</h6>
              </div>
            </li>
            <li
              className="nav-item cursor-pointer"
              role="presentation"
              onClick={() => {
                setActiveTab("organizations")
              }}>
              <div
                style={{ opacity: activeTab === "organizations" ? "1" : "0.3" }}
                className="nav-link"
                role="tab">
                <h6 className="mb-0">Organisations</h6>
              </div>
            </li>
          </ul>
        </div>
        {activeTab === "organizations" ? <OrganizationTab /> : <ContactTab />}
      </div>
    </Container>
  )
}
