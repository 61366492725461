export const FIELD_VALIDATIONS = [
  { field: "email", message: "Enter a valid email address" },
  { field: "first_name", message: "Enter a valid first name" },
  { field: "last_name", message: "Enter a valid last name" },
  { field: "phone_number", message: "Enter a valid phone number" },
  { field: "client_type", message: "Please select a client type" },
  { field: "company", message: "Please enter a company" }
]

export const FIELD_VALIDATIONS_ORGANIZATION = [
  { field: "name", message: "Enter a valid name" },
  { field: "email", message: "Enter a valid email address" },
  { field: "address", message: "Enter a valid address" },
  { field: "account_type", message: "Please select a account type" }
]
