/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { roleCheck } from "utils/index";

import { withRouter } from "react-router-dom";

import { postComment } from "requesters/commentRequester";
import { getFlagByJobService } from "requesters/flagRequester";
import {
  ACCOUNTS_MANAGER,
  ADDITIONAL_INFO,
  ADMIN,
  DRAFTING,
  DRAFTING_3D,
  DRAFTING_3D_MANAGER,
  DRAFTING_3D_OVERSEAS_LEBANON_MANAGER,
  DRAFTING_3D_OVERSEAS_LEBANON_USER,
  DRAFTING_3D_OVERSEAS_PHILIPPINES_MANAGER,
  DRAFTING_3D_OVERSEAS_PHILIPPINES_USER,
  DRAFTING_MANAGER,
  DRAFTING_OVERSEAS,
  DRAFTING_OVERSEAS_LEBANON,
  DRAFTING_OVERSEAS_MANAGER,
  DRAFTING_OVERSEAS_PHILIPPINES,
  DRAFTING_OVERSEAS_USER,
  DRAFTING_STATES,
  FIELD_WORK_STATES,
  GPS_AHD,
  GPS_MGA,
  JOB_SENT,
  OPERATION_MANAGER,
  OPERATIONS,
  OVERSEAS,
  OVERSEAS_STATES_ALLOWED,
  OVERSEAS_USER_STATES_ALLOWED,
  PRODUCT_CODE_FULL_PAYMENT,
  ALLOWED_TO_BOOK_IN_CHECK_SURVEY_STATES,
  PRODUCT_CODE_PASS_THE_RESOLVE_FLAGGING_STEP,
  PRODUCT_CODE_RS_CAN_VIEW_READY_TO_SEND,
  RECEPTION_MANAGER,
  REGISTERED_SURVEYORS_MANAGER,
  REVIEW_REPORT, TO_LOCAL_DRAFTING,
  TO_REVIEW,
  ACCOUNTS,
  REGISTERED_SURVEYORS
} from "utils/constants";
// import { updateJobServiceTransition } from "requesters/jobServiceRequester"
// import CommentBox from "./activities/CommentBox"
import BookingConfirmed from "./activities/BookingConfirmed";
import CreatingDeliverables from "./activities/CreatingDeliverables";
import DraftingRequired from "./activities/DraftingRequired";
import FieldWorkStates from "./activities/FieldWorkStates";
import PendingDeposit from "./activities/PendingDeposit";
import SendInvoice from "./activities/SendInvoice";
import ToConfirm from "./activities/ToConfirm";
import SelectDraftman from "./dropdowns/SelectDraftman";
import SelectRegisteredSurveyor from "./dropdowns/SelectRegisteredSurveyor";
import SelectSurveyor from "./dropdowns/SelectSurveyor";
// import ShowChangeBookingDate from "./activities/ShowChangeBookingDate"
import { addAdditionalScheduledDay, updateJobServiceTransition } from "../../../../../requesters/jobServiceRequester"
import * as reducers from "../../../reducers"
import { getBaseUrl, getCurrentRole, getCurrentUser } from "../../../reducers"
import AddNotesForNextSiteVisitModal from "../modals/AddNotesForNextSiteVisitModal"
import AddNotesModal from "../modals/AddNotesModal"
import AdditionalLogModal from "../modals/AdditionalLogModal"
import BoundaryCalculationRequired from "./activities/BoundaryCalculationRequired"
import FlaggedJobService from "./activities/FlaggedJobService"
import LinkedBookInJobs from "./activities/LinkedBookInJobs"
import ReadyToSend from "./activities/ReadyToSend"
import RegisteredSurveyor from "./activities/RegisteredSurveyor"
import ReportRequired from "./activities/ReportRequired"
import ActivityTimelines from "./activities/ActivityTimeline/ActivityTimelines"
import WaeFinal from "./activities/WaeFinal"
import BookInCheckSurvey from "./activities/BookInCheckSurvey";
import AddTimeSpent from "../../../../../components/AddTimeSpent";
import AddTaskModal from "../../Activities/AddTaskModal";

const Activity = ({
  activities, jobService, reloadData, setActiveTab
}) => {
  const {
    attributes: {
      state,
      invoiceState,
      accountsInvoiceState,
      accountType,
      draftmanId,
      registeredSurveyorId,
      activityLog,
      scheduledAssignments,
      jobPackUploaded,
      code,
      allCheckedOut,
      is3D,
      draftingTeam,
      cost,
      draftmanName,
      isAccountJob,
      xeroInvoiceState,
      payBeforeDrafting,
      collectPaymentOnsiteRequested,
      collectPaymentOnsiteCollected,
      collectPaymentOnsiteApproved,
      activeFlag,
      requiresRaiseInvoice,
      bookedInForJobServiceActiveFlag,
      bookedInJobServices,
      bookedInForJobServiceId,
      jobId,
      hasActiveSurvey,
      hasFinalInvoice,
      hasDepositInvoice,
      additionalActivityLog,
      boundaryCalculationRequired,
      assignedSurveyorIds,
      criticalNote,
      criticalNoteCreatedBy,
      criticalNoteModifiedBy,
      criticalNoteModifiedAt,
      previousState,
      assignedDraftsmenIds,
      docketResolved,
      requiresDocketInvoice,
      paymentReminderSent,
      workflow,
    }, id
  } = jobService

  const activeFlagData = activeFlag?.data?.attributes
  const activeFlagSentTo = activeFlagData?.sentTo
  const activeFlagFlagType = activeFlagData?.flagType
  const activeFlagResolved = activeFlagData?.resolved
  const notResolvedBookInJob = PRODUCT_CODE_PASS_THE_RESOLVE_FLAGGING_STEP.includes(code)
    && activeFlagFlagType === "PLAN-REQ" && !activeFlagResolved

  const isGpsAhd = (code === GPS_AHD)
  const isGpsMga = (code === GPS_MGA)

  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role
  const currentRole = useSelector(getCurrentRole)
  const baseUrl = useSelector(getBaseUrl)

  const [finalPaymentReminderSentClicked, setFinalPaymentReminderSentClicked] = useState(false)
  const [activityLogFiltered, setActivityLogFiltered] = useState(activityLog)

  const isAdmin = currentRole.includes(ADMIN)
  const canAddNoteForNextSiteVisit = roleCheck(systemRole, currentRole, OPERATIONS) || roleCheck(systemRole, currentRole, REGISTERED_SURVEYORS) || roleCheck(systemRole, currentRole, DRAFTING)
  const isAdminOrOperationsOrAccounts = roleCheck(systemRole, currentRole, OPERATIONS) || roleCheck(systemRole, currentRole, ACCOUNTS)
  const isRSManager = roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)

  const overseasTeam = currentRole.includes(DRAFTING_OVERSEAS)
    || currentRole.includes(DRAFTING_OVERSEAS_LEBANON)
    || currentRole.includes(DRAFTING_OVERSEAS_PHILIPPINES)

  const isDraftingOverseasManager = roleCheck(systemRole, currentTeamRole, DRAFTING_OVERSEAS_MANAGER)
    || roleCheck(systemRole, currentTeamRole, DRAFTING_3D_OVERSEAS_LEBANON_MANAGER)
    || roleCheck(systemRole, currentTeamRole, DRAFTING_3D_OVERSEAS_PHILIPPINES_MANAGER)

  const isDraftingOverseasUser = roleCheck(systemRole, currentTeamRole, DRAFTING_OVERSEAS_USER)
    || roleCheck(systemRole, currentTeamRole, DRAFTING_3D_OVERSEAS_LEBANON_USER)
    || roleCheck(systemRole, currentTeamRole, DRAFTING_3D_OVERSEAS_PHILIPPINES_USER)

  const isLocalDraftingManager = roleCheck(systemRole, currentTeamRole, DRAFTING_3D_MANAGER) || roleCheck(systemRole, currentTeamRole, DRAFTING_MANAGER)

  const id3DTeam = (currentRole.includes(DRAFTING_3D)
    || currentRole.includes(DRAFTING_OVERSEAS_LEBANON)
    || currentRole.includes(DRAFTING_OVERSEAS_PHILIPPINES))

  const isAdditionalDraftman = assignedDraftsmenIds.includes(Number(currentUser.id))
  const isPrimaryDraftman = Number(currentUser.id) === draftmanId || isAdmin


  const finalWaeAllowed = roleCheck(systemRole, currentRole, OPERATIONS) || isRSManager

  const teamAllowed = () => {
    if (systemRole === ADMIN) {
      return true
    }
    if (is3D && !draftingTeam && (roleCheck(systemRole, currentTeamRole, DRAFTING_3D_MANAGER) || isPrimaryDraftman || isAdditionalDraftman)) {
      return true
    }
    if (is3D && draftingTeam === DRAFTING_OVERSEAS_LEBANON && (roleCheck(systemRole, currentTeamRole, DRAFTING_3D_OVERSEAS_LEBANON_MANAGER) || roleCheck(systemRole, currentTeamRole, DRAFTING_3D_MANAGER) || isPrimaryDraftman || isAdditionalDraftman)) {
      return true
    }
    if (is3D && draftingTeam === DRAFTING_OVERSEAS_PHILIPPINES && (roleCheck(systemRole, currentTeamRole, DRAFTING_3D_OVERSEAS_PHILIPPINES_MANAGER) || roleCheck(systemRole, currentTeamRole, DRAFTING_3D_MANAGER) || isPrimaryDraftman || isAdditionalDraftman)) {
      return true
    }
    if (!is3D && !draftingTeam && (roleCheck(systemRole, currentTeamRole, DRAFTING_MANAGER) || isPrimaryDraftman || isAdditionalDraftman)) {
      return true
    }
    if (!is3D && draftingTeam === DRAFTING_OVERSEAS && (roleCheck(systemRole, currentTeamRole, DRAFTING_OVERSEAS_MANAGER) || roleCheck(systemRole, currentTeamRole, DRAFTING_MANAGER) || isPrimaryDraftman || isAdditionalDraftman)) {
      return true
    }
    return false
  }

  const accountJobsCheck = () => {
    if (isAccountJob && xeroInvoiceState === "authorised" && state === "to_invoice") {
      return true
    }
    return false
  }

  const jobPackWarningRender = () => {
    if (isGpsAhd || isGpsMga) {
      return false
    }
    if (!jobPackUploaded && accountType === "50% - 50%" && (payBeforeDrafting || ["paid_deposit", "paid_full"].includes(invoiceState) || Number(cost) === 0)) {
      return true
    }
    if (!jobPackUploaded && accountType !== "50% - 50%") {
      return true
    }
    return false
  }

  const renderToInvoiceLabel = invoiceState => {
    if (invoiceState === "deposit_invoice_drafted") {
      return "Awaiting payment (Deposit)"
    }
    if (invoiceState === "final_invoice_drafted" && !hasDepositInvoice && accountType === "50% - 50%" && !PRODUCT_CODE_FULL_PAYMENT.includes(code)) {
      return "Awaiting payment (Deposit)"
    }
    if (invoiceState === "paid_deposit" && hasFinalInvoice) {
      return "Awaiting payment (Final)"
    }
    if (invoiceState === "final_invoice_drafted") {
      return "Awaiting payment (Final)"
    }
    return "To Invoice"
  }

  const showSendPaymentReminder = () => {
    if (paymentReminderSent) return false
    if (renderToInvoiceLabel(invoiceState) === "Awaiting payment (Deposit)") return false
    if (!["to_invoice", "awaiting_payment"].includes(state)) return false
    if ((invoiceState === "paid_deposit" && hasFinalInvoice) || invoiceState === "final_invoice_drafted") return true
    return false
  }

  const markAsFinalInvoicePaymentReminderSentHandler = () => {
    setFinalPaymentReminderSentClicked(true)
    updateJobServiceTransition(baseUrl, id, "mark-as-final-invoice-payment-reminder-sent")
      .then(res => {
        if (res.status === 200) {
          reloadData()
        } else {
          setFinalPaymentReminderSentClicked(false)
          toast.error(
            <div>
              <span role="img" aria-label="failure-icon">❌</span>
              <span className="ml-2">{res?.error?.response?.data?.errors[0]?.detail}</span>
            </div>,
            {
              toastId: "container-inner",
              containerId: "main"
            }
          )
        }
      })
      .catch(() => {
        setFinalPaymentReminderSentClicked(false)
      })
  }

  const requireSurvSelect = ["To Confirm", "Field Work"]
  const requireDraftmanSelect = ["To Draft", "Qa", OVERSEAS]
  // const [flagJobServiceSource, setFlagJobServiceSource] = useState("additional_info_drafting")
  const flagJobServiceSource = state === TO_LOCAL_DRAFTING ? "Additional Info (Drafting)" : "Additional Info (RS)"
  const [flaggedJs, setFlaggedJs] = useState(null)

  const [comment, setComment] = useState("")
  const [showAdditionalDayConfirmation, setShowAdditionalDayConfirmation] = useState(false)

  const [showNotesModal, setShowNotesModal] = useState(false)
  const [showAdditionalLogModal, setAdditionalLogModal] = useState(false)
  const [showNotesForNextSiteVisitModal, setShowNotesForNextSiteVisitModal] = useState(false)
  const [showAddTaskModal, setShowAddTaskModal] = useState(false)

  // const [draftingRequired, setDraftingRequired] = useState(false)
  // const [regSurveyorRequired, setRegSurveyorRequired] = useState(false)
  const [currentUserAssigned, setCurrentUserAssigned] = useState()

  // variables only used to add comments from the Overseas team
  const currentActivityID = activities[0]?.id
  // const previousActivityComments = activities[1]?.attributes?.comments

  const getCurrentUserAssignmentRole = () => {
    scheduledAssignments.map(scheduledAssignment => {
      setCurrentUserAssigned(scheduledAssignment.assignments.find(a => a.surveyorId === Number(currentUser.id)))
    })
  }

  const getFlagsByJobServiceHandler = jobServiceId => {
    getFlagByJobService(baseUrl, jobServiceId)
      .then(res => {
        if (res.status === 200) {
          setFlaggedJs(Object.values(_.get(res.response, "flag", {}))[0])
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getFlagsByJobServiceHandler(id)
    getCurrentUserAssignmentRole()
  }, [id])

  useEffect(() => {
    if (!isAdminOrOperationsOrAccounts) {
      const filteredActivityLog = activityLog.filter(activity => {
        const { data: { label } } = activity
        return !["Disabled Unmatched Invoice warning", "Enabled Unmatched Invoice warning", "Disabled Outstanding Invoice warning", "Enabled Outstanding Invoice warning"].includes(label)
      })
      setActivityLogFiltered(filteredActivityLog)
    } else {
      setActivityLogFiltered(activityLog)
    }
  }, [activityLog])

  const saveComment = (activityID, comment) => {
    const newComment = {
      commentableId: activityID,
      commentBody: comment,
      commentableType: "Activity",
      currentUserId: currentUser.id,
    }
    postComment(baseUrl, newComment)
      .then(res => {
        if (res.status === 201) {
          window.location.reload()
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  // const flagTypeDesc = {
  //   additional_info_drafting: "Additional Info (Drafting)",
  //   additional_info_reg_surveyor: "Additional Info (RS)",
  //   additional_info_amendments: "Draft Amendments"
  // }


  const titleize = str => str
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/(?:^|\s|-)\w/g, match => match.toUpperCase());

  const raiseInvoiceRole = () => {
    if (roleCheck(systemRole, currentTeamRole, ACCOUNTS_MANAGER)) {
      return (<SendInvoice jobService={jobService} reloadData={reloadData} setActiveTab={setActiveTab} />)
    }
    return null
  }

  const pendingDepositRoles = () => {
    if (roleCheck(systemRole, currentTeamRole, ACCOUNTS_MANAGER) || roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER)) {
      return (
        <React.Fragment>
          { showSendPaymentReminder() ? (
            <>
              <p className="mb-3 mt-3 bg-coral py-2 px-3 rounded">
                Send the final payment reminder
                {" "}
                and click &apos;
                Mark as Sent
                &apos; after sending.
              </p>
              <button type="submit" className="btn btn-outlined-blue btn-md mr-2" disabled={finalPaymentReminderSentClicked} onClick={markAsFinalInvoicePaymentReminderSentHandler}>
                Mark as Sent
                {finalPaymentReminderSentClicked && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
              </button>
            </>
          ) : (
            <PendingDeposit
              currentTeamRole={currentTeamRole}
              roleCheck={roleCheck}
              systemRole={systemRole}
              jobService={jobService}
              reloadData={reloadData} />
          )}
        </React.Fragment>
      )
    }
    return null
  }

  const confirmCollectedFromSurveyor = () => {
    updateJobServiceTransition(baseUrl, id, "collect-payment-onsite-approve")
    .then(res => {
      if (res.status === 200) {
        reloadData()
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }

  const flaggedJobRoles = () => {
    const sentTo = activeFlag?.data?.attributes?.sentTo
    if (sentTo === "drafting" && is3D && (roleCheck(systemRole, currentTeamRole, DRAFTING_3D_MANAGER) || isPrimaryDraftman)) {
      return true
    }
    if (sentTo === "drafting" && !is3D && (roleCheck(systemRole, currentTeamRole, DRAFTING_MANAGER) || isPrimaryDraftman)) {
      return true
    }
    if (sentTo === "operations" && (roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER))) {
      return true
    }
    if (sentTo === "registered_surveyors" && (isRSManager || Number(currentUser.id) === registeredSurveyorId)) {
      return true
    }
    return false
  }

  const handleAddAdditionalScheduleDay = () => {
    addAdditionalScheduledDay(baseUrl, id)
      .then(res => {
        setShowAdditionalDayConfirmation(false)
        reloadData();
      })
  }

  const renderInvoiceState = invoiceState => {
    if (invoiceState === "deposit_invoice_drafted") {
      if (jobService.attributes.payBeforeDrafting) {
        return "Awaiting payment (Deposit) - Payment Required Prior to Drafting"
      }
      return "Awaiting payment (Deposit)"
    }
    if (invoiceState === "final_invoice_drafted" && !hasDepositInvoice && accountType === "50% - 50%" && !PRODUCT_CODE_FULL_PAYMENT.includes(code)) {
      if (jobService.attributes.payBeforeDrafting) {
        return "Final Invoice Drafted - Awaiting payment (Deposit) - Payment Required Prior to Drafting"
      }
      return "Final Invoice Drafted - Awaiting payment (Deposit)"
    }
    if (hasDepositInvoice && invoiceState === "paid_deposit" && !hasFinalInvoice) {
      return "Paid Deposit - Final Invoice Pending"
    }
    if ((invoiceState === "paid_deposit")) {
      return "Paid Deposit - Awaiting payment (Final)"
    }
    if (invoiceState === "final_invoice_drafted") {
      return "Awaiting payment (Final)"
    }
    return titleize(invoiceState)
  }

  const collectedAmounts = scheduledAssignments
  .flatMap(assignment => assignment.assignments)
  .map(i => i.collectPaymentOnsiteCollectedAmount)

  const hasZeroCollectedPayment = collectedAmounts.includes("0.0")

  return (
    <div id="pills-job-activities" className="tab-pane fade show active" role="tabpanel">
      <div className="d-flex justify-content-between align-items-center mb-5">
        <div className="col-left">
          <button className="btn btn-outline-primary" type="button" onClick={() => setShowNotesModal(true)}>Add Note</button>
          <AddNotesModal
            show={showNotesModal}
            reloadData={reloadData}
            jobService={jobService}
            onHide={() => { setShowNotesModal(false) }} />

          { canAddNoteForNextSiteVisit && (
            <>
              <button className="btn btn-outline-primary ml-2" type="button" onClick={() => setShowNotesForNextSiteVisitModal(true)}>Note for Next Site Visit</button>
              <AddNotesForNextSiteVisitModal
                show={showNotesForNextSiteVisitModal}
                reloadData={reloadData}
                jobService={jobService}
                onHide={() => { setShowNotesForNextSiteVisitModal(false) }} />
            </>
          )}

          { isAdmin && (
            <>
              <button className="btn btn-secondary ml-2" type="button" onClick={() => setAdditionalLogModal(true)}>Additional Log</button>
              <AdditionalLogModal
                show={showAdditionalLogModal}
                onHide={() => { setAdditionalLogModal(false) }}
                additionalLog={additionalActivityLog} />
            </>
          )}

          { isRSManager && (
            <div className="add-rs-activity d-inline-block">
              <button
                type="button"
                className="btn btn-outline-primary btn-md mr-2 ml-2"
                onClick={() => setShowAddTaskModal(true)}>
                + Add Activity
              </button>
              <AddTaskModal
                modalTitle="Add Activity"
                show={showAddTaskModal}
                onHide={() => setShowAddTaskModal(false)}
                jobService={jobService}
                onCreated={createdTask => {
                  window.location.href = `/registered-surveyors-activities/${createdTask?.id}`
                }}
                reloadData={() => {}} />
            </div>
          )}
        </div>
        <div className="col-right">
          { ALLOWED_TO_BOOK_IN_CHECK_SURVEY_STATES.includes(state) && roleCheck(systemRole, currentRole, OPERATIONS) && !hasActiveSurvey && (
            <span className="ml-2">
              <BookInCheckSurvey
                jobService={jobService}
                reloadData={reloadData} />
            </span>
          )}

          <AddTimeSpent
            buttonText="+ Add Time"
            reloadData={reloadData}
            trackableId={jobService.id}
            trackableType={jobService.type}
            // trackedForDivision={} // optional it you know exactly division
            trackedOnState={state} />
        </div>
      </div>

      <ul className="list-unstyled activities-list border-left">

        { finalWaeAllowed && JOB_SENT === state && (
          <WaeFinal jobService={jobService} reloadData={reloadData} />
        )}

        {((state === "job_pack_incomplete") && roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER)) && (
          <>
            <div className="d-flex justify-content-between">
              <li className="activity timeline-activity datepicker-container mb-0" key="ToConfirm">
                <div className="circle circle-outline" />
                <div className="d-flex justify-content-between">
                  <div className={`activity-status ${!roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) ? "mb-5" : null}`}>
                    <strong>Preferred Booking Date</strong>
                  </div>
                </div>
              </li>
              <SelectSurveyor jobService={jobService} reloadData={reloadData} />
            </div>
            <li className="activity timeline-activity" key={`row-${2}`}>
              {jobPackWarningRender() && (
                <p className="mb-3 mt-3 bg-coral py-2 px-3 rounded">Job Pack files missing</p>
              )}
              <ToConfirm jobService={jobService} reloadData={reloadData} />
            </li>
          </>
        )}

        {((state === "ready_to_send" || accountJobsCheck()) && (roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) || roleCheck(systemRole, currentTeamRole, RECEPTION_MANAGER) || (isRSManager && PRODUCT_CODE_RS_CAN_VIEW_READY_TO_SEND.includes(code)))) && (
          <ReadyToSend
            jobService={jobService}
            reloadData={reloadData} />
        )}

        {(FIELD_WORK_STATES.includes(state) && state !== "job_pack_incomplete") && (
          <>
            <FieldWorkStates
              jobService={jobService}
              reloadData={reloadData}
              setActiveTab={setActiveTab} />
          </>
        )}

        {
          state === "create_deliverables" && (
            <li className="activity timeline-activity" key="CreatingDeliverables">
              <div className="circle circle-outline" />
              <strong>Creating Deliverables</strong>
              <CreatingDeliverables jobService={jobService} reloadData={reloadData} />
            </li>
          )
        }

        {((DRAFTING_STATES.includes(state) || (state === "additional_info" && activeFlagSentTo === "drafting")) && (isLocalDraftingManager || isPrimaryDraftman || isAdditionalDraftman) && !overseasTeam && teamAllowed()) && (
          <li className="activity timeline-activity" key="DraftingRequired">
            <div className="circle circle-outline" />
            <div className="d-flex justify-content-between">
              <div className={`activity-status ${!roleCheck(systemRole, currentTeamRole, DRAFTING_MANAGER) ? "mb-3" : null}`}>
                <strong>Drafting Required</strong>
                {draftmanName && (
                  <strong className="ml-2">{`(${draftmanName})`}</strong>
                )}
              </div>
              <SelectDraftman jobService={jobService} reloadData={reloadData} readOnly={!(isAdmin || (isLocalDraftingManager && !OVERSEAS_STATES_ALLOWED.includes(state)))} />
            </div>

            <DraftingRequired
              jobService={jobService}
              reloadData={reloadData}
              flagJobServiceSource={flagJobServiceSource}
              noActions={state === "additional_info" || (isAdditionalDraftman && !isLocalDraftingManager)}
              isPrimaryDraftman={isPrimaryDraftman}
              systemRole={systemRole}
              currentTeamRole={currentTeamRole}
              activityID={id}
              saveComment={saveComment}
              comment={comment}
              setComment={setComment} />
          </li>
        )}

        {(overseasTeam && teamAllowed()) && (
          <li className="activity timeline-activity" key="DraftingRequiredOverseas">
            {OVERSEAS_STATES_ALLOWED.includes(state) && (
              <>
                <div className="circle circle-outline" />
                <div className="d-flex justify-content-between">
                  <div className={`activity-status ${!isDraftingOverseasManager ? "mb-3" : null}`}>
                    <strong>Drafting Required</strong>
                    {draftmanName && (
                      <strong className="ml-2">{`(${draftmanName})`}</strong>
                    )}
                  </div>
                  <SelectDraftman jobService={jobService} reloadData={reloadData} readOnly={!isDraftingOverseasManager} />
                </div>
              </>
            )}
            {((isDraftingOverseasUser && state === OVERSEAS_USER_STATES_ALLOWED) || (isDraftingOverseasManager && OVERSEAS_STATES_ALLOWED.includes(state))) && (
              <DraftingRequired
                jobService={jobService}
                reloadData={reloadData}
                flagJobServiceSource={flagJobServiceSource}
                isPrimaryDraftman={isPrimaryDraftman}
                systemRole={systemRole}
                currentTeamRole={currentTeamRole}
                activityID={currentActivityID}
                saveComment={saveComment}
                comment={comment}
                overseas
                noActions={(isAdditionalDraftman && !isDraftingOverseasManager)}
                setComment={setComment} />
            )}
          </li>
        )}

        {
          (state === TO_REVIEW && (isRSManager || Number(currentUser.id) === registeredSurveyorId)) && (
            <li className="activity timeline-activity" key="RegisteredSurveyor">
              <div className="circle circle-outline" />
              <div className="d-flex justify-content-between">
                <div className={`activity-status ${!isRSManager ? "mb-3" : null}`}>
                  <strong>Registered Surveyor</strong>
                </div>
                {roleCheck(systemRole, currentTeamRole, "registered_surveyors_manager") && (
                  <SelectRegisteredSurveyor jobService={jobService} reloadData={reloadData} />
                )}
              </div>
              {!boundaryCalculationRequired && (
                <RegisteredSurveyor
                  notResolvedBookInJob={notResolvedBookInJob}
                  jobService={jobService}
                  reloadData={reloadData}
                  flagJobServiceSource={flagJobServiceSource}
                  // setFlagJobServiceSource={setFlagJobServiceSource}
                  systemRole={systemRole}
                  currentTeamRole={currentTeamRole}
                  currentUser={currentUser}
                  comment={comment}
                  setComment={setComment} />
              )}
            </li>
          )
        }

        {
          (state === REVIEW_REPORT && (isRSManager || roleCheck(systemRole, currentTeamRole, DRAFTING_MANAGER))) && (
            <li className="activity timeline-activity" key="Report Required">
              <div className="circle circle-outline" />
              <div className="d-flex justify-content-between">
                <div className={`activity-status ${!isRSManager ? "mb-5" : null}`}>
                  <strong>Report Required</strong>
                </div>
                {/* <SelectRegisteredSurveyor jobService={jobService} reloadData={reloadData} /> */}
              </div>
              <ReportRequired
                jobService={jobService}
                reloadData={reloadData}
                activityID={id}
                saveComment={saveComment}
                comment={comment}
                setComment={setComment} />
            </li>
          )
        }

        { isAdminOrOperationsOrAccounts && collectPaymentOnsiteRequested && collectPaymentOnsiteCollected && !collectPaymentOnsiteApproved && !hasZeroCollectedPayment && (
          <li className="activity timeline-activity" key="Approve Collect Payment Onsite">
            <div className="circle circle-yellow" />
            <div className="justify-content-between">
              <div className="activity-status">
                <strong>Collect Payment Onsite</strong>
                <div className="grey-sub-activities mt-3">
                  <button type="button" className="btn btn-outlined-blue btn-md mr-2 mb-1" onClick={confirmCollectedFromSurveyor}>
                    Collected from Surveyor
                  </button>
                </div>
              </div>
            </div>
          </li>
        )}

        { /* Have to recheck here with the same component above as it starts to become complicated logically */}
        {
          (
            (Number(cost) !== 0 || !docketResolved)
            && !["rescheduled", "cancelled"].includes(state)
            && (
              state === "to_invoice"
              || (accountType === "50% - 50%" && invoiceState === "paid_deposit" && state === "to_confirm" && hasFinalInvoice)
              || (accountType === "50% - 50%" && ["invoice_pending", "deposit_invoice_drafted"].includes(invoiceState))
              || (accountType === "50% - 50%" && invoiceState === "final_invoice_drafted" && hasFinalInvoice && !hasDepositInvoice)
              || (workflow === "8" && (invoiceState === "invoice_pending" || invoiceState === "final_invoice_drafted"))
              || state === "awaiting_payment"
            )
          ) && (
            <li className="activity timeline-activity" key="To invoice">
              <>
                <div className="circle circle-yellow" />
                <div className="justify-content-between">
                  <div className="activity-status">
                    <strong>{renderToInvoiceLabel(invoiceState)}</strong>
                    <div className="grey-sub-activities">
                      <div className="my-3">
                        {requiresDocketInvoice && invoiceState === "paid_full" ? "Requires Docket Invoice" : invoiceState === "paid_full" && !docketResolved ? "Docket Invoiced" : renderInvoiceState(invoiceState)}
                      </div>
                      {(invoiceState === "paid_full" && requiresDocketInvoice) || !(["deposit_invoice_drafted", "final_invoice_drafted", "paid_full"].includes(invoiceState) || (invoiceState === "paid_deposit" && hasFinalInvoice) || state === "awaiting_payment") ? (raiseInvoiceRole()) : (pendingDepositRoles())}
                    </div>
                  </div>
                </div>  
              </>
            </li>
          )
        }

        {
          criticalNote && (
            <li className="activity timeline-activity" key="Critical Note For Client">
              <div className="circle circle-red" />
              <div className="d-flex justify-content-between">
                <div className="activity-status">
                  <strong>{`Critical Note For Client - by ${criticalNoteCreatedBy}`}</strong>
                  <br></br>
                  <span className="activities-timestamp">{`${criticalNoteModifiedAt} - Last modified by ${criticalNoteModifiedBy}`}</span>
                </div>
              </div>
              <div className="timeline-item mt-2">
                <div className="card border-0 shadow-sm">
                  <div className="card-body p-3">
                    <div dangerouslySetInnerHTML={{ __html: criticalNote || "" }}></div>
                  </div>
                </div>
              </div>
            </li>
          )
        }

        {
          requiresRaiseInvoice && (
            <li className="activity timeline-activity" key="To invoice">
              <>
                <div className="circle circle-yellow" />
                <div className="justify-content-between">
                  <div className="activity-status">
                    <strong>To Invoice</strong>
                    <div className="grey-sub-activities">
                      <div className="my-3">
                        {renderInvoiceState(invoiceState)}
                      </div>
                      {raiseInvoiceRole()}
                    </div>
                  </div>
                </div>
              </>
            </li>
          )
        }

        {
          ((state === ADDITIONAL_INFO || (state === TO_REVIEW && boundaryCalculationRequired)) && activeFlag?.data && flaggedJobRoles()) && (
            <li className="activity timeline-activity" key="Flagged Job">
              <div className="circle circle-outline" />
              <div className="d-flex justify-content-between">
                <div className="activity-status">
                  <strong>Flagged Job</strong>
                </div>
              </div>
              <FlaggedJobService
                jobService={jobService}
                reloadData={reloadData}
                comment={comment}
                setComment={setComment} />
            </li>
          )
        }

        {
          (bookedInForJobServiceActiveFlag?.data) && (
            <li className="activity timeline-activity" key="Flagged Job">
              <div className="circle circle-outline" />
              <div className="d-flex justify-content-between">
                <div className="activity-status">
                  <strong>Flagged Job</strong>
                </div>
              </div>
              <FlaggedJobService
                previewOnly
                flag={bookedInForJobServiceActiveFlag}
                jobService={jobService}
                reloadData={reloadData} />
            </li>
          )
        }

        {
          bookedInJobServices?.length > 0 && (
            <li className="activity timeline-activity" key="Linked Book in Jobs">
              <div className="circle circle-outline" />
              <div className="d-flex justify-content-between">
                <div className="activity-status">
                  <strong>Linked Book In Jobs</strong>
                </div>
              </div>
              <LinkedBookInJobs bookedInJobServices={bookedInJobServices} />
            </li>
          )
        }

        {
          bookedInForJobServiceId && (
            <li className="activity timeline-activity" key="Linked Book in Jobs">
              <div className="circle circle-outline" />
              <div className="d-flex justify-content-between">
                <div className="activity-status">
                  <strong>Linked Book In Jobs</strong>
                </div>
              </div>
              <div className="timeline-item mt-2">
                <div className="card border-0 shadow-sm">
                  <div className="card-body p-3">
                    <div className="row align-items-center">
                      <div className="col-sm-6">
                      </div>
                    </div>
                    <a
                      href={`/jobs/${jobId}/job_services/${bookedInForJobServiceId}`}
                      className="d-block text-dark">
                      Linked Parent Job
                    </a>
                  </div>
                </div>
              </div>
            </li>
          )
        }

        {
          boundaryCalculationRequired && state === "field_work_incompleted" && previousState !== TO_REVIEW && ((roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER)) || assignedSurveyorIds.includes(Number(currentUser.id))) && (
            <>
              <div className="d-flex justify-content-between">
                <li className="activity timeline-activity datepicker-container mb-0" key="BookingConfirmed">
                  <div className="circle circle-outline" />
                  <div className="d-flex justify-content-between">
                    <div className="activity-status">
                      <strong>Boundary Calculation Required</strong>
                    </div>
                  </div>
                </li>
              </div>
              <li className="activity timeline-activity" key={`row-${2}`}>
                {jobPackWarningRender() && (
                  <p className="mb-0 mt-3 bg-coral py-2 px-3 rounded">Job Pack files missing</p>
                )}
                {currentUserAssigned?.role === "assistant" && (
                  <p className="mb-0 mt-3 bg-secondary py-2 px-3 rounded text-white">Job assistant</p>
                )}

                {scheduledAssignments.map((scheduledAssignment, index) => {
                  const assigned = scheduledAssignment.assignments.find(a => a.surveyorId === Number(currentUser.id))
                  return (
                    <>
                      {(roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) || assigned) && (
                        <div className="timeline-item mb-3" key={scheduledAssignment?.scheduledAt}>
                          <BookingConfirmed
                            showActionButtons={false}
                            showRequestGps={index === 0}
                            jobService={jobService}
                            scheduledAssignment={scheduledAssignment}
                            reloadData={reloadData}
                            currentUser={currentUser}
                            systemRole={systemRole}
                            currentTeamRole={currentTeamRole}
                            activityID={id}
                            saveComment={saveComment}
                            comment={comment}
                            setComment={setComment}
                            setActiveTab={setActiveTab} />
                        </div>
                      )
                      }
                      <BoundaryCalculationRequired currentUser={currentUser} jobService={jobService} isAdmin={isAdmin} scheduledAssignment={scheduledAssignment} />
                    </>
                  )
                })}
              </li>
            </>
          )
        }

        {
          <ActivityTimelines activityLog={activityLogFiltered} jobService={jobService} reloadData={reloadData} />
        }
      </ul>
    </div>
  )
}

const mapStateToProps = state => ({
  currentRole: reducers.getCurrentRole(state)
})

export default withRouter(connect(mapStateToProps, {})(Activity))
