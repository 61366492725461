import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert"
import { getBaseUrl } from "../../../reducers";
import { markJobServiceAsCollectedPaymentOnsite } from "../../../../../requesters/jobServiceRequester";

const CollectPaymentOnsiteCollectedModal = ({
  show, onHide, reloadData, jobService, assignment
}) => {
  const {
    attributes: { collectPaymentOnsiteRequested, collectPaymentOnsiteRequestedAmount, collectPaymentOnsiteCollected }
  } = jobService

  const baseUrl = useSelector(getBaseUrl)
  const [requesting, setRequesting] = useState(false)
  const [collectedAmount, setCollectedAmount] = useState(0)
  const [paymentOnsiteNote, setPaymentOnsiteNote] = useState("")

  // eslint-disable-next-line no-restricted-globals
  const cannotSubmitCollect = collectPaymentOnsiteCollected || requesting || isNaN(parseFloat(collectedAmount))
                            || (parseFloat(collectedAmount) !== parseFloat(collectPaymentOnsiteRequestedAmount) && paymentOnsiteNote.trim() === "")

  const onCollectedAmountChange = newCollectedAmount => {
    if (parseFloat(newCollectedAmount) && parseFloat(newCollectedAmount) > parseFloat(collectPaymentOnsiteRequestedAmount)) return;

    setCollectedAmount(newCollectedAmount)
  }

  const onCollectPaymentOnsiteSubmitCollect = () => {
    if (cannotSubmitCollect) return;

    const payload = {
      surveyor_assignment_id: assignment?.surveyorAssignmentId,
      collected_amount: collectedAmount,
      payment_onsite_note: paymentOnsiteNote
    }
    setRequesting(true)
    markJobServiceAsCollectedPaymentOnsite(baseUrl, jobService.id, payload)
    .then(res => {
      if (res.status === 200 && res.response.success) {
        reloadData()
        onHide()
      }
    })
    .catch(err => console.log(err))
    .finally(() => setRequesting(false))
  }

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <div>
          <h2 className="modal-title">Collect Payment Onsite - Surveyor Collect</h2>
        </div>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          <div className="form-group row">
            <label className="col-auto col-form-label"> Requested Amount </label>
            <div className="col-5">
              <input
                disabled
                className="form-control"
                inputMode="decimal"
                pattern="[0-9]*"
                type="number"
                value={collectPaymentOnsiteRequestedAmount} />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-auto col-form-label">How Much Was Collected?</label>
            <div className="col-5 flex-grow-1">
              <input
                className="form-control"
                inputMode="decimal"
                min={0}
                pattern="[0-9]*"
                step="1"
                type="number"
                onChange={e => onCollectedAmountChange(e.target.value)}
                value={collectedAmount} />
            </div>
          </div>
          <div className="form-group row mt-3">
            <div className="col-12">
              <textarea
                name="notes"
                className="form-control"
                placeholder="Reason required if Collected amount is different form the requested amount"
                rows="3"
                value={paymentOnsiteNote}
                onChange={e => setPaymentOnsiteNote(e.target.value)}>
              </textarea>
            </div>
          </div>
        </form>
        <hr />
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-primary btn-lg"
          onClick={onCollectPaymentOnsiteSubmitCollect}
          disabled={cannotSubmitCollect}
          type="submit">
          Submit
          {requesting && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
        </button>
      </div>
    </Modal>
  )
}

export default CollectPaymentOnsiteCollectedModal
