import React, { useState, useEffect } from "react"
// import { sortByName } from "utils/index"
// import { sortIcon } from "components/Icons"
import DraggableDraftingRow from "./DraggableDraftingRow"

export default function DraftingTable({
  jobsDrafting,
  // setJobsDrafting,
  draftmenOptions,
  fetchData,
  filtersApplied,
  readOnly,
  is2DTeam
}) {
  // const [switchOrder, setSwitchOrder] = useState(false)
  const [jobsByDraftsman, setJobsByDraftsman] = useState(jobsDrafting)
  // useEffect(() => {
  //   sortByName(jobsDrafting, "draftmanName", switchOrder)
  // }, [switchOrder])

    useEffect(() => {
      setJobsByDraftsman(jobsDrafting)
  }, [jobsDrafting])

  return (
    <div className="table-responsive">
      <table className="table table-listing table-hover">
        <thead>
          <tr>
            <th></th>
            <th>Job No.</th>
            <th>Job Address</th>
            <th>Job Service</th>
            <th className="text-left">Surveyor</th>
            <th className="text-center absorbing-column">Date of Field Work</th>
            <th className="text-center absorbing-column">
              <span className="mr-1">Draftsman</span>
              {/* <span type="button" onClick={() => setSwitchOrder(!switchOrder)}>
                <img src={sortIcon} />
              </span> */}
            </th>
            {/* <th>Priority</th> */}
            <th className="text-center">Status</th>
            {!readOnly && (
              <th className="text-center">Overseas Team</th>
            )}
            <th className="text-center absorbing-column">Due Date</th>
            <th></th>
          </tr>
        </thead>
        {!!jobsDrafting.length && (
          <DraggableDraftingRow
            draftmenOptions={draftmenOptions}
            jobsDrafting={jobsByDraftsman}
            setJobsDrafting={setJobsByDraftsman}
            fetchData={fetchData}
            is2DTeam={is2DTeam}
            filtersApplied={filtersApplied}
            readOnly={readOnly} />
        )}
      </table>
    </div>
  )
}
