import React, { useState } from "react";
import { closeIcon } from "components/Icons"

const UserAvatar = ({
  initials, onRemove, name, readOnly
}) => {
  const [hovered, setHovered] = useState(false);
  return (
    <div
      style={{ position: "relative", cursor: "pointer" }}
      className="user-init bg-blue"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
      {initials}
      {hovered && (
        <>
          {!readOnly && (
            <div className="close-icon" onClick={onRemove}>
              <img src={closeIcon} />
            </div>
          )}
          <div className="user-tooltip">{name}</div>
        </>
      )}
    </div>
  );
};

export default UserAvatar;
