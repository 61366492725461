import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  ACCOUNTS_MANAGER,
  PRODUCT_CODE_FULL_PAYMENT,
  OPERATIONS,
} from "utils/constants";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert"
import { updateJobServiceTransition } from "requesters/jobServiceRequester";
import { getBaseUrl, getCurrentRole } from "../../../../reducers";
import CollectPaymentOnsiteModal from "../../modals/CollectPaymentOnsiteModal";
import { revokeJobServiceCollectPaymentOnsite } from "../../../../../../requesters/jobServiceRequester";
import BookInCheckSurvey from "./BookInCheckSurvey";

const PendingDeposit = ({
  jobService,
  reloadData,
  currentTeamRole,
  roleCheck,
  systemRole,
}) => {
  const transition = "pay-invoice"
  const toastOptions = {
    toastId: "container-inner",
    containerId: "main"
  }
  const {
    id,
    attributes: {
      invoiceState, workflow, hasFinalInvoice, hasDepositInvoice, code, state, payBeforeDrafting, registeredSurveyorId, draftmanId,
      collectPaymentOnsiteRequested, collectPaymentOnsiteCollected, collectPaymentOnsiteApproved, docketResolved, requiresDocketInvoice,
      hasActiveSurvey
    }
  } = jobService
  const baseUrl = useSelector(getBaseUrl)
  const currentRole = useSelector(getCurrentRole)
  const [markAsPaidClicked, setMarkAsPaidClicked] = useState(false)
  const [markAsPaidDepositClicked, setMarkAsPaidDepositClicked] = useState(false)
  const [showCollectPaymentOnsiteModal, setShowCollectPaymentOnsiteModal] = useState(false)
  const [showCancelCollectPaymentOnsiteConfirmation, setShowCancelCollectPaymentOnsiteConfirmation] = useState(false)
  const [canUpdateCollectPaymentOnsite, setCanEditCollectPaymentOnsite] = useState(false)
  const [markAsPaidOtherClicked, setMarkAsPaidOtherClicked] = useState(false)

  const markAsPaidHandler = () => {
    setMarkAsPaidClicked(true)
    updateJobServiceTransition(baseUrl, id, transition)
      .then(res => {
        if (res.status === 200) {
          reloadData()
        } else {
          setMarkAsPaidClicked(false)
          toast.error(
            <div>
              <span role="img" aria-label="failure-icon">❌</span>
              <span className="ml-2">{res?.error?.response?.data?.errors[0]?.detail}</span>
            </div>,
            toastOptions
          )
        }
      })
      .catch(() => {
        setMarkAsPaidClicked(false)
      })
  }

  const markDepositAsPaidHandler = () => {
    setMarkAsPaidDepositClicked(true)
    updateJobServiceTransition(baseUrl, id, "mark-deposit-as-paid")
      .then(res => {
        if (res.status === 200) {
          reloadData()
        } else {
          setMarkAsPaidDepositClicked(false)
          toast.error(
            <div>
              <span role="img" aria-label="failure-icon">❌</span>
              <span className="ml-2">{res?.error?.response?.data?.errors[0]?.detail}</span>
            </div>,
            toastOptions
          )
        }
      })
      .catch(() => {
        setMarkAsPaidDepositClicked(false)
      })
  }

  const payBeforeDraftingHandler = () => {
    updateJobServiceTransition(baseUrl, id, "pay-before-drafting")
      .then(res => {
        if (res.status === 200) {
          reloadData()
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  const onEditCollectPaymentOnsite = () => {
    setCanEditCollectPaymentOnsite(true)
    setShowCollectPaymentOnsiteModal(true)
  }

  const onCancelCollectPaymentOnsite = () => {
    setShowCancelCollectPaymentOnsiteConfirmation(false)
    revokeJobServiceCollectPaymentOnsite(baseUrl, id)
      .then(res => {
        if (res.status === 200 && res.response.success) {
          reloadData()
        }
      })
      .catch(err => console.log(err))
  }

  const confirmCollectedFromSurveyor = () => {
    updateJobServiceTransition(baseUrl, id, "collect-payment-onsite-approve")
      .then(res => {
        if (res.status === 200) {
          reloadData()
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  const CollectPaymentOnsiteApproveButton = () => {
    if (collectPaymentOnsiteApproved) return <></>;

    return (
      <button type="button" className="btn btn-outlined-blue btn-md mr-2 mb-1" onClick={confirmCollectedFromSurveyor}>
        Collected from Surveyor
      </button>
    )
  }

  const CollectPaymentOnsiteRequestedButtons = () => (
    <React.Fragment>
      { collectPaymentOnsiteCollected ? <></> : (
        <div className="d-inline">
          <button type="button" className="btn btn-outlined-blue btn-md mr-2 mb-1" onClick={() => setShowCancelCollectPaymentOnsiteConfirmation(true)}>
            Cancel Collect Payment Onsite
          </button>

          <button type="button" className="btn btn-outlined-blue btn-md mr-2 mb-1" onClick={onEditCollectPaymentOnsite}>
            Edit Collect Payment Onsite
          </button>

          <SweetAlert
            show={showCancelCollectPaymentOnsiteConfirmation}
            type="info"
            title="Are you sure to Cancel Collect?"
            style={{ width: "600px" }}
            closeOnClickOutside
            allowEscape={false}
            showCloseButton
            onCancel={() => setShowCancelCollectPaymentOnsiteConfirmation(false)}
            confirmBtnText="Yes"
            onConfirm={onCancelCollectPaymentOnsite}>
          </SweetAlert>
        </div>
      )}
    </React.Fragment>
  )

  const markAsPaidAllOtherHandler = () => {
    setMarkAsPaidOtherClicked(true)
    updateJobServiceTransition(baseUrl, id, "pay-other-invoice", null, null, null, null, null, { mask_as_paid_all: true })
      .then(res => {
        if (res.status === 200) {
          reloadData()
        } else {
          setMarkAsPaidOtherClicked(false)
          toast.error(
            <div>
              <span role="img" aria-label="failure-icon">❌</span>
              <span className="ml-2">{res?.error?.response?.data?.errors[0]?.detail}</span>
            </div>,
            toastOptions
          )
        }
      })
      .catch(() => {
        setMarkAsPaidOtherClicked(false)
      })
  }

  const isAccountsManager = roleCheck(systemRole, currentTeamRole, ACCOUNTS_MANAGER)

  return (
    <div className="timeline-item">
      <div className="text-left">
        {isAccountsManager && invoiceState !== "paid_full" && (
          <button type="submit" disabled={markAsPaidClicked} className="btn btn-outlined-blue btn-md mr-2 mb-1" onClick={markAsPaidHandler}>
            Mark as Paid
            {markAsPaidClicked && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
          </button>
        )}

        {(isAccountsManager && (hasFinalInvoice && !hasDepositInvoice) && invoiceState !== "paid_deposit" && !PRODUCT_CODE_FULL_PAYMENT.includes(code)) && !collectPaymentOnsiteRequested && registeredSurveyorId === null && draftmanId === null && workflow !== "8" && (
          <button type="submit" className="btn btn-outlined-blue btn-md mr-2 mb-1" disabled={markAsPaidDepositClicked} onClick={markDepositAsPaidHandler}>
            Mark Deposit as Paid
            {markAsPaidDepositClicked && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
          </button>
        )}

        {roleCheck(systemRole, currentTeamRole, ACCOUNTS_MANAGER) && invoiceState === "paid_full" && !requiresDocketInvoice && !docketResolved && (
          <button type="submit" disabled={markAsPaidClicked} className="btn btn-outlined-blue btn-md mr-2" onClick={markAsPaidAllOtherHandler}>
            Mark as Paid All Other Invoices
            {markAsPaidOtherClicked && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
          </button>
        )}

        {state !== "to_invoice" && !payBeforeDrafting && registeredSurveyorId === null && draftmanId === null && workflow !== "8" && (

          // NEED TO TEST jobService.attributes.registered_surveyor_id.present OUT SIDE OF HOURS
          <React.Fragment>
            { collectPaymentOnsiteRequested ? (
              <CollectPaymentOnsiteRequestedButtons />
            ) : (
              <div className="d-inline">
                { invoiceState !== "paid_deposit" && (
                  <button type="submit" className="btn btn-outlined-blue btn-md mr-2 mb-1" onClick={payBeforeDraftingHandler}>
                    {workflow === "6" ? "Pay before Registered Surveyor" : "Pay before Drafting"}
                  </button>
                )}

                { invoiceState !== "paid_full" && (
                  <button type="button" className="btn btn-outlined-blue btn-md mr-2 mb-1" onClick={() => setShowCollectPaymentOnsiteModal(true)}>
                    Collect Payment Onsite
                  </button>
                )}
              </div>
            )}

            <CollectPaymentOnsiteModal
              show={showCollectPaymentOnsiteModal}
              onHide={() => setShowCollectPaymentOnsiteModal(false)}
              canUpdate={canUpdateCollectPaymentOnsite}
              jobService={jobService}
              reloadData={reloadData} />
          </React.Fragment>
        )}
        {/* Don't show the Check Survey button at here for now */}
        { false && roleCheck(systemRole, currentRole, OPERATIONS) && !hasActiveSurvey && (
          <span className="mr-2 mb-1">
            <BookInCheckSurvey
              buttonClasses="mb-1"
              jobService={jobService}
              reloadData={reloadData} />
          </span>
          )}
      </div>
    </div>
  )
}
export default PendingDeposit
