import normalize from "json-api-normalizer";
import customAxios from "./customAxios";

export const getGears = (baseUrl, filters) => {
    const path = `${baseUrl}/gears`

    return customAxios.get(path, { params: filters })
        .then(({ data, status }) => ({ response: normalize(data, { endpoint: "meta" }), status }))
        .catch(error => ({ error }))
}

export const getGear = (baseUrl, gearId) => {
    const path = `${baseUrl}/gears/${gearId}`

    return customAxios.get(path)
        .then(({ data, status }) => ({ response: normalize(data), status }))
        .catch(error => ({ error }))
}

export const getGearsPerUser = (baseUrl, userId) => {
    const path = `${baseUrl}/gears/gear-per-user`

    return customAxios.get(path, { params: { user_id: userId } })
        .then(({ data, status }) => ({ response: normalize(data, { endpoint: "meta" }), status }))
        .catch(error => ({ error }))
}

export const putGear = (baseUrl, gearId, gearData) => {
    const path = `${baseUrl}/gears/${gearId}`

    return customAxios.put(path, { gear: gearData })
        .then(({ data, status }) => ({ response: normalize(data), status }))
        .catch(error => ({ error }))
}

export const archiveGear = (baseUrl, gearId, archiveStatus) => {
    const path = `${baseUrl}/gears/${gearId}/archive`

    return customAxios.put(path, { archive_status: archiveStatus })
        .then(({ data, status }) => ({ response: normalize(data), status }))
        .catch(error => ({ error }))
}

export const postGear = (baseUrl, gearData) => {
    const path = `${baseUrl}/gears/`

    return customAxios.post(path, { gear: gearData })
        .then(({ data, status }) => ({ response: normalize(data), status }))
        .catch(error => ({ error }))
}
