import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { getBaseUrl } from "../../reducers"
import { getReceptionDashboardData, getJobPackIncompleteCount } from "../../../../requesters/jobServiceRequester"

import DashboardCardContainer from "./components/DashboardCardContainer";
import DashboardCard from "./components/DashboardCard";


export default function DashboardForReception({ currentUser }) {
  const { attributes: { firstname } } = currentUser
  const baseUrl = useSelector(getBaseUrl)
  const [jobPacksIncomplete, setJobPacksIncomplete] = useState(0)
  const [jobsReady, setJobsReady] = useState(0)

  useEffect(() => {
    getReceptionDashboardData(baseUrl)
    .then(res => {
      if (res.status === 200) {
        setJobsReady(res.response.jobs_ready)
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }, [])

  return (
    <div className="dashboard">
      <div className="row no-gutters">
        <div className="col-lg-4 col-xl-3">
          <div className="container-fluid">
            <div className="container-inner">
              <div className="greeting">
                Hello,
                {" "}
                <br />
                {" "}
                {firstname}
                {" "}
              </div>
            </div>
          </div>
        </div>
        <DashboardCardContainer title="today">
          <div className="row">
            <DashboardCard
              link="/live-job-status?filter=job_pack_not_uploaded"
              title="Job Packs Incomplete until Today"
              count={jobPacksIncomplete}
              selfFetchingCount
              getCountRequester={getJobPackIncompleteCount}
              setCount={setJobPacksIncomplete}
              colorClass="bg-coral" />
            <DashboardCard
              link="/live-job-status?filter=ready_to_send"
              title="Jobs Ready to Send"
              count={jobsReady}
              colorClass="bg-light" />
          </div>
        </DashboardCardContainer>
      </div>
    </div>
  )
}