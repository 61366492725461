import customAxios from "./customAxios";
import normalize from "json-api-normalizer"

export const getProduct = baseUrl => {
    const path = `${baseUrl}/products/get-products`
    return customAxios.get(path)
        .then(({ data, status }) => ({ response: normalize(data), status }))
        .catch(error => ({ error }))
}

export const getFilteredProducts = (baseUrl, jobType) => {
    const path = `${baseUrl}/products/${jobType}/products`
    return customAxios.get(path)
        .then(({ data, status }) => ({ response: normalize(data), status }))
        .catch(error => ({ error }))
}
