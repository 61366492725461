import BaseController from "controllers/home/base"

import app  from "apps/App"

class Controller extends BaseController {
  call() {
    super.call()

    const rootElem = document.getElementById("react-root")
    if (rootElem) {
      app(rootElem)
    }
  }
}

export default Controller
