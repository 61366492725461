import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { Container } from "react-bootstrap"
import { ADMIN, OPERATIONS, ACCOUNTS } from "utils/constants"
import CustBreadcrumb from "components/CustBreadcrumb"
import { useParams } from "react-router-dom";
import { getGearHistory } from "requesters/gearHistoryRecordRequester"
import { getBaseUrl, getCurrentUser, getCurrentRole } from "../../../../reducers"
import HistoryDetail from "./HistoryDetail"


export default function GearHistoryDetail() {
    const { id } = useParams()
    const gearHistoryId = parseInt(id, 10)
    const baseUrl = useSelector(getBaseUrl)
    const currentRole = useSelector(getCurrentRole)
    const currentUser = useSelector(getCurrentUser)
    const [gearHistory, setGearHistory] = useState([])
    const [requesting, setRequesting] = useState(false)

    const readOnly = !(currentRole.includes(ADMIN) || currentRole.includes(OPERATIONS) || currentRole.includes(ACCOUNTS) || currentUser.attributes.role === ADMIN)

    const fetchGearHistory = () => {
      setRequesting(true)
      getGearHistory(baseUrl, gearHistoryId)
        .then(res => {
          if (res.status === 200) {
            setGearHistory(_.get(res.response.history, `[${gearHistoryId}].attributes`, {}))
          }
        })
        // eslint-disable-next-line no-console
        .catch(error => console.log(error))
      setRequesting(false)
    }

    useEffect(() => {
      fetchGearHistory()
    }, [gearHistoryId])

    return (
      <Container fluid className="gear-details-page pb-5">
        {!readOnly && (
          <GearBreadcrumb gearIdentifier={gearHistory?.historyableIdentifier} gearId={gearHistory?.historyableId} />
        )}
        <HistoryDetail readOnly={readOnly} gearHistory={gearHistory} requesting={requesting} historyId={id} />
      </Container>
    )
}

function GearBreadcrumb({ gearIdentifier = "No Gear", gearId = 0 }) {
    return <CustBreadcrumb links={[["Settings", "/settings"], ["Company Assets", "/settings/gears"], [gearIdentifier, `/settings/gears/${gearId}`]]} active="History Details" />
}