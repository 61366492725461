import { filePdfIcon } from "components/Icons"
import Loader from "components/Loader"
import { format } from "date-fns"
import React, { useState } from "react"
import {
  Spinner
} from "react-bootstrap"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { getDocketPdf, postDocket, deleteDocket } from "requesters/docketRequester"
import {
  DOCKET_STATUS,
  OPERATION_MANAGER,
  SURVEYORS
} from "utils/constants"
import { roleCheck } from "utils/index"
import AnchorButton from "components/AnchorButton"
import SweetAlert from "react-bootstrap-sweetalert"
import { getBaseUrl, getCurrentRole, getCurrentUser } from "../../../reducers"
import AddDocketModal from "../modals/AddDocketModal"


export default function Docket({ jobService, reloadData }) {
  const [loading, setLoading] = useState(false)
  const [docketSelectedDelete, setDocketSelectedDelete] = useState(null)
  const [loadingPdfDocketId, setLoadingPdfDocketId] = useState(null)
  const baseUrl = useSelector(getBaseUrl)
  const currentUser = useSelector(getCurrentUser)
  const currentRole = useSelector(getCurrentRole)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role
  const isAdminOrOperations = roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER)
  const isSurveyor = roleCheck(systemRole, currentRole, SURVEYORS)
  const [showDocketModal, setShowDocketModal] = useState(false)
  const { attributes: { scheduledAssignments } } = jobService || { attributes: { scheduledAssignments: [], primarySurveyorId: 0 } }

  const dockets = jobService?.attributes?.dockets?.data || []
  const scheduledAssignmentsOptions = []
  if (scheduledAssignments.length > 0) {
    scheduledAssignments.forEach(scheduledAssignment => {
      if (isAdminOrOperations) {
        scheduledAssignmentsOptions.push({
          label: format(new Date(scheduledAssignment.scheduledAt), "dd MMM yyyy, EEEE h:mm aa"),
          value: scheduledAssignment.primarySurveyorAssignmentId
        })
      } else {
        const assignment = scheduledAssignment.assignments.find(a => a.surveyorId === Number(currentUser.id)) || {};
        if (assignment && assignment.surveyorAssignmentId) {
          scheduledAssignmentsOptions.push({
            label: format(new Date(scheduledAssignment.scheduledAt), "dd MMM yyyy, EEEE h:mm aa"),
            value: assignment.surveyorAssignmentId
          })
        }
      }
    });
  }

  const addDocketHandler = surveyorAssignmentId => {
    setShowDocketModal(false)
    setLoading(true)
    postDocket(baseUrl, { jobServiceId: jobService.id, surveyorAssignmentId })
      .then(({ status }) => {
        if (status === 201) {
          reloadData()
          setLoading(false)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const deleteDocketHandler = docketId => {
    setDocketSelectedDelete(null)
    setLoading(true)
    deleteDocket(baseUrl, docketId)
      .then(({ status }) => {
        if (status === 200) {
          reloadData()
          setLoading(false)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <div className="timeline-item">
      <div className="card border-0 shadow-sm timeline-card">
        <div className="card-body p-lg-5">
          <div className="d-flex justify-content-between">
            <h5 className="card-title">Dockets</h5>
            {(isAdminOrOperations || isSurveyor) && <button disabled={loading} onClick={() => setShowDocketModal(true)} className="btn btn-outline-primary" type="button">+ Docket</button>}
          </div>
          <div className="table-responsive">
            <table className="table mt-4">
              <thead className="bg-light">
                <tr>
                  <th></th>
                  <th>Date</th>
                  <th>No.</th>
                  <th>Surveyor Assignment</th>
                  <th>Creator</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {dockets.map(docket => {
                  const canDelete = (isAdminOrOperations && ["new", "signed", "no_signature"].includes(docket.attributes?.status)) || (isSurveyor && docket.attributes?.status === "new")
                  return (
                    <tr className="border-top border-bottom" key={docket.id}>
                      <td>
                        <span className="bi bi-check-circle-fill" style={{ color: DOCKET_STATUS[docket.attributes?.status].color }}></span>
                      </td>
                      <td style={{ width: "120px" }}>{format(new Date(docket.attributes?.createdAt), "d MMM yyyy")}</td>
                      <td>{docket.attributes?.number}</td>
                      <td>{docket.attributes?.surveyorAssignmentName}</td>
                      <td>{docket.attributes?.creator}</td>
                      <td className={DOCKET_STATUS[docket.attributes?.status].bgColor}>
                        {DOCKET_STATUS[docket.attributes?.status].label}
                      </td>
                      {/* bg-cora bg-light-blue  */}
                      <td>
                        <div className="d-flex justify-content-end align-items-center">
                          {canDelete && (
                            <>
                              <AnchorButton
                                disabled={loading}
                                className="text-danger"
                                onClick={() => {
                                  setDocketSelectedDelete(docket)
                                }}>
                                Delete
                              </AnchorButton>
                              <span className="text-muted mx-2">|</span>
                            </>
                          )}
                          <AnchorButton type="button">
                            <Link to={`/dockets/${docket.id}`}>Detail</Link>
                          </AnchorButton>
                          <span className="text-muted ml-2">|</span>
                          <button
                            className="btn btn-link"
                            type="button"
                            disabled={loadingPdfDocketId === docket.id}
                            onClick={() => {
                              setLoadingPdfDocketId(docket.id)
                              getDocketPdf(baseUrl, docket.id).then(({ response, status }) => {
                                if (status === 200 && response.json && response.json.file_url) {
                                  const downloadLink = document.createElement("a");
                                  downloadLink.href = response.json.file_url;
                                  downloadLink.download = response.json.filename;
                                  downloadLink.target = "_blank";
                                  downloadLink.click();
                                  downloadLink.remove()
                                }
                                setLoadingPdfDocketId(null)
                              }).catch(() => setLoadingPdfDocketId(null))
                            }}>
                            {loadingPdfDocketId === docket.id ? <Spinner animation="border" size="sm" /> : <img height="22" src={filePdfIcon} />}
                          </button>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <AddDocketModal
        show={showDocketModal}
        onHide={() => setShowDocketModal(false)}
        onAddDocket={addDocketHandler}
        scheduledAssignmentsOptions={scheduledAssignmentsOptions} />
      {docketSelectedDelete && (
        <SweetAlert
          show={!!docketSelectedDelete}
          type="custom"
          customClass="text-dark"
          title={`Are you sure you want to delete docket number ${docketSelectedDelete.attributes.number} ?`}
          style={{ width: "600px" }}
          confirmBtnText="Delete"
          confirmBtnCssClass="btn btn-danger"
          closeOnClickOutside
          allowEscape={false}
          showCancel
          confirmBtnStyle={{
            border: "none",
            boxShadow: "none"
          }}
          onCancel={() => setDocketSelectedDelete(null)}
          onConfirm={() => deleteDocketHandler(docketSelectedDelete.id)}>
          <p className="text-muted">
            This action cannot be undone.
          </p>
        </SweetAlert>
      )}
      {loading && <Loader />}
    </div>
  )
}
