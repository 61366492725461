import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import cx from "classnames"
import { updateJobService, getSuggestedSurveyor } from "requesters/jobServiceRequester"
import { getBaseUrl } from "../../reducers";

const SelectSurveyorButton = ({
  btnClassName, disabled, rowData, setTargetId, reloadData, onChangeSurveyorFailed
}) => {
  const {
    attributes: {
      surveyor_name: surveyorName,
      surveyor_id: surveyorId,
      role_label: roleLabel,
      job_service: {
        data: {
          id: jobServiceId,
          attributes: {
            draftedPrimarySurveyorName
          }
        }
      }
    },
    id: assignmentId
  } = rowData
  const [showSelectSurveyorView, setShowSelectSurveyorView] = useState(false)
  const [suggestedSurveyors, setSuggestedSurveyors] = useState({ suggested: [], other: [] })
  const baseUrl = useSelector(getBaseUrl)

  const fetchSuggestedSurveyorData = () => {
    getSuggestedSurveyor(baseUrl, jobServiceId)
    .then(res => {
      if (res.status === 200 && res.response?.suggested_surveyors) {
        setSuggestedSurveyors(res.response.suggested_surveyors)
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }

  const onChangeSurveyor = async (e) => {
    const { response, error } = await updateJobService(baseUrl, jobServiceId, { drafted_primary_surveyor_id: e.target.value })
    if (response) {
      setTargetId(assignmentId)
      reloadData()
    }
    if (error) {
      onChangeSurveyorFailed()
    }
  }

  const formattedSurveyorName = () => {
    if (roleLabel === "Primary Surveyor") {
      return surveyorName || draftedPrimarySurveyorName
    }
    return surveyorName
  }

  const formattedRoleLabel = () => (roleLabel !== "Primary Surveyor" ? <i>{`(${roleLabel})`}</i> : null)

  useEffect(() => {
    if (showSelectSurveyorView) {
      fetchSuggestedSurveyorData()
    }
  }, [showSelectSurveyorView])

  return (
    <React.Fragment>
      <button
        style={{ minHeight: "50px" }}
        disabled={disabled}
        className={`btn ${btnClassName}`}
        type="button"
        onClick={() => setShowSelectSurveyorView(true)}>
        {formattedSurveyorName()}
        <div>{formattedRoleLabel()}</div>
      </button>
      <div
        onMouseLeave={() => setShowSelectSurveyorView(false)}
        className={cx("dropdown-menu shadow-sm", { "show-modal": showSelectSurveyorView })}>
        <p className="small mb-2">Select surveyor</p>
        <select
          className="form-control"
          value={surveyorId || ""}
          onChange={e => {
            onChangeSurveyor(e)
            setShowSelectSurveyorView(false)
          }}>
          {
            suggestedSurveyors && Object.keys(suggestedSurveyors).map(group => {
              const groupName = group.toUpperCase()
              const options = suggestedSurveyors[group]

              return (
                <optgroup label={groupName} key={groupName}>
                  { options.map(o => <option key={`${o.value}-${o.label}`} value={o.value} disabled={o.disabled}>{o.label}</option>) }
                </optgroup>
              )
            })
          }
        </select>
      </div>
    </React.Fragment>
  )
}

export default SelectSurveyorButton