import React from "react";
import {
  TO_INVOICE,
  PENDING_PAYMENT,
  OVERSEAS
} from "../../../../../../../utils/constants";

const ActivityTimelinePoint = ({
  event, state, mainActivity, lastActivity
}) => {
  const getClassesByEvent = () => {
    switch (event) {
      case TO_INVOICE:
        return "circle circle-yellow";
      case PENDING_PAYMENT:
        if (state === "pending" || state === "to_invoice") return "circle circle-yellow";
        break;
      case ["To Confirm", "Field Work", "To Draft", "To Review", "Qa"]:
        return "circle circle-blue";
      case OVERSEAS:
        return "circle circle-yellow";
      case "Ready To Send":
        return "circle circle-green"
      default:
        return "";
    }
  }

  const checkpointClasses = () => {
    if (state === "sent") return "circle circle-green";
    if (lastActivity) return "circle circle-grey";
    const classNames = mainActivity ? getClassesByEvent() : "circle-small"
    return classNames
  }

  return (
    <div className={checkpointClasses()} />
  )
}

export default ActivityTimelinePoint