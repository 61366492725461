import React from "react"
import { useSelector } from "react-redux"
import { format } from "date-fns"
import { Link } from "react-router-dom";

export default function GearHistoryRecord({ gear_history_record }) {
  const { gearActionTypes } = useSelector(state => state.options)

  const {
      attributes
  } = gear_history_record

  const {
      id,
      reportDate,
      action,
      notes,
      userName,
      createdAt,
      createdByName
  } = attributes

  const actionLabel = gearActionTypes.filter(gear => gear[1] === action)[0][0]

  return (
    <tr onClick={() => { window.location.href = `/settings/gears/history/${id}` }} className="cursor-pointer">
      <td>{reportDate}</td>
      <td>{actionLabel === "Archive" ? "Archived" : actionLabel}</td>
      <td>{userName}</td>
      <td>{notes}</td>
      <td>{createdByName}</td>
      <td>{format(new Date(createdAt), "dd/MM/yyyy, hh:mma")}</td>
      <td className="d-flex align-items-center justify-content-end">
        <Link to={`/settings/gears/history/${id}`}>Detail</Link>
      </td>
    </tr>
  )
}