import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Modal, Spinner } from "react-bootstrap"
import { multipleUploadFile, markUploadedFilesAsType } from "requesters/jobServiceRequester"
import SweetAlert from "react-bootstrap-sweetalert"
import { getBaseUrl, getCurrentUser } from "../../../reducers"
import {
  MAX_SIZE_UPLOADED_FILE,
  MAX_SIZE_UPLOADED_FILE_TEXT,
  MARK_AS_TYPES,
  RECEIVED_PLANS_CATEGORY
} from "../../../../../utils/constants"
import Dropzone from "../../../../../components/utils/Dropzone"
import CustomCheckItem from "../../../../../components/CustomCheckItem"

const AddUsedPlansForCalcModal = ({
  show, onHide, jobService, reloadData, assignment
}) => {
  const baseUrl = useSelector(getBaseUrl)
  const currentUser = useSelector(getCurrentUser)
  const {
    id,
    attributes: { jobInstructions, plansUsedForCalcFiles }
  } = jobService
  const usedPlans = plansUsedForCalcFiles.map(file => file?.data?.id)
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [largeUploads, setLargeUploads] = useState([])
  const [uploading, setUploading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [filesCollectionError, setFilesCollectionError] = useState(false)
  const [markingUsedPlans, setMarkingUsedPlans] = useState(false)
  const [selectedPlans, setSelectedPlans] = useState(usedPlans)

  const uploadedPlans = jobInstructions.filter(file => file.data?.attributes?.documentCategory === RECEIVED_PLANS_CATEGORY)
                                        .sort((a, b) => selectedPlans.includes(b?.data?.id) - selectedPlans.includes(a?.data?.id));

  const handlePreview = e => {
    const files = e.value
    const largeFiles = files?.filter(file => file.size > MAX_SIZE_UPLOADED_FILE)
    const filesCollectionSize = files?.reduce((collectionSize, file) => collectionSize + file.size)

    if (largeFiles?.length) {
      setLargeUploads(largeFiles)
      setUploadedFiles([])
      setShowError(true)
    } else if (filesCollectionSize > MAX_SIZE_UPLOADED_FILE) {
      setUploadedFiles([])
      setFilesCollectionError(true)
    } else {
      setUploadedFiles(files)
    }
  }

  const multipleFilesUpload = () => {
    const formData = new FormData();
    uploadedFiles.forEach((file, index) => {
      formData.append(`file-${index}`, file);
      formData.append("document_category", RECEIVED_PLANS_CATEGORY)
      formData.append("created_by_user_id", currentUser.id)
      if (assignment) {
        formData.append("surveyor_assignment_id", assignment.surveyorAssignmentId)
      }
    });
    setUploading(true)
    multipleUploadFile(baseUrl, id, formData)
    .then(res => {
      if (res.status === 201 && res.response.uploaded_file_ids.length > 0) {
        const newSelectedPlans = [...selectedPlans, ...res.response.uploaded_file_ids].map(id => id.toString())
        setSelectedPlans(newSelectedPlans)
        setUploading(false)
        setUploadedFiles([])
        reloadData()
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }

  const onSaveSelectedPlans = () => {
    if (!selectedPlans) return;
    setMarkingUsedPlans(true)
    markUploadedFilesAsType(baseUrl, id, selectedPlans, MARK_AS_TYPES.plans_used_for_calc, false)
    .then(res => {
      if (res.status === 200) {
        reloadData()
        onHide()
        setUploadedFiles([])
        setMarkingUsedPlans(false)
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }

  const onChangeUsedPlans = (e, elementID, fileName) => {
    const checkboxChecked = e.target.checked
    const uploadedFileID = elementID.split("-")?.pop()
    const isSelectedForPlans = selectedPlans.includes(uploadedFileID)

    if (checkboxChecked && !isSelectedForPlans) {
      setSelectedPlans([...selectedPlans, uploadedFileID])
    } else if (!checkboxChecked && isSelectedForPlans) {
      const newSelectedPlans = selectedPlans.filter(selectedId => selectedId !== uploadedFileID)
      setSelectedPlans(newSelectedPlans)
    }
  }

  useEffect(() => {
    setSelectedPlans(usedPlans)
    setUploadedFiles([])
    setLargeUploads([])
    setShowError(false)
    setFilesCollectionError(false)
  }, [show])

  const UploadedPlanElement = file => {
    const {
      data: { id, attributes: { fileName } }
    } = file
    const isUsedPlans = selectedPlans.includes(id)

    return (
      <div key={id} className={`row mb-0 py-2 ${isUsedPlans ? "hightlighted hl-warning" : ""}`}>
        <div className="col-6">
          <i className="bi bi-file-earmark mr-2"></i>
          {fileName}
        </div>
        <div className="col-4 text-center">
          { isUsedPlans ? "Plans Used for Calculations" : RECEIVED_PLANS_CATEGORY }
        </div>
        <div className="col-2">
          <CustomCheckItem
            id={`uploaded-file-${id}`}
            name={fileName}
            label="Used Plan"
            iconClasses="font-085rem"
            checked={isUsedPlans}
            setChecked={onChangeUsedPlans} />
        </div>
      </div>
    )
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <h2 className="modal-title">SELECT USED PLANS FOR CALC</h2>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
        </form>
        <div className="mt-2">
          <div className="form-group mt-4">
            <Dropzone
              wrapperClass="drag-drop"
              multiple
              name="used-plans"
              setUploadedFiles={setUploadedFiles}
              handleFileUpload={handlePreview} />
          </div>
          <div className="mt-4">
            { uploadedFiles.map(file => (
              <p key={file.path} className="file-list-item mb-0">
                <i className="bi bi-file-earmark mr-2"></i>
                {file.path}
              </p>
            ))}
          </div>
          <div className="text-right">
            <button
              className="btn btn-outline-primary btn-sm"
              disabled={!uploadedFiles?.length || largeUploads?.length || uploading}
              onClick={multipleFilesUpload}
              type="submit">
              {uploading ? <Spinner animation="border" /> : `Upload plans` }
            </button>
          </div>
        </div>
        <hr className={uploadedPlans?.length > 0 ? "" : "hr-normal"} />

        { uploadedPlans?.length > 0 ? (
          <div>
            <p>Uploaded Plans</p>
            <div className="ml-0 mt-1">
              { uploadedPlans.map(file => UploadedPlanElement(file)) }
            </div>
          </div>
        ) : (
          <p>
            No Existing Received Plans - Please Upload
          </p>
        )}

        <div className="modal-footer d-flex justify-content-end px-0 py-4">
          <button
            className="btn btn-link btn-lg"
            onClick={onHide}
            type="submit">
            Close
          </button>
          <button
            className="btn btn-red btn-lg"
            onClick={onSaveSelectedPlans}
            disabled={!selectedPlans?.length || markingUsedPlans}
            type="submit">
            {markingUsedPlans ? <Spinner animation="border" /> : `Save` }
          </button>
        </div>
      </div>

      <SweetAlert
        show={showError}
        type="error"
        title="File size exceeded!"
        style={{ width: "600px" }}
        closeOnClickOutside
        allowEscape={false}
        onConfirm={() => {
          setShowError(false)
          setLargeUploads([])
          }}>
        Max size is {MAX_SIZE_UPLOADED_FILE_TEXT}
      </SweetAlert>

      <SweetAlert
        show={filesCollectionError}
        type="error"
        title="File sizes exceeded!"
        style={{ width: "600px" }}
        closeOnClickOutside
        allowEscape={false}
        onConfirm={() => setFilesCollectionError(false)}>
        Max size for a collection of files is {MAX_SIZE_UPLOADED_FILE_TEXT}
      </SweetAlert>
    </Modal>
  )
  }
export default AddUsedPlansForCalcModal
