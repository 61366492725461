import React from "react"
import BookingRow from "./BookingRow"

const RowComponent = ({ days }) => (
  <>
    {days?.length && days.map(day => {
        const { bookings, date, totalPrice, targetAmount, targetCompletePercentage } = day
        return (
          <>
            {bookings.map(booking => (
              <BookingRow date={date} booking={booking} />
              ))}
            <tr>
              <td colSpan="9" className="text-right font-weight-bold text-white bg-secondary">
                <span>{`Target: ${targetAmount} (${targetCompletePercentage}%)`}</span>
                <span className="ml-3">{totalPrice}</span>
              </td>
            </tr>
          </>
        )
      })}
  </>
  )

export default RowComponent