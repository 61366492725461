
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { roleCheck } from "utils/index";
import {
  REGISTERED_SURVEYORS_MANAGER
} from "utils/constants"
import { getBaseUrl } from "../../reducers"
import { 
  getRSManagerDashboardData, 
  getOverdueJobsCount,
  getOnHoldJobServiceCount,
  getClientDelayedJobServiceCount
} from "../../../../requesters/jobServiceRequester";
import DashboardCard from "./components/DashboardCard";

export default function DashboardForRegisteredSurveyor({ currentUser }) {
  const baseUrl = useSelector(getBaseUrl)
  const { attributes: { firstname } } = currentUser
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role
  const isRegisteredSurveyorManager = roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)

  const [jobsReady, setJobsReady] = useState(0)
  const [forPlanReport, setForPlanReport] = useState(0)
  const [overdueJobs, setOverdueJobs] = useState(0)
  const [jobsOutstandingInvoice, setJobsOutstandingInvoice] = useState(0)
  const [onHoldJobService, setOnHoldJobService] = useState(0)
  const [clientDelayedJobService, setClientDelayedJobService] = useState(0)

  useEffect(() => {
    getRSManagerDashboardData(baseUrl)
      .then(res => {
        if (res.status === 200) {
          setJobsReady(res.response.jobs_ready_count)
          setJobsOutstandingInvoice(res.response.jobs_outstanding_invoice_count)
          setForPlanReport(res.response.for_plan_report_count)
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }, [])

  return (
    <div className="dashboard">
      <div className="row no-gutters">
        <div className="col-lg-4 col-xl-3">
          <div className="container-fluid">
            <div className="container-inner">
              <div className="greeting">
                Hello,
                {" "}
                <br />
                {" "}
                {firstname}
                {" "}
              </div>
            </div>
          </div>
        </div>
        {isRegisteredSurveyorManager && (
          <div className="col-lg-8 col-xl-9 today">
            <div className="container-fluid">
              <div className="container-inner">
                <h2>{"today".toUpperCase()}</h2>
                <div className="row key-to-do">
                  <div className="col-lg-10">
                    <div className="row mt-4">
                      <DashboardCard
                        link="/live-job-status?filter=ready_to_send_for_rs_manager"
                        title="Jobs Ready To Send"
                        count={jobsReady}
                        colorClass={jobsReady === 0 ? "bg-light" : "bg-coral"} />
                      <DashboardCard
                        link="/live-job-status?filter=for_plan_report"
                        title="Plan Report"
                        count={forPlanReport}
                        colorClass={forPlanReport === 0 ? "bg-light" : "bg-coral"} />
                      <DashboardCard
                        link="/live-job-status?filter=jobs_overdue"
                        title="Client Jobs Overdue"
                        selfFetchingCount
                        getCountRequester={getOverdueJobsCount}
                        setCount={setOverdueJobs}
                        count={overdueJobs}
                        colorClass={overdueJobs === 0 ? "bg-light" : "bg-coral"} />
                      <DashboardCard
                        link="/live-job-status?filter=jobs_is_outstanding_invoice"
                        title="Outstanding Invoices"
                        count={jobsOutstandingInvoice}
                        colorClass={jobsOutstandingInvoice === 0 ? "bg-light" : "bg-coral"} />
                      <DashboardCard
                        link="/flagged-jobs?filter=on_hold"
                        title="On Hold"
                        selfFetchingCount
                        getCountRequester={getOnHoldJobServiceCount}
                        setCount={setOnHoldJobService}
                        count={onHoldJobService}
                        colorClass={onHoldJobService === 0 ? "bg-light" : "bg-coral"} />
                      <DashboardCard
                        link="/flagged-jobs?filter=client_delayed"
                        title="Jobs Client Delayed"
                        selfFetchingCount
                        getCountRequester={getClientDelayedJobServiceCount}
                        setCount={setClientDelayedJobService}
                        count={clientDelayedJobService}
                        colorClass={clientDelayedJobService === 0 ? "bg-light" : "bg-coral"} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
