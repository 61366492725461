/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable arrow-body-style */
import React, { useState, useCallback } from "react";
import {
  GoogleMap, Marker, withScriptjs, withGoogleMap, InfoWindow
} from "react-google-maps";
import { NEARBY_JOB_GG_MAP_MARKER_STYLES, NEARBY_JOB_GG_MAP_STYLES } from "../../../../../../utils/constants";
import LocationSummary from "./LocationSummary";
// eslint-disable-next-line no-unused-vars
const GoogleMapView = withScriptjs(withGoogleMap(props => {
  const [locationHolder, setLocationHolder] = useState(null);
  const { nearbyJobs, ...otherProps } = props

  const renderMarker = useCallback(() => {
    return nearbyJobs && nearbyJobs.length > 0 && nearbyJobs.map(nearbyJob => {
      const {
        attributes: {
          longitude, latitude, lastJobService
        }
      } = nearbyJob

      return (
        <Marker
          key={nearbyJob.id}
          onMouseOver={() => {
            setLocationHolder(nearbyJob)
          }}
          onClick={() => {
            if (lastJobService) {
              window.open(`/jobs/${lastJobService.jobId}/job_services/${lastJobService.id}?tab=History`, "_top")
            }
          }}
          position={{
            lat: Number(latitude),
            lng: Number(longitude)
          }} />
      )
    })
  }, [nearbyJobs])

  return (
    <GoogleMap
      {...otherProps}
      options={{ styles: NEARBY_JOB_GG_MAP_STYLES }}
      onMouseOut={() => setLocationHolder(null)}>
      {renderMarker()}

      <Marker
        icon={{
          url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
          scaledSize: new window.google.maps.Size(45, 45),
        }}
        position={{ ...props.defaultCenter }} />

      {locationHolder && locationHolder.attributes && (
        <InfoWindow
          onDomReady={() => {
            const element = document.getElementsByClassName("gm-style-iw-d")
            const closeButton = document.querySelector('button[title="Close"]');
            if (closeButton) {
              closeButton.remove();
            }
            if (element[0]) {
              element[0].style.overflow = "hidden"
              element[0].style["padding-bottom"] = "12px"
            }
          }}
          options={{
            maxWidth: 300,
            pixelOffset: new window.google.maps.Size(0, -35)
          }}
          position={{ lat: Number(locationHolder.attributes.latitude), lng: Number(locationHolder.attributes.longitude) }}>
          <div style={{ position: "relative" }}>
            <div onMouseOut={() => setLocationHolder(null)} style={NEARBY_JOB_GG_MAP_MARKER_STYLES}></div>
            <h6>{locationHolder.attributes.internalJobIdentifier}</h6>
            <p>{locationHolder.attributes.fullAddress}</p>
            <i>
              <LocationSummary nearbyJobLocation={locationHolder} />
            </i>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  )
}));

export default GoogleMapView;
