import customAxios from "./customAxios";

export const postUserBlockOut = (baseUrl, params) => {
  const path = `${baseUrl}/user_block_out`;
  return customAxios.post(path, params)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }))
}

export const putUserBlockOut = (baseUrl, id, params) => {
  const path = `${baseUrl}/user_block_out/${id}`;
  return customAxios.put(path, params)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }))
}

export const deleteUserBlockOut = (baseUrl, id) => {
  const path = `${baseUrl}/user_block_out/${id}`;
  return customAxios.delete(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }))
}
