/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom";
import { getJobServiceByScheduledDate } from "requesters/jobServiceRequester";
import { useSelector } from "react-redux"
import { StringParam, useQueryParam } from "use-query-params"
import { round } from "lodash";
import { format } from "date-fns"
import Loader from "components/Loader"
import FilterDropdown from "components/FilterDropdown"
import GoogleMapView from "./GoogleMapView"
import { getBaseUrl } from "../../../reducers";
import CADatePicker from "../../../components/Calendar/DatePicker"
import AddressInput from "../../../../../components/AddressInput";

export default function SchedulesMapViewsPage() {
  const [surveyorOptions, setSurveyorOptions] = useState([])
  const [fetchingDirectionFailed, setFetchingDirectionFailed] = useState(false)
  const [legs, setLegs] = useState([]);
  const [selectedSurveyorId, setSelectedSurveyorId] = useState(null)
  const [jobServices, setJobServices] = useState([])
  const [allJobServices, setAllJobServices] = useState([])
  const [loading, setLoading] = useState(true)
  const [tempWaypoints, setTempWaypoints] = useState([]);
  const [additionalDestination, setAdditionalDestination] = useState("")
  const baseUrl = useSelector(getBaseUrl)
  const [scheduledDate, setScheduledDate] = useQueryParam("scheduled_date", StringParam);
  const [calendarDate, setCalendarDate] = useState(() => 
    scheduledDate ? new Date(scheduledDate) : new Date()
  );

  useEffect(() => {
    const formattedDate = format(calendarDate, "yyyy-MM-dd")
    if (formattedDate !== scheduledDate) {
      setScheduledDate(formattedDate);
    }
  }, [calendarDate]);

  const getJobServiceValid = dataJobServices => {
    const jobServicesValid = []
    Object.keys(dataJobServices).forEach(key => {
      if (dataJobServices[key].attributes.addressLatitude && dataJobServices[key].attributes.addressLongitude) {
        jobServicesValid.push(dataJobServices[key])
      }
    })
    return jobServicesValid
  }

  const getSurveyorsOptions = jobServicesData => {
    const surveyorIds = []
    const options = []
    jobServicesData.forEach(item => {
      if (!surveyorIds.includes(item.attributes.draftedPrimarySurveyorId)) {
        options.push([item.attributes.draftedPrimarySurveyorName, item.attributes.draftedPrimarySurveyorId])
        surveyorIds.push(item.attributes.draftedPrimarySurveyorId)
      }
    })
    return options
  }

  const requestJobServices = () => {
    getJobServiceByScheduledDate(baseUrl, { scheduled_date: scheduledDate })
      .then(res => {
        if (res.status === 200 && res.response && res.response.jobService) {
          const jobServiceValid = getJobServiceValid(res.response.jobService)
          const options = getSurveyorsOptions(jobServiceValid)
          // The order of setStates very important here
          setAllJobServices(jobServiceValid)
          setSurveyorOptions(options)
          setSelectedSurveyorId(null)
          setJobServices(jobServiceValid)
        } else {
          setAllJobServices([])
          setSurveyorOptions([])
          setSelectedSurveyorId(null)
          setJobServices([])
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (selectedSurveyorId) {
      const jobServicesFiltered = allJobServices.filter(item => item.attributes.draftedPrimarySurveyorId === selectedSurveyorId)
      setJobServices(jobServicesFiltered)
    } else {
      setJobServices(allJobServices)
    }
    setTempWaypoints([])
    setFetchingDirectionFailed(false) // reset init state
  }, [selectedSurveyorId])

  useEffect(() => {
    if (scheduledDate) {
      requestJobServices()
    }
  }, [scheduledDate])

  const getAddressSuburb = address => {
    const addressSuburb = address?.split(",")?.slice(1, 2)[0]
    const suburbOnly = addressSuburb?.split("NSW")?.shift()
    return suburbOnly
  }

  const onPlaceChange = (place, location) => {
    const newWaypoint = {
      full_address: place.description,
      location: {
        lat: location.lat,
        lng: location.lng
      },
      stopover: true
    };
    setTempWaypoints([...tempWaypoints, newWaypoint]);
    setAdditionalDestination("")
  };

  const AdditionalRoutesSection = () => {
    return (
      <div className="temporary-route-section mt-5">
        <h6><b>Add Temporary routes</b></h6>
        <div className="temp-routes mb-3">
          { tempWaypoints.map(waypoint => (
            <p key={waypoint.full_address}>
              <span className="mr-2">{waypoint.full_address}</span>
              <i
                className="bi bi-x-circle-fill text-danger delete-route"
                onClick={() => {
                  setTempWaypoints(tempWaypoints.filter(wp => wp.full_address !== waypoint.full_address))
                }}
                title="Delete File" />
            </p>
          ))}
        </div>
        <div className="form-group pr-3">
          <AddressInput
            value={additionalDestination}
            setValue={setAdditionalDestination}
            onPlaceChange={onPlaceChange}
            placeholder="New destination" />
        </div>
      </div>
    )
  }

  const renderContent = (loading, jobServices) => {
    if (loading) {
      return <Loader />;
    }
    if (jobServices.length === 0 || fetchingDirectionFailed) {
      return <div>No data - please check the Lat and Lon of properties for this day</div>;
    }
    const defaultCenterLocation = {
      lat: selectedSurveyorId ? jobServices[0].attributes.surveyorAddressLatitude : jobServices[0].attributes.addressLatitude,
      lng: selectedSurveyorId ? jobServices[0].attributes.surveyorAddressLongitude : jobServices[0].attributes.addressLongitude
    }
    const totalDuration = legs.reduce((total, leg) => total + Math.round(leg.duration?.value / 60), 0)
    const totalDistance = legs.reduce((total, leg) => total + round(leg.distance?.value / 1000, 1), 0)

    return (
      <div className="w-100 d-flex">
        <GoogleMapView
          setLegs={setLegs}
          onFetchingDirectionFailed={() => setFetchingDirectionFailed(true)}
          onFetchingDirectionSuccess={() => setFetchingDirectionFailed(false)}
          selectedSurveyorId={selectedSurveyorId}
          jobServices={jobServices}
          tempWaypoints={tempWaypoints}
          center={defaultCenterLocation}
          defaultZoom={15}
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCjB3Ue5bHZwF8QkwobkSYCUAJ35pIZCXk&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: "100%" }} />}
          containerElement={<div style={{ height: "700px", width: `${selectedSurveyorId ? "75%" : "100%"}` }} />}
          mapElement={<div style={{ height: "100%" }} />} />
        {selectedSurveyorId && (
          <div style={{
            width: "25%", padding: "20px", backgroundColor: "#f9f9f9", borderLeft: "1px solid #ddd"
          }}>
            <div className="routes-info-section">
              <h4>Route Information</h4>
              {legs.length > 0 && (
                <ul style={{ listStyle: "none", padding: 0 }}>
                  { legs.map((leg, index) => {
                    const originLabel = `${getAddressSuburb(leg.start_address)} (${index === 0 ? "H" : index})`
                    const destinationLabel = `${getAddressSuburb(leg.end_address)} (${index === (legs.length - 1) ? "H" : index + 1})`
                    const routeInfo = `${originLabel} to ${destinationLabel}`
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={index} style={{ marginBottom: "15px" }}>
                        <strong>
                          {routeInfo}
                        </strong>
                        <div>
                          {leg.duration.text}
                          {" "}
                          (
                          {leg.distance.text}
                          )
                        </div>
                      </li>
                    )
                  })}
                  <li>
                    <strong>Total Travel</strong>
                    <div>
                      {`${totalDuration} mins (${round(totalDistance, 2)}km)`}
                    </div>
                  </li>
                </ul>
              )}
            </div>
            {selectedSurveyorId && AdditionalRoutesSection()}
          </div>
        )}
      </div>
    );
  };

  const backButton = () => {
    const history = useHistory();

    return (
      <button className="btn btn-link px-0" onClick={() => history.goBack()}>Go Back</button>
    );
  };

  return (
    <div className="container-fluid mb-5">
      <div className="container-inner">
        <div style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          paddingBottom: "20px",
          paddingTop: "20px",
          backgroundColor: "white"
        }}>
          {backButton()}
          <div className="d-md-flex justify-content-between align-items-center header-tabs-container">
            <div className="header-tabs">
              <h1>Schedules Map View</h1>
            </div>
          </div>
          <div className="table-listing-filters mb-2 d-md-flex justify-content-between">
            <div className="btn-group mr-2">
              <FilterDropdown
                title="Surveyor"
                filteredName={surveyorOptions.find(option => option[1] === selectedSurveyorId)?.[0]}
                options={surveyorOptions}
                setFilter={setSelectedSurveyorId} />
              <CADatePicker {...{ calendarDate, setCalendarDate }} />
              <div className="d-flex align-items-center px-2">
                {scheduledDate}
              </div>
            </div>
          </div>
          {renderContent(loading, jobServices)}
        </div>
      </div>
    </div>
  )
}
