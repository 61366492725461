import React, { useState, useEffect } from "react"
import DatePicker from "react-datepicker"
import { useSelector } from "react-redux"
import { upsertJobServiceRsReportDraft } from "requesters/jobServiceRequester"
import { Modal } from "react-bootstrap"
// import { fromCallback } from "bluebird"
import Select from "react-select"
import { format } from "date-fns";
import { getBaseUrl } from "../../../reducers"

const GenerateRsReportModal = ({
  show, onHide, jobService, fetchRsDraftReports, reloadData, currentReport
}) => {
  const baseUrl = useSelector(getBaseUrl)
  const {
          attributes: {
                        code,
                        label,
                        jobFullAddress,
                        jobSuburb,
                        rsRegistrationId,
                        registeredSurveyorId,
                        invoiceContacts,
                        location,
                      }, id
        } = jobService

  const firstInvoiceContact = invoiceContacts.find(contact => (contact?.contactType === "Invoice")) || invoiceContacts[0]
  const INITIAL_STATE = {
    area: jobSuburb,
    client_address: jobFullAddress,
    client_name: firstInvoiceContact?.name,
    content: "",
    county: location.county,
    deposited_plan_no: location.depositedPlan,
    job_service_name: label,
    lga: location.lga,
    lot_no: location.lotNumber,
    parish: location.parish,
    plan_sketch_prepared_at: null,
    section_no: location.sectionNo,
    subject: "",
    address_detail: "",
    rs_registration_id: rsRegistrationId || "",
    product_code: code,
  }

  const [form, setForm] = useState(INITIAL_STATE)
  const [section, setSection] = useState(currentReport?.id ? 2 : 1)
  const [validForm, setValidForm] = useState(true)

  useEffect(() => {
    setSection(currentReport?.id ? 2 : 1)
    if (currentReport?.id) {
      const {
        attributes: {
                      area,
                      clientAddress,
                      clientName,
                      content,
                      county,
                      depositedPlanNo,
                      jobServiceName,
                      lga,
                      lotNo,
                      parish,
                      planSketchPreparedAt,
                      rsRegistrationId,
                      sectionNo,
                      subject,
                      addressDetail,
                      id: reportId,
                      productCode,
                    },
      } = currentReport

      setForm({
              area,
              client_address: clientAddress,
              client_name: clientName,
              content,
              county,
              deposited_plan_no: depositedPlanNo,
              job_service_name: jobServiceName,
              lga,
              lot_no: lotNo,
              parish,
              plan_sketch_prepared_at: new Date(planSketchPreparedAt),
              section_no: sectionNo,
              subject,
              address_detail: addressDetail,
              rs_registration_id: rsRegistrationId || "",
              report_id: reportId,
              product_code: productCode || code,
            })
    } else {
      setForm(INITIAL_STATE)
    }
  }, [show])


  const contactList = invoiceContacts.map(contact => ({
    value: contact?.name,
    label: `${contact?.name} (${contact?.contactType})`,
  }))

  const [selectedContact, setSelectedContact] = useState({
    value: firstInvoiceContact?.name,
    label: firstInvoiceContact ? `${firstInvoiceContact?.name} (${firstInvoiceContact?.contactType})` : "",
  })

  const handleChangeContact = e => {
    setSelectedContact(e)
    setForm({ ...form, client_name: e.value })
  }

  const handleChange = e => {
    const { name, value } = e.target
    setForm({ ...form, [name]: value })
  }

  const createTemplateContent = () => {
    const section_sentence = form.section_no ? ` of Section ${form.section_no}` : ""
    const section = form.section_no ? `${form.section_no}/` : ""
    const dateFormatted = "{{PlanSketchPreparedDate}}"
    const addressDetail = `${form.client_name}\n${form.client_address}`
    const subjectTemplate = `Re: ${form.job_service_name}\nLot ${form.lot_no}${section_sentence} in deposited plan ${form.deposited_plan_no}\nNo. ${form.client_address}`
    const contentTemplate = `We have surveyed the land comprised in Certificate of Title Folio Identifier ${form.lot_no}/${section}${form.deposited_plan_no} being Lot ${form.lot_no}${section_sentence} in Deposited Plan ${form.deposited_plan_no} located at ${form.area} in the Local Government Area of ${form.lga} Parish of ${form.parish} and County of ${form.county}.\n\nUpon the abovementioned property stands a XXXX and XXXX known as No. ${form.client_address}.\n\nThe positions of the XXXX and XXXX in relation to the boundaries are as shown on the attached plan. Levels across the site have also been determined.\n\nThere is a Proposed Acquisition Pursuant to Section 11 Land Acquisition (Just Terms Compensation) Act, 1991 affecting the parts described in AQ470001 on the title document.\n\nThis report should be read in conjunction with the attached plan prepared by us dated ${dateFormatted} which forms part of this report.\n\nThe survey shown on the attached plan is for detail and identification survey purposes only. Should further additions or improvements be proposed upon the subject property after this date the boundaries must be marked on the ground prior to construction.`
    setForm({
      ...form, subject: subjectTemplate, content: contentTemplate, address_detail: addressDetail
    })
  }

  const showErrorMessage = attributes => {
    if (!validForm && !attributes) {
      return true
    }
    return false
  }

  const sendToTemplateSection = () => {
    if (!form.client_name || !form.client_address || !form.job_service_name || !form.lot_no || !form.deposited_plan_no || !form.lga || !form.area || !form.parish || !form.county) {
      setValidForm(false)
    } else {
      setValidForm(true)
      createTemplateContent()
      setSection(2)
    }
  }

  const saveDraft = () => {
    const isValid = rsRegistrationId
      ? form.rs_registration_id && form.plan_sketch_prepared_at
      : true;

    if (!isValid) {
      setValidForm(false);
      return;
    }

    upsertJobServiceRsReportDraft(baseUrl, id, "upsert-rs-report-draft", form)
      .then(() => {
        reloadData();
        fetchRsDraftReports();
        onHide();
      })
      .catch(err => console.log(err));
  };


  const regenerateReport = () => {
    setSection(1)
  }

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title><h2 className="modal-title">Registered Surveyor Report</h2></Modal.Title>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          {section === 1 && (
            <>
              <div className="row mb-3">
                <label className="col-4">Client Name*</label>
                <div className="col-8">
                  <Select
                    name="client_name"
                    // isClearable
                    isSearchable
                    onChange={handleChangeContact}
                    options={contactList}
                    value={selectedContact} />

                  {showErrorMessage(form.client_name) && (
                    <div className="err-msg">Required Field</div>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-4">Property Address*</label>
                <div className="col-8">
                  <input
                    name="client_address"
                    value={form.client_address}
                    onChange={handleChange}
                    className="form-control" />

                  {showErrorMessage(form.client_address) && (
                    <div className="err-msg">Required Field</div>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-4">Job Service Name*</label>
                <div className="col-8">
                  <input
                    name="job_service_name"
                    value={form.job_service_name}
                    onChange={handleChange}
                    className="form-control" />

                  {showErrorMessage(form.job_service_name) && (
                    <div className="err-msg">Required Field</div>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-4">Lot No*</label>
                <div className="col-8">
                  <input
                    name="lot_no"
                    value={form.lot_no}
                    onChange={handleChange}
                    className="form-control" />

                  {showErrorMessage(form.lot_no) && (
                    <div className="err-msg">Required Field</div>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-4">Section No</label>
                <div className="col-8">
                  <input
                    name="section_no"
                    value={form.section_no}
                    onChange={handleChange}
                    className="form-control" />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-4">Deposited Plan No*</label>
                <div className="col-8">
                  <input
                    name="deposited_plan_no"
                    value={form.deposited_plan_no}
                    onChange={handleChange}
                    className="form-control" />

                  {showErrorMessage(form.deposited_plan_no) && (
                    <div className="err-msg">Required Field</div>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-4">LGA*</label>
                <div className="col-8">
                  <input
                    name="lga"
                    value={form.lga}
                    onChange={handleChange}
                    className="form-control" />

                  {showErrorMessage(form.lga) && (
                    <div className="err-msg">Required Field</div>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-4">Area*</label>
                <div className="col-8">
                  <input
                    name="area"
                    value={form.area}
                    onChange={handleChange}
                    className="form-control" />

                  {showErrorMessage(form.area) && (
                    <div className="err-msg">Required Field</div>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-4">Parish*</label>
                <div className="col-8">
                  <input
                    name="parish"
                    value={form.parish}
                    onChange={handleChange}
                    className="form-control" />

                  {showErrorMessage(form.parish) && (
                    <div className="err-msg">Required Field</div>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-4">County*</label>
                <div className="col-8">
                  <input
                    name="county"
                    value={form.county}
                    onChange={handleChange}
                    className="form-control" />

                  {showErrorMessage(form.county) && (
                    <div className="err-msg">Required Field</div>
                  )}
                </div>
              </div>
            </>
          )}

          {section === 2 && (
            <>
              <div className="form-group">
                <label>Address Details</label>
                <textarea
                  className="form-control mr-5"
                  name="address_detail"
                  rows="3"
                  value={form.address_detail}
                  onChange={handleChange} />

              </div>
              <div className="form-group">
                <label>Subject</label>
                <textarea
                  className="form-control mr-5 text-uppercase"
                  name="subject"
                  rows="3"
                  value={form.subject}
                  onChange={handleChange} />

              </div>
              <div className="form-group">
                <label>Template Content</label>
                <textarea
                  className="form-control mr-5"
                  name="content"
                  rows="8"
                  value={form.content}
                  onChange={handleChange} />

              </div>
              <div className="form-group">
                <label> Product Code</label>
                <div>
                  <input
                    name="product_code"
                    value={form.product_code}
                    onChange={handleChange}
                    className="form-control" />
                </div>
              </div>
              <div className="form-group">
                <label
                  className={registeredSurveyorId ? 'required' : ''}
                  title="To default this ID update in Profile">
                  Registered Surveyor ID
                </label>
                <div>
                  <input
                    name="rs_registration_id"
                    value={form.rs_registration_id}
                    onChange={handleChange}
                    className="form-control" />

                  {showErrorMessage(form.rs_registration_id) && (
                    <div className="err-msg">Required Field</div>
                  )}
                </div>
              </div>

              <div className="form-group">
                <label className={registeredSurveyorId ? 'required' : ''}>Plan/Sketch Prepared</label>
                <div>
                  <DatePicker
                    name="plan_sketch_prepared_at"
                    placeholderText="DD/MM/YYYY"
                    selected={form.plan_sketch_prepared_at}
                    dateFormat="dd/MM/yyyy"
                    onChange={e => setForm({ ...form, plan_sketch_prepared_at: e })}
                    className="form-control" />

                  {showErrorMessage(form.plan_sketch_prepared_at) && (
                    <div className="err-msg">Required Field</div>
                  )}
                </div>
              </div>
            </>
          )}

        </form>
        <div className="modal-footer d-flex justify-content-between p-0 mb-4 mt-5">
          <button className="btn btn-light btn-lg" data-dismiss="modal" type="button" onClick={onHide}>Close</button>
          {section === 1 && (
            <button
              className="btn btn-red"
              onClick={sendToTemplateSection}
              // disabled={!form.client_name || !form.client_address || !form.job_service_name || !form.lot_no || !form.deposited_plan_no || !form.lga || !form.area || !form.parish || !form.county || !form.rs_registration_id || !form.plan_sketch_prepared_at}
              type="button">
              Generate Template
            </button>
          )}

          {section === 2 && (
            <div>
              <button
                className="btn btn-link"
                onClick={regenerateReport}
                type="button">
                Regenerate Report
              </button>

              <button
                className="btn btn-red"
                onClick={saveDraft}
                type="submit">
                Save Draft
              </button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}
export default GenerateRsReportModal
