import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { format } from "date-fns"
import { getBaseUrl } from "apps/App/reducers"
import SweetAlert from "react-bootstrap-sweetalert"
import { updateJobServiceTransition } from "requesters/jobServiceRequester"
import cx from "classnames"
import { useQueryParam, StringParam } from "use-query-params"
import { JOB_STATES_DISPLAY } from "../utils/constants"

export default function StatusTableDropdown({
  jobService, reloadData, readOnly, surveyor
}) {
  const [showMessage, setShowMessage] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)

  const [alertTitle, setAlertTitle] = useState("")

  const baseUrl = useSelector(getBaseUrl)
  const {
    attributes: {
      state: currentState,
      disabledChangeOnSchedule,
      jobAddress,
      bookingDates,
      confirmationStatus,
      id,
    }
  } = jobService

  const [state, setState] = useState("pending")
  const [targetId, setTargetId] = useQueryParam("target_id", StringParam)


  useEffect(() => {
    setState(currentState)
  }, [currentState])

  const isUpdatable = (state === JOB_STATES_DISPLAY.to_confirm.state_name && !!surveyor && !disabledChangeOnSchedule)
  const updateJobState = transition => {
    updateJobServiceTransition(baseUrl, id, transition)
      .then(res => {
        if (res.status === 200) {
          if (res.response.primary_surveyor_assignment_id) {
            setTargetId(res.response.primary_surveyor_assignment_id)
          }
          reloadData()
        }
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
        setAlertTitle("Oops. Something went wrong")
        setShowMessage(true)
      })
  }

  const hideAlert = () => {
    setShowMessage(false)
  }

  return (
    <td className={cx(`dropdown text-center w-100px ${(jobService.attributes.state === 'to_confirm' && confirmationStatus === 'declined') ? 'bg-danger' : JOB_STATES_DISPLAY[state]?.bgClass} keep`)}>
      {(isUpdatable && !readOnly) ? (
        <>
          <button
            className="btn dropdown-toggle"
            onClick={() => {
              setShowConfirm(true)
            }}
            id={`status-update-${id}`}
            type="button">
            {JOB_STATES_DISPLAY[state]?.text}
          </button>
          <SweetAlert
            show={showConfirm}
            type="info"
            title={(
              <div style={{ fontSize: "20px" }}>
                Are you sure you wish to confirm
                {" "}
                <b>{jobAddress}</b>
                {" "}
                for
                {" "}
                <b>{format(new Date(bookingDates[0]), "h:mm aa EEEE, dd MMM yyyy")}</b>
              </div>
            )}
            showCancel
            closeOnClickOutside
            allowEscape
            onCancel={() => {
              setShowConfirm(false)
            }}
            confirmBtnText="Confirm"
            onConfirm={() => {
              setShowConfirm(false)
              updateJobState("confirm-booking")
            }} />
        </>
      ) : <button className="btn dropdown-toggle" type="button">{JOB_STATES_DISPLAY[state]?.text}</button>}
      <SweetAlert
        show={showMessage}
        type="error"
        title={alertTitle}
        closeOnClickOutside
        allowEscape={false}
        onConfirm={hideAlert} />
    </td>
  )
}
