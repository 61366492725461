import React, { useEffect, useState, memo } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Container } from "react-bootstrap"
import { get, isEmpty } from "lodash"
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "components/Loader"
import CustBreadcrumb from "components/CustBreadcrumb"
import formatDateString from "components/utils/formatDateString"
import { getTask } from "requesters/taskRequester"
import { getTimeSpents } from "requesters/timeSpentRequester"
import {
  REGISTERED_SURVEYORS_MANAGER,
  REGISTERED_SURVEYORS
} from "utils/constants"
import { roleCheck } from "utils/index"
import { getBaseUrl, getCurrentRole, getCurrentUser } from "../../../reducers"
import TaskDetailsCard from "./TaskDetailsCard"
import TransitionTaskButton from "./TransitionTaskButton"
import AddTimeSpent from "../../../../../components/AddTimeSpent"
import TimeSpentTable from "../../JobDetails/tabs/TimeSpent/TimeSpentTable"

export default function TaskDetails() {
  const { id } = useParams()
  const taskId = parseInt(id, 10)
  const baseUrl = useSelector(getBaseUrl)
  const currentUser = useSelector(getCurrentUser)
  const currentRole = useSelector(getCurrentRole)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role
  const [requesting, setRequesting] = useState(false)
  const [timeSpentRequesting, setTimeSpentRequesting] = useState(false)
  const [task, setTask] = useState(undefined)
  const [timeSpents, setTimeSpents] = useState([])
  const [hasNextPage, setHasNextPage] = useState(true)
  const [currentPage, setCurrentPage] = useState(1);
  const [showAlertForAutoCreatedTask, setShowAlertForAutoCreatedTask] = useState(false)

  const isRegisteredSurveyor = roleCheck(systemRole, currentRole, REGISTERED_SURVEYORS)
  const isRSManager = roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)
  const canWorkOnTask = isRSManager || task?.assignedUserId?.toString() === currentUser.id.toString()

  if (!isRegisteredSurveyor) window.location = "/"
  if (task && !canWorkOnTask) window.location = "/"

  const BreadcrumbElement = memo(({ taskId }) => <CustBreadcrumb breadcrumbClassNames="mb-0" links={[["Registered Surveyors Activities", "/registered-surveyors-activities"]]} active={`Activity/Task Detail : ${taskId}`} />)

  const fetchData = async () => {
    setRequesting(true)
    const { response, error } = await getTask(baseUrl, taskId)
    if (response) {
      setTask(Object.values(response.task).pop()?.attributes)
    }
    if (error) console.error(error);
    setRequesting(false)
  }

  const fetchTimeSpentData = () => {
    setTimeSpentRequesting(true)
      getTimeSpents(baseUrl, task.id, 'task', currentPage)
      .then(res => {
        if (res.status === 200) {
          const timeSpentData = get(res.response, "timeSpent", {})
          let listOfTimeSpent = Object.values(timeSpentData)
          const additionalInfo = res.response.meta?.additional_info
          const hasNextPageValue = additionalInfo && additionalInfo?.meta?.hasNextPage
          if (!isEmpty(additionalInfo)) {
            listOfTimeSpent = additionalInfo.meta?.ids.map(id => timeSpentData[id])
          }
          const newTimeSpentList = currentPage > 1 ? [...timeSpents, ...listOfTimeSpent] : listOfTimeSpent
          setHasNextPage(hasNextPageValue)
          setTimeSpents(newTimeSpentList)
        }
      })
      .catch(err => console.log(err))
      .finally(() => setTimeSpentRequesting(false))
    }

  useEffect(() => {
    fetchData()
  }, [baseUrl, taskId])

  useEffect(() => {
    if (task) {
      fetchTimeSpentData()
    }
  }, [task])

  const isAutoCreatedToReviewTask = task?.creationType === "auto" && task?.taskType === "to_review"
  const canMarkComplete = task?.state !== "completed" && task?.taskType && task?.assignedUserId && !isAutoCreatedToReviewTask

  return (
    <>
      { !requesting && task && canWorkOnTask ? (
        <Container fluid className="pb-5 task-details-page">
          <BreadcrumbElement taskId={taskId} />
          <div className="row">
            <div className="col-md-8 d-flex justify-content-end mb-2">
              { canMarkComplete && (
                <TransitionTaskButton
                  taskId={id}
                  reloadData={fetchData}
                  transitionTo="complete"
                  btnClassNames="btn btn-outline-primary mr-2"
                  btnText="Mark Complete" />
              )}

              { isAutoCreatedToReviewTask && (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => setShowAlertForAutoCreatedTask(true)}>
                    Mark Complete
                  </button>
                  <SweetAlert
                    show={showAlertForAutoCreatedTask}
                    type="warning"
                    title=""
                    style={{ width: "600px" }}
                    confirmBtnText="Go to Job"
                    onConfirm={() => {
                      window.location.href = `/jobs/${task?.jobService?.jobId}/job_services/${task?.jobService?.id}`
                    }}
                    showCancel
                    onCancel={() => setShowAlertForAutoCreatedTask(false)}
                    allowEscape={false}
                    showCloseButton
                    closeOnClickOutside>
                    "To Review" Tasks can only be complete by Moving the Job Service. Please go to the Job Service page to complete.
                  </SweetAlert>
                </>
              )}

              <div className="ml-2">
                <AddTimeSpent
                  buttonText="+ Add Time"
                  reloadData={fetchTimeSpentData}
                  trackableId={task.id}
                  trackableType="task"
                  trackedOnState={task.state} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <TaskDetailsCard isRSManager={isRSManager} task={task} id={id} reloadData={fetchData} />
            </div>
            <div className="col-md-8">
              <TimeSpentTable timeSpents={timeSpents} currentState={task.state} reloadData={fetchTimeSpentData} />
              {timeSpentRequesting && <Loader />}
            </div>
          </div>
        </Container>
      ) : <Loader /> }
    </>
  )
}
