import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
    Container, Row, Col
} from "react-bootstrap"

import { getGear } from "requesters/gearRequester"
import CustBreadcrumb from "components/CustBreadcrumb"
import { useParams } from "react-router-dom";
import { getBaseUrl } from "../../../../reducers"
import * as actionTypes from "../../../../actions/types"
import NewGearHistoryRecordForm from "./NewGearHistoryRecordForm";


export default function NewGearHistoryRecordCard() {
    const dispatch = useDispatch()
    const baseUrl = useSelector(getBaseUrl)
    const { id } = useParams()
    const gearId = parseInt(id, 10)
    dispatch({ type: actionTypes.MANAGE_GEAR_HISTORY_RECORDS_START_CREATING })

    useEffect(() => {
      async function fetchData() {
          dispatch({ type: actionTypes.GEAR_REQUEST_START })
          const { response, error } = await getGear(baseUrl, id)
          if (response) dispatch({ type: actionTypes.GEAR_REQUEST_SUCCESS, data: response })
          if (error) dispatch({ type: actionTypes.GEAR_REQUEST_FAILURE })
      }
      fetchData()
  }, [baseUrl, gearId])

    return (
      <Container fluid className="gear-details-page pb-5">
        <GearBreadcrumb />
        <GearBody />
      </Container>
    )
}

function GearBreadcrumb() {
    const { id } = useParams()
    const gear = _.get(useSelector(state => state.data.gears.byId), id)
    const { requestGearState } = useSelector(state => state.manageGearPage)
    const gear_identifier = requestGearState ? "..." : _.get(gear, `attributes.gearIdentifier`, "error")
    return <CustBreadcrumb links={[["Settings", "/settings"], ["Gears", "/settings/gears"], [gear_identifier, `/settings/gears/${id}`]]} active="Add History" />
}

function GearBody() {
  const { id } = useParams()
  const gear = _.get(useSelector(state => state.data.gears.byId), id)
    return (
      <>
        <h2 className="mb-1">Add History</h2>
        <Row>
          <Col md={8}>
            <NewGearHistoryRecordForm gear={gear} />
          </Col>
        </Row>
      </>
    )
}