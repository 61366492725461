import React from "react";

const DetailsRow = ({ days }) => {
  const RowElement = (job, idx) => {
    const {
      job_no, registered_surveyor_name, job_title, type, start_date, finish_date
    } = job

    return (
      <tr key={`${registered_surveyor_name}-${job_no}-${idx}`}>
        <td>{registered_surveyor_name}</td>
        <td>{job_no}</td>
        <td>{job_title}</td>
        <td>{type}</td>
        <td>{start_date}</td>
        <td>{finish_date}</td>
      </tr>
    )
  }

  return (
    <>
      { days.map(day => {
        const { jobs } = day

        return (
          jobs.map((job, idx) => RowElement(job, idx))
        )
      })}
    </>
  )
}

export default DetailsRow