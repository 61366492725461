import React, { useEffect, useState, useCallback } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import SweetAlert from "react-bootstrap-sweetalert";
import DatePicker from "react-datepicker";
import ReactQuill from "react-quill";
import Loader from "components/Loader"
import Dropzone from "components/utils/Dropzone";
import EditSaveButtons from "components/EditSaveCancelButton"
import { updateTask } from "requesters/taskRequester"
import { omitBy, isNull, isEmpty, get, debounce } from "lodash"
import { getBaseUrl, getCurrentUser } from "../../../reducers"
import { addPayloadToFormData, capitalizeString } from "../../../../../utils"
import {
  TASK_TYPES,
  MAX_SIZE_UPLOADED_FILE,
  MAX_SIZE_UPLOADED_FILE_TEXT,
  ADMIN
} from "../../../../../utils/constants"
import { getRegisterdSurveyors } from "../../../../../requesters/userRequester"
import { getJobServicesForRsTask } from "../../../../../requesters/jobServiceRequester"
import FileRow from "./FileRow";


export default function TaskDetailsCard({ id, task, isRSManager, reloadData }) {
  const baseUrl = useSelector(getBaseUrl)
  const currentUser = useSelector(getCurrentUser)
  const isAdmin = currentUser.attributes.role === ADMIN
  const [onEditView, setOnEditView] = useState(false)
  const [requesting, setRequesting] = useState(false)
  const [currentTask, setCurrentTask] = useState(task)

  const initialAttributes = {
    assigned_user_id: currentTask.assignedUserId || null,
    task_type: TASK_TYPES[currentTask.taskType],
    start_at: currentTask.startAt ? moment(currentTask.startAt, "YYYY-MM-DD HH:mm:ss").toDate() : null,
    finished_at: currentTask.finishedAt ? moment(currentTask.finishedAt, "YYYY-MM-DD HH:mm:ss").toDate() : null,
    notes: currentTask.notes || null
  }
  const [newTaskAttributes, setNewTaskAttributes] = useState(initialAttributes)
  const [linkToJobServiceId, setLinkToJobServiceId] = useState(currentTask.jobService?.id || null)
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [largeUploadedFiles, setLargeUploadedFiles] = useState([])
  const [showFilesError, setShowFilesError] = useState(false)
  const [regSurveyorOptions, setRegSurveyorOptions] = useState([])
  const [jobServiceOptions, setJobServiceOptions] = useState([])
  const { to_review, ...filteredTypes } = TASK_TYPES
  const taskTypeOptions = Object.keys(filteredTypes).map(type => ({ label: capitalizeString(type), value: filteredTypes[type] }))

  const fetchRegSurveyorOptions = () => {
    getRegisterdSurveyors(baseUrl)
    .then(res => {
      if (res.status === 200) {
        const newRegSurveyorOptions = res.response.map(surveyorData => ({ label: `${surveyorData[0]} ${surveyorData[1]}`, value: surveyorData[2] }))
        setRegSurveyorOptions(newRegSurveyorOptions)
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }

  const fetchJobServices = (query, callback) => {
    getJobServicesForRsTask(baseUrl, query)
    .then(res => {
      if (res.status === 200) {
        const jobServices = get(res.response, "jobService", {})
        let jobServiceList = Object.values(jobServices)
        const additionalInfo = res.response.meta?.additional_info
        if (additionalInfo && !isEmpty(additionalInfo.meta?.ids)) {
          jobServiceList = additionalInfo.meta.ids.map(id => jobServices[id])
        }
        const options = jobServiceList.map(service => ({ label: `${service.attributes.jobNo} ${service.attributes.label}`, value: service.id }))
        setJobServiceOptions(options)
        callback(options)
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }

  const debouncedFetchJobServices = useCallback(debounce(fetchJobServices, 500), [])

  const onUpdate = () => {
    setRequesting(true)
    let payload = {
      ...newTaskAttributes,
      workable_id: linkToJobServiceId,
      workable_type: "job_service",
      "uploaded_files[]": uploadedFiles
    }
    payload = omitBy(payload, isNull);
    const data = addPayloadToFormData(new FormData(), payload)

    updateTask(baseUrl, id, data)
    .then(res => {
      if (res.status === 200) {
        reloadData()
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
    .finally(() => setRequesting(false))
  }

  const onCheckingFiles = e => {
    const files = e.value
    const largeFiles = files?.filter(file => file.size > MAX_SIZE_UPLOADED_FILE)
    if (largeFiles?.length) {
      setLargeUploadedFiles(largeFiles)
      setShowFilesError(true)
    }
  }

  const onEditAttr = (key, value) => {
    const newAttr = { ...newTaskAttributes }
    newAttr[key] = value
    setNewTaskAttributes(newAttr)
  }

  useEffect(() => {
    fetchRegSurveyorOptions()
    fetchJobServices()
  }, [baseUrl, id])

  const canUpdate = onEditView && !!newTaskAttributes.task_type?.toString() && !!newTaskAttributes.assigned_user_id
  const isAutoCreatedToReviewTask = currentTask.creationType === "auto" && currentTask.taskType === "to_review"

  return (
    <div className="card border-0 shadow-sm">
      { requesting && (
        <div className="loader-overlay">
          <Loader />
        </div>
      )}
      <div className="card-body p-4">
        <div className={`row mb-${onEditView && isAutoCreatedToReviewTask ? "3" : "2"}`}>
          <div className="col-4">Task Type</div>
          <div className="col-8">
            { isRSManager && onEditView && !isAutoCreatedToReviewTask ? (
              <Select
                name="task_type"
                placeholder="Select"
                value={taskTypeOptions.find(o => o.value === newTaskAttributes.task_type)}
                options={taskTypeOptions}
                onChange={o => onEditAttr("task_type", o.value)} />
            ) : capitalizeString(currentTask.taskType) }
          </div>
        </div>
        <div className={`row mb-${onEditView && isAutoCreatedToReviewTask ? "3" : "2"}`}>
          <div className="col-4">Assigned Registered Surveyor</div>
          <div className="col-8">
            { isRSManager && onEditView && !isAutoCreatedToReviewTask ? (
              <Select
                name="assign_to"
                placeholder="Assign to RS"
                value={regSurveyorOptions.find(o => o.value === newTaskAttributes.assigned_user_id)}
                options={regSurveyorOptions}
                onChange={o => onEditAttr("assigned_user_id", o.value)} />
            ) : currentTask.assignedUserName}
          </div>
        </div>
        { isRSManager && !onEditView && (
          <div className="row mb-2">
            <div className="col-4">Created by</div>
            <div className="col-8">
              {currentTask.createdByUser?.name}
            </div>
          </div>
        )}
        <div className={`row mb-${onEditView && isAutoCreatedToReviewTask ? "3" : "2"}`}>
          <div className="col-4">Linked Job Service</div>
          <div className="col-8">
            { isRSManager && onEditView && !isAutoCreatedToReviewTask ? (
              <AsyncSelect
                name="link_to_job_service_id"
                isClearable
                isSearchable
                placeholder="Link to Job Service"
                defaultOptions={jobServiceOptions}
                options={jobServiceOptions}
                loadOptions={debouncedFetchJobServices}
                value={jobServiceOptions.find(o => o.value.toString() === linkToJobServiceId?.toString())}
                onChange={o => setLinkToJobServiceId(o.value)} />
            ) : (
              currentTask.jobService && (
                <>
                  <p className="mb-0">
                    <Link to={`/jobs/${currentTask.jobService?.jobId}/job_services/${currentTask.jobService?.id}`} className="link-no-decoration" target="_blank">
                      {`${currentTask.jobService?.jobNo} - ${currentTask.jobService?.jobAddress}`}
                    </Link>
                  </p>
                  <Link to={`/jobs/${currentTask.jobService?.jobId}/job_services/${currentTask.jobService?.id}`} className="link-no-decoration" target="_blank">
                    {`${currentTask.jobService?.label}`}
                  </Link>
                </>
              )
            )}
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-4">Start At</div>
          <div className="col-8">
            { isRSManager && onEditView ? (
              <DatePicker
                id="start_at"
                wrapperClassName="date_picker full-width w-100"
                className="form-control"
                timeFormat="HH:mm a"
                showTimeSelect
                timeIntervals={15}
                timeCaption="Time"
                selected={newTaskAttributes.start_at}
                onChange={date => onEditAttr("start_at", date)}
                dateFormat="dd/MM/yyyy, hh:mm aa" />
            ) : (currentTask.startAt && moment(currentTask.startAt).format("DD/MM/YYYY HH:mm:ss"))}
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-4">Finished At</div>
          <div className="col-8">
            { isRSManager && onEditView ? (
              <DatePicker
                id="finish_at"
                wrapperClassName="date_picker full-width w-100"
                className="form-control"
                timeFormat="HH:mm a"
                showTimeSelect
                timeIntervals={15}
                timeCaption="Time"
                selected={newTaskAttributes.finished_at}
                onChange={date => onEditAttr("finished_at", date)}
                dateFormat="dd/MM/yyyy, hh:mm aa" />
            ) : (currentTask.finishedAt && moment(currentTask.finishedAt).format("DD/MM/YYYY HH:mm:ss"))}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12 my-2">Notes</div>
          <div className="col-12 px-4">
            { onEditView ? (
              <ReactQuill
                placeholder="e.g. existing dwelling, any substantial trees"
                value={newTaskAttributes.notes}
                onChange={value => onEditAttr("notes", value)} />
            ) : (
              currentTask.notes && <div className="bg-linen py-2 px-3 rounded mb-4" dangerouslySetInnerHTML={{ __html: currentTask.notes || "" }}></div>
            )}
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-12 mb-2 mt-3">Attachments</div>
          <div className="col-12">
            <table className="table mt-2">
              <tbody className="bg-white">
                { currentTask.attachments?.map((file, idx) => {
                  const isOwnerFile = isRSManager || Number(currentUser.id) === file?.data?.attributes?.createdByUserId

                  return (
                    <FileRow
                      key={`${id}-${file.data.id}`}
                      rowStyles={`${idx === currentTask.attachments.length - 1 ? "border-bottom-0" : ""} ${idx === 0 ? "border-top-0" : ""}`}
                      isOwnerFile={isOwnerFile}
                      taskId={id}
                      canDelete={currentTask.state !== "completed" || isAdmin}
                      file={file}
                      reloadData={reloadData} />
                  )
                })}
              </tbody>
            </table>
            { onEditView ? (
              <>
                <Dropzone
                  wrapperClass="drag-drop"
                  multiple
                  name="photo"
                  setUploadedFiles={setUploadedFiles}
                  handleFileUpload={onCheckingFiles} />
                <p> New Uploaded files: </p>
                <div className="uploaded-attachments">
                  { uploadedFiles.map(file => (
                    <p key={file.path} className="file-list-item mb-0 mr-2">
                      <i className="bi bi-file-earmark mr-2"></i>
                      {file.path}
                    </p>
                  ))}
                </div>
                <SweetAlert
                  show={showFilesError}
                  type="error"
                  title="File size exceeded!"
                  style={{ width: "600px" }}
                  onConfirm={() => {
                    setShowFilesError(false)
                    setLargeUploadedFiles([])
                    setUploadedFiles([])
                  }}
                  allowEscape={false}
                  closeOnClickOutside>
                  {`There're ${largeUploadedFiles.length} files over the Max file size ${MAX_SIZE_UPLOADED_FILE_TEXT}`}
                </SweetAlert>
              </>
            ) : <></>}
          </div>
        </div>
        <div className="mt-4">
          <EditSaveButtons
            editState={onEditView}
            startEdit={() => setOnEditView(true)}
            disableEdit={!canUpdate}
            save={onUpdate}
            cancel={() => setOnEditView(false)} />
        </div>
      </div>
    </div>
  )
}
