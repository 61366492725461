import React from "react"
import { toast } from "react-toastify"

const toastId = "axios-message"
const containerId = "axios"
const toastOptions = {
  toastId,
  containerId
}

export default class AxiosNotifcationMiddleware {
  onRequest(config) {
    return config
  }

  onSync(promise) {
    return promise
  }

  onResponse(response) {
    if (response && response.data) {
      const data = JSON.parse(response.data)
      const notice = _.get(data, "meta.notice")

      if (notice !== undefined) {
        toast.success(
          <div>
            <span><i className="fa fa-exclamation mr-2" /></span>
            {notice}
          </div>,
          toastOptions
        )
      }
    }
    return response
  }

  onResponseError(error) {
    const data = JSON.parse(error.response.data)
    // const notice = _.get(data, "meta.notice")
    if (data.message !== undefined && data.id === undefined) {
      toast.error(
        <div>
          {data.message}
        </div>,
        toastOptions
      )
    }
    if (data.errors && data.errors.length > 0) {
      const message = data.errors.map(error => error.detail || "").join(", ")
      toast.error(
        <div>
          {message}
        </div>,
        toastOptions
      )
    }
    throw error
  }
}
