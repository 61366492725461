import React, { useState } from "react";

import GenerateInvoiceModal from "../../modals/GenerateInvoiceModal"

const SendInvoice = ({ jobService, reloadData, setActiveTab }) => {
  const [showGenerateInvoiceModal, setShowGenerateInvoiceModal] = useState(false)

  return (
    <div className="row">
      <button type="submit" className="btn btn-red btn-md" onClick={() => setShowGenerateInvoiceModal(true)}>
        Raise Invoice
      </button>
      <GenerateInvoiceModal
        show={showGenerateInvoiceModal}
        jobService={jobService}
        reloadData={reloadData}
        setActiveTab={setActiveTab}
        onHide={() => {
          setShowGenerateInvoiceModal(false)
          }} />
    </div>
  )
}
export default SendInvoice