import React, { useState } from "react"
import _ from "lodash"
import { format } from "date-fns"
import {
  OPERATION_MANAGER,
  DOCKET_STATUS,
  SURVEYORS,
  ACCOUNTS
} from "utils/constants"
import { roleCheck } from "utils/index"
import { useSelector } from "react-redux"
import { getCurrentUser, getCurrentRole } from "../../../reducers"
import AddDocketTotalAmountModal from "../AddDocketTotalAmountModal"
import UpdateDocketTimeInOutModal from "./UpdateDocketTimeInOutModal"
import UpdateDocketAmountModal from "./UpdateDocketAmountModal"


export default function DocketInfo({
  docket, onUpdateTotalAmount, onUpdateTimeInOut, loading
}) {
  const currentUser = useSelector(getCurrentUser)
  const currentRole = useSelector(getCurrentRole)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role
  const isAdminOrOperations = roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER)
  const isAccounts = roleCheck(systemRole, currentRole, ACCOUNTS)
  const isSurveyor = roleCheck(systemRole, currentRole, SURVEYORS)
  const jobServices = _.get(docket, "jobService.data.attributes", {})
  const [showModal, setShowModal] = useState(false)
  const [showTimeInOutModal, setShowTimeInOutModal] = useState(false)
  const [showUpdateDocketAmountModal, setShowUpdateDocketAmountModal] = useState(false)
  const canEditTimeInOut = (["new", "no_signature", "signed"].includes(docket?.status) && isAdminOrOperations) || (["new"].includes(docket?.status) && isSurveyor)
  const canEditTotalAmount = (!["new", "no_signature", "signed"].includes(docket?.status) && (isAdminOrOperations || isAccounts))
  const displayJobNo = jobServices?.forProjectX ? jobServices?.projectXJobNo : jobServices?.jobNo

  return (
    <div className="card border-0">
      <div className="card-body p-4">
        <div className="row align-items-center">
          <div className="col-md-3">Docket No.</div>
          <div className="col-md-9">{docket.number}</div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-3">Job No.</div>
          <div className="col-md-9">{displayJobNo}</div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-3">Surveyor Assignment</div>
          <div className="col-md-9">{docket?.surveyorAssignmentName}</div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-3">Job Title </div>
          <div className="col-md-9">{jobServices?.label}</div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-3">Docket Date</div>
          <div className="col-md-9">{docket?.date ? format(new Date(docket?.date), "d MMM yyyy ") : ""}</div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-3">Time In</div>
          <div className="col-md-9 d-flex align-items-center">
            {docket?.timeIn ? (
              <>
                <span>{format(new Date(docket?.timeIn), "HH:mm a")}</span>
                {" "}
                {(isSurveyor || isAdminOrOperations) && (<button disabled={loading || !canEditTimeInOut} onClick={() => setShowTimeInOutModal(true)} type="button" className="btn btn-link">Edit</button>)}
              </>
            ) : (isSurveyor || isAdminOrOperations) && (
              <button disabled={loading || !canEditTimeInOut} type="button" onClick={() => setShowTimeInOutModal(true)} className="btn btn-link">Add</button>
            )}
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-3">Time Out</div>
          <div className="col-md-9 d-flex align-items-center">
            {docket?.timeOut ? (
              <>
                <span>{format(new Date(docket?.timeOut), "HH:mm a")}</span>
                {" "}
                {(isSurveyor || isAdminOrOperations) && (<button disabled={loading || !canEditTimeInOut} onClick={() => setShowTimeInOutModal(true)} type="button" className="btn btn-link">Edit</button>)}

              </>
            ) : (isSurveyor || isAdminOrOperations) && (
              <button disabled={loading || !canEditTimeInOut} type="button" onClick={() => setShowTimeInOutModal(true)} className="btn btn-link">Add</button>
            )}
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-3">Time Spent</div>
          <div className="col-md-9">{docket?.timeSpent}</div>
        </div>

        {(isAdminOrOperations || isAccounts) && (
          <div className="row align-items-center">
            <div className="col-md-3">Total Amount</div>
            <div className="col-md-9 d-flex align-items-center">
              $
              {docket?.totalAmount ? `${docket?.totalAmount}` : "0.00"}
              {canEditTotalAmount && (
                <button disabled={loading} onClick={() => setShowUpdateDocketAmountModal(true)} type="button" className="btn btn-link">Edit</button>)}
            </div>
          </div>
        )}

        <div className="row align-items-center">
          <div className="col-md-3">Date Signed</div>
          <div className="col-md-9">{docket.dateSigned ? format(new Date(_.get(docket, "dateSigned", new Date())), "d MMM yyyy") : ""}</div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-3">Name of Supervisor</div>
          <div className="col-md-9">{docket.noSignature ? "No Signature" : _.get(docket, "supervisorSignedName", "")}</div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-3">Name of Surveyor</div>
          <div className="col-md-9">{_.get(docket, "surveyorSignedName", "")}</div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-3">Status </div>
          <div className="col-md-9" style={{ color: DOCKET_STATUS[docket?.status]?.color, fontWeight: "bold" }}>{DOCKET_STATUS[docket?.status]?.label}</div>
        </div>
        {isAdminOrOperations && ["new", "no_signature", "signed"].includes(docket?.status) && (
          <div className="row mt-3 align-items-center">
            <div className="col-md-3">
              <button
                disabled={loading}
                onClick={() => {
                  setShowModal(true)
                }}
                type="button"
                className="btn btn-light-blue">
                Add Total Amount
              </button>
              <AddDocketTotalAmountModal
                loading={loading}
                totalAmount={docket.totalAmount || 0}
                show={showModal}
                setShow={setShowModal}
                onUpdateTotalAmount={onUpdateTotalAmount} />
            </div>
          </div>
        )}
        {canEditTotalAmount && (
          <UpdateDocketAmountModal
            loading={loading}
            docket={docket}
            show={showUpdateDocketAmountModal}
            onHide={() => setShowUpdateDocketAmountModal(false)}
            onUpdateTotalAmount={onUpdateTotalAmount} />
        )}
        <UpdateDocketTimeInOutModal
          loading={loading}
          timeIn={docket.timeIn}
          timeOut={docket.timeOut}
          show={showTimeInOutModal}
          setShow={setShowTimeInOutModal}
          onUpdateTimeInOut={onUpdateTimeInOut} />
      </div>
    </div>
  )
}
