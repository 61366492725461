import React from "react"

const ContactListItem = ({ contact, limitedInfo, isDraftingOverseas }) => {
  const {
    attributes: {
      firstName, lastName, email, phoneNumber, clientType, contactType, contactTypeObject
    }
  } = contact

  return isDraftingOverseas && !["person", "invoice"].includes(contactTypeObject.value) ? (
    <></>
  ) : (
    <div className="d-flex align-items-start contact-item">
      <img
        className="mr-3"
        src="/assets/icons/icn_contact_user-fe25297bee5a727ac1f4f052d0a0ad53bb7b3d8c02ce3ae3857592f83f1665e3.svg" />
      <div className="contact-info">
        <div className="d-flex align-items-center">
          <span>{`${firstName} ${lastName} (${contactTypeObject.label})`}</span>
          {contactType === "account_payable" && <span className="badge badge-dark ml-2">Account Payable</span>}
        </div>

        {!isDraftingOverseas && phoneNumber && (
        <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
      )}
        {!limitedInfo && email && (
        <>
          <span className="ml-1">&middot;</span>
          <a className="ml-1" href={`mailto:${email}`}>Email</a>
        </>
      )}
        <br />
        {!isDraftingOverseas && (
        <>{clientType}</>
      )}
      </div>
    </div>
)
}

export default ContactListItem
