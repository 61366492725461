import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import AnchorButton from "components/AnchorButton"
import { deleteContact } from "requesters/contactRequester"
import SweetAlert from "react-bootstrap-sweetalert"
import { getBaseUrl } from "../../reducers"

export default function ContactRow({ contact, onDeleteContact }) {
  const {
    id, attributes
  } = contact
  const baseUrl = useSelector(getBaseUrl)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  if (attributes) {
    const {
      fullName,
      clientType,
      company,
      jobServicesCount,
      email,
      phoneNumber
    } = attributes

    const handleDelete = () => {
      deleteContact(baseUrl, id)
        .then(res => {
          if (res.status === 200) {
            onDeleteContact(id)
            setShowDeleteAlert(false)
          } else {
            setShowDeleteAlert(false)
          }
        })
        .catch(() => {
          setShowDeleteAlert(false)
        })
    }

    const handleNavigateToDetail = () => {
      window.location.href = `/contacts/${id}/edit`
    }
    return (
      <>
        <tr className="cursor-pointer">
          <td onClick={handleNavigateToDetail}>{fullName}</td>
          <td onClick={handleNavigateToDetail}>{clientType}</td>
          <td onClick={handleNavigateToDetail}>{company}</td>
          <td onClick={handleNavigateToDetail}>{jobServicesCount}</td>
          <td onClick={handleNavigateToDetail}>{email}</td>
          <td onClick={handleNavigateToDetail}>{phoneNumber}</td>
          <td>
            <Link to={`/contacts/${id}/edit`}>Edit</Link>
            {jobServicesCount === 0 && (
              <>
                <span className="text-muted mx-2">|</span>
                <AnchorButton disabled={showDeleteAlert} className="text-danger" type="button" onClick={() => setShowDeleteAlert(true)}>
                  Delete
                </AnchorButton>
              </>
            )}
          </td>
        </tr>
        <SweetAlert
          show={showDeleteAlert}
          type="custom"
          customClass="text-dark"
          title={(
            <>
              Are you sure you want to delete contact
              <span className="ml-1"></span>
              <b>{fullName}</b>
              ?
            </>
          )}
          style={{ width: "600px" }}
          confirmBtnText="Delete"
          confirmBtnCssClass="btn btn-danger"
          closeOnClickOutside
          allowEscape={false}
          showCancel
          confirmBtnStyle={{
            border: "none",
            boxShadow: "none"
          }}
          onCancel={() => setShowDeleteAlert(false)}
          onConfirm={() => handleDelete()}>
          <p className="text-muted">
            This action cannot be undone.
          </p>
        </SweetAlert>
      </>

    )
  }
  return null
}
