/* eslint-disable react/jsx-no-target-blank */
import _ from "lodash";
import React, { useState } from "react";
import {
  OverlayTrigger,
  Spinner,
  Tooltip
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateJobServiceTransition } from "requesters/jobServiceRequester";
import Swal from "sweetalert2";
import { ACCOUNTS_MANAGER } from "utils/constants";
import { roleCheck } from "utils/index";
import { ACCOUNTS } from "../../../../../utils/constants";
import { getBaseUrl, getCurrentRole, getCurrentUser } from "../../../reducers";
import EditInvoiceModal from "../modals/EditInvoiceModal";
import GenerateInvoiceModal from "../modals/GenerateInvoiceModal";
import WriteOffJobServiceModal from "../modals/WriteOffJobServiceModal";
import AutoLinkInvoiceModal from "../modals/AutoLinkInvoiceModal";
import ManualLinkInvoiceModal from "../modals/ManualLinkInvoiceModal";

const Accounts = ({ jobService, reloadData, setActiveTab }) => {
  const {
    id, attributes: { invoiceState, invoices, state }
  } = jobService
  const groupedJobServices = _.groupBy(invoices, "data.attributes.invoiceNumber")
  const baseUrl = useSelector(getBaseUrl)
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentRole = useSelector(getCurrentRole)
  const currentTeamRole = currentUser.attributes.current_team_role
  const isAccountManager = roleCheck(systemRole, currentTeamRole, ACCOUNTS_MANAGER)
  const canWriteOffAmount = roleCheck(systemRole, currentRole, ACCOUNTS) && state !== "rescheduled"
  const [showGenerateInvoiceModal, setShowGenerateInvoiceModal] = useState(false)
  const [showWriteOffJobServiceModal, setShowWriteOffJobServiceModal] = useState(false)
  const [showLinkInvoiceModal, setShowLinkInvoiceModal] = useState(false)
  const [showManualLinkInvoiceModal, setShowManualLinkInvoiceModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showEditInvoiceModal, setShowEditInvoiceModal] = useState(false)
  const [selectedInvoice, setSelectedInvoice] = useState(null)

  const toastOptions = {
    toastId: "container-inner",
    containerId: "main"
  }

  const handleModalOpen = invoice => {
    if (invoice) {
      setSelectedInvoice(invoice[0])
      setShowEditInvoiceModal(true)
    }
  }
  const markAsPaidHandler = () => {
    setLoading(true)
    updateJobServiceTransition(baseUrl, id, "pay-invoice")
      .then(res => {
        if (res.status === 200) {
          if (setActiveTab) setActiveTab("Accounts"); // set active tab to accounts if coming from Activity Tab
          Swal.fire(
            "Invoice paid",
            "Invoice has been paid",
            "success"
          );
          reloadData();
        } else {
          setLoading(false)
          toast.error(
            <div>
              <span role="img" aria-label="failure-icon">❌</span>
              <span className="ml-2">{res?.error?.response?.data?.errors[0]?.detail}</span>
            </div>,
            toastOptions
          )
        }
      })
      // eslint-disable-next-line no-console
      .catch(() => {
        setLoading(false)
      })
  }

  const markAsOtherInvoicePaidHandler = invoiceId => {
    setLoading(true)
    updateJobServiceTransition(baseUrl, id, "pay-other-invoice", null, null, null, invoiceId)
      .then(res => {
        if (res.status === 200) {
          if (setActiveTab) setActiveTab("Accounts"); // set active tab to accounts if coming from Activity Tab
          Swal.fire(
            "Invoice paid",
            "Invoice has been paid",
            "success"
          );
          reloadData();
        } else {
          setLoading(false)
          toast.error(
            <div>
              <span role="img" aria-label="failure-icon">❌</span>
              <span className="ml-2">{res?.error?.response?.data?.errors[0]?.detail}</span>
            </div>,
            toastOptions
          )
        }
      })
      // eslint-disable-next-line no-console
      .catch(() => {
        setLoading(false)
      })
  }

  const renderButtonMarkAsPaid = (invoiceType, invoiceId, markAsPaid, jobServiceState) => {
  const canShowMarkAsPaid = ["awaiting_payment", "to_invoice"].includes(jobServiceState)
    if (invoiceType === "other" && !markAsPaid) {
      return (
        <button
          disabled={loading}
          type="button"
          className="btn btn-outlined-blue btn-sm"
          onClick={() => {
            markAsOtherInvoicePaidHandler(invoiceId)
          }}>
          Mark as Paid
          {loading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
        </button>
      )
    }
    if (invoiceType === "deposit" && invoiceState === "deposit_invoice_drafted" && canShowMarkAsPaid) {
      return (
        <button
          disabled={loading}
          type="button"
          className="btn btn-outlined-blue btn-sm"
          onClick={() => {
            markAsPaidHandler(invoiceId)
          }}>
          Mark as Paid
          {loading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
        </button>
      )
    }
    if (invoiceType === "final" && invoiceState === "final_invoice_drafted" && canShowMarkAsPaid) {
      return (
        <button
          disabled={loading}
          type="button"
          className="btn btn-outlined-blue btn-sm"
          onClick={() => {
            markAsPaidHandler(invoiceId)
          }}>
          Mark as Paid
          {loading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
        </button>
      )
    }

    return <></>
  }

  return (
    <>
      {/* NOTE: hidden upon client request */}
      {/* <div className="timeline-item mb-5">
        <div className="card border-0 shadow-sm timeline-card">
          <div className="card-body p-5">
            <h5 className="card-title">FEE PROPOSAL</h5>
            <div className="table-responsive">
              <table className="table mt-4">
                <tbody className="bg-white">
                  <tr className="border-top border-bottom">
                    <td>Half Day</td>
                    <td className="text-right">$750</td>
                  </tr>
                  <tr className="border-top border-bottom">
                    <td>Full Day</td>
                    <td className="text-right activity-status">$1500</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> */}
      <div className="timeline-item">
        <div className="card border-0 shadow-sm timeline-card">
          <div className="card-body p-5">
            <div className="row justify-content-between">
              <h5 className="card-title">INVOICES</h5>
              <div className="buttons-group">
                <button
                  className="btn btn-red mr-2"
                  data-target="#InvoiceModal"
                  data-toggle="modal"
                  type="button"
                  onClick={() => setShowGenerateInvoiceModal(true)}>
                  Generate Invoice
                </button>
                <GenerateInvoiceModal
                  show={showGenerateInvoiceModal}
                  jobService={jobService}
                  reloadData={reloadData}
                  onHide={() => { setShowGenerateInvoiceModal(false) }} />

                <button
                  className="btn btn-outline-secondary mr-2"
                  data-target="#LinkInvoiceModal"
                  data-toggle="modal"
                  type="button"
                  onClick={() => setShowLinkInvoiceModal(true)}>
                  Link Invoice
                </button>
                <AutoLinkInvoiceModal
                  show={showLinkInvoiceModal}
                  onHide={() => setShowLinkInvoiceModal(false)}
                  onSuccess={() => {}}
                  onFail={() => setShowManualLinkInvoiceModal(true)}
                  jobService={jobService}
                  reloadData={reloadData} />
                <ManualLinkInvoiceModal
                  show={showManualLinkInvoiceModal}
                  onHide={() => setShowManualLinkInvoiceModal(false)}
                  jobService={jobService}
                  reloadData={reloadData} />
              </div>
            </div>
            <div className="table-responsive">
              <table className="table mt-4">
                <thead className="bg-light">
                  <tr>
                    <th>Sent</th>
                    <th className="text-center">Invoice Number</th>
                    <th className="text-center">Job Service</th>
                    <th className="text-center">Issue Date</th>
                    <th>Invoice Type</th>
                    <th>Amount</th>
                    <th>Amount Paid</th>
                    <th>GST Paid</th>
                    <th className="text-right">Status</th>
                    <th className="text-right">Online Link</th>
                    <th className="text-right" style={{ width: "120px" }}></th>
                    <th className="text-right"></th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {Object.entries(groupedJobServices).map(([invoiceNumber, invoice]) => (
                    <tr
                      className="border-top border-bottom"
                      onClick={e => {
                        if (e.target.localName !== "path" && e.target.className !== "text-right online-invoice-url" && e.target.localName !== "button") {
                          window.open(invoice[0].data.attributes.xeroInvoiceLink)
                        }
                      }}
                      style={{ cursor: "pointer" }}
                      key={`${invoiceNumber}-${invoice[0].data.attributes.xeroId}`}>
                      <td>
                        {invoice[0].data.attributes.sentDate ? (
                          <OverlayTrigger
                            placement="auto"
                            overlay={<Tooltip>{invoice[0].data.attributes.sentDate}</Tooltip>}>
                            <span className="bi bi-check-circle-fill" style={{ color: "green" }}></span>
                          </OverlayTrigger>
                        ) : (<span className="bi bi-check-circle-fill" style={{ color: "grey" }}></span>)}
                      </td>
                      <td className="text-center">{invoiceNumber}</td>
                      <td className="text-center">
                        {invoice.map((item, index) => (
                          <span>
                            {item.data.attributes.jobServiceLabel}
                            {" "}
                            {index < invoice.length - 1 ? ", " : ""}
                          </span>
                        ))}
                      </td>
                      <td className="text-center">{invoice[0].data.attributes.issueDate}</td>
                      <td className="text-center">{invoice[0].data.attributes.invoiceTypeLabel}</td>
                      <td className="text-center">
                        $
                        {parseFloat(invoice[0].data.attributes.price).toFixed(2)}
                        {/* {invoice.reduce((sum, item) => sum + parseFloat(item.data.attributes.price), 0.0).toFixed(2)} */}
                      </td>
                      <td className="text-center">
                        $
                        {parseFloat(invoice[0].data.attributes.amountPaid).toFixed(2)}
                        {/* {invoice.reduce((sum, item) => sum + parseFloat(item.data.attributes.amountPaid), 0.0).toFixed(2) || 0} */}
                      </td>
                      <td className="text-center">
                        $
                        {parseFloat(invoice[0].data.attributes.gstPaid).toFixed(2)}
                        {/* {invoice.reduce((sum, item) => sum + parseFloat(item.data.attributes.gstPaid), 0.0).toFixed(2) || 0} */}
                      </td>
                      <td className="text-right"><strong>{_.capitalize(invoice[0].data.attributes.state)}</strong></td>
                      <td className="text-right online-invoice-url">
                        {invoice[0].data.attributes.onlineInvoiceUrl && (
                          <a href={invoice[0].data.attributes.onlineInvoiceUrl} target="_blank" style={{ marginRight: "20px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="26">
                              <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM64 80c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm128 72c8.8 0 16 7.2 16 16v17.3c8.5 1.2 16.7 3.1 24.1 5.1c8.5 2.3 13.6 11 11.3 19.6s-11 13.6-19.6 11.3c-11.1-3-22-5.2-32.1-5.3c-8.4-.1-17.4 1.8-23.6 5.5c-5.7 3.4-8.1 7.3-8.1 12.8c0 3.7 1.3 6.5 7.3 10.1c6.9 4.1 16.6 7.1 29.2 10.9l.5 .1 0 0 0 0c11.3 3.4 25.3 7.6 36.3 14.6c12.1 7.6 22.4 19.7 22.7 38.2c.3 19.3-9.6 33.3-22.9 41.6c-7.7 4.8-16.4 7.6-25.1 9.1V440c0 8.8-7.2 16-16 16s-16-7.2-16-16V422.2c-11.2-2.1-21.7-5.7-30.9-8.9l0 0c-2.1-.7-4.2-1.4-6.2-2.1c-8.4-2.8-12.9-11.9-10.1-20.2s11.9-12.9 20.2-10.1c2.5 .8 4.8 1.6 7.1 2.4l0 0 0 0 0 0c13.6 4.6 24.6 8.4 36.3 8.7c9.1 .3 17.9-1.7 23.7-5.3c5.1-3.2 7.9-7.3 7.8-14c-.1-4.6-1.8-7.8-7.7-11.6c-6.8-4.3-16.5-7.4-29-11.2l-1.6-.5 0 0c-11-3.3-24.3-7.3-34.8-13.7c-12-7.2-22.6-18.9-22.7-37.3c-.1-19.4 10.8-32.8 23.8-40.5c7.5-4.4 15.8-7.2 24.1-8.7V232c0-8.8 7.2-16 16-16z" />
                            </svg>
                          </a>
                        )}
                      </td>
                      <td className="text-right online-invoice-url">
                        {(!invoice[0].data.attributes.markAsPaid && isAccountManager && invoice[0].data.attributes.jobServiceId === Number(id)) ? renderButtonMarkAsPaid(invoice[0].data.attributes.invoiceType, invoice[0].data.id, invoice[0].data.attributes.markAsPaid, state) : <></>}
                      </td>
                      <td className="text-right online-invoice-url">
                        {(((invoice[0].data.attributes.invoiceType === "deposit" && invoiceState === "deposit_invoice_drafted") || (invoice[0].data.attributes.invoiceType === "final" && invoiceState === "final_invoice_drafted")) && !invoice[0].data.attributes.markAsPaid && !parseFloat(invoice[0].data.attributes.amountPaid) > 0 && isAccountManager && invoice[0].data.attributes.jobServiceId === Number(id) && invoice.length === 1)
                          ? (
                            <button
                              disabled={loading}
                              type="button"
                              className="btn btn-outlined-blue btn-sm"
                              onClick={() => handleModalOpen(invoice)}>
                              Edit
                            </button>
                          )
                          : <></>}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            { canWriteOffAmount && (
              <div className="row no-gutters">
                <button
                  className="btn btn-outline-secondary mr-2"
                  data-target="write-off-modal"
                  data-toggle="modal"
                  type="button"
                  onClick={() => setShowWriteOffJobServiceModal(true)}>
                  Write Off Amount
                </button>

                <WriteOffJobServiceModal
                  show={showWriteOffJobServiceModal}
                  onHide={() => setShowWriteOffJobServiceModal(false)}
                  jobService={jobService}
                  reloadData={reloadData} />
              </div>
            )}
          </div>
        </div>
      </div>
      {selectedInvoice && (
        <EditInvoiceModal
          show={showEditInvoiceModal}
          onHide={() => setShowEditInvoiceModal(false)}
          jobService={jobService}
          invoice={selectedInvoice}
          reloadData={reloadData} />
      )}
    </>
  )
}

export default Accounts
