import React from "react";
import DetailsRow from "./DetailsRow";

const DetailsSection = ({ rows }) => {
  const { name, days } = rows

  return (
    <>
      <div className="my-5">
        <h5>{name}</h5>
        <div className="table-responsive">
          <table className="table table-listing table-hover table-striped">
            <thead>
              <tr>
                <th>Registered Surveyor Name</th>
                <th>Job No</th>
                <th>Job Title</th>
                <th>Job Service/Type</th>
                <th>Dept. Start Date</th>
                <th>Finish Date</th>
              </tr>
            </thead>
            <tbody>
              <DetailsRow days={days} />
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default DetailsSection