import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";

export default function AddDocketModal({
  show, onHide, scheduledAssignmentsOptions, onAddDocket
}) {
  // const baseUrl = useSelector(getBaseUrl);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [surveyorAssignmentId, setSurveyorAssignmentId] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const validateForm = () => {
    setDisableSubmit(true);
    if (!surveyorAssignmentId) {
      setErrorMessage("Required Field");
      setDisableSubmit(false);
    } else {
      setErrorMessage(null);
      onAddDocket(surveyorAssignmentId)
    }
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <h2 className="modal-title">Add Docket</h2>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          <div className="form-group row">
            <label className="col-3 col-form-label">Assignment Time *</label>
            <div className="col-9">
              <Select
                name="assignment"
                isSearchable
                onChange={e => {
                  setSurveyorAssignmentId(e.value)
                }}
                options={scheduledAssignmentsOptions}
                placeholder="Select a Assignment Time" />
              {
                errorMessage && (<div className="err-msg">{errorMessage}</div>)
              }
            </div>
          </div>
        </form>
        <div className="modal-footer d-flex justify-content-end px-0 py-4">
          <button
            className="btn btn-red btn-lg"
            onClick={validateForm}
            disabled={disableSubmit}
            type="submit">
            Add Docket
          </button>
        </div>
      </div>
    </Modal>
  );
}
