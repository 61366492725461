import { format, isToday } from "date-fns"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { updateJobServiceTransition } from "../../../../../requesters/jobServiceRequester"
import { getBaseUrl } from "../../../reducers"

import JobServiceTableNote from "../../Schedules/JobServiceTableNote"

const RowForSurveyor = ({ currentUser, jobService, requestJobServices }) => {
  const {
    id,
    attributes: {
      booking_dates, job_no, client_name, client_number, job_address, label, checked_in_at, checked_out_at, job_id, scheduled_assignments
    }
  } = jobService

  const scheduledAssignmentsToday = scheduled_assignments.find(sa => isToday(new Date(sa.scheduled_at)));
  const assignment = scheduledAssignmentsToday?.assignments?.find(a => a.surveyor_id === Number(currentUser.id)) || {};
  const { surveyor_assignment_id } = assignment


  const jobTime = format(new Date(booking_dates[0]), "h:mm aa")
  const checkIn = format(new Date(checked_in_at), "HH:mm aaa") || ""
  const checkOut = format(new Date(checked_out_at), "HH:mm aaa") || ""
  const checkInOut = checked_out_at ? `${checkIn} - ${checkOut}` : (checked_in_at ? checkIn : "")
  const baseUrl = useSelector(getBaseUrl)

  const [onCheckIn, setOnCheckIn] = useState(false)

  const toastOptions = {
    toastId: "container-inner",
    containerId: "main"
  }

  const jobCheckInHandler = () => {
    setOnCheckIn(true)
    updateJobServiceTransition(baseUrl, id, "check-in", null, surveyor_assignment_id)
      .then(res => {
        if (res.status === 200) {
          requestJobServices(id)
          setOnCheckIn(false)
          window.location.reload()
        } else {
          setOnCheckIn(false)
          toast.error(
            <div>
              <span role="img" aria-label="failure-icon">❌</span>
              <span className="ml-2">{res?.error?.response?.data?.errors[0]?.detail}</span>
            </div>,
            toastOptions
          )
        }
      })
  }


  const reloadData = () => {
    requestJobServices(id)
    window.location.reload()
  }

  const buttons = checkIn => (!checkIn ? <button disabled={onCheckIn} className="btn btn-blue btn-sm" type="button" onClick={jobCheckInHandler}>Check in</button> : <></>)

  return (
    <tr>
      <td>
        <Link to={`/jobs/${job_id}/job_services/${id}`} className="link-no-decoration">{jobTime}</Link>
      </td>
      <td>
        <Link to={`/jobs/${job_id}/job_services/${id}`} className="link-no-decoration">{job_no}</Link>
      </td>
      <td>
        <Link to={`/jobs/${job_id}/job_services/${id}`} className="link-no-decoration">{client_name}</Link>
      </td>
      <td>
        <Link to={`/jobs/${job_id}/job_services/${id}`} className="link-no-decoration">{client_number}</Link>
      </td>
      <td>
        <Link to={`/jobs/${job_id}/job_services/${id}`} className="link-no-decoration">{job_address}</Link>
      </td>
      <td>
        <Link to={`/jobs/${job_id}/job_services/${id}`} className="link-no-decoration">{label}</Link>
      </td>
      <td className="text-right">
        <Link to={`/jobs/${job_id}/job_services/${id}`} className="link-no-decoration">{checkInOut}</Link>
      </td>
      <td>{buttons(checked_in_at, checked_out_at)}</td>
      <JobServiceTableNote rowData={jobService} reloadData={reloadData} />
      <td><Link to={`/jobs/${job_id}/job_services/${id}`}>Details</Link></td>
    </tr>
  )
}

export default RowForSurveyor
