import React, { useState } from "react";
import { useSelector } from "react-redux";
import { updateJobServiceTransition } from "requesters/jobServiceRequester";
import { Spinner } from "react-bootstrap"
import { checkValidUrl } from "utils/";
import ConfirmationModal from "../../modals/ConfirmationModal";
import CommentBox from "./CommentBox";
import { getBaseUrl } from "../../../../reducers"

const CreatingDeliverables = ({
  jobService
}) => {
  const {
    id,
    attributes: { jobNo, forProjectX, projectXJobNo, jobTitle, cost, docketResolved }
  } = jobService
  const displayJobNo = forProjectX ? projectXJobNo : jobNo
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [dropboxLink, setDropboxLink] = useState("")
  const btnOutline = "btn btn-outline-primary"
  const btnPrimary = "btn btn-primary"
  const baseUrl = useSelector(getBaseUrl)

  const sendToReadyToSend = () => {
    setLoading(true)
    let transition
    if (Number(cost) === 0 && docketResolved) {
      transition = "ready-to-send"
    } else {
      transition = "move-to-accounts"
    }
    updateJobServiceTransition(baseUrl, id, transition, null, null, null, null, dropboxLink)
      .then(res => {
        if (res.status === 200) {
          window.location.reload()
        } else {
          setLoading(false)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }
  return (
    <div className="timeline-item mt-2">
      <CommentBox
        comment={dropboxLink}
        setComment={comment => {
        if (!checkValidUrl(comment)) {
          setError("Please enter a valid URL")
        } else {
          setError("")
        }
        setDropboxLink(comment)
      }}
        placeholder="Add Dropbox link" />
      {error && <div className="text-danger">{error}</div>}
      <div className="row mt-4">
        <div className="mr-2">
          <button className={dropboxLink ? btnPrimary : btnOutline} type="button" disabled={error || !dropboxLink || showConfirmModal || loading} onClick={() => { setShowConfirmModal(true) }}>
            Ready to Send
            {loading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
          </button>
          <ConfirmationModal
            jobNo={displayJobNo}
            jobTitle={jobTitle}
            show={showConfirmModal}
            resetState={() => {
              setShowConfirmModal(false)
            }}
            sendTo={sendToReadyToSend}
            messageType="Ready to Send">
          </ConfirmationModal>
        </div>
      </div>
    </div>
  )
}
export default CreatingDeliverables
