import React, { useState } from "react";
import { useSelector } from "react-redux";
import { updateJobService } from "requesters/jobServiceRequester";
import { Modal, Spinner } from "react-bootstrap";
import { getBaseUrl } from "../../reducers";

const EditNoteModal = ({
  show, onHide, reloadData, jobService, title, readOnly
}) => {
  const baseUrl = useSelector(getBaseUrl)
  const { id, attributes: { label, jobNotes } } = jobService
  const [currentNotes, setCurrentNotes] = useState(jobNotes)
  const [loading, setLoading] = useState(false)

  const validateForm = () => {
    setLoading(true)
    updateJobService(baseUrl, id, { notes: currentNotes })
      .then(res => {
        if (res.status === 200) {
          reloadData()
        }
        onHide()
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        {!readOnly && (
          <Modal.Title><h2 className="modal-title">{title}</h2></Modal.Title>
        )}
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          <div className="form-group">
            <label>Notes</label>
            <textarea
              disabled={readOnly}
              className="form-control mr-5"
              placeholder="e.g. exisitng dwelling, any substantial trees"
              rows="4"
              value={currentNotes || ""}
              onChange={e => setCurrentNotes(e.target.value)} />
          </div>
        </form>
        <div className="modal-footer d-flex justify-content-between p-0 mb-4 mt-5">
          <button className="btn btn-light btn-lg" data-dismiss="modal" type="button" onClick={onHide}>Close</button>
          {!readOnly && (
            <button
              className="btn btn-red btn-lg"
              onClick={validateForm}
              disabled={currentNotes === jobNotes || loading}
              type="submit">
              Save Changes 1
              {loading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}
export default EditNoteModal
