import React, { useState } from "react"
import Select from "react-select"
import { Modal } from "react-bootstrap"
import { useSelector } from "react-redux"
import { assignSurveyorMember } from "requesters/userRequester"
import { getBaseUrl } from "../../../../reducers"

const UserSurveyorMembersModal = ({
  show, onHide, reloadData, user, unassignedSurveyors
}) => {
  const baseUrl = useSelector(getBaseUrl)

  const [selectedSurveyor, setSelectedSurveyor] = useState(null)

  const handleSurveyorMemberAssignment = () => {
    assignSurveyorMember(baseUrl, selectedSurveyor.value, parseInt(user.user.id, 10))
      .then(res => {
        if (res.status === 201) {
          setSelectedSurveyor(null)
          reloadData()
          onHide()
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" backdrop="static">
      <Modal.Header closeButton>
        <div>
          <h2 className="modal-title">Assign Surveyor</h2>
        </div>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          <div className="form-group row">
            <label htmlFor="surveyor-user" className="col-3 col-form-label">Surveyor</label>
            <div className="col-7">
              <Select
                id="surveyors_list"
                name="surveyorsList"
                value={selectedSurveyor}
                onChange={value => setSelectedSurveyor(value)}
                options={unassignedSurveyors}
                placeholder="Select a surveyor" />
            </div>
          </div>
        </form>
        <hr />
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-red btn-lg"
          onClick={handleSurveyorMemberAssignment}
          disabled={!selectedSurveyor}
          type="submit">
          Assign Surveyor
        </button>
      </div>
    </Modal>
  )
}

export default UserSurveyorMembersModal