import customAxios from "./customAxios";
import normalize from "json-api-normalizer";

export const updateAppConfig = (baseUrl, appConfig) => {
  const path = `${baseUrl}/app_configs`;

  return customAxios.patch(path, { ...appConfig })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const getScanningGuide = (baseUrl) => {
  const path = `${baseUrl}/app_configs/project_x/scanning_guide`;

  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }));
};