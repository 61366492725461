import React, { Fragment, useEffect, useState } from "react"





export default function DashboardCardContainer(props) {
  const { title, children } = props

  return (
    <div className="col-lg-8 col-xl-9 today">
      <div className="container-fluid">
        <div className="container-inner">
          <h2>{title.toUpperCase()}</h2>
          <div className="row key-to-do">
            <div className="col-lg-10">
              {children}
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

