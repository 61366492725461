import React from "react"
import { Container } from "react-bootstrap"
import { format } from "date-fns"

import RowForDrafting from "./RowForDrafting"

const TableForDrafting = ({ activities }) => {
  const date_string = format(new Date(), "dd MMM yyyy, EEEE")
  return (
    <Container fluid className="bg-white py-5 mt-5">
      <h3>{`Today, ${date_string}`}</h3>
      <table className="table table-listing table-hover">
        <thead>
          <tr>
            <th>Job No.</th>
            <th>Job Address</th>
            <th>Job Service</th>
            <th className="text-left">Surveyor</th>
            <th className="text-center absorbing-column">Date of Field Work</th>
            <th className="text-center absorbing-column">
              <span className="mr-1">Draftsman</span>
            </th>
            <th className="text-center">Status</th>
            <th className="text-center absorbing-column">Due Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {activities.map(row => <RowForDrafting activities={row} key={row.id} />)}
        </tbody>
      </table>
    </Container>
  )
}

export default TableForDrafting