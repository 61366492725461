import React, { useEffect, useState } from "react";
import Select from "react-select"
import { useSelector } from 'react-redux'
import { getBaseUrl, getResource } from "../../../reducers"
import { postBookingAsUser, removeBookingAsUser, updateJobTransition, updateJob, sendPlan } from "requesters/jobRequester"
import SweetAlert from "react-bootstrap-sweetalert";
import cx from "classnames"
import { getSurveyors, getDraftmen } from "requesters/userRequester"
import DatePicker from "react-datepicker"
import { format } from "date-fns"
import { createDate } from "utils/";

export default function ReadyToSendStage({ job, isActive, reloadData }) {
  return (
    <div
      id="completed"
      className={isActive ? "tab-pane fade active show" : "tab-pane fade"} />
  )
}