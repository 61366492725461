import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import EmailValidator from "email-validator"
import { getOrganizations, putOrganization } from "requesters/organizationRequester";
import { updateJobService } from "requesters/jobServiceRequester";
import { roleCheck } from "utils/index";
import {
  OPERATION_MANAGER,
  ACCOUNTS_MANAGER,
} from "utils/constants";
import { FIELD_VALIDATIONS_ORGANIZATION } from "../Contacts/formErrors";
import { getCurrentUser, getBaseUrl } from "../../reducers";

export const OrganizationPanel = ({
  organisation, reloadData, jobServiceId, limitedInfo
}) => {
  const baseUrl = useSelector(getBaseUrl);
  const currentUser = useSelector(getCurrentUser);
  const { accountTypes } = useSelector(state => state.options);
  const { role: systemRole, current_team_role: currentTeamRole } = currentUser.attributes;
  const accountTypeOptions = accountTypes.map(acc => ({ value: acc[0], label: acc[1] }));

  const [initialOrganisation, setInitialOrganisation] = useState(null);
  const [selectedOrganisation, setSelectedOrganisation] = useState(null);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validForm, setValidForm] = useState(true)

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [address, setAddress] = useState(null);
  const [accountType, setAccountType] = useState(null);

  useEffect(() => {
    if (showModal) {
      initializeModal();
    }
  }, [showModal, organisation]);

  useEffect(() => {
    if (initialOrganisation) {
      setIsReadOnly(selectedOrganisation?.value != initialOrganisation.id);
    }
  }, [selectedOrganisation, initialOrganisation]);

  useEffect(() => {
    if (selectedOrganisation) {
      updateOrganisationDetails();
    }
    setValidForm(true)
  }, [selectedOrganisation]);

  const initializeModal = () => {
    setName(organisation?.name || null);
    setEmail(organisation?.email || null);
    setAddress(organisation?.address || null);
    setAccountType(organisation?.accountType || "");
    setInitialOrganisation(organisation);
    setSelectedOrganisation(organisation ? {
      value: organisation.id,
      label: organisation.name
    } : null);
    setIsReadOnly(true);
    fetchInitialOptions();
  };

  const fetchInitialOptions = async () => {
    try {
      const res = await getOrganizations(baseUrl);
      if (res.status === 200) {
        setOrganizationOptions(Object.values(res.response.organization));
      }
    } catch (err) {
      console.error("Error fetching initial options:", err);
    }
  };

  const loadOptions = async (inputValue) => {
    try {
      const res = await getOrganizations(baseUrl, { by_keyword: inputValue });
      if (res.status === 200) {
        return Object.values(res.response.organization).map(org => ({
          value: org.id,
          label: org.attributes.name,
        }));
      }
      return [];
    } catch (err) {
      console.error("Error fetching options:", err);
      return [];
    }
  };

  const updateOrganisationDetails = () => {
    const temp = organizationOptions.find(organisation => organisation.id === selectedOrganisation.value);
    if (temp) {
      setName(temp.attributes.name);
      setEmail(temp.attributes.email);
      setAddress(temp.attributes.address);
      setAccountType(temp.attributes.accountType);
    }
  };

  const validateForm = () => {
    let validFields = true
    const fields = [
      {
        email,
        name,
        address,
        accountType
      }
    ]

    _.map(fields[0], (value, key) => {
      switch (key) {
        case "email":
          validFields = EmailValidator.validate(value)
          break
        default:
          if (_.isEmpty(value)) {
            validFields = false
          }
      }
    })

    setValidForm(false)
    return validFields;
  }

  const errorMessageElement = (attribute, value) => {
    if (!validForm) {
      const message = _.find(FIELD_VALIDATIONS_ORGANIZATION, ["field", attribute])
      let validField = true
      switch (attribute) {
        case "email":
          validField = EmailValidator.validate(email)
          break
        default:
          if (_.isEmpty(value)) {
            validField = false
          }
      }
      if (!validField) {
        return (
          <div className="err-msg">
            {message.message}
          </div>
        )
      }
      return null
    }
    return null
  }

  const handleSave = async () => {
    if (selectedOrganisation && isReadOnly) {
      await updateJobServiceAndHandleResponse({ organization_id: selectedOrganisation.value });
    } else {
      const isValid = validateForm()
      if (!isValid) return;

      const params = {
        email,
        name,
        address,
        account_type: accountType
      };

      try {
        setLoading(true);
        const res = await putOrganization(baseUrl, organisation.id, params);

        if (res.status === 200) {
          setShowModal(false);
          reloadData();
        } else {
          console.error("Failed to save organization");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const removeOrganisationFromJobservice = () => {
    updateJobServiceAndHandleResponse({ organization_id: null });
  };

  const updateJobServiceAndHandleResponse = async (params) => {
    try {
      setLoading(true);
      const response = await updateJobService(baseUrl, jobServiceId, params);

      if (response.status === 200) {
        setShowModal(false);
        reloadData();
      } else {
        console.error("Failed to update job service");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  const options = organizationOptions.map(org => ({
    value: org.id,
    label: org.attributes.name,
  }));

  return (
    <div className="table-description mt-5 mb-3">
      <div className="d-flex align-items-center mb-2">
        <h6 className="mr-3 mb-1">Organisation</h6>
        {(roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) || 
          roleCheck(systemRole, currentTeamRole, ACCOUNTS_MANAGER)) && (
          <button
            type="button"
            className="btn btn-link p-0"
            onClick={() => setShowModal(true)}>
            {organisation ? "Edit" : "Add"}
          </button>
        )}
      </div>
      {organisation && (
        <div className="d-flex align-items-start organization-item">
          <img
            className="mr-3"
            src="/assets/icons/icn_contact_user-fe25297bee5a727ac1f4f052d0a0ad53bb7b3d8c02ce3ae3857592f83f1665e3.svg"
            alt="organization icon" />
          <div className="organization-info">
            <div className="align-items-center">
              <div>{organisation.name}</div>
              <div>{organisation.accountTypeLabel}</div>
            </div>
            <span>{organisation.address}</span>
            {!limitedInfo && organisation.email && (
              <>
                <span className="ml-1">&middot;</span>
                <a className="ml-1" href={`mailto:${organisation.email}`}>Email</a>
              </>
            )}
          </div>
        </div>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Organization</Modal.Title>
        </Modal.Header>
        <div className="modal-body mb-4">
          <div className="row">
            <div className="col-sm-10">
              <div className="form-group row">
                <label className="col-sm-4 col-form-label">Organization*</label>
                <div className="col-sm-8">
                  <AsyncSelect
                    value={selectedOrganisation}
                    loadOptions={loadOptions}
                    defaultOptions={options}
                    onChange={setSelectedOrganisation}
                    placeholder="Select an organization"
                    isSearchable
                    isClearable />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label">Name*</label>
                <div className="col-sm-8">
                  <input
                    className="form-control"
                    name="name"
                    disabled={isReadOnly}
                    onChange={e => setName(e.target.value)}
                    placeholder="First name"
                    value={name || ""}
                    type="text" />
                  {errorMessageElement("name", name)}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label">Email*</label>
                <div className="col-sm-8">
                  <input
                    className="form-control"
                    name="email"
                    disabled={isReadOnly}
                    onChange={e => setEmail(e.target.value)}
                    placeholder="Email"
                    value={email || ""}
                    type="text" />
                  {errorMessageElement("email", email)}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label">Address*</label>
                <div className="col-sm-8">
                  <input
                    className="form-control"
                    name="address"
                    disabled={isReadOnly}
                    onChange={e => setAddress(e.target.value)}
                    placeholder="Address"
                    value={address || ""}
                    type="text" />
                  {errorMessageElement("address", address)}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label">Account Type*</label>
                <div className="col-sm-8">
                  <Select
                    name="account_type"
                    placeholder="Select"
                    isDisabled={isReadOnly}
                    value={accountTypeOptions.find(option => option.value === accountType)}
                    options={accountTypeOptions}
                    onChange={e => setAccountType(e ? e.value : "")} />
                  {errorMessageElement("account_type", accountType)}
                </div>
              </div>
            </div>
            <div className="col-sm-2 text-right">
              {organisation && (
                <button
                  className="btn btn-link"
                  onClick={removeOrganisationFromJobservice}
                  type="button">
                  Remove
                </button>
              )}
            </div>
          </div>
          <div className="text-right">
            <button
              type="button"
              className="btn btn-red btn-lg"
              disabled={!selectedOrganisation || loading}
              onClick={handleSave}>
              Save
              {loading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
