import React, { useEffect, useState } from "react"
import { Modal, Spinner } from "react-bootstrap"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import Select from "react-select"
import { format } from "date-fns"

const SendAccessEmailModal = ({
  show, onHide, jobService, sentJobServiceAccessEmail
}) => {
  const {
    attributes: {
      contactsWithEmail, ccContactOptions, accountType, cost, activeFlag, bookingDates
    }
  } = jobService
  const mainPersons = contactsWithEmail.filter(jobContact => jobContact.contactType === "Main Person")
  const invoicePersons = contactsWithEmail.filter(jobContact => jobContact.contactType === "Invoice")
  const defaultOption = invoicePersons.length ? invoicePersons[0] : mainPersons[0]
  const toContactOptionsList = contactsWithEmail.map(jobContact => ({ value: jobContact.id, label: `${jobContact.name} (${jobContact.contactType})`, name: jobContact.name }))
  const [currentContact, setCurrentContact] = useState({})
  const [contactsToBeCc, setContactsToBeCc] = useState([])
  const [displayedCcContacts, setDisplayedCcContacts] = useState([])

  const emailTemplate = `
    <p>Hi {{Name}},</p>
    <p>We have booked in a Surveyor for the following date/s</p>
    {{BookingDates}}
    <p>If you can please confirm access by clicking one of the options below and then completing the form.</p>
    ${accountType === "50% - 50%" && Number(cost) !== 0 && activeFlag.data === null ? "<p>The booking is pending a 50% deposit prior to attending site, a separate email with the invoice details will be sent from the C&A Accounts Team.</p><p><br></p>" : ""}
  `
  const [message, setMessage] = useState(emailTemplate)

  const [additionalCc, setAdditionalCc] = useState("")
  const [loading, setLoading] = useState(false)

  const handleContacts = (e, contact) => {
    const tmpArray = [...contactsToBeCc]
    if (e.target.checked) {
      tmpArray.push(parseInt(contact.value, 10))
    } else {
      _.pull(tmpArray, parseInt(contact.value, 10))
    }

    setContactsToBeCc(tmpArray)
  }

  useEffect(() => {
    const newDisplayedCcContacts = ccContactOptions.map(jobContact => ({ value: jobContact.value, label: jobContact.label }))
    if (newDisplayedCcContacts.some(jobContact => jobContact.value === currentContact.value)) {
      const updatedCcList = newDisplayedCcContacts.filter(jobContact => jobContact.value !== currentContact.value)
      setDisplayedCcContacts(updatedCcList)
    }
  }, [currentContact])

  const onSubmit = () => {
    const emailOptions = {
      to: currentContact.value,
      cc: contactsToBeCc,
      additional_cc: additionalCc,
      message,
    }
    setLoading(true)
    sentJobServiceAccessEmail(emailOptions)
    setAdditionalCc("")
    onHide()
  }

  useEffect(() => {
    if (show) {
      setLoading(false)
      setDisplayedCcContacts(ccContactOptions.length ? ccContactOptions.map(jobContact => ({ value: jobContact.value, label: jobContact.label })) : [])
      setCurrentContact(defaultOption ? { value: defaultOption.id, label: `${defaultOption.name} (${defaultOption.contactType})`, name: defaultOption.name } : toContactOptionsList[0])
    }
  }, [show])


  useEffect(() => {
    let newEmailTemplate = emailTemplate
    if (currentContact && currentContact.value) {
      newEmailTemplate = emailTemplate.replace("{{Name}}", currentContact.name)
      if (additionalCc || contactsToBeCc.length > 0) {
        newEmailTemplate = emailTemplate.replace("{{Name}}", "All")
      }
    }
    if (bookingDates && bookingDates.length > 0) {
      const bookingDatesStr = bookingDates.map(date => `<p>- ${format(new Date(date), "EEEE, dd MMM yyyy")}</p>`).join("")
      newEmailTemplate = newEmailTemplate.replace("{{BookingDates}}", bookingDatesStr)
    }
    setMessage(newEmailTemplate)
  }, [currentContact, contactsToBeCc, additionalCc, bookingDates])

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header
        className="pb-3"
        closeButton>
        <Modal.Title>
          <h3 className="modal-title">Choose Who to Send To</h3>
        </Modal.Title>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <div className="form-group row">
          <label className="col-2 col-form-label">To</label>
          <div className="col-10">
            <Select
              className="assign-dropdown w-75"
              name="client"
              isSearchable
              placeholder="Select Client"
              options={toContactOptionsList.length ? toContactOptionsList : []}
              value={currentContact}
              onChange={e => {
                setCurrentContact(e);
              }} />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-2 col-form-label">CC</label>
          <form className="col-10">
            <div className="mb-4">
              {!!displayedCcContacts?.length && displayedCcContacts.map(contact => (
                <div className="custom-control custom-checkbox mb-2" key={contact.value}>
                  <input
                    className="custom-control-input"
                    id={contact.value}
                    type="checkbox"
                    onChange={e => handleContacts(e, contact)} />
                  <label className="custom-control-label" htmlFor={contact.value}>{contact.label}</label>
                </div>
              ))}
            </div>
          </form>
        </div>

        <div className="form-group row">
          <div className="col-2"></div>
          <div className="col-10">
            <label htmlFor="email">Additional Non-Contacts Cc</label>
            <input
              name="additional_cc"
              className="form-control"
              type="email"
              id="additional_cc"
              placeholder="Email"
              onChange={e => setAdditionalCc(e.target.value)} />
          </div>
        </div>

        <div className="form-group">
          <ReactQuill
            value={message}
            onChange={value => setMessage(value)} />
        </div>

        <div className="modal-footer d-flex align-items-center justify-content-between px-0 py-4">
          <button
            className="btn btn-blue btn-lg"
            onClick={onHide}
            type="button">
            Back
          </button>
          <button
            className="btn btn-red btn-lg"
            onClick={onSubmit}
            disabled={!toContactOptionsList?.length || loading}
            type="submit">
            Send to Client
            {loading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
          </button>
        </div>
      </div>
    </Modal>
  )
}
export default SendAccessEmailModal
