import React from "react"
import {
  Form, Col, Row, Button
 } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import Loader from "components/Loader"
import { updateMyPassword } from "requesters/userRequester"
import { getBaseUrl } from "../../../reducers"
import * as actionTypes from "../../../actions/types"

export default function UpdatePassword() {
  const baseUrl = useSelector(getBaseUrl)
  const dispatch = useDispatch()

  const { updatePasswordStatus } = useSelector(state => state.accountSettingsPage)

  function handlePwdFormSubmit(event) {
    event.preventDefault()
    const form = event.target
    const data = new FormData(form)
    const dataObj = Object.fromEntries(data)
    async function submitData() {
      dispatch({ type: actionTypes.CURRENT_USER_UPDATE_PWD_START })
      const { response, error } = await updateMyPassword(baseUrl, { user: dataObj })
      if (response) {
        dispatch({ type: actionTypes.CURRENT_USER_UPDATE_PWD_SUCCESS })
        window.location = "/users/sign_in"
      }
      if (error) {
        console.error(error, error.response)
        const { status, data } = error.response
        const message = _.map(_.get(data, "errors", []), "detail")
        if (status >= 400 && status < 500) {
          swal.fire({
            icon: "error",
            title: "Error",
            text: message,
          })
        }
        dispatch({ type: actionTypes.CURRENT_USER_UPDATE_PWD_FAILURE })
      }
    }
    submitData()
  }
  return (
    <>
      <h3 className="my-4">Change Password</h3>
      <p>You'll need to login again after update your password.</p>
      {updatePasswordStatus && (
      <div className="loader-overlay">
        <Loader />
      </div>
          )}
      <Form onSubmit={handlePwdFormSubmit}>
        <Form.Group as={Row} className="mb-2" controlId="current-password">
          <Form.Label column sm="4" xl="3">
            Current Password
          </Form.Label>
          <Col sm="6" xl="4">
            <Form.Control type="password" name="current_password" autoComplete="current-password" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-2" controlId="new-password">
          <Form.Label column sm="4" xl="3">
            New Password
          </Form.Label>
          <Col sm="6" xl="4">
            <Form.Control type="password" name="password" autoComplete="new-password" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-2" controlId="new-password-confirm">
          <Form.Label column sm="4" xl="3">
            Confirm Password
          </Form.Label>
          <Col sm="6" xl="4">
            <Form.Control type="password" name="password_confirmation" autoComplete="new-password" />
          </Col>
        </Form.Group>
        <Button variant="light" className="mt-4" type="submit">
          Update Password
        </Button>
      </Form>
    </>
)
}