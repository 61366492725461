import React from "react";
import { useSelector } from "react-redux";
import { getCurrentRole, getCurrentUser } from "../../reducers";
import { roleCheck } from "../../../../utils";
import { PROCESSOR } from "../../../../utils/constants";

const Processing = () => {
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role
  const currentRole = useSelector(getCurrentRole)
  const isProcessor = roleCheck(systemRole, currentRole, PROCESSOR)

  if (!isProcessor) window.location = "/"

  return (
    <div>
      Processing page
    </div>
  )
}

export default Processing