import React from "react";
import AccountsRow from "./AccountsRow";

export default function AccountsTable({ jobs, fetchData }) {
  return (
    <div className="table-responsive">
      <table className="table table-listing table-hover">
        <thead>
          <tr>
            <th>Job No.</th>
            <th>Job Address</th>
            <th>Job Service</th>
            <th>Contact</th>
            <th>Account Type</th>
            <th className="text-center absorbing-column">Invoice Type</th>
            <th className="text-center">Invoice Status</th>
            <th className="text-center">Job Status</th>
            <th className="text-center">Amount</th>
            {/* <th className="text-center">SLA</th> */}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {jobs.map(job => (
            <AccountsRow key={job.attributes.id} job={job} fetchData={fetchData} />
          ))}
        </tbody>
      </table>
    </div>
  );
}