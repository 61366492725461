import React, { useEffect, useState } from "react";
import { postBookingAsUser, removeBookingAsUser, updateJobTransition, updateJob } from "requesters/jobRequester"
import SweetAlert from "react-bootstrap-sweetalert";
import cx from "classnames"
import Select from "react-select"
import { getSurveyors, getDraftmen } from "requesters/userRequester"
import { getBaseUrl, getResource } from "../../../reducers"
import { useSelector } from "react-redux"

export default function DraftingStage({ job, isActive, reloadData }) {
  return (
    <div
      id="drafting"
      className={isActive ? "tab-pane fade active show" : "tab-pane fade"} />
  )
}