import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap"
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import { createNote } from "requesters/jobServiceRequester";
import { NEXT_VISIT_SITE_NOTES } from "utils/constants";
import { getBaseUrl, getCurrentUser } from "../../../reducers";

const AddNotesForNextSiteVisitModal = ({
  show, onHide, reloadData, jobService
}) => {
  const baseUrl = useSelector(getBaseUrl)
  const { id, attributes: { notesForNextSiteVisit } } = jobService
  const currentUser = useSelector(getCurrentUser)
  const userId = currentUser.id

  const [notes, setNotes] = useState(notesForNextSiteVisit || "")
  const [loading, setLoading] = useState(false)

  const createNotesHandler = () => {
    setLoading(true)
    createNote(baseUrl, id, userId, { notes, label: NEXT_VISIT_SITE_NOTES })
      .then(res => {
        if (res.status === 201) {
          reloadData()
          onHide()
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <div>
          <h2 className="modal-title">Add Note for Next Site Visit</h2>
        </div>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          <div className="form-group">
            <label>Notes</label>
            <ReactQuill
              style={{ height: "200px" }}
              placeholder="e.g. existing dwelling, any substantial trees"
              value={notes || ""}
              onChange={value => setNotes(value)} />
          </div>
        </form>
        <hr />
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-red btn-lg"
          onClick={() => createNotesHandler()}
          disabled={!notes || loading}
          type="submit">
          Save
          {loading && <Spinner animation="border" />}
        </button>
      </div>
    </Modal>
  )
}
export default AddNotesForNextSiteVisitModal
