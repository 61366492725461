import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getUsers } from "requesters/userRequester"
import { roleCheck } from "utils/index"

import {
    Container, Row, Col,
} from "react-bootstrap"

import {
  ADMIN, OPERATIONS, ACCOUNTS, OPERATION_MANAGER, ACCOUNTS_MANAGER
} from "utils/constants"
import { getGear } from "requesters/gearRequester"
import CustBreadcrumb from "components/CustBreadcrumb"
import Loader from "components/Loader"
import { getGearHistoryRecords } from "../../../../../requesters/gearHistoryRecordRequester"
import { getBaseUrl, getCurrentUser, getCurrentRole } from "../../../reducers"
import * as actionTypes from "../../../actions/types"
import GearDetailsCard from "./GearDetailsCard";
import GearHistoryRecords from "./GearHistoryRecords";

export default function GearDetails() {
    const { id } = useParams()
    const gearId = parseInt(id, 10)
    const dispatch = useDispatch()
    const baseUrl = useSelector(getBaseUrl)
    const currentRole = useSelector(getCurrentRole)
    const currentUser = useSelector(getCurrentUser)

    const readOnly = !(currentRole.includes(ADMIN) || currentRole.includes(OPERATIONS) || currentRole.includes(ACCOUNTS) || currentUser.attributes.role === ADMIN)

    async function fetchData() {
      dispatch({ type: actionTypes.GEAR_REQUEST_START })
      const { response, error } = await getGear(baseUrl, id)
      if (response) dispatch({ type: actionTypes.GEAR_REQUEST_SUCCESS, data: response })
      if (error) dispatch({ type: actionTypes.GEAR_REQUEST_FAILURE })
    }

    // fetchData
    useEffect(() => {
        fetchData()
    }, [baseUrl, gearId])

    return (
      <Container fluid className="gear-details-page pb-5">
        <GearBreadcrumb readOnly={readOnly} />
        <GearBody readOnly={readOnly} />
      </Container>
    )
}

function GearBreadcrumb({ readOnly }) {
    const { id } = useParams()
    const gear = _.get(useSelector(state => state.data.gears.byId), id)
    const { requestGearState } = useSelector(state => state.manageGearPage)
    const gear_identifier = requestGearState ? "..." : _.get(gear, `attributes.gearIdentifier`, "error")

    if (readOnly) return <CustBreadcrumb links={[["Account Settings", "/settings/account-settings"], ["My Assets", "/settings/my-gear"]]} active={gear_identifier} />
    return <CustBreadcrumb links={[["Settings", "/settings"], ["Company Assets", "/settings/gears"]]} active={gear_identifier} />
}

function GearBody({ readOnly }) {
  const currentUser = useSelector(getCurrentUser)
  const { id } = useParams()
  const gear = _.get(useSelector(state => state.data.gears.byId), id)
  const { requestGearState } = useSelector(state => state.manageGearPage)

  const [users, setUsers] = useState({})
  const [gearHistoryRecords, setGearHistoryRecords] = useState([])
  const [requesting, setRequesting] = useState(false)
  const gearId = parseInt(id, 10)
  const baseUrl = useSelector(getBaseUrl)

  const fetchUserData = () => {
    getUsers(baseUrl)
    .then(res => {
      if (res.status === 200) {
        setUsers(_.get(res.response, "user", {}))
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }

  useEffect(() => {
    fetchUserData()
  }, [baseUrl])

  const fetchGearHistoryData = () => {
    setRequesting(true)

    getGearHistoryRecords(baseUrl, gearId)
    .then(res => {
      if (res.status === 200) {
        setGearHistoryRecords(_.orderBy(res.response.history, "attributes.createdAt", "desc"))
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))

    setRequesting(false)
  }

  useEffect(() => {
    fetchGearHistoryData()
  }, [baseUrl, gearId])

  if (requestGearState) return <Loader />
  if (!gear) return <h1>{`Gear with id: ${id} doesn't exist!`}</h1>

  const { attributes: { gearIdentifier } } = gear

  return (
    <>
      <h2 className="mb-1">{gearIdentifier}</h2>
      <Row>
        <Col md={8}>
          <GearDetailsCard currentUser={currentUser} readOnly={readOnly} users={users} onUpdateGear={fetchGearHistoryData} />
          <GearHistoryRecords readOnly={readOnly} requesting={requesting} gearHistoryRecords={gearHistoryRecords} gearId={gearId} />
        </Col>
      </Row>
    </>
  )
}
