import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  updateJobServiceTransition
} from "requesters/jobServiceRequester";
import { getBaseUrl } from "../../../../reducers";
import ConfirmationModal from "../../modals/ConfirmationModal";

const BoundaryCalculationRequired = ({
  jobService, scheduledAssignment, currentUser, isAdmin
}) => {
  const {
    id,
    attributes: {
      jobNo,
      forProjectX,
      projectXJobNo,
      jobTitle
    }
  } = jobService
  const displayJobNo = forProjectX ? projectXJobNo : jobNo

  const { assignments } = scheduledAssignment

  const assignment = assignments.find(a => a.surveyorId === Number(currentUser?.id))
    || assignments.find(a => a.surveyorId === Number(jobService?.attributes?.primarySurveyorId)) || {};

  const { uploadedMandatoryFiles } = assignment

  const baseUrl = useSelector(getBaseUrl)

  const sendToRegSurveyor = () => {
    updateJobServiceTransition(baseUrl, id, "move-to-registered-surveyor")
      .then(res => {
        if (res.status === 200) {
          window.location.reload()
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  const [showSendToRsForCalculation, setShowSendToRsForCalculation] = useState(false)
  return (
    <div className="row align-items-center mt-4">
      <div className="mr-4">Send to</div>
      <div className="mr-2">
        <button disabled={!uploadedMandatoryFiles && !isAdmin} className="btn btn btn-outline-primary" type="button" onClick={() => setShowSendToRsForCalculation(true)}>Registered Surveyor for Calculation</button>
        <ConfirmationModal
          jobNo={displayJobNo}
          jobTitle={jobTitle}
          show={showSendToRsForCalculation}
          resetState={() => setShowSendToRsForCalculation(false)}
          sendTo={() => {
            setShowSendToRsForCalculation(false)
            sendToRegSurveyor()
          }}
          messageType="Registered Surveyor for Calculation">
        </ConfirmationModal>

      </div>
    </div>
  )
}
export default BoundaryCalculationRequired
