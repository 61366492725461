export const INIT_APPLICATION = "@@app/INIT"
export const RESOURCE_FETCHED_SUCCESS = "@@axios/DATA_RETRIEVED"

export const FLASH_TRIGGERED = "Flash/Triggered"

// Team
export const TEAM_REQUEST_SUCCESS = "Teams/RequestSuccess"

// TeamUsers
export const TEAM_USER_REQUEST_SUCCESS = "TeamUsers/RequestSuccess"

// Surveyor Skill
export const SURVEYOR_SKILL_REQUEST_START = "SurveyorSkills/RequestStart"
export const SURVEYOR_SKILL_REQUEST_SUCCESS = "SurveyorSkills/RequestSuccess"
export const SURVEYOR_SKILL_REQUEST_FAILURE = "SurveyorSkills/RequestFailure"

// manage users page
// User
export const USER_REQUEST_START = "ManageUsers/User/RequestStart"
export const USER_REQUEST_SUCCESS = "ManageUsers/User/RequestSuccess"
export const USER_REQUEST_FAILURE = "ManageUsers/User/RequestFailure"
export const USER_POST_START = "ManageUsers/User/PostStart"
export const USER_POST_SUCCESS = "ManageUsers/User/PostSuccess"
export const USER_POST_FAILURE = "ManageUsers/User/PostFailure"
export const MANAGE_USERS_START_EDITING = "ManageUsers/StartEditing"
export const MANAGE_USERS_EDIT_UPDATE = "ManageUsers/EditUpdate"
export const MANAGE_USERS_EDIT_SAVE = "ManageUsers/EditSave"
export const MANAGE_USERS_EDIT_CANCEL = "ManageUsers/EditCancel"
export const MANAGE_USERS_EDIT_RESET = "ManageUsers/EditReset"
export const TEAM_USER_DELETE_REQUEST_SUCCESS = "TeamUser/Delete/RequestSuccess"
export const TEAM_USER_DELETE_REQUEST_FAILURE = "TeamUser/Delete/RequestFailure"
export const TEAM_USER_CREATE_REQUEST_SUCCESS = "TeamUser/Create/RequestSuccess"
export const TEAM_USER_CREATE_REQUEST_FAILURE = "TeamUser/Create/RequestFailure"
export const TEAM_USER_SET_DEFAULT_REQUEST_SUCCESS = "TeamUser/SetDefault/RequestSuccess"
export const TEAM_USER_SET_DEFAULT_REQUEST_FAILURE = "TeamUser/SetDefault/RequestFailure"

export const CURRENT_USER_SET_EDIT_FIELDS = "CurrentUser/SetEditFields"
export const CURRENT_USER_START_EDITING = "CurrentUser/StartEditing"
export const CURRENT_USER_EDIT_UPDATE = "CurrentUser/EditUpdate"
export const CURRENT_USER_EDIT_SAVE = "CurrentUser/EditSave"
export const CURRENT_USER_EDIT_CANCEL = "CurrentUser/EditCancel"
export const CURRENT_USER_POST_START = "CurrentUser/Post/RequestStart"
export const CURRENT_USER_POST_SUCCESS = "CurrentUser/Post/RequestSuccess"
export const CURRENT_USER_POST_FAILURE = "CurrentUser/Post/RequestFailure"

export const CURRENT_USER_UPDATE_PWD_START = "CurrentUser/UpdatePassword/RequestStart"
export const CURRENT_USER_UPDATE_PWD_SUCCESS = "CurrentUser/UpdatePassword/RequestSuccess"
export const CURRENT_USER_UPDATE_PWD_FAILURE = "CurrentUser/UpdatePassword/RequestFailure"

export const CURRENT_USER_UPDATE_CURRENT_ROLE_REQUEST_START = "CurrentUser/UpdateCurrentRole/RequestStart"

// manage gear page
// Gear
export const GEAR_REQUEST_START = "ManageGears/Gear/RequestStart"
export const GEAR_REQUEST_SUCCESS = "ManageGears/Gear/RequestSuccess"
export const GEAR_REQUEST_FAILURE = "ManageGears/Gear/RequestFailure"
export const GEAR_PUT_START = "ManageGears/Gear/PutStart"
export const GEAR_PUT_SUCCESS = "ManageGears/Gear/PutSuccess"
export const GEAR_PUT_FAILURE = "ManageGears/Gear/PutFailure"
export const GEAR_POST_START = "ManageGears/Gear/PostStart"
export const GEAR_POST_SUCCESS = "ManageGears/Gear/PostSuccess"
export const GEAR_POST_FAILURE = "ManageGears/Gear/PostFailure"
export const MANAGE_GEARS_START_EDITING = "ManageGears/StartEditing"
export const MANAGE_GEARS_EDIT_UPDATE = "ManageGears/EditUpdate"
export const MANAGE_GEARS_EDIT_SAVE = "ManageGears/EditSave"
export const MANAGE_GEARS_EDIT_CANCEL = "ManageGears/EditCancel"
export const MANAGE_GEARS_START_CREATING = "ManageGears/StartCreating"
export const MANAGE_GEARS_CREATE_UPDATE = "ManageGears/CreateUpdate"
export const MANAGE_GEARS_CREATE_SAVE = "ManageGears/CreateSave"
export const MANAGE_GEARS_CREATE_CANCEL = "ManageGears/CreateCancel"
export const MANAGE_GEAR_HISTORY_RECORDS_START_CREATING = "ManageGearHistoryRecords/StartCreating"
export const MANAGE_GEAR_HISTORY_RECORDS_CREATE_UPDATE = "ManageGearHistoryRecords/CreateUpdate"
export const MANAGE_GEAR_HISTORY_RECORDS_CREATE_SAVE = "ManageGearHistoryRecords/CreateSave"
export const MANAGE_GEAR_HISTORY_RECORDS_CREATE_CANCEL = "ManageGearHistoryRecords/CreateCancel"
export const GEAR_HISTORY_RECORD_POST_START = "ManageGearHistoryRecords/GearHistoryRecord/PostStart"
export const GEAR_HISTORY_RECORD_POST_SUCCESS = "ManageGearHistoryRecords/GearHistoryRecord/PostSuccess"
export const GEAR_HISTORY_RECORD_POST_FAILURE = "ManageGearHistoryRecords/GearHistoryRecord/PostFailure"



export const SCHEDULE_PAGE_REQUEST_START = "SchedulePage/Request/Start"
export const SCHEDULE_PAGE_REQUEST_SUCCESS = "SchedulePage/Request/Success"
export const SCHEDULE_PAGE_REQUEST_FAILURE = "SchedulePage/Request/Failure"
