import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Container } from "react-bootstrap"
import { useQueryParam, StringParam } from "use-query-params"

import SearchInput from "components/SearchInput"
import FilterDropdown from "components/FilterDropdown"
import Loader from "components/Loader"
import { getJobs } from "requesters/jobRequester"
import JobsTable from "./JobsTable"

import { getBaseUrl } from "../../reducers"

export default function Jobs({ history }) {
  const baseUrl = useSelector(getBaseUrl)
  const { jobStatus, slaTypes, jobTypes } = useSelector(state => state.options)

  // const [byStatus, setByStatus]   = useQueryParam("by_status", StringParam)
  // const [bySLA, setBySLA]         = useQueryParam("by_sla", StringParam)
  const [byJobType, setByJobType] = useQueryParam("by_job_type", StringParam)
  const [bySearch, setBySearch] = useQueryParam("by_search", StringParam)

  // const byStatusName = _.get(jobStatus, `${_.findIndex(jobStatus, status => status[1] === byStatus)}.0`)
  // const bySLAName = _.get(slaTypes, `${_.findIndex(slaTypes, type => type[1] === bySLA)}.0`)
  const byJobTypeName = _.get(jobTypes, `${_.findIndex(jobTypes, type => type[1] === byJobType)}.0`)

  const [jobs, setJobs] = useState([])
  const [requesting, setRequesting] = useState(false)

  // const [showLeadModal, setShowLeadModal] = useState(false)

  useEffect(() => {
    async function fetchData() {
      setRequesting(true)
      const { response, error } = await getJobs(baseUrl, {
        // by_status: byStatus,
        // by_sla: bySLA,
        by_job_type: byJobType,
        by_keyword: bySearch
      })
      if (response) {
        setJobs(_.get(response, "job", {}))
      }
      if (error) {
        console.error(error)
      }
      setRequesting(false)
    }
    fetchData()
  }, [baseUrl, byJobType, bySearch])

  return (
    <Container fluid className="manage-jobs-page">
      <div className="container-inner">
        <div className="d-flex justify-content-between align-items-center header-tabs-container">
          <div className="header-tabs">
            <h1>Jobs</h1>
          </div>
        </div>
        <div className="table-listing-filters">
          <span className="mr-2">
            <SearchInput value={bySearch} setValue={setBySearch} />
          </span>
          <div className="btn-group mr-2">
            <FilterDropdown title="Job Type" filteredName={byJobTypeName} setFilter={setByJobType} options={jobTypes} />
          </div>
        </div>
        {requesting ? <Loader /> : <JobsTable jobs={Object.values(jobs)} />}
      </div>
    </Container>
  )
}
