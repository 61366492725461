import React from "react"
import { useSelector } from "react-redux"
import { Link, NavLink } from "react-router-dom"
import { NavDropdown, Nav, Navbar, Container } from "react-bootstrap"

import caLogo from "../../../../../assets/images/ca-logo.svg"
import { getCurrentUser } from "../../reducers"


export default function NavBarCustomer() {
  const currentUser = useSelector(getCurrentUser)
  if (!currentUser) { window.location.href = "/login" }
  const {
    attributes: {
      firstname
    }
  } = currentUser
  return (
    <nav className="navbar navbar-light bg-white">
      <Container fluid>
        <div className="w-100 d-flex align-items-center justify-content-between">

          <Link to="/customer" className="navbar-brand w-50" >
            <img src={caLogo} alt="CA SURVEYOR" className="img-fluid" />
          </Link>
          <div>
            {`Hi ${firstname}, `}
            <Link to="/logout" >logout</Link>
          </div>
        </div>
      </Container>
    </nav>
  )
}