import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert"
import { getBaseUrl } from "../../../reducers";
import { requestCollectPaymentOnsiteForJobService, updateCollectPaymentOnsiteAmount } from "../../../../../requesters/jobServiceRequester";

const CollectPaymentOnsiteModal = ({
  show, onHide, reloadData, jobService, canUpdate
}) => {
  const {
    attributes: { productPrice, collectPaymentOnsiteRequested, collectPaymentOnsiteRequestedAmount }
  } = jobService
  const canPerformUpdateAction = canUpdate && collectPaymentOnsiteRequested && collectPaymentOnsiteRequestedAmount

  const baseUrl = useSelector(getBaseUrl)
  const [requesting, setRequesting] = useState(false)
  const [requestAmount, setRequestAmount] = useState(productPrice)

  // eslint-disable-next-line no-restricted-globals
  const notAllowToRequest = collectPaymentOnsiteRequested || requesting || isNaN(parseFloat(requestAmount))
  // eslint-disable-next-line no-restricted-globals
  const notAllowToClickUpdate = requesting || isNaN(parseFloat(requestAmount))

  const onRequestAmountChange = newRequestAmount => {
    if (parseFloat(newRequestAmount) && parseFloat(newRequestAmount) > parseFloat(productPrice)) return;

    setRequestAmount(newRequestAmount)
  }

  const requestCollectPaymentOnsite = () => {
    setRequesting(true)
    requestCollectPaymentOnsiteForJobService(baseUrl, jobService.id, requestAmount)
    .then(res => {
      if (res.status === 200 && res.response.success) {
        reloadData()
        onHide()
      }
    })
    .catch(err => console.log(err))
    .finally(() => setRequesting(false))
  }

  const updateCollectPaymentOnsite = () => {
    setRequesting(true)
    updateCollectPaymentOnsiteAmount(baseUrl, jobService.id, requestAmount)
    .then(res => {
      if (res.status === 200 && res.response.success) {
        reloadData()
        onHide()
      }
    })
    .catch(err => console.log(err))
    .finally(() => setRequesting(false))
  }

  useEffect(() => {
    if (canPerformUpdateAction && requestAmount !== collectPaymentOnsiteRequestedAmount) {
      setRequestAmount(collectPaymentOnsiteRequestedAmount)
    }
  }, [canUpdate])

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <div>
          <h2 className="modal-title">Collect Payment Onsite</h2>
        </div>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          <div className="form-group row">
            <label className="col-5 col-form-label"> Total Amount (Product price) </label>
            <div className="col-5">
              <input
                disabled
                className="form-control"
                inputMode="decimal"
                pattern="[0-9]*"
                type="number"
                value={productPrice} />
            </div>
          </div>
          { canPerformUpdateAction && (
            <div className="form-group row">
              <label className="col-5 col-form-label"> Requested Amount </label>
              <div className="col-5">
                <input
                  disabled
                  className="form-control"
                  inputMode="decimal"
                  pattern="[0-9]*"
                  type="number"
                  value={collectPaymentOnsiteRequestedAmount} />
              </div>
            </div>
          )}
          <div className="form-group row">
            <label className="col-5 col-form-label">How much to Collect?</label>
            <div className="col-5">
              <input
                className="form-control"
                inputMode="decimal"
                min={0}
                pattern="[0-9]*"
                step="1"
                type="number"
                onChange={e => onRequestAmountChange(e.target.value)}
                value={requestAmount} />
            </div>
          </div>
        </form>
        <hr />
      </div>
      <div className="modal-footer">
        { canPerformUpdateAction ? (
          <button
            className="btn btn-primary btn-lg"
            onClick={updateCollectPaymentOnsite}
            disabled={notAllowToClickUpdate}
            type="submit">
            Update Request
            {requesting && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
          </button>
        ) : (
          <button
            className="btn btn-primary btn-lg"
            onClick={requestCollectPaymentOnsite}
            disabled={notAllowToRequest}
            type="submit">
            Request Collect
            {requesting && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
          </button>
        )}
      </div>
    </Modal>
  )
}

export default CollectPaymentOnsiteModal
