import React from "react";
import JobServicesRow from "./JobServicesRow";

export default function JobServicesTable({ jobServiceList }) {
  return (
    <div className="table-responsive">
      <table className="table table-listing table-hover">
        <thead>
          <tr>
            <th>Job No.</th>
            <th>Job Address</th>
            <th>Client Name</th>
            <th className="absorbing-column">Client Number</th>
            <th>Job Service</th>
            <th className="text-center">Status</th>
            <th className="text-center absorbing-column">Date of Field Work</th>
            <th className="text-center absorbing-column">Due Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!!jobServiceList?.length && jobServiceList.map(job => (
            <JobServicesRow key={job.attributes.id} job={job} />
          ))}
        </tbody>
      </table>
    </div>
  );
}