import React from "react"

export default function DashboardNoTeam({ currentUser }) {
  const { attributes: { firstname } } = currentUser

  return (
    <div className="dashboard">
      <div className="row no-gutters">
        <div className="col-lg-4 col-xl-3">
          <div className="container-fluid">
            <div className="container-inner">
              <div className="greeting">
                Hello,
                {" "}
                <br />
                {" "}
                {firstname}
                {" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
