import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { useSelector } from "react-redux"
import { postComment } from "requesters/commentRequester"
import { Link } from "react-router-dom"
import {
  GPS_AHD,
  OPERATION_MANAGER,
} from "utils/constants"
import { roleCheck } from "utils/index"
import { addAdditionalScheduledDay } from "../../../../../../requesters/jobServiceRequester"
import { getBaseUrl, getCurrentUser } from "../../../../reducers"
import SelectSurveyor from "../dropdowns/SelectSurveyor"
import BookingConfirmed from "./BookingConfirmed"
import ToConfirm from "./ToConfirm"

const FieldWorkStates = ({ jobService, reloadData, setActiveTab }) => {
  const {
    attributes: {
      state,
      code,
      jobId,
      availableToCheckNearbyJobs,
      totalNearbyFutureBookings,
      scheduledAssignments,
      allCheckedOut,
      accountType,
      jobPackUploaded,
      payBeforeDrafting,
      invoiceState,
      cost,
      workflow,
      draftedPrimarySurveyorId,
      confirmationStatus,
    }, id
  } = jobService

  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role

  const baseUrl = useSelector(getBaseUrl)
  const [comment, setComment] = useState("")
  const [showAdditionalDayConfirmation, setShowAdditionalDayConfirmation] = useState(false)
  const [currentUserAssigned, setCurrentUserAssigned] = useState()

  const isGpsAhd = (code === GPS_AHD)

  const saveComment = (activityID, comment) => {
    const newComment = {
      commentableId: activityID,
      commentBody: comment,
      commentableType: "Activity",
      currentUserId: currentUser.id,
    }
    postComment(baseUrl, newComment)
      .then(res => {
        if (res.status === 201) {
          window.location.reload()
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  const jobPackWarningRender = () => {
    if (isGpsAhd) {
      return false
    }
    if (!jobPackUploaded && accountType === "50% - 50%" && (payBeforeDrafting || ["paid_deposit", "paid_full"].includes(invoiceState) || Number(cost) === 0)) {
      return true
    }
    if (!jobPackUploaded && accountType !== "50% - 50%") {
      return true
    }
    return false
  }

  const jobDeclinedWarningRender = () => {
    return confirmationStatus === 'declined'
  }

  const handleAddAdditionalScheduleDay = () => {
    addAdditionalScheduledDay(baseUrl, id)
      .then(() => {
        setShowAdditionalDayConfirmation(false)
        reloadData();
      })
  }

  const getCurrentUserAssignmentRole = () => {
    scheduledAssignments.forEach(scheduledAssignment => {
      setCurrentUserAssigned(scheduledAssignment.assignments.find(a => a.surveyorId === Number(currentUser.id)))
    })
  }

  useEffect(() => {
    getCurrentUserAssignmentRole()
  }, [id])

  const currentUserIsSurveyor = scheduledAssignments.some(scheduled =>
    scheduled.assignments.some(assignment => assignment.surveyorId === Number(currentUser.id))
  );

  if (!roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) && !currentUserIsSurveyor) {
    return null
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <li className="activity timeline-activity datepicker-container mb-0" key="BookingConfirmed">
          <div className="circle circle-outline" />
          <div className="d-flex justify-content-between">
            <div className="activity-status">
              <strong>{state === "to_confirm" ? "Preferred Booking Date" : "Field Work"}</strong>
            </div>
          </div>
        </li>
        {(roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) && (state === "to_confirm" || state === "confirmed" || state === "job_pack_incomplete") && workflow !== "8") && (
          <SelectSurveyor jobService={jobService} reloadData={reloadData} />
        )}
      </div>
      <li className="activity timeline-activity" key={`row-${2}`}>
        {jobDeclinedWarningRender() && (
          <p className="mb-0 mt-3 bg-coral py-2 px-3 rounded">Client Declined</p>
        )}
        {jobPackWarningRender() && (
          <p className="mb-0 mt-3 bg-coral py-2 px-3 rounded">Job Pack files missing</p>
        )}
        {currentUserAssigned?.role === "assistant" && (
          <p className="mb-0 mt-3 bg-secondary py-2 px-3 rounded text-white">Job assistant</p>
        )}

        {scheduledAssignments.map((scheduledAssignment, index) => {
          const assigned = scheduledAssignment.assignments.find(a => a.surveyorId === Number(currentUser.id))

          return (roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) || assigned) && (
            <div className="timeline-item mb-3" key={scheduledAssignment?.scheduledAt}>
              <BookingConfirmed
                showActionButtons={allCheckedOut && scheduledAssignments.length === index + 1}
                showRequestGps={index === 0}
                jobService={jobService}
                scheduledAssignment={scheduledAssignment}
                reloadData={reloadData}
                currentUser={currentUser}
                systemRole={systemRole}
                currentTeamRole={currentTeamRole}
                activityID={id}
                saveComment={saveComment}
                comment={comment}
                setComment={setComment}
                setActiveTab={setActiveTab} />
            </div>
          )
        })}

        {(roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) && (state === "to_confirm" || state === "confirmed")) && (
          <>
            <div className="text-left">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-inline-block">
                  <button className="btn btn-link" type="button" onClick={() => setShowAdditionalDayConfirmation(true)}>Add additional date</button>
                </div>
                <div>
                  { availableToCheckNearbyJobs ? (
                    <Link
                      to={`/jobs/${jobId}/job_services/${id}?tab=Nearby Jobs&future_jobs_only=1`}
                      className="btn btn-link text-underline">
                      {`${totalNearbyFutureBookings} jobs nearby booked-in`}
                    </Link>
                  ) : (
                    <span>No Lat/Lon Available</span>
                  )}
                </div>
              </div>
            </div>
            <SweetAlert
              show={showAdditionalDayConfirmation}
              type="info"
              title="Are you sure?"
              style={{ width: "600px" }}
              closeOnClickOutside
              allowEscape={false}
              showCancel
              cancelBtnBsStyle="secondary"
              onCancel={() => { setShowAdditionalDayConfirmation(false) }}
              onConfirm={handleAddAdditionalScheduleDay}>
              This will create a new schedule with the next available business day of the primary surveyor.
            </SweetAlert>
          </>
        )}

      </li>
      {(roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) && state === "to_confirm" && (draftedPrimarySurveyorId || confirmationStatus === 'declined')) && (
        <li className="activity timeline-activity" key={`row-${3}`}>
          <ToConfirm jobService={jobService} reloadData={reloadData} />
        </li>
      )}
    </>
  )
}

export default FieldWorkStates
