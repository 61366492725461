import React from "react";

import SummaryReport from "./SummaryReport"
import DetailedReport from "./DetailedReport"


const PerformanceTable = ({ summaryRows, totalRow, detailedRows }) => (
  <>
    <div className="my-5">
      {summaryRows?.length
    ? (

      <SummaryReport
        summaryRows={summaryRows}
        totalRow={totalRow} />

    ) : (
      "No Data Available"
    )}
    </div>
    <div>
      {detailedRows?.length ? (detailedRows.map(detailedRow => (
        <DetailedReport
          detailedRows={detailedRow}
          key={detailedRow.name} />
    ))) : (
      null
    )}
    </div>
  </>
  )

export default PerformanceTable;