import React, { useState } from "react"
import { useSelector } from "react-redux"
import { updateJobService } from "requesters/jobServiceRequester"
import StatusTableDropdown from "components/StatusTableDropdown"
import { Link, useLocation } from "react-router-dom"
import SweetAlert from "react-bootstrap-sweetalert"
import cx from "classnames"
import { useDetectClickOutside } from "react-detect-click-outside"
import {
  ON_HOLD,
  CANCELLED,
  CLIENT_DELAYED
} from "utils/constants"
import JobServiceTableNote from "../Schedules/JobServiceTableNote"
import JobServiceTableBookingTime from "../Schedules/JobServiceTableBookingTime"
import { getBaseUrl } from "../../reducers"


export default function FlaggedJobRow({
  rowData, reloadData, bySection, readOnly
}) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isCancelledFilter = searchParams.get('filter') === 'cancelled';
  const baseUrl = useSelector(getBaseUrl)
  const [showSurveyorModal, setShowSurveyorModal] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [alertType, setAlertType] = useState("error")
  const [alertTitle, setAlertTitle] = useState("")

  const {
    id, attributes: {
      checkedInAt,
      clientName,
      clientNumber,
      state,
      cost,
      jobAddress,
      jobId,
      jobNo,
      forProjectX,
      projectXJobNo,
      label,
      surveyorId,
      surveyorsList,
      draftedPrimarySurveyorName,
      draftedPrimarySurveyorId,
      bookingTime,
      cancelledAt,
    }
  } = rowData
  const displayJobNo = forProjectX ? projectXJobNo : jobNo

  async function updateJobServiceHandler(params) {
    const { response, error } = await updateJobService(baseUrl, id, params)
    if (response) {
      reloadData()
    }
    if (error) {
      setAlertTitle("Ooops! Something went wrong")
      setAlertType("error")
      setShowMessage(true)
    }
  }

  const handleSurveyorModal = () => {
    if (!readOnly && !checkedInAt) {
      setShowSurveyorModal(true)
    }
  }

  const SurveyorModal = ({ setShowSurveyorModal }) => {
    const closeModal = () => {
      setShowSurveyorModal(false)
    }
    const ref = useDetectClickOutside({ onTriggered: closeModal });
    return (
      <div
        onMouseLeave={closeModal}
        className={cx("dropdown-menu shadow-sm", { "show-modal": showSurveyorModal })}
        ref={ref}>
        <p className="small mb-2">Select surveyor</p>
        <select
          className="form-control"
          value={surveyorId || ""}
          onChange={e => {
            updateJobServiceHandler({ drafted_primary_surveyor_id: e.target.value })
            setShowSurveyorModal(false)
          }}>
          {
            !!surveyorsList?.length && surveyorsList.map(option => (
              <option
                disabled={option.disabled}
                value={option.value}
                key={option.suggestedId || option.value}>
                {option.label}
              </option>
            ))
          }
        </select>
      </div>
    )
  }

  return (
    <tr className={[ON_HOLD, CANCELLED, CLIENT_DELAYED].includes(state) ? "greyout" : ""}>
      <td>
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{displayJobNo}</Link>
      </td>
      <td>
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{clientName}</Link>
      </td>
      <td>
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{clientNumber}</Link>
      </td>
      <td>
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{jobAddress}</Link>
      </td>
      <td>
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{label}</Link>
      </td>
      {!readOnly && (
        <td className="text-right">
          <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{`$${cost}`}</Link>
        </td>
      )}
      {
        bySection !== "to_be_booked"
          ? (
            <>
              <StatusTableDropdown jobService={rowData} reloadData={reloadData} readOnly={readOnly} surveyor={draftedPrimarySurveyorId} />
              <td className="dropdown bg-light pick-surveyor">
                <button
                  className="btn btn-block"
                  disabled // TODO: fix in v 1.1
                  type="button"
                  onClick={() => handleSurveyorModal()}>
                  {draftedPrimarySurveyorName}
                </button>
                <SurveyorModal setShowSurveyorModal={setShowSurveyorModal} />
              </td>
              <JobServiceTableBookingTime jobService={rowData} reloadData={reloadData} readOnly bySection={bySection} bookingTime={isCancelledFilter ? cancelledAt : bookingTime} />
              <JobServiceTableNote rowData={rowData} reloadData={reloadData} readOnly={readOnly} />
            </>
          )
          : (
            <>
              <td className="dropdown text-center bg-light-blue pl-2 pr-2">INVITATION SENT</td>
              <td></td>
              <td></td>
              <td></td>
            </>
          )
      }
      <td><Link to={`/jobs/${jobId}/job_services/${id}`}>Details</Link></td>
      <SweetAlert
        show={showMessage}
        type={alertType}
        title={alertTitle}
        closeOnClickOutside
        allowEscape={false}
        onConfirm={() => setShowMessage(false)} />
    </tr>
  )
}
