import { getBaseUrl } from "apps/App/reducers"
import moment from "moment"
import React, { useState } from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { createPublicHoliday, updatePublicHoliday } from "requesters/publicHolidaysRequester"
import HolidayBasicInput from "./HolidayBasicInput"
import HolidayDateInput from "./HolidayDateInput"

export default function HolidayForm({ holiday = null }) {
  const history = useHistory();
  const baseUrl = useSelector(getBaseUrl)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [holidayForm, setHolidayForm] = useState(holiday || {})
  const { id, attributes } = holidayForm
  const holidayAttrs = ["name", "startAt", "endAt"]
  const toastOptions = {
    toastId: "user-details-page",
    containerId: "main"
  }

  function updateAttr(data) {
    setHolidayForm({ ...holidayForm, ...{ attributes: { ...attributes, ...data } } })
  }

  async function updateHoliday() {
    const result = await updatePublicHoliday(baseUrl, { id, attributes })
    if ("error" in result) {
      toast.error(
        <div>
          <span role="img" aria-label="failure-icon">❌</span>
          <span className="ml-2">Holiday update error!</span>
        </div>,
        toastOptions
      )
    } else {
      toast.success(
        <div>
          <span role="img" aria-label="success-icon">✅</span>
          <span className="ml-2">Holiday updated!</span>
        </div>,
        toastOptions
      )
    }
    setDisableSubmit(false)
  }

  async function createHoliday() {
    const result = await createPublicHoliday(baseUrl, { id, attributes })
    if ("error" in result) {
      toast.error(
        <div>
          <span role="img" aria-label="failure-icon">❌</span>
          <span className="ml-2">Holiday update error!</span>
        </div>,
        toastOptions
      )
    } else {
      history.push("/settings/manage-holidays");
    }
    setDisableSubmit(false)
  }

  function handleSave() {
    setDisableSubmit(true)

    if (holiday) {
      updateHoliday()
    } else {
      createHoliday()
    }
  }


  return (
    <>
      <h2 className="mb-1">{holiday?.attributes?.name || "New holiday"}</h2>
      <Row>
        <Col md={8}>
          <div className="card border-0 shadow-sm">
            <div className="card-body p-4">
              {
                holidayAttrs.map(attr => {
                  if (["startAt", "endAt"].includes(attr)) {
                    return (
                      <HolidayDateInput
                        key={attr}
                        field={attr}
                        value={attributes?.[attr]}
                        setInputValue={value => { updateAttr({ [attr]: moment(value).format("yyyy-MM-DD") }) }} />
                    )
                  }
                    return (
                      <HolidayBasicInput
                        key={attr}
                        field={attr}
                        inputValue={attributes?.[attr]}
                        setInputValue={value => { updateAttr({ [attr]: value }) }} />
                    )
                })
              }
              <div className="mt-4">
                <button
                  className="btn btn-red"
                  onClick={handleSave}
                  disabled={disableSubmit}
                  type="submit">
                  {disableSubmit
                    ? <Spinner animation="border" role="status" size="sm" />
                    : `${holiday ? "Save" : "Add Holiday"}`
                  }
                </button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}
