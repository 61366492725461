import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { toast, ToastContainer } from "react-toastify"

import "react-toastify/dist/ReactToastify.css"
import * as actionTypes from "../actions/types"

const toastOptions =  {
  toastId: "rails-flash",
  containerId: "main"
}

export default function Notifications() {
  const dispatch = useDispatch()
  const { flash } = useSelector(state => state)
  useEffect(() => {
    if (flash.length) {
      flash.forEach(f => {
        if (f[0] === "alert") {
          toast.error(
            <div>
              <span role="img" aria-label="failure-icon">❌</span>
              <span className="ml-2">{f[1]}</span>
            </div>,
            toastOptions
          )
        } else if (f[0] === "notice") {
          toast.success(
            <div>
              <span role="img" aria-label="success-icon">✅</span>
              <span className="ml-2">{f[1]}</span>
            </div>,
            toastOptions
          )
        }
      })
      dispatch({ type: actionTypes.FLASH_TRIGGERED })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div>
      <ToastContainer
        enableMultiContainer
        containerId="main" />

      <ToastContainer
        enableMultiContainer
        containerId="axios"
        closeButton={false}
        limit={1} />

      {/* <ToastContainer
        enableMultiContainer
        containerId="cable"
        position="bottom-right"
        className="toast-thin"
        autoClose={3000}
        limit={1} /> */}
    </div>
  )
}
