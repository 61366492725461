import React, { useState, useCallback } from "react";
import SweetAlert from "react-bootstrap-sweetalert"
import { format } from "date-fns";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getUploadedFile } from "requesters/uploadsRequester";
import { getBaseUrl } from "../../../reducers";
import FileIcon from "../../JobDetails/tabs/fileRow/FileIcon";
import { deleteTaskAttechment } from "../../../../../requesters/taskRequester";

export default function FileRow({
  taskId,
  file,
  isOwnerFile,
  canDelete,
  rowStyles,
  reloadData
}) {
  const {
    id: fileId,
    attributes: {
      createdByUser,
      fileTrackedUrl,
      fileName,
      createdAt,
      name
    }
  } = file.data
  const baseUrl = useSelector(getBaseUrl)
  const [loadingFileUrl, setLoadingFileUrl] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  if (!isOwnerFile) return <></>;

  const attachAndDetachDownloadLink = downloadLink => {
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  const onClickFile = async () => {
    setLoadingFileUrl(true)
    const result = await getUploadedFile(fileTrackedUrl)
    if ("response" in result) {
      const extension = result?.response?.json?.extension || ""
      const download_url = result?.response?.json?.download_url
      const file_name = result?.response?.json?.file_name
      const downloadLink = document.createElement("a");
      const isUrlFile = extension && extension.toLowerCase() === "url"

      if (isUrlFile) {
        fetch(download_url)
          .then(data => data.text())
          .then(content => {
            const lines = content?.split("\n") || []
            const urlLine = lines.find(l => l.toLowerCase().includes("url="))
            const url = urlLine?.split("=")?.pop()
            downloadLink.href = url;
            downloadLink.setAttribute("target", "_blank")
            attachAndDetachDownloadLink(downloadLink)
            setLoadingFileUrl(false)
          })
      } else if (extension && extension.toLowerCase() === "xml") {
        fetch(download_url)
          .then(data => data.blob())
          .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            downloadLink.href = url;
            downloadLink.setAttribute("download", file_name)
            attachAndDetachDownloadLink(downloadLink)
            setLoadingFileUrl(false)
          })
          .catch(() => {
            setLoadingFileUrl(false)
          })
      } else {
        downloadLink.href = download_url;
        downloadLink.setAttribute("download", file_name)
        downloadLink.setAttribute("target", "_blank")
        attachAndDetachDownloadLink(downloadLink)
        setLoadingFileUrl(false)
      }
    } else {
      setLoadingFileUrl(false)
    }
  }

  const onDeleteFile = () => {
    deleteTaskAttechment(baseUrl, taskId, [fileId])
    .then(res => {
      if (res.status === 200) {
        setShowDeleteConfirmation(false)
        reloadData()
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }

  return (
    <tr className={`border-top border-bottom my-2 ${rowStyles}`}>
      <td className="align-middle">
        <div className="row no-gutters align-items-center">
          <FileIcon file={file} />
          <Button
            disabled={loadingFileUrl}
            variant="link"
            onClick={() => onClickFile()}
            title={name || fileName}
            className="text-truncate text-left">
            {name || fileName}
          </Button>
        </div>
      </td>
      <td className="text-secondary text-center align-middle">{createdByUser}</td>
      <td className="text-center text-secondary align-middle">
        <span className="mr-2">{format(new Date(createdAt), "dd MMM yyyy, h:mm aa")}</span>
      </td>
      { canDelete && (
        <td style={{ fontSize: "1rem" }} className="align-middle">
          <i
            className="bi bi-x-circle-fill text-danger delete-file"
            onClick={() => setShowDeleteConfirmation(true)}
            title="Delete File" />
          <SweetAlert
            show={showDeleteConfirmation}
            type="custom"
            customClass="text-dark"
            title="Are you sure you want to delete this file?"
            style={{ width: "600px" }}
            confirmBtnText="Delete"
            confirmBtnCssClass="btn btn-danger"
            confirmBtnStyle={{
              border: "none",
              boxShadow: "none"
            }}
            closeOnClickOutside
            allowEscape={false}
            showCancel
            onCancel={() => setShowDeleteConfirmation(false)}
            onConfirm={onDeleteFile}>
            <i className="bi bi-file-earmark mr-2"></i>
            {file.data.attributes.fileName}
          </SweetAlert>
        </td>
      )}
    </tr>
  )
}
