import normalize from "json-api-normalizer";
import customAxios from "./customAxios";

export const getUsers = (baseUrl, filters) => {
  const path = `${baseUrl}/users`;

  return customAxios.get(path, { params: filters })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
};
export const getSurveyors = baseUrl => {
  const path = `${baseUrl}/users/surveyors`;

  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }));
};
export const getSurveyorsUsers = baseUrl => {
  const path = `${baseUrl}/users/surveyors_users`;

  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }));
};
export const getDraftmen = baseUrl => {
  const path = `${baseUrl}/users/draftmen`;

  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }));
};

export const getDraftsmenOverseas = baseUrl => {
  const path = `${baseUrl}/users/draftsmen-overseas`;

  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }));
};

export const getRegisterdSurveyors = (baseUrl, workflow = null) => {
  const path = `${baseUrl}/users/registered-surveyors`;

  return customAxios.get(path, { params: { workflow } })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }));
};

export const getUser = (baseUrl, userId) => {
  const path = `${baseUrl}/users/${userId}`;

  return customAxios.get(path)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const getUserNotifcations = (baseUrl, pageNumber, filters) => {
  const path = `${baseUrl}/notifications?page=${pageNumber}`;

  return customAxios.get(path, { params: filters })
  .then(({ data, headers, status }) => ({ response: data, headers, status }))
    .catch(error => ({ error }));
};

export const updateUserNotification = (baseUrl, id) => {
  const path = `${baseUrl}/notifications/${id}/read`;

  return customAxios.patch(path)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const markNotificationsRead = (baseUrl, notificationIds) => {
  const path = `${baseUrl}/notifications/mark_notifications_read`;

  return customAxios.patch(path, { notification_ids: notificationIds })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const getScopedUserNotifcations = baseUrl => {
  const path = `${baseUrl}/notifications/scoped_notification`;

  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }));
};

export const putUser = (baseUrl, userId, userData) => {
  const path = `${baseUrl}/users/${userId}`;

  return customAxios.put(path, { user: userData })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const updateMyDetails = (baseUrl, { user }) => {
  const path = `${baseUrl}/users/my_details`;

  return customAxios.put(path, { user })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const updateMyPassword = (baseUrl, data) => {
  const path = `${baseUrl}/users/password`;

  return customAxios.put(path, data)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const archiveUser = (baseUrl, userId, archiveState) => {
  const path = `${baseUrl}/users/${userId}/archive`;

  return customAxios.put(path, { archive: archiveState })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const discardTeamUser = (baseUrl, userId, userTeamId) => {
  const path = `${baseUrl}/users/${userId}/team_users/${userTeamId}/team_user_discard`;
  return customAxios.put(path)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const deleteTeamUser = (baseUrl, userId, userTeamId) => {
  const path = `${baseUrl}/users/${userId}/team_users/${userTeamId}`;

  return customAxios.delete(path)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const createTeamUser = (baseUrl, userId, teamUserData) => {
  const path = `${baseUrl}/users/${userId}/team_users`;
  return customAxios.put(path, { team_user: teamUserData })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const setDefaultTeamUser = (baseUrl, userId, userTeamId) => {
  const path = `${baseUrl}/users/${userId}/team_users/${userTeamId}/set_default`;

  return customAxios.put(path)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const uploadUserSignature = (baseUrl, id, formData) => {
  const path = `${baseUrl}/users/${id}/signature`;
  return customAxios.put(path, formData)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const uploadUserLatLong = (baseUrl, id, params) => {
  const path = `${baseUrl}/users/${id}/lat_long`;
  return customAxios.put(path, params)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }));
};

export const getUserBlockOutList = (baseUrl, id) => {
  const path = `${baseUrl}/users/${id}/block_outs`;
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
}

export const resendInvitation = (baseUrl, id) => {
  const path = `${baseUrl}/users/${id}/invitation`

  return customAxios.post(path, id)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }));
}

export const createAssetsUser = (baseUrl, params) => {
  const path = `${baseUrl}/users/create-assets-user`

  return customAxios.post(path, params)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
}

export const assignSurveyorMember = (baseUrl, userId, assignedToId) => {
  const path = `${baseUrl}/users/${userId}/surveyor_members`

  return customAxios.post(path, { user_id: userId, assigned_to_id: assignedToId })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }));
}

export const getAssignedSurveyorMembers = (baseUrl, userId) => {
  const path = `${baseUrl}/users/${userId}/surveyor_members`

  return customAxios.get(path, { params: { user_id: userId } })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }));
}

export const removeSurveyorMember = (baseUrl, userId, assignedToId) => {
  const path = `${baseUrl}/users/${userId}/surveyor_members`

  return customAxios.delete(path, { params: { user_id: userId, assigned_to_id: assignedToId } })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }))
}
