import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert"
import { useSelector } from "react-redux"
import { getBaseUrl } from "../../../reducers";
import { markUploadedFilesAsType } from "../../../../../requesters/jobServiceRequester";
import { MARK_AS_TYPES } from "../../../../../utils/constants";

const MarkFileAsUsedPlansForCalc = ({
  file, jobService, isUsedPlans, reloadData
}) => {
  const {
    data: { id, attributes: { fileName } }
  } = file
  const baseUrl = useSelector(getBaseUrl)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [requesting, setRequesting] = useState(false)

  const onSaveUsedPlansState = () => {
    if (requesting) return;

    const newType = isUsedPlans ? MARK_AS_TYPES.not_set : MARK_AS_TYPES.plans_used_for_calc
    setRequesting(true)
    markUploadedFilesAsType(baseUrl, jobService.id, [id], newType)
    .then(res => {
      if (res.status === 200) {
        setRequesting(false)
        setShowConfirmation(false)
        reloadData()
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }

  const confirmationTitle = isUsedPlans ? "Are you sure you want to Un-set it as used plan?" : "Are you sure you want to Set it as used plan?"

  return (
    <React.Fragment>
      <button
        type="button"
        disabled={requesting}
        className="px-2 cursor-pointer btn btn-link"
        onClick={() => setShowConfirmation(true)}>
        <i className={`bi bi-clipboard2${isUsedPlans ? "-check-fill" : ""} text-green`}></i>
      </button>
      <SweetAlert
        show={showConfirmation}
        type="custom"
        customClass="text-dark"
        title={confirmationTitle}
        style={{ width: "600px" }}
        confirmBtnText="Save"
        confirmBtnCssClass="btn btn-primary"
        confirmBtnStyle={{ border: "none", boxShadow: "none" }}
        closeOnClickOutside
        allowEscape={false}
        showCancel
        onCancel={() => setShowConfirmation(false)}
        onConfirm={onSaveUsedPlansState}>
        <i className="bi bi-file-earmark mr-2"></i>
        {fileName}
      </SweetAlert>
    </React.Fragment>
  )
}

export default MarkFileAsUsedPlansForCalc