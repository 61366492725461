// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
require("@rails/activestorage").start()
// require("trix")
// require("@rails/actiontext")
require("channels")

import axios from "axios"
import './bootstrap_custom.js'
import '../stylesheets/application'
import * as Sentry from '@sentry/browser';
import boot from "../lib/boot";
import Promise from "bluebird"

$(function() {
  Sentry.init(gon.env_vars.sentry);
  Sentry.configureScope(scope => {
    scope.setUser(gon.current_user);
  });
  configureAxios(axios)
  configurePromise()

  boot.init()
});

// Axios Configuration
const configureAxios = (axios) => {
  axios.defaults.headers.common["X-CSRF-Token"] = document.getElementsByName("csrf-token")[0].getAttribute("content")
  axios.defaults.headers.common["Accept"] = "application/json"
  axios.defaults.headers.common["Content-Type"] = "application/json"
  if (_.has(gon, "auth_token")) {
    axios.defaults.headers.common["Authorization"] = _.get(gon, "auth_token")
  }
}

// Bluebird Configuration
const configurePromise = () => {
  Promise.config({
    // Enable warnings
    warnings: true,
    // Enable long stack traces
    longStackTraces: false,
    // Enable cancellation
    cancellation: true,
    // Enable monitoring
    monitoring: true,
    // Enable async hooks
    asyncHooks: true,
  });
}