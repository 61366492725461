import cx from "classnames"
import StatusTableDropdown from "components/StatusTableDropdown"
import $ from "jquery"
import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import {
  ACCOUNTS_MANAGER,
  INVOICE_STATES,
  ON_HOLD,
  CANCELLED,
  SURVEYORS_MANAGER,
  CONFIRMED,
  CLIENT_DELAYED,
  GPS_AHD,
  GPS_MGA
} from "utils/constants"
import { roleCheck } from "utils/index"
import { useQueryParam, StringParam } from "use-query-params"
import iconAlert from "../../../../../assets/images/icons/icn_alert.svg"
import { getBaseUrl, getCurrentUser } from "../../reducers"
import JobServiceSchedulesTableNote from "./JobServiceSchedulesTableNote"
import JobServiceTableBookingTime from "./JobServiceTableBookingTime"
import JobServiceETA from "./JobServiceETA"
import SelectSurveyorButton from "./SelectSurveyorButton"

export default function TableRow({
  rowData, reloadData, bySection, readOnly
}) {
  const baseUrl = useSelector(getBaseUrl)
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role
  const [showMessage, setShowMessage] = useState(false)
  const [alertType, setAlertType] = useState("error")
  const [alertTitle, setAlertTitle] = useState("")
  const isSurveyorManager = roleCheck(systemRole, currentTeamRole, SURVEYORS_MANAGER)
  const isAccountsManager = roleCheck(systemRole, currentTeamRole, ACCOUNTS_MANAGER)
  const [targetId, setTargetId] = useQueryParam("target_id", StringParam)

  const {
    attributes: {
      onsite_hours_longer_than_eta: onsiteHoursLongerThanETA,
      surveyor_id: surveyorId,
      scheduled_at: bookingTime,
      surveyor_booking_valid: surveyorBookingValid,
      job_service: {
        data: {
          id,
          attributes: {
            is50_50_account: is50_50Account,
            calculated_total_cost: calculatedTotalCost, // Use for display cost only
            total_cost: totalCost, // Use for invoiceable cost
            label,
            invoice_state: invoiceState,
            client_name: clientName,
            drafted_primary_surveyor_id: draftedPrimarySurveyorId,
            rescheduled_from_id: rescheduledFromId,
            job_address: jobAddress,
            job_id: jobId,
            job_no: jobNo,
            for_project_x: forProjectX,
            project_x_job_no: projectXJobNo,
            job_notes: jobNotes,
            state,
            operations_notes: operationsNotes,
            pay_before_drafting: payBeforeDrafting,
            collect_payment_onsite_requested: collectPaymentOnsiteRequested,
            job_pack_uploaded: jobPackUploaded,
            checked_in_at: checkedInAt,
            estimated_hours: estimatedHours,
            drafted_primary_surveyor_booking_valid: draftedPrimarySurveyorBookingValid,
            disabled_change_on_schedule: disabledChangeOnSchedule,
            booking_dates: bookingDates,
            confirmation_status: confirmationStatus,
            code,
            awaiting_documents: awaitingDocuments,
          }
        }
      }
    },
    id: assignmentId
  } = rowData
  const displayJobNo = forProjectX ? projectXJobNo : jobNo

  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip()
    return () => {
      $('[data-toggle="tooltip"]').tooltip("dispose")
    };
  }, []);

  // change if the time needs to be update back from the Schedules page again
  const jobService = {
    id,
    attributes: {
      id,
      bookingDates: bookingDates.length > 0 ? bookingDates : [bookingTime],
      jobNotes,
      state,
      operationsNotes,
      jobPackUploaded,
      disabledChangeOnSchedule,
      jobAddress,
      estimatedHours,
      confirmationStatus
    }
  }

  const getDisplayInvoiceState = () => {
    const isDraftedInvoice = ["deposit_invoice_drafted", "final_invoice_drafted"].includes(invoiceState)

    if (isDraftedInvoice && payBeforeDrafting) return "pay_before_drafting";
    if (isDraftedInvoice && collectPaymentOnsiteRequested) return "collect_payment_onsite_requested";

    return invoiceState;
  }

  const displayInvoiceState = getDisplayInvoiceState()
  const invoiceStateColor = INVOICE_STATES[displayInvoiceState]?.bgClass
  const invoiceStateText = INVOICE_STATES[displayInvoiceState]?.text

  const onChangeSurveyorFailed = () => {
    setAlertTitle("Ooops! Something went wrong")
    setAlertType("error")
    setShowMessage(true)
  }

  function checkSearchesRequired() {
    const isGpsAhd = (code === GPS_AHD)
    const isGpsMga = (code === GPS_MGA)
    if (isGpsAhd || isGpsMga || jobPackUploaded) {
      return false
    }
    if (is50_50Account && !(payBeforeDrafting || collectPaymentOnsiteRequested || ["paid_deposit", "paid_full"].includes(invoiceState) || Number(totalCost) === 0)) {
      return false
    }
    return true
  }

  const showInvoiceStatus = is50_50Account && Number(totalCost) !== 0
  const showIconSearchesRequired = checkSearchesRequired()

  const checkInOverDue = () => {
    if (checkedInAt || state !== CONFIRMED) return false

    return (new Date() - new Date(bookingTime)) / (1000 * 60 * 60) > 2
  }

  const elementClasses = () => {
    const classes = []

    if ([CANCELLED, ON_HOLD, CLIENT_DELAYED].includes(state)) classes.push("greyout")
    else if (checkInOverDue()) classes.push("highlight")
    return classes
  }

  const displayJobTitle = jobAddress?.split("NSW")[0]

  return (
    <tr
      id={assignmentId}
      style={Number(assignmentId) === Number(targetId) ? { backgroundColor: "rgba(0, 0, 0, 0.075)" } : {}}
      className={elementClasses().join(" ")}
      {...(showIconSearchesRequired ? { "data-toggle": "tooltip", title: "Searches Required" } : {})}>
      <td>
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{displayJobNo}</Link>
      </td>
      <td>
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{clientName}</Link>
      </td>
      <td className="medium-width">
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{displayJobTitle}</Link>
      </td>
      <td className={`${awaitingDocuments ? "bg-coral border-right border-white" : ""}`}>
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{label}</Link>
      </td>

      {!readOnly && (
        <td className={`${rescheduledFromId ? "bg-coral border-right border-white" : ""} text-right`}>
          <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{`$${calculatedTotalCost}`}</Link>
        </td>
      )}

      <StatusTableDropdown
        jobService={jobService}
        reloadData={reloadData}
        readOnly={readOnly}
        surveyor={surveyorId || draftedPrimarySurveyorId} />

      {(isSurveyorManager || isAccountsManager || !readOnly) && (
        <td className={cx(`dropdown text-center ${showInvoiceStatus ? invoiceStateColor : null} border-left border-white w-100px`)}>
          {showInvoiceStatus && (
            <button
              className="btn dropdown-toggle"
              type="button">
              {invoiceStateText}
            </button>
          )}
        </td>
      )}
      <td className={`dropdown pick-surveyor${!surveyorBookingValid || !draftedPrimarySurveyorBookingValid ? " bg-coral" : " bg-light"}`}>
        <SelectSurveyorButton
          disabled={readOnly || disabledChangeOnSchedule}
          btnClassName="btn-block"
          setTargetId={setTargetId}
          reloadData={reloadData}
          onChangeSurveyorFailed={onChangeSurveyorFailed}
          rowData={rowData} />
      </td>
      <JobServiceTableBookingTime
        jobService={jobService}
        reloadData={() => {
          setTargetId(assignmentId)
          reloadData()
        }}
        readOnly={readOnly}
        bySection={bySection}
        bookingTime={bookingTime} />
      <td className={`${onsiteHoursLongerThanETA ? "bg-coral" : ""}`}>
        <JobServiceETA
          jobService={jobService}
          reloadData={() => {
            setTargetId(assignmentId)
            reloadData()
          }}
          readOnly={readOnly} />
      </td>
      <JobServiceSchedulesTableNote
        rowData={rowData}
        reloadData={() => {
          setTargetId(assignmentId)
          reloadData()
        }}
        readOnly={readOnly} />
      <td className="keep"><Link to={`/jobs/${jobId}/job_services/${id}`}>Details</Link></td>
      <td>{showIconSearchesRequired && (<img src={iconAlert} alt="Searches Required" />)}</td>
      <SweetAlert
        show={showMessage}
        type={alertType}
        title={alertTitle}
        closeOnClickOutside
        allowEscape={false}
        onConfirm={() => setShowMessage(false)} />
    </tr>
  )
}
