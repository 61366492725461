import React, { useState } from "react"
import { useSelector } from "react-redux"
import { notepadIcon, notepadIconDark } from "components/Icons"
import {
  RECEPTION_MANAGER,
  stringEllipsis
} from "utils/constants"
import EditNoteModal  from "./EditNoteModal"
import { getCurrentUser } from "../../reducers"

export default function JobServiceTableNote({ rowData, reloadData, readOnly }) {
  const [showNotesModal, setShowNotesModal] = useState(false)
  const { attributes: { jobNotes } } = rowData

  const currentUser = useSelector(getCurrentUser)
  const currentTeamRole = currentUser.attributes.current_team_role
  const receptionTeam = currentTeamRole.includes(RECEPTION_MANAGER)

  return (
    <td>
      <div className="dropdown">
        <img
          src={jobNotes ? notepadIconDark : notepadIcon}
          className="cursor-pointer"
          data-toggle="dropdown"
          id="dropdownMenuLink" />
        <div className="dropdown-menu dropdown-menu-right p-3 note-dropdown">
          {!receptionTeam && !readOnly && (
            <div className="text-right">
              <button
                type="button"
                className="btn btn-link p-0"
                onClick={() => { setShowNotesModal(true) }}>
                {`${jobNotes ? "Edit" : "Add Notes"}`}
              </button>
            </div>
          )}
          <p className="mb-0">
            {stringEllipsis(jobNotes, 60, 60)}
            {" "}
            {jobNotes?.length > 60 && (
              <button
                type="button"
                onClick={() => { setShowNotesModal(true) }}
                className="btn btn-link p-0 ml-2">
                More
              </button>
            )}
          </p>
        </div>
        <EditNoteModal
          readOnly={receptionTeam || readOnly}
          show={showNotesModal}
          title={jobNotes ? "Edit Notes" : "Add Notes"}
          onHide={() => { setShowNotesModal(false) }}
          jobService={rowData}
          reloadData={reloadData} />
      </div>
    </td>
  )
}