import { Quill } from 'react-quill';

const Clipboard = Quill.import('modules/clipboard');
const Delta = Quill.import('delta');

class CustomClipboard extends Clipboard {
  onPaste(e) {
    e.preventDefault();
    
    const range = this.quill.getSelection();
    const html = this.getClipboardHtml(e);

    if (html) {
      const cleanedHtml = this.cleanClipboardHtml(html);
      const delta = this.convert(cleanedHtml);
      this.insertDelta(range, delta);
    }
  }

  getClipboardHtml(e) {
    const clipboardData = e.clipboardData || window.clipboardData;
    return clipboardData.getData('text/html') || clipboardData.getData('text/plain');
  }

  cleanClipboardHtml(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    this.removePreTags(doc);
    this.removeBackgroundColor(doc);
    return doc.body.innerHTML;
  }

  removePreTags(doc) {
    doc.querySelectorAll('pre').forEach(pre => {
      const textContent = pre.textContent;
      const span = document.createElement('span');
      span.textContent = textContent;
      span.style.whiteSpace = 'pre-wrap';
      pre.parentNode.replaceChild(span, pre);
    });
  }

  removeBackgroundColor(doc) {
    doc.querySelectorAll('*').forEach(el => {
      const style = el.getAttribute('style');
      if (style) {
        const cleanedStyle = style
          .split(';')
          .filter(rule => !rule.trim().startsWith('background-color'))
          .join(';');
  
        if (cleanedStyle.trim()) {
          el.setAttribute('style', cleanedStyle);
        } else {
          el.removeAttribute('style');
        }
      }
    });
  }

  insertDelta(range, delta) {
    this.quill.updateContents(new Delta().retain(range.index).delete(range.length).concat(delta), 'user');
    this.quill.setSelection(range.index + delta.length(), 0);
    this.quill.scrollIntoView();
  }
}

Quill.register('modules/clipboard', CustomClipboard, true);
