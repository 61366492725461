import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { createGlobalStyle } from "styled-components";

const DatePickerWrapperStyles = createGlobalStyle`
.date_picker.full-width {
    width: 100%;
}
`;

export default function UpdateDocketTimeInOutModal({
  show, setShow, onUpdateTimeInOut, timeIn, timeOut, loading
}) {
  const initTime = (currenTime, secondTime) => {
    if (currenTime) {
      return new Date(currenTime)
    }
    if (secondTime) {
      return new Date(secondTime)
    }
    return new Date()
  }
  const [currentTimeIn, setCurrentTimeIn] = useState(initTime(timeIn, timeOut))
  const [currentTimeOut, setCurrentTimeOut] = useState(initTime(timeOut, timeIn))


  useEffect(() => {
    if (show !== true) {
      setCurrentTimeIn(initTime(timeIn, timeOut))
      setCurrentTimeOut(initTime(timeOut, timeIn))
    }
  }, [show])


  useEffect(() => {
    if (timeIn) {
      setCurrentTimeIn(new Date(timeIn))
    }
    if (timeOut) {
      setCurrentTimeOut(new Date(timeOut))
    }
  }, [timeIn, timeOut])
  return (
    <Modal show={show} onHide={setShow} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title><h4 className="modal-title">Update Time In/Out</h4></Modal.Title>
      </Modal.Header>
      <div className="modal-body">
        <form>
          <div className="form-group row">
            <label className="col-4 col-form-label">Time In</label>
            <div className="col-4">
              <div className="d-block">
                <DatePicker
                  wrapperClassName="date_picker full-width"
                  selected={currentTimeIn}
                  onChange={e => {
                    setCurrentTimeIn(e)
                  }}
                  timeFormat="HH:mm a"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  closeOnScroll={e => e.target === document}
                  className="form-control"
                  dateFormat="HH:mm a" />
                <DatePickerWrapperStyles />
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-4 col-form-label">Time Out</label>
            <div className="col-4">
              <div className="d-block">
                <DatePicker
                  wrapperClassName="date_picker full-width"
                  selected={currentTimeOut}
                  onChange={e => {
                    setCurrentTimeOut(e)
                  }}
                  timeFormat="HH:mm a"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  closeOnScroll={e => e.target === document}
                  className="form-control"
                  dateFormat="HH:mm a" />
                <DatePickerWrapperStyles />
              </div>
            </div>
          </div>
        </form>
        <div className="modal-footer d-flex justify-content-between p-0 mb-4 mt-5">
          <div>
            <button
              disabled={loading}
              className="btn btn-red btn-lg mr-2"
              onClick={() => {
                onUpdateTimeInOut(currentTimeIn, currentTimeOut)
                setShow(false)
              }}
              type="submit">
              Save
              {loading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
