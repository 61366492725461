import React, { useEffect, useMemo, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { DeepLinker } from "../utils";

const OpenInAppButton = ({
  classNames, btnText, appDeepLink, onFallback, onIgnored, onReturn, customBehavior
}) => {
  const [openFailed, setOpenFailed] = useState(false)
  const previousLinker = useRef();

  const linker = useMemo(() => {
    if (previousLinker.current) {
      previousLinker.current.destroy(); // remove event listeners
    }

    const newLinker = new DeepLinker({
      onIgnored: () => { // Browser failed to respond to the deep link
        if (customBehavior && onIgnored) {
          onIgnored();
        } else {
          setOpenFailed(true)
        }
      },
      onFallback: () => { // Dialog hidden or user returned to tab
        if (customBehavior && onFallback) {
          onFallback()
        } else {
          setOpenFailed(true)
        }
      },
      onReturn: () => { // User returned to the page from the native app
        if (customBehavior && onReturn) {
          onReturn()
        } else {
          setOpenFailed(false)
        }
      }
    })
    previousLinker.current = newLinker;

    return newLinker
  }, [])

  useEffect(() => () => { // Unmount func
    if (previousLinker.current) {
      previousLinker.current.destroy();
    }
  }, []);

  const onOpen = e => {
    e.preventDefault();

    linker.openURL(appDeepLink)
  };

  return (
    <React.Fragment>
      <button type="button" onClick={onOpen} className={`btn ${classNames}`}>
        {btnText}
      </button>

      <SweetAlert
        show={!customBehavior && openFailed}
        customClass="hide-on-desktop"
        type="error"
        title="Can't open the app!"
        style={{ width: "600px" }}
        closeOnClickOutside
        allowEscape={false}
        onConfirm={() => setOpenFailed(false)}>
        App Unavailable, Contact Operations
      </SweetAlert>
    </React.Fragment>
  )
}

export default OpenInAppButton