import normalize from "json-api-normalizer"
import customAxios from "./customAxios";

export const getTimeSpents = (baseUrl, trackableId, trackableType, page = undefined, perPage = undefined) => {
  const path = `${baseUrl}/time_spents`
  const payload = {
    trackable_id: trackableId,
    trackable_type: trackableType,
    page,
    per_page: perPage
  }

  return customAxios.get(path, { params: payload })
    .then(({ data, status }) => ({ response: normalize(data, { endpoint: "additional_info" }), status }))
    .catch(error => ({ error }))
}

export const getTimeSpent = (baseUrl, id) => {
  const path = `${baseUrl}/time_spents/${id}`

  return customAxios.get(path)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const addTimeSpent = (baseUrl, params) => {
  const path = `${baseUrl}/time_spents`

  return customAxios.post(path, { ...params })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const adjustTimeSpent = (baseUrl, id, params) => {
  const path = `${baseUrl}/time_spents/${id}`

  return customAxios.put(path, params)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const deleteTimeSpent = (baseUrl, id) => {
  const path = `${baseUrl}/time_spents/${id}`;

  return customAxios
    .delete(path)
    .then(({ status }) => ({ status }))
    .catch((error) => ({ error }));
};
