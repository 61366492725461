import React, { useState } from "react";
import AddTimeSpentModal from "./modal/AddTimeSpentModal";

const AddTimeSpent = ({
  buttonText,
  reloadData,
  trackableId,
  trackableType,
  trackedOnState,
  trackedForDivision
}) => {
  const [showingModal, setShowingModal] = useState(false)

  return (
    <React.Fragment>
      <button className="btn btn-outline-primary" type="button" onClick={() => setShowingModal(true)}>{buttonText}</button>
      <AddTimeSpentModal
        show={showingModal}
        onHide={() => setShowingModal(false)}
        reloadData={reloadData}
        trackableId={trackableId}
        trackableType={trackableType}
        trackedOnState={trackedOnState}
        trackedForDivision={trackedForDivision} />
    </React.Fragment>
  )
}

export default AddTimeSpent