import React from "react";
import { useSelector } from "react-redux";
import { getBaseUrl } from "../../../reducers";
import { transitionTaskTo } from "../../../../../requesters/taskRequester";

const TransitionTaskButton = ({
  taskId, transitionTo, reloadData, btnClassNames, btnText
}) => {
  const baseUrl = useSelector(getBaseUrl);

  const onTransition = () => {
    transitionTaskTo(baseUrl, taskId, transitionTo)
    .then(res => {
      if (res.status === 200) {
        reloadData()
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }

  return (
    <button
      type="button"
      className={btnClassNames}
      onClick={onTransition}>
      {btnText}
    </button>
  )
}

export default TransitionTaskButton