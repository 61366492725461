import React from "react"

export default function EditSaveButtons({
  editState, createState, startEdit, save, cancel, disableEdit
 }) {
   if (editState) {
     return (
       <>
         <button
           className="btn btn-red"
           type="button"
           disabled={disableEdit}
           onClick={save}>
           Save
         </button>
         <button
           className="btn btn-light ml-2"
           type="button"
           onClick={cancel}>
           Cancel
         </button>
       </>
     )
   }
   if (createState) {
       return (
         <>
           <button
             className="btn btn-red"
             type="button"
             onClick={save}>
             Save
           </button>
         </>
       )
   }
   return <button className="btn btn-light" type="button" onClick={startEdit}>Edit</button>
 }