import { arrowDownIcon } from "components/Icons";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Lightbox from "react-awesome-lightbox";
import { Accordion, Card, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  downloadAllPhotos,
  downloadAllSelectedFiles,
  generateJobServiceRsReport, getJobServicesRsReports,
  prepareReadyForReleaseFiles, zipPhotos
} from "requesters/jobServiceRequester";
import { filesReadyForRelease } from "requesters/uploadsRequester";
import {
  ADMIN,
  DRAFTING,
  DRAFTING_3D,
  DRAFTING_3D_MANAGER,
  DRAFTING_MANAGER,
  DRAFTING_OVERSEAS,
  DRAFTING_OVERSEAS_LEBANON,
  DRAFTING_OVERSEAS_PHILIPPINES,
  DRAFTING_STATES,
  DRAFTING_USER,
  DRAFTING_OVERSEAS_USER,
  DRAFTING_OVERSEAS_MANAGER,
  FIELD_WORK_STATES,
  FILE_GROUPS,
  OPERATION_MANAGER,
  RECEPTION_MANAGER,
  RECEPTION_USER,
  REGISTERED_SURVEYORS,
  REGISTERED_SURVEYORS_MANAGER,
  REGISTERED_SURVEYORS_USER,
  SURVEYORS_MANAGER,
  TEAMS_MANAGERS,
  createFileOptions,
  PRODUCT_CODE_RS_CAN_VIEW_READY_TO_SEND
} from "utils/constants";
import { roleCheck } from "utils/index";
import { getBaseUrl, getCurrentRole, getCurrentUser } from "../../../reducers";
import AddFilesModal from "../modals/AddFilesModal";
import GenerateRsReportModal from "../modals/GenerateRsReportModal";
import FilePreview from "./FilePreview";
import FileRow from "./FileRow";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import ReleasedFilesConfirmationModal from "../modals/ReleasedFilesConfirmationModal";


const Files = ({
  jobService,
  reloadData
}) => {
  const [showUploadFilesModal, setShowUploadFilesModal] = useState(false)
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role
  const currentTeams = useSelector(getCurrentRole)
  const hasDraftingUser = currentTeamRole.some(role => /drafting.*user/.test(role))
  const isManagerRole = TEAMS_MANAGERS.map(team => (roleCheck(systemRole, currentTeamRole, team)))
  const [showRsReportModal, setShowRsReportModal] = useState(false)
  const baseUrl = useSelector(getBaseUrl)
  const [draftRsReports, setDraftRsReports] = useState([])
  const [currentReport, setCurrentReport] = useState({})
  const [fileOptions, setFileOptions] = useState([])
  // const [showImportJobPacksModal, setShowImportJobPacksModal] = useState(false)
  const [showPopupGallery, setShowPopupGallery] = useState(false)
  const [showAllPhotos, setShowAllPhotos] = useState(false)
  const [galleryStartIndex, setGalleryStartIndex] = useState(0)
  // const [hasBeenConfirmed, setHasBeenConfirmed] = useState(false)
  const [showReleaseFilesModal, setShowReleaseFilesModal] = useState(false)
  const [releaseFilesModalConfirmed, setReleaseFilesModalConfirmed] = useState(false)
  const [zipPhotosClicked, setZipPhotosClicked] = useState(false)
  const [downloadAllButtonClicked, setDownloadAllButtonClicked] = useState(false)
  const [showUploadedFilesSection, setShowUploadedFilesSection] = useState(false)
  const [uploadedFilesSectionSize, setUploadedFilesSectionSize] = useState("normal")
  const [currentFileGroup, setCurrentFileGroup] = useState("")

  const {
    attributes: {
      state,
      registeredSurveyorId,
      registeredSurveyorStatesOnwards,
      rsDraftReports,
      draftingFiles,
      flagFiles,
      jobInstructions,
      plansUsedForCalcFiles,
      photos,
      registeredSurveyorFiles,
      releasedFiles,
      searches,
      surveyorFiles,
      is3D,
      isBookInCheckSurvey,
      discardedFiles,
      hasImportableJobPacks,
      jobPackUploaded,
      assignedSurveyorIds,
      readyForReleaseFiles,
      outstandingInvoiceFlagged,
      code
    }, id
  } = jobService

  const usedPlans = plansUsedForCalcFiles.map(file => file?.data?.id)
  const toastOptions = {
    toastId: "container-inner",
    containerId: "main"
  }
  const isOperationsReceptionOrAdmin = systemRole === ADMIN || roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) || roleCheck(systemRole, currentTeamRole, RECEPTION_MANAGER)
  const isRegisteredSurveyorManager = roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)
  const isReception = currentTeamRole.includes(RECEPTION_MANAGER) || currentTeamRole.includes(RECEPTION_USER)
  const rsReportsViewAllowed = roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)
    || Number(currentUser.id) === registeredSurveyorId
    || roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER)
    || roleCheck(systemRole, currentTeamRole, RECEPTION_MANAGER)
    || roleCheck(systemRole, currentTeamRole, DRAFTING_USER)
    || roleCheck(systemRole, currentTeamRole, DRAFTING_MANAGER)
    || roleCheck(systemRole, currentTeamRole, DRAFTING_OVERSEAS_MANAGER)
    || roleCheck(systemRole, currentTeamRole, DRAFTING_OVERSEAS_USER)
    || roleCheck(systemRole, currentTeamRole, DRAFTING_3D_MANAGER)

  const canSupersedeRsFiles = roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)
    || Number(currentUser.id) === registeredSurveyorId
    || roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER)
    || roleCheck(systemRole, currentTeamRole, RECEPTION_MANAGER)

  const rsFilesCreateEditAllowed = roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)
    || Number(currentUser.id) === registeredSurveyorId

  const rsReportsCreateEditAllowed = roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)
    || Number(currentUser.id) === registeredSurveyorId
    || roleCheck(systemRole, currentTeamRole, DRAFTING_USER)
    || roleCheck(systemRole, currentTeamRole, DRAFTING_MANAGER)

  const rsReportsGenerateAllowed = roleCheck(systemRole, currentTeamRole, ADMIN)
    || roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)
    || roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_USER)

  const canAddDraftingFiles = systemRole === ADMIN
    || currentTeams.includes(DRAFTING_3D)
    || currentTeams.includes(DRAFTING_OVERSEAS)
    || currentTeams.includes(DRAFTING_OVERSEAS_LEBANON)
    || currentTeams.includes(DRAFTING_OVERSEAS_PHILIPPINES)
    || currentTeams.includes(REGISTERED_SURVEYORS)
    || currentTeams.includes(DRAFTING)

  const canAddQuoteAcceptanceFiles = ["admin", "accounts", "operations"].some(team => currentTeams.includes(team))

  const isRegisteredSurveyor = currentTeams.includes(REGISTERED_SURVEYORS)

  const canAddPhotos = roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER)
    || roleCheck(systemRole, currentTeamRole, SURVEYORS_MANAGER)
    || assignedSurveyorIds.includes(Number(currentUser.id))

  const localDrafting = currentTeams.includes(DRAFTING) || currentTeams.includes(DRAFTING_3D)
  const canAddSurveyorFiles = roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER)
    || assignedSurveyorIds.includes(Number(currentUser.id))
    || localDrafting
    || roleCheck(systemRole, currentTeamRole, SURVEYORS_MANAGER)
    || isRegisteredSurveyor

  const images = photos.map(photo => ({
    url: photo.data.attributes.filePath,
    title: photo.data.attributes.fileName
  }));

  const [allPhotos, setAllPhotos] = useState(photos.slice(0, 10))
  const [filteredDraftingFiles, setFilteredDraftingFiles] = useState([])

  const allowedJobInstructionsOptions = canAddQuoteAcceptanceFiles ? FILE_GROUPS.job_instructions
    : FILE_GROUPS.job_instructions.filter(o => o !== "Quote Acceptance")
  const filteredJobInstructionsFiles = canAddQuoteAcceptanceFiles ? jobInstructions
    : jobInstructions.filter(file => file.data?.attributes?.documentCategory !== "Quote Acceptance")

  const sortedAndFilteredJobInstructionsFiles = filteredJobInstructionsFiles.sort((a, b) => usedPlans.includes(b?.data?.id) - usedPlans.includes(a?.data?.id));

  useEffect(() => {
    if (showAllPhotos) {
      setAllPhotos(photos)
    } else {
      setAllPhotos(photos.slice(0, 10))
    }
  }, [photos])

  useEffect(() => {
    if (isRegisteredSurveyor) {
      setFilteredDraftingFiles(draftingFiles.filter(file => !file.data.attributes.superseded))
    } else if (hasDraftingUser) {
      setFilteredDraftingFiles(draftingFiles.filter(file => file.data.attributes.documentCategory === "Drafting Markup" && file.data.attributes.superseded === false))
    } else {
      setFilteredDraftingFiles(draftingFiles)
    }
  }, [draftingFiles]);

  const photoHandler = () => {
    if (allPhotos.length <= 10) {
      setShowAllPhotos(true)
      setAllPhotos(photos)
    } else {
      setShowAllPhotos(false)
      setAllPhotos(photos.slice(0, 10))
    }
  }

  const isOwnerFile = file => {
    if (Number(currentUser.id) === file?.data?.attributes?.createdByUserId) {
      return true
    }
    return false
  }

  const canUpdate = (teamManager, file) => {
    if (roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER)) {
      return true
    }
    if (roleCheck(systemRole, currentTeamRole, teamManager) || isOwnerFile(file)) {
      return true
    }
    return false
  }


  const fetchRsDraftReports = () => {
    getJobServicesRsReports(baseUrl, id)
      .then(res => {
        setDraftRsReports(Object.values(_.get(res.response.drafts, "registeredSurveyorReport", {})))
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  const generateRsReportFile = reportId => {
    generateJobServiceRsReport(baseUrl, id, { report_id: reportId })
      .then(() => {
        setCurrentReport({})
        fetchRsDraftReports()
        reloadData()
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  useEffect(() => {
    fetchRsDraftReports()
  }, [])

  const generateFileOptions = fileGroup => {
    let categoryArray = FILE_GROUPS[fileGroup]
    const defaultTeam = currentUser.attributes?.default_team

    if (fileGroup === "surveyor_files" && defaultTeam === "drafting") {
      categoryArray = ["SCIMS"]
    }

    if (fileGroup === "job_instructions" && !canAddQuoteAcceptanceFiles) {
      _.remove(categoryArray, category => category === "Quote Acceptance");
    }

    if (isBookInCheckSurvey && ["registered_surveyor_files", "drafting_files"].includes(fileGroup)) {
      categoryArray = []
    }

    const options = createFileOptions(categoryArray)
    setFileOptions(options)
  }

  const handleAddFiles = fileGroup => {
    setCurrentFileGroup(fileGroup)
    generateFileOptions(fileGroup)
    const isShowUploadedFilesSection = fileGroup === "searches"
    const newSize = fileGroup === "searches" ? "full" : "normal"
    setShowUploadedFilesSection(isShowUploadedFilesSection)
    setUploadedFilesSectionSize(newSize)
    setShowUploadFilesModal(true)
  }

  const defaultCategory = () => {
    if (currentFileGroup === "photos") return "Photo";

    return null
  }

  const uploadedFilesSource = () => {
    switch (currentFileGroup) {
      case "searches":
        return searches;
      default:
        return []
    }
  }

  const releaseFilesHandler = () => {
    setReleaseFilesModalConfirmed(true)
    prepareReadyForReleaseFiles(baseUrl, jobService.id, true)
      .then(res => {
        if (res.status === 200) {
          toast.info(
            <div>
              <span><i className="fa fa-exclamation mr-2" /></span>
              <span className="ml-2">Releasing Files, please wait and refresh after a few minutes...</span>
            </div>,
            toastOptions
          )
          setShowReleaseFilesModal(false)
          reloadData()
        }
      })
      .catch(err => console.log(err))
  }

  const zipPhotosHandler = () => {
    setZipPhotosClicked(true)
    zipPhotos(baseUrl, jobService.id, true)
      .then(res => {
        if (res.status === 200) {
          toast.info(
            <div>
              <span><i className="fa fa-exclamation mr-2" /></span>
              <span className="ml-2">Zipping photos, please wait and refresh after a few minutes...</span>
            </div>,
            toastOptions
          )
          reloadData()
          setTimeout(() => {
            setZipPhotosClicked(false)
          }, 2500);
        }
      })
  }

  const downloadAllPhotosHandler = () => {
    setDownloadAllButtonClicked(true)
    downloadAllPhotos(baseUrl, jobService.id)
      .then(res => {
        if (res.status === 200) {
          // Create anchor tag and force download file
          const downloadLink = document.createElement("a");
          downloadLink.href = res.response.file_url;
          downloadLink.download = res.response.filename;
          downloadLink.click();
          downloadLink.remove()
          toast.info(
            <div>
              <span><i className="fa fa-exclamation mr-2"></i></span>
              <span className="ml-2">Downloading photos...</span>
            </div>,
            toastOptions
          )
          reloadData()
          setTimeout(() => {
            setDownloadAllButtonClicked(false)
          }, 2500);
        }
      })
  }

  const draftingFilesopen = DRAFTING_STATES.includes(state) || registeredSurveyorStatesOnwards.includes(state) ? "Drafting Files" : "0"
  const searchesOpen = FIELD_WORK_STATES.includes(state) || registeredSurveyorStatesOnwards.includes(state) ? "Searches" : "0"
  const surveyorFilesOpen = FIELD_WORK_STATES.includes(state) ? "Surveyor Files" : "0"
  const flagFilesOpen = flagFiles?.length ? "Flag Files" : "0"
  const registeredSurveyorFilesOpen = registeredSurveyorStatesOnwards.includes(state) ? "Registered Surveyor Files" : "0"
  const [selectedFileIds, setSelectedFileIds] = useState([])
  const [downloadAllLoading, setDownloadAllLoading] = useState(false)
  const filesReadyForReleaseHandler = () => {
    filesReadyForRelease(baseUrl, jobService.id, selectedFileIds)
      .then(res => {
        if (res.status === 200) {
          window.location.reload()
        }
      })
  }

  const downloadAllFilesHandler = () => {
    setDownloadAllLoading(true)
    downloadAllSelectedFiles(baseUrl, jobService.id, selectedFileIds)
      .then(res => {
        if (res.status === 200) {
          const downloadLink = document.createElement("a");
          downloadLink.href = res.response.file_url;
          downloadLink.download = res.response.filename;
          downloadLink.click();
          downloadLink.remove()
          toast.info(
            <div>
              <span><i className="fa fa-exclamation mr-2"></i></span>
              <span className="ml-2">Downloading All Selected Files...</span>
            </div>,
            toastOptions
          )
          setTimeout(() => {
            setDownloadAllLoading(false)
          }, 2000);
        } else {
          toast.info(
            <div>
              <span><i className="fa fa-exclamation mr-2"></i></span>
              <span className="ml-2">Something went wrong...</span>
            </div>,
            toastOptions
          )
          setDownloadAllLoading(false)
        }
      })
  }

  const onSelectAllFiles = checked => {
    if (checked) {
      const allFile = [...registeredSurveyorFiles, ...filteredDraftingFiles, ...flagFiles, ...surveyorFiles, ...searches, ...discardedFiles]
      const allFileIds = []
      allFile.forEach(file => {
        if (!file.data?.attributes?.superseded) {
          allFileIds.push(file.data.id)
        }
      })
      setSelectedFileIds(allFileIds)
    } else {
      setSelectedFileIds([])
    }
  }

  const getCheckedFiles = file => {
    if (!selectedFileIds.includes(file.data.id)) {
      setSelectedFileIds([...selectedFileIds, file.data.id])
    } else {
      setSelectedFileIds(selectedFileIds.filter(id => id !== file.data.id))
    }
  }

  // const handleImportJobPacks = () => {
  //   if (hasImportableJobPacks) {
  //     setHasBeenConfirmed(true)
  //     importJobPacks(baseUrl, jobService.id)
  //       .then(() => {
  //         setShowImportJobPacksModal(false);
  //         setHasBeenConfirmed(false)
  //         reloadData();
  //       });
  //   }
  // }

  // Boolean states to determine if Accordion Arrow should be facing up or down
  const [releasedFilesArrowUp, setReleasedFilesArrowUp] = useState(false)
  const [rsFilesArrowUp, setRsFilesArrowUp] = useState(registeredSurveyorFilesOpen !== "0")
  const [draftingFilesArrowUp, setDraftingFilesArrowUp] = useState(draftingFilesopen !== "0")
  const [flagFilesArrowUp, setFlagFilesArrowUp] = useState(flagFilesOpen !== "0")
  const [surveyorFilesArrowUp, setSurveyorFilesArrowUp] = useState(surveyorFilesOpen !== "0")
  const [photoFilesArrowUp, setPhotoFilesArrowUp] = useState(false)
  const [jobInstructionsArrowUp, setJobInstructionsArrowUp] = useState(true)
  const [searchesArrowUp, setSearchesArrowUp] = useState(searchesOpen !== "0")
  const [deletedFilesArrowUp, setDeletedFilesArrowUp] = useState(false)
  const [readyForReleaseFilesArrowUp, setReadyForReleaseFilesArrowUp] = useState(false)

  const arrowStyle = isUp => {
    if (isUp) {
      return { transform: "rotate(-180deg)", transition: "0.2s ease-in-out" }
    }
    return { transform: "none", transition: "0.2s ease-in-out" }
  }

  return (
    <>
      <div className="timeline-item mb-5">
        <div className="d-flex flex-row-reverse justify-content-between align-items-center">
          <div>
            {!isReception && (
              <button
                type="button"
                className="btn btn-outline-primary mb-3"
                onClick={downloadAllFilesHandler}
                disabled={selectedFileIds.length <= 0}>
                Download All Selected Files
                {downloadAllLoading && <Spinner className="ml-2" animation="border" role="status" size="sm" />}
              </button>
            )}
            {(isOperationsReceptionOrAdmin || (isRegisteredSurveyorManager && PRODUCT_CODE_RS_CAN_VIEW_READY_TO_SEND.includes(code))) && (
              <button
                type="button"
                className="btn btn-outline-primary mb-3 ml-3"
                onClick={filesReadyForReleaseHandler}
                disabled={selectedFileIds.length <= 0}>
                Prepare Selected Files For Release
              </button>
            )}
          </div>
          <div className="custom-control custom-checkbox">
            <input
              className="custom-control-input"
              id="check-all"
              type="checkbox"
              onChange={e => {
                onSelectAllFiles(e.target.checked)
              }} />
            <label className="custom-control-label" htmlFor="check-all">Select All</label>
          </div>

        </div>
        <Accordion defaultActiveKey="0" onSelect={() => (setReleasedFilesArrowUp(!releasedFilesArrowUp))}>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="Released Files" className="cursor-pointer">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="mb-0">
                  Released Files (
                  {releasedFiles.length}
                  )
                </h6>
                <img
                  src={arrowDownIcon}
                  className="img-fluid"
                  style={arrowStyle(releasedFilesArrowUp)} />
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="Released Files">
              <Card.Body>
                {roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) && (
                  <div className="text-right">
                    <button
                      className="btn btn-outline-secondary mr-2"
                      type="button"
                      onClick={() => handleAddFiles("released_files")}>
                      Add Files
                    </button>

                  </div>
                )}

                <div className="table-responsive">
                  <table className="table mt-4">
                    <tbody className="bg-white">
                      {releasedFiles.length > 0 ? releasedFiles.map(file => (
                        <FileRow
                          isDisableSendToClient={outstandingInvoiceFlagged}
                          fileGroup="Released Files"
                          getCheckedFiles={getCheckedFiles}
                          canUpdate={canUpdate(OPERATION_MANAGER, file)}
                          isOperationsReceptionOrAdmin={isOperationsReceptionOrAdmin}
                          file={file}
                          fileOptions={FILE_GROUPS.released_files}
                          key={file.data.id}
                          isManagerRole={isManagerRole}
                          reloadData={reloadData}
                          jobService={jobService}
                          selectedFileIds={selectedFileIds} />
                      )) : (
                        <tr>
                          <td>No File uploaded.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <Accordion defaultActiveKey="0" onSelect={() => (setReadyForReleaseFilesArrowUp(!readyForReleaseFilesArrowUp))}>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="Ready for Release" className="cursor-pointer">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="mb-0">
                  Ready for Release (
                  {readyForReleaseFiles.length}
                  )
                </h6>
                <img
                  src={arrowDownIcon}
                  className="img-fluid"
                  style={arrowStyle(readyForReleaseFilesArrowUp)} />
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="Ready for Release">
              <Card.Body>
                {(systemRole === ADMIN || roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) || roleCheck(systemRole, currentTeamRole, RECEPTION_MANAGER) || roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)) && (
                  <div className="text-right">
                    <button
                      className="btn btn-outline-secondary mr-2"
                      type="button"
                      onClick={() => setShowReleaseFilesModal(true)}
                      disabled={readyForReleaseFiles.length <= 0}>
                      Release Files
                    </button>
                    <button
                      className="btn btn-outline-secondary mr-2"
                      type="button"
                      disabled={zipPhotosClicked}
                      onClick={() => zipPhotosHandler()}>
                      {zipPhotosClicked ? <Spinner animation="border" role="status" size="sm" /> : "Zip Photos"}
                    </button>

                    <ReleasedFilesConfirmationModal
                      show={showReleaseFilesModal}
                      onHide={() => setShowReleaseFilesModal(false)}
                      modalConfirmed={releaseFilesModalConfirmed}
                      filesHandler={releaseFilesHandler}
                      message="Are you sure you want to Release the Files?" />
                  </div>
                )}

                <div className="table-responsive">
                  <table className="table mt-4">
                    <tbody className="bg-white">
                      {readyForReleaseFiles.length > 0 ? readyForReleaseFiles.map(file => (
                        <FileRow
                          getCheckedFiles={getCheckedFiles}
                          canUpdate
                          isOperationsReceptionOrAdmin={isOperationsReceptionOrAdmin}
                          file={file}
                          key={file.data.id}
                          isManagerRole={isManagerRole}
                          fileGroup="Ready for Release"
                          jobService={jobService}
                          reloadData={reloadData}
                          selectedFileIds={selectedFileIds} />
                      )) : (
                        <tr>
                          <td>No File uploaded.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        {rsReportsViewAllowed && (
          <Accordion
            defaultActiveKey={registeredSurveyorFilesOpen}
            onSelect={() => (setRsFilesArrowUp(!rsFilesArrowUp))}>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="Registered Surveyor Files" className="cursor-pointer">
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="mb-0">
                    Registered Surveyor Files (
                    {registeredSurveyorFiles.length}
                    )
                  </h6>
                  <img
                    src={arrowDownIcon}
                    className="img-fluid"
                    style={arrowStyle(rsFilesArrowUp)} />
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="Registered Surveyor Files">
                <Card.Body>
                  <div className="text-right">
                    {rsFilesCreateEditAllowed && (
                      <button
                        className="btn btn-outline-secondary mr-2"
                        type="button"
                        disabled={isBookInCheckSurvey}
                        onClick={() => handleAddFiles("registered_surveyor_files")}>
                        Add Files
                      </button>
                    )}


                    {(!rsDraftReports?.length && !draftRsReports?.length && rsReportsCreateEditAllowed) && (
                      <button
                        className="btn btn-primary mr-2"
                        type="button"
                        onClick={() => setShowRsReportModal(true)}>
                        Generate New Report
                      </button>
                    )}
                  </div>

                  {(!!draftRsReports?.length && rsReportsCreateEditAllowed) && draftRsReports.map(draft => (
                    <div className="row px-3 justify-content-between mt-3" key={draft?.id}>
                      <div className="">
                        <p className="pt-2">
                          {draft?.attributes?.fileName}
                          <span className="badge bg-warning ml-1">draft</span>
                        </p>
                      </div>
                      <div>
                        <button
                          className="btn btn-outline-primary mr-2"
                          onClick={() => {
                            setCurrentReport(draft);
                            setShowRsReportModal(true)
                          }}
                          type="button">
                          Edit
                        </button>
                        <a
                          className="btn btn-outline-primary mr-2"
                          target="_blank"
                          rel="noreferrer"
                          href={draft?.attributes?.uploadedFileUrl}>
                          Preview PDF
                        </a>
                        {(rsReportsGenerateAllowed && registeredSurveyorId) && (
                          <button
                            className="btn btn-red"
                            onClick={() => generateRsReportFile(draft?.id)}
                            type="button">
                            Generate File
                          </button>
                        )}
                      </div>
                    </div>
                  ))}

                  <GenerateRsReportModal
                    currentReport={currentReport}
                    reloadData={reloadData}
                    fetchRsDraftReports={fetchRsDraftReports}
                    show={showRsReportModal}
                    onHide={() => setShowRsReportModal(false)}
                    jobService={jobService} />

                  <div className="table-responsive">
                    <table className="table mt-4">
                      <tbody className="bg-white">
                        {registeredSurveyorFiles?.length > 0 ? _.sortBy(registeredSurveyorFiles, [obj => obj.data.attributes.superseded]).map(file => (
                          <FileRow
                            getCheckedFiles={getCheckedFiles}
                            canUpdate={canUpdate(REGISTERED_SURVEYORS_MANAGER, file)}
                            isOperationsReceptionOrAdmin={isOperationsReceptionOrAdmin}
                            file={file}
                            fileOptions={FILE_GROUPS.registered_surveyor_files}
                            key={file.data.id}
                            isManagerRole={isManagerRole}
                            jobService={jobService}
                            reloadData={reloadData}
                            showSuperseded={canSupersedeRsFiles}
                            selectedFileIds={selectedFileIds} />
                        )) : (
                          <tr>
                            <td>No File uploaded.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        )}

        <Accordion
          defaultActiveKey={draftingFilesopen}
          onSelect={() => (setDraftingFilesArrowUp(!draftingFilesArrowUp))}>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="Drafting Files" className="cursor-pointer">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="mb-0">
                  Drafting Files (
                  {filteredDraftingFiles.length}
                  )
                </h6>
                <img
                  src={arrowDownIcon}
                  className="img-fluid"
                  style={arrowStyle(draftingFilesArrowUp)} />
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="Drafting Files">
              <Card.Body>
                {canAddDraftingFiles && (
                  <div className="text-right">
                    <button
                      className="btn btn-outline-secondary mr-2"
                      type="button"
                      disabled={isBookInCheckSurvey}
                      onClick={() => handleAddFiles("drafting_files")}>
                      Add Files
                    </button>
                  </div>
                )}

                <div className="table-responsive">
                  <table className="table mt-4">
                    <tbody className="bg-white">
                      {filteredDraftingFiles?.length > 0 ? _.sortBy(filteredDraftingFiles, [obj => obj.data.attributes.superseded]).map(file => (
                        <FileRow
                          getCheckedFiles={getCheckedFiles}
                          canUpdate={canUpdate(is3D ? DRAFTING_3D_MANAGER : DRAFTING_MANAGER, file)}
                          isOperationsReceptionOrAdmin={isOperationsReceptionOrAdmin}
                          file={file}
                          fileOptions={gon.options.fileGroups.drafting_files}
                          key={file.data.id}
                          isManagerRole={isManagerRole}
                          jobService={jobService}
                          reloadData={reloadData}
                          showSuperseded
                          selectedFileIds={selectedFileIds} />
                      )) : (
                        <tr>
                          <td>No File uploaded.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        {!!flagFiles?.length && (
          <Accordion
            defaultActiveKey={flagFilesOpen}
            onSelect={() => (setFlagFilesArrowUp(!flagFilesArrowUp))}>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="Flag Files" className="cursor-pointer">
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="mb-0">
                    Flag Files (
                    {flagFiles.length}
                    )
                  </h6>
                  <img
                    src={arrowDownIcon}
                    className="img-fluid"
                    style={arrowStyle(flagFilesArrowUp)} />
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="Flag Files">
                <Card.Body>
                  <div className="table-responsive">
                    <table className="table mt-4">
                      <tbody className="bg-white">
                        {flagFiles?.length > 0 ? flagFiles.map(file => (
                          <FileRow
                            getCheckedFiles={getCheckedFiles}
                            canUpdate={canUpdate(OPERATION_MANAGER, file)}
                            isOperationsReceptionOrAdmin={isOperationsReceptionOrAdmin}
                            file={file}
                            fileOptions={FILE_GROUPS.flag_files}
                            key={file.data.id}
                            isManagerRole={isManagerRole}
                            jobService={jobService}
                            reloadData={reloadData}
                            selectedFileIds={selectedFileIds} />
                        )) : (
                          <tr>
                            <td>No File uploaded.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        )}

        <Accordion
          defaultActiveKey={surveyorFilesOpen}
          onSelect={() => (setSurveyorFilesArrowUp(!surveyorFilesArrowUp))}>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="Surveyor Files" className="cursor-pointer">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="mb-0">
                  Surveyor Files (
                  {surveyorFiles.length}
                  )
                </h6>
                <img
                  src={arrowDownIcon}
                  className="img-fluid"
                  style={arrowStyle(surveyorFilesArrowUp)} />
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="Surveyor Files">
              <Card.Body>
                {canAddSurveyorFiles && (
                  <div className="text-right">
                    <button
                      className="btn btn-outline-secondary mr-2"
                      type="button"
                      onClick={() => handleAddFiles("surveyor_files")}>
                      Add Files
                    </button>
                  </div>
                )}

                <div className="table-responsive">
                  <table className="table mt-4">
                    <tbody className="bg-white">
                      {surveyorFiles?.length > 0 ? surveyorFiles.map(file => (
                        <FileRow
                          getCheckedFiles={getCheckedFiles}
                          canUpdate={canUpdate(OPERATION_MANAGER, file)}
                          isOperationsReceptionOrAdmin={isOperationsReceptionOrAdmin}
                          file={file}
                          fileOptions={FILE_GROUPS.surveyor_files}
                          key={file.data.id}
                          isManagerRole={isManagerRole}
                          jobService={jobService}
                          reloadData={reloadData}
                          selectedFileIds={selectedFileIds} />
                      )) : (
                        <tr>
                          <td>No File uploaded.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <Accordion
          defaultActiveKey="0"
          onSelect={() => (setPhotoFilesArrowUp(!photoFilesArrowUp))}>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="Photos" className="cursor-pointer">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="mb-0">
                  Photos (
                  {photos.length}
                  )
                </h6>
                <img
                  src={arrowDownIcon}
                  className="img-fluid"
                  style={arrowStyle(photoFilesArrowUp)} />
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="Photos">
              <Card.Body>
                <div className="text-right">
                  <button
                    className="btn btn-outline-secondary mr-2"
                    type="button"
                    disabled={!photos.length > 0 || downloadAllButtonClicked}
                    onClick={() => downloadAllPhotosHandler()}>
                    Download All
                  </button>
                  {canAddPhotos && (
                    <button
                      className="btn btn-outline-secondary mr-2"
                      type="button"
                      onClick={() => handleAddFiles("photos")}>
                      Add Photos
                    </button>
                  )}
                </div>

                <div className="row ml-0 mt-1">
                  {photos?.length > 0 ? allPhotos.map((file, index) => (
                    <FilePreview
                      isGalleryButton
                      popupGallery={() => { setShowPopupGallery(true); setGalleryStartIndex(index) }}
                      file={file}
                      key={file.data.id}
                      canDelete={roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) || currentUser.attributes.name === file?.data?.attributes?.createdByUser}
                      reloadData={reloadData} />
                  )) : (
                    <div>No Photo uploaded.</div>
                  )}
                </div>
                {photos.length > 10
                  && (
                    <button className="btn btn-outline-secondary mr-2 mt-2" onClick={() => photoHandler()} type="button">{showAllPhotos ? "Show Less" : "Show More"}</button>
                  )
                }
                {showPopupGallery && <Lightbox startIndex={galleryStartIndex} images={images} onClose={() => setShowPopupGallery(false)} />}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <Accordion
          defaultActiveKey="Job Instructions"
          onSelect={() => (setJobInstructionsArrowUp(!jobInstructionsArrowUp))}>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="Job Instructions" className="cursor-pointer">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="mb-0">
                  Job Instructions (
                  {sortedAndFilteredJobInstructionsFiles.length}
                  )
                </h6>
                <img
                  src={arrowDownIcon}
                  className="img-fluid"
                  style={arrowStyle(jobInstructionsArrowUp)} />
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="Job Instructions">
              <Card.Body>
                <div className="text-right">
                  <button
                    className="btn btn-outline-secondary mr-2"
                    type="button"
                    onClick={() => handleAddFiles("job_instructions")}>
                    Add Files
                  </button>
                </div>
                <div className="table-responsive">
                  <table className="table mt-4">
                    <tbody className="bg-white">
                      {sortedAndFilteredJobInstructionsFiles?.length > 0 ? sortedAndFilteredJobInstructionsFiles.map(file => (
                        <FileRow
                          getCheckedFiles={getCheckedFiles}
                          canUpdate={canUpdate(OPERATION_MANAGER, file)}
                          isOperationsReceptionOrAdmin={isOperationsReceptionOrAdmin}
                          file={file}
                          fileOptions={allowedJobInstructionsOptions}
                          key={file.data.id}
                          fileGroup="Job Instructions"
                          isManagerRole={isManagerRole}
                          jobService={jobService}
                          reloadData={reloadData}
                          selectedFileIds={selectedFileIds} />
                      )) : (
                        <tr>
                          <td>No File uploaded.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <Accordion
          defaultActiveKey={searchesOpen}
          onSelect={() => (setSearchesArrowUp(!searchesArrowUp))}>
          <Card className="border-last-card">
            <Accordion.Toggle as={Card.Header} eventKey="Searches" className="cursor-pointer">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="mb-0">
                  Searches (
                  {searches.length}
                  )
                </h6>
                <img
                  src={arrowDownIcon}
                  className="img-fluid"
                  style={arrowStyle(searchesArrowUp)} />
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="Searches">
              <Card.Body>
                <div className="text-right">
                  {/* {(hasImportableJobPacks && !jobPackUploaded) && (
                    <button
                      className="btn btn-outline-secondary mr-2"
                      type="button"
                      onClick={() => setShowImportJobPacksModal(true)}>
                      Import Job Pack Files
                    </button>
                  )} */}
                  <button
                    className="btn btn-outline-secondary mr-2"
                    type="button"
                    onClick={() => handleAddFiles("searches")}>
                    Add Files
                  </button>
                  {/* <SweetAlert
                    show={showImportJobPacksModal}
                    type="warning"
                    title="Are you sure?"
                    closeOnClickOutside
                    allowEscape={false}
                    onConfirm={() => handleImportJobPacks()}
                    confirmBtnText="Proceed"
                    showCloseButton
                    disabled={hasBeenConfirmed}
                    onCancel={() => setShowImportJobPacksModal(false)}>
                    This will import all the job pack files of a different job service from the same location.
                  </SweetAlert> */}
                </div>
                <div className="table-responsive">
                  <table className="table mt-4">
                    <tbody className="bg-white">
                      {searches?.length > 0 ? searches.map(file => (
                        <FileRow
                          fileGroup="Searches"
                          getCheckedFiles={getCheckedFiles}
                          canUpdate={canUpdate(OPERATION_MANAGER, file)}
                          isOperationsReceptionOrAdmin={isOperationsReceptionOrAdmin}
                          fileOptions={FILE_GROUPS.searches}
                          file={file}
                          key={file.data.id}
                          isManagerRole={isManagerRole}
                          jobService={jobService}
                          reloadData={reloadData}
                          selectedFileIds={selectedFileIds} />
                      )) : (
                        <tr>
                          <td>No File uploaded.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        {(systemRole === ADMIN) && (
          <Accordion
            defaultActiveKey="0"
            onSelect={() => (setDeletedFilesArrowUp(!deletedFilesArrowUp))}>
            <Card className="border-last-card">
              <Accordion.Toggle as={Card.Header} eventKey="Deleted Files" className="cursor-pointer">
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="mb-0">
                    Deleted Files (
                    {discardedFiles.length}
                    )
                  </h6>
                  <img
                    src={arrowDownIcon}
                    className="img-fluid"
                    style={arrowStyle(deletedFilesArrowUp)} />
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="Deleted Files">
                <Card.Body>
                  <div className="table-responsive">
                    <table className="table files-table mt-4">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th className="text-center">Category</th>
                          <th className="text-center">Created By</th>
                          <th className="text-center">Created Date</th>
                          <th className="text-center">Deleted By</th>
                          <th className="text-center">Deleted Date</th>
                        </tr>
                      </thead>

                      <tbody className="bg-white">
                        {discardedFiles?.length > 0 ? discardedFiles.map(file => (
                          <FileRow
                            getCheckedFiles={getCheckedFiles}
                            isDeleted
                            canUpdate={canUpdate(OPERATION_MANAGER, file)}
                            isOperationsReceptionOrAdmin={isOperationsReceptionOrAdmin}
                            file={file}
                            key={file.data.id}
                            isManagerRole={isManagerRole}
                            jobService={jobService}
                            reloadData={reloadData}
                            selectedFileIds={selectedFileIds} />
                        )) : (
                          <tr>
                            <td>No File deleted.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        )}

        <AddFilesModal
          showUploadedFilesSection={showUploadedFilesSection}
          uploadedFilesSectionSize={uploadedFilesSectionSize}
          uploadedFilesSource={uploadedFilesSource()}
          fileCategories={fileOptions}
          defaultCategory={defaultCategory()}
          jobService={jobService}
          show={showUploadFilesModal}
          reloadData={reloadData}
          onHide={() => { setShowUploadFilesModal(false) }} />
      </div>
    </>
  )
}

export default Files
