import React, { useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { roleCheck } from "utils/index"
import { Container } from "react-bootstrap"
import FilterDropdown from "components/FilterDropdown"
import SearchInput from "components/SearchInput"
import Loader from "components/Loader"
import AnchorButton from "components/AnchorButton"
import { get } from "lodash";
import { getBaseUrl, getCurrentRole, getCurrentUser } from "../../reducers";

import {
  REGISTERED_SURVEYORS_MANAGER,
  REGISTERED_SURVEYORS_USER,
  TASK_STATUSES,
  TASK_TYPES
} from "../../../../utils/constants";
import { getRegisterdSurveyors } from "../../../../requesters/userRequester";
import AddTaskModal from "./AddTaskModal";
import { capitalizeString } from "../../../../utils";
import TasksByAssignedUserTable from "./TasksByAssignedUserTable";
import { getTasksGroupByAssignedUser } from "../../../../requesters/taskRequester";

export default function Activities() {
  const taskTypeOptions = useMemo(() => {
    const types = Object.keys(TASK_TYPES)
    return types.map(type => ([capitalizeString(type), TASK_TYPES[type]]))
  }, [])
  const taskStatusOptions = Object.keys(TASK_STATUSES).map(status => ([TASK_STATUSES[status], status]))

  const baseUrl = useSelector(getBaseUrl)
  const currentRole = useSelector(getCurrentRole)
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role
  const [requesting, setRequesting] = useState(false)
  const [tasks, setTasks] = useState([])
  const [regSurveyorOptions, setRegSurveyorOptions] = useState([])
  const [showAddTaskModal, setShowAddTaskModal] = useState(false)
  const isRSManager = roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)
  const isRSUser = roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_USER)
  const canAccess = isRSUser || isRSManager

  if (!canAccess) window.location = "/"

  const initialFilters = {
    by_status: "not_completed",
    by_assigned_surveyor: isRSManager ? undefined : currentUser.id,
    by_task_type: undefined,
    by_keyword: undefined
  }
  const [filters, setFilters] = useState(initialFilters)
  const [dropdownFiltersChanged, setDropdownFiltersChanged] = useState(false)

  const fetchRegSurveyorOptions = () => {
    getRegisterdSurveyors(baseUrl)
    .then(res => {
      if (res.status === 200) {
        const newRegSurveyorOptions = res.response.map(surveyorData => ([`${surveyorData[0]} ${surveyorData[1]}`, surveyorData[2]]))
        setRegSurveyorOptions(newRegSurveyorOptions)
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }

  const fetchData = () => {
    setRequesting(true)
    getTasksGroupByAssignedUser(baseUrl, filters)
    .then(res => {
      if (res.status === 200) {
        const tasks = get(res, "response", {})
        setTasks(tasks)
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
    .finally(() => setRequesting(false))
  }

  const onSearch = e => {
    e.preventDefault()
    fetchData()
  }

  const onSetFilters = (key, val) => {
    const newFilters = { ...filters }
    newFilters[key] = val
    setFilters(newFilters)
    if (key !== "by_keyword") {
      setDropdownFiltersChanged(true)
    }
  }

  const getFilterName = (key, options) => {
    const selectedOption = options.find(o => o[1] === filters[key])
    const filteredName = selectedOption ? selectedOption[0] : ""

    return filteredName
  }

  useEffect(() => {
    fetchRegSurveyorOptions()
    fetchData()
  }, [])

  useEffect(() => {
    if (dropdownFiltersChanged) {
      fetchData()
      setDropdownFiltersChanged(false)
    }
  }, [dropdownFiltersChanged])

  return (
    <Container fluid className="">
      <div className="container-inner">
        <div className="d-flex justify-content-between align-items-center header-tabs-container">
          <div className="header-tabs">
            <h1>REGISTERED SURVEYORS ACTIVITIES</h1>
          </div>
          <div className="header-buttons">
            { isRSManager && (
              <div className="add-activity">
                <button
                  type="button"
                  className="btn btn-outlined-blue btn-md mr-2"
                  onClick={() => setShowAddTaskModal(true)}>
                  + Add Activity
                </button>
                <AddTaskModal
                  modalTitle="Add Activity"
                  show={showAddTaskModal}
                  onHide={() => setShowAddTaskModal(false)}
                  reloadData={fetchData} />
              </div>
            )}
          </div>
        </div>
        <div className="table-listing-filters d-md-flex justify-content-between">
          <div>
            <div className="btn-group mr-2">
              <SearchInput
                value={filters.by_keyword}
                setValue={val => onSetFilters("by_keyword", val)}
                placeholder="Search by job no, job address"
                handleSubmit={onSearch}
                disableSubmit={requesting} />
            </div>
            <div className="btn-group mr-2">
              <FilterDropdown
                title="Status"
                filteredName={getFilterName("by_status", taskStatusOptions)}
                setFilter={val => onSetFilters("by_status", val)}
                options={taskStatusOptions} />
            </div>
            <div className="btn-group mr-2">
              <FilterDropdown
                title="Task Type"
                filteredName={getFilterName("by_task_type", taskTypeOptions)}
                setFilter={val => onSetFilters("by_task_type", val)}
                options={taskTypeOptions} />
            </div>
            { isRSManager && (
              <div className="btn-group mr-2">
                <FilterDropdown
                  title="Assigned To"
                  filteredName={getFilterName("by_assigned_surveyor", regSurveyorOptions)}
                  setFilter={val => onSetFilters("by_assigned_surveyor", val)}
                  options={regSurveyorOptions} />
              </div>
            )}
            <div className="btn-group">
              <AnchorButton
                className="f-small"
                onClick={() => {
                  setFilters(initialFilters)
                  setDropdownFiltersChanged(true)
                }}>
                Clear Filters
              </AnchorButton>
            </div>
          </div>
        </div>
        { requesting ? <Loader />
          : (
            <TasksByAssignedUserTable
              tasks={tasks}
              setTasks={setTasks}
              reloadData={fetchData} />
          )}
      </div>
    </Container>
  )
}