import normalize from "json-api-normalizer"
import customAxios from "./customAxios";

export const getTasks = (baseUrl, params) => {
  const path = `${baseUrl}/tasks`;
  return customAxios.get(path, params)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
};

export const getTasksGroupByAssignedUser = (baseUrl, filters) => {
  const path = `${baseUrl}/tasks/assigned-users-grouped`;
  return customAxios.get(path, { params: filters })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }))
};

export const getTask = (baseUrl, id) => {
  const path = `${baseUrl}/tasks/${id}`
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const createTask = (baseUrl, params) => {
  const path = `${baseUrl}/tasks`
  return customAxios.post(path, params)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const updateTask = (baseUrl, id, params) => {
  const path = `${baseUrl}/tasks/${id}`
  return customAxios.put(path, params)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const updateTaskOrderInAssignedUserScope = (baseUrl, id, destinationId) => {
  const path = `${baseUrl}/tasks/${id}/assigned-user-priority`
  return customAxios.put(path, { destination_id: destinationId })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const transitionTaskTo = (baseUrl, id, transition) => {
  const path = `${baseUrl}/tasks/${id}/${transition}`
  return customAxios.put(path)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const deleteTaskAttechment = (baseUrl, id, fileIds) => {
  const path = `${baseUrl}/tasks/${id}/delete_attachments`
  return customAxios.put(path, { uploaded_file_ids: fileIds })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}