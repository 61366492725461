import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useQueryParam, StringParam } from "use-query-params";
import { isEmpty } from "lodash";
import { roleCheck } from "utils/index";
import { getCurrentUser } from "../../reducers";
import { getJobServiceByAddressCount } from "../../../../requesters/jobServiceRequester";
import Accounts from "./tabs/Accounts";
import Activity from "./tabs/Activity";
import Docket from "./tabs/Docket";
import Files from "./tabs/Files";
import History from "./tabs/History";
import NearbyJobs from "./tabs/NearbyJobs";
import TimeSpentTab from "./tabs/TimeSpent";

const payments = [{
  dateCreated: new Date(),
  invoiceNo: "12345",
  type: "Final Payment",
  amount: "600",
  status: "Payment Confirmed"
},
{
  dateCreated: new Date(),
  invoiceNo: "12345",
  type: "Docket",
  amount: "200",
  status: "Invoice Sent"
},
{
  dateCreated: new Date(),
  invoiceNo: "12345",
  type: "Deposit",
  amount: "600",
  status: "Payment Confirmed"
}
];

const DetailsTabs = ({
  job, reloadData, jobService, activities, baseUrl, currentRole
}) => {
  const [activeTab, setActiveTab] = useQueryParam("tab", StringParam)
  const [historyJobsCount, setHistoryJobsCount] = useState(0)
  const [nearbyJobsCounter, setNearbyJobsCounter] = useState(0)
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role

  const Tab = ({ title, activeTab, setActiveTab }) => {
    const isActiveTab = activeTab === title || (isEmpty(activeTab) && title === "Activity")
    const showHistoryJobsCounter = title === "History" && historyJobsCount > 0
    const showNearByJobsCounter = title === "Nearby Jobs"
    return (
      <li className="nav-item cursor-pointer" role="presentation" onClick={() => setActiveTab(title)}>
        <div
          id="pills-job-payment-tab"
          className={`nav-link ${isActiveTab ? "active" : ""}`}
          role="tab">
          <h6 className="mb-0">
            <span>{ title }</span>
            { showHistoryJobsCounter && (
              <span className="ml-1">
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                ({historyJobsCount})
              </span>
            )}
            { showNearByJobsCounter && (
              <span className="ml-1">
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                ({nearbyJobsCounter})
              </span>
            )}
          </h6>
        </div>
      </li>
    )
  };

  useEffect(() => {
    getJobServiceByAddressCount(baseUrl, jobService.id)
    .then(res => {
      if (res.status === 200) {
        setHistoryJobsCount(res.response?.job_count)
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }, [activeTab])

  useEffect(() => {
    const { attributes: { totalNearbyByJobs } } = jobService
    setNearbyJobsCounter(totalNearbyByJobs)
  }, [jobService])

  const canSeeAccountsTab = ["operations_manager", "admin_user", "admin_manager", "accounts_user", "accounts_manager"]
  const showAccounts = canSeeAccountsTab.map(team => (roleCheck(systemRole, currentTeamRole, team)))
  const showDocket = true

  return (
    <>
      <ul id="pills-tab" className="nav nav-pills my-5 ca-tabs" role="tablist">
        <Tab title="Activity" activeTab={activeTab} setActiveTab={setActiveTab} />
        <Tab title="Files" activeTab={activeTab} setActiveTab={setActiveTab} />
        {showAccounts?.includes(true) && (
          <Tab title="Accounts" activeTab={activeTab} setActiveTab={setActiveTab} />
        )}
        <Tab title="History" activeTab={activeTab} setActiveTab={setActiveTab} />
        <Tab title="Nearby Jobs" activeTab={activeTab} setActiveTab={setActiveTab} />
        <Tab title="Time Spent" activeTab={activeTab} setActiveTab={setActiveTab} />
        {showDocket && (
          <Tab title="Dockets" activeTab={activeTab} setActiveTab={setActiveTab} />
        )}
      </ul>
      <div id="pills-tabContent" className="tab-content">
        {activeTab === "Accounts" && <Accounts payments={payments} jobService={jobService} reloadData={reloadData} />}
        {activeTab === "Files" && <Files jobService={{ ...jobService, jobTitle: job?.attributes?.jobTitle }} baseUrl={baseUrl} currentRole={currentRole} reloadData={reloadData} />}
        {(isEmpty(activeTab) || activeTab === "Activity") && <Activity activities={activities} job={job} jobService={jobService} reloadData={reloadData} setActiveTab={setActiveTab} />}
        {activeTab === "History" && <History jobService={jobService} reloadData={reloadData} setActiveTab={setActiveTab} />}
        {activeTab === "Nearby Jobs" && <NearbyJobs jobService={jobService} />}
        {activeTab === "Time Spent" && <TimeSpentTab jobService={jobService} reloadData={reloadData} />}
        {activeTab === "Dockets" && showDocket && <Docket jobService={jobService} reloadData={reloadData} />}
      </div>
    </>
  );
};

export default DetailsTabs;
