import React, { memo } from "react";
import { useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { updateDraftsmanOrder } from "requesters/jobServiceRequester"
import {
  DRAFTING_USER, DRAFTING_OVERSEAS_USER, ON_HOLD, CLIENT_DELAYED
} from "utils/constants"
import DraftingRow from "./DraftingRow"
import { getBaseUrl, getCurrentUser } from "../../reducers"

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function TableRow({
  jobService,
  index,
  draftmenOptions,
  fetchData,
  filtersApplied,
  readOnly,
  is2DTeam
}) {
  const currentUser = useSelector(getCurrentUser)
  const currentTeamRole = currentUser.attributes.current_team_role
  const disableDrag = !!((currentTeamRole.includes(DRAFTING_USER)
                      || currentTeamRole.includes(DRAFTING_OVERSEAS_USER)
                      || jobService.attributes.is_additional))
                      || filtersApplied

  return (
    <Draggable draggableId={`${jobService.attributes.is_additional ? jobService.attributes.draftsmen_assignment_id : jobService?.id}`} index={index} isDragDisabled={disableDrag}>
      {provided => (
        <tr
          className={[CLIENT_DELAYED, ON_HOLD].includes(jobService.attributes.state) ? "greyout" : ""}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          <DraftingRow
            key={`${jobService.id}-${(jobService.attributes.draftsmen_assignment_id || 0)}`}
            disableDrag={disableDrag}
            drafting={jobService}
            draftmenOptions={draftmenOptions}
            fetchData={fetchData}
            filtersApplied={filtersApplied}
            readOnly={readOnly}
            is2DTeam={is2DTeam}
            index={index} />
        </tr>
      )}
    </Draggable>
  );
}

const RowList = memo(({
  jobsDrafting,
  draftmenOptions,
  setJobsDrafting,
  fetchData,
  filtersApplied,
  readOnly,
  is2DTeam
}) => jobsDrafting.map((jobService, index) => (
  <TableRow
    jobService={jobService}
    draftmenOptions={draftmenOptions}
    fetchData={fetchData}
    readOnly={readOnly}
    filtersApplied={filtersApplied}
    setJobsDrafting={setJobsDrafting}
    index={index}
    is2DTeam={is2DTeam}
    key={`${jobService.id}-${(jobService.attributes.draftsmen_assignment_id || 0)}`} />
)));

function DraggableDraftingRow({
  draftmenOptions,
  jobsDrafting,
  setJobsDrafting,
  fetchData,
  filtersApplied,
  readOnly,
  is2DTeam
}) {
  const baseUrl = useSelector(getBaseUrl);
  const updateSortingIndex = (id, index) => {
    updateDraftsmanOrder(baseUrl, id, index)
  }


  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const destination = jobsDrafting[result.destination.index]
    if (!destination || destination.attributes.is_additional) {
      return
    }


    updateSortingIndex(result.draggableId, result.destination.index)

    const rows = reorder(
      jobsDrafting,
      result.source.index,
      result.destination.index
    );

    setJobsDrafting(rows);
  }

  return (
    <DragDropContext onDragEnd={result => {
      onDragEnd(result)
    }}>
      <Droppable droppableId="list">
        {provided => (
          <tbody ref={provided.innerRef} {...provided.droppableProps}>
            <RowList
              draftmenOptions={draftmenOptions}
              jobsDrafting={jobsDrafting}
              setJobsDrafting={setJobsDrafting}
              fetchData={fetchData}
              is2DTeam={is2DTeam}
              filtersApplied={filtersApplied}
              readOnly={readOnly} />
            {provided.placeholder}
          </tbody>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DraggableDraftingRow
