import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { Modal, Spinner } from "react-bootstrap";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import _ from "lodash";
import Loader from "components/Loader"

import { sendInvoiceForJobService, getJobServicesByJob } from "requesters/jobServiceRequester"
import Swal from "sweetalert2";
import InvoiceLineItems from "../InvoiceLineItems";
import { getBaseUrl } from "../../../reducers";
import {
  INVOICE_PENDING, PAID_DEPOSIT, CANCELLED, RESCHEDULED
} from "../../../../../utils/constants";

const GenerateInvoiceModal = ({
  show, onHide, jobService, reloadData, setActiveTab
}) => {
  const baseUrl = useSelector(getBaseUrl)
  const [jobServices, setJobServices] = useState([])
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const { promiseInProgress } = usePromiseTracker();
  const [loadingJobServices, setLoadingJobServices] = useState(false)
  const [jobServicesOptions, setJobServicesOptions] = useState([])
  const [docketOptions, setDocketOptions] = useState([])

  const {
    id, attributes: {
      requiresRaiseInvoice, state, jobId, invoiceContacts, invoiceComments, invoiceState, accountType, xeroAccounts, workflow, cost, cancellationFee, hasFinalInvoice, hasDepositInvoice, rescheduleFee, requiresDocketInvoice, organization
    }
  } = jobService;

  const defaultXeroAccount = xeroAccounts.find(acc => acc?.label?.includes("3D"))?.value || xeroAccounts[0]?.value

  const needDepositInvoice = accountType === "50% - 50%" && invoiceState === INVOICE_PENDING && Number(cost) > 0 && !hasFinalInvoice && !hasDepositInvoice && workflow !== "7" && workflow !== "8" && state !== CANCELLED

  const needCancellationInvoice = state === CANCELLED && cancellationFee > 0 && requiresRaiseInvoice

  const needRescheduleInvoice = state === RESCHEDULED && rescheduleFee > 0 && requiresRaiseInvoice

  const needDocketInvoice = !!requiresDocketInvoice

  const needFinalInvoice = state !== CANCELLED && !hasFinalInvoice && (([PAID_DEPOSIT, INVOICE_PENDING].includes(invoiceState) && Number(cost) > 0) || needRescheduleInvoice || needDocketInvoice)

  const contactOptions = invoiceContacts.map(contact => ({
    value: contact.contactId,
    label: `${contact.name} (${contact.contactType})`,
    type: contact.contactType
  }));

  const [invoiceToOptions, setInvoiceToOptions] = useState([]);

  // setInvoiceToOptions([...contactOptions, ...organizationOptions])

  // const jobServiceIds = jobServicesOptions.filter(service => service.current).map(item => item.value);
  const contactId = contactOptions.find(contact => contact.type === "Invoice")?.value
    || contactOptions.find(contact => contact.type === "Main Person")?.value


  function createInvoiceTypeOptions() {
    const options = []
    if (needFinalInvoice) {
      options.push({ name: "Final", value: "Final" })
    }
    if (needDepositInvoice) {
      options.push({ name: "Deposit", value: "Deposit" })
    }
    options.push({ name: "Other", value: "Other" })
    return options
  }

  function getJobServiceOptions(dataJobServices) {
    return dataJobServices.map(js => {
      let current = js.id === jobService.id
      if (js.attributes.state === RESCHEDULED && js.attributes.rescheduleFee > 0 && js.attributes.requiresRaiseInvoice) {
        current = true
      }
      return {
        value: js.id,
        label: `${js.attributes.label} (${js.attributes.titleizeState})`,
        current
      }
    })
  }

  function getInvoiceLineItems(formData, jobServicesData, docketOptionsData) {
    const newInvoiceLineItems = []
    if (formData.invoice_type === "Deposit") {
      if (formData.job_service_ids.length > 0) {
        formData.job_service_ids.forEach(jobServiceId => {
          const jobService = jobServices.find(js => js.id === jobServiceId)
          if (jobService && !jobService.attributes.hasDepositInvoice) {
            newInvoiceLineItems.push({
              id: `${Date.now()}-${jobServiceId}`,
              description: `${jobService.attributes.label} (Deposit)`,
              quantity: 1,
              unit_amount: Number(jobService.attributes.cost) / 2,
              item_type: jobService.attributes.code,
              readonly: true,
              disable_remove: true,
              original_id: jobServiceId
            })
          }
        })
      }
    } else {
      const disableRemove = formData.invoice_type !== "Other"
      if (formData.job_service_ids.length > 0) {
        formData.job_service_ids.forEach(jobServiceId => {
          const jobService = jobServicesData.find(js => js.id === jobServiceId)
          if (jobService) {
            if (jobService.attributes.state === "cancelled" && jobService.attributes.cancellationFee > 0 && jobService.attributes.requiresCancellationInvoice) {
              newInvoiceLineItems.push({
                id: `${Date.now()}-${jobServiceId}`,
                description: `${jobService.attributes.label} (Cancellation Fee)`,
                quantity: 1,
                unit_amount: Number(jobService.attributes.cancellationFee),
                item_type: "CANCELLATION_FEE",
                readonly: true,
                disable_remove: disableRemove,
                original_id: jobServiceId
              })
            } else if (jobService.attributes.state === "rescheduled" && jobService.attributes.rescheduleFee > 0 && jobService.attributes.requiresRescheduleInvoice) {
              newInvoiceLineItems.push({
                id: `${Date.now()}-${jobServiceId}`,
                description: `${jobService.attributes.label} (Reschedule Fee)`,
                quantity: 1,
                unit_amount: Number(jobService.attributes.rescheduleFee),
                item_type: "RESCHEDULE_FEE",
                readonly: true,
                disable_remove: disableRemove,
                original_id: jobServiceId
              })
            } else if (Number(jobService.attributes.cost) > 0 && !jobService.attributes.hasFinalInvoice) {
              const onlyNeedOneHalf = jobService.attributes.invoiceState === PAID_DEPOSIT && jobService.attributes.hasDepositInvoice
              newInvoiceLineItems.push({
                id: `${Date.now()}-${jobServiceId}`,
                description: jobService.attributes.label,
                quantity: 1,
                unit_amount: onlyNeedOneHalf ? Number(jobService.attributes.cost) / 2 : Number(jobService.attributes.cost),
                item_type: jobService.attributes.code,
                readonly: true,
                disable_remove: disableRemove,
                original_id: jobServiceId
              })
            }
          }
        })
      }
      if (formData.docket_ids.length > 0 && formData.include_docket) {
        formData.docket_ids.forEach(docketId => {
          const docket = docketOptionsData.find(docket => Number(docket.value) === Number(docketId))
          if (docket) {
            newInvoiceLineItems.push({
              id: `${Date.now()}-${docketId}`,
              description: docket.docketData.docketLineItemDescriptions,
              quantity: 1,
              unit_amount: Number(docket.docketData.totalAmount),
              item_type: "DOCKET",
              readonly: true,
              disable_remove: disableRemove,
              original_id: docketId
            })
            if (docket.docketData.hasDocketDescriptions && docket.docketData.docketDescriptionsLineItemsDescriptions) {
              docket.docketData.docketDescriptionsLineItemsDescriptions.forEach(description => {
                newInvoiceLineItems.push({
                  id: `${Date.now()}-${docketId}-${description.id}`,
                  description: description.description,
                  quantity: 1,
                  unit_amount: 0,
                  item_type: "DOCKET_DESCRIPTION",
                  readonly: true,
                  disable_remove: disableRemove,
                  original_id: description.id
                })
              })
            }
          }
        })
      }
    }
    return newInvoiceLineItems
  }


  const invoiceTypeOptions = createInvoiceTypeOptions()

  const initialInvoiceType = () => {
    if ((needFinalInvoice || needDepositInvoice) && invoiceTypeOptions.find(option => option.value === "Final")) {
      return "Final"
    }
    return "Other"
  }

  const INITIAL_STATE = {
    job_id: jobId,
    job_service_ids: [],
    invoice_type: initialInvoiceType(),
    xero_account: defaultXeroAccount,
    invoice_to_id: contactId,
    invoice_to_type: "Contact",
    include_docket: needDocketInvoice && initialInvoiceType() !== "Deposit",
    docket_ids: [],
  }

  const [form, setForm] = useState(INITIAL_STATE);
  const [showCheckBoxIncludeDocket, setShowCheckBoxIncludeDocket] = useState(["Final", "Other"].includes(INITIAL_STATE.invoice_type));
  const [invoiceLineItems, setInvoiceLineItems] = useState([])

  useEffect(() => {
    const invoiceAbleJobServices = jobServices.filter(js => js.attributes.invoicable)
    const newJobServicesOptions = getJobServiceOptions(invoiceAbleJobServices)
    const newDocketOptions = []
    invoiceAbleJobServices.forEach(js => {
      js.attributes.dockets.data.forEach(d => {
        if (d.attributes.status === "reviewed") {
          newDocketOptions.push({
            value: d.id,
            label: `Docket: ${d.attributes.number} - ${js.attributes.code} (${js.attributes.titleizeState})`,
            current: js.id === jobService.id,
            docketData: d.attributes
          })
        }
      })
    })
    setJobServicesOptions(newJobServicesOptions)
    setDocketOptions(newDocketOptions)
    let newForm = { ...form }
    if (newJobServicesOptions.length > 0) {
      newForm = { ...newForm, job_service_ids: newJobServicesOptions.filter(service => service.current).map(item => item.value) }
    }
    if (newDocketOptions.length > 0 && form.include_docket && form.invoice_type !== "Deposit") {
      newForm = { ...newForm, docket_ids: newDocketOptions.filter(docket => docket.current).map(item => item.value) }
    }
    setForm(newForm)
    const newInvoiceLineItems = getInvoiceLineItems(newForm, jobServices, newDocketOptions)
    setInvoiceLineItems(newInvoiceLineItems)
    const validForm = form.job_service_ids.length !== 0 && form.invoice_to_id && newInvoiceLineItems.length !== 0
    setButtonDisabled(validForm)
  }, [jobServices])

  useEffect(() => {
    setLoadingJobServices(true)
    getJobServicesByJob(baseUrl, jobId)
      .then(res => {
        if (res.status === 200) {
          setJobServices(Object.values(_.get(res.response, "jobService", {})))
        }
        setLoadingJobServices(false)
      })
      // eslint-disable-next-line no-console
      .catch(() => {
        setLoadingJobServices(false)
      })
  }, [jobService])

  useEffect(() => {
    const validForm = form.job_service_ids.length !== 0 && form.invoice_to_id && invoiceLineItems.length !== 0
    if (!promiseInProgress && validForm) {
      setButtonDisabled(false)
    } else {
      setButtonDisabled(true)
    }
  }, [form, invoiceLineItems]);

  useEffect(() => {
    const newContactOptions = invoiceContacts.map(contact => ({
      value: contact.contactId,
      label: `${contact.name} (${contact.contactType})`,
      type: contact.contactType
    }));
    const organizationOptions = organization ? [{ value: organization.id, label: `${organization.name} (Organization)`, type: "Organization" }] : []
    setInvoiceToOptions([...newContactOptions, ...organizationOptions])
  }, [invoiceContacts, organization]);

  const sendInvoiceHandler = async () => {
    setButtonDisabled(true)
    const clearInvoiceLineItems = invoiceLineItems.map(item => _.omit(item, ["id", "readonly", "disable_remove"]));
    const result = await trackPromise(sendInvoiceForJobService(baseUrl, id, "send-invoice", { ...form, invoice_line_items: clearInvoiceLineItems }));

    if (result.error) {
      Swal.fire(
        "Error",
        result.error.message,
        "error"
      );
    } else if (result.status === 200) {
      onHide(); // close modal
      if (setActiveTab) setActiveTab("Accounts"); // set active tab to accounts if coming from Activity Tab
      Swal.fire(
        "Invoice created",
        "Invoice has been created on xero.",
        "success"
      );
      reloadData();
    }
  }

  const handleChange = (e, a) => {
    let newFormData = {}
    let formName = ""
    if (Array.isArray(e)) {
      formName = a.name
      newFormData = { ...form, [a.name]: e.map(js => js.value) }
    } else if (a && e.value) {
      formName = a.name
      newFormData = { ...form, [a.name]: e.value }
      if (formName === "invoice_to_id") {
        const invoiceToType = invoiceToOptions.find(invoiceToOption => invoiceToOption.value === e.value)?.type
        if (invoiceToType === "Organization") {
          newFormData = { ...newFormData, invoice_to_type: "Organization" }
        } else {
          newFormData = { ...newFormData, invoice_to_type: "Contact" }
        }
      }
    } else {
      const { name, value } = e.target
      formName = name
      let objectChange = {
        [name]: value
      }
      if (name === "invoice_type") {
        if (value === "Final" || value === "Other") {
          let newJobServicesOptions = getJobServiceOptions(jobServices.filter(js => js.attributes.invoicable))
          if (value === "Final") {
            const jobServiceNeedFinal = jobServices.filter(js => js.attributes.invoicable && !js.attributes.hasFinalInvoice && (([PAID_DEPOSIT, INVOICE_PENDING].includes(js.attributes.invoiceState) && Number(js.attributes.cost) > 0) || js.attributes.requiresRaiseInvoice || js.attributes.requiresDocketInvoice))
            newJobServicesOptions = getJobServiceOptions(jobServiceNeedFinal)
          }
          setJobServicesOptions(newJobServicesOptions)
          setShowCheckBoxIncludeDocket(true)
          objectChange = {
            ...objectChange, include_docket: false, docket_ids: [], job_service_ids: newJobServicesOptions.filter(service => service.current).map(item => item.value)
          }
        } else {
          const jobServiceNeedDeposit = jobServices.filter(js => js.attributes.invoicable && js.attributes.invoiceState === INVOICE_PENDING && Number(js.attributes.cost) > 0 && js.attributes.accountType === "50% - 50%" && !js.attributes.hasDepositInvoice && !js.attributes.hasFinalInvoice)
          const newJobServicesOptions = getJobServiceOptions(jobServiceNeedDeposit)
          setJobServicesOptions(newJobServicesOptions)
          setShowCheckBoxIncludeDocket(false)
          objectChange = {
            ...objectChange, include_docket: false, docket_ids: [], job_service_ids: newJobServicesOptions.filter(service => service.current).map(item => item.value)
          }
        }
      }
      if (name === "include_docket") {
        if (e.target.checked) {
          objectChange = { ...objectChange, docket_ids: docketOptions.filter(docket => docket.current).map(item => item.value), include_docket: true }
        } else {
          objectChange = { ...objectChange, docket_ids: [], include_docket: false }
        }
      }
      newFormData = { ...form, ...objectChange }
    }
    setForm({ ...newFormData })
    if (["invoice_type", "include_docket", "docket_ids", "job_service_ids"].includes(formName)) {
      const newInvoiceLineItems = getInvoiceLineItems(newFormData, jobServices, docketOptions)
      setInvoiceLineItems(newInvoiceLineItems)
    }
  };

  const validateForm = () => {
    if (form.job_service_ids.length === 0 || form.invoice_to_id === null) {
      Swal.fire(
        "Error",
        "Please select job service and contact or organization.",
        "error"
      );
    } else if (form.include_docket === true && form.docket_ids.length === 0) {
      Swal.fire(
        "Error",
        "Please select docket.",
        "error"
      );
    } else if (invoiceLineItems.length === 0) {
      Swal.fire(
        "Error",
        "Please add invoice line items.",
        "error"
      );
    } else {
      // console.log("form", form)
      sendInvoiceHandler()
    }
  }


  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <h2 className="modal-title">RAISE INVOICE</h2>
      </Modal.Header>
      {loadingJobServices && <Loader />}
      <div className="modal-body px-md-5">
        <form>
          <div className="form-group row mb-3">
            <label className="col-3">Invoice Type</label>
            <div className="col-8">
              <select name="invoice_type" className="form-control custom-select" value={form.invoice_type} onChange={handleChange}>
                {invoiceTypeOptions.map(option => (
                  <option key={option.value} value={option.value}>{option.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-group row mb-3">
            <label className="col-3">Job Service</label>
            <div className="col-8">
              <Select
                disabled={loadingJobServices}
                name="job_service_ids"
                isMulti
                options={jobServicesOptions}
                value={jobServicesOptions.filter(service => form.job_service_ids.includes(service.value))}
                onChange={handleChange} />
            </div>
          </div>
          <div className="form-group row mb-3">
            <label className="col-3">Xero Account</label>
            <div className="col-8">
              <select name="xero_account" className="form-control custom-select" value={form.xero_account} onChange={handleChange}>
                {
                  xeroAccounts.map(account => (
                    <option key={account.value} value={account.value}>{account.label}</option>
                  ))
                }
              </select>
            </div>
          </div>
          <div className="form-group row mb-3">
            <label className="col-3">Invoice To</label>
            <div className="col-8">
              <Select
                defaultValue={contactOptions.find(person => person.type === "Invoice") || contactOptions.find(person => person.type === "Main Person")}
                name="invoice_to_id"
                options={invoiceToOptions}
                onChange={(e, a) => handleChange(e, a)} />
            </div>
          </div>
          {showCheckBoxIncludeDocket && docketOptions.length > 0 && (
            <div className="form-group row mb-3">
              <label className="col-3">Include Dockets</label>
              <div className="col-8">
                <input
                  checked={form.include_docket}
                  type="checkbox"
                  name="include_docket"
                  onChange={(e, a) => {
                    handleChange(e, a)
                  }} />
              </div>
            </div>
          )}
          {form.include_docket && (
            <div className="form-group row mb-3">
              <label className="col-3">Dockets</label>
              <div className="col-8">
                <Select
                  name="docket_ids"
                  isMulti
                  options={docketOptions}
                  onChange={(e, a) => handleChange(e, a)}
                  value={docketOptions.filter(docket => form.docket_ids.includes(docket.value))} />
              </div>
            </div>
          )}
        </form>
        <hr />
        <InvoiceLineItems
          invoiceLineItems={invoiceLineItems}
          setInvoiceLineItems={setInvoiceLineItems} />
        <hr />
        <div className="form-group row mb-3">
          <label className="col-3">Invoice Comment</label>
          <div className="col-8">
            {invoiceComments}
          </div>
        </div>
        <div className="modal-footer d-flex justify-content-end px-0 py-3">
          <button
            className="btn btn-red btn-lg"
            onClick={validateForm}
            disabled={buttonDisabled || promiseInProgress || loadingJobServices}
            type="submit">
            {promiseInProgress ? (
              <span>
                Creating...
                <Spinner animation="border" role="status" size="sm" />
              </span>
            ) : (
              `Create`
            )}
          </button>
        </div>
      </div>

    </Modal>
  )
}
export default GenerateInvoiceModal
