export const OPERATIONS = "operations"
export const ACCOUNTS = "accounts"
export const REGISTERED_SURVEYORS = "registered_surveyors"
export const DRAFTING = "drafting"
export const RECEPTION = "reception"
export const SURVEYORS = "surveyors"
export const SALES = "sales"
export const ADMIN = "admin"
export const DRAFTING_OVERSEAS = "drafting_overseas"
export const DRAFTING_OVERSEAS_LEBANON = "3d_drafting_overseas_lebanon"
export const DRAFTING_3D = "3d_drafting"
export const DRAFTING_OVERSEAS_PHILIPPINES = "3d_drafting_overseas_philippines"
export const ASSET_ONLY = "asset_only"
export const PROCESSOR = "processor"


// Team roles
export const RECEPTION_MANAGER = "reception_manager"
export const RECEPTION_USER = "reception_user"
export const OPERATION_MANAGER = "operations_manager"
export const SURVEYORS_USER = "surveyors_user"
export const SURVEYORS_MANAGER = "surveyors_manager"
export const DRAFTING_OVERSEAS_MANAGER = "drafting_overseas_manager"
export const DRAFTING_OVERSEAS_USER = "drafting_overseas_user"
export const DRAFTING_3D_MANAGER = "3d_drafting_manager"
export const DRAFTING_3D_USER = "3d_drafting_user"
export const DRAFTING_3D_OVERSEAS_LEBANON_MANAGER = "3d_drafting_overseas_lebanon_manager"
export const DRAFTING_3D_OVERSEAS_PHILIPPINES_MANAGER = "3d_drafting_overseas_philippines_manager"
export const DRAFTING_3D_OVERSEAS_LEBANON_USER = "3d_drafting_overseas_lebanon_user"
export const DRAFTING_3D_OVERSEAS_PHILIPPINES_USER = "3d_drafting_overseas_philippines_user"
export const REGISTERED_SURVEYORS_MANAGER = "registered_surveyors_manager"
export const REGISTERED_SURVEYORS_USER = "registered_surveyors_user"
export const DRAFTING_USER = "drafting_user"
export const ACCOUNTS_MANAGER = "accounts_manager"
export const DRAFTING_MANAGER = "drafting_manager"
export const PROCESSOR_MANAGER = "processor_manager"

export const USER = "user"
export const MANAGER = "manager"

// Activities
export const TO_CONFIRM = "To Confirm"
export const FIELD_WORK = "Field Work"
export const TO_INVOICE = "To Invoice"
export const PENDING_PAYMENT = "Pending Payment"
export const TO_DRAFT = "To Draft"
export const QA = "Qa"
export const OVERSEAS = "Overseas"

// Job Service states
export const PENDING = "pending"
export const TO_INVOICE_STATE = "to_invoice"
export const JOB_PACK_INCOMPLETE = "job_pack_incomplete"
export const DATA_OVERDUE = "data_overdue"
export const OVERSEAS_STATE = "overseas_drafting_assignment_required"
export const TO_OVERSEAS_DRAFTING = "to_overseas_drafting"
export const OVERSEAS_QA = "overseas_qa"
export const OVERSEAS_STATES_ALLOWED = ["overseas_drafting_assignment_required", "overseas_qa", "to_overseas_drafting"]
export const OVERSEAS_USER_STATES_ALLOWED = "to_overseas_drafting"
export const CONFIRMED = "confirmed"
export const GPS_REQUESTED = "gps_requested"
export const GPS_DECLINED = "gps_declined"
export const GPS_APPROVED = "gps_approved"
export const REQUESTED_CADASTRAL = "cadastral_requested"
export const CADASTRAL_DECLINED = "cadastral_declined"
export const CADASTRAL_APPROVED = "cadastral_approved"
export const FIELD_WORK_STATES = ["to_confirm", "confirmed", "gps_requested", "gps_approved", "gps_declined", "field_work_ongoing", "field_work_finished", "data_overdue", "job_pack_incomplete", "cadastral_requested", "cadastral_declined", "cadastral_approved"]
export const FIELD_WORK_STATE = "field_work_ongoing"
export const DRAFTING_STATES = ["drafting_assignment_required", "to_local_drafting", "overseas_drafting_assignment_required", "overseas_qa", "to_overseas_drafting", "local_qa"]
export const LOCAL_QA = "local_qa"
export const REVIEW_REPORT = "review_report"
export const TO_LOCAL_DRAFTING = "to_local_drafting"
export const ADDITIONAL_INFO = "additional_info"
export const FIELD_WORK_FINISHED = "field_work_finished"
export const RESCHEDULE_STATES_ALLOWED = ["confirmed", "field_work_ongoing", "gps_requested", "gps_declined", "gps_approved", "job_pack_incomplete", "field_work_incompleted"]
export const CANCELLED = "cancelled"
export const ON_HOLD = "on_hold"
export const JOB_SENT = "job_sent"
export const CLIENT_DELAYED = "client_delayed"
export const TO_REVIEW = "to_review"
export const READY_TO_SEND = "ready_to_send"
export const AWAITING_PAYMENT = "awaiting_payment"
export const RESCHEDULED = "rescheduled"
export const JOB_ON_HOLD_STATES_NOT_ALLOWED = [CANCELLED, "job_sent", ON_HOLD, CLIENT_DELAYED]
export const ALLOWED_TO_BOOK_IN_CHECK_SURVEY_STATES = ["ready_to_send", "awaiting_payment", "to_invoice"]
export const UNPROCESSED_FROM_NAVVIS = "unprocessed_from_navvis"
export const PROCESSING_IN_NAVVIS = "processing_in_navvis"
export const PROCESSING_STATES = [UNPROCESSED_FROM_NAVVIS, PROCESSING_IN_NAVVIS]

// invoice states
export const INVOICE_PENDING = "invoice_pending"
export const PAID_DEPOSIT = "paid_deposit"
export const FINAL_INVOICE_DRAFTED = "final_invoice_drafted"
export const DEPOSIT_INVOICE_DRAFTED = "deposit_invoice_drafted"
export const INVOICE_AWAITING_PAYMENT_STATES = [FINAL_INVOICE_DRAFTED, DEPOSIT_INVOICE_DRAFTED, PAID_DEPOSIT]

// Job service state transitions
export const MARK_AS_PAID = "mark-as-paid"
export const SET_TO_READY_TO_SEND = "set-to-ready-to-send"
export const SET_TO_INVOICE = "set-to-invoice"
export const REQUEST_GPS = "request-gps"
export const DECLINE_GPS = "decline-gps"
export const APPROVE_GPS = "approve-gps"
export const SET_TO_FIELD_WORK = "set-to-field-work"
export const SET_TO_GPS_COMPLETED = "set-to-gps-completed"
export const SET_TO_OVERSEAS_QA = "set-to-overseas-qa"
export const ASSIGN_OVERSEAS_MANAGER = "assign_overseas_manager"
export const REQUEST_CHECK_SURVEY = "request-check-survey"
export const REQUEST_CADASTRAL = "request-cadastral"
export const DECLINE_CADASTRAL = "decline-cadastral"
// Product codes
export const GPS_AHD = "GPS-AHD"
export const GPS_MGA = "GPS-MGA"
export const PROJECT_SETOUT = "PRO-SET"
export const CHECK_SURVEY = "CHK"

export const TEAMS_MANAGERS = [
  OPERATION_MANAGER,
  // ACCOUNTS_MANAGER,
  REGISTERED_SURVEYORS_MANAGER,
  // "sales_manager",
  DRAFTING_MANAGER,
  // RECEPTION_MANAGER,
  "admin_manager",
  DRAFTING_3D_MANAGER,
]

export const JOB_STATES_DISPLAY = {
  to_book: {
    text: "to_book",
    bgClass: "bg-light-blue"
  },
  paid: {
    text: "to_confirm",
    bgClass: "bg-light-blue"
  },
  invitation_sent: {
    text: "invitation_sent",
    bgClass: "bg-light-blue"
  },
  confirmed: {
    text: "Confirmed",
    bgClass: "bg-green"
  },
  to_confirm: {
    text: "To Confirm",
    bgClass: "bg-light-blue",
    state_name: "to_confirm",
  },
  pending: {
    text: "Pending",
    bgClass: "bg-yellow"
  },
  data_overdue: {
    text: "Data Overdue",
    bgClass: "bg-coral"
  },
  additional_info: {
    text: "Additional Info",
    bgClass: "bg-coral"
  },
  additional_info_operations: {
    text: "Additional Info - Operations",
    bgClass: "bg-coral"
  },
  additional_info_drafting: {
    text: "Additional Info - Drafting",
    bgClass: "bg-coral"
  },
  additional_info_rs: {
    text: "Additional Info - Registered Surveyor",
    bgClass: "bg-coral"
  },
  preferred_booking_date: {
    text: "Deposit",
    bgClass: "bg-coral"
  },
  job_pack_incomplete: {
    text: "Job Pack Overdue",
    bgClass: "bg-coral"
  },
  to_review: {
    text: "To Review",
    bgClass: "bg-light-blue",
    btnClass: "btn-light-blue"
  },
  drafting_assignment_required: {
    text: "Drafting Required",
    bgClass: "bg-light-blue",
    btnClass: "bg-light-blue"
  },
  to_local_drafting: {
    text: "Local Drafting",
    bgClass: "bg-light-blue",
    btnClass: "bg-light-blue"
  },
  to_overseas_drafting: {
    text: "Drafting Overseas Required",
    bgClass: "bg-light-blue",
    btnClass: "bg-light-blue"
  },
  local_qa: {
    text: "QA Required",
    bgClass: "bg-light-blue",
    btnClass: "bg-light-blue"
  },
  overseas_drafting_assignment_required: {
    text: "Overseas",
    bgClass: "bg-yellow",
    btnClass: "btn-yellow"
  },
  ready_to_send: {
    text: "Ready To Send",
    bgClass: "bg-green",
    btnClass: "btn-green"
  },
  gps_requested: {
    text: "GPS Requested",
    bgClass: "bg-coral",
  },
  gps_approved: {
    text: "GPS Approved",
    bgClass: "bg-coral",
  },
  gps_declined: {
    text: "GPS Declined",
    bgClass: "bg-coral",
  },
  gps_completed: {
    text: "GPS Completed",
    bgClass: "bg-green",
  },
  check_survey_requested: {
    text: "Check Survey Requested",
    bgClass: "bg-coral",
  },
  check_survey_completed: {
    text: "Check Survey Completed",
    bgClass: "bg-green",
  },
  field_work: {
    text: "Field Work",
    bgClass: "bg-green",
  },
  overseas_qa: {
    text: "Overseas QA",
    bgClass: "bg-light-blue",
    btnClass: "bg-light-blue"
  },
  review_report: {
    text: "Report Required",
    bgClass: "bg-coral",
    btnClass: "bg-coral"
  },
  job_sent: {
    text: "Job Sent",
    bgClass: "bg-green",
  },
  to_invoice: {
    text: "To Invoice",
    bgClass: "bg-light-blue",
    btnClass: "bg-light-blue"
  },
  field_work_ongoing: {
    text: "Field Work Ongoing",
    bgClass: "bg-green",
  },
  field_work_finished: {
    text: "Field Work Finished",
    bgClass: "bg-green",
  },
  unprocessed_from_navvis: {
    text: "Processing",
    bgClass: "bg-green",
  },
  processing_in_navvis: {
    text: "Processing",
    bgClass: "bg-green",
  },
  awaiting_payment: {
    text: "Awaiting Payment",
    bgClass: "bg-coral",
  },
  awaiting_payment_deposit: {
    text: "Awaiting Payment (Deposit)",
    bgClass: "bg-coral",
  },
  awaiting_payment_final: {
    text: "Awaiting Payment (Final)",
    bgClass: "bg-coral",
  },
  rescheduled: {
    text: "Rescheduled",
    bgClass: "bg-coral",
  },
  cancelled: {
    text: "Cancelled",
    bgClass: "bg-yellow",
  },
  completed: {
    text: "Completed",
    bgClass: "bg-green",
  },
  field_work_incompleted: {
    text: "Field Work Incompleted",
    bgClass: "bg-coral",
  },
  on_hold: {
    text: "On Hold",
    bgClass: "bg-coral",
  },
  client_delayed: {
    text: "Client Delayed",
    bgClass: "bg-coral",
  },
  resolved_book_in_flag: {
    text: "Resolved",
    bgClass: "bg-green",
  },
  create_deliverables: {
    text: "Create Deliverables",
    bgClass: "bg-coral"
  },
  cadastral_requested: {
    text: "Cadastral Requested",
    bgClass: "bg-coral"
  },
  cadastral_declined: {
    text: "Cadastral Declined",
    bgClass: "bg-coral"
  }
}

export const INVOICE_STATES = {
  invoice_pending: {
    text: "To Invoice",
    bgClass: "bg-light-blue",
    api: "",
    btnClass: "btn-light-blue"
  },
  deposit_invoice_drafted: {
    text: "Awaiting payment (Deposit)",
    bgClass: "bg-coral",
    api: "",
    btnClass: "btn-coral"
  },
  final_invoice_drafted: {
    text: "Awaiting payment (Final)",
    bgClass: "bg-coral",
    api: "",
    btnClass: "btn-coral"
  },
  paid_deposit: {
    text: "Deposit Paid",
    bgClass: "bg-green",
    api: "",
    btnClass: "btn-green"
  },
  paid_full: {
    text: "Fully Paid",
    bgClass: "bg-green",
    api: "",
    btnClass: "btn-green"
  },
  awaiting_payment: {
    text: "Awaiting Payment",
    bgClass: "bg-coral",
    api: "",
    btnClass: "btn-coral"
  },
  pay_before_drafting: {
    text: "Invoice Sent (Pay Before Drafting)",
    bgClass: "bg-green",
    api: "",
    btnClass: "btn-green"
  },
  collect_payment_onsite_requested: {
    text: "Invoice Sent (Collect Payment Onsite)",
    bgClass: "bg-green",
    api: "",
    btnClass: "btn-green"
  }
};

export const FIELD_WORK_FILES = [
  {
    label: "Field Notes*",
    value: "Field Notes"
  },
  {
    label: "Magnet File*",
    value: "Magnet File"
  },
  {
    label: "Raw Data*",
    value: "Raw Data"
  },
  {
    label: "Other Surveying",
    value: "Other Surveying"
  },
  {
    label: "SCIMS",
    value: "SCIMS"
  },
]

export const FILE_TYPES = [
  {
    label: "Drafted Documents",
    value: "Drafted Documents"
  },
  {
    label: "Field Notes",
    value: "Field Notes"
  },
  {
    label: "Magnet File",
    value: "Magnet File"
  },
  {
    label: "Other",
    value: "Other"
  },
  {
    label: "Photo",
    value: "Photo"
  },
  {
    label: "Raw Data",
    value: "Raw Data"
  },
  {
    label: "RS Report",
    value: "RS Report"
  },
  {
    label: "Signed Plans",
    value: "Signed Plans",
  },
  {
    label: "Signed Sketch",
    value: "Signed Sketch",
  }
]


export const JOB_PACK_FILE_TYPES = [
  {
    label: "Certificate Of Title (CT)",
    value: "Certificate Of Title (CT)"
  },
  {
    label: "Deposited Plan/Strata Plan/Crown Plan",
    value: "Deposited Plan/Strata Plan/Crown Plan"
  },
  {
    label: "Cadastral Records Enquiry (CRE)",
    value: "Cadastral Records Enquiry (CRE)"
  },
  {
    label: "Dial Before You Dig (DBYD)",
    value: "Dial Before You Dig (DBYD)"
  },
]

export const FILE_CATEGORIES = ["88B/88E", "Administration Sheets", "Certificate Of Title (CT)", "Cadastral Records Enquiry (CRE)", "Correspondence", "DA/CDC Conditions", "Deposited Plan/Strata Plan/Crown Plan", "Dealings/Section 88B", "Dial Before You Dig (DBYD)", "Drafted Documents", "Field Notes", "Flag File", "Flag Photo", "Magnet File", "Master Boundary File", "Other Drafting", "Other Surveying", "Photo", "PPN", "Pre-calc", "Raw Data", "Received Plans", "RS Report", "SCIMS", "Signed Plans", "Signed Sketch", "RS Checks", "Other RS"]

export const MASTER_BOUNDARY_FILE = "Master Boundary File"

export const FILE_GROUPS = {
  drafting_files: ["Drafted Documents", "Drafting Markup", "Other Drafting"],
  flag_files: ["Flag File", "Flag Photo"],
  job_instructions: ["Correspondence", "DA/CDC Conditions", "Pre-calc", "Received Plans", "Quote", "Quote Acceptance", "Pipedrive", "Master Boundary File"],
  photos: ["Photo"],
  registered_surveyor_files: ["88B/88E", "Administration Sheets", "Master Boundary File", "PPN", "RS Report", "Signed Plans", "Signed Sketch", "RS Checks", "Other RS"],
  released_files: [],
  searches: ["Certificate Of Title (CT)", "Cadastral Records Enquiry (CRE)", "Dealings/Section 88B", "Deposited Plan/Strata Plan/Crown Plan", "Dial Before You Dig (DBYD)"],
  surveyor_files: ["Field Notes", "Magnet File", "Raw Data", "Other Surveying", "SCIMS"]
}

export const RECEIVED_PLANS_CATEGORY = "Received Plans"

export const stringEllipsis = (string, maxLength, sliceIndex) => {
  if (string?.length > maxLength) {
    return `${string.slice(0, sliceIndex)}...`;
  }
  return string;
};

export const CONTACT_TYPES = [
  { value: "person", label: "Main Person", title: "contactType" },
  { value: "participant", label: "Participant", title: "contactType" },
  { value: "invoice", label: "Invoice", title: "contactType" },
]

export const INVOICE_TYPES = [
  { value: "deposit", label: "Deposit", title: "Deposit" },
  { value: "final", label: "Final", title: "Final" }
]

export const XERO_INVOICE_STATUSES = ["DRAFT", "SUBMITTED", "AUTHORISED", "PAID", "VOIDED"]

export const GPS_STATES = {
  field_work_ongoing: "GPS Complete",
  field_work_finished: "GPS Complete",
  gps_requested: "Job Pending GPS Approval from Operations",
  gps_approved: "Approved - Job Pending GPS",
  gps_declined: "GPS Request Declined"
}

export const createFileOptions = arr => {
  const fileOptions = arr?.map(i => ({ label: i, value: i }))
  return fileOptions
}

export const FILE_RELEASE_1_CATEGORIES = ["Signed Plans", "Signed Sketch", "RS Report", "Photos", "Drafted Documents", "Certificate Of Title (CT)", "Deposited Plan/Strata Plan/Crown Plan", "Dealings/Section 88B"]
export const FILE_RELEASE_2_CATEGORIES = ["Signed Plans", "Signed Sketch", "RS Report"]
export const FILE_RELEASE_3_CATEGORIES = ["Administration Sheets", "Drafted Documents"]
export const FILE_RELEASE_4_CATEGORIES = ["PPN", "88B/88E"]

export const PLAN_OF_DELIMITATION_RELEASING_CONTENT = `
  <p>Your next step is to submit to the owners for sign off on page 2 of the administration sheets, they will need to arrange for their mortgagee to sign as well on this page if there is a mortgage on the property.</p>
  <p><i>Upon examination with NSW LRS they may request one or more adjoining owners to consent to the location of the new boundaries. If this is required, we will provide you with a copy of the plan for each neighbour with a place for them to sign and date along with a letter to assist with discussions with the neighbours.</i></p>
  <p>Once you have all the signatures, please provide the signed administration sheets back to our office and we can lodge them electronically to NSW LRS for registration of the Plan, this is a turnaround of approx. 4 weeks with NSW LRS to examine and register the Plan.</p>
  <p>Our fee to lodge the plan is $600+GST, this does not include LRS’s fee which will be obtained separately once you are ready for lodgement. You can visit NSW LRS’s fee schedule by following this link and downloading their fee schedule, refer to page 5 of their fee schedule for the Titling and Plan Services.</p>
  <a href="https://www.nswlrs.com.au/Fees" target="_blank">https://www.nswlrs.com.au/Fees</a>
  <p><u>It is recommended that you arrange for your plan to be lodged ASAP for registration otherwise the boundaries may change if there is a newly registered plan nearby.</u></p>
  <p style="color: red;"><i style="color: red;">Please note this plan has a 2 year currency period. If your plan exceeds the 2 year period from the date it was signed by the registered surveyor it will require a Certificate of Currency to be lodged with the plan to NSW LRS. If this is required there will be an additional fee from C & A Surveyors to return to site and update the plan accordingly if needed and provide the certificate. For further information on this you can visit NSW LRS’s website.</i></p>
  <p><a href="https://www.nswlrs.com.au/About/About/Announcements/101" target="_blank"><i>https://www.nswlrs.com.au/About/About/Announcements/101</i></a></p>
`
export const PLAN_OF_REDEFINITION_OR_PLAN_OF_CONSOLIDATION_RELEASING_CONTENT = `
  <p>Your next step is to submit to the owners for sign off on page 2 of the administration sheets, they will need to arrange for their mortgagee to sign as well on this page if there is a mortgage on the property.</p>
  <p>Once you have all the signatures, please provide the signed administration sheets back to our office and we can lodge them electronically to NSW LRS for registration of the Plan, this is a turnaround of approx. 4 weeks with NSW LRS to examine and register the Plan.</p>
  <p>Our fee to lodge the plan is $600+GST, this does not include LRS’s fee which will be obtained separately once you are ready for lodgement. You can visit NSW LRS’s fee schedule by following this link and downloading their fee schedule, refer to page 5 for the Titling and Plan Services</p>
  <a href="https://www.nswlrs.com.au/Fees" target="_blank">https://www.nswlrs.com.au/Fees</a>
  <p><u>It is recommended that you arrange for your plan to be lodged ASAP for registration otherwise the boundaries may change if there is a newly registered plan nearby.</u></p>
  <p style="color: red;"><i style="color: red;">Please note this plan has a 2 year currency period. If your plan exceeds the 2 year period from the date it was signed by the registered surveyor it will require a Certificate of Currency to be lodged with the plan to NSW LRS. If this is required there will be an additional fee from C & A Surveyors to return to site and update the plan accordingly if needed and provide the certificate. For further information on this you can visit NSW LRS’s website.</i></p>
  <p><a href="https://www.nswlrs.com.au/About/About/Announcements/101" target="_blank"><i>https://www.nswlrs.com.au/About/About/Announcements/101</i></a></p>
`
export const PLAN_OF_SUBDIVISION_RELEASING_CONTENT = `
  <p>Your next step is to submit to the council/private certifier through the NSW Planning Portal as a Subdivision Certificate Application for sign off. Alternatively, we can lodge the plan to council on your behalf for $650 + GST (excluding Council’s fee). There will be a checklist of items you will need to submit to the council/private certifier, you can obtain this checklist from councils’ website or your certifier. The turnaround time for execution by council can vary depending on their workload however is usually between 6-8 weeks.</p>
  <p>Once they have signed, please submit to the mortgagee for sign off on page 2 of the administration sheets and page 2 of the section 88B if there is a mortgage on the property followed by all owners signing in the spaces provided as well.</p>
  <p>Once you have all the signatures, please provide the signed documents back to our office and we can lodge them electronically to NSW LRS for registration of the Plan, this is a turnaround of approx. 4 weeks with NSW LRS to examine and register the Plan.</p>
  <p>Our fee to lodge the plan is $600+GST, this does not include LRS’s fee which will be obtained separately once you are ready for lodgement. You can visit NSW LRS’s fee schedule by following this link and downloading their fee schedule, refer to page 5 for the Titling and Plan Services.</p>
  <a href="https://www.nswlrs.com.au/Fees" target="_blank">https://www.nswlrs.com.au/Fees</a>
  <p><u>It is recommended that you arrange for your plan to be lodged ASAP for registration otherwise the boundaries may change if there is a newly registered plan nearby.</u></p>
  <p style="color: red;"><i style="color: red;">Please note this plan has a 2 year currency period. If your plan exceeds the 2 year period from the date it was signed by the registered surveyor it will require a Certificate of Currency to be lodged with the plan to NSW LRS. If this is required there will be an additional fee from C & A Surveyors to return to site and update the plan accordingly if needed and provide the certificate. For further information on this you can visit NSW LRS’s website.</i></p>
  <p><a href="https://www.nswlrs.com.au/About/About/Announcements/101" target="_blank"><i>https://www.nswlrs.com.au/About/About/Announcements/101</i></a></p>
`
// eslint-disable-next-line no-underscore-dangle
export const _88E_OR_88E_EASE_RELEASING_CONTENT = `
  <p>Your next step is to submit to the council through the NSW Planning Portal as a Section 88E Application for sign off. Alternatively, we can lodge the 88Es to council on your behalf for $650 + GST (excluding Council’s fee). There will be a checklist of items you will need to submit to the council, you can obtain this checklist from councils’ website. The turnaround time for execution by council can vary depending on their workload however is usually between 6-8 weeks. Please note that some councils only accept this application through email and not the planning portal.</p>
  <p>Once they have signed, please submit to the mortgagee for sign off followed by all owners signing in the spaces provided as well.</p>
  <p>Once you have all the signatures, please provide the signed 88Es to your solicitor/conveyancer to lodge them electronically to NSW LRS for registration on the title.</p>
`
export const DOCKET_STATUS = {
  new: { label: "New", bgColor: "bg-light-blue", color: "grey" },
  signed: { label: "On Review", bgColor: "bg-coral", color: "orange" },
  no_signature: { label: "On Review", bgColor: "bg-coral", color: "orange" },
  reviewed: { label: "Reviewed", bgColor: "bg-green", color: "green" },
  invoiced: { label: "Invoiced", bgColor: "bg-green", color: "green" },
}
export const PRODUCT_CODE_NEED_SCIMS = ["3D-DETID", "3D-DETID+INT", "3D-DET", "3D-DET+INT", "P-3D-DETID", "P-3D-DETID+INT", "P-3D-DET", "P-3D-DET+INT", "DET-BD", "DET-ID", "DET-ID+INT", "DET", "DET+INT", "P-DETID", "P-DETID+INT", "P-DET", "P-DET+INT", "V-DETID", "V-DET"]

export const PRODUCT_CODE_NEED_MASTER_BOUNDARY_FILE = ["CONSOL", "DELIM", "EASE", "SUB", "REDEF"]

export const PRODUCT_CODE_REQUIRED_REPORT = ["3D-DETID", "3D-DETID+INT", "DET-ID", "DET-ID+INT", "FIN", "FIN-GNY", "FIN-HOU", "ID", "P-3D-DETID", "P-3D-DETID+INT", "P-DETID", "P-DETID+INT", "V-DETID"]

export const PRODUCT_CODE_FULL_PAYMENT = ["LDG-CCL", "LDG-LRS", "SEARCH"]

export const PRODUCT_CODE_PASS_THE_RESOLVE_FLAGGING_STEP = ["CONSOL", "DELIM", "EASE", "REDEF", "SUB"]

export const PRODUCT_CODE_BCR = ["ID+SET-BDY", "SET", "SET-BDY", "SET-GNY", "SET-HOU", "SET-REP", "SITE"]

export const MAX_SIZE_UPLOADED_FILE = 200000000

export const MAX_SIZE_UPLOADED_FILE_TEXT = "200MB"

export const PRODUCT_CODE_RS_CAN_VIEW_READY_TO_SEND = ["CONSOL", "DELIM", "EASE", "PLAN-REQ", "REDEF", "STRATA", "STUM", "SUB", "88B", "88E", "88E-EASE", "FO-SEC-88E-88B"]

export const PRODUCT_CODE_REQUIRE_PLANS_USED_FOR_CALCULATIONS = ["SET-GNY", "SET-HOU", "SET-REP", "SITE", "PRO-SET", "SET-DUP", "SET-EXT"]

export const PRODUCT_CODE_PRELIM_WAE = ["WAE-ADD", "WAE-PUD", "WAE-SEW", "WAE-STW", "WAE-SUB", "ADD-INFO-DRAFT"]

export const DEFAULT_PER_PAGE = 25

export const FLAGGED_PAGE_DEFAULT_PER_PAGE = 30

export const MARK_AS_TYPES = {
  not_set: "not_set",
  plans_used_for_calc: "plans_used_for_calc"
}

export const CANCELLED_LOG = {
  event: "cancel_booking",
  nextState: "cancelled"
}

export const RESCHEDULED_LOG = {
  event: "reschedule",
  nextState: "rescheduled"
}

export const ON_HOLD_LOG = {
  event: "job_on_hold",
  nextState: "on_hold"
}

export const WORKFLOW_STEPS = {
  to_book: ["bg-green", "Field Work", 1], // [bgColorClassName, headerName, stageNumber]
  preferred_booking_date: ["bg-green", "Field Work", 1],
  confirmed: ["bg-green", "Field Work", 1],
  to_local_drafting: ["bg-light-blue", "Drafting", 3],
  local_qa: ["bg-light-blue", "Drafting", 3],
  overseas_drafting_assignment_required: ["bg-light-blue", "Drafting", 3],
  to_overseas_drafting: ["bg-light-blue", "Drafting", 3],
  drafting_assignment_required: ["bg-light-blue", "Drafting", 3],
  to_review: ["bg-light-blue", "Registered Surveyor", 3],
  data_overdue: ["bg-coral", "Data Overdue", 1],
  additional_info: ["bg-coral", "Additional Info", 1], // to be changed with the previous state of the job
  completed: ["bg-green", "Completed", 4],
  ready_to_send: ["bg-green", "Ready to Send", 4],
  to_invoice: ["bg-green", "Payment Required", 4],
  paid_invoice: ["bg-green", "Final Payment", 4],
  sent: ["bg-green", "Final Payment", 4],
  pending: ["bg-yellow", "Deposit", 0],
  imported: ["bg-yellow", "Deposit", 0],
  checked_in: ["bg-green", "Checked In", 3],
  to_confirm: ["bg-light-blue", "Field Work", 1],
  job_pack_incomplete: ["bg-coral", "Job Packs Incomplete", 1],
  awaiting_payment: ["bg-coral", "Awaiting Payment", 1],
  check_survey_requested: ["bg-coral", "Check Survey Requested", 1],
  gps_requested: ["bg-coral", "Field Work", 1],
  gps_approved: ["bg-green", "Field Work", 1],
  gps_declined: ["bg-green", "Field Work", 1],
  gps_completed: ["bg-green", "Field Work", 1],
  check_survey_completed: ["bg-green", "Check Survey Completed", 1],
  field_work_ongoing: ["bg-green", "Field Work", 1],
  field_work_finished: ["bg-green", "Field Work", 1],
  unprocessed_from_navvis: ["bg-green", "Processing", 2],
  processing_in_navvis: ["bg-green", "Processing", 2],
  overseas_qa: ["bg-light-blue", "Drafting", 3],
  review_report: ["bg-coral", "Registered Surveyor", 3],
  job_sent: ["bg-green", "Job Sent", 4],
  rescheduled: ["bg-coral", "Rescheduled", 1],
  cancelled: ["bg-yellow", "Cancelled", 0],
  field_work_incompleted: ["bg-coral", "Field Work Incompleted", 1],
  on_hold: ["bg-coral", "On Hold", 1],
  client_delayed: ["bg-coral", "Client Delayed", 1],
  resolved_book_in_flag: ["bg-green", "Field Work", 1],
  create_deliverables: ["bg-light-blue", "Drafting", 3],
  cadastral_requested: ["bg-coral", "Field Work", 1],
  cadastral_declined: ["bg-green", "Field Work", 1],
}

export const NEXT_VISIT_SITE_NOTES = "Note for Next Site Visit Added"

export const NEARBY_JOB_GG_MAP_STYLES = [
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "transit.station.bus",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "transit.station.rail",
    stylers: [{ visibility: "off" }]
  }
]
export const NEARBY_JOB_GG_MAP_MARKER_STYLES = {
  position: "absolute",
  width: "100%",
  height: "calc(100% + 24px)",
  top: "-12px",
  left: "-12px"
}

export const GEAR_CATEGORIES_REQUIRED_INSPECTION = ["car", "instrument", "controller"]

export const TASK_TYPES = {
  site_visit: 0,
  reminder: 1,
  quote_assistance: 2,
  boundary_fix: 3,
  to_review: 4,
  call_client: 5
}
export const TASK_STATUSES = {
  initial: "New",
  // pending: "Pending",
  // working: "Working on",
  completed: "Completed",
  not_completed: "All Not Completed"
}
export const PRODUCT_CODE_REQUIRE_SCANNER_CADASTRAL = ["DET-BD", "DET-ID", "DET-ID+INT", "P-DETID", "P-DETID+INT", "STRATA", "V-DETID"]
