import { format } from "date-fns";
import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { getUploadedFile } from "requesters/uploadsRequester";
import { getCurrentRole } from "../../../reducers";
import EditFileModal from "../modals/EditFileModal";
import SendReleasedFilesEmailModal from "../modals/SendReleasedFilesEmailModal";
import DeleteFile from "./DeleteFile";
import SupersededFile from "./SupersededFile";
import { RECEIVED_PLANS_CATEGORY } from "../../../../../utils/constants";
import MarkFileAsUsedPlansForCalc from "./MarkFileAsUsedPlansForCalc";
import FileIcon from "./fileRow/FileIcon";

export default function FileRow({
  file,
  reloadData,
  canUpdate,
  fileOptions,
  isDeleted,
  fileGroup,
  getCheckedFiles,
  jobService,
  showSuperseded,
  selectedFileIds,
  isDisableSendToClient
}) {
  const {
    attributes: {
      createdByUser,
      fileTrackedUrl,
      fileName,
      documentCategory,
      isPlansUsedForCalc,
      updatedAt,
      name,
      discardedAt,
      discardedByUser,
      superseded,
      isExpired,
      extractMsg
    }
  } = file.data

  const currentTeams = useSelector(getCurrentRole)
  const [showEditFiletModal, setShowEditFiletModal] = useState(false)
  const isJobInstructions = fileGroup === "Job Instructions"
  const isReadyForRelease = fileGroup === "Ready for Release"
  const isReleasedFiles = fileGroup === "Released Files"
  const isSearches = fileGroup === "Searches"
  const isPaidFull = jobService?.attributes?.invoiceState === "paid_full" || Number(jobService?.attributes?.cost) === 0
  const isAccounts = !(jobService?.fifty_fifty || jobService?.prior_to_release)
  const outstandingInvoiceFlagged = jobService?.attributes?.outstandingInvoiceFlagged
  const unmatchedInvoiceFlagged = jobService?.attributes?.unmatchedInvoiceFlagged
  const [showReleasedFilesModal, setShowReleasedFilesModal] = useState(false)
  const [loadingFileUrl, setLoadingFileUrl] = useState(false)
  const EXTRACT_MESSAGE = {
    extracting: {
      text: "Extracting file",
      color: "text-green"
    },
    error: {
      text: "Error extracting file",
      color: "text-danger"
    },
    unsupported: {
      text: "Unsupported file",
      color: "text-danger"
    }
  }

  const canShowQuoteAcceptanceFiles = ["admin", "accounts", "operations"].some(team => currentTeams.includes(team))
  if (isJobInstructions && !canShowQuoteAcceptanceFiles) {
    _.remove(fileOptions, o => o === "Quote Acceptance" || o === "Quote");
  }

  function attachAndDetachDownloadLink(downloadLink) {
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  async function handleLinkClick() {
    setLoadingFileUrl(true)
    const result = await getUploadedFile(fileTrackedUrl)
    if ("response" in result) {
      const extension = result?.response?.json?.extension || ""
      const download_url = result?.response?.json?.download_url
      const file_name = result?.response?.json?.file_name
      const downloadLink = document.createElement("a");
      const isUrlFile = extension && extension.toLowerCase() === "url"
      // const isQuoteCategory = documentCategory === "Quote"

      if (isUrlFile) {
        fetch(download_url)
          .then(data => data.text())
          .then(content => {
            const lines = content?.split("\n") || []
            const urlLine = lines.find(l => l.toLowerCase().includes("url="))
            const url = urlLine?.split("=")?.pop()
            downloadLink.href = url;
            downloadLink.setAttribute("target", "_blank")
            attachAndDetachDownloadLink(downloadLink)
            setLoadingFileUrl(false)
          })
      } else if (extension && extension.toLowerCase() === "xml") {
        fetch(download_url)
          .then(data => data.blob())
          .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            downloadLink.href = url;
            downloadLink.setAttribute("download", file_name)
            attachAndDetachDownloadLink(downloadLink)
            setLoadingFileUrl(false)
          })
          .catch(() => {
            setLoadingFileUrl(false)
          })
      } else {
        downloadLink.href = download_url;
        downloadLink.setAttribute("download", file_name)
        downloadLink.setAttribute("target", "_blank")
        attachAndDetachDownloadLink(downloadLink)
        setLoadingFileUrl(false)
      }
    } else {
      setLoadingFileUrl(false)
    }
  }

  const renderSendReleasedFilesEmailModal = useCallback(() => (
    <SendReleasedFilesEmailModal
      show={showReleasedFilesModal}
      onHide={() => setShowReleasedFilesModal(false)}
      jobService={jobService}
      file={file} />
  ), [jobService, showReleasedFilesModal, file])

  const supersededStyle = { opacity: superseded ? 0.5 : 1 }
  const isReceivedPlans = isJobInstructions && documentCategory === RECEIVED_PLANS_CATEGORY
  const isUsedPlans = isReceivedPlans && isPlansUsedForCalc

  return (
    <>
      {isJobInstructions && !fileOptions.includes(documentCategory)
        ? <React.Fragment></React.Fragment>
        : (
          <tr className={`border-top border-bottom ${isSearches && isExpired ? "bg-coral" : ""} ${isUsedPlans ? "hightlighted hl-warning" : ""}`}>
            {(!(isReadyForRelease || isReleasedFiles)) && (
              <td width="2%" className="align-middle">
                {!superseded && (
                  <div className="ml-2 d-flex align-items-center">
                    <div className="custom-control custom-checkbox" key={file.data.id}>
                      <input
                        className="custom-control-input"
                        id={file.data.id}
                        type="checkbox"
                        checked={selectedFileIds.includes(file.data.id)}
                        onChange={() => getCheckedFiles(file)} />
                      <label className="custom-control-label" htmlFor={file.data.id}></label>
                    </div>
                  </div>
                )}
              </td>
            )}
            <td style={{ ...supersededStyle, maxWidth: 500 }} className="align-middle">
              <div className="row align-items-center">
                <FileIcon file={file} />
                <div className="col-10">
                  <Button
                    disabled={loadingFileUrl}
                    variant="link"
                    onClick={() => handleLinkClick()}
                    title={name || fileName}
                    className="text-truncate text-left">
                    {name || fileName}
                  </Button>
                  {extractMsg && (
                    <i style={{ fontSize: "0.85rem", fontWeight: 600 }} className={EXTRACT_MESSAGE[extractMsg]?.color}>
                      (
                      {EXTRACT_MESSAGE[extractMsg]?.text}
                      )
                    </i>
                  )}
                </div>
              </div>
            </td>
            <td style={{ ...supersededStyle, fontSize: "1rem" }} className="text-secondary text-center align-middle">
              {isReceivedPlans ? (
                <div className="d-flex align-items-center justify-content-start">
                  <MarkFileAsUsedPlansForCalc
                    file={file}
                    jobService={jobService}
                    reloadData={reloadData}
                    isUsedPlans={isUsedPlans} />
                  <span className="text-left">
                    {isUsedPlans ? "Plans Used for Calculations" : documentCategory}
                  </span>
                </div>
              ) : (
                documentCategory
              )}
            </td>
            <td style={{ ...supersededStyle, fontSize: "1rem" }} className="text-secondary text-center align-middle">{createdByUser}</td>
            <td style={{ ...supersededStyle, fontSize: "1rem" }} className="text-center text-secondary align-middle">
              <span className="mr-2">{format(new Date(updatedAt), "dd MMM yyyy, h:mm aa")}</span>
            </td>
            {(isReleasedFiles && (isPaidFull || isAccounts)) && (
              <td className="align-middle" style={{ fontSize: "1rem" }}>
                <button disabled={outstandingInvoiceFlagged || unmatchedInvoiceFlagged} type="button" className="btn btn-link" onClick={() => setShowReleasedFilesModal(true)}>
                  {file.data.attributes.releasedFileSentAt ? "Resend" : "Send to Client"}
                </button>
                {renderSendReleasedFilesEmailModal()}
              </td>
            )}
            {isDeleted && (
              <>
                <td style={{ fontSize: "1rem" }} className="text-secondary text-center align-middle">{discardedByUser}</td>
                <td style={{ fontSize: "1rem" }} className="text-secondary text-center align-middle">{format(new Date(discardedAt), "dd MMM yyyy, h:mm aa")}</td>
              </>
            )}
            {(canUpdate) && (
              <td style={{ fontSize: "1rem" }} className="align-middle">
                {(!isDeleted && !fileGroup) && (
                  <>
                    <button
                      className="btn btn-link btn-lg p-0 pb-1 mr-2"
                      onClick={() => setShowEditFiletModal(true)}
                      type="button">
                      Edit
                    </button>

                    <EditFileModal
                      file={file}
                      fileOptions={fileOptions}
                      show={showEditFiletModal}
                      reloadData={reloadData}
                      onHide={() => { setShowEditFiletModal(false) }} />
                  </>
                )}
                {(!isReleasedFiles || !file.data.attributes.releasedFileSentAt) && (
                  <DeleteFile
                    file={file}
                    reloadData={reloadData} />
                )}
              </td>
            )}
            {showSuperseded && (
              <td style={{ fontSize: "1rem" }} className="align-middle">
                <SupersededFile
                  reloadData={reloadData}
                  file={file}>
                </SupersededFile>
              </td>
            )}
          </tr>
        )
      }
    </>
  )
}
