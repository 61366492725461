import React, { useState } from "react"

const UserAvailabitlityDays = ({
  editState, title, availabitlities, updateAttr
}) => {
  const [localAvailabitlities, setLocalAvailabitlities] = useState(availabitlities)
  const checkedDays = Object.keys(localAvailabitlities).filter(day => localAvailabitlities[day]).map(day => day.slice(0, 1).toUpperCase() + day.slice(1, 3))
  const updateAvailabityHander = newAvailabitlities => {
    setLocalAvailabitlities({ ...localAvailabitlities, ...newAvailabitlities })
    updateAttr({ ...localAvailabitlities, ...newAvailabitlities })
  }
  if (editState) {
    return (
      <div className="row mb-2 mt-4">
        <div className="col-3">{title}</div>
        <div className="col-9">
          <ul className="list-unstyled d-flex flex-wrap">
            {Object.keys(availabitlities).map(day => (
              <li key={day}>
                <div className="custom-control custom-checkbox mr-2">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={localAvailabitlities[day]}
                    id={day}
                    onChange={event => updateAvailabityHander({ [day]: event.target.checked })} />
                  <label className="custom-control-label" htmlFor={day}>{day.slice(0, 1).toUpperCase() + day.slice(1, 3)}</label>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }

  return (
    <div className="row mb-2">
      <div className="col-3">{title}</div>
      <div className="col-9">
        <ul className="list-unstyled d-flex flex-wrap">
          {checkedDays.map(day => <li key={day} className="badge bg-warning mr-1">{day}</li>)}
        </ul>
      </div>
    </div>
  )
}

export default UserAvailabitlityDays
