import React from "react"
import GearHistoryRecord from "./GearHistoryRecord"

export default function GearHistoryRecordsTable({ gear_history_records }) {
    return (
      <div className="table-responsive">
        <table className="table table-listing table-hover">
          <thead>
            <tr>
              <th>Action Date</th>
              <th>Action</th>
              <th>User</th>
              <th>Notes</th>
              <th>Created By</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {gear_history_records.map(gear_history_record => <GearHistoryRecord key={`table-row-${gear_history_record.id}`} gear_history_record={gear_history_record} />)}
          </tbody>
        </table>
        {gear_history_records.length === 0 && <div>Table is Empty</div>}
      </div>
    )
}