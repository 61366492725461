import React, { useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { useSelector } from "react-redux"
import { updateJobServiceTransition } from "requesters/jobServiceRequester"
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap"
import { roleCheck } from "utils/index"
import { getBaseUrl, getCurrentUser, getCurrentRole } from "../../../../reducers"
import BookInCheckSurvey from "./BookInCheckSurvey";
import { OPERATIONS } from "../../../../../../utils/constants";

const ReadyToSend = ({ jobService, reloadData }) => {
  const {
    id, attributes: {
      docketResolved,
      outstandingInvoiceFlagged,
      hasActiveSurvey,
      unmatchedInvoiceFlagged
    }
  } = jobService
  const baseUrl = useSelector(getBaseUrl);
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  // const currentTeamRole = currentUser.attributes.current_team_role
  const currentRole = useSelector(getCurrentRole)

  const [showMarkAsSentConfirmation, setShowMarkAsSentConfirmation] = useState(false)
  const [showMarkAsCompletedConfirmation, setShowMarkAsCompletedConfirmation] = useState(false)
  // const [showPrepareFilesConfirmation, setShowPrepareFilesConfirmation] = useState(false)
  // const [hasBeenClicked, setHasBeenClicked] = useState(false)

  // const isAdmin = currentRole.includes(ADMIN)
  // const isOperationsReceptionOrAdmin = isAdmin || roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) || roleCheck(systemRole, currentTeamRole, RECEPTION_MANAGER)
  const isAdminOrOperations = roleCheck(systemRole, currentRole, OPERATIONS)

  // const prepareReadyForReleaseFilesHandler = () => {
  //   setHasBeenClicked(true)
  //   prepareReadyForReleaseFiles(baseUrl, jobService.id, false)
  //     .then(res => {
  //       if (res.status === 200) {
  //         window.location.href = `/jobs/${jobService.attributes.jobId}/job_services/${jobService.id}?tab=Files`
  //       }
  //     })
  //   .catch((err) => console.log(err))
  // }

  const [loading, setLoading] = useState(false)


  const markAsSentHandler = () => {
    setLoading(true)
    setShowMarkAsSentConfirmation(false);
    updateJobServiceTransition(baseUrl, id, "mark-as-sent")
      .then(res => {
        if (res.status === 200) {
          reloadData();
        } else {
          setLoading(false)
        }
      })
      .catch(() => {
        setLoading(false)
      });
  };

  const markAsCompletedHandler = () => {
    setLoading(true)
    setShowMarkAsCompletedConfirmation(false);
    updateJobServiceTransition(baseUrl, jobService.id, "mark-as-completed")
      .then(res => {
        if (res.status === 200) {
          reloadData();
        } else {
          setLoading(false)
        }
      })
      .catch(() => {
        setLoading(false)
      });
  };

  const validateMarkAsSent = () => {
    if (docketResolved) {
      markAsSentHandler();
    } else {
      setShowMarkAsSentConfirmation(false);
      Swal.fire(
        "Docket Error",
        "Docket has not been resolved",
        "error"
      );
    }
  };

  const buttonMustDisabled = loading || outstandingInvoiceFlagged || hasActiveSurvey || unmatchedInvoiceFlagged

  const validateMarkAsCompleted = () => {
    if (jobService.attributes.docketResolved) {
      markAsCompletedHandler();
    } else {
      setShowMarkAsCompletedConfirmation(false);
      Swal.fire(
        "Docket Error",
        "Docket has not been resolved",
        "error"
      );
    }
  };

  return (
    <li className="activity timeline-activity" key="readyToSend">
      <>
        <div className="circle circle-green" />
        <div className="justify-content-between">
          <div className="activity-status">
            <strong>Ready to Send</strong>
            <div className="grey-sub-activities">
              <div className="timeline-item my-2 d-flex justify-content-start align-items-center">
                <div className="text-left">
                  {/* {(isOperationsReceptionOrAdmin && jobService.attributes.preparedFilesAt === null && !jobService.attributes.ongoingFilePreparation) && (
                  <>
                    <button className="btn btn-outline-secondary mr-3" type="button" onClick={() => setShowPrepareFilesConfirmation(true)}>Prepare Files to Send</button>

                    <ReleasedFilesConfirmationModal
                      show={showPrepareFilesConfirmation}
                      onHide={() => setShowPrepareFilesConfirmation(false)}
                      modalConfirmed={hasBeenClicked}
                      filesHandler={prepareReadyForReleaseFilesHandler}
                      message="Are you sure you want to Prepare Files?" />
                  </>
                  )} */}
                </div>
                <div className="text-left">
                  <button
                    disabled={buttonMustDisabled}
                    type="submit"
                    className="btn btn-outlined-blue btn-md"
                    onClick={() => setShowMarkAsSentConfirmation(true)}>
                    Mark as Sent
                    {loading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
                  </button>
                  <SweetAlert
                    show={showMarkAsSentConfirmation}
                    type="info"
                    title="Are you sure you want to ‘Mark As Sent’? Have you Manually Sent the Files?"
                    style={{ width: "600px" }}
                    closeOnClickOutside
                    allowEscape={false}
                    confirmBtnText="Mark as Sent"
                    onCancel={() => { setShowMarkAsSentConfirmation(false) }}
                    onConfirm={validateMarkAsSent}>
                  </SweetAlert>
                  {/* Don't show the Check Survey button at here for now */}
                  { false && isAdminOrOperations && !hasActiveSurvey && (
                    <span className="ml-2">
                      <BookInCheckSurvey
                        jobService={jobService}
                        reloadData={reloadData} />
                    </span>
                  )}
                </div>
                <div className="text-left ml-2">
                  <button
                    disabled={loading || jobService.attributes.outstandingInvoiceFlagged}
                    type="submit"
                    className="btn btn-outlined-blue btn-md"
                    onClick={() => setShowMarkAsCompletedConfirmation(true)}>
                    Completed without Sending
                    {loading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
                  </button>
                  <SweetAlert
                    show={showMarkAsCompletedConfirmation}
                    type="info"
                    title="Are you sure you want to ‘Mark As Completed?"
                    style={{ width: "600px" }}
                    closeOnClickOutside
                    allowEscape={false}
                    confirmBtnText="Completed without Sending"
                    onCancel={() => { setShowMarkAsCompletedConfirmation(false) }}
                    onConfirm={validateMarkAsCompleted}>
                  </SweetAlert>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </li>
  );
};

export default ReadyToSend;
