import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { updateJobService } from "requesters/jobServiceRequester";
import { ACCOUNTS_MANAGER, OPERATION_MANAGER } from "utils/constants";
import { roleCheck } from "utils/index";
import { getBaseUrl } from "../../reducers";

const EditNoteModalSchedules = ({
  show, onHide, reloadData, jobService, title, readOnly, currentUser, noteType
}) => {
  const [loading, setLoading] = useState(false)
  const baseUrl = useSelector(getBaseUrl)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role
  let disabled = readOnly
  if (roleCheck(systemRole, currentTeamRole, ACCOUNTS_MANAGER) && noteType === "operationsNotes") {
    disabled = false
  }

  const {
    attributes: {
      job_service: {
        data: {
          id,
          attributes: {
            job_notes: jobNotes,
            operations_notes: operationsNotes,
            client_notes: clientNotes
          }
        }
      }
    }
  } = jobService

  const notes = noteType === "jobNotes" ? jobNotes : (noteType === "operationsNotes" ? operationsNotes : clientNotes)
  const [currentNotes, setCurrentNotes] = useState(notes)

  const notesAttributeHandler = () => {
    if (noteType === "jobNotes") {
      return { notes: currentNotes }
    } else if (noteType === "operationsNotes") {
      return { operations_notes: currentNotes }
    } else {
      return { client_notes: currentNotes }
    }
  }

  const validateForm = () => {
    setLoading(true)
    updateJobService(baseUrl, id, notesAttributeHandler())
      .then(res => {
        if (res.status === 200) {
          reloadData()
          onHide()
          setLoading(false)
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        {!disabled && (
          <Modal.Title><h2 className="modal-title">{title}</h2></Modal.Title>
        )}
      </Modal.Header>
      <div className="modal-body px-md-5">
        {(roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) || roleCheck(systemRole, currentTeamRole, ACCOUNTS_MANAGER)) ? (
          <>
            <form>
              <div className="form-group">
                <label>Notes</label>
                <ReactQuill
                  readOnly={disabled}
                  placeholder="e.g. existing dwelling, any substantial trees"
                  value={currentNotes || ""}
                  onChange={value => setCurrentNotes(value)} />
              </div>
            </form>
            <div className="modal-footer d-flex justify-content-between p-0 mb-4 mt-5">
              <button className="btn btn-light btn-lg" data-dismiss="modal" type="button" onClick={onHide}>Close</button>
              {!disabled && (
                <button
                  className="btn btn-red btn-lg"
                  onClick={validateForm}
                  disabled={currentNotes === notes || loading}
                  type="submit">
                  Save Changes
                  {loading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
                </button>
              )}
            </div>
          </>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: notes || "" }}></div>
        )}
      </div>
    </Modal>
  )
}
export default EditNoteModalSchedules
