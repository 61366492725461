import React, { useState } from "react"
import Select from "react-select"
import { useSelector } from "react-redux"
import { postContact } from "requesters/contactRequester"
import EmailValidator from "email-validator"
import { Spinner } from "react-bootstrap"
import CustBreadcrumb from "components/CustBreadcrumb"
import { roleCheck } from "utils/index"
import {
  ACCOUNTS_MANAGER,
  OPERATION_MANAGER,
  REGISTERED_SURVEYORS_MANAGER,
} from "utils/constants"
import { getBaseUrl, getCurrentUser } from "../../reducers"
import { FIELD_VALIDATIONS } from "./formErrors"


export default function NewContactForm({ history }) {
  const baseUrl = useSelector(getBaseUrl)
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role

  const { clientTypes } = useSelector(state => state.options)
  const clientTypeOptions = clientTypes.map(v => ({ value: v[1], label: v[0] }))

  // form fields
  const [email, setEmail] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [company, setCompany] = useState("")
  const [clientType, setClientType] = useState([])

  // form events
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [validForm, setValidForm] = useState(true)

  const rolesAllowed = () => {
    if (roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) || roleCheck(systemRole, currentTeamRole, ACCOUNTS_MANAGER) || roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)) {
      return true
    }
    return false
  }

  if (!rolesAllowed()) {
    window.location = "/"
  }

  function handleSubmit() {
    const data = {
      contact: {
        email,
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        company,
        client_type: clientType,
        contact_type: "general",
        user_id: currentUser
      }
    }

    setDisableSubmit(true)
    postContact(baseUrl, { data })
    .then(res => {
      if (res.status === 201) {
        history.push("/contacts")
        setDisableSubmit(false)
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }

  function validateForm() {
    let validFields = true
    const fields = [
      {
        email,
        firstName,
        lastName,
        // phoneNumber,
        // company,
        // clientType
      }
    ]

    _.map(fields[0], (value, key) => {
      switch (key) {
        case "email":
          validFields = EmailValidator.validate(value)
          break
        default:
          if (_.isEmpty(value)) {
            validFields = false
          }
      }
    })

    if (validFields) {
      handleSubmit()
    }
    setValidForm(false)
  }

  function errorMessage(attribute, value) {
    if (!validForm) {
      const message = _.find(FIELD_VALIDATIONS, ["field", attribute])
      let validField = true

      switch (attribute) {
        case "email":
          validField = EmailValidator.validate(email)
          break
        case "phone_number":
          if (phoneNumber.length < 10 || phoneNumber.length > 12) {
            validField = false
          }
          break
        default:
          if (_.isEmpty(value)) {
            validField = false
          }
      }

      if (!validField) {
        return (
          <div className="err-msg">
            { message.message}
          </div>
        )
      }
      return null
    }
    return null
  }

  return (
    <div className="gear-details-page pb-5 container-fluid">
      <CustBreadcrumb links={[["Contacts", "/contacts"]]} active="New" />
      <h2 className="mb-4">New Contact</h2>
      <div className="row">
        <div className="col-md-8">
          <div className="card border-0 shadow-sm">
            <div className="card-body p-4">
              <div className="form-group row">
                <label className="col-3 col-form-label">Email*</label>
                <div className="col-9">
                  <input
                    name="email"
                    className="form-control"
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={e => setEmail(e.target.value)} />
                  {errorMessage("email", email)}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">First Name*</label>
                <div className="col-9">
                  <input
                    name="first_name"
                    className="form-control"
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)} />
                  {errorMessage("first_name", firstName)}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">Last Name*</label>
                <div className="col-9">
                  <input
                    name="last_name"
                    className="form-control"
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={e => setLastName(e.target.value)} />
                  {errorMessage("last_name", lastName)}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">Phone Number</label>
                <div className="col-9">
                  <input
                    name="phone_number"
                    className="form-control"
                    type="text"
                    placeholder="optional"
                    value={phoneNumber}
                    onChange={e => setPhoneNumber(e.target.value)} />
                  {/* {errorMessage("phone_number", phoneNumber)} */}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">Company</label>
                <div className="col-9">
                  <input
                    name="company"
                    className="form-control"
                    type="text"
                    placeholder="optional"
                    value={company}
                    onChange={e => setCompany(e.target.value)} />
                  {/* {errorMessage("company", company)} */}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-3 col-form-label">Client Type</label>
                <div className="col-9">
                  <Select
                    name="client_type"
                    placeholder="Select"
                    value={_.filter(clientTypeOptions, ["value", clientType])}
                    options={clientTypeOptions}
                    onChange={e => setClientType(_.get(e, "value", ""))} />
                  {/* {errorMessage("client_type", clientType)} */}
                </div>
              </div>
              <div className="mt-4">
                <button
                  className="btn btn-red"
                  onClick={validateForm}
                  disabled={disableSubmit}
                  type="submit">
                  {disableSubmit
                    ? <Spinner animation="border" role="status" size="sm" />
                    : `Add Contact`
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
