
import React, { useState, useEffect } from "react"
import { Modal } from "react-bootstrap"
import { useSelector } from "react-redux"
import "react-datepicker/dist/react-datepicker.css"
import { format } from "date-fns"

import { postGearHistoryRecord } from "requesters/gearHistoryRecordRequester"
import { getBaseUrl } from "../../../reducers"

const GearReturnModal = ({
 gearId, gearIdentifier, show, setShow, currentUser
}) => {
  const baseUrl = useSelector(getBaseUrl)
  const [notes, setNotes] = useState("")

  const { id: userId } = currentUser

  const handleClose = () => {
    setShow(false)
    setNotes("")
  }

  const handleIssue = e => {
    setNotes(e.target.value)
  }

  const handleSubmit = () => {
      const issueDate = format(new Date(), "dd/MM/yyyy")
      const newHistory = {
        user: userId,
        action: "returned_by",
        notes,
        report_date: issueDate
      }
      handleClose()
      postGearHistoryRecord(baseUrl, newHistory, gearId)
      .then(res => {
        if (res.status === 201) {
          window.location.reload()
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered>
        <div className="modal-content">
          <div className="modal-header border-0">
            <h5 className="modal-title" id="gearReturnModalLabel">
              Return Gear
            </h5>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={handleClose}>
              <span aria-hidden="true"> ×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row mb-4">
              <div className="col-md-4">Item ID</div>
              <div className="col-md-8">{gearIdentifier}</div>
            </div>
            <textarea
              className="form-control"
              placeholder="Optional Notes"
              rows="3"
              value={notes}
              onChange={handleIssue} />
          </div>
          <div className="modal-footer border-0 justify-content-between mt-4">
            <button
              className="btn btn-light btn-lg"
              onClick={handleClose}
              type="button">
              Cancel
            </button>
            <button
              className="btn btn-red btn-lg"
              type="button"
              onClick={handleSubmit}
              // disabled={disableSubmit}
              >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default GearReturnModal