import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { getUploadedFile } from "../../../../../../requesters/uploadsRequester";

const LocationSummary = ({ nearbyJobLocation }) => {
  const {
    attributes: {
      lastJobService,
      defaultJobComments,
      masterBoundaryFile,
      summarizedJobServices
    }
  } = nearbyJobLocation
  const [loadingFileUrl, setLoadingFileUrl] = useState(false)

  const JobServicesSummarySection = () => {
    if (lastJobService && summarizedJobServices) {
      const hasSummarizedJobs = summarizedJobServices.length > 0;

      return (
        <div>
          {hasSummarizedJobs ? (
            <>
              <p className="mb-0">We have completed:</p>
              <p>{summarizedJobServices.join(",")}</p>
            </>
          ) : (
            <p className="mb-0">Jobs are in Progress</p>
          )}
        </div>
      )
    }

    const summaryContent = defaultJobComments || "See T Drive for Files or Contact IT if there are any issues"

    return <span>{summaryContent}</span>
  }

  const MasterFileSection = () => {
    if (!masterBoundaryFile?.data) return <></>;

    const {
      data: {
        attributes: { name, fileName, fileTrackedUrl }
      }
    } = masterBoundaryFile

    const onClickMasterFile = async () => {
      setLoadingFileUrl(true)
      const result = await getUploadedFile(fileTrackedUrl)
      if ("response" in result) {
        const download_url = result?.response?.json?.download_url
        const file_name = result?.response?.json?.file_name
        const downloadLink = document.createElement("a");

        downloadLink.href = download_url;
        downloadLink.setAttribute("download", file_name)
        downloadLink.setAttribute("target", "_blank")
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }

      setLoadingFileUrl(false)
    }

    return (
      <p className="d-flex align-items-center">
        <span>Master file:</span>
        <span className="ml-1">
          <Button
            disabled={loadingFileUrl}
            variant="link"
            onClick={() => onClickMasterFile()}
            title={name || fileName}
            className="text-truncate text-left m-0 p-0">
            {name || fileName}
          </Button>
        </span>
      </p>
    )
  }

  return (
    <div>
      {JobServicesSummarySection()}
      {MasterFileSection()}
    </div>
  )
}

export default LocationSummary
