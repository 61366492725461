import React from "react"
import { Link } from "react-router-dom"

const GpsActivityCompleted = ({
  gpsState,
  jobService,
}) => {
  const {
    attributes: {
      jobId,
      isGpsAhd,
      isGpsMga,
      gpsRequestComment,
      gpsDeclineComment,
      activeGpsRescheduledJob,
      linkedJobServiceId
    }
  } = jobService

  const isCompleted = gpsState === "complete_gps"
  const isDeclined = gpsState === "decline_gps"
  const isGPSJob = isGpsAhd || isGpsMga

  const getUrl = () => {
    const defaultTab = isGPSJob ? "Activity" : "Files"
    let link = `/jobs/${jobId}/job_services/${linkedJobServiceId}?tab=${defaultTab}`;
    const wasRescheduledGPSJob = !isGPSJob && activeGpsRescheduledJob?.id && activeGpsRescheduledJob?.id !== linkedJobServiceId
    if (wasRescheduledGPSJob) link = `/jobs/${activeGpsRescheduledJob.jobId}/job_services/${activeGpsRescheduledJob.id}`;
    return link
  }
  const url = getUrl()
  const label = isGPSJob ? "View Parent Job" : "GPS files click here"

  const notesRender = () => {
    if (isDeclined && gpsDeclineComment) {
      return (
        <>
          <p className="mb-0">
            <strong>Surveyor Notes: </strong>
            {gpsRequestComment?.body}
          </p>
          <p className="mb-0">
            <strong>Operation Notes: </strong>
            {gpsDeclineComment?.body}
          </p>
        </>
      )
    }

    return (
      <p className="mb-0">
        <strong>Surveyor Notes: </strong>
        {gpsRequestComment?.body}
      </p>
    )
  }

  return (
    <div className="timeline-item mt-2 mb-2">
      <div className="card border-0 shadow-sm">
        <div className="card-body">
          <div className="row px-3 flex-column">
            <p className="mb-0">
              {isCompleted && (
                <Link
                  to={url}
                  onClick={() => { window.location.href = url }}
                  className="link-no-decoration">
                  {label}
                </Link>
              )}
            </p>
            {notesRender()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default GpsActivityCompleted
