import React from "react";
import { Link } from "react-router-dom"
import cx from "classnames";
import { useSelector } from "react-redux"
import { updateJobServiceTransition } from "requesters/jobServiceRequester"
import { INVOICE_STATES, JOB_STATES_DISPLAY, CANCELLED } from "utils/constants"
import { getBaseUrl } from "../../reducers"

export default function AccountsRow({ job, fetchData }) {
  const {
    id,
    jobId,
    jobNo,
    forProjectX,
    projectXJobNo,
    jobAddress,
    clientName: contact,
    code,
    cost,
    accountType,
    invoiceType,
    accountsInvoiceState,
    state,
    refundTypeLabel,
    payBeforeDrafting,
    previousState
  } = job.attributes;

  const baseUrl = useSelector(getBaseUrl)
  const transition = (state === "to_invoice") ? "set-to-ready-to-send" : "mark-as-paid"
  const displayJobNo = forProjectX ? projectXJobNo : jobNo

  const getInvoiceStatusColumn = () => {
    const isDraftedAndPayBeforeDrafting = ["deposit_invoice_drafted", "final_invoice_drafted"].includes(accountsInvoiceState) && payBeforeDrafting
    if (isDraftedAndPayBeforeDrafting) return { bgClass: "bg-green", text: "Invoice Sent (Pay Before Drafting)" }
    return { bgClass: INVOICE_STATES[accountsInvoiceState]?.bgClass, text: INVOICE_STATES[accountsInvoiceState]?.text }
  }

  const invoiceStatusColumn = getInvoiceStatusColumn()
  const invoiceStateColor = invoiceStatusColumn.bgClass
  const invoiceStateText = invoiceStatusColumn.text


  const markAsPaidHandler = () => {
    updateJobServiceTransition(baseUrl, id, transition)
      .then(res => {
        if (res.status === 200) {
          fetchData()
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  const stateRender = () => {
    if (state === CANCELLED) {
      return {
        text: `${JOB_STATES_DISPLAY[state]?.text} - ${refundTypeLabel}`,
        bgClass: JOB_STATES_DISPLAY[state]?.bgClass
      }
    }
    if (["awaiting_payment", "to_invoice"].includes(state) && accountsInvoiceState === "deposit_invoice_drafted") {
      return JOB_STATES_DISPLAY.awaiting_payment_deposit
    }
    if (["awaiting_payment", "to_invoice"].includes(state) && accountsInvoiceState === "final_invoice_drafted") {
      return JOB_STATES_DISPLAY.awaiting_payment_final
    }
    if (state === "awaiting_payment") {
      return JOB_STATES_DISPLAY[previousState]
    }
    return JOB_STATES_DISPLAY[state]
  }

  return (
    <tr>
      <td>
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{displayJobNo}</Link>
      </td>
      <td>
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{jobAddress}</Link>
      </td>
      <td>
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{code}</Link>
      </td>
      <td>
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{contact}</Link>
      </td>
      <td>
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{accountType}</Link>
      </td>
      <td>
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{invoiceType}</Link>
      </td>
      <td className={cx(`dropdown text-center ${invoiceStateColor}`)}>
        <button
          className="btn dropdown-toggle"
          disabled
          type="button">
          {invoiceStateText}
        </button>
      </td>
      <td className={cx(`dropdown text-center ${stateRender()?.bgClass} border-left border-white`)}>
        <button
          className="btn dropdown-toggle"
          disabled
          type="button">
          {stateRender()?.text}
        </button>
      </td>
      {/* <StatusChangeDropdown job={job} reloadData={fetchData} jobStates={jobStates} updatableStates={updatableStates} updatableOptions={updatableOptions} /> */}
      <td className="text-center">
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{`$${cost}`}</Link>
      </td>
      {/* <td className="text-center"></td> */}
      <td>
        <div className="d-flex align-items-center justify-content-end">
          <a href={`/jobs/${jobId}/job_services/${id}`}>Details</a>
        </div>
      </td>
      {/* <td>
        <div className="dropdown">
          <button className="btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" disabled={currentState(state) !== "invoice_sent"}>
            <i className="bi bi-three-dots-vertical" />
          </button>
          {currentState(state) === "invoice_sent" && (
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <button className="dropdown-item" type="button" onClick={markAsPaidHandler}>Mark as Paid</button>
          </div>
          )}
        </div>
      </td> */}
    </tr>
  );
}
