import React from "react"
import OrganizationRow from "./OrganizationRow"

export default function OrganizationsTable({ organizations, onDeleteOrganization }) {
  return (
    <div className="table-responsive">
      <table className="table table-listing table-hover">
        <thead>
          <tr>
            <th>Organisation Name</th>
            <th>Email</th>
            <th>Account Type</th>
            <th>Address</th>
            <th>Related Jobs</th>
          </tr>
        </thead>
        <tbody>
          {organizations.map(organization => <OrganizationRow key={`table-row-${organization.id}`} organization={organization} onDeleteOrganization={onDeleteOrganization} />)}
        </tbody>
      </table>
    </div>
  )
}
