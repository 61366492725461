import React, { useState } from "react";
import { useSelector } from "react-redux"
import SweetAlert from "react-bootstrap-sweetalert"
import { updateFile } from "../../../../../requesters/uploadsRequester";
import { getBaseUrl } from "../../../reducers"

const SupersededFile = ({ file, reloadData }) => {
  const baseUrl = useSelector(getBaseUrl)
  const [showMessage, setShowMessage] = useState(false)

  const handleUpdateFile = id => {
    updateFile(baseUrl, id, {
      superseded: !file.data?.attributes?.superseded
    })
      .then(() => {
        reloadData()
        setShowMessage(false)
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  const btnStyle = {
    border: "none",
    boxShadow: "none"
  };

  return (
    <>
      <button
        className="btn btn-link btn-lg p-0 pb-1 mr-2"
        onClick={() => setShowMessage(true)}
        type="button">
        {file.data?.attributes?.superseded ? "Restore" : "Supersede"}
      </button>
      <SweetAlert
        show={showMessage}
        type="custom"
        customClass="text-dark"
        title={`Are you sure you want to ${file.data?.attributes?.superseded ? "Restore" : "Supersede"} this file?`}
        style={{ width: "600px" }}
        confirmBtnText="Save Changes"
        confirmBtnCssClass="btn btn-danger"
        confirmBtnStyle={btnStyle}
        closeOnClickOutside
        allowEscape={false}
        showCancel
        onCancel={() => setShowMessage(false)}
        onConfirm={() => handleUpdateFile(file.data.id)}>
        <i className="bi bi-file-earmark mr-2"></i>
        {file.data.attributes.fileName}
      </SweetAlert>
    </>
  )
}

export default SupersededFile
