import CustBreadcrumb from "components/CustBreadcrumb"
import Loader from "components/Loader"
import React, { useEffect, useState } from "react"
import {
  Container,
  Spinner
} from "react-bootstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import _ from "lodash"
import {
 getDocket, getDocketPdf, updateDocket, sendEmailToClient
} from "requesters/docketRequester"
import {
  ACCOUNTS,
  ADMIN, OPERATIONS, OPERATION_MANAGER, SURVEYORS
} from "utils/constants"
import { roleCheck } from "utils/index"
import AiOutlineInfoCircleCustom from "../../../components/AiOutlineInfoCircleCustom"
import { getBaseUrl, getCurrentRole, getCurrentUser } from "../../../reducers"
import DocketDescriptions from "./DocketDescriptions"
import DocketInfo from "./DocketInfo"
import SendCopyToClientModal from "../SendCopyToClientModal"

export default function DocketDetails() {
  const { id } = useParams()
  const docketId = parseInt(id, 10)
  const baseUrl = useSelector(getBaseUrl)
  const currentRole = useSelector(getCurrentRole)
  const currentUser = useSelector(getCurrentUser)
  const [showMessage, setShowMessage] = useState(false)
  const [messageError, setMessageError] = useState(null)

  const [loading, setLoading] = useState(false)
  const [loadingPdf, setLoadingPdf] = useState(false)
  const [showSendToClientModal, setShowSendToClientModal] = useState(false)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role

  const [docket, setDocket] = useState(null)
  const isAdminOrOperations = roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER)
  const isSurveyor = roleCheck(systemRole, currentRole, SURVEYORS)


  const readOnly = !(currentRole.includes(ADMIN) || currentRole.includes(OPERATIONS) || currentRole.includes(ACCOUNTS) || currentUser.attributes.role === ADMIN)
  const [showConfirmDocketModal, setShowConfirmDocketModal] = useState(false)
  const [showSubmitDocketModal, setSubmitDocketModal] = useState(false)


  async function fetchData() {
    setLoading(true)
    const { response, error } = await getDocket(baseUrl, docketId)
    if (response) {
      setDocket(Object.values(response.docketDetail)[0]?.attributes)
    }
    if (error) {
      setShowMessage(true)
      setMessageError("Something went wrong. Please try again later.")
    }
    setLoading(false)
  }

  async function handleSendToClient(clientEmails) {
    setLoading(true)
    const { response } = await sendEmailToClient(baseUrl, docketId, { client_emails: clientEmails })
    if (response && response.json && response.json.error) {
      setShowMessage(true)
      setMessageError(response.json.error)
    }
    setLoading(false)
  }


  // fetchData
  useEffect(() => {
    fetchData()
  }, [baseUrl, docketId])

  return (
    <>
      {docket && (
        <Container fluid className="pb-5">
          <DocketBreadcrumb readOnly={readOnly} docket={docket} />
          <div className="row ">
            <div className="col-md-8">
              <div className="d-flex justify-content-between align-items-center">
                <h1>{docket?.number || ""}</h1>
                <div className="d-flex justify-content-end align-items-center">
                  {isSurveyor && ["new"].includes(docket?.status) && (
                    <>
                      <button
                        disabled={
                          loading
                          || _.get(docket, "docketDescriptions.data", []).length === 0
                          || !!_.get(docket, "timeIn", null) === false
                          || !!_.get(docket, "timeOut", null) === false
                        }
                        className="btn btn-outline-primary mr-2"
                        type="button"
                        onClick={() => {
                          setSubmitDocketModal(true)
                        }}>
                        Submit to Operations
                      </button>
                      <SweetAlert
                        custom
                        show={showSubmitDocketModal}
                        title="Are you sure?"
                        style={{ width: "600px" }}
                        closeOnClickOutside
                        allowEscape={false}
                        showCloseButton
                        onCancel={() => setSubmitDocketModal(false)}
                        confirmBtnText="Continue"
                        customIcon={<AiOutlineInfoCircleCustom style={{ color: "rgb(238, 162, 54)", margin: "12px auto" }} />}
                        onConfirm={() => {
                          setLoading(true)
                          setSubmitDocketModal(false)
                          updateDocket(baseUrl, docketId, { no_signature: true, status: "signed" }).then(() => {
                            fetchData()
                          })
                        }}>
                        <span style={{
                          fontSize: "18px",
                          fontWeight: "normal"
                        }}>
                          {`Submit docket ${docket.number} to Operations`}
                        </span>
                      </SweetAlert>

                    </>
                  )}
                  {(isSurveyor || isAdminOrOperations) && !["new"].includes(docket?.status) && (
                    <>
                      <button
                        disabled={
                          loading
                          || _.get(docket, "docketDescriptions.data", []).length === 0
                          || !!_.get(docket, "timeIn", null) === false
                          || !!_.get(docket, "timeOut", null) === false
                        }
                        className="btn btn-outline-primary mr-2"
                        type="button"
                        onClick={() => {
                          setShowSendToClientModal(true)
                        }}>
                        Send Copy to Supervisor / Client
                      </button>
                      <SendCopyToClientModal contactsWithEmail={_.get(docket, "jobService.data.attributes.contactsWithEmail", [])} show={showSendToClientModal} setShow={setShowSendToClientModal} onSendCopy={handleSendToClient} />
                    </>
                  )}
                  {isAdminOrOperations && ["new", "no_signature", "signed"].includes(docket?.status) && (
                    <>
                      <button
                        disabled={loading}
                        className="btn btn-outline-primary mr-2"
                        type="button"
                        onClick={() => {
                          setShowConfirmDocketModal(true)
                        }}>
                        Approve and Send to Accounts
                      </button>
                      <SweetAlert
                        custom
                        show={showConfirmDocketModal}
                        title="Are you sure?"
                        style={{ width: "600px" }}
                        closeOnClickOutside
                        allowEscape={false}
                        showCloseButton
                        onCancel={() => setShowConfirmDocketModal(false)}
                        confirmBtnText="Continue"
                        customIcon={<AiOutlineInfoCircleCustom style={{ color: "rgb(238, 162, 54)", margin: "12px auto" }} />}
                        onConfirm={() => {
                          setLoading(true)
                          setShowConfirmDocketModal(false)
                          updateDocket(baseUrl, docketId, { total_amount_confirmed: true }).then(() => {
                            fetchData()
                          })
                        }}>
                        {!docket.totalAmount || docket.totalAmount === "0.00" ? (
                          <>
                            <p style={{ fontSize: "18px", color: "red" }}>WARNING $0.00 AMOUNT</p>
                            <span style={{
                              fontSize: "18px",
                              fontWeight: "normal",
                              color: "red"
                            }}>
                              {`Approve and Send docket ${docket.number}, with a value of `}
                              <b>
                                $0.00
                              </b>
                              {" to Accounts?"}
                            </span>
                          </>
                        ) : (
                          <span style={{
                            fontSize: "18px",
                            fontWeight: "normal"
                          }}>
                            {`Approve and Send docket ${docket.number}, with a value of `}
                            <b>
                              $
                              {docket.totalAmount}
                            </b>
                            {" to Accounts?"}
                          </span>
                        )}
                      </SweetAlert>
                    </>
                  )}
                  <button
                    onClick={() => {
                      setLoadingPdf(true)
                      getDocketPdf(baseUrl, docketId).then(({ response, status }) => {
                        if (status === 200 && response.json && response.json.file_url) {
                          const downloadLink = document.createElement("a");
                          downloadLink.href = response.json.file_url;
                          downloadLink.download = response.json.filename;
                          downloadLink.target = "_blank";
                          downloadLink.click();
                          downloadLink.remove()
                        }
                        setLoadingPdf(false)
                      })
                    }}
                    type="button"
                    disabled={loadingPdf}
                    className="btn btn-outline-primary">
                    {loadingPdf && <Spinner className="mr-2" animation="border" role="status" size="sm" />}
                    {" "}
                    Export PDF
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <DocketInfo
                loading={loading}
                docket={docket}
                onUpdateTotalAmount={(totalAmount, reason) => {
                  setLoading(true)
                  updateDocket(baseUrl, docketId, { total_amount: totalAmount, reason }).then(() => {
                    fetchData()
                  }).catch(() => {
                    setLoading(false)
                  })
                }}
                onUpdateTimeInOut={(timeIn, timeOut) => {
                  setLoading(true)
                  updateDocket(baseUrl, docketId, { time_in: timeIn, time_out: timeOut }).then(() => {
                    fetchData()
                  }).catch(() => {
                    setLoading(false)
                  })
                }} />
              <DocketDescriptions
                loading={loading}
                setLoading={setLoading}
                status={docket.status}
                docketDescriptions={_.orderBy(_.get(docket, "docketDescriptions.data", []), "id", "desc")}
                reloadData={() => {
                  fetchData()
                }}
                docketId={docketId} />
            </div>
          </div>
          <SweetAlert
            show={showMessage}
            type="error"
            title={messageError || ""}
            text="Please try again later."
            showCancelButton={false}
            showConfirmButton={false}
            closeOnClickOutside
            allowEscape={false}
            onConfirm={() => {
              setShowMessage(false)
              setMessageError(null)
            }} />
        </Container>
      )}
      {loading && <Loader />}
    </>
  )
}

function DocketBreadcrumb({ docket }) {
  // const { id } = useParams()

  // if (readOnly) return <CustBreadcrumb links={[["Job Service Details", `/jobs/${docket.jobService.data.attributes.jobId}/job_services/${docket.jobService.data.id}`]]} active={docket.number} />
  return <CustBreadcrumb links={[["Job Service", `/jobs/${docket.jobService.data.attributes.jobId}/job_services/${docket.jobService.data.id}?tab=Dockets`]]} active={`Docket Detail : ${docket.number}`} />
}
