import normalize from "json-api-normalizer";
import customAxios from "./customAxios";


export const postDocket = (baseUrl, data) => {
  const path = `${baseUrl}/dockets`
  return customAxios.post(path, { job_service_id: Number(data.jobServiceId), surveyor_assignment_id: Number(data.surveyorAssignmentId) })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }))
}


export const getDocket = (baseUrl, docketId) => {
  const path = `${baseUrl}/dockets/${docketId}`
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const createDocketDescription = (baseUrl, docketId, data) => {
  const path = `${baseUrl}/dockets/${docketId}/docket_descriptions`
  return customAxios.post(path, data)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const updateDocketDescription = (baseUrl, docketDescriptionId, data) => {
  const path = `${baseUrl}/docket_descriptions/${docketDescriptionId}`
  return customAxios.put(path, data)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }))
}

export const getDocketPdf = (baseUrl, docketId) => {
  const path = `${baseUrl}/dockets/${docketId}/generate-pdf`
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }))
}

export const updateDocket = (baseUrl, docketId, data) => {
  const path = `${baseUrl}/dockets/${docketId}`
  return customAxios.patch(path, data)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }))
}

export const deleteDocket = (baseUrl, docketId) => {
  const path = `${baseUrl}/dockets/${docketId}`
  return customAxios.delete(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }))
}


export const sendEmailToClient = async (baseUrl, docketId, data) => {
  const path = `${baseUrl}/dockets/${docketId}/send-email-client`
  return customAxios.post(path, data)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }))
}
