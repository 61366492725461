import React, { useState } from "react";
import AnchorButton from "components/AnchorButton"
import { closeIcon } from "components/Icons"


const InvoiceLineItems = ({
  invoiceLineItems, setInvoiceLineItems
}) => {
  const [lineItems, setLineItems] = useState([...invoiceLineItems])
  return (
    <>
      <div className="invoice-line-item-container">
        <div className="d-flex justify-content-between align-items-center">
          <div className="invoice-line-item-title">
            <h5>Invoice Line Items</h5>
          </div>
        </div>
        <div className="container-inner">
          <div className="row">
            <div className="col-lg-5 text-left">
              <h6>Description</h6>
            </div>
            <div className="col-lg-7 row align-items-center">
              <div className="col-lg-3 text-center">
                <h6>Qty</h6>
              </div>
              <div className="col-lg-4 text-center">
                <h6>Unit Price</h6>
              </div>
              <div className="col-lg-4 text-right">
                <h6>Total</h6>
              </div>
              <div className="col-lg-1">
              </div>
            </div>
          </div>
          <hr className="mt-1 mb-2" />
          {invoiceLineItems.map(item => (
            <div className="row mb-2" key={item.id}>
              <div className="col-lg-5 text-left">
                <div>
                  <textarea rows={2} value={item.description} onChange={e => setInvoiceLineItems(invoiceLineItems.map(li => (li.id === item.id ? { ...li, description: e.target.value } : li)))} className="form-control" type="text" />
                </div>
              </div>
              <div className="col-lg-7 row align-items-center">
                <div className="col-lg-3 text-center">
                  <div>
                    <input min={0} step={1} value={item.quantity} onChange={e => setInvoiceLineItems(invoiceLineItems.map(li => (li.id === item.id ? { ...li, quantity: Number(e.target.value || 0) } : li)))} className="form-control" type="number" />
                  </div>
                </div>
                <div className="col-lg-4 text-center">
                  <div className="input-group">
                    <span className=" input-group-text">$</span>
                    <input min={0} step={0.01} value={item.unit_amount} onChange={e => setInvoiceLineItems(invoiceLineItems.map(li => (li.id === item.id ? { ...li, unit_amount: Number(e.target.value || 0) } : li)))} className="form-control" type="number" />
                  </div>
                </div>
                <div className="col-lg-4 text-right">
                  <div>
                    $
                    {(Math.round(item.quantity * item.unit_amount * 100) / 100).toFixed(2)}


                  </div>
                </div>
                <div className="col-lg-1">
                  {!item.disable_remove && (
                    <AnchorButton className="ml-2" onClick={() => { setInvoiceLineItems(invoiceLineItems.filter(li => li.id !== item.id)) }}>
                      <img src={closeIcon} />
                    </AnchorButton>
                  )}
                </div>
              </div>
            </div>
          ))}
          {invoiceLineItems.length > 0 && (<hr className="mt-2 mb-1" />)}
          <div className="d-flex justify-content-end mt-4">
            <button
              className="btn btn-outlined-blue"
              onClick={() => setInvoiceLineItems([...invoiceLineItems, {
                id: Date.now(), description: "", quantity: 0, unit_amount: 0, item_type: "OTHER_FEE"
              }])}
              type="button">
              + Add Line Item
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default InvoiceLineItems
