import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Container, Spinner } from "react-bootstrap"
import { useQueryParam, StringParam } from "use-query-params"
import { Link } from "react-router-dom"
import SearchInput from "components/SearchInput"
import FilterDropdown from "components/FilterDropdown"
import Loader from "components/Loader"
import AnchorButton from "components/AnchorButton"
import { getUsers, archiveUser } from "requesters/userRequester"
import { roleCheck } from "utils/index"
import { OPERATIONS, ADMIN } from "utils/constants"
import { getCurrentRole, getCurrentUser, getBaseUrl } from "../../reducers"
import AddUserModal from "./AddUserModal"

export default function ManageUsers() {
  const baseUrl = useSelector(getBaseUrl)
  const currentUser = useSelector(getCurrentUser)
  const currentRole = useSelector(getCurrentRole)
  const systemRole = currentUser.attributes.role
  const isAdmin = currentRole.includes(ADMIN)

  // constant created ad hoc in order to use the comonent FilterDropdown
  const teams = [
    ["Sales", "Sales"],
    ["Operations", "Operations"],
    ["Drafting", "Drafting"],
    ["Registered Surveyors", "Registered Surveyors"],
    ["Surveyors", "Surveyors"],
    ["Reception", "Reception"],
    ["Accounts", "Accounts"],
    ["Admin", "Admin"],
    ["Processor", "Processor"],
  ]
  if (isAdmin) {
    teams.push(["Asset Only", "Asset Only"])
  }

  const { teamRoles, userStatus } = useSelector(state => state.options)
  const newUserStatus = [...userStatus, ["All", "all"]]
  // const { teams, teamRoles, userStatus } = useSelector(state => state.options)
  const [byTeam, setByTeam] = useQueryParam("by_team", StringParam)
  const [byRole, setByRole] = useQueryParam("by_role", StringParam)
  const [byStatus, setByStatus] = useQueryParam("by_status", StringParam)
  const [bySearch, setBySearch] = useQueryParam("by_search", StringParam)
  const byTeamName = _.get(teams, `${_.findIndex(teams, team => team[1] === byTeam)}.0`)
  const byRoleName = _.get(teamRoles, `${_.findIndex(teamRoles, role => role[1] === byRole)}.0`)
  const byStatusName = _.get(newUserStatus, `${_.findIndex(newUserStatus, status => status[1] === byStatus)}.0`, userStatus[0][0])

  const [users, setUsers] = useState({})
  const [requesting, setRequesting] = useState(false)
  const [showingAddUserModal, setShowingAddUserModal] = useState(false)

  if (!roleCheck(systemRole, currentRole, OPERATIONS)) window.location = "/"

  function triggerArchiveUser(userId, archiveState) {
    async function fetchData() {
      const { response, error } = await archiveUser(baseUrl, userId, archiveState)
      if (response) {
        const { user } = response
        setUsers({ ...users, ...user })
      }
      if (error) {
        console.error(error)
      }
    }
    fetchData()
  }

  function clearFilters() {
    setBySearch(undefined)
    setByTeam(undefined)
    setByRole(undefined)
    setByStatus(undefined)
  }

  const fetchUsers = async () => {
    setRequesting(true)
    let newByStatus = byStatus
    if (!byStatus) {
      newByStatus = "active"
    }
    if (byStatus === "all") {
      newByStatus = undefined
    }
    const { response, error } = await getUsers(baseUrl, {
      by_team: byTeam,
      by_role: byRole,
      by_status: newByStatus,
      by_name_or_email: bySearch,
      by_operations_surveyors_team: !isAdmin && !!currentRole.includes(OPERATIONS)
    })
    if (response) {
      setUsers(_.get(response, "user", {}))
    }
    if (error) {
      console.error(error)
    }
    setRequesting(false)
  }

  useEffect(() => {
    fetchUsers()
  }, [baseUrl, byTeam, byRole, byStatus, bySearch])

  return (
    <Container fluid className="manage-users-page">
      <div className="d-flex justify-content-between align-items-center header-tabs-container">
        <div className="header-tabs">
          <h1>Manage Users</h1>
        </div>
        <div>
          <a className="btn btn-outlined-blue" href="/users/invitation/new">+ Invite User</a>
          { isAdmin && (
            <React.Fragment>
              <button
                type="button"
                className="btn btn-outlined-blue ml-2"
                onClick={() => setShowingAddUserModal(true)}>
                + Add Asset&#39; User
              </button>
              <AddUserModal
                forAssetsOnly
                show={showingAddUserModal}
                onHide={() => setShowingAddUserModal(false)}
                reloadData={fetchUsers} />
            </React.Fragment>
          )}
        </div>
      </div>
      <div className="table-listing-filters">
        <div className="btn-group mr-2">
          <SearchInput value={bySearch} setValue={setBySearch} placeholder="Search by name or email" />
        </div>
        <div className="btn-group mr-2">
          <FilterDropdown title="Team" filteredName={byTeamName} setFilter={setByTeam} options={teams} />
        </div>
        <div className="btn-group mr-2">
          <FilterDropdown title="Role" filteredName={byRoleName} setFilter={setByRole} options={teamRoles} />
        </div>
        <div className="btn-group mr-2">
          <FilterDropdown hiddenAllOption title="Status" filteredName={byStatusName} setFilter={setByStatus} options={newUserStatus} />
        </div>
        <div className="btn-group">
          <AnchorButton
            className="f-small"
            onClick={clearFilters}>
            Clear&#xa0;Filters
          </AnchorButton>
        </div>
      </div>
      {requesting ? <Loader /> : <UserTable isAdmin={isAdmin} users={Object.values(users)} triggerArchiveUser={triggerArchiveUser} />}
    </Container>
  )
}

function UserTable({ isAdmin, users, triggerArchiveUser }) {
  const sortedUsers = _.orderBy(users, ["attributes.name"])

  return (
    <div className="table-responsive">
      <table className="table table-listing table-hover">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Team</th>
            <th>Role</th>
            <th style={{ width: "5%" }}>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sortedUsers.map(user => (
            !user.attributes.archived
            && <UserRow isAdmin={isAdmin} key={`table-row-${user.type}-${user.id}`} user={user} triggerArchiveUser={triggerArchiveUser} />
          ))}
          {sortedUsers.map(user => (
            user.attributes.archived
            && <UserRow isAdmin={isAdmin} key={`table-row-${user.type}-${user.id}`} user={user} triggerArchiveUser={triggerArchiveUser} />
          ))}
        </tbody>
      </table>
      {users.length === 0 && <div>Table is Empty</div>}
    </div>
  )
}

function UserRow({ isAdmin, user, triggerArchiveUser }) {
  const {
    id, attributes, relationships
  } = user
  const archiveState = _.get(attributes, "archived", false)
  const [archiveRequested, setArchiveRequested] = useState(false)
  useEffect(() => {
    setArchiveRequested(false)
  }, [archiveState])
  if (attributes) {
    const {
      name, email, archived, defaultRole, defaultTeam
    } = attributes
    const teams = _.get(relationships, "teams.data", [])
    const statusText = archived ? "Archived" : "Active"
    const archiveButtonText = archived ? "Unarchive" : "Archive"

    if (defaultTeam === "asset_only" && !isAdmin) return <React.Fragment></React.Fragment>

    return (
      <tr>
        <td>
          <Link to={`/settings/manage-users/${id}`} className="link-no-decoration">{name}</Link>
        </td>
        <td>
          <Link to={`/settings/manage-users/${id}`} className="link-no-decoration">{email}</Link>
        </td>
        <td>
          <Link to={`/settings/manage-users/${id}`} className="link-no-decoration">{`${teams.length} teams`}</Link>
        </td>
        <td>
          <Link to={`/settings/manage-users/${id}`} className="link-no-decoration">{defaultRole?.replace(/_/g, " ")}</Link>
        </td>
        <td>
          <Link to={`/settings/manage-users/${id}`} className="link-no-decoration">{statusText}</Link>
        </td>
        <td className="text-right">
          <Link to={`/settings/manage-users/${id}`}>Detail</Link>
          <span className="text-muted mx-2">|</span>
          <AnchorButton
            className="w-4rem mr-2"
            onClick={() => {
              triggerArchiveUser(id, !archived)
              setArchiveRequested(true)
            }}>
            {archiveRequested ? (
              <Spinner
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true" />
            ) : archiveButtonText}
          </AnchorButton>
        </td>
      </tr>
    )
  }
  return null
}
