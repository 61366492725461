/* eslint-disable no-unused-vars */
import React, { forwardRef } from "react"
import DatePicker, { CalendarContainer } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import { calendarIconBlack, caretLeftIcon, caretRightIcon  } from "components/Icons"

export default function CADatePicker({ calendarDate, setCalendarDate }) {
  const years = _.range(2023, (new Date()).getFullYear() + 3, 1)
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]

  const CalendarBtn = forwardRef(({ onClick }, ref) => (
    <button ref={ref} type="button" className="btn btn-light btn-lg rounded-pill ml-2" onClick={onClick}>
      <img src={calendarIconBlack} />
    </button>
  ))

  const MyContainer = ({ className, children }) => (
    <div className="ca-custom">
      <CalendarContainer className={className}>
        {children}
      </CalendarContainer>
    </div>
  )

  return (
    <DatePicker
      closeOnScroll
      popperPlacement="bottom-end"
      popperModifiers={{
        preventOverflow: {
          enabled: true,
          escapeWithReference: false,
          boundariesElement: "viewport"
        }
      }}
      calendarContainer={MyContainer}
      customInput={<CalendarBtn />}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className="custom-calendar-header d-flex justify-content-center">
          <button className="anchor-button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled} type="button">
            <img src={caretLeftIcon} alt="Previous Month" className="mr-3" />
          </button>
          <select
            value={months[date.getMonth()]}
            onChange={(e) => changeMonth(months.indexOf(e.target.value))}
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <select value={date.getFullYear()} onChange={(e) => changeYear(e.target.value)}>
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <button className="anchor-button" onClick={increaseMonth} disabled={nextMonthButtonDisabled} type="button">
            <img src={caretRightIcon} alt="Next Month" className="ml-3" />
          </button>
        </div>
      )}
      todayButton="Today"
      selected={calendarDate}
      onChange={date => {
        setCalendarDate(date)
      }} />
  )
}
