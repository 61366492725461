import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { getSurveyors } from "requesters/userRequester";
import Select from "react-select";
import { updateJobService } from "requesters/jobServiceRequester";
import DatePicker from "react-datepicker";
import {
 postBookingAsUser, removeBookingAsUser, updateJobTransition, updateJob, sendBookingInvite
} from "requesters/jobRequester";
import SweetAlert from "react-bootstrap-sweetalert";
import cx from "classnames";
import { getBaseUrl } from "../../../reducers";

export default function FieldWorkStage({ job, isActive, reloadData }) {
  return (
    <div
      id="field"
      className={isActive ? "tab-pane fade active show" : "tab-pane fade"} />
  );
}
