import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import { useSelector } from "react-redux"

import { getAllJobServices } from "requesters/jobServiceRequester"
import {
  RECEPTION_MANAGER
} from "utils/constants"

import AnchorButton from "components/AnchorButton"
import Loader from "components/Loader"
import SearchInput from "components/SearchInput"
import { orderBy, get, isEmpty } from "lodash"
import JobServicesTable from "./JobServicesTable"

import { getBaseUrl, getCurrentRole, getCurrentUser } from "../../reducers"

export default function JobServices() {
  const baseUrl = useSelector(getBaseUrl)
  const currentRole = useSelector(getCurrentRole)
  const currentUser = useSelector(getCurrentUser)
  const currentTeamRole = currentUser.attributes.current_team_role
  const [requesting, setRequesting] = useState(false)
  const [jobServiceList, setJobServiceList] = useState([])


  const params = (new URL(document.location)).searchParams;
  const filter = params.get("filter");
  const job_service_id = params.get("job_service_id");

  const initialFilters = {
    by_keyword: undefined,
    by_status: filter === "ready_to_send" ? "ready_to_send" : undefined,
    job_pack_not_uploaded: (filter === "job_pack_not_uploaded" || currentTeamRole.includes(RECEPTION_MANAGER)) ? true : undefined,
    jobs_to_be_allocated: filter === "jobs_to_be_allocated" ? true : undefined,
    jobs_overdue: filter === "jobs_overdue" ? true : undefined,
    incomplete_jobs: filter === "incomplete_jobs" ? true : undefined,
    unpaid_pay_before_drafting: filter === "unpaid_pay_before_drafting" ? true : undefined,
    collect_payment_onsite: filter === "collect_payment_onsite" ? true : undefined,
    job_docket_to_review: filter === "job_docket_to_review" ? true : undefined,
    create_deliverables: filter === "create_deliverables" ? true : undefined,
    for_plan_report: filter === "for_plan_report" ? true : undefined,
    resolved_from_rs: filter === "resolved_from_rs" ? true : undefined,
    ready_to_send_for_rs_manager: filter === "ready_to_send_for_rs_manager" || undefined,
    jobs_is_outstanding_invoice: filter === "jobs_is_outstanding_invoice" ? true : undefined,
    job_service_id: job_service_id && filter === "jobs_is_outstanding_invoice" ? job_service_id : undefined,
    searches_to_complete: filter === "searches_to_complete" ? true : undefined,
    can_be_moved_forward: filter === "can_be_moved_forward" ? true : undefined
  }
  const [filters, setFilters] = useState(initialFilters)
  const [isFilterCleared, setIsFilterCleared] = useState(false)

  const clearFilters = () => {
    setFilters({ by_keyword: undefined, by_status: undefined, job_pack_not_uploaded: undefined })
    setIsFilterCleared(true)
  }

  const setBySearch = val => {
    setFilters({ ...filters, by_keyword: val })
  }

  const jobServiceListHandler = jobList => {
    if (initialFilters.job_pack_not_uploaded) {
      setJobServiceList(orderBy(jobList, [obj => new Date(obj.attributes.bookingDates[0]), "attributes.jobNo"], ["asc", "asc"]))
    } else {
      setJobServiceList(orderBy(jobList, "attributes.bookingTime", "asc"))
    }
  }

  const fetchData = () => {
    setRequesting(true)
    getAllJobServices(baseUrl, filters)
      .then(res => {
        if (res.status === 200) {
          const jobs = get(res.response, "jobService", {})
          let jobList = Object.values(jobs)

          const additionalInfo = res.response.meta?.additional_info
          if (additionalInfo && !isEmpty(additionalInfo.meta?.ids)) {
            jobList = additionalInfo.meta.ids.map(id => jobs[id])
            setJobServiceList(jobList)
          } else {
            jobServiceListHandler(jobList);
          }
        }
      })
      .then(() => {
        setRequesting(false)
      })
      .catch(() => {
        setRequesting(false)
      })
  }

  const handleSubmit = e => {
    e.preventDefault()
    fetchData()
  }

  useEffect(() => {
    fetchData()
    setIsFilterCleared(false)
  }, [isFilterCleared, currentRole])

  return (
    <Container fluid className="">
      <div className="container-inner">
        <div className="d-flex justify-content-between align-items-center header-tabs-container">
          <div className="header-tabs">
            <h1>Live Job Status</h1>
          </div>
        </div>
        <div className="table-listing-filters d-md-flex justify-content-between">
          <div>
            <div className="btn-group mr-2">
              <SearchInput value={filters.by_keyword} setValue={setBySearch} handleSubmit={handleSubmit} dataProcessing={requesting} />
            </div>
            <div className="btn-group">
              <AnchorButton
                className="f-small"
                onClick={clearFilters}>
                Clear Filters
              </AnchorButton>
            </div>
          </div>
        </div>
        {requesting ? <Loader /> : (<JobServicesTable jobServiceList={jobServiceList} fetchData={fetchData} />)}
      </div>
    </Container>
  )
}
