import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  withRouter, Route, Switch, Redirect
} from "react-router-dom";

// import ProtectedRoute from "components/utils/ProtectedRoute"
// import ExternalRoute from "components/utils/ExternalRoute"
// import InvalidRoute from "components/utils/InvalidRoute"
// import RailsRoute from "./RailsRoute"

import * as reducers from "../reducers";

import { initApplication } from "../actions";

import Notifications from "./Notifications";
import Settings from "../pages/Settings";
import UserNotifications from "../pages/UserNotifications";
import ManageUsers from "../pages/Settings/ManageUsers";
import UserDetails from "../pages/Settings/UserDetails";
import NavBar from "./NavBar";
import Jobs from "../pages/Jobs";
import AccountSettings from "../pages/Settings/AccountSettings";
import MyGear from "../pages/Settings/AccountSettings/MyGear"
import Contacts from "../pages/Contacts";
import NewContactForm from "../pages/Contacts/NewContactForm";
import NewOrganizationForm from "../pages/Contacts/NewOrganizationForm";
import OrganizationEdit from "../pages/Contacts/OrganizationEdit";
import Gears from "../pages/Settings/Gear";
import GearDetails from "../pages/Settings/GearDetails";
import NewGearCard from "../pages/Settings/GearDetails/NewGearCard";
import NewGearHistoryRecordCard from "../pages/Settings/GearDetails/GearHistoryRecords/NewGearHistoryRecordCard";
import GearHistoryDetail from "../pages/Settings/GearDetails/GearHistoryRecords/GearHistoryDetail";
import ContactEdit from "../pages/Contacts/contactEdit";
import Homepage from "../pages/Homepage";
import DocketDetails from "../pages/Dockets/DocketDetails";

import ContactView from "../pages/Contacts/contactView";
import CustomerPortal from "../pages/Customer/CustomerPortal";
import BookingTimeslot from "../pages/Customer/BookingTimeslot";
import NavBarCustomer from "./Customer/NavBar";
import JobDetailsNew from "../pages/JobDetails";
import SchedulesPage from "../pages/Schedules";
import SchedulesMapViewsPage from "../pages/Schedules/MapViews";
import DraftingPage from "../pages/Drafting";
import AccountsPage from "../pages/Accounts";
import Performance from "../pages/Performance";
import RegisteredSurveyorsPage from "../pages/RegisteredSurveyors";
import RegisteredSurveyorActivities from "../pages/Activities";
import RegisteredSurveyorActivitiesDetails from "../pages/Activities/TaskDetails";
import JobServices from "../pages/JobServices";
import FlaggedJobs from "../pages/FlaggedJobs";
import ManageHolidays from "../pages/Settings/ManageHolidays";
import EditHoliday from "../pages/Settings/HolidayDetails/EditHoliday";
import NewHoliday from "../pages/Settings/HolidayDetails/NewHoliday";
import ProcessingPage from "../pages/Processing";
import { ASSET_ONLY } from "../../../utils/constants";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previousLocation: undefined,
      modalOpen: false
    };

    props.initApplication();
  }

  static getDerivedStateFromProps(props, state) {
    const modalOpen = ((props.location.state || {}).isModal || false);
    const { currentRole } = props

    let prevLocation = props.location;

    if (props.history.action !== "POP" && !modalOpen) {
      prevLocation = props.location;
    } else {
      prevLocation = state.previousLocation;
    }

    return {
      ...state,
      ...{
        modalOpen,
        currentRole,
        previousLocation: prevLocation
      }
    };
  }

  componentDidUpdate () {
    // $('.tooltip').remove()
  }

  render() {
    const { currentRole, location: { pathname }, currentUser: { attributes: { firstname } } } = this.props;
    const darkBg = (/\/settings\/manage-users\//g.test(pathname))
      || (/\/settings\/gears\//g.test(pathname))
      || (pathname === "/contacts/new")
      || (/\/contacts\//g.test(pathname) && /\/edit/g.test(pathname))
      || (/\/dockets\//g.test(pathname))
      || (/\/organizations\//g.test(pathname))
      || (/\/registered-surveyors-activities\//g.test(pathname))

    const isAssetsOnlyRole = currentRole.includes(ASSET_ONLY)

    return (
      <Switch>
        <Route
          path="/customer-portal"
          render={() => (
            <div>
              <NavBarCustomer />
              <Switch>
                <Route exact path="/customer-portal/:secureToken" component={CustomerPortal} />
                <Route exact path="/customer-portal/:secureToken/booking/jobs/:id" component={BookingTimeslot} />

                <Route path="/customer-portal/" component={CustomerPortal} />
              </Switch>
            </div>
          )} />

        <Route
          path="/"
          render={() => (
            <div className={darkBg ? "dark-bg" : ""}>
              <Notifications />
              <NavBar currentRole={currentRole} />
              <div className={`content ${pathname}`}>
                { isAssetsOnlyRole ? (
                  <Switch>
                    <Route exact path="/" component={MyGear} history />
                    <Route exact path="/settings/account-settings" component={AccountSettings} />
                    <Route exact path="/settings/my-gear" component={MyGear} />
                    <Route exact path="/settings/gears/:id" component={GearDetails} />
                    <Route exact path="/settings/gears/history/:id" component={GearHistoryDetail} />
                  </Switch>
                ) : (
                  <Switch>
                    <Route exact path="/" component={Homepage} history />
                    <Route exact path="/jobs" component={Jobs} history />
                    <Route exact path="/jobs/:id/job_services/:job_service_id" component={JobDetailsNew} />
                    <Route exact path="/contacts" component={Contacts} />
                    <Route exact path="/contacts/new" component={NewContactForm} history={history} />
                    <Route exact path="/organizations/new" component={NewOrganizationForm} history={history} />
                    <Route exact path="/contacts/:id/edit" component={ContactEdit} history={history} />
                    <Route exact path="/organizations/:id/edit" component={OrganizationEdit} history={history} />
                    <Route exact path="/contacts/:id" component={ContactView} history={history} />
                    <Route exact path="/schedules" component={SchedulesPage} />
                    <Route exact path="/schedules/map-views" component={SchedulesMapViewsPage} />
                    <Route exact path="/drafting" component={DraftingPage} />
                    <Route exact path="/accounts" component={AccountsPage} />
                    <Route exact path="/processing" component={ProcessingPage} />
                    <Route exact path="/performance/:type?" component={Performance} />
                    <Route exact path="/live-job-status" component={JobServices} />
                    <Route exact path="/flagged-jobs" component={FlaggedJobs} />
                    <Route exact path="/notifications" component={UserNotifications} />
                    <Route exact path="/registered-surveyors" component={RegisteredSurveyorsPage} />
                    <Route exact path="/registered-surveyors-activities" component={RegisteredSurveyorActivities} />
                    <Route exact path="/registered-surveyors-activities/:id" component={RegisteredSurveyorActivitiesDetails} />
                    <Route exact path="/settings" component={Settings} />
                    <Route exact path="/settings/manage-users" component={ManageUsers} />
                    <Route exact path="/settings/manage-users/:id" component={UserDetails} />
                    <Route exact path="/settings/gears" component={Gears} />
                    <Route exact path="/settings/gears/new" component={NewGearCard} />
                    <Route exact path="/settings/gears/:id" component={GearDetails} />
                    <Route exact path="/settings/gears/:id/gear-history-record/new" component={NewGearHistoryRecordCard} />
                    <Route exact path="/settings/gears/history/:id" component={GearHistoryDetail} />
                    <Route exact path="/settings/account-settings" component={AccountSettings} />
                    <Route exact path="/settings/my-gear" component={MyGear} />
                    <Route exact path="/dockets/:id" component={DocketDetails} />
                    <Route exact path="/settings/manage-holidays" component={ManageHolidays} />
                    <Route exact path="/settings/manage-holidays/new" component={NewHoliday} />
                    <Route exact path="/settings/manage-holidays/:id" component={EditHoliday} />
                    <Route path="/">
                      <div className="container-fluid">
                        <h5>{`React page not implemented path: ${pathname}`}</h5>
                      </div>
                    </Route>
                  </Switch>
                )}
              </div>
            </div>
          )} />
      </Switch>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: reducers.getCurrentUser(state),
  currentRole: reducers.getCurrentRole(state)
});

export default withRouter(connect(mapStateToProps, {
  initApplication
})(Main));
