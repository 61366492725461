import normalize from "json-api-normalizer"
import customAxios from "./customAxios";

export const getFieldWorkJobServices = (baseUrl, filters) => {
  const path = `${baseUrl}/job_services/field_works`

  return customAxios.get(path, { params: filters })
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const getJobService = (baseUrl, filters) => {
  const path = `${baseUrl}/job_services/${filters.job_service_id}`

  return customAxios.get(path, filters)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const getJobServicesByJob = (baseUrl, jobId) => {
  const path = `${baseUrl}/job_services`
  return customAxios.get(path, { params: { job_id: jobId } })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const getJobServicesByJobNo = (baseUrl, jobNo) => {
  const path = `${baseUrl}/job_services/job_services_by_job_no`
  return customAxios.get(path, { params: { job_no: jobNo } })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
}

export const getJobServicesForRsTask = (baseUrl, by_keyword) => {
  const path = `${baseUrl}/job_services/job_services_for_rs_task`
  return customAxios.get(path, { params: { by_keyword } })
    .then(({ data, status }) => ({ response: normalize(data, { endpoint: "additional_info" }), status }))
    .catch(error => ({ error }));
}

export const getAllJobServices = (baseUrl, filters) => {
  const path = `${baseUrl}/job_services/all_job_services`
  return customAxios.get(path, { params: filters })
    .then(({ data, status }) => ({ response: normalize(data, { endpoint: "additional_info" }), status }))
    .catch(error => ({ error }));
}

export const getJobServiceByScheduledDate = (baseUrl, params) => {
  const path = `${baseUrl}/job_services/job_services_by_scheduled_date`
  return customAxios.get(path, { params: { ...params } })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
} 

export const getJobServicesByClient = (baseUrl, secureToken) => { // client is the primary contact
  const path = `${baseUrl}/external/contacts/${secureToken}/job_services`;

  return customAxios.get(path)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const updateJobServiceTransition = (baseUrl, id, transition, comment = null, surveyorAssignmentId = null, draftingTeam = null, invoiceId = null, dropboxLink = null, additionalParams = {}) => {
  const path = `${baseUrl}/job_services/${id}/${transition}`;
  return customAxios.put(path, {
    comment,
    surveyor_assignment_id: surveyorAssignmentId,
    drafting_team: draftingTeam,
    invoice_id: invoiceId,
    dropbox_link: dropboxLink,
    ...additionalParams
  })
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }));
};

export const requestCollectPaymentOnsiteForJobService = (baseUrl, id, requestAmount) => {
  const path = `${baseUrl}/job_services/${id}/request-collect-payment-onsite`;
  return customAxios.put(path, { request_amount: requestAmount })
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }));
};

export const updateCollectPaymentOnsiteAmount = (baseUrl, id, requestAmount) => {
  const path = `${baseUrl}/job_services/${id}/update-collect-payment-onsite-amount`;
  return customAxios.put(path, { request_amount: requestAmount })
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }));
};

export const markJobServiceAsCollectedPaymentOnsite = (baseUrl, id, params) => {
  const path = `${baseUrl}/job_services/${id}/collected-payment-onsite`;
  return customAxios.put(path, params)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }));
};

export const revokeJobServiceCollectPaymentOnsite = (baseUrl, id) => {
  const path = `${baseUrl}/job_services/${id}/revoke-collect-payment-onsite`;
  return customAxios.put(path)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }));
};

export const checkoutJobService = (baseUrl, id, surveyorAssignmentId, notes, percentage, multipleCheckouts) => {
  const path = `${baseUrl}/job_services/${id}/check-out`;
  return customAxios.put(path, {
    notes, surveyor_assignment_id: surveyorAssignmentId, percentage, multiple_checkouts: multipleCheckouts
  })
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }));
};

export const uploadFile = (baseUrl, id, formData) => {
  const path = `${baseUrl}/job_services/${id}/upload`;
  return customAxios.put(path, formData)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }));
};

export const getJobServiceByAddress = (baseUrl, id, page, perPage) => {
  const path = `${baseUrl}/job_services/${id}/job_services_by_address`;
  return customAxios.get(path, { params: { page, per_page: perPage } })
    .then(({ data, status }) => ({ response: normalize(data, { endpoint: "additional_info" }), status }))
    .catch(error => ({ error }));
};

export const getJobServiceByAddressCount = (baseUrl, id, includeCurrentJobService = false) => {
  const path = `${baseUrl}/job_services/${id}/job_services_by_address_count`;
  return customAxios.get(path, { params: { include_current_job_service: includeCurrentJobService } })
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }));
};

export const linkInvoiceFromXero = (baseUrl, id, params, linkMethod = "auto") => {
  const endpoint = linkMethod === "auto" ? "link-invoice" : "manual-link-invoice"
  const path = `${baseUrl}/job_services/${id}/${endpoint}`;
  return customAxios.put(path, params)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }));
};

export const sendInvoiceForJobService = (baseUrl, id, transition, params) => {
  const path = `${baseUrl}/job_services/${id}/${transition}`;
  return customAxios.put(path, params)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }));
};

export const updateJobServiceDetails = (baseUrl, id, transition, params) => {
  const path = `${baseUrl}/job_services/${id}/${transition}`;
  return customAxios.put(path, params)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }));
};

export const upsertJobServiceRsReportDraft = (baseUrl, id, transition, params) => {
  const path = `${baseUrl}/job_services/${id}/${transition}`;
  return customAxios.put(path, params)
    .then(({ status }) => ({ status }))
    .catch(error => ({ error }));
};

export const generateJobServiceRsReport = (baseUrl, id, params) => {
  const path = `${baseUrl}/job_services/${id}/generate-rs-report-pdf-file`;
  return customAxios.put(path, params)
    .then(({ status }) => ({ status }))
    .catch(error => ({ error }));
};

export const getJobServicesRsReports = (baseUrl, id) => {
  const path = `${baseUrl}/job_services/${id}/registered-surveyor-reports`;
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: { drafts: normalize(data?.json?.drafts), files: normalize(data?.json?.files) }, status }))
    .catch(error => ({ error }));
};

export const getJobServicesToBeBooked = (baseUrl, filters) => {
  const path = `${baseUrl}/job_services/field_works/to-book`;

  return customAxios.get(path, { params: filters })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const updateSurveyorAssignmentDate = (baseUrl, jobServiceId, params) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/update-assignment-date`
  return customAxios.put(path, params)
    .then(({ data, status }) => ({ response: normalize(data), status }))
};

export const getSuggestedSurveyor = (baseUrl, id) => {
  const path = `${baseUrl}/job_services/${id}/suggested-surveyor`;
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }));
};

export const assignSurveyor = (baseUrl, jobServiceId, params) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/assign-surveyor`
  return customAxios.put(path, params)
    .then(({ status }) => ({ status }))
    .catch(error => ({ error }));
};

export const unassignSurveyor = (baseUrl, jobServiceId, params) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/unassign-surveyor`
  return customAxios.put(path, params)
    .then(({ status }) => ({ status }))
    .catch(error => ({ error }));
};

export const assignDraftman = (baseUrl, jobServiceId, params) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/assign-draftman`
  return customAxios.put(path, params)
    .then(({ status }) => ({ response: status }))
    .catch(error => ({ error }));
};

export const assignAdditionalDraftman = (baseUrl, jobServiceId, params) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/assign-additional-draftman`
  return customAxios.put(path, params)
    .then(({ status }) => ({ response: status }))
    .catch(error => ({ error }));
};

export const unassignAdditionalDraftman = (baseUrl, jobServiceId, params) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/unassign-additional-draftman`
  return customAxios.put(path, params)
    .then(({ status }) => ({ response: status }))
    .catch(error => ({ error }));
};

export const assignRegSurveyor = (baseUrl, jobServiceId, params) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/assign-registered-surveyor`
  return customAxios.put(path, params)
    .then(({ status }) => ({ response: status }))
    .catch(error => ({ error }));
};

export const getFlaggedJobs = (baseUrl, filters, params) => {
  const path = `${baseUrl}/job_services/field_works/flagged-jobs`

  return customAxios.get(path, { params: { ...filters, ...params } })
    .then(({ data, status }) => ({ response: normalize(data, { endpoint: "additional_info" }), status }))
    .catch(error => ({ error }))
}

export const postBookingAsClient = (baseUrl, data) => {
  // requires secure_token
  const path = `${baseUrl}/job_services/jobs/${data.jobId}`

  return customAxios.post(path, { params: { booking_time: data.bookingTime, secure_token: data.secureToken } })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const updateJobService = (baseUrl, jobServiceId, params) => {
  const path = `${baseUrl}/job_services/${jobServiceId}`

  return customAxios.put(path, params)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    // .then((dd) => (console.log(dd)))
    .catch(error => console.log(error))
}

export const updateJobServicePrice = (baseUrl, id, params) => {
  const path = `${baseUrl}/job_services/${id}/update-price`

  return customAxios.put(path, params)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }));
}

export const updateJobServiceRescheduleFee = (baseUrl, id, params) => {
  const path = `${baseUrl}/job_services/${id}/update-reschedule-fee`

  return customAxios.put(path, params)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }));
}

export const updateJobServiceCancellationFee = (baseUrl, id, params) => {
  const path = `${baseUrl}/job_services/${id}/update-cancellation-fee`

  return customAxios.put(path, params)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }));
}

export const updateJobServiceProduct = (baseUrl, id, params) => {
  const path = `${baseUrl}/job_services/${id}/update-product`

  return customAxios.put(path, params)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }));
}

export const discardJobService = (baseUrl, jobServiceId) => {
  const path = `${baseUrl}/job_services/field_works/${jobServiceId}/discard`

  return customAxios.put(path)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}
export const updateFieldWorkJobServiceStatus = (baseUrl, jobServiceId, newStatus) => {
  const path = `${baseUrl}/job_services/field_works/${jobServiceId}/status`

  return customAxios.put(path, { job_service: { new_status: newStatus } })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const updateFiledWorkJobServiceBooking = (baseUrl, jobServiceId, bookingTime) => {
  const path = `${baseUrl}/job_services/field_works/${jobServiceId}/booking_time`

  return customAxios.put(path, { job_service: { time: bookingTime } })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const multipleUploadFile = (baseUrl, id, formData) => {
  const path = `${baseUrl}/job_services/${id}/multiple_upload`;
  return customAxios.post(path, formData)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }));
};

export const markUploadedFilesAsType = (baseUrl, id, fileIds, fileType, updateOnlyFileIds = true) => {
  const path = `${baseUrl}/job_services/${id}/mark-uploaded-files-as`;
  return customAxios.put(path, { uploaded_file_ids: fileIds, mark_as_type: fileType, update_only_file_ids: updateOnlyFileIds })
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }));
};

export const getJobServicesDrafting = (baseUrl, filters) => {
  const path = `${baseUrl}/job_services/drafting`;
  return customAxios.get(path, { params: filters })
    // .then(({ data, status }) => ({ response: normalize(data), status }))
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }));
}

export const getJobServicesRegSurveyors = (baseUrl, filters) => {
  const path = `${baseUrl}/job_services/registered-surveyors`;
  return customAxios.get(path, { params: filters })
    // .then(({ data, status }) => ({ response: normalize(data), status }))
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }));
}

export const getJobServicesAccounts = (baseUrl, filters) => {
  const path = `${baseUrl}/job_services/accounts`;
  return customAxios.get(path, { params: filters })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
}

export const getJobServicesAccountsConstants = baseUrl => {
  const path = `${baseUrl}/job_services/accounts/constants`;
  return customAxios.get(path)
    .catch(error => ({ error }))
}

export const getJobServiceAsssignedSurveyor = (baseUrl, userId) => {
  const path = `${baseUrl}/job_services/dashboard/surveyors`
  return customAxios.get(path, { params: { user_id: userId } })
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const getJobServiceAsssignedDraftsman = (baseUrl, filters) => {
  const path = `${baseUrl}/job_services/dashboard/draftsman`
  return customAxios.get(path, { params: filters })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const getActiveJobServiceAsssignedUser = (baseUrl, userId) => {
  const path = `${baseUrl}/job_services/active_assigned_job`
  return customAxios.get(path, { params: { user_id: userId } })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const checkCountOfActiveJobServiceAsssignedUser = (baseUrl, userId) => {
  const path = `${baseUrl}/job_services/has_active_jobs`
  return customAxios.get(path, { params: { user_id: userId } })
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const getReceptionDashboardData = baseUrl => {
  const path = `${baseUrl}/job_services/dashboard/reception`
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const getOperationDashboardData = baseUrl => {
  const path = `${baseUrl}/job_services/dashboard/operations`
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const getRSManagerDashboardData = baseUrl => {
  const path = `${baseUrl}/job_services/dashboard/registered-surveyor-managers`
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const getDraftingManagerDashboardData = (baseUrl, draftingType) => {
  const path = `${baseUrl}/job_services/dashboard/drafting_manager`
  return customAxios.get(path, { params: { drafting_type: draftingType } })
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const getAdminDashboardData = baseUrl => {
  const path = `${baseUrl}/job_services/dashboard/admin`
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const getAccountsDashboardData = baseUrl => {
  const path = `${baseUrl}/job_services/dashboard/accounts`
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const getOverdueDataCount = baseUrl => {
  const path = `${baseUrl}/job_services/dashboard/overdue_data_count`
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const getOverdueJobsCount = baseUrl => {
  const path = `${baseUrl}/job_services/dashboard/overdue_jobs_count`
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const getToInvoiceJobServiceCount = baseUrl => {
  const path = `${baseUrl}/job_services/dashboard/to_invoice_count`
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const getAwaitingPaymentJobServiceCount = baseUrl => {
  const path = `${baseUrl}/job_services/dashboard/awaiting_payment_count`
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const getAwaitingPaymentDepositJobServiceCount = baseUrl => {
  const path = `${baseUrl}/job_services/dashboard/awaiting_payment_deposit_count`
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const getAwaitingPaymentFinalJobServiceCount = baseUrl => {
  const path = `${baseUrl}/job_services/dashboard/awaiting_payment_final_count`
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const getOnHoldJobServiceCount = baseUrl => {
  const path = `${baseUrl}/job_services/dashboard/on_hold_count`
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const getClientDelayedJobServiceCount = baseUrl => {
  const path = `${baseUrl}/job_services/dashboard/client_delayed_count`
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const getAwaitingSendFinalReminderJobServiceCount = baseUrl => {
  const path = `${baseUrl}/job_services/dashboard/awaiting_send_final_reminder`
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const getCanBeMovedForwardJobServiceCount = baseUrl => {
  const path = `${baseUrl}/job_services/dashboard/can_be_moved_forward`
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const getCancelledJobServiceCount = baseUrl => {
  const path = `${baseUrl}/job_services/dashboard/cancelled_count`
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const getNonMatchingInvoiceAmounts = baseUrl => {
  const path = `${baseUrl}/job_services/dashboard/non_matching_invoice_amounts`
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const getJobPackIncompleteCount = baseUrl => {
  const path = `${baseUrl}/job_services/dashboard/job_pack_incomplete_count`
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const sendAccessEmail = (baseUrl, jobServiceId, emailOptions) => {
  const {
    to, cc, additional_cc, message
  } = emailOptions
  const path = `${baseUrl}/job_services/field_works/send-access-email`
  return customAxios.put(path, {
    id: jobServiceId, to, cc, additional_cc, message
  })
    .then(({ data, status }) => ({ response: normalize(data), status }))
}

export const updateDraftsmanOrder = (baseUrl, jobServiceId, sortIndex) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/draftsman-priority`
  return customAxios.put(path, { sort_index: sortIndex })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const updateRsOrder = (baseUrl, jobServiceId, sortIndex) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/registered-surveyor-priority`
  return customAxios.put(path, { sort_index: sortIndex })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const newGpsJobService = (baseUrl, jobServiceId, data) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/new-job-service-gps`;

  return customAxios.post(path, { ...data })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const requestCheckSurvey = (baseUrl, jobServiceId, data = {}) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/request-check-survey`;

  return customAxios.post(path, { ...data })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const newWaeFin = (baseUrl, jobServiceId, data = {}) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/new-wae-fin`;

  return customAxios.post(path, { ...data })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const rescheduleJobService = (baseUrl, jobServiceId, data) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/reschedule`;

  return customAxios.post(path, { ...data })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const writeOffAmountJobService = (baseUrl, id, params) => {
  const path = `${baseUrl}/job_services/${id}/write_off`;

  return customAxios.put(path, params)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }));
};

export const cancelJobService = (baseUrl, jobServiceId, data) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/cancel`;

  return customAxios.patch(path, { ...data })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

// export const importJobPacks = (baseUrl, jobServiceId) => {
//   const path = `${baseUrl}/job_services/${jobServiceId}/import-job-packs`;

//   return customAxios.post(path)
//     .then(({ data, status }) => ({ response: normalize(data), status }))
//     .catch(error => ({ error }));
// };

export const bookInFlaggedJob = (baseUrl, jobServiceId) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/book_in_flagged_job`;

  return customAxios.post(path)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const updatePrimarySurveyor = (baseUrl, jobServiceId, surveyorId) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/primary-surveyor`;

  return customAxios.patch(path, { surveyor_id: surveyorId })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const addAdditionalScheduledDay = (baseUrl, jobServiceId) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/schedules`;

  return customAxios.post(path)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const createNote = (baseUrl, id, userId, params) => {
  const path = `${baseUrl}/job_services/${id}/create-note`

  return customAxios.post(path, { user_id: userId, ...params })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }));
};

export const updateNote = (baseUrl, id, params) => {
  const path = `${baseUrl}/job_services/${id}/update-note`

  return customAxios.put(path, { ...params })
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }));
};

export const updateActivityComment = (baseUrl, id, params) => {
  const path = `${baseUrl}/job_services/${id}/update-audit-comment`

  return customAxios.put(path, { ...params })
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }));
};

export const prepareReadyForReleaseFiles = (baseUrl, jobServiceId, toReleasedFiles) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/prepare-ready-for-release-files`

  return customAxios.put(path, { id: jobServiceId, to_released_files: toReleasedFiles })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const zipAllSignedPlans = (baseUrl, jobServiceId) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/zip-all-signed-plans`

  return customAxios.put(path, { id: jobServiceId })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const checkZipAllSignedPlans = (baseUrl, jobServiceId) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/check-zip-all-signed-plans`
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const sendReleasedFiles = (baseUrl, emailOptions, message, jobServiceId, fileId) => {
  const path = `${baseUrl}/job_services/send-released-files`

  return customAxios.put(path,
    {
      to: emailOptions.to,
      cc: emailOptions.cc,
      subject: emailOptions.subject,
      additional_cc: emailOptions.additional_cc,
      message,
      id: jobServiceId,
      file_id: fileId
    })
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const zipPhotos = (baseUrl, jobServiceId, shouldZipPhotos) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/zip-photos`

  return customAxios.put(path, { id: jobServiceId, should_zip_photos: shouldZipPhotos })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const downloadAllPhotos = (baseUrl, jobServiceId) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/download-all-photos`

  return customAxios.put(path, { id: jobServiceId })
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const downloadAllSelectedFiles = (baseUrl, jobServiceId, selectedFileIds) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/download-all-selected-files`
  return customAxios.put(path, { id: jobServiceId, selected_file_ids: selectedFileIds })
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const hasNearbyJobsFutureBookings = async (baseUrl, jobServiceId) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/has-nearby-jobs-future-bookings`
  return customAxios.get(path)
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}

export const nearbyJob = async (baseUrl, jobServiceId, params) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/nearby-jobs`
  try {
    const { data, status } = await customAxios.get(path, { params })
    return ({ response: Object.keys(normalize(data).nearbyJob).map(key => normalize(data).nearbyJob[key]), status })
  } catch (error) {
    return ({ error })
  }
}

export const updateInvocie = async (baseUrl, jobServiceId, id, invoiceType, price) => {
  const path = `${baseUrl}/job_services/${jobServiceId}/update-invoice`
  return customAxios.put(path, {
    id: jobServiceId, invoice_id: id, invoice_type: invoiceType, price
  })
    .then(({ data, status }) => ({ response: data.json, status }))
    .catch(error => ({ error }))
}
