import React from "react"

const CommentBox = ({ comment, setComment, placeholder }) => {
  const setCommentHandler = e => {
    setComment(e.target.value)
  }

  return (
    <div className="row mt-3 form-group border-0 comment-input">
      <input
        type="text"
        className="form-control"
        placeholder={placeholder || "Add Comment"}
        value={comment}
        onChange={setCommentHandler} />
    </div>
  )
}

export default CommentBox
