import customAxios from "./customAxios";

export const getOperationsReports = (baseUrl, filters) => {
  const path = `${baseUrl}/performance_reports`

  return customAxios.get(path, { params: filters })
    .then(data => (data))
    .catch(error => ({ error }))
}

export const getRegisteredSurveyorReports = (baseUrl, filters) => {
  const path = `${baseUrl}/performance_reports/registered_surveyor_reports`

  return customAxios.get(path, { params: filters })
    .then(data => (data))
    .catch(error => ({ error }))
}

export const getDraftingReports = (baseUrl, filters) => {
  const path = `${baseUrl}/performance_reports/drafting_reports`

  return customAxios.get(path, { params: filters })
    .then(data => (data))
    .catch(error => ({ error }))
}

export const getSurveyors = baseUrl => {
  const path = `${baseUrl}/users/surveyors`

  return customAxios.get(path)
    .then(data => (data))
    .catch(error => ({ error }))
}
