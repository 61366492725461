import React from "react"
import GearRecord from "./GearRecord"

export default function GearsTable({ gears, fetchData, gearIds }) {
  return (
    <div className="table-responsive">
      <table className="table table-listing table-hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>Item Description</th>
            <th>Equipment Type</th>
            <th>Status</th>
            <th className="text-center">Service Date</th>
            <th>User</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {gearIds.map(id => {
            const gear = gears[id]
            return gear && <GearRecord key={`table-row-${id}`} gear={gear} fetchData={fetchData} />
          })}
        </tbody>
      </table>
      {gears.length === 0 && <div>Table is Empty</div>}
    </div>
  )
}
