import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { createGlobalStyle } from "styled-components";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import Select from "react-select"
import { getBaseUrl } from "../../../reducers";
import { getProduct } from "../../../../../requesters/productRequester"
import { rescheduleJobService } from "../../../../../requesters/jobServiceRequester"

const DatePickerWrapperStyles = createGlobalStyle`
.date_picker.full-width {
    width: 100%;
}
`;

const RescheduleBookingModal = ({
  show, onHide, job, reloadData, jobService
}) => {
  const baseUrl = useSelector(getBaseUrl)

  const [currentJobAddress, setCurrentJobAddress] = useState(undefined)
  const [currentNotes, setCurrentNotes] = useState("")
  const [newBookingDate, setNewBookingdate] = useState(null)
  const [rescheduleFee, setRescheduleFee] = useState(0)
  const [product, setProduct] = useState(undefined)
  const [currentJsId, setCurrentJsId] = useState("")

  useEffect(() => {
    setCurrentNotes("")
    setNewBookingdate(null)
    setProduct(jobService?.attributes?.label_id)
  }, [show])

  const checkAddress = () => {
    if (job && jobService) {
      const { attributes: { jobAddress } } = job
      const { id, attributes: { locationId } } = jobService

      setCurrentJobAddress({ value: locationId, label: jobAddress })
      setCurrentJsId(id)
    }
  }

  useEffect(() => {
    checkAddress()
  }, [])

  const onSaveHandler = () => {
    const params = {
      comment: currentNotes,
      rescheduled_at: newBookingDate,
      reschedule_fee: rescheduleFee
    }

    rescheduleJobService(baseUrl, currentJsId, params)
    .then(res => {
        if (res.status === 201) {
            const jobServiceId = Object.keys(res?.response?.jobService)[0];
            window.location.href = `/jobs/${jobService.attributes.jobId}/job_services/${jobServiceId}`
        }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <div>
          <h2 className="modal-title">Reschedule Job</h2>
          {/* <p>For ongoing jobs requiring additional site visit</p> */}
        </div>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <div className="mb-4">
          <strong>Job Details</strong>
        </div>
        <form>
          <div className="form-group row">
            <label className="col-3 col-form-label">Job Address*</label>
            <div className="col-7">
              <input
                className="form-control"
                disabled
                type="text"
                value={currentJobAddress?.label} />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Job Service*</label>
            <div className="col-7">
              <input
                className="form-control"
                disabled
                type="text"
                value={jobService.attributes.code} />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Reschedule Date*</label>
            <div className="col-7">
              <div className="d-block">
                <DatePicker
                  wrapperClassName="date_picker full-width"
                  required
                  selected={newBookingDate}
                  onChange={e => setNewBookingdate(e)}
                  showTimeSelect
                  closeOnScroll={e => e.target === document}
                  className="form-control"
                  dateFormat="dd MMM yyyy, ha" />
                <DatePickerWrapperStyles />
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Reschedule Fee</label>
            <div className="col-7">
              <input
                className="form-control"
                placeholder="Optional"
                type="decimal"
                onChange={e => setRescheduleFee(e.target.value)}
                value={rescheduleFee} />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Comments</label>
            <div className="col-7">
              <input
                className="form-control"
                placeholder="Optional"
                type="text"
                onChange={e => setCurrentNotes(e.target.value)}
                value={currentNotes} />
            </div>
          </div>
        </form>
        <hr />
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-red btn-lg"
          onClick={onSaveHandler}
          disabled={!newBookingDate}
          type="submit">
          Reschedule Booking
        </button>
      </div>
    </Modal>
  )
}
export default RescheduleBookingModal
