import { param } from "jquery"
import normalize from "json-api-normalizer"
import customAxios from "./customAxios";

export const getUploadedFile = url => customAxios.get(url)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }))

export const deleteUpload = (baseUrl, id) => {
  const path = `${baseUrl}/uploads/${id}`

  return customAxios.delete(path)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const updateFile = (baseUrl, id, params) => {
  const path = `${baseUrl}/uploads/${id}/update-file`

  return customAxios.put(path, params)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const filesReadyForRelease = (baseUrl, jobServiceId, fileIds) => {
  const path = `${baseUrl}/uploads/${jobServiceId}/files-ready-for-release`

  return customAxios.put(path, { id: jobServiceId, uploaded_file_ids: fileIds })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}
