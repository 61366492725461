import React from "react"
import ContactRow from "./ContactRow"

export default function ContactsTable({ contacts, onDeleteContact }) {
  return (
    <div className="table-responsive">
      <table className="table table-listing table-hover">
        <thead>
          <tr>
            <th>Contact Name</th>
            <th>Client Type</th>
            <th>Company</th>
            <th>Related Jobs</th>
            <th>Email</th>
            <th>Phone</th>
          </tr>
        </thead>
        <tbody>
          {contacts.map(contact => <ContactRow key={`table-row-${contact.id}`} contact={contact} onDeleteContact={onDeleteContact} />)}
        </tbody>
      </table>
    </div>
  )
}
