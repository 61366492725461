import React from "react"
import { DropdownButton, Dropdown } from "react-bootstrap"

export default function FilterDropdown({
  title, filteredName, setFilter, options, hiddenAllOption
}) {
  return (
    <DropdownButton id="dropdown-basic-button" title={`${title}${filteredName ? `: ${filteredName}` : ": All"}`} variant="light rounded-pill" size="lg">
      {!hiddenAllOption && (
        <Dropdown.Item
          as="button"
          onClick={() => { setFilter(undefined) }}>
          All
        </Dropdown.Item>
      )}
      {options && options.map(option => {
        const [name, slug] = option
        return (
          <Dropdown.Item
            key={`tole-${slug}`}
            as="button"
            onClick={() => { setFilter(slug) }}>
            {name}
          </Dropdown.Item>
        )
      })}
    </DropdownButton>
  )
}
