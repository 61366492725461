import { notepadIconDark } from "components/Icons"
import { format } from "date-fns"
import _ from "lodash"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import {
  OPERATION_MANAGER, SURVEYORS
} from "utils/constants"
import { roleCheck } from "utils/index"
import { getCurrentUser, getCurrentRole } from "../../../reducers"
import CreateAndUpdateDocketDescriptionModal from "./CreateAndUpdateDocketDescriptionModal"

export default function DocketDescriptions({
  docketDescriptions, reloadData, docketId, status, loading, setLoading
}) {
  const [showModal, setShowModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [docketDescriptionSelected, setDocketDescriptionSelected] = useState(null)
  const currentUser = useSelector(getCurrentUser)
  const currentTeamRole = currentUser.attributes.current_team_role
  const currentRole = useSelector(getCurrentRole)
  const systemRole = currentUser.attributes.role
  const isAdminOrOperations = roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER)
  const isSurveyor = roleCheck(systemRole, currentRole, SURVEYORS)

  const canAddItem = (["new", "no_signature", "signed"].includes(status) && isAdminOrOperations) || (["new"].includes(status) && isSurveyor)

  return (
    <div className="card border-0 mt-3">
      <div className="card-body p-4">
        <div className="d-flex justify-content-between align-items-center">
          <h3>Docket Descriptions</h3>
          {canAddItem && (
            <button
              disabled={loading}
              onClick={() => {
                setDocketDescriptionSelected(null)
                setIsEdit(false)
                setTimeout(() => setShowModal(true), 50)
              }}
              type="button"
              className="btn btn-outline-primary">
              + Add Item
            </button>
          )}
        </div>
        <div className="table-responsive mt-2">
          <table className="table table-listing table-hover">
            <thead>
              <tr>
                <th>Description of works</th>
                <th>Marks</th>
                <th>Benchmark</th>
                {/* <th>Creator</th>
                <th>Last Update by</th> */}
                <th>Created date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {docketDescriptions.map(docketDescription => {
                const attributes = _.get(docketDescription, "attributes", {})
                return (
                  <tr key={docketDescription.id}>
                    <td>{attributes?.descriptionOfWork}</td>
                    <td>{attributes?.marks}</td>
                    <td>{attributes?.benchmarks}</td>
                    {/* <td>{ }</td>
                    <td>{attributes?.updatedByUser}</td> */}
                    <th>{attributes?.createdAt ? format(new Date(attributes.createdAt), "d MMM yyyy") : ""}</th>
                    <td>
                      <button
                        disabled={!canAddItem}
                        className="btn btn-link p-0"
                        onClick={() => {
                          setDocketDescriptionSelected({ id: docketDescription.id, ...attributes })
                          setIsEdit(true)
                          setTimeout(() => {
                            setShowModal(true)
                          }, 50);
                        }}
                        type="button">
                        <img
                          src={notepadIconDark}
                          className="cursor-pointer" />
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <CreateAndUpdateDocketDescriptionModal
          setLoading={setLoading}
          loading={loading}
          show={showModal}
          setShow={setShowModal}
          reloadData={reloadData}
          dataDocketDescription={docketDescriptionSelected}
          docketId={docketId}
          isEdit={isEdit}
          setIsEdit={setIsEdit} />
      </div>
    </div>
  )
}
