import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { updateJobService } from "requesters/jobServiceRequester";
import {
  ACCOUNTS_MANAGER, OPERATION_MANAGER, OPERATIONS, SURVEYORS_MANAGER, ACCOUNTS, ADMIN
} from "utils/constants";
import { roleCheck, generalRoleCheck } from "utils/index";
import { getBaseUrl, getCurrentUser, getCurrentRole } from "../../../reducers";

const EditProjectModal = ({
  show, onHide, job, reloadData, jobService, limitedInfo
}) => {
  const [loading, setLoading] = useState(false)
  const baseUrl = useSelector(getBaseUrl)
  const currentRole = useSelector(getCurrentRole)
  const currentUser = useSelector(getCurrentUser)
  const { accountTypes } = useSelector(state => state.options);
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role
  const accountTypeOptions = accountTypes.map(acc => ({ value: acc[0], label: acc[1] }));
  const {
    attributes: {
      jobNo,
      jobAddress,
      clientType,
      jobTitle,
      leadType,
      accountType,
      accountTypeId
    }
  } = job

  const {
    id, attributes: {
      clientDueDateCount,
      estimatedHours,
      jobServiceLabel,
      jobNotes,
      forProjectX: jobForProjectX,
      canChangeProjectXFlag,
      cost,
      operationsNotes,
      clientNotes,
      label,
      outstandingInvoiceFlagged,
      unmatchedInvoiceFlagged,
      isUnmatchedInvoice,
      disabledNotMatchAmount,
      disabledOutstandingInvoice,
      canBeMovedForward,
      awaitingDocuments,
    }
  } = jobService
  const [currentNotes, setCurrentNotes] = useState(jobNotes)
  const [currentLabel, setCurrentLabel] = useState(jobServiceLabel)
  const [newEstimatedHours, setNewEstimatedHours] = useState(estimatedHours)
  const [newClientDueDateCount, setNewClientDueDateCount] = useState(clientDueDateCount)
  const [newJobTitle, setNewJobTitle] = useState(jobTitle)
  const [currentOperationsNotes, setCurrentOperationsNotes] = useState(operationsNotes)
  const [currentClientNotes, setCurrentClientNotes] = useState(clientNotes)
  const [outstandingInvoice, setOutstandingInvoice] = useState(outstandingInvoiceFlagged)
  const [unMatchedInvoice, setUnMatchedInvoice] = useState(unmatchedInvoiceFlagged)
  const [canBeMovedForwardValue, setCanBeMovedForwardValue] = useState(canBeMovedForward)
  const [awaitingDocumentsValue, setAwaitingDocumentsValue] = useState(awaitingDocuments)
  const [forProjectX, setForProjectX] = useState(jobForProjectX)

  const [currentAccountType, setCurrentAccountType] = useState(accountTypeId);

  const validateForm = () => {
    setLoading(true)
    const payload = {
      operations_notes: currentOperationsNotes,
      client_notes: currentClientNotes,
      notes: currentNotes,
      client_due_date_count: newClientDueDateCount,
      estimated_hours: newEstimatedHours,
      job_title: newJobTitle,
      job_service_label: currentLabel,
      account_type: currentAccountType,
      disabled_outstanding_invoice: !outstandingInvoice,
      disabled_not_match_amount: isUnmatchedInvoice ? !unMatchedInvoice : disabledNotMatchAmount,
      can_be_moved_forward: canBeMovedForwardValue,
      awaiting_documents: awaitingDocumentsValue,
      for_project_x: forProjectX,
    }

    updateJobService(baseUrl, id, payload)
      .then(res => {
        if (res.status === 200) {
          reloadData()
          onHide()
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title><h2 className="modal-title">EDIT PROJECT</h2></Modal.Title>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          {roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) && (
            <>
              <div className="row mb-2">
                <label className="col-4">Job Title</label>
                <div className="col-6">
                  <input className="form-control" value={newJobTitle} onChange={e => setNewJobTitle(e.target.value)} />
                </div>
              </div>
              <div className="row">
                <label className="col-4">Job Address</label>
                <div className="col-8">
                  <p className="mb-0">{jobAddress}</p>
                </div>
              </div>
              <div className="row">
                <label className="col-4">Job No</label>
                <div className="col-6">
                  <p className="mb-0">{jobNo}</p>
                </div>
              </div>
            </>
          )}
          {!limitedInfo && (
            <>
              <div className="row">
                <label className="col-4">Value</label>
                <div className="col-6">
                  <p className="mb-0">{`$${cost}`}</p>
                </div>
              </div>
              <div className="row">
                <label className="col-4">Client Type</label>
                <div className="col-6">
                  <p className="mb-0">{clientType}</p>
                </div>
              </div>
              <div className="row">
                <label className="col-4">Lead Type</label>
                <div className="col-6">
                  <p className="mb-0">{leadType}</p>
                </div>
              </div>
              <div className="row mb-2">
                <label className="col-4">Account Type</label>
                <div className="col-6">
                  {generalRoleCheck(systemRole, currentTeamRole, ACCOUNTS) ? (
                    <Select
                      name="account_type"
                      placeholder="Select"
                      isDisabled={!generalRoleCheck(systemRole, currentTeamRole, ACCOUNTS)}
                      defaultValue={accountTypeOptions.find(option => option.value === accountTypeId)}
                      value={accountTypeOptions.find(option => option.value === currentAccountType)}
                      options={accountTypeOptions}
                      onChange={e => setCurrentAccountType(e ? e.value : "")} />
                  ) : (
                    <p className="mb-0">{accountType}</p>
                  )}
                </div>
              </div>
            </>
          )}

          {roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) && (
            <div className="row">
              <label className="col-4">Job Service</label>
              <div className="col-8">
                <p className="mb-0">{label}</p>
              </div>
            </div>
          )}

          {!limitedInfo && (
            <>
              <div className="row mb-2">
                <label className="col-4">Estimated Hours</label>
                <div className="col-6">
                  <input className="form-control" value={newEstimatedHours} onChange={e => setNewEstimatedHours(e.target.value)} />
                </div>
              </div>

              <div className="row mb-2">
                <label className="col-4">Client Due Date Count</label>
                <div className="col-6">
                  <input className="form-control" value={newClientDueDateCount} onChange={e => setNewClientDueDateCount(e.target.value)} />
                </div>
              </div>

            </>
          )}

          {(roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER)
            || roleCheck(systemRole, currentTeamRole, SURVEYORS_MANAGER))
            ? (
              <div className="row">
                <label className="col-4">Job Label</label>
                <div className="col-6">
                  <input
                    className="form-control mr-5"
                    placeholder="Enter Job Label..."
                    value={currentLabel || ""}
                    maxLength={60}
                    onChange={e => setCurrentLabel(e.target.value)} />
                </div>
              </div>
            ) : null}

          {(roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER)) && (
            <div className="row mt-2">
              <label className="col-4">Awaiting Documents</label>
              <div className="col-6">
                <input
                  checked={awaitingDocumentsValue}
                  className="w-20px h-20px"
                  type="checkbox"
                  onChange={e => setAwaitingDocumentsValue(e.target.checked)} />
              </div>
            </div>
          )}

          {(roleCheck(systemRole, currentRole, ACCOUNTS)
            || currentRole?.includes(ADMIN)) && (outstandingInvoiceFlagged || disabledOutstandingInvoice) && (
              <div className="row mt-2">
                <label className="col-4">Outstanding Invoices</label>
                <div className="col-6">
                  <input
                    checked={outstandingInvoice}
                    className="w-20px h-20px"
                    type="checkbox"
                    onChange={e => setOutstandingInvoice(e.target.checked)} />
                </div>
              </div>
            )
          }

          { (roleCheck(systemRole, currentRole, OPERATIONS) || currentRole?.includes(ADMIN)) && (
            <div className="row mt-2">
              <label className="col-4">Project X</label>
              <div className="col-6">
                <input
                  disabled={!canChangeProjectXFlag}
                  checked={forProjectX}
                  className="w-20px h-20px"
                  type="checkbox"
                  onChange={e => setForProjectX(e.target.checked)} />
              </div>
            </div>
          )}

          {(roleCheck(systemRole, currentRole, ACCOUNTS)
            || currentRole?.includes(ADMIN) || roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER)) && isUnmatchedInvoice && (
              <div className="row mt-2">
                <label className="col-4">Unmatched Invoice</label>
                <div className="col-6">
                  <input
                    checked={unMatchedInvoice}
                    className="w-20px h-20px"
                    type="checkbox"
                    onChange={e => setUnMatchedInvoice(e.target.checked)} />
                </div>
              </div>
            )
          }

          {(roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) || currentRole?.includes(ADMIN)) && (
            <div className="row mt-2">
              <label className="col-4">Booking can be moved forward</label>
              <div className="col-6">
                <input
                  checked={canBeMovedForwardValue}
                  className="w-20px h-20px"
                  type="checkbox"
                  onChange={e => setCanBeMovedForwardValue(e.target.checked)} />
              </div>
            </div>
          )}

          {roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) && (
            <div className="form-group">
              <label>Job Comments</label>
              <ReactQuill
                placeholder="e.g. existing dwelling, any substantial trees"
                value={currentNotes || ""}
                onChange={value => setCurrentNotes(value)} />
            </div>
          )}
          {(roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) || roleCheck(systemRole, currentTeamRole, ACCOUNTS_MANAGER)) && (
            <>
              <div className="form-group">
                <label>Operations Notes</label>
                <ReactQuill
                  placeholder="Enter Operations Notes..."
                  value={currentOperationsNotes || ""}
                  onChange={value => setCurrentOperationsNotes(value)} />
              </div>
              <div className="form-group">
                <label>Client Notes</label>
                <ReactQuill
                  placeholder="Enter Client Notes..."
                  value={currentClientNotes || ""}
                  onChange={value => setCurrentClientNotes(value)} />
              </div>
            </>
          )}
        </form>
        <div className="modal-footer d-flex justify-content-between p-0 mb-4 mt-5">
          <button className="btn btn-light btn-lg" data-dismiss="modal" type="button" onClick={onHide}> Cancel</button>
          <button
            className="btn btn-red btn-lg"
            onClick={validateForm}
            disabled={loading || (
              unMatchedInvoice === unmatchedInvoiceFlagged && outstandingInvoice === outstandingInvoiceFlagged
              && currentOperationsNotes === operationsNotes && currentClientNotes === clientNotes && currentNotes === jobNotes
              && newEstimatedHours === estimatedHours && newClientDueDateCount === clientDueDateCount && newJobTitle === jobTitle
              && currentLabel === jobServiceLabel && currentAccountType === accountTypeId && canBeMovedForward === canBeMovedForwardValue
              && awaitingDocuments === awaitingDocumentsValue && jobForProjectX === forProjectX
            )}
            type="submit">
            Save Changes
            {loading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
          </button>
        </div>
      </div>
    </Modal>
  )
}
export default EditProjectModal
