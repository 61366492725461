import React, { useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { useSelector } from "react-redux"
import { updateJobServiceTransition } from "requesters/jobServiceRequester"
import {
  PRODUCT_CODE_NEED_MASTER_BOUNDARY_FILE, REGISTERED_SURVEYORS,
  REGISTERED_SURVEYORS_MANAGER,
  REGISTERED_SURVEYORS_USER,
} from "utils/constants"
import { roleCheck } from "utils/index"
import { getBaseUrl, getCurrentRole } from "../../../../reducers"
import ConfirmationModal from "../../modals/ConfirmationModal"
import CommentBox from "./CommentBox"

import CriticalNoteJobServiceModal from "../../modals/CriticalNoteJobServiceModal"
import FlagJobServiceModal from "../../modals/FlagJobServiceModal"
import { PRODUCT_CODE_PASS_THE_RESOLVE_FLAGGING_STEP } from "../../../../../../utils/constants"

const RegisteredSurveyor = ({
  notResolvedBookInJob,
  jobService,
  reloadData,
  flagJobServiceSource,
  setFlagJobServiceSource,
  systemRole,
  currentTeamRole,
  currentUser,
  comment,
  setComment
}) => {
  const {
    id, attributes: {
      registeredSurveyorName,
      registeredSurveyorId,
      workflow,
      cost,
      jobNo,
      forProjectX,
      projectXJobNo,
      jobTitle,
      criticalNote,
      code,
      masterBoundaryFile,
      docketResolved
    }
  } = jobService
  const displayJobNo = forProjectX ? projectXJobNo : jobNo
  const baseUrl = useSelector(getBaseUrl)
  const [showFlagModal, setShowFlagModal] = useState(false)
  const [showCriticalNoteModal, setShowCriticalNoteModal] = useState(false)
  const [showAddFlagError, setShowAddFlagError] = useState(false)
  const [readyToSend, setReadyToSend] = useState(false)
  const [readyToDraft, setReadyToDraft] = useState(false)

  const isAdminOrRegisteredSurveyor = roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_USER) || roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)
  const btnOutline = "btn btn-outline-primary"
  const btnPrimary = "btn btn-primary"
  const currentRole = useSelector(getCurrentRole)
  const isRegisteredSurveyor = currentRole?.includes(REGISTERED_SURVEYORS)
  const isRequiredMasterBoundaryFile = (
    PRODUCT_CODE_NEED_MASTER_BOUNDARY_FILE.includes(code?.toUpperCase())
    && isRegisteredSurveyor
    && workflow !== "7"
    && !!masterBoundaryFile?.data === false
  )

  const sendToReadyToSend = () => {
    let transition
    if (workflow === "7") {
      transition = "complete-job"
    } else if (Number(cost) === 0 && docketResolved) {
      transition = "ready-to-send"
    } else {
      transition = "move-to-accounts"
    }

    updateJobServiceTransition(baseUrl, id, transition, comment)
      .then(res => {
        if (res.status === 200) {
          window.location.reload()
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  const sendToReadyToDraft = () => {
    updateJobServiceTransition(baseUrl, id, "move-to-drafting-manager", comment)
      .then(res => {
        if (res.status === 200) {
          window.location.reload()
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  const readyToSendHandler = () => {
    setReadyToSend(!readyToSend)
    setReadyToDraft(false)
  }

  const readyToDraftHandler = () => {
    setReadyToDraft(!readyToDraft)
    setReadyToSend(false)
  }

  const sendJobServiceHandler = () => {
    if (readyToDraft) {
      sendToReadyToDraft()
    }
    if (readyToSend) {
      sendToReadyToSend()
    }
  }

  const handleAddFlag = () => {
    if (notResolvedBookInJob) {
      setShowAddFlagError(true)
    } else {
      setShowFlagModal(true)
    }
  }

  return (
    <div className="timeline-item mt-2">
      <div className="card border-0 shadow-sm">
        <div className="card-body p-4">
          <div className="row py-3 mx-2 align-items-center">
            {isAdminOrRegisteredSurveyor && (
              <div className="col-sm-6">
                <button className={`btn ${criticalNote ? 'text-danger' : ''}`} type="button" onClick={() => { setShowCriticalNoteModal(true) }}>
                  <i className="bi bi-flag-fill mr-2"></i>
                  Add Critical Note
                </button>

                <CriticalNoteJobServiceModal
                  show={showCriticalNoteModal}
                  onHide={() => { setShowCriticalNoteModal(false) }}
                  jobService={jobService}
                  reloadData={reloadData} />
              </div>
            )}
            <div className="col-sm-6">
              {(registeredSurveyorName && !["4", "7"].includes(workflow)) && (
                <button className={`btn ${isAdminOrRegisteredSurveyor ? "float-right" : ""}`} type="button" onClick={handleAddFlag}>
                  <i className="bi bi-flag-fill mr-2"></i>
                  Add Flag
                </button>
              )}

              <SweetAlert
                show={showAddFlagError}
                type="error"
                title="Can't create another active flag!"
                style={{ width: "600px" }}
                closeOnClickOutside
                allowEscape={false}
                onConfirm={() => setShowAddFlagError(false)}>
                Please resolve active flag first!
              </SweetAlert>

              <FlagJobServiceModal
                show={showFlagModal}
                onHide={() => { setShowFlagModal(false) }}
                jobService={jobService}
                reloadData={reloadData}
                flagJobServiceSource={flagJobServiceSource}
                setFlagJobServiceSource={setFlagJobServiceSource} />
            </div>
          </div>
        </div>
      </div>
      <CommentBox comment={comment} setComment={setComment} />

      {(roleCheck(systemRole, currentTeamRole, "registered_surveyors_manager") || Number(currentUser.id) === registeredSurveyorId) && (
        <div className="row mt-4">
          <div className="mr-4 pt-2">Send to</div>
          <div className="mr-2">
            <button className={readyToSend ? btnPrimary : btnOutline} type="button" disabled={!registeredSurveyorName || notResolvedBookInJob} onClick={readyToSendHandler}>{workflow === "7" ? "Complete" : "Ready to Send"}</button>
            <ConfirmationModal
              jobNo={displayJobNo}
              jobTitle={jobTitle}
              show={readyToSend && !isRequiredMasterBoundaryFile}
              resetState={() => setReadyToSend(false)}
              sendTo={sendToReadyToSend}
              messageType={workflow === "7" ? "Complete" : "Client"}>
            </ConfirmationModal>
            {isRequiredMasterBoundaryFile && readyToSend && (
              <SweetAlert
                show={readyToSend}
                type="error"
                title="Unable to Send"
                style={{ width: "600px" }}
                closeOnClickOutside
                allowEscape={false}
                onConfirm={() => { setReadyToSend(false) }}>
                Please upload Master Boundary File
              </SweetAlert>
            )}
          </div>
          {!["4", "7"].includes(workflow) && (
            <>
              <div className="mr-2">
                <button className={readyToDraft ? btnPrimary : btnOutline} type="button" disabled={!registeredSurveyorName} onClick={readyToDraftHandler}>Ready to Draft</button>
              </div>
              <ConfirmationModal jobNo={displayJobNo} jobTitle={jobTitle} show={readyToDraft} resetState={() => setReadyToDraft(false)} sendTo={sendToReadyToDraft} messageType="Ready to Draft"></ConfirmationModal>
            </>
          )}
        </div>
      )}
    </div>
  )
}
export default RegisteredSurveyor
