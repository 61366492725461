import React from "react"
import { combineReducers } from "redux"
import { toast } from "react-toastify"
import normalize from "json-api-normalizer"

import * as actionTypes from "../actions/types"

const toastOptions =  {
  toastId: "account-settings-page",
  containerId: "main"
}

function postUserState(state = false, action) {
  switch (action.type) {
    case actionTypes.CURRENT_USER_POST_START:
      return true
    case actionTypes.CURRENT_USER_POST_SUCCESS:
      toast.success(
        <div>
          <span role="img" aria-label="success-icon">✅</span>
          <span className="ml-2">Account updated!</span>
        </div>,
        toastOptions
      )
      return false
    case actionTypes.CURRENT_USER_POST_FAILURE:
      toast.error(
        <div>
          <span role="img" aria-label="failure-icon">❌</span>
          <span className="ml-2">Account update error!</span>
        </div>,
        toastOptions
      )
      return false
    default:
      return state
  }
}

function editState(state = false, action) {
  switch (action.type) {
    case actionTypes.CURRENT_USER_START_EDITING:
      return true
    case actionTypes.CURRENT_USER_EDIT_SAVE:
    case actionTypes.CURRENT_USER_EDIT_CANCEL:
      return false
    default:
      return state
  }
}

function userEditableAttr(state = {}, action) {
  switch (action.type) {
    case actionTypes.CURRENT_USER_SET_EDIT_FIELDS:
    case actionTypes.CURRENT_USER_EDIT_CANCEL:
    case actionTypes.USER_POST_SUCCESS:
      return action.data
    case actionTypes.CURRENT_USER_EDIT_UPDATE: {
      return { ...state, ...action.data }
    }
    default:
      return state
  }
}

function currentUser(state = _.get(normalize({ data: gon.current_user }), `user.${gon.current_user_id}`), action) {
  switch (action.type) {
    case actionTypes.CURRENT_USER_POST_SUCCESS:
      return _.get(action.data, `user.${gon.current_user_id}`)
    default:
      return state
  }
}

function updatePasswordStatus(state = false, action) {
  switch (action.type) {
    case actionTypes.CURRENT_USER_UPDATE_PWD_START:
      return true
    case actionTypes.CURRENT_USER_UPDATE_PWD_SUCCESS:
    case actionTypes.CURRENT_USER_UPDATE_PWD_FAILURE:
      return false
    default:
      return state
  }
}

export default combineReducers({
  currentUser,
  postUserState,
  editState,
  userEditableAttr,
  updatePasswordStatus
})
