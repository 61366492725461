import {
  compareAsc,
  isPast
} from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { sendAccessEmail, updateJobService, updateJobServiceTransition } from "requesters/jobServiceRequester";
import { getBaseUrl } from "../../../../reducers";
import SendAccessEmailModal from "../../modals/SendAccessEmailModal";
import { PRODUCT_CODE_REQUIRE_SCANNER_CADASTRAL } from "../../../../../../utils/constants";

const ToConfirm = ({ jobService, reloadData }) => {
  const {
    attributes: {
      bookingTime,
      draftedPrimarySurveyorId,
      bookingDates,
      isGpsAhd,
      isGpsMga,
      state,
      code,
      forProjectX,
      missingCadastralSurveyor,
      missingScannerSurveyor
    }, id
  } = jobService
  const baseUrl = useSelector(getBaseUrl)
  const [newBookingDates, setNewBookingDates] = useState(bookingDates?.map(d => new Date(d)))
  const [showMessage, setShowMessage] = useState(false)
  const [alertTitle, setAlertTitle] = useState("")
  const [alertText, setAlertText] = useState("")
  const [isDateChanged, setIsDateChanged] = useState(false)
  const [showEmailAccessModal, setShowEmailAccessModal] = useState(false)
  const [missingSurveyor, setMissingSurveyor] = useState(false)
  const [missingSurveyorTypes, setMissingSurveyorTypes] = useState([])
  const [showMissingMessage, setShowMissingMessage] = useState(false)
  const datepickerRefs = useRef([]);
  const [loading, setLoading] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)


  const updateMissingInfo = () => {
    const isMissingSurveyor = PRODUCT_CODE_REQUIRE_SCANNER_CADASTRAL.includes(code) && forProjectX && (missingCadastralSurveyor || missingScannerSurveyor)
    const initMissingTypes = []
    if (missingCadastralSurveyor) initMissingTypes.push("Cadastral")
    if (missingScannerSurveyor) initMissingTypes.push("Scanner")

    setMissingSurveyor(isMissingSurveyor)
    setMissingSurveyorTypes(initMissingTypes)
  }

  useEffect(() => {
    updateMissingInfo()
  }, [code, forProjectX, missingCadastralSurveyor, missingScannerSurveyor])

  const resetError = () => {
    setShowMessage(false)
    setAlertTitle("")
    setAlertText("")
  }

  const JobConfirmBookingHandler = () => {
    setConfirmLoading(true)
    updateJobServiceTransition(baseUrl, id, "confirm-booking")
      .then(res => {
        if (res.status === 200) {
          reloadData()
        } else {
          setShowMessage(true)
          setAlertTitle("Job Service can't be confirmed!")
          setAlertText(res?.error?.response?.data?.errors[0]?.detail || "Check the Surveyor skills and the booking time.")
          setConfirmLoading(false)
        }
      })
      .catch(() => {
        setConfirmLoading(false)
      })
  }

  const checkMissingSurveyor = () => {
    if (missingSurveyor) {
      setShowMissingMessage(true)
    } else {
      JobConfirmBookingHandler()
    }
  }

  const isPastArray = arr => arr.map(d => isPast(d)).includes(true);

  const editBookingTimeHandler = () => {
    setLoading(true)
    if (!isPastArray(newBookingDates)) {
      const uniqueBookingDates = newBookingDates.filter((date, i, self) => self.findIndex(d => d.getTime() === date.getTime()) === i)

      updateJobService(baseUrl, id, { booking_dates: uniqueBookingDates })
        .then(res => {
          if (res.status === 200) {
            setNewBookingDates(uniqueBookingDates.sort(compareAsc))
            reloadData()
            setIsDateChanged(false)
          }
          setLoading(false)
        })
        // eslint-disable-next-line no-console
        .catch(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
      if (isPastArray(newBookingDates)) {
        setShowMessage(true)
        setAlertTitle("Please choose an available timeslot!")
        setAlertText("You can only choose a timeslot in the future!")
      }
    }
  }

  const resetDate = () => {
    setNewBookingDates(bookingDates.map(d => new Date(d)))
    setIsDateChanged(false)
  }

  const toastOptions = {
    toastId: "container-inner",
    containerId: "main"
  }

  const addAdditionalDate = () => {
    const lastDate = moment(newBookingDates[newBookingDates.length - 1])
    const nextDate = lastDate.add(1, "days");

    while (nextDate.day() === 0 || nextDate.day() === 6) {
      nextDate.add(1, "days");
    }

    setNewBookingDates([...newBookingDates, nextDate.toDate()]);
    setIsDateChanged(true);
  };


  const removeDate = index => {
    const arr = [...newBookingDates]
    arr.splice(index, 1)
    setNewBookingDates(arr);
    setIsDateChanged(true)
  }

  const sentJobServiceAccessEmail = emailOptions => {
    sendAccessEmail(baseUrl, id, emailOptions)
      .then(res => {
        if (res.status === 200) {
          reloadData()
          toast.success(
            <div>
              <span role="img" aria-label="success-icon">✅</span>
              <span className="ml-2">Email Sent!</span>
            </div>,
            toastOptions
          )
        }
      })
      .catch(err => {
        toast.error(
          <div>
            <span role="img" aria-label="failure-icon">❌</span>
            <span className="ml-2">{err?.response?.data?.errors[0]?.detail}</span>
          </div>,
          toastOptions
        )
      })
  }

  const minDate = index => (newBookingDates[index - 1] >= 0 ? newBookingDates[index - 1] : new Date())

  const handleCalendarOpen = key => {
    datepickerRefs.current.forEach((ref, index) => {
      if (key !== index && ref) {
        ref.setOpen(false);
      }
    });
  };

  return (
    <div className="card border-0 shadow-sm">
      <div className="card-body p-4">
        {/* {state !== "to_confirm" && (
          <div className="mb-3">
            {newBookingDates?.map((date, index) => (
              <div className="text-left mb-2" key={date}>
                <div className="d-inline-block">
                  <DatePicker
                    required
                    selected={date}
                    onChange={e => {
                      const arr = [...newBookingDates]
                      arr[index] = e
                      setNewBookingDates(arr)
                      setIsDateChanged(true)
                    }}
                    showTimeSelect
                    closeOnScroll={e => e.target === document}
                    className="form-control"
                    dateFormat="dd MMM yyyy, h:mma"
                    minDate={minDate(index)}
                    ref={el => datepickerRefs.current[index] = el}
                    onCalendarOpen={() => handleCalendarOpen(index)} />
                  {index !== 0 && (
                    <i
                      className="cursor-pointer ml-2 bi bi-x-circle"
                      onClick={() => removeDate(index)} />
                  )}

                </div>
              </div>
            ))}


            <div className="text-left mt-3">
              <div className="d-inline-block">
                <button className="btn btn-link" type="button" onClick={addAdditionalDate}>Add additional date</button>
              </div>
            </div>

            {isDateChanged && (
              <div className="text-left mt-3">
                <div className="d-inline-block">
                  <button disabled={loading} onClick={editBookingTimeHandler} type="button" className="btn btn-outline-dark mr-2">
                    Update
                    {loading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
                  </button>
                  <button onClick={resetDate} type="button" className="btn btn-link mx-2">Cancel</button>
                </div>
              </div>
            )}

            <hr />
          </div>
        )} */}
        <div className="text-left mt-3">
          { missingSurveyor && (
            <p className="text-danger">
              <i>{`No ${missingSurveyorTypes.join("/")} is allocated`}</i>
            </p>
          )}
          <div className="d-inline-block">
            {(!isGpsAhd && !isGpsMga) && (
              <button className="btn btn-outline-primary" disabled={isDateChanged || confirmLoading} type="button" onClick={() => setShowEmailAccessModal(true)}>Send Confirmation Email</button>
            )}

            {(draftedPrimarySurveyorId) && (
              <button className="btn btn-red mt-lg-2 mt-xl-0 ml-2" disabled={isDateChanged || confirmLoading} type="button" onClick={checkMissingSurveyor}>
                Confirm Booking
                {confirmLoading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
              </button>
            )}
            <SendAccessEmailModal
              show={showEmailAccessModal}
              onHide={() => setShowEmailAccessModal(false)}
              jobService={jobService}
              sentJobServiceAccessEmail={sentJobServiceAccessEmail}
              reloadData={reloadData} />
          </div>
        </div>
        <SweetAlert
          show={showMessage}
          type="error"
          title={alertTitle}
          style={{ width: "600px" }}
          closeOnClickOutside
          allowEscape={false}
          onConfirm={resetError}>
          {alertText}
        </SweetAlert>
        <SweetAlert
          show={showMissingMessage}
          type="warning"
          title=""
          style={{ width: "600px" }}
          closeOnClickOutside
          allowEscape={false}
          onConfirm={() => {
            setShowMissingMessage(false)
            JobConfirmBookingHandler()
          }}
          showCancel
          onCancel={() => setShowMissingMessage(false)}>
          {`Are you sure you wish to Confirm the Booking with a missing ${missingSurveyorTypes.join("/")} Surveyor?`}
        </SweetAlert>
      </div>
    </div>
  )
}
export default ToConfirm
