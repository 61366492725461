import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { updateJobServiceTransition } from "requesters/jobServiceRequester"
import { postComment } from "requesters/commentRequester"
import { Modal, Spinner } from "react-bootstrap"
import { REQUEST_GPS, DECLINE_GPS } from "utils/constants"
import { getBaseUrl, getCurrentUser } from "../../../reducers"

const RequestGpsModal = ({
  show, onHide, jobService, reloadData, decline
}) => {
  const baseUrl = useSelector(getBaseUrl)
  const { id } = jobService
  const currentUser = useSelector(getCurrentUser)

  const [disableSubmit, setDisableSubmit] = useState(false)
  const [modalContent, setModalContent] = useState({})

  const [notes, setNotes] = useState("")

  const [loading, setLoading] = useState(false)

  const generateContent = () => {
    if (decline) {
      setModalContent({
        header: "Decline GPS request",
        subHeader: "Enter the reasons why the request is declined",
        apiTransition: DECLINE_GPS,
        buttonLabel: "Decline Request",
      })
    } else {
      setModalContent({
        header: "Request GPS",
        subHeader: "Send the GPS request to Operations team",
        apiTransition: REQUEST_GPS,
        buttonLabel: "Send for Approval",
      })
    }
  }

  useEffect(() => {
    generateContent()
    setLoading(false)
  }, [show])

  const validateForm = () => {
    const newComment = {
      commentableId: id,
      commentBody: notes,
      commentableType: "JobService",
      currentUserId: currentUser.id,
      commentType: modalContent?.apiTransition,
    }
    setLoading(true)
    postComment(baseUrl, newComment)
      .then(res => {
        if (res.status === 201) {
          updateJobServiceTransition(baseUrl, id, modalContent?.apiTransition)
            .then(res => {
              if (res.status === 200) {
                reloadData()
                onHide()
              }
            })
            .catch(() => {
              setLoading(false)
            })
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (notes) {
      setDisableSubmit(false)
    } else {
      setDisableSubmit(true)
    }
  }, [notes])

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <h2 className="modal-title">{modalContent?.header}</h2>
          <div className="mb-2 h6">
            <p className="mr-1">{modalContent?.subHeader}</p>
          </div>
        </Modal.Title>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          <div className="form-group row mb-4 pb-4 border-bottom">
            <textarea
              name="flag_notes"
              className="form-control mr-5"
              placeholder="Notes*"
              rows="4"
              value={notes}
              onChange={e => setNotes(e.target.value)}>
            </textarea>
          </div>
        </form>
        <div className="modal-footer d-flex justify-content-end px-0 py-4">
          <button
            className="btn btn-link btn-lg"
            onClick={onHide}
            type="submit">
            Close
          </button>
          <button
            className="btn btn-red btn-lg"
            onClick={validateForm}
            disabled={disableSubmit || loading}
            type="submit">
            {modalContent?.buttonLabel}
            {loading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
          </button>
        </div>
      </div>
    </Modal>
  )
}
export default RequestGpsModal
