import React, { useEffect } from "react"
import { useSelector } from "react-redux"

import { getBaseUrl } from "../../reducers"


import DashboardCard from "./components/DashboardCard";
import DashboardCardContainer from "./components/DashboardCardContainer";

export default function DashboardForSales({ currentUser }) {
  const { attributes: { firstname } } = currentUser

  const baseUrl = useSelector(getBaseUrl)

  useEffect(() => {
    // fetch data
  }, [])

  return (
    <div className="dashboard">
      <div className="row no-gutters">
        <div className="col-lg-4 col-xl-3">
          <div className="container-fluid">
            <div className="container-inner">
              <div className="greeting">
                Hello,
                {" "}
                <br />
                {" "}
                {firstname}
                {" "}
              </div>
            </div>
          </div>
        </div>
        {/* <DashboardCardContainer title="today">
          <div className="row">
            <DashboardCard
              link="/leads"
              title="to be assigned"
              count="5"
              colorClass="bg-light" />
            <DashboardCard
              link="/leads"
              title="submitted to council"
              count="10"
              colorClass="bg-light" />
          </div>
        </DashboardCardContainer> */}
      </div>
    </div>

  )
}
