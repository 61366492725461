import React, { useState } from "react"
import { useSelector } from "react-redux"
import { updateJobServiceTransition } from "requesters/jobServiceRequester";
import { Modal } from "react-bootstrap"


import { getBaseUrl } from "../../../reducers"

const JobOnHoldModal = ({
  show, onHide, reloadData, jobService
}) => {
  const baseUrl = useSelector(getBaseUrl)

  const [reason, setReason] = useState("")
  const [holdJobButtonClicked, setHoldJobButtonClicked] = useState(false)
  const [onHoldType, setOnHoldType] = useState("client-delayed")

  const onSubmit = () => {
    updateJobServiceTransition(baseUrl, jobService?.id, onHoldType, reason)
      .then(() => {
        reloadData()
        onHide()
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title><h2 className="modal-title">Job On Hold</h2></Modal.Title>
      </Modal.Header>
      <div className="modal-body">
        <form>
          <div className="col-12 mb-2">
            <label className="mr-4 cursor-pointer">
              <input
                className="mr-1 cursor-pointer"
                name="radio-client-delayed"
                type="radio"
                value="client-delayed"
                checked={onHoldType === "client-delayed"}
                onChange={() => { setOnHoldType("client-delayed") }} />
              Client Delayed
            </label>
            <label className="mr-4 cursor-pointer">
              <input
                className="mr-1 cursor-pointer"
                name="radio-on-hold"
                type="radio"
                value="on-hold"
                checked={onHoldType === "on-hold"}
                onChange={() => { setOnHoldType("on-hold") }} />
              On Hold
            </label>
          </div>
          <div className="col-12 mb-5">
            <textarea
              name="flag_notes"
              className="form-control"
              placeholder="Notes*"
              rows="4"
              value={reason}
              onChange={e => setReason(e.target.value)}>
            </textarea>
          </div>
        </form>
        <div className="modal-footer d-flex justify-content-end p-0 mb-4 mt-5">
          <button
            className="btn btn-link btn-lg"
            onClick={onHide}
            type="submit">
            Close
          </button>
          <button
            className="btn btn-red btn-lg"
            onClick={() => { onSubmit(); setHoldJobButtonClicked(true) }}
            disabled={!reason || holdJobButtonClicked}
            type="submit">
            Hold Job
          </button>
        </div>
      </div>
    </Modal>
  )
}
export default JobOnHoldModal
