import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getJobServicesByJob } from "../../../../../requesters/jobServiceRequester";
import { getBaseUrl } from "../../../reducers";
import RelatedJobServiceItem from "./RelatedJobServiceItem";

const RelatedJobServices = ({ jobId, title, jobServiceId }) => {
  const baseUrl = useSelector(getBaseUrl)
  const [relatedJobServices, setRelatedJobServices] = useState([])

  const fetchData = () => {
    getJobServicesByJob(baseUrl, jobId)
    .then(res => {
      if (res.status === 200) {
        const listOfJobService = Object.values(_.get(res.response, "jobService", {}))
        setRelatedJobServices(listOfJobService)
      }
    })
    .catch(err => console.log(err))
  }

  useEffect(() => {
    fetchData()
  }, [])

  const JobServiceItem = jobService => (
    <React.Fragment>
      { jobService.id === jobServiceId ? <></>
        : <RelatedJobServiceItem jobService={jobService} key={`related-job-service-${jobService.id}`} />
      }
    </React.Fragment>
  )

  return (
    <div className="table-responsive my-2">
      <h6>{ title }</h6>
      <table className="table mt-4">
        { relatedJobServices.map(jobService => JobServiceItem(jobService)) }
      </table>
    </div>
  )
}

export default RelatedJobServices