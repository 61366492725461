import React from "react"
import { combineReducers } from "redux"
import { toast } from "react-toastify"

import * as actionTypes from "../actions/types"

const toastOptions =  {
    toastId: "gear-details-page",
    containerId: "main"
}

function postGearHistoryRecordState(state = false, action) {
    switch (action.type) {
        case actionTypes.GEAR_HISTORY_RECORD_POST_START:
            return true
        case actionTypes.GEAR_HISTORY_RECORD_POST_SUCCESS:
            toast.success(
              <div>
                <span role="img" aria-label="success-icon">✅</span>
                <span className="ml-2">History added!</span>
              </div>,
                toastOptions
            )
        case actionTypes.GEAR_HISTORY_RECORD_POST_FAILURE:
            toast.error(
              <div>
                <span role="img" aria-label="failure-icon">❌</span>
                <span className="ml-2">History add error!</span>
              </div>,
                toastOptions
            )
            return false
        default:
            return state
    }
}

function createState(state = false, action) {
    switch (action.type) {
        case actionTypes.MANAGE_GEAR_HISTORY_RECORDS_START_CREATING:
            return true
        case actionTypes.MANAGE_GEAR_HISTORY_RECORDS_CREATE_SAVE:
        case actionTypes.MANAGE_GEAR_HISTORY_RECORDS_CREATE_CANCEL:
            return false
        default:
            return state
    }
}

function gearHistoryRecordCreatableAttr(state = {}, call) {
    switch (call.type) {
        case actionTypes.MANAGE_GEAR_HISTORY_RECORDS_START_CREATING:
        case actionTypes.MANAGE_GEAR_HISTORY_RECORDS_CREATE_CANCEL:
        case actionTypes.GEAR_HISTORY_RECORD_POST_SUCCESS: {
            const { data } = call
            const gearHistoryRecordAttributes = _.get(data, "attributes", {})
            const {
                action,
                reportDate,
                notes,
                user,
            } = gearHistoryRecordAttributes
            return {
                action,
                report_date: reportDate,
                notes,
                user,
            }
        }
        case actionTypes.MANAGE_GEAR_HISTORY_RECORDS_CREATE_UPDATE: {
            return { ...state, ...call.data }
        }
        default:
            return state
    }
}

export default combineReducers({
    gearHistoryRecordCreatableAttr,
    postGearHistoryRecordState,
    createState
})