import React from "react";

const PerformanceReportsTab = ({ tabOptions, activeTab, setActiveTab }) => {
  const Tab = ({ title, activeTab, setActiveTab }) => (
    <li className="nav-item cursor-pointer" role="presentation" onClick={() => setActiveTab(title)}>
      <div id="performance-report-tab" className={activeTab === title ? "nav-link active" : "nav-link"} role="tab">
        <h6 className="mb-0">{title}</h6>
      </div>
    </li>
  );

  return (
    <ul id="performance-reports-tabs" className="nav reports-tabs my-5" role="tablist">
      {
        tabOptions.map(tab => <Tab key={tab} title={tab} activeTab={activeTab} setActiveTab={setActiveTab} />)
      }
    </ul>
  )
}

export default PerformanceReportsTab