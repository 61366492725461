import React, { useEffect, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { Link } from "react-router-dom"
import SweetAlert from "react-bootstrap-sweetalert";
import cx from "classnames";
import { updateJobService, assignDraftman, updateJobServiceTransition } from "requesters/jobServiceRequester";
import { useSelector } from "react-redux";
import StatusChangeDropdown from "components/StatusChangeDropdown";
import {
  DRAFTING_OVERSEAS_MANAGER,
  OVERSEAS_STATE,
  DRAFTING_3D_OVERSEAS_LEBANON_MANAGER,
  DRAFTING_OVERSEAS_LEBANON,
  DRAFTING_3D_OVERSEAS_PHILIPPINES_MANAGER,
  DRAFTING_OVERSEAS_PHILIPPINES,
  ASSIGN_OVERSEAS_MANAGER,
  DRAFTING_OVERSEAS,
  OVERSEAS_STATES_ALLOWED
} from "utils/constants"
import { format } from "date-fns"
import { reorderDots } from "components/Icons"
import ConfirmationModal from "../JobDetails/modals/ConfirmationModal";
import { getBaseUrl, getCurrentUser } from "../../reducers";
import { brokenLink } from "../../../../components/Icons";

// const updatableStates = ["to_draft", "qa"];
const updatableStates = [];
const updatableOptions = ["to_draft", "qa"];

export default function DraftingRow({
  drafting, draftmenOptions, fetchData, readOnly, disableDrag, is2DTeam
}) {
  const baseUrl = useSelector(getBaseUrl);
  const currentUser = useSelector(getCurrentUser)
  const currentTeamRole = currentUser.attributes.current_team_role
  const [showMessage, setShowMessage] = useState(false);
  const [alertType, setAlertType] = useState("error");
  const [alertTitle, setAlertTitle] = useState("");
  const [showDraftmenModal, setShowDraftmenModal] = useState(false);
  const [draftmenOptionsFiltered, setDraftmenOptionsFiltered] = useState(draftmenOptions)
  const [overseas2D, setOverseas2D] = useState(false)
  const [overseas3dLebanon, setOverseas3dLebanon] = useState(false)
  const [overseas3dPhilippines, setOverseas3dPhilippines] = useState(false)
  // const isOverseasManager = currentTeamRole.includes(DRAFTING_OVERSEAS_MANAGER)

  const {
    id, attributes:
    {
      job_id,
      job_no,
      for_project_x,
      project_x_job_no,
      job_address,
      highlighted,
      label: job_type,
      drafted_primary_surveyor_name,
      draftman_id,
      draftman_name,
      checked_out_at,
      client_due_date,
      drafting_team_label,
      state,
      is3D,
      drafting_team,
      client_due_date_overdue,
      is_additional,
      additional_draftsmen_name,
      job_has_multiple_job_services,
    }
  } = drafting;

  const isOverseasManager = (!is3D && currentTeamRole.includes(DRAFTING_OVERSEAS_MANAGER))
    || (is3D && currentTeamRole.includes(DRAFTING_3D_OVERSEAS_LEBANON_MANAGER) && drafting_team === DRAFTING_OVERSEAS_LEBANON)
    || (is3D && currentTeamRole.includes(DRAFTING_3D_OVERSEAS_PHILIPPINES_MANAGER) && drafting_team === DRAFTING_OVERSEAS_PHILIPPINES)

  const clientDueDateObj = new Date(client_due_date)
  const currentDate = new Date()
  const dueDateOverdue = client_due_date_overdue
  const tomorrow = new Date()

  const btnOutline = `btn btn-outline-primary`
  const btnPrimary = "btn-primary"

  tomorrow.setDate(currentDate.getDate() + 1);
  const dueTomorrow = tomorrow.toLocaleDateString() === clientDueDateObj.toLocaleDateString()
  const displayJobNo = for_project_x ? project_x_job_no : job_no

  const dueDateHandler = () => {
    if (!client_due_date) {
      return ``
    }
    if (dueTomorrow) {
      return `bg-yellow`
    }
    if (dueDateOverdue || highlighted) {
      return `bg-coral`
    }

    return ""
  }

  const updateJobHandler = params => {
    updateJobService(baseUrl, id, params).then(res => {
      if (res.status === 200) {
        fetchData()
      }
    })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
        setAlertTitle("Ooops! Something went wrong")
        setAlertType("error")
        setShowMessage(true)
      })
  }

  const assignDraftmanHandler = draftmanId => {
    assignDraftman(baseUrl, id, { draftman_id: draftmanId })
      .then(res => {
        if (res.response === 200) {
          fetchData()
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  const handleDareftmanModal = () => {
    if (isOverseasManager || !readOnly) {
      setShowDraftmenModal(true)
    }
  }

  const DraftmenModal = ({ setShowDraftmenModal }) => {
    const closeModal = () => {
      setShowDraftmenModal(false);
    };
    // const ref = useDetectClickOutside({ onTriggered: closeModal });
    return (
      <div
        onMouseLeave={() => {
          closeModal()
        }}
        className={cx("dropdown-menu shadow-sm", { "show-modal": showDraftmenModal })}>
        <p className="small mb-2">Select draftsman</p>
        <select
          name={`draftman-${id}`}
          id={`draftman-${id}`}
          className="form-control"
          value={draftman_id || ""}
          onChange={e => {
            assignDraftmanHandler(e.target.value)
            // updateJobHandler({ draftman_id: e.target.value })
            setShowDraftmenModal(false)
          }}>
          <option value="" key="">Select a draftsman</option>
          {
            !!draftmenOptionsFiltered?.length && draftmenOptionsFiltered.map(option => (
              <option
                value={option[1]}
                key={`-${option[1]}`}>
                {option[0]}
              </option>
            ))
          }
        </select>
      </div>
    );
  };

  const overseas2dHandler = () => {
    setOverseas2D(!overseas2D)
  }

  const overseas3dLebanonHandler = () => {
    setOverseas3dLebanon(!overseas3dLebanon)
    setOverseas3dPhilippines(false)
  }

  const overseas3dPhilippinesHandler = () => {
    setOverseas3dPhilippines(!overseas3dPhilippines)
    setOverseas3dLebanon(false)
  }

  const sendToOverseas = (transition, draftingTeam = null) => {
    updateJobServiceTransition(baseUrl, id, transition, "", null, draftingTeam)
      .then(res => {
        if (res.status === 200) {
          window.location.reload()
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  const overseasButton = () => {
    if (is3D) {
      return (
        <div className="mr-2">
          <button className={`btn ${overseas3dLebanon ? btnPrimary : btnOutline} mt-2 mr-2`} type="button" onClick={overseas3dLebanonHandler}>Overseas Lebanon</button>
          {overseas3dLebanon && (
            <ConfirmationModal jobNo={displayJobNo} jobTitle={job_type} show={overseas3dLebanon} resetState={() => setOverseas3dLebanon(false)} sendTo={() => sendToOverseas(ASSIGN_OVERSEAS_MANAGER, DRAFTING_OVERSEAS_LEBANON)} messageType="Overseas Lebanon"></ConfirmationModal>
          )}

          <button className={`btn ${overseas3dPhilippines ? btnPrimary : btnOutline} mt-2`} type="button" onClick={overseas3dPhilippinesHandler}>Overseas Philippines</button>
          {overseas3dPhilippines && (
            <ConfirmationModal jobNo={displayJobNo} jobTitle={job_type} show={overseas3dPhilippines} resetState={() => setOverseas3dPhilippines(false)} sendTo={() => sendToOverseas(ASSIGN_OVERSEAS_MANAGER, DRAFTING_OVERSEAS_PHILIPPINES)} messageType="Overseas Philippines"></ConfirmationModal>
          )}
        </div>
      )
    }

    return (
      <div className="mr-2">
        <button className={`btn ${overseas2D ? btnPrimary : btnOutline} mt-2`} type="button" onClick={overseas2dHandler}>Overseas</button>
        {overseas2D && (
          <ConfirmationModal jobNo={displayJobNo} jobTitle={job_type} show={overseas2D} resetState={() => setOverseas2D(false)} sendTo={() => sendToOverseas(ASSIGN_OVERSEAS_MANAGER, DRAFTING_OVERSEAS)} messageType="Overseas"></ConfirmationModal>
        )}
      </div>
    )
  }

  const filterDraftman = draftman => {
    if (OVERSEAS_STATES_ALLOWED.includes(state)) {
      switch (drafting_team) {
        case DRAFTING_OVERSEAS_LEBANON:
          return draftman.filter(option => option[2].is_overseas_lebanon)
        case DRAFTING_OVERSEAS_PHILIPPINES:
          return draftman.filter(option => option[2].is_overseas_philippines)
        default:
          return draftman.filter(option => option[2].is_overseas)
      }
    }
  
    return draftman.filter(option => 
      is3D 
        ? option[2].is_draftmen_3d
        : option[2].is_draftmen_2d
    )
  }

  useEffect(() => {
    setDraftmenOptionsFiltered(filterDraftman(draftmenOptions));
  }, [draftmenOptions])

  return (
    <>
      <td className="bg-white">
        {!disableDrag && (
          <img src={reorderDots} style={{ width: 20 }} />
        )}
      </td>
      <td className={`${highlighted && "bg-coral"}`}>
        <Link to={`/jobs/${job_id}/job_services/${id}`} className="link-no-decoration">{displayJobNo}</Link>
      </td>
      <td className={`${highlighted && "bg-coral"}`}>
        <Link to={`/jobs/${job_id}/job_services/${id}`} className="link-no-decoration">{job_address}</Link>
      </td>
      <td className={`${highlighted && "bg-coral"}`}>
        <Link to={`/jobs/${job_id}/job_services/${id}`} className="link-no-decoration">{job_type}</Link>
      </td>
      <td className={`${highlighted && "bg-coral"} text-left`}>
        <Link to={`/jobs/${job_id}/job_services/${id}`} className="link-no-decoration">{drafted_primary_surveyor_name}</Link>
      </td>
      <td className={`${highlighted && "bg-coral"} text-center`}>
        <Link to={`/jobs/${job_id}/job_services/${id}`} className="link-no-decoration">{checked_out_at ? format(new Date(checked_out_at), "dd MMM yyyy") : ""}</Link>
      </td>
      <td className={`${highlighted ? "bg-coral" : "bg-light"} dropdown`}>
        <button
          style={{ minHeight: "30px" }}
          className="btn btn-block"
          disabled={is_additional}
          type="button"
          onClick={handleDareftmanModal}>
          {is_additional ? `${additional_draftsmen_name} (Secondary)` : draftman_name || ""}
        </button>
        <DraftmenModal setShowDraftmenModal={setShowDraftmenModal} />
      </td>
      {/* <td className="text-center">
        {index + 1}
      </td> */}
      <StatusChangeDropdown job={drafting} reloadData={fetchData} updatableStates={updatableStates} updatableOptions={updatableOptions} />
      {!readOnly && (
        <td className={`${highlighted && "bg-coral"} text-center`}>
          { !draftman_id && state === "drafting_assignment_required" ? overseasButton() : <Link to={`/jobs/${job_id}/job_services/${id}`} className="link-no-decoration">{drafting_team_label}</Link> }
        </td>
      )}
      <td className={`${dueDateHandler()} text-center`}>
        <Link to={`/jobs/${job_id}/job_services/${id}`} className="link-no-decoration">{client_due_date}</Link>
      </td>
      <td className={`${highlighted && "bg-coral"}`}>
        {job_has_multiple_job_services && <img src={brokenLink} className="icon-size-18" id="has-child-jobs-icon" />}
      </td>
      <td className={`${highlighted && "bg-coral"}`}>
        <div className="d-flex align-items-center justify-content-end">
          <Link to={`/jobs/${job_id}/job_services/${id}`}>Details</Link>
        </div>
      </td>
      <SweetAlert
        show={showMessage}
        type={alertType}
        title={alertTitle}
        closeOnClickOutside
        allowEscape={false}
        onConfirm={() => setShowMessage(false)} />
    </>
  );
}
