import customAxios from "./customAxios";
import normalize from "json-api-normalizer"

export const getActivities = (baseUrl, filters) => {
    const path = `${baseUrl}/activities`

    return customAxios.get(path, { params: filters })
        .then(({ data, status }) => ({ response: normalize(data), status }))
        .catch(error => ({ error }))
}
