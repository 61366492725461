import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import SweetAlert from "react-bootstrap-sweetalert"
import { uploadUserSignature } from "requesters/userRequester"
import { getBaseUrl } from "../../../../reducers"

const DeleteUserSignature = ({
  fileName, user_id, setUploadedFileName, setUploadedFilePath
}) => {
  const baseUrl = useSelector(getBaseUrl)
  const [showMessage, setShowMessage] = useState(false)
  const [mounted, setMounted] = useState(true)

  useEffect(() => () => {
      setMounted(false)
    }, [])

  function handleUploadDelete() {
    const formData = new FormData()
    formData.append("signature_file", null)
    uploadUserSignature(baseUrl, user_id, formData)
      .then(res => {
        if (mounted && res.status === 200) {
          setUploadedFileName("")
          setUploadedFilePath("")
          setShowMessage(false)
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }


  const btnStyle = {
    border: "none",
    boxShadow: "none"
  };

  return (
    <>
      <i
        className="bi bi-x-circle-fill text-danger delete-file"
        onClick={() => setShowMessage(true)}
        title="Delete File" />
      <SweetAlert
        show={showMessage}
        type="custom"
        customClass="text-dark"
        title="Are you sure you want to delete this file?"
        style={{ width: "600px" }}
        confirmBtnText="Delete"
        confirmBtnCssClass="btn btn-danger"
        confirmBtnStyle={btnStyle}
        closeOnClickOutside
        allowEscape={false}
        showCancel
        onCancel={() => setShowMessage(false)}
        onConfirm={() => handleUploadDelete()}>
        <i className="bi bi-file-earmark mr-2"></i>
        {fileName || "No file selected"}
      </SweetAlert>
    </>
  )
}

export default DeleteUserSignature