import React, { useEffect, useState } from "react"
import Select from "react-select"
import { useSelector } from "react-redux"
import { getContact, putContact, putXeroContactIds } from "requesters/contactRequester"
import EmailValidator from "email-validator"
import { Spinner } from "react-bootstrap"
import CustBreadcrumb from "components/CustBreadcrumb"
import { useParams } from "react-router-dom"
import { roleCheck } from "utils/index"
import { getAllJobServices } from "requesters/jobServiceRequester"
import { toast } from "react-toastify"

import {
  ACCOUNTS_MANAGER,
  OPERATION_MANAGER,
  REGISTERED_SURVEYORS_MANAGER
} from "utils/constants"

import { getCurrentUser, getBaseUrl } from "../../reducers"
import { FIELD_VALIDATIONS } from "./formErrors"
import JobServicesTable from "../JobServices/JobServicesTable"


export default function ContactEdit() {
  const baseUrl = useSelector(getBaseUrl)
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role
  const { id } = useParams()


  const { clientTypes } = useSelector(state => state.options)
  const clientTypeOptions = clientTypes.map(v => ({ value: v[1], label: v[0] }))
  const [jobServices, setJobServices] = useState([])
  // form fields
  const [email, setEmail] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [company, setCompany] = useState("")
  const [clientType, setClientType] = useState("") // This is clientTypeRaw
  const [integrationContacts, setIntegrationContacts] = useState([])
  const [jobServicesCount, setJobServicesCount] = useState(0)
  // form events
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [validForm, setValidForm] = useState(true)
  const [loadingJobServices, setLoadingJobServices] = useState(false)
  const [page, setPage] = useState(1)


  const rolesAllowed = () => {
    if (roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) || roleCheck(systemRole, currentTeamRole, ACCOUNTS_MANAGER) || roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)) {
      return true
    }
    return false
  }

  const fetchData = () => {
    getContact(baseUrl, id)
      .then(res => {
        if (res.status === 200) {
          const userData = Object.values(_.get(res.response, "contactLite", {}))
          const userDetail = _.find(userData, item => item.id === id, {})
          setEmail(userDetail?.attributes?.email || "")
          setFirstName(userDetail?.attributes?.firstName || "")
          setLastName(userDetail?.attributes?.lastName || "")
          setPhoneNumber(userDetail?.attributes?.phoneNumber || "")
          setCompany(userDetail?.attributes?.company || "")
          setClientType(userDetail?.attributes?.clientTypeRaw)
          setJobServicesCount(userDetail?.attributes?.jobServicesCount)
          setIntegrationContacts(userDetail.attributes?.integrationContacts || [])
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  const fetchJobServices = () => {
    setLoadingJobServices(true)
    getAllJobServices(baseUrl, {
      contact_id: id, page, per_page: 10, sort_by: "desc"
    })
      .then(res => {
        if (res.status === 200) {
          const jobs = _.get(res.response, "jobService", {})
          const jobList = Object.values(jobs)
          setJobServices(currentJobList => [...currentJobList, ...jobList])
        }
      })
      .then(() => {
        setLoadingJobServices(false)
        // scroll to bottom
        setTimeout(() => {
          const element = document.getElementById("bottom-of-page")
          element.scrollIntoView({ behavior: "smooth" })
        }, 100)
      })
      .catch(() => {
        setLoadingJobServices(false)
      })
  }

  useEffect(() => {
    if (page > 1) {
      fetchJobServices()
    }
  }, [page])


  useEffect(() => {
    if (!rolesAllowed()) {
      window.location = "/"
    } else {
      fetchData()
      fetchJobServices()
    }
  }, [id])


  function handleSubmit() {
    const data = {
      contact: {
        email,
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        company,
        client_type: clientType,
      }
    }

    putContact(baseUrl, id, { data })
      .then(res => {
        setDisableSubmit(false)
        if (res.status === 200) {
          toast.success(
            <div>
              <span role="img" aria-label="success-icon">✅</span>
              <span className="ml-2">Save Contact Successfully!</span>
            </div>,
            {
              toastId: "rails-flash",
              containerId: "main"
            }
          )
        }
      })
      .catch(() => setDisableSubmit(false))
  }

  function handleSubmitXeroIds() {
    const convertedIntegrationContacts = integrationContacts.map(({ id, xeroContactId }) => ({ id, xero_contact_id: xeroContactId }))

    putXeroContactIds(baseUrl, id, convertedIntegrationContacts)
      .then(res => {
        setDisableSubmit(false);
        if (res.status === 200) {
          toast.success(
            <div>
              <span role="img" aria-label="success-icon">✅</span>
              <span className="ml-2">Xero Contact IDs updated successfully!</span>
            </div>,
            {
              toastId: "rails-flash",
              containerId: "main",
            }
          );
        }
      })
      .catch(() => {
        setDisableSubmit(false);
        toast.error(
          <div>
            <span role="img" aria-label="error-icon">❌</span>
            <span className="ml-2">Failed to update Xero Contact IDs.</span>
          </div>,
          {
            toastId: "rails-flash",
            containerId: "main",
          }
        );
      });
  }

  function validateForm() {
    let validFields = true
    const fields = [
      {
        email,
        firstName,
        lastName
      }
    ]

    _.map(fields[0], (value, key) => {
      switch (key) {
        case "email":
          validFields = EmailValidator.validate(value)
          break
        default:
          if (_.isEmpty(value)) {
            validFields = false
          }
      }
    })

    if (validFields) {
      setDisableSubmit(true)
      handleSubmit()
    }
    setValidForm(false)
  }

  function errorMessage(attribute, value) {
    if (!validForm) {
      const message = _.find(FIELD_VALIDATIONS, ["field", attribute])
      let validField = true

      switch (attribute) {
        case "email":
          validField = EmailValidator.validate(email)
          break
        case "phone_number":
          if (phoneNumber.length < 10 || phoneNumber.length > 12) {
            validField = false
          }
          break
        default:
          if (_.isEmpty(value)) {
            validField = false
          }
      }

      if (!validField) {
        return (
          <div className="err-msg">
            {message.message}
          </div>
        )
      }
      return null
    }
    return null
  }

  function handleXeroContactChange(index, newXeroContactId) {
    const updatedIntegrationContacts = [...integrationContacts]
    updatedIntegrationContacts[index].xeroContactId = newXeroContactId
    setIntegrationContacts(updatedIntegrationContacts)
  }

  return (
    <div className="pb-5 container-fluid">
      <CustBreadcrumb links={[["Contacts", "/contacts"]]} active="Edit" />
      <h2 className="mb-4">Edit Contact</h2>
      <div className="row">
        <div className="col-md-8">
          <div className="card border-0 shadow-sm">
            <div className="card-body p-4">
              <div className="form-group row">
                <label className="col-3 col-form-label">Email*</label>
                <div className="col-9">
                  <input
                    name="email"
                    className="form-control"
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={e => setEmail(e.target.value)} />
                  {errorMessage("email", email)}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">First Name*</label>
                <div className="col-9">
                  <input
                    name="first_name"
                    className="form-control"
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)} />
                  {errorMessage("first_name", firstName)}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">Last Name*</label>
                <div className="col-9">
                  <input
                    name="last_name"
                    className="form-control"
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={e => setLastName(e.target.value)} />
                  {errorMessage("last_name", lastName)}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">Phone Number</label>
                <div className="col-9">
                  <input
                    name="phone_number"
                    className="form-control"
                    type="text"
                    placeholder="optional"
                    value={phoneNumber}
                    onChange={e => setPhoneNumber(e.target.value)} />
                  {/* {errorMessage("phone_number", phoneNumber)} */}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">Company</label>
                <div className="col-9">
                  <input
                    name="company"
                    className="form-control"
                    type="text"
                    placeholder="optional"
                    value={company}
                    onChange={e => setCompany(e.target.value)} />
                  {/* {errorMessage("company", company)} */}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">Client Type</label>
                <div className="col-9">
                  <Select
                    name="client_type"
                    placeholder="Select"
                    value={_.filter(clientTypeOptions, ["value", clientType])}
                    options={clientTypeOptions}
                    onChange={e => setClientType(_.get(e, "value", ""))} />
                  {/* {errorMessage("client_type", clientType)} */}
                </div>
              </div>
              <div className="mt-4">
                <button
                  className="btn btn-red"
                  onClick={validateForm}
                  disabled={disableSubmit}
                  type="submit">
                  Save Contact
                  {disableSubmit && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
                </button>
              </div>
            </div>
          </div>
        </div>
        {roleCheck(systemRole, currentTeamRole, ACCOUNTS_MANAGER) && (
          <div className="col-md-4">
            <div className="card border-0 shadow-sm">
              <div className="card-body p-4">
                {integrationContacts.map((integrationContact, index) => (
                  <div key={integrationContact.id} className="form-group row">
                    <label className="col-3 col-form-label">Xero Contact ID ({index + 1})</label>
                    <div className="col-9">
                      <input
                        className="form-control"
                        type="text"
                        value={integrationContact.xeroContactId || ""}
                        onChange={e => handleXeroContactChange(index, e.target.value)} />
                    </div>
                  </div>
                ))}
                <div className="mt-4">
                  <button
                    className="btn btn-red"
                    onClick={handleSubmitXeroIds}
                    type="submit">
                    Save Xero IDs
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        
      </div>
      {jobServicesCount > 0 && jobServices.length === 0 && (
        <div className="d-flex justify-content-center mt-4">
          Load Related Job Services...
        </div>
      )}
      {jobServices.length > 0 && (
        <div className="card border-0 mt-4">
          <div className="card-body p-4">
            <JobServicesTable jobServiceList={jobServices} />
            {(jobServices.length > 0 && jobServices.length < jobServicesCount) && (
              <div className="d-flex justify-content-center mt-4">
                <button
                  disabled={loadingJobServices}
                  className="btn btn-red"
                  type="button"
                  onClick={() => {
                    setPage(page + 1)
                  }}>
                  Load More
                  {loadingJobServices && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      <span id="bottom-of-page" />
    </div>
  )
}
