import React, { useEffect, useState, useCallback } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import SweetAlert from "react-bootstrap-sweetalert";
import DatePicker from "react-datepicker";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";
import Dropzone from "components/utils/Dropzone";
import { debounce, get, isEmpty, omitBy, isNull } from "lodash"
import { getBaseUrl } from "../../reducers";
import { createTask } from "../../../../requesters/taskRequester";
import {
  TASK_TYPES,
  MAX_SIZE_UPLOADED_FILE,
  MAX_SIZE_UPLOADED_FILE_TEXT
} from "../../../../utils/constants";
import { getRegisterdSurveyors } from "../../../../requesters/userRequester";
import { getJobServicesForRsTask } from "../../../../requesters/jobServiceRequester";
import { addPayloadToFormData, capitalizeString } from "../../../../utils";


const AddTaskModal = ({
  show, onHide, reloadData, modalTitle, jobService, onCreated
}) => {
  const baseUrl = useSelector(getBaseUrl)
  const [regSurveyorOptions, setRegSurveyorOptions] = useState([])
  const [jobServiceOptions, setJobServiceOptions] = useState([])
  const [taskType, setTaskType] = useState(null)
  const [assignedRegSurveyorId, setAssignedRegSurveyorId] = useState(null)
  const [linkToJobServiceId, setLinkToJobServiceId] = useState(null)
  const [startAt, setStartAt] = useState(null)
  const [finishAt, setFinishAt] = useState(null)
  const [notes, setNotes] = useState(null)
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [largeUploadedFiles, setLargeUploadedFiles] = useState([])
  const [showFilesError, setShowFilesError] = useState(false)
  const [requesting, setRequesting] = useState(false)
  const { to_review, ...filteredTypes } = TASK_TYPES
  const taskTypeOptions = Object.keys(filteredTypes).map(type => ({ label: capitalizeString(type), value: filteredTypes[type] }))

  const clearStates = () => {
    setTaskType(null)
    setAssignedRegSurveyorId(null)
    setLinkToJobServiceId(null)
    setStartAt(null)
    setFinishAt(null)
    setNotes(null)
    setUploadedFiles([])
    setLargeUploadedFiles([])
    setShowFilesError(false)
    setRequesting(false)
  }

  const onAddingTask = () => {
    setRequesting(true)
    let payload = {
      workable_id: linkToJobServiceId,
      workable_type: "job_service",
      assigned_user_id: assignedRegSurveyorId,
      task_type: taskType,
      start_at: startAt,
      finished_at: finishAt,
      notes,
      "uploaded_files[]": uploadedFiles
    }
    payload = omitBy(payload, isNull);
    const data = addPayloadToFormData(new FormData(), payload)

    createTask(baseUrl, data)
    .then(res => {
      if (res.status === 201) {
        reloadData()
        if (onCreated) {
          const createdTask = res.response?.task ? Object.values(res.response?.task)?.shift() : null
          onCreated(createdTask);
        }
        onHide()
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
    .finally(() => setRequesting(false))
  }

  const fetchRegSurveyorOptions = () => {
    getRegisterdSurveyors(baseUrl)
    .then(res => {
      if (res.status === 200) {
        const newRegSurveyorOptions = res.response.map(surveyorData => ({ label: `${surveyorData[0]} ${surveyorData[1]}`, value: surveyorData[2] }))
        setRegSurveyorOptions(newRegSurveyorOptions)
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }

  const fetchJobServices = (query, callback) => {
    getJobServicesForRsTask(baseUrl, query)
    .then(res => {
      if (res.status === 200) {
        const jobServices = get(res.response, "jobService", {})
        let jobServiceList = Object.values(jobServices)
        const additionalInfo = res.response.meta?.additional_info
        if (additionalInfo && !isEmpty(additionalInfo.meta?.ids)) {
          jobServiceList = additionalInfo.meta.ids.map(id => jobServices[id])
        }
        const options = jobServiceList.map(service => ({ label: `${service.attributes.jobNo} ${service.attributes.label}`, value: service.id }))
        setJobServiceOptions(options)
        callback(options)
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }

  const debouncedFetchJobServices = useCallback(debounce(fetchJobServices, 500), [])

  const onCheckingFiles = e => {
    const files = e.value
    const largeFiles = files?.filter(file => file.size > MAX_SIZE_UPLOADED_FILE)
    if (largeFiles?.length) {
      setLargeUploadedFiles(largeFiles)
      setShowFilesError(true)
    }
  }

  useEffect(() => {
    if (show) {
      fetchRegSurveyorOptions()
      if (jobService) {
        setLinkToJobServiceId(jobService.id)
      } else {
        fetchJobServices()
      }
    } else {
      clearStates()
    }
  }, [show])

  const canSubmit = !!taskType?.toString() && !!assignedRegSurveyorId
  const SelectedLinkedJobServiceOption = jobServiceOptions.find(o => o.value === linkToJobServiceId)

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <div>
          <h2 className="modal-title">{modalTitle || "Add Task"}</h2>
        </div>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          <div className="form-group row">
            <label className="col-3 col-form-label">Activity Type</label>
            <div className="col-7">
              <Select
                name="task_type"
                placeholder="Select"
                value={taskTypeOptions.find(o => o.value === taskType)}
                options={taskTypeOptions}
                onChange={o => setTaskType(o.value)} />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Assign To</label>
            <div className="col-7">
              <Select
                name="assign_to"
                placeholder="Assign to RS"
                value={regSurveyorOptions.find(o => o.value === assignedRegSurveyorId)}
                options={regSurveyorOptions}
                onChange={o => setAssignedRegSurveyorId(o.value)} />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Link To Job Service</label>
            <div className={`col-7 ${jobService ? "d-flex align-items-center" : ""}`}>
              { jobService ? (
                <span className="ml-2">{`${jobService.attributes?.jobNo} ${jobService.attributes?.label}`}</span>
              ) : (
                <AsyncSelect
                  name="link_to_job_service_id"
                  isClearable
                  isSearchable
                  placeholder="Link to Job Service"
                  defaultOptions={jobServiceOptions}
                  options={jobServiceOptions}
                  loadOptions={debouncedFetchJobServices}
                  value={SelectedLinkedJobServiceOption}
                  onChange={o => setLinkToJobServiceId(o?.value)} />
              )}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Start At</label>
            <div className="col-7">
              <DatePicker
                id="start_at"
                wrapperClassName="date_picker full-width w-100"
                className="form-control"
                timeFormat="HH:mm a"
                showTimeSelect
                timeIntervals={15}
                timeCaption="Time"
                selected={startAt}
                onChange={date => setStartAt(date)}
                dateFormat="dd/MM/yyyy, hh:mm aa" />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Finish At</label>
            <div className="col-7">
              <DatePicker
                id="finish_at"
                wrapperClassName="date_picker full-width w-100"
                className="form-control"
                timeFormat="HH:mm a"
                showTimeSelect
                timeIntervals={15}
                timeCaption="Time"
                selected={finishAt}
                onChange={date => setFinishAt(date)}
                dateFormat="dd/MM/yyyy, hh:mm aa" />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Notes</label>
            <div className="col-7">
              <ReactQuill
                placeholder="e.g. existing dwelling, any substantial trees"
                value={notes}
                onChange={value => setNotes(value)} />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-3 col-form-label">Attachments</label>
            <div className="col-7">
              <Dropzone
                wrapperClass="drag-drop"
                multiple
                name="photo"
                setUploadedFiles={setUploadedFiles}
                handleFileUpload={onCheckingFiles} />
              <div className="uploaded-attachments">
                { uploadedFiles.map(file => (
                  <p key={file.path} className="file-list-item mb-0 mr-2">
                    <i className="bi bi-file-earmark mr-2"></i>
                    {file.path}
                  </p>
                ))}
              </div>
              <SweetAlert
                show={showFilesError}
                type="error"
                title="File size exceeded!"
                style={{ width: "600px" }}
                onConfirm={() => {
                  setShowFilesError(false)
                  setLargeUploadedFiles([])
                  setUploadedFiles([])
                }}
                allowEscape={false}
                closeOnClickOutside>
                {`There're ${largeUploadedFiles.length} files over the Max file size ${MAX_SIZE_UPLOADED_FILE_TEXT}`}
              </SweetAlert>
            </div>
          </div>
        </form>
        <hr />
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-red btn-lg"
          onClick={onAddingTask}
          disabled={requesting || !canSubmit}
          type="submit">
          Add
          {requesting && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
        </button>
      </div>
    </Modal>
  )
}

export default AddTaskModal