import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import SweetAlert from "react-bootstrap-sweetalert"
import { Spinner } from "react-bootstrap"
import { uploadUserSignature } from "requesters/userRequester"
import { getBaseUrl } from "../../../reducers"
import DeleteUserSignature from "./components/DeleteUserSignature"
import {
  MAX_SIZE_UPLOADED_FILE,
  MAX_SIZE_UPLOADED_FILE_TEXT,
  stringEllipsis
} from "../../../../../utils/constants"

export default function UserUploadFile({
  title, user_id, path, fileName, editState,
  labelWrapperClass, inputWrapperClass
}) {
  const baseUrl = useSelector(getBaseUrl)
  const [showError, setShowError] = useState(false)
  const [uploading, setUploading] = useState({
    signature: false
  })
  const [uploadedFileName, setUploadedFileName] = useState(fileName)
  const [uploadedFilePath, setUploadedFilePath] = useState(path)


  const handleUploadFile = file => {
    if (file.size > MAX_SIZE_UPLOADED_FILE) {
      setShowError(true)
    } else {
      const formData = new FormData()
      formData.append("signature_file", file)
      setUploading({ ...uploading, signature: true })
        uploadUserSignature(baseUrl, user_id, formData)
        .then(res => {
          if (res.status === 200) {
            setUploading({ ...uploading, signature: false })
            const userData = _.get(res.response.user, `[${user_id}].attributes`, {})
            setUploadedFileName(userData?.surveyorSignatureFileName)
            setUploadedFilePath(userData?.surveyorSignaturePath)
          }
        })
        // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    }
  }

  const shortenFileName = fileName => {
    if (fileName) {
      return stringEllipsis(fileName, 20, 25)
    }
    return "Choose file"
  }

  useEffect(() => {
    setUploading({
      signature: false
    })
  }, [uploadedFileName, uploadedFilePath])

  if (editState) {
    return (
      <div className="form-group row">
        <div className={`${labelWrapperClass || "col-3"} col-form-label`}>{title}</div>
        <div className={inputWrapperClass || "col-8"}>
          <div className="custom-file">
            <input
              className="custom-file-input"
              id="surveyorSignature"
              type="file"
              onChange={e => handleUploadFile(e.currentTarget.files[0])}
              title={uploadedFileName} />
            <label
              className="custom-file-label"
              htmlFor="surveyorSignature">
              {shortenFileName(uploadedFileName)}
            </label>
          </div>
          {uploadedFileName && (
            <div className="mr-3 mt-2">
              <a
                href={uploadedFilePath}
                target="_blank"
                rel="noopener noreferrer"
                title={uploadedFileName}>
                <img
                  src={uploadedFilePath}
                  width="80"
                  height="80"
                  className="img-preview" />
              </a>
              <DeleteUserSignature
                setUploadedFileName={setUploadedFileName}
                setUploadedFilePath={setUploadedFilePath}
                fileName={uploadedFileName}
                user_id={user_id} />
            </div>
            )}
        </div>
        {uploading.signature && (
          <Spinner
            animation="border"
            variant="secondary" />
        )}
        <SweetAlert
          show={showError}
          type="error"
          title="File size exceeded!"
          style={{ width: "600px" }}
          closeOnClickOutside
          allowEscape={false}
          onConfirm={() => setShowError(false)}>
          Max size is {MAX_SIZE_UPLOADED_FILE_TEXT}
        </SweetAlert>
      </div>
    )
  }
  return (
    <div className="row mb-2">
      <div className="col-3">{title}</div>
      <div className="col-9">
        { path ? <a href={path} target="_blank" rel="noopener noreferrer" title={fileName}>{shortenFileName(fileName)}</a> : "No signature uploaded" }
      </div>
    </div>
  )
}