import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { roleCheck } from "utils/index";
import ActivityTimelinePoint from "./ActivityTimelinePoint";
import GpsActivityCompleted from "../GpsActivityCompleted";
import { updateActivityComment } from "../../../../../../../requesters/jobServiceRequester";
import { getBaseUrl, getCurrentUser } from "../../../../../reducers";
import EditCheckOutModal from "../../../modals/EditCheckOutModal";
import {
  CANCELLED_LOG,
  RESCHEDULED_LOG,
  ON_HOLD_LOG,
  OPERATION_MANAGER
} from "../../../../../../../utils/constants";

const ActivityTimelineAuditItem = ({
  jobService, reloadData, activity, lastActivity, isAdmin
}) => {
  const {
    data: {
      event, nextState, userName, label, timestamp, comment, id, checkSurveyId, checkSurveyCompleted, auditType, surveyorAssignmentId, hideAction, auditedChanges
    }
  } = activity

  const {
    attributes: {
      state,
      scheduledAssignments,
    }
  } = jobService

  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role
  const baseUrl = useSelector(getBaseUrl)
  const [onEditView, setOnEditView] = useState(false)
  const [currentComment, setCurrentComment] = useState(comment)
  const [showCheckOutModal, setShowCheckOutModal] = useState(false)
  const [activeAssignment, setActiveAssignment] = useState(null)
  const isCheckedOutLog = label.includes("Checked out")
  const isCollectPaymentOnsite = /\bCollected \$\d+(\.\d{1,2})? Payment onsite amount\b/.test(label);
  const isClientNote = activity.data.label === "Client notes"
  const isRescheduledActivityLog = event === RESCHEDULED_LOG.event && nextState === RESCHEDULED_LOG.nextState
  const isOnHoldLog = event === ON_HOLD_LOG.event && nextState === ON_HOLD_LOG.nextState
  const canEdit = (isAdmin && comment && comment.trim() !== "" && !isCheckedOutLog && (!isCollectPaymentOnsite || !isClientNote))
                || (isAdmin && isRescheduledActivityLog)
                || (isAdmin && isOnHoldLog)

  const isCancelledActivityLog = event === CANCELLED_LOG.event && nextState === CANCELLED_LOG.nextState
  const notAllowToUpdate = isCancelledActivityLog && currentComment.trim() === ""

  const timelineTitle = () => (
    <div className="grey-sub-activities">
      <span>
        {label?.split("\n").map((line, index) => (
          <span key={`${line}-${index}`}>
            {line}
            {index < label.split("\n").length - 1 && <br />}
          </span>
        ))}
      </span>
      <span className="activities-timestamp ml-3">{[timestamp, userName].filter(value => !!value).join(" - ")}</span>
      { canEdit && (
        <button
          disabled={!canEdit}
          onClick={() => setOnEditView(true)}
          type="button"
          className="btn btn-link">
          Edit
        </button>
      )}
    </div>
  )

  const updateComment = () => {
    updateActivityComment(baseUrl, jobService.id, { audit_id: id, comment: currentComment, audit_type: auditType })
    .then(res => {
      if (res.status === 200 && res.response?.success) {
        setOnEditView(false)
        reloadData()
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }

  const onCancelEdit = () => {
    setOnEditView(false)
    setCurrentComment(comment)
  }

  const CommentElement = () => {
    if (!onEditView) {
      return <div dangerouslySetInnerHTML={{ __html: currentComment }} />;
    }

    return (
      <>
        <textarea
          className="form-control mr-5"
          rows="4"
          value={currentComment}
          onChange={e => setCurrentComment(e.target.value)} />
        <button type="button" className="btn btn-outline-secondary mt-2 mr-2" onClick={onCancelEdit}>
          Cancel
        </button>
        <button disabled={notAllowToUpdate} type="button" className="btn btn-outline-primary mt-2" onClick={updateComment}>
          Update
        </button>
      </>
    )
  }

  const CheckSurveySection = () => {
    if (!["book_in_check_survey", "complete_check_survey"].includes(event)) return <></>;

    const {
      attributes: { jobId, isBookInCheckSurvey, bookInJobParent }
    } = jobService
    let url = `/jobs/${jobId}/job_services/${checkSurveyId}?tab=Files`;
    let displayText = "CHK files click here"

    if (isBookInCheckSurvey && bookInJobParent) return <></>;

    const canShowCheckSurveyLog = event === "complete_check_survey" || (!checkSurveyCompleted)

    return (
      <React.Fragment>
        { canShowCheckSurveyLog ? (
          <div className="timeline-item mt-2 mb-2">
            <div className="card border-0 shadow-sm">
              <div className="card-body">
                <div className="row px-3 flex-column">
                  <p className="mb-0">
                    <Link
                      to={url}
                      onClick={() => { window.location.href = url }}
                      className="link-no-decoration">
                      {displayText}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : <></>}
      </React.Fragment>
    )
  }

  const EditCheckOutPercentage = () => {
    if (hideAction === true) return null;

    return (
      <>
        {scheduledAssignments.map((assignment, index) => {
          const { assignmentsWithSameTime, primarySurveyorAssignmentId, assignments } = assignment;

          if (roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) && primarySurveyorAssignmentId === surveyorAssignmentId) {
            return (
              <div key={index}>
                <button
                  type="button"
                  className="btn btn-outline-primary mt-2"
                  onClick={() => {
                    setShowCheckOutModal(true);
                    setActiveAssignment(
                      assignments.find(
                        (a) => a.surveyorAssignmentId === primarySurveyorAssignmentId
                      )
                    );
                  }}
                >
                  Change Checkout %
                </button>
                <EditCheckOutModal
                  jobService={jobService}
                  reloadData={reloadData}
                  show={showCheckOutModal}
                  surveyorAssignmentId={primarySurveyorAssignmentId}
                  assignment={activeAssignment}
                  productName={jobService.attributes.productName}
                  assignmentsWithSameTime={assignmentsWithSameTime}
                  onHide={() => setShowCheckOutModal(false)}
                />
              </div>
            )
          }
          return null
        })}
      </>
    );

    return null
  };

  return (
    <React.Fragment>
      <ActivityTimelinePoint event={event} state={nextState} mainActivity={false} lastActivity={lastActivity} />
      <div className="d-flex justify-content-between">
        <div className="activity-status">
          <div className="grey-sub-activities">
            <div>{ timelineTitle() }</div>
          </div>
        </div>
      </div>
      {CheckSurveySection()}
      {(event === "complete_gps" || event === "decline_gps")
        && (
          <GpsActivityCompleted
            jobService={jobService}
            gpsState={event} />
        )
      }
      {((auditedChanges || []).includes('checked_out_at') && ['field_work_incompleted', 'field_work_finished'].includes(state)) && (
        EditCheckOutPercentage()
      )}
      <div className={`${(onEditView || !currentComment) ? "" : "bg-yellow"} mt-2 py-2 px-3 rounded`}>
        {CommentElement()}
        {activity.data.associatedFiles?.map(file => (
          <div className="my-2" key={file.data.id}>
            <a
              href={file.data?.attributes?.filePath}
              target="_blank"
              rel="noopener noreferrer"
              className="d-block text-dark">
              <i className="bi bi-file-earmark mr-2"></i>
              {file.data?.attributes?.fileName}
            </a>
          </div>
        ))}
      </div>
    </React.Fragment>
  )
}

export default ActivityTimelineAuditItem
