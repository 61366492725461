import React from "react";
import { useSelector } from "react-redux";
import { Draggable } from "react-beautiful-dnd";
import {
  REGISTERED_SURVEYORS_USER,
  REGISTERED_SURVEYORS_MANAGER,
} from "utils/constants"
import { roleCheck } from "utils/index"
import { getCurrentUser } from "../../reducers";
import TaskRow from "./TaskRow";

const DraggableTaskRow = ({
  task, index, setTasks, reloadData
}) => {
  const currentUser = useSelector(getCurrentUser)
  const currentTeamRole = currentUser.attributes.current_team_role
  const systemRole = currentUser.attributes.role
  const isRegisteredSurveyorsManager = roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)
  const disableDrag = !!((currentTeamRole.includes(REGISTERED_SURVEYORS_USER)))

  return (
    <Draggable
      draggableId={task?.id}
      index={index}
      isDragDisabled={disableDrag}>
      { provided => (
        <React.Fragment>
          {(index === 10 && isRegisteredSurveyorsManager) && (
            <tr><td colSpan="100%" className="draggable-separate-line" /></tr>
          )}
          <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}>
            <TaskRow
              key={task.id}
              disableDrag={disableDrag}
              task={task}
              reloadData={reloadData}
              index={index} />
          </tr>
        </React.Fragment>
      )}
    </Draggable>
  )
}

export default DraggableTaskRow