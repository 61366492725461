import { getBaseUrl } from "apps/App/reducers";
import _ from "lodash";
import React, { useState, useMemo } from "react";

import { useSelector } from "react-redux";
import { getSurveyorTypes } from "requesters/surveyorTypesRequester";

const UserSurveyorType = ({ editState, updateAttr, userEditableAttr }) => {
  const baseUrl = useSelector(getBaseUrl)
  const { surveyor_type: surveyorType } = userEditableAttr
  const [surveyorTypes, setSurveyorTypes] = useState([])
  const [surveyorValue, setSurveyorValue] = useState(null)

  const fetchSurveyorType = async () => {
    const result = await getSurveyorTypes(baseUrl)

    if ("response" in result) {
      const { surveyorType } = result.response
      const surveyorTypesList = Object.values(surveyorType)

      setSurveyorTypes(surveyorTypesList)
    }

    if ("error" in result) {
      console.error(result.error)
    }
  }

  useMemo(fetchSurveyorType, [])
  useMemo(() => {
    if (surveyorTypes.length) {
      const { attributes: { value } } = _.find(surveyorTypes, { attributes: { label: surveyorType } })

      setSurveyorValue(value)
    }
  }, [surveyorType, surveyorTypes])

  return (
    <>
      {
        editState
          ? <UserEditSurveyorType surveyorType={surveyorType} surveyorTypes={surveyorTypes} updateAttr={updateAttr} />
         : <UserShowSurveyorType surveyorType={surveyorType} />
      }
    </>
  );
};

const UserShowSurveyorType = ({ surveyorType }) => (
  <div className="row mb-2">
    <div className="col-3">Surveyor Type</div>
    <div className="col-9">
      <p>
        {surveyorType === "Surveyor 3d" ? "3D" : surveyorType}
      </p>
    </div>
  </div>
)

const UserEditSurveyorType = ({
  updateAttr, surveyorType, surveyorTypes
}) => (
  <>
    <div className="row mb-2">
      <div className="col-3">Surveyor Type</div>
      <div className="col-4">
        <select
          value={surveyorType}
          onChange={e => updateAttr({ surveyor_type: e.target.value })}
          className="form-control form-control-sm">
          {surveyorTypes.map(option => (
            <option key={option.id} value={option.attributes.label}>
              {option.attributes.label === "Surveyor 3d" ? "3D" : option.attributes.label}
            </option>
            ))}
        </select>
      </div>
    </div>
  </>
  )

export default UserSurveyorType;
