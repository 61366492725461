import customAxios from "./customAxios";

export const getGooglePlacesAutocomplete = (baseUrl, by_query) => {
  const path = `${baseUrl}/google_places/autocomplete`

  return customAxios.get(path, { params: by_query })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }))
}

export const getGooglePlacesDetail = (baseUrl, place_id) => {
  const path = `${baseUrl}/google_places/detail`

  return customAxios.get(path, { params: place_id })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }))
}
