import normalize from "json-api-normalizer"

const resourceFetchedSuccess = data => ({
  type: "@@axios/DATA_RETRIEVED",
  data
})

export default class AxiosResourceMiddleware {
  constructor(store) {
    this.store = store
  }

  onRequest(config) {
    return config
  }

  onSync(promise) {
    return promise
  }

  onResponse(response) {
    if (response && response.data) {
      const data = normalize(JSON.parse(response.data))
      this.store.dispatch(resourceFetchedSuccess(data))
    }
    return response
  }
}