import React from "react";
import { useSelector } from "react-redux";
import {
  reorderDots,
} from "components/Icons";
import {
  REGISTERED_SURVEYORS_MANAGER,
  REGISTERED_SURVEYORS_USER,
  TASK_STATUSES
} from "utils/constants";
import { roleCheck } from "utils/index";
import { Link } from "react-router-dom";
import { getCurrentUser } from "../../reducers";
import { capitalizeString } from "../../../../utils";

const TaskRow = ({
  task, reloadData, disableDrag
}) => {
  const {
    id: taskId,
    attributes:
    {
      job_service,
      task_type,
      assigned_user_name,
      state,
      notes,
      finished_at,
      start_at,
      created_by_user
    }
  } = task
  // const notesDisplay = notes?.substring(0, 50).concat(notes?.length > 50 ? "..." : "")
  const jobServiceId = job_service?.id
  const jobId = job_service?.job_id
  const jobServiceLabel = job_service?.label
  const jobServiceCode = job_service?.code
  const jobAddress = job_service?.job_address
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role
  const isRegisteredSurveyorsUser = roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_USER)
  const isRegisteredSurveyorsManager = roleCheck(systemRole, currentTeamRole, REGISTERED_SURVEYORS_MANAGER)

  const stripHtmlTags = (str) => str?.replace(/<[^>]*>/g, '')
  const notesWithoutTags = stripHtmlTags(notes)
  const notesDisplay = notesWithoutTags?.substring(0, 50).concat(notesWithoutTags?.length > 50 ? "..." : "")

  return (
    <>
      <td className="bg-white">
        { !disableDrag && <img src={reorderDots} style={{ width: 20 }} /> }
      </td>
      <td>
        <Link to={`/registered-surveyors-activities/${taskId}`} className="link-no-decoration">{capitalizeString(task_type)}</Link>
      </td>
      <td>
        <Link to={`/registered-surveyors-activities/${taskId}`} className="link-no-decoration">{TASK_STATUSES[state]}</Link>
      </td>
      { job_service ? (
        <>
          <td>
            <Link to={`/jobs/${jobId}/job_services/${jobServiceId}`} className="link-no-decoration">{jobAddress}</Link>
          </td>
          <td>
            <Link to={`/jobs/${jobId}/job_services/${jobServiceId}`} className="link-no-decoration">{jobServiceCode}</Link>
          </td>
        </>
      ) : (
        <td colSpan={2} className="my-auto">
          <Link to={`/registered-surveyors-activities/${taskId}`} className="link-no-decoration">{notesDisplay}</Link>
        </td>
      )}
      <td>
        <Link to={`/registered-surveyors-activities/${taskId}`} className="link-no-decoration">{finished_at && moment(finished_at).format("DD/MM/YYYY HH:mm:ss")}</Link>
      </td>
      <td>
        <Link to={`/registered-surveyors-activities/${taskId}`} className="link-no-decoration">{start_at && moment(start_at).format("DD/MM/YYYY HH:mm:ss")}</Link>
      </td>
      { isRegisteredSurveyorsManager && (
        <td>
          <Link to={`/registered-surveyors-activities/${taskId}`} className="link-no-decoration">{created_by_user?.name}</Link>
        </td>
      )}
      <td className="actions-btn">
        <div className="d-flex align-items-center justify-content-end">
          <Link to={`/registered-surveyors-activities/${taskId}`}>Details</Link>
        </div>
      </td>
    </>
  )
}

export default TaskRow