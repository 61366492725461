import React from "react"
import { Link } from "react-router-dom";
import cx from "classnames";
import { format } from "date-fns"
import { JOB_STATES_DISPLAY } from "utils/constants"

const jobStates = {
  to_draft: {
    text: "Drafting Required",
    bgClass: "bg-light-blue",
    api: "reset-to-draft",
    btnClass: "bg-light-blue"
  },
  qa: {
    text: "QA Required",
    bgClass: "bg-light-blue",
    api: "set-qa",
    btnClass: "bg-light-blue"
  },
  overseas: {
    text: "Overseas",
    bgClass: "bg-yellow",
    api: "overseas",
    btnClass: "btn-yellow"
  },
};

const RowForDrafting = ({ activities }) => {
  const {
    id,
    attributes: {
      jobId, jobNo, forProjectX, projectXJobNo, jobAddress, label, surveyorName, draftmanName, state, checkedOutAt
    }
  } = activities
  const displayJobNo = forProjectX ? projectXJobNo : jobNo

  return (
    <tr>
      <td>
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{displayJobNo}</Link>
      </td>
      <td>
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{jobAddress}</Link>
      </td>
      <td>
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{label}</Link>
      </td>
      <td>
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{surveyorName}</Link>
      </td>
      <td className="text-center">
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{format(new Date(checkedOutAt), "dd MMM yyyy")}</Link>
      </td>
      <td>
        <Link to={`/jobs/${jobId}/job_services/${id}`} className="link-no-decoration">{draftmanName}</Link>
      </td>
      <td className={cx(`dropdown text-center ${JOB_STATES_DISPLAY[state]?.bgClass}`)}>
        <button
          className="btn dropdown-toggle"
          disabled
          type="button">
          {JOB_STATES_DISPLAY[state]?.text}
        </button>
      </td>
      <td className="text-center"></td>
      <td className="text-right">
        <Link to={`/jobs/${jobId}/job_services/${id}`}>Details</Link>
      </td>
    </tr>

  )
}

export default RowForDrafting
