
import React from "react"
import DatePicker from "react-datepicker"
import _ from "lodash"
import moment from "moment"
import { createGlobalStyle } from "styled-components"

const DatePickerWrapperStyles = createGlobalStyle`
.date_picker.full-width {
    width: 100%;
}
`

export default function HolidayDateInput({
  field, value, setInputValue
}) {
  const formattedDate = value ? moment(value, "yyyy-MM-DD").toDate() : null
  return (
    <div className="form-group row">
      <label htmlFor="start-date" className="col-3 col-form-label">{_.startCase(field)}</label>
      <div className="col-9">
        <DatePicker
          id={field}
          wrapperClassName="date_picker full-width"
          className="form-control"
          selected={formattedDate}
          onChange={date => setInputValue(date)}
          dateFormat="yyyy-MM-dd" />
        <DatePickerWrapperStyles />
      </div>
    </div>
  )
}
