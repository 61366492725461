import React, { useEffect, useState } from "react";
import Loader from "components/Loader"
import DatePicker from "react-datepicker"
import moment from "moment"
import { useSelector } from "react-redux";
import {
  useQueryParam,
  StringParam,
  withDefault
} from "use-query-params"
import { getBaseUrl } from "../../reducers";
import { getRegisteredSurveyorReports } from "../../../../requesters/performanceReportRequester";
import ReportTable from "./RegisteredSurveyorReport/ReportTable";

const getDate = val => {
  const momentObj = moment(val)
  const dateString = momentObj.format("YYYY-MM-DD")

  return dateString
}

const customDateParam = withDefault(StringParam, getDate(new Date()))

export default function RegisteredSurveyorReports() {
  const baseUrl = useSelector(getBaseUrl)
  const [requesting, setRequesting] = useState(false)
  const [summaryRows, setSummaryRows] = useState([])
  const [totalRow, setTotalRow] = useState([])
  const [detailedRows, setDetailedRows] = useState([])
  const [finishDate, setFinishDate] = useQueryParam("rs_finish_date", customDateParam)

  const initialFilter = {
    finish_date: finishDate
  }
  const [filters, setFilters] = useState(initialFilter)

  const fetchData = () => {
    setRequesting(true)
    getRegisteredSurveyorReports(baseUrl, filters)
      .then(res => {
        if (res.status === 200) {
          setSummaryRows(res.data.json.summary)
          setTotalRow(res.data.json.total)
          setDetailedRows(res.data.json.detail)
        }
      })
      .catch(err => console.log(err));
      setRequesting(false)
  }

  const onFinishDateChange = val => {
    const newFinishDate = getDate(val)
    setFinishDate(newFinishDate)
    setFilters({ ...filters, finish_date: newFinishDate })
  }

  const clearStates = () => {
    setFinishDate(undefined)
    setFilters({})
  }

  useEffect(() => {
    fetchData()
  }, [filters])

  useEffect(() => () => clearStates(), [])

  const finishDateObject = moment(finishDate, "YYYY-MM-DD").toDate()

  return (
    <React.Fragment>
      <div className="container-inner">
        <div className="table-listing-filters d-md-flex flex-row justify-content-between">
          <DatePicker
            selected={finishDateObject}
            onChange={onFinishDateChange}
            closeOnScroll={e => e.target === document}
            className="form-control mb-2 mt-2 ml-1 "
            dateFormat="dd/MM/yyyy" />
        </div>
      </div>
      {requesting ? <Loader /> : <ReportTable summaryRows={summaryRows} totalRow={totalRow} detailedRows={detailedRows} />}
    </React.Fragment>
  )
}