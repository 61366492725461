import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";

const ReleasedFilesConfirmationModal = ({
  show, onHide, modalConfirmed, filesHandler, message
}) => (
  <Modal show={show} onHide={onHide} size="lg" centered>
    <Modal.Header closeButton>
      <Modal.Title>
        <h2 className="modal-title text-center">{message}</h2>
      </Modal.Title>
    </Modal.Header>
    <div className="modal-footer pt-4 d-flex align-items-center justify-content-center">
      <button className="btn btn-lg btn-blue" disabled={modalConfirmed} type="button" onClick={() => filesHandler()}>
        {modalConfirmed ? <Spinner animation="border" role="status" size="sm" /> : "Proceed"}
      </button>
    </div>
  </Modal>
  )
export default ReleasedFilesConfirmationModal