import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Container } from "react-bootstrap"
import { useQueryParam, StringParam } from "use-query-params"
import { roleCheck } from "utils/index"
import { OPERATION_MANAGER, ACCOUNTS_MANAGER } from "utils/constants"

import SearchInput from "components/SearchInput"
import FilterDropdown from "components/FilterDropdown"
import Loader from "components/Loader"
import { getGears } from "requesters/gearRequester"
import { getUsers } from "requesters/userRequester"
import { Link } from "react-router-dom";
import AnchorButton from "components/AnchorButton"
import { getBaseUrl, getCurrentUser } from "../../../reducers"

import GearsTable from "./GearsTable"

export default function Gears() {
  const baseUrl = useSelector(getBaseUrl)
  const { gearStatus } = useSelector(state => state.options)
  const [userOptions, setUserOptions] = useState([])

  const [byStatus, setByStatus] = useQueryParam("by_status", StringParam)
  const [bySearch, setBySearch] = useQueryParam("by_search", StringParam)
  const [byUser, setByUser] = useQueryParam("by_user", StringParam)

  const [gears, setGears] = useState([])
  const [gearIds, setGearIds] = useState([])

  const [requesting, setRequesting] = useState(false)
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentTeamRole = currentUser.attributes.current_team_role

  const rolesAllowed = () => {
    if (roleCheck(systemRole, currentTeamRole, OPERATION_MANAGER) || roleCheck(systemRole, currentTeamRole, ACCOUNTS_MANAGER)) {
      return true
    }
    return false
  }


    async function fetchData() {
        const { response, error } = await getGears(baseUrl, {
            by_status: byStatus,
            by_keyword: bySearch,
            by_user: byUser
        })
        if (response) {
            setGears(_.get(response, "gear", {}))
            setGearIds(_.get(response, "meta.meta.meta.ids", []))
        }
        if (error) {
            console.error(error)
        }
    }

    const fetchUsers = () => {
      getUsers(baseUrl)
      .then(res => {
        if (res.status === 200) {
          const userData = Object.values(_.get(res.response, "user", {}))
          const userList = userData.map(user => [user.attributes.name, user.attributes.name])
          setUserOptions(userList)
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
    }

    useEffect(() => {
      setRequesting(true)
      if (!rolesAllowed()) {
        window.location = "/"
      } else {
        fetchData()
        fetchUsers()
      }
      setRequesting(false)
    }, [baseUrl, byStatus, bySearch, byUser])

    const clearFilters = () => {
        setBySearch(undefined)
        setByStatus(undefined)
        setByUser(undefined)
      }

    return (
      <Container fluid className="manage-leads-page">
        <div className="container-inner">
          <div className="d-flex justify-content-between align-items-center header-tabs-container">
            <div className="header-tabs">
              <h1>Company Assets</h1>
            </div>
            <Link
              className="btn btn-outlined-blue"
              to="/settings/gears/new"
              data-target="#newEquipmentModal"
              data-toggle="modal"
              type="button">
              + New Asset
            </Link>
          </div>
          <div className="table-listing-filters">
            <div className="btn-group mr-2">
              <SearchInput value={bySearch} setValue={setBySearch} placeholder="Search by description or category" />
            </div>
            <div className="btn-group mr-2">
              <FilterDropdown
                title="Status"
                filteredName={gearStatus.find(([_, k]) => k === byStatus)?.[0]}
                setFilter={setByStatus}
                options={gearStatus} />
            </div>
            <div className="btn-group mr-2">
              <FilterDropdown
                title="User"
                filteredName={byUser}
                setFilter={setByUser}
                options={userOptions} />
            </div>
            <div className="btn-group">
              <AnchorButton
                className="f-small"
                onClick={clearFilters}>
                Clear&#xa0;Filters
              </AnchorButton>
            </div>
          </div>
          {requesting ? <Loader /> : <GearsTable gearIds={gearIds} gears={gears} fetchData={fetchData} />}
        </div>
      </Container>
    )
}
