import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"

import { Modal, Spinner } from "react-bootstrap"
import { multipleUploadFile } from "requesters/jobServiceRequester"
import Select from "react-select"
import SweetAlert from "react-bootstrap-sweetalert"
import { getBaseUrl, getCurrentUser } from "../../../reducers"

import { MAX_SIZE_UPLOADED_FILE, MAX_SIZE_UPLOADED_FILE_TEXT } from "../../../../../utils/constants"
import Dropzone from "../../../../../components/utils/Dropzone"

const AddFilesModal = ({
  show, onHide, jobService, reloadData, fileCategories, defaultCategory,
  showUploadedFilesSection, uploadedFilesSectionSize, uploadedFilesSource, assignment
}) => {
  const baseUrl = useSelector(getBaseUrl)
  const currentUser = useSelector(getCurrentUser)
  const { id, attributes: { surveyorFiles } } = jobService

  const [fileCategory, setFileCategory] = useState("")
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [largeUploads, setLargeUploads] = useState([])
  const [uploading, setUploading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [filesCollectionError, setFilesCollectionError] = useState(false)
  const [uploadedPhoto, setUploadedPhoto] = useState([])

  const handlePreview = e => {
    setUploadedPhoto([])
    const files = e.value
    const largeFiles = files?.filter(file => file.size > MAX_SIZE_UPLOADED_FILE)
    const filesCollectionSize = files?.map(file => file.size).reduce((result, size) => result + size)

    if (largeFiles?.length) {
      setLargeUploads(largeFiles)
      setUploadedPhoto([])
      setShowError(true)
    } else if (filesCollectionSize > MAX_SIZE_UPLOADED_FILE) {
      setUploadedPhoto([])
      setFilesCollectionError(true)
    } else {
      setUploadedFiles(files)
    }
  }

  const multiplePhotsUpload = () => {
    const formData = new FormData();
    uploadedPhoto.forEach((file, index) => {
        formData.append(`file-${index}`, file);
        formData.append("document_category", fileCategory)
        formData.append("created_by_user_id", currentUser.id)
        if (assignment) {
          formData.append("surveyor_assignment_id", assignment.surveyorAssignmentId)
        }
      });
      setUploading(true)
      multipleUploadFile(baseUrl, id, formData)
      .then(res => {
        if (res.status === 201) {
          setUploading(false)
          setUploadedPhoto([])
          reloadData()
          // onHide()
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
      .finally(() => {
        if (fileCategory === "Photo") {
          setUploading(false)
        }
      });
  }

  useEffect(() => {
    if (show) {
      setFileCategory(defaultCategory)
    } else {
      setUploadedFiles([])
      setUploadedPhoto([])
      setFileCategory("")
      setShowError(false)
      setLargeUploads([])
      setFilesCollectionError(false)
    }
  }, [show])

  const onSubmit = () => {
    multiplePhotsUpload()
  }

  const defaultValue = fileCategories.find(x => x.value === fileCategory)

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <h2 className="modal-title">ADD FILES</h2>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          <div className="form-group row mb-3">
            <label className="col-3 pt-2">File Category*</label>
            <div className="col-8">
              <div className="custom-file">
                <Select
                  name="file_category"
                  isSearchable
                  value={defaultValue}
                  onChange={e => setFileCategory(e.value)}
                  options={fileCategories}
                  placeholder="Select a file category" />
              </div>
            </div>
          </div>
        </form>

        <div className="mt-2">
          <div className="form-group mt-4">
            <Dropzone
              wrapperClass="drag-drop"
              multiple
              name="photo"
              fileTypes={fileCategories[0]?.value === "Photo" && {
                "image/jpeg": [".jpeg"], "image/jpg": [".jpg"], "image/png": [".png"], "image/gif": [".gif"]
              }}
              fileTypeNames=".jpeg .png .jpg .gif"
              setUploadedFiles={setUploadedPhoto}
              handleFileUpload={handlePreview} />
          </div>
          <div className="mt-4">
            {!!uploadedPhoto?.length && uploadedFiles.map(file => (
              <p key={file.path} className="file-list-item mb-0">
                <i className="bi bi-file-earmark mr-2"></i>
                {file.path}
              </p>
            ))}
          </div>
        </div>
        <hr />

        {(showUploadedFilesSection && uploadedFilesSource?.length > 0) && (
          <>
            <p>Uploaded Files</p>
            <div className="ml-0 mt-1">
              {!!uploadedFilesSource?.length && uploadedFilesSource.map(
                file => (
                  <div key={file?.data?.id} className="row mb-0">
                    <div className={`${uploadedFilesSectionSize === "full" ? "col-7" : "col-5"}`}>
                      <i className="bi bi-file-earmark mr-2"></i>
                      {file?.data?.attributes?.fileName}
                    </div>
                    <div className={`${uploadedFilesSectionSize === "full" ? "col-5" : "col-3"}`}>
                      {file?.data?.attributes?.documentCategory}
                    </div>
                  </div>
                )
              )}
            </div>
          </>
        )}


        <div className="modal-footer d-flex justify-content-end px-0 py-4">
          <button
            className="btn btn-link btn-lg"
            onClick={onHide}
            type="submit">
            Close
          </button>
          <button
            className="btn btn-red btn-lg"
            disabled={!fileCategory || !uploadedPhoto?.length || largeUploads?.length}
            onClick={onSubmit}
            type="submit">
            {uploading ? (
              <Spinner
                animation="border" />
            )
            : (
              `Save`
            )}
          </button>
        </div>
      </div>

      <SweetAlert
        show={showError}
        type="error"
        title="File size exceeded!"
        style={{ width: "600px" }}
        closeOnClickOutside
        allowEscape={false}
        onConfirm={() => {
          setShowError(false)
          setLargeUploads([])
          }}>
        Max size is {MAX_SIZE_UPLOADED_FILE_TEXT}
      </SweetAlert>

      <SweetAlert
        show={filesCollectionError}
        type="error"
        title="File sizes exceeded!"
        style={{ width: "600px" }}
        closeOnClickOutside
        allowEscape={false}
        onConfirm={() => setFilesCollectionError(false)}>
        Max size for a collection of files is {MAX_SIZE_UPLOADED_FILE_TEXT}
      </SweetAlert>
    </Modal>
  )
  }
export default AddFilesModal
