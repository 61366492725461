import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import DatePicker from "react-datepicker"
//  import SearchInput from "components/SearchInput"
import Select from "react-select"
import Loader from "components/Loader"
import { roleCheck } from "utils/index"
import moment from "moment"
import {
  useQueryParam,
  StringParam,
  ArrayParam,
  withDefault
} from "use-query-params"
import {
  OPERATIONS,
  SALES,
} from "utils/constants"
import FilterDropdown from "components/FilterDropdown"
import PerformanceTable from "./PerformanceTable"
import { getBaseUrl, getCurrentRole, getCurrentUser } from "../../reducers"
import { getOperationsReports, getSurveyors } from "../../../../requesters/performanceReportRequester"

const OperationsReports = () => {
  const baseUrl = useSelector(getBaseUrl)
  const currentRole = useSelector(getCurrentRole)
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const isSalesManager = roleCheck(systemRole, currentRole, SALES) && currentUser.attributes.default_team_role === "manager"
  const liveJobsAllowed = roleCheck(systemRole, currentRole, OPERATIONS) || isSalesManager
  if (!liveJobsAllowed) window.location = "/"

  const getDate = val => {
    const momentObj = moment(val)
    const dateString = momentObj.format("YYYY-MM-DD")

    return dateString
  }

  const [selectedSurveyors, setSelectedSurveyors] = useQueryParam("surveyor_ids", ArrayParam)
  const [selectedSurveyorOptions, setSelectedSurveyorOptions] = useState([])
  const [surveyorOptions, setSurveyorOptions] = useState([])
  const customDateParam = withDefault(StringParam, getDate(new Date()))
  const [startDate, setStartDate] = useQueryParam("start_date", customDateParam)
  const [endDate, setEndDate] = useQueryParam("end_date", customDateParam)
  const [productTypeOptions, setProductTypeOptions] = useState([])
  const [selectedProductType, setSelectedProductType] = useQueryParam("product_type", StringParam)
  const [requesting, setRequesting] = useState(true)
  const [summaryRows, setSummaryRows] = useState([])
  const [totalRow, setTotalRow] = useState([])
  const [detailedRows, setDetailedRows] = useState([])

  const initialFilters = {
    surveyor_ids: selectedSurveyors || [],
    start_date: startDate,
    end_date: endDate,
    product_type: selectedProductType,
  }
  const [filters, setFilters] = useState(initialFilters)


  const onSurveyorSelectChange = val => {
    const surveyorIds = val?.map(v => v.value) || []
    setSelectedSurveyors(surveyorIds)
    setFilters({ ...filters, surveyor_ids: surveyorIds })
  }

  const getAndSetSelectedSurveyorOptions = options => {
    const sourceOptions = options || surveyorOptions || []
    const newSelectedSurveyorOptions = sourceOptions.filter(option => selectedSurveyors?.includes(option.value?.toString()))
    setSelectedSurveyorOptions(newSelectedSurveyorOptions)
  }

  useEffect(() => {
    getAndSetSelectedSurveyorOptions()
  }, [selectedSurveyors])

  const fetchData = () => {
    setRequesting(true)
    getOperationsReports(baseUrl, filters)
      .then(res => {
        if (res.status === 200) {
          const allTypes = res.data.json.productTypes?.map(type => [type, type])
          setProductTypeOptions(allTypes)
          setSummaryRows(res.data.json.summary)
          setTotalRow(res.data.json.total)
          setDetailedRows(res.data.json.detail)
        }
      })
      .catch(err => console.log(err));
      setRequesting(false)
  }

  useEffect(() => {
    fetchData()
  }, [selectedSurveyors, startDate, endDate, selectedProductType])

  const onStartDateChange = val => {
    const newStartDate = getDate(val)
    setStartDate(newStartDate)
    setFilters({ ...filters, start_date: newStartDate })
  }

  const onEndDateChange = val => {
    const newEndDate = getDate(val)
    setEndDate(newEndDate)
    setFilters({ ...filters, end_date: newEndDate })
  }

  const setProductType = val => {
    setSelectedProductType(val)
    setFilters({ ...filters, product_type: val })
  }

  const fetchSurveyors = () => {
    getSurveyors(baseUrl)
    .then(res => {
      const options = []
      res.data.forEach(s => options.push({ label: `${s[0]} ${s[1]}`, value: s[2] }))
      const sortedOptions = _.orderBy(options, "label", "asc")
      setSurveyorOptions(sortedOptions)
      getAndSetSelectedSurveyorOptions(sortedOptions)
    })
    .catch(err => console.error(err))
  }

  const clearStates = () => {
    setFilters({})
    setSelectedSurveyors([])
    setStartDate(undefined)
    setEndDate(undefined)
    setSelectedProductType(undefined)
  }

  useEffect(() => {
    fetchSurveyors()

    return () => clearStates()
  }, [])

  const startDateObject = moment(startDate, "YYYY-MM-DD").toDate()
  const endDateObject = moment(endDate, "YYYY-MM-DD").toDate()

  return (
    <React.Fragment>
      <div className="container-inner">
        <div className="table-listing-filters d-md-flex flex-row justify-content-between">
          <div className="d-flex flex-row align-items-center">
            <div className="col-md-6 pl-0">
              <Select
                isMulti
                isSearchable
                value={selectedSurveyorOptions}
                onChange={onSurveyorSelectChange}
                options={surveyorOptions} />
            </div>
            <div className="btn-group mr-2">
              <DatePicker
                selected={startDateObject}
                selectsStart
                startDate={startDateObject}
                endDate={endDateObject}
                dateFormat="dd/MM/yyyy"
                onChange={onStartDateChange} />
              <DatePicker
                selected={endDateObject}
                selectsEnd
                startDate={startDateObject}
                endDate={endDateObject}
                dateFormat="dd/MM/yyyy"
                onChange={onEndDateChange} />
            </div>
            <div className="btn-group">
              <FilterDropdown title="Product Type" filteredName={selectedProductType} setFilter={setProductType} options={productTypeOptions} />
            </div>
          </div>
          <div>
            {/* <button
              type="button"
              className="btn btn-primary"
              // onClick={generateReport}
              >
              Export to Excel
            </button> */}
          </div>
        </div>
      </div>
      {requesting ? <Loader /> : <PerformanceTable summaryRows={summaryRows} totalRow={totalRow} detailedRows={detailedRows} />}
    </React.Fragment>
  )
}

export default OperationsReports
