import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { roleCheck } from "utils/index"
import { get, isEmpty } from "lodash"
import Loader from "components/Loader"
import { getBaseUrl, getCurrentRole, getCurrentUser } from "../../../../reducers"
import { OPERATIONS } from "../../../../../../utils/constants"
import { getTimeSpents } from "../../../../../../requesters/timeSpentRequester"
import TimeSpentTable from "./TimeSpentTable"
import AddTimeSpent from "../../../../../../components/AddTimeSpent"

const TimeSpentTab = ({ jobService, reloadData }) => {
  const baseUrl = useSelector(getBaseUrl)
  const [timeSpents, setTimeSpents] = useState([])
  const [requesting, setRequesting] = useState(true)
  const [hasNextPage, setHasNextPage] = useState(true)
  const [currentPage, setCurrentPage] = useState(1);
  const currentUser = useSelector(getCurrentUser)
  const systemRole = currentUser.attributes.role
  const currentRole = useSelector(getCurrentRole)
  const isAdminOrOperations = roleCheck(systemRole, currentRole, OPERATIONS)

  const fetchData = () => {
    setRequesting(true)
    getTimeSpents(baseUrl, jobService.id, jobService.type, currentPage)
    .then(res => {
      if (res.status === 200) {
        const timeSpentData = get(res.response, "timeSpent", {})
        let listOfTimeSpent = Object.values(timeSpentData)
        const additionalInfo = res.response.meta?.additional_info
        const hasNextPageValue = additionalInfo && additionalInfo?.meta?.hasNextPage
        if (!isEmpty(additionalInfo)) {
          listOfTimeSpent = additionalInfo.meta?.ids.map(id => timeSpentData[id])
        }
        const newTimeSpentList = currentPage > 1 ? [...timeSpents, ...listOfTimeSpent] : listOfTimeSpent
        setHasNextPage(hasNextPageValue)
        setTimeSpents(newTimeSpentList)
        setRequesting(false)
        reloadData()
      }
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
    .finally(() => setRequesting(false))
  }

  const reloadAfterAddTime = () => {
    reloadData()
    if (currentPage === 1) {
      fetchData()
    } else {
      setCurrentPage(1)
    }
  }

  useEffect(() => {
    fetchData()
  }, [currentPage])

  return (
    <div className="timeline-item">
      <div className="card border-0 shadow-sm timeline-card">
        <div className="card-body p-lg-5">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="card-title">Time Spent</h5>
            <AddTimeSpent
              buttonText="+ Add Time"
              reloadData={reloadAfterAddTime}
              trackableId={jobService.id}
              trackableType={jobService.type}
              trackedOnState={jobService.attributes.state} />
          </div>
          <TimeSpentTable timeSpents={timeSpents} currentState={jobService.attributes.state} reloadData={reloadAfterAddTime} />
          { hasNextPage && !requesting && (
            <div className="d-flex justify-content-center align-items-center m-4">
              <button
                type="button"
                onClick={() => setCurrentPage(currentPage + 1)}
                className="btn btn-lg btn-primary"
                disabled={requesting}>
                Load More
              </button>
            </div>
          )}
          {requesting && <Loader />}
        </div>
      </div>
    </div>
  )
}

export default TimeSpentTab
