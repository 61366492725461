import React from "react"
import { combineReducers } from "redux"
import { toast } from "react-toastify"

import * as actionTypes from "../actions/types"

const toastOptions =  {
  toastId: "user-details-page",
  containerId: "main"
}

function requestUserState(state = false, action) {
  switch (action.type) {
    case actionTypes.USER_REQUEST_START:
      return true
    case actionTypes.USER_REQUEST_SUCCESS:
    case actionTypes.USER_REQUEST_FAILURE:
      return false
    default:
      return state
  }
}

function postUserState(state = false, action) {
  switch (action.type) {
    case actionTypes.USER_POST_START:
      return true
    case actionTypes.USER_POST_SUCCESS:
      toast.success(
        <div>
          <span role="img" aria-label="success-icon">✅</span>
          <span className="ml-2">User updated!</span>
        </div>,
        toastOptions
      )
      return false
    case actionTypes.USER_POST_FAILURE:
      toast.error(
        <div>
          <span role="img" aria-label="failure-icon">❌</span>
          <span className="ml-2">User update error!</span>
        </div>,
        toastOptions
      )
      return false
    default:
      return state
  }
}

function requestSurveyorSkillsState(state = false, action) {
  switch (action.type) {
    case actionTypes.SURVEYOR_SKILL_REQUEST_START:
      return true
    case actionTypes.SURVEYOR_SKILL_REQUEST_SUCCESS:
    case actionTypes.SURVEYOR_SKILL_REQUEST_FAILURE:
      return false
    default:
      return state
  }
}

function editState(state = false, action) {
  switch (action.type) {
    case actionTypes.MANAGE_USERS_START_EDITING:
      return true
    case actionTypes.MANAGE_USERS_EDIT_RESET:
    case actionTypes.MANAGE_USERS_EDIT_SAVE:
    case actionTypes.MANAGE_USERS_EDIT_CANCEL:
      return false
    default:
      return state
  }
}

function userEditableAttr(state = {}, action) {
  switch (action.type) {
    case actionTypes.USER_POST_SUCCESS:
    case actionTypes.USER_REQUEST_SUCCESS: {
      const { data: { user } } = action
      const userAttributes = _.get(Object.values(user), "0.attributes", {})
      const {
        firstname, surname, email, phone, role, fulladdress, emergencyContactNumber, emergencyContact, surveyorId,
        monday, tuesday, wednesday, thursday, friday, saturday, sunday,
        hourlyRate,
        surveyorType,
        surveyorSkillsIds
      } = userAttributes
      return {
        firstname,
        surname,
        email,
        phone,
        role,
        fulladdress,
        emergency_contact: emergencyContact,
        emergency_contact_number: emergencyContactNumber,
        surveyor_type: surveyorType,
        surveyor_id: surveyorId,
        hourly_rate: hourlyRate,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
        surveyor_skills_ids: surveyorSkillsIds
      }
    }
    case actionTypes.MANAGE_USERS_EDIT_CANCEL: {
      const { data } = action
      const userAttributes = _.get(data, "attributes", {})
      const {
        firstname, surname, email, phone, role, fulladdress, surveyorType, surveyorSkillsIds, emergencyContactNumber, emergencyContact, surveyorId,
        monday, tuesday, wednesday, thursday, friday, saturday, sunday,
        hourlyRate
      } = userAttributes
      return {
        firstname,
        surname,
        email,
        phone,
        role,
        fulladdress,
        emergency_contact: emergencyContact,
        emergency_contact_number: emergencyContactNumber,
        surveyor_type: surveyorType,
        surveyor_skills_ids: surveyorSkillsIds,
        surveyor_id: surveyorId,
        hourly_rate: hourlyRate,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday
      }
    }
    case actionTypes.MANAGE_USERS_EDIT_UPDATE: {
      return { ...state, ...action.data }
    }
    default:
      return state
  }
}

export default combineReducers({
  requestUserState,
  requestSurveyorSkillsState,
  postUserState,
  editState,
  userEditableAttr
})
