import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";

import "react-datepicker/dist/react-datepicker.css";


export default function AddDocketTotalAmountModal({
  show, setShow, onUpdateTotalAmount, totalAmount, loading
}) {
  const [currentTotalAmount, setCurrentTotalAmount] = useState(0)

  useEffect(() => {
    setCurrentTotalAmount(totalAmount)
  }, [totalAmount])
  return (
    <Modal show={show} onHide={setShow} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title><h4 className="modal-title">Add Total Amount</h4></Modal.Title>
      </Modal.Header>
      <div className="modal-body px-md-5">
        <form>
          <div className="form-group">
            <div style={{ fontSize: "25px" }}>
              <input value={currentTotalAmount} type="number" className="form-control" placeholder="Total Amount" onChange={e => setCurrentTotalAmount(e.target.value)} />
            </div>
          </div>
        </form>
        <div className="modal-footer d-flex justify-content-between p-0 mb-4 mt-5">
          <div>
            <button
              disabled={loading}
              className="btn btn-red btn-lg mr-2"
              onClick={() => {
                onUpdateTotalAmount(currentTotalAmount)
                setShow(false)
              }}
              type="submit">
              Save
              {loading && <Spinner className="ml-1" animation="border" role="status" size="sm" />}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
