import React from "react"
import LocationSummary from "./LocationSummary"

const TableNearbyJobs = ({ nearbyJobs, showFutureJobsOnly }) => {
  const defaultTab = showFutureJobsOnly ? "Activity" : "History"

  const getJobServiceLink = jobService => `/jobs/${jobService.jobId}/job_services/${jobService.id}?tab=${defaultTab}`

  // const handleClick = lastJobService => {
  //   if (lastJobService) {
  //     window.open(getJobServiceLink(lastJobService), "_top")
  //   }
  // }

  const NearbyJobRow = nearbyJobLocation => {
    const {
      id,
      attributes: {
        lastJobService,
        internalJobIdentifier,
        defaultJobTitle,
        distance
      }
    } = nearbyJobLocation

    return (
      <tr key={`${id}`}>
        <td>
          { lastJobService ? <a href={getJobServiceLink(lastJobService)}>{internalJobIdentifier}</a> : <span>{internalJobIdentifier}</span> }
        </td>
        <td>
          { lastJobService ? <a href={getJobServiceLink(lastJobService)}>{defaultJobTitle}</a> : <span>{defaultJobTitle}</span> }
        </td>
        <td>{distance}</td>
        <td>
          <LocationSummary nearbyJobLocation={nearbyJobLocation} />
        </td>
      </tr>
    )
  }

  return (
    <div className="table-responsive">
      <table className="table mt-4">
        <thead>
          <tr>
            <th scope="col">Job No</th>
            <th scope="col">Job Title</th>
            <th scope="col">Distance (M)</th>
            <th scope="col">Notes</th>
          </tr>
        </thead>
        <tbody>
          {nearbyJobs.map(nearbyJob => NearbyJobRow(nearbyJob))}
        </tbody>
      </table>
    </div>
  )
}

export default TableNearbyJobs;
