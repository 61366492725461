import React, { useState }  from "react"
import { useSelector } from "react-redux"
import { getGooglePlacesAutocomplete, getGooglePlacesDetail } from "requesters/googlePlacesRequester"
import { uploadUserLatLong } from "requesters/userRequester"
import { getBaseUrl } from "../../../reducers"

export default function UserAddressInput({
  title, hrefPrefix, user_id,
  editState, value, inputValue, setInputValue,
  labelWrapperClass, inputWrapperClass
}) {
  const baseUrl = useSelector(getBaseUrl)
  const [suggestions, setSuggestions] = useState([])
  const [activeSuggestion, setActiveSuggestion] = useState(0)
  let clsName
  const handleInputChange = e => {
    setInputValue(e.target.value)
    if (e.target.value.length > 2) {
      getGooglePlacesAutocomplete(baseUrl, { by_query: e.target.value })
      .then(res => {
        if (res.status === 200) {
          const places = _.get(res.response, "data", [])
          setSuggestions(places)
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
    } else {
      setSuggestions([])
    }
  }

  const handleItemSelect = item => {
    getGooglePlacesDetail(baseUrl, { place_id: item.place_id })
      .then(res => {
        if (res.status === 200) {
          const place = _.get(res.response, "data", {})
          uploadUserLatLong(baseUrl, user_id, { latitude: place.lat, longitude: place.lng })
          .then(res => {
            if (res.status === 200) {
              setInputValue(item?.description)
              setSuggestions([])
            }
          })
          // eslint-disable-next-line no-console
          .catch(err => console.log(err))
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err))
  }

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      // enter key
      handleItemSelect(suggestions[activeSuggestion])
    } else if (e.keyCode === 38) {
      // up arrow key
      if (activeSuggestion === 0) {
        setActiveSuggestion(suggestions.length - 1)
        return;
      }
      setActiveSuggestion(activeSuggestion - 1)
    } else if (e.keyCode === 40) {
      // down arrow key
      if (activeSuggestion + 1 === suggestions.length) {
        setActiveSuggestion(0)
        return;
      }
      setActiveSuggestion(activeSuggestion + 1)
    }
  }

  if (editState) {
    return (
      <div className="form-group row">
        <label className={`${labelWrapperClass || "col-3"} col-form-label`}>{title}</label>
        <div className={inputWrapperClass || "col-9"}>
          <input
            className="form-control"
            type="text"
            placeholder="Enter an address"
            value={inputValue || ""}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown} />
          {suggestions.length > 0 && (
          <ul className="list-group">
            {suggestions.map((item, index) => {
              clsName = ""
              if (index === activeSuggestion) {
                clsName = "list-group-item active"
              } else {
                clsName = "list-group-item"
              }
              return (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <li
                  className={clsName}
                  key={item.place_id}
                  onClick={() => { handleItemSelect(item) }}
                  onKeyDown={event => {
                    if (event.key === "Enter") {
                      event.preventDefault()
                      if (item) {
                        handleItemSelect(item)
                      }
                    }
                  }}>
                  {item.description}
                </li>
              )
              })}
          </ul>
          )}
        </div>
      </div>
    )
  }
  return (
    <div className="row mb-2">
      <div className="col-3">{title}</div>
      <div className="col-9">
        {hrefPrefix ? <a href={`${hrefPrefix}:${value}`}>{value}</a> : value}
      </div>
    </div>
  )
}