import React, { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { StringParam, useQueryParam } from "use-query-params"
import SearchInput from "components/SearchInput"
import debounce from "lodash.debounce"
import { getOrganizations } from "requesters/organizationRequester"
import OrganizationsTable from "./OrganizationsTable"
import { getBaseUrl } from "../../reducers"

export default function OrganizationTab() {
  const baseUrl = useSelector(getBaseUrl)
  const [bySearch, setBySearch] = useQueryParam("by_search", StringParam)

  const [organizations, setOrganizations] = useState([])
  const [requesting, setRequesting] = useState(true)
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true)


  const nextPageHandler = contactsList => {
    if (!contactsList || Object.keys(contactsList).length < 25) {
      setHasNextPage(false)
    } else {
      setHasNextPage(true)
    }
  }

  const searchOrganizations = input => {
    setRequesting(true)
    getOrganizations(baseUrl, {
      by_keyword: input,
      page,
      per_page: 25,
    }).then(res => {
      if (res.status === 200) {
        const organizationList = page > 1 ? { ...organizations, ..._.get(res.response, "organization", {}) } : _.get(res.response, "organization", {})
        setOrganizations(organizationList)
        nextPageHandler(res.response?.organization)
      }
      setRequesting(false)
    }).catch(() => { setRequesting(false) })
  }

  const debouncedSearchContacts = useCallback(
    debounce(input => {
      searchOrganizations(input)
    }, 500),
    [searchOrganizations]
  )

  const handleSearchInput = input => {
    setBySearch(input);

    debouncedSearchContacts(input)
  }

  useEffect(() => {
    searchOrganizations(bySearch);
  }, [baseUrl, page])

  useEffect(() => {
    setPage(1)
  }, [bySearch])

  const loadMore = () => {
    setPage(page + 1)
  }


  const onDeleteOrganization = organizationId => {
    const listOrganizations = Object.values(organizations).filter(organization => organization.id !== organizationId)
    setOrganizations(listOrganizations)
  }

  return (
    <>
      <div className="table-listing-filters">
        <div className="btn-group mr-2">
          <SearchInput value={bySearch} disableSubmit setValue={handleSearchInput} placeholder="Search by name or address" />
        </div>
      </div>
      <OrganizationsTable organizations={Object.values(organizations)} onDeleteOrganization={onDeleteOrganization} />
      <div className="d-flex justify-content-center align-items-center m-4">
        {hasNextPage && <button type="button" onClick={loadMore} className="btn btn-lg btn-primary" disabled={requesting}>{requesting ? "Loading..." : "Load More"}</button>}
      </div>
    </>
  )
}
